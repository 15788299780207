import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { EditFormFieldsControls } from '../EditFormFieldsControls/EditFormFieldsControls';
import { EditFormFieldsContainerWrapper } from './EditFormFieldsContainer.styles';

type EditFormFieldsContainerProps = {
  fieldTitle?: string;
  fieldValue?: string;
  editIcon?: React.ReactNode;
  isSuccess: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onClose: () => void;
  submitDisabled: boolean;
  showBelow?: boolean;
  allowEdit?: boolean;
  children: React.ReactNode;
};

export const EditFormFieldsContainer: React.FC<EditFormFieldsContainerProps> = ({
  fieldTitle,
  fieldValue,
  isSuccess,
  isLoading,
  onSubmit,
  onClose,
  submitDisabled,
  showBelow,
  allowEdit,
  editIcon,
  children,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleCloseForm = useCallback(() => {
    setIsEdit(false);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (isSuccess) {
      handleCloseForm();
    }
  }, [handleCloseForm, isSuccess]);

  return (
    <EditFormFieldsContainerWrapper>
      <div
        className={classNames('edit-form-fields-container__main', {
          'edit-form-fields-container__main--show-below': showBelow,
          'edit-form-fields-container__main--only-icon': !fieldTitle && !fieldValue,
        })}
      >
        {!!fieldTitle && (
          <Typography className="edit-form-fields-container__title">{fieldTitle}:</Typography>
        )}
        <div className="edit-form-fields-container__block">
          {!isEdit && (
            <div className="edit-form-fields-container__label">
              {!!fieldValue && (
                <Typography className="edit-form-fields-container__label-text">
                  {fieldValue}
                </Typography>
              )}

              {allowEdit && (
                <div className="edit-form-fields-container__label-edit">
                  <IconButton
                    onClick={() => setIsEdit(true)}
                    className="edit-form-fields-container__label-edit-button"
                    aria-label="edit-form-fields-container-edit"
                  >
                    {editIcon ? (
                      editIcon
                    ) : (
                      <EditIcon className="edit-form-fields-container__label-edit-icon" />
                    )}
                  </IconButton>
                </div>
              )}
            </div>
          )}
          {isEdit && !showBelow && (
            <div className="edit-form-fields-container__inner-content">
              <div className="edit-form-fields-container__inner-content-form">{children}</div>
              <EditFormFieldsControls
                isLoading={isLoading}
                onSubmit={onSubmit}
                submitDisabled={submitDisabled}
                onClose={handleCloseForm}
                closeDisabled={isLoading}
              />
            </div>
          )}
        </div>
      </div>
      {isEdit && showBelow && (
        <div className="edit-form-fields-container__outer-content">
          <div className="edit-form-fields-container__outer-content-form">{children}</div>
          <EditFormFieldsControls
            isLoading={isLoading}
            onSubmit={onSubmit}
            submitDisabled={submitDisabled}
            onClose={handleCloseForm}
            closeDisabled={isLoading}
          />
        </div>
      )}
    </EditFormFieldsContainerWrapper>
  );
};

import React, { useState } from 'react';

import FileSelect from 'components/atoms/FileSelect';
import Modal from 'components/elements/Modal';
import ValidatedInput from 'components/elements/ValidatedInput';
import styled from 'styled-components';
import { handleApiReq, handleFormReq } from 'utils/ApiRequestHelpers';
import { getInputState } from 'utils/helpers';
import useAlert from 'utils/hooks/useAlert';
import useValidateForm from 'utils/hooks/useValidateForm';
import { isRequired, isInList } from 'utils/inputValidation';

const SubmitButton = styled.button`
  ${(props) => props.theme.submitButton()};
`;
const CancelButton = styled.button`
  ${(props) => props.theme.clearButton()};
`;

const InputWrapper = styled.div`
  width: 60%;
  max-width: 40rem;
  min-width: 22rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 2rem auto 0;

  && > button {
    margin: 0 2rem;
  }
`;

const FileUploadLabelWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  min-width: 100%;
  width: 60rem;
`;

const Error = styled.span`
  color: ${(props) => props.theme.failed};
  font-size: ${(props) => props.theme.textSmaller};
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: 500;
`;

const initialState = {
  certificateNumber: '',
  date: new Date(),
  result: '',
};

const AddGasSafetyRecordModal = ({ deviceId, handleResetAction }) => {
  const { errorAlert } = useAlert();

  const [safetyCert, setSafetyCert] = useState(initialState);
  const [file, setFile] = useState<File>();

  const validationSchema = {
    certificateNumber: [{ fn: isRequired, msg: 'Certificate Number is required' }],
    date: [{ fn: isRequired, msg: 'Date is required' }],
    result: [
      { fn: isRequired, msg: 'Result must be selected' },
      { fn: isInList(['Pass', 'Fail']), msg: 'Result can only be passed or failed' },
    ],
    fileName: [{ fn: isRequired, msg: 'Certificate must be provided' }],
  };

  const { validationState, validateForm, validateInput } = useValidateForm(
    {
      ...safetyCert,
      fileName: file?.name ?? '',
    },
    validationSchema
  );

  const handleInputChange = (value, name) => {
    setSafetyCert((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(value, name);
  };

  const handleFileSelect = (file) => {
    setFile(file);
    validateInput(file?.name, 'fileName');
  };

  const handleAddRecord = async () => {
    const body = {
      deviceId: deviceId,
      recordSerialNumber: safetyCert.certificateNumber,
      recordTimestamp: safetyCert.date,
      recordStatus: safetyCert.result,
    };

    const res = await handleApiReq('AddBoilerGasSafetyRecord', body);

    return res;
  };

  const handleFileUpload = async (recordId) => {
    const data = new FormData();
    data.append('deviceId', deviceId);
    data.append('recordId', recordId);

    if (file) {
      data.append('file', file, file.name);
    }

    const res = await handleFormReq('AddBoilerRecordAttachment', data);

    return res;
  };

  const handleSubmit = async () => {
    const isValid = validateForm(true);

    if (isValid) {
      const res = await handleAddRecord();

      if (res) {
        const { recordId } = res;
        const isSuccessful = await handleFileUpload(recordId);

        if (isSuccessful) {
          handleResetAction(true);

          return;
        }

        errorAlert('Failed to add record');
      }
    }
  };

  return (
    <Modal showModal handleClose={handleResetAction} title="Add Gas Safety Record">
      <div>
        <InputWrapper>
          <ValidatedInput
            errorLabel
            type="text"
            label="Certificate Number"
            errMsg={validationState['certificateNumber'].err}
            inputData={{
              onChange: handleInputChange,
              placeholder: 'Certificate Number',
              name: 'certificateNumber',
              state: getInputState(validationState, 'certificateNumber'),
              value: safetyCert.certificateNumber,
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <ValidatedInput
            errorLabel
            type="date"
            label="Date"
            errMsg={validationState['date'].err}
            inputData={{
              onChange: handleInputChange,
              name: 'date',
              state: getInputState(validationState, 'date'),
              value: safetyCert.date,
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <ValidatedInput
            errorLabel
            type="passfail"
            label="Result"
            errMsg={validationState['result'].err}
            inputData={{
              onChange: handleInputChange,
              name: 'result',
              state: getInputState(validationState, 'result'),
              value: safetyCert.result,
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <FileUploadLabelWrapper>
            <span>Certificate</span>
            {validationState['fileName'].err && <Error>Certificate must be provided</Error>}
          </FileUploadLabelWrapper>
          <FileSelect fileName={file?.name} setFile={handleFileSelect} />
        </InputWrapper>
      </div>
      <ButtonWrapper>
        <SubmitButton onClick={handleSubmit}>Save</SubmitButton>
        <CancelButton onClick={handleResetAction}>Cancel</CancelButton>
      </ButtonWrapper>
    </Modal>
  );
};

export default AddGasSafetyRecordModal;

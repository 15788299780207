import { camelCaseToTitleCase, formatDate } from './helpers';

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isJson = (arr) =>
  Array.isArray(arr) && arr.every((row) => typeof row === 'object' && !(row instanceof Array));

const is2DArray = (arr) => Array.isArray(arr) && arr.every((row) => Array.isArray(row));

const getJsonHeaders = (arr) =>
  [...new Set(arr.map((x) => Object.keys(x)).flat())].map((h) => ({
    label: camelCaseToTitleCase(h),
    key: h,
  }));

const getHeaders = (arr) =>
  arr.map((h) => ({
    label: camelCaseToTitleCase(h),
    key: h,
  }));

const isDate = (p) => p === 'testDate' || p === 'date' || p === 't180StartDate';

const getObjValue = (property, obj) => {
  if (!obj[property]) return '';

  return isDate(property) ? formatDate(obj[property]) : obj[property];
};

const objToArr = (data, headers) => {
  const getHeadersObj = () => {
    if (!headers) {
      return getJsonHeaders(data);
    }
    if (isJson(headers)) {
      return headers;
    }

    return getHeaders(headers);
  };

  const headerObj = getHeadersObj();
  const headerLabels = headerObj.map((h) => h.label);
  const headerKeys = headerObj.map((h) => h.key);

  return [headerLabels, ...data.map((obj) => headerKeys.map((h) => getObjValue(h, obj)))];
};

const elementOrEmpty = (element) => (element || element === 0 ? element : '');

const createCsv = (data, seperator = ',', enclosingChar = '"') =>
  data
    .filter((e) => e)
    .map((row) =>
      row
        .map(
          (col) => `${enclosingChar}${isNaN(col) ? '' : '\t'}${elementOrEmpty(col)}${enclosingChar}`
        )
        .join(seperator)
    )
    .join('\n');

const arrayToCsv = ({ data, headers, separator, enclosingCharacter }) =>
  createCsv(headers ? [headers, ...data] : data, separator, enclosingCharacter);

const jsonToCsv = ({ data, headers, separator, enclosingCharacter }) =>
  createCsv(objToArr(data, headers), separator, enclosingCharacter);

export const toCsv = (csvProps) => {
  if (isJson(csvProps.data)) return jsonToCsv(csvProps);
  if (is2DArray(csvProps.data)) return arrayToCsv(csvProps);
  throw new TypeError('Data should be a "Array of arrays" OR "Array of objects" ');
};

export const buildURI = (data, headers, separator, enclosingCharacter, uFEFF) => {
  const csv = toCsv({ data, headers, separator, enclosingCharacter });
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob([uFEFF ? '\uFEFF' : '', csv], { type });
  const dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`;

  const URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);
};

import Alert from 'types/Tags/Alert';
import { handleReduxApiReq } from 'utils/ApiRequestHelpers';
import { getDelayDisplay } from 'utils/helpers';

import IAction from '../IAction';

const ALERT_REQUEST = 'ALERT_REQUEST';
const ALERT_ERROR = 'ALERT_ERROR';
const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS';
const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS';
const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS';

export const alertTypes = {
  ALERT_REQUEST,
  ALERT_ERROR,
  CREATE_ALERT_SUCCESS,
  DELETE_ALERT_SUCCESS,
  UPDATE_ALERT_SUCCESS,
};

const alertRequest = () => ({
  type: ALERT_REQUEST,
});

const alertError = (error): IAction<any> => ({
  type: ALERT_ERROR,
  error: error,
});

const createAlertSuccess = (alert: Alert): IAction<any> => ({
  type: CREATE_ALERT_SUCCESS,
  payload: alert,
});

const updateAlertSuccess = (alert: Alert): IAction<any> => ({
  type: UPDATE_ALERT_SUCCESS,
  payload: alert,
});

const deleteAlertSuccess = (id): IAction<any> => ({
  type: DELETE_ALERT_SUCCESS,
  payload: id,
});

export const createAlertAction = async (dispatch, alert) => {
  dispatch(alertRequest());
  try {
    const data = await handleReduxApiReq('CreateAlertDefinition', alert, 'POST');
    if (data) {
      data.delayDisplay = getDelayDisplay(data);
      dispatch(createAlertSuccess(data));
    }
  } catch (e) {
    dispatch(alertError(e));
  }
};

export const createAlert = (alert: Alert) => async (dispatch) => {
  createAlertAction(dispatch, alert);
};

export const deleteAlertAction = async (dispatch, req) => {
  dispatch(alertRequest());
  try {
    const data = await handleReduxApiReq('DeleteAlertDefinition', req, 'POST');
    if (data) {
      dispatch(deleteAlertSuccess(data));
    }
  } catch (e) {
    dispatch(alertError(e));
  }
};

export const deleteAlert = (alert: Alert) => async (dispatch) => {
  const { tagTenant, tagId, alertId } = alert;
  deleteAlertAction(dispatch, { tagTenant, tagId, alertId });
};

export const updateAlertAction = async (dispatch, alert) => {
  dispatch(alertRequest());
  try {
    const data = await handleReduxApiReq('UpdateAlertDefinition', alert, 'POST');
    if (data) {
      data.alert.delayDisplay = getDelayDisplay(data.alert);
      dispatch(updateAlertSuccess(data));
    }
  } catch (e) {
    dispatch(alertError(e));
  }
};

export const updateAlert = (alert: Alert) => async (dispatch) => {
  updateAlertAction(dispatch, alert);
};

import styled from 'styled-components';

export const TotalDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  & span {
    font-size: ${(props) => props.theme.textBiggest};
    font-weight: 600;
  }

  & p {
    width: 60%;
    margin: 0 1rem;
    font-size: ${(props) => props.theme.textSmall};
    font-weight: 500;
  }

  & .link-icon {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
`;

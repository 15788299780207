import { isDate } from 'util';

import React, { useState } from 'react';

import Calendar from 'react-calendar';
import styled from 'styled-components';
import { subtractDays, formatAsDate } from 'utils/helpers';

const InputWrapper = styled.div`
  position: relative;
  z-index: 2;

  & > input {
    ${(props) => props.theme.inputStyles};
  }
`;

const CalanderWrapper = styled.div`
  position: absolute;
  top: 4rem;

  background: ${(props) => props.theme.white};
  border-radius: 5px;

  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
`;

const CalendarInner = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.textSmaller};

  & ul {
    width: 14rem;
    font-size: inherit;

    & li {
      padding: 1rem 0;
      background: ${(props) => props.theme.white};
      color: inherit;
      font-size: inherit;
      transition: all 0.3s ease-in-out;
      text-align: center;

      &:first-child {
        border-top-left-radius: 5px;
      }

      &:hover {
        cursor: pointer;
        background: #b5bbc9;
        color: white;
      }

      &.selected {
        background: ${(props) => props.theme.primary};
        color: white;
      }
    }
  }
`;

const CalendarLeft = styled.div`
  font-size: ${(props) => props.theme.textSmall};
  border-right: 1px solid #707070;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const CalendarRight = styled.div`
  & > div {
    border: none;
    margin: 1rem;
  }
`;

const Button = styled.button`
  ${(props) => props.theme.defaultButton('10rem')};
  margin-bottom: 2rem;
`;

const options = [
  { label: '1 day', range: 0 },
  { label: '7 days', range: 6 },
  { label: '30 days', range: 29 },
];

const DateRangeInput = ({ name, value, placeholder = '', onChange }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [range, setRange] = useState(0);

  const handleOnClick = () => setShowCalendar(!showCalendar);

  const handleSelect = (i) => {
    setRange(i);
    onChange([subtractDays(value[1], options[i].range), value[1]]);
  };

  return (
    <InputWrapper>
      <input
        autoComplete="off"
        name={name}
        value={value.map((d) => formatAsDate(d)).join(' - ')}
        placeholder={placeholder}
        readOnly
        onClick={handleOnClick}
      />
      {showCalendar && (
        <CalanderWrapper>
          <CalendarInner>
            <CalendarLeft>
              <ul>
                {options.map((o, i) => (
                  <li
                    key={i}
                    onClick={() => handleSelect(i)}
                    className={i === range ? 'selected' : ''}
                    role="presentation"
                  >
                    {o.label}
                  </li>
                ))}
              </ul>
              <Button onClick={() => setShowCalendar(false)}>Ok</Button>
            </CalendarLeft>
            <CalendarRight>
              <Calendar
                returnValue="end"
                maxDate={new Date()}
                value={value}
                onChange={(value) => {
                  if (isDate(value)) {
                    onChange([subtractDays(value, options[range].range), value]);
                  }
                }}
              />
            </CalendarRight>
          </CalendarInner>
        </CalanderWrapper>
      )}
    </InputWrapper>
  );
};

export default DateRangeInput;

import { createSelector } from 'reselect';
import { Field } from 'types/Listing';
import { createThunkEffect } from 'utils/ReduxUtils';

export interface Fields {
  device: Field[];
  boiler: Field[];
  emered: Field[];
  thirdParty: Field[];
}

const intialFields: Fields = {
  device: [],
  boiler: [],
  emered: [],
  thirdParty: [],
};

const FETCH_FIELDS = 'FETCH_FIELDS';
const CLEAR_FIELDS = 'CLEAR_FIELDS';

export const fieldActionTypes = {
  FETCH_FIELDS,
  CLEAR_FIELDS,
};

export const clearFields = () => ({ type: CLEAR_FIELDS });

export const fetchFields = () => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_FIELDS, 'GetAvailableFields', {});

export const fieldsReducer = (state = intialFields, action): Fields => {
  switch (action.type) {
    case `${fieldActionTypes.FETCH_FIELDS}_SUCCESS`:
      return {
        ...state,
        ...action.payload,
      };
    case fieldActionTypes.CLEAR_FIELDS:
      return intialFields;
    default:
      return state;
  }
};

const selectViewFields = (state: any) => state.fields;

export const selectEmeredViewFields = createSelector(selectViewFields, (fields) => [
  ...fields.device.filter((f) => f.viewable),
  ...fields.emered.filter((f) => f.viewable),
]);

export const selectBoilerViewFields = createSelector(selectViewFields, (fields) => [
  ...fields.device.filter((f) => f.viewable),
  ...fields.boiler.filter((f) => f.viewable),
]);

export const selectNetworkingViewFields = createSelector(selectViewFields, (fields) => [
  ...fields.device.filter((f) => f.viewable),
]);

export const selectThirdPartyViewFields = createSelector(selectViewFields, (fields) => [
  ...fields.thirdParty.filter((f) => f.viewable),
]);

import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import CircleIcon from '@mui/icons-material/Circle';
import ReportIcon from '@mui/icons-material/Report';
import InventoryIcon from '@mui/icons-material/Inventory';
import { DeviceStatus } from 'types/BoilerTypes/BcmConnectDetails';
import { MuiStatusColors } from 'types/MUI/statusColors';

export const getStatusChipAssets = (status: DeviceStatus) => {
  switch (status) {
    case DeviceStatus.Ok:
      return { color: MuiStatusColors.Success, icon: <CheckCircleIcon /> };
    case DeviceStatus.Installed:
    case DeviceStatus.Allocated:
    case DeviceStatus.DisabledNonPayment:
    case DeviceStatus.DisabledOther:
    case DeviceStatus.PreventativeMaintenance:
    case DeviceStatus.RepairedDevice:
    case DeviceStatus.RepairedEquipment:
      return { color: MuiStatusColors.Warning, icon: <ErrorIcon /> };
    case DeviceStatus.Inventory:
    case DeviceStatus.Unknown:
    case DeviceStatus.Factory:
    case DeviceStatus.AwaitingRecycling:
    case DeviceStatus.REMOVED:
    case DeviceStatus.DISPOSED:
      return { color: MuiStatusColors.Default, icon: <InventoryIcon /> };
    case DeviceStatus.FailedEquipment:
      return { color: MuiStatusColors.Error, icon: <WarningIcon /> };
    case DeviceStatus.FailedDevice:
      return { color: MuiStatusColors.Primary, icon: <ReportIcon /> };
    default:
      return { color: MuiStatusColors.Info, icon: <CircleIcon /> };
  }
};

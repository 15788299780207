import styled from 'styled-components';

export const ActionCellContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .action-cell-content {
    &__block {
      display: flex;
    }
  }
`;

import React from 'react';

import styled from 'styled-components';

const TestResult = styled.span<{ status: string }>`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  /* padding: .5rem 1rem; */
  color: ${(props) => props.theme.white};
  border-radius: 20px;
  margin-left: 0.5rem;
  background-color: ${(props) => props.theme.resultColor(props.status)};
`;

const TestResultIndicator = ({ result }) => <TestResult status={result} />;

export default TestResultIndicator;

import { timeFormat } from 'd3-time-format';
import * as allCurves from '@visx/curve';

export const formatDateTime = timeFormat('%d %b %H:%M');

export const paddingY = 30;
export const paddingX = 5;
export const lineThickness = 15;

type CurveType = keyof typeof allCurves;
export const curveType: CurveType = 'curveLinear';

import { ConnectedDevice } from 'types/BoilerTypes/BcmConnectDetails';

export type DeviceActionIds = 'none' | 'uninstall' | 'completeInstall';

export interface DeviceAction {
  id: DeviceActionIds;
  label: string;
  shouldDisplay(device: ConnectedDevice): boolean;
}

export const deviceActions: DeviceAction[] = [
  {
    id: 'uninstall',
    label: 'Uninstall',
    shouldDisplay: ({ deviceId, userHasReadOnlyAccess }) =>
      deviceId !== '00000000-0000-0000-0000-000000000000' && !userHasReadOnlyAccess,
  },
  {
    id: 'completeInstall',
    label: 'Complete Install',
    shouldDisplay: ({ deviceType, status }) =>
      (deviceType === 'PowerMeter' || deviceType === 'MultiDot' || deviceType === 'EnviroDot') &&
      status === 'Allocated',
  },
];

import styled from 'styled-components';

export const MobileNumberFieldWrapper = styled.div`
  .phone-number-field {
    background-color: #f7f7f7;
    padding-right: 5px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 20px;
    display: flex;

    @media (min-width: 768px) {
      padding-right: 10px;
    }

    &__select {
      @media (max-width: 768px) {
        font-size: 1.44rem;

        .MuiSelect-select {
          padding-left: 8px;
          padding-right: 16px !important;
        }

        .MuiSvgIcon-root {
          right: 3px;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
        outline: none;
      }
    }

    &__input {
      @media (max-width: 768px) {
        font-size: 1.44rem;
        width: 95px;

        &-end-icon {
          margin: 5px;
        }
      }

      @media (min-width: 768px) {
        width: 140px;
      }
    }

    &--error {
      border: 1px solid ${(props) => props.theme.error};
    }

    &--valid {
      border: 1px solid ${(props) => props.theme.success};
    }
  }
`;

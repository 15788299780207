import { useSelector } from 'react-redux';
import { selectIsLoading } from 'reducers/loading/selectors';

type Action =
  | 'FETCH_BUILDINGINFO'
  | 'UPDATE_FLOORPOS'
  | 'FETCH_BUILDINGUPDATE'
  | 'UPDATE_BUILDING'
  | 'FETCH_DEBUGINFO'
  | 'FETCH_BOILERDETAILS'
  | 'TOGGLE_CHSETPOINT'
  | 'TOGGLE_BUZZER'
  | 'UPDATE_CHSETPOINT'
  | 'UPDATE_ADDRESS'
  | 'UPDATE_OCCUPIERID'
  | 'FETCH_BOILERFAULTS'
  | 'FETCH_BOILERMETRICS'
  | 'CLEAR_DEVICE'
  | 'FETCH_MESSAGES'
  | 'FETCH_DEBUGINFO'
  | 'ADD_DEVICETAG'
  | 'REMOVE_DEVICETAG'
  | 'FETCH_EMEREDDETAILS'
  | 'FETCH_EMEREDTESTS'
  | 'UPDATE_TEST'
  | 'FETCH_NETWORKDEVICE'
  | 'FETCH_FIELDS'
  | 'CLEAR_FIELDS'
  | 'FETCH_FORMS'
  | 'FETCH_GROUPS'
  | 'FETCH_NOTES'
  | 'FETCH_REPAIRFORMS'
  | 'ALERT_REQUEST'
  | 'FETCH_TENANTINFO'
  | 'FETCH_TESTSCHEDULE'
  | 'FETCH_SUMMARY'
  | 'FETCH_TENANTEQUIPMENT'
  | 'FETCH_TENANTEMEREDS'
  | 'FETCH_TENANTS'
  | 'FETCH_ALLTESTS'
  | 'FETCH_VIEWDATA'
  | 'FETCH_SHOULDUPDATE'
  | 'REFRESH_VIEWDATA'
  | 'FETCH_VIEWDEFINITIONS'
  | 'ADD_VIEWDEFINITION'
  | 'UPDATE_VIEWDEFINITION'
  | 'DELETE_VIEWDEFINITION'
  | 'FETCH_BUILDING_SUMMARY'
  | 'FETCH_BUILDINGINFO_REQUEST';

const useIsLoading = (actions: Action[]) => {
  const isLoading = useSelector((state: any) => selectIsLoading(actions)(state));

  return isLoading;
};

export default useIsLoading;

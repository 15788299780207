import styled from 'styled-components';

export const CustomSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.1rem;

  .custom-switch {
    &__text {
      font-size: 1.44rem;
      font-weight: 600;
      line-height: 1.5;
      color: ${(props) => props.theme.notDarkGrey};

      &.active {
        color: #0187b5;
      }
    }
  }
`;

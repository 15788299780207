import styled from 'styled-components';

export const ActionsList = styled.ul`
  ${({ theme }) => theme.wrapper};
  position: absolute;
  right: 0;
  top: 3.5rem;
  width: 20rem;
  z-index: 2;
  font-size: 1.4rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.grey};

  &.open {
    opacity: 1;
    visibility: visible;
  }

  & li {
    padding: 0.5rem 1rem;
    background-color: ${(props) => props.theme.white};
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #ececf7;
      cursor: pointer;
    }
  }
`;

import generateUUID from 'utils/uuid';

import ToastStatusEnum from '../../types/ToastStatusEnum';
import IAction from '../IAction';
import { IToast } from './IToastState';

const ADD_TOAST = 'ADD_TOAST';
const REMOVE_TOAST = 'REMOVE_TOAST';

export const toastTypes = {
  ADD_TOAST,
  REMOVE_TOAST,
};

export const addToast = (
  message: string,
  type: ToastStatusEnum,
  dismissalRequired: boolean = false
): IAction<IToast> => ({
  type: ADD_TOAST,
  payload: {
    type,
    message,
    dismissalRequired,
    id: generateUUID(),
  },
});

export const removeToast = (id: string): IAction<IToast> => ({
  type: REMOVE_TOAST,
  payload: { id },
});

import { addToast } from 'reducers/toasts/ToastActions';
import ToastStatusEnum from 'types/ToastStatusEnum';

import { handleReduxApiReq } from './ApiRequestHelpers';

const defaultMsg = 'Failed to update device, please try again';
export const createAction = (type: string, payload: any = undefined) => ({ type, payload });

export const createThunkEffect = async (dispatch, actionType, url, reqBody, isRefresh = false) => {
  dispatch(createAction(actionType + (isRefresh ? '_REFRESH' : '_REQUEST')));
  try {
    const data = await handleReduxApiReq(url, reqBody);
    dispatch(createAction(`${actionType}_SUCCESS`, data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error: ', e);
    dispatch(createAction(`${actionType}_ERROR`, { error: e }));
  }
};

const checkFailureMessage = (message: string, defaultMsg: string): string => {
  if (message) {
    if (message.includes('OccupierId')) {
      return message;
    } else {
      return defaultMsg;
    }
  } else {
    return defaultMsg;
  }
};

export const createThunkWithFailure = async (
  dispatch,
  actionType,
  url,
  reqBody,
  failMsg = defaultMsg
) => {
  dispatch(createAction(`${actionType}_REQUEST`));
  try {
    const res = await handleReduxApiReq(url, reqBody);
    if (res.isSuccessful) {
      dispatch(createAction(`${actionType}_SUCCESS`, res.data));
    } else {
      dispatch(
        createAction(`${actionType}_FAILURE`, {
          message: res.reason ? res.reason : checkFailureMessage(res.message, failMsg),
        })
      );
      dispatch(
        addToast(
          res.reason ? res.reason : checkFailureMessage(res.message, failMsg),
          ToastStatusEnum.Error
        )
      );
    }
  } catch (e) {
    dispatch(createAction(`${actionType}_ERROR`, { error: e }));
  }
};

import React from 'react';

import styled from 'styled-components';

const BuildingMarkerWrapper = styled.div<{ selected: boolean; isZoomed: boolean }>`
  width: ${(props) => (props.isZoomed ? 6 : 4)}rem;
  height: ${(props) => (props.isZoomed ? 6 : 4)}rem;
  position: absolute;
  left: ${(props) => (props.isZoomed ? -3 : -2)}rem;
  top: ${(props) => (props.isZoomed ? -3 : -2)}rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.primaryLight};
  z-index: 8;
  padding: ${(props) => (props.isZoomed ? 1 : 0.75)}rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &.active {
    background-color: ${(props) => props.theme.primary};
   z-index: 11;
  }

  & svg {
    fill: ${(props) => props.theme.darkGreen};
    width: 100%;
    height: 100%;
  }

  & div {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    position: absolute;
    background-color: ${(props) => props.theme.primary};
    font-size: ${(props) => props.theme.textSmaller};
    color: ${(props) => props.theme.white};
    text-align: center;
    width: 12rem;
    padding: 0.5rem 0.25rem;
    margin-top: 0.5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 14;
    visibility: visible;
  }

  & div.hidden {
    opacity: 0;
    visibility: hidden;
  }
`;

const BuildingMarker = ({ $hover, handleOnClick, selected, zoom, buildingName }) => {
  const isZoomed = zoom > 17;

  return (
    <BuildingMarkerWrapper
      onClick={handleOnClick}
      selected={selected}
      className={$hover ? 'active' : ''}
      isZoomed={isZoomed}
    >
      <svg>
        <use href={'images/icon-building.svg#icon-building'} />
      </svg>
      <div className={$hover ? '' : 'hidden'}>
        <p>{buildingName}</p>
      </div>
    </BuildingMarkerWrapper>
  );
};

export default BuildingMarker;

import React from 'react';
import { timeFormat } from 'd3-time-format';
import moment from 'moment';

import { EventProps } from 'types/Telemetry';
import { OngoingText } from '../EventChart.styles';

const formatDateTime = timeFormat('%d %b %H:%M');
const formatTime = timeFormat('%H:%M');

export const formatEventDescriptionDates = (event: EventProps) => {
  const innerStart = new Date(event.start);
  const innerEnd = new Date(event.end);

  const eventStartDate = moment(innerStart.setSeconds(0));
  const eventEndDate = moment(innerEnd.setSeconds(0));

  if (eventEndDate.isAfter(eventStartDate)) {
    if (eventEndDate.isAfter(moment(new Date()))) {
      return (
        <>
          {`${formatDateTime(innerStart)} - `}
          <OngoingText>ongoing</OngoingText>
        </>
      );
    } else {
      if (eventEndDate.diff(eventStartDate, 'days') > 0) {
        return <>{`${formatDateTime(innerStart)} - ${formatDateTime(innerEnd)}`}</>;
      } else {
        return <>{`${formatDateTime(innerStart)} - ${formatTime(innerEnd)}`}</>;
      }
    }
  } else {
    return <>{formatDateTime(innerStart)}</>;
  }
};

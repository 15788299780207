import React, { useMemo, useState } from 'react';

import { handleCsvDownload } from 'components/atoms/CsvLink';
import { BulkActions } from 'components/DeviceActions';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoading } from 'reducers/loading/selectors';
import { setMapData } from 'reducers/mapData';
import {
  ViewDefinition,
  updateViewDefinition,
  addViewDefinition,
  selectSelectedView,
  ListingColumn,
} from 'reducers/viewDefinitions';
import styled from 'styled-components';
import { Field, Filter, FilterDisplay } from 'types/Listing';
import { Areas } from 'types/roles';
import { sortObjByProp } from 'utils/helpers';
import useNavigate, { Page } from 'utils/hooks/useNavigate';
import useRowSelect from 'utils/hooks/useRowSelect';
import useSortSearchAndPage from 'utils/hooks/useSortSearchAndPage';

import Actions, { Action } from './Actions';
import ColumnSelection from './ColumnSelect';
import Header from './Header';
import ListingTable from './ListingTable';
import ManageView from './ManageView';
import SaveAsModal from './SaveAsModal';
import TableFilters from './TableFilters';
import TableFooter from './TableFooter';
import TableInfo from './TableInfo';

const ViewWrapper = styled.div`
  padding: 2rem 1rem;
  font-size: 1.6rem;
`;

const TableWrapper = styled.div`
  font-size: 1.6rem;
  background: ${({ theme }) => theme.white};
  min-height: 20vh;
  padding: 1rem;
  margin: 2.5rem -1rem;
  border-radius: 5px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
`;

interface ListingViewProps {
  availableViews: ViewDefinition[];
  availableFields: Field[];
  route: Page;
  rowAction: any;
  showActions?: boolean;
  refreshViewData: () => void;
  updateSelectedView: (newView: ViewDefinition) => void;
  updateFilters: (filters: Filter[]) => void;
  updateViewColumns: (columns: ListingColumn[]) => void;
}

const pageToArea = (page: Page): Areas => {
  switch (page) {
    case 'EmergencyLights':
      return Areas.lighting;
    case 'Boilers':
      return Areas.boilers;
    case 'NetworkingDevices':
      return Areas.networking;
    default:
      return Areas.misc;
  }
};

const ListingView = ({
  availableViews,
  availableFields,
  rowAction,
  route,
  showActions = true,
  refreshViewData,
  updateFilters,
  updateViewColumns,
}: ListingViewProps) => {
  const dispatch = useDispatch();
  const isFetching = useSelector((state: any) => selectIsLoading(['FETCH_VIEWDATA'])(state));
  const data = useSelector((state: any) => state.viewData.data);
  const selectedView: ViewDefinition = useSelector((state: any) => selectSelectedView(state));

  const { sortInfo, sortFunctions } = useSortSearchAndPage(data, selectedView);
  const { updateSearch, updateSortProp, updateCurrentPage } = sortFunctions;
  const { searchTerm, sortProp, pageInfo, viewData, currentPage } = sortInfo;

  const viewDataMemoised = useMemo(() => viewData, [viewData]);

  const { selectedRowInfo, selectedRowFunctions } = useRowSelect(data, viewDataMemoised);
  const { selectedRows, selected, pageSelected } = selectedRowInfo;
  const { toggleSelectedRow, selectAllRows, selectPageRows, clearAllRows, clearPageRows } =
    selectedRowFunctions;

  const [hasChanged, setHasChanged] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [showSaveAs, setShowSaveAs] = useState(false);

  const { goTo } = useNavigate();

  const handleColumnApply = (columns: ListingColumn[]) => {
    setHasChanged(true);
    updateViewColumns(columns);
    setShowColumns(false);
  };

  const onSaveClick = () => {
    dispatch(updateViewDefinition(selectedView));
    setHasChanged(false);
  };

  const onSaveAsClick = async ({ name, keepAuthors, makePublic }) => {
    const newView = {
      ...selectedView,
      label: name,
      authors: keepAuthors ? selectedView.authors : [],
      private: !makePublic,
      viewId: '',
    };
    await dispatch(addViewDefinition(newView));
    setHasChanged(false);
  };

  const isThirdParty = selectedView.root === 'thirdParty';

  const columns = selectedView?.columns.map((c) => {
    const column = availableFields.find((f) => f.fieldId === c);

    return {
      key: c,
      label: column?.label,
      sortable: column?.sortable,
      dataType: column?.dataType,
    };
  });

  const filters: FilterDisplay[] | [] = selectedView?.filters
    .filter((f) => availableFields.find((x) => x.fieldId === f.fieldId))
    .map((f) => {
      const field = availableFields.find((x) => x.fieldId === f.fieldId);

      return {
        ...f,
        dataType: field!.dataType,
        label: field!.label,
      };
    });

  const handleColumnSort = (key, sortable) => {
    if (sortable) updateSortProp(key);
  };

  const handleMapClick = () => {
    if (!isFetching) {
      dispatch(
        setMapData(
          data.reduce(
            (acc, device) =>
              device.deviceGeoLatitude
                ? [
                  ...acc,
                  {
                    lat: device.deviceGeoLatitude,
                    lng: device.deviceGeoLongitude,
                    deviceId: device.deviceId,
                    deviceName: device.deviceDisplayName,
                    serialNumber: device.deviceSerial,
                    deviceType: device.deviceProductId,
                    deviceStatus: device.deviceStatus,
                    connectionStatus: device.deviceConnectionState,
                    floor: device.deviceBuildingFloor,
                    building: device.deviceBuildingId,
                  },
                ]
                : acc,
            []
          )
        )
      );
      goTo('FloorPlan');
    }
  };

  const downloadClick = (e) => {
    handleCsvDownload(
      e,
      () => data,
      columns,
      ',',
      '"',
      true,
      `${selectedView.label.split(' ').join()}.csv`,
      isFetching
    );
  };

  const selectedDeviceIds = [...[...selectedRows].map((el) => el[0])];

  const selectedDevices = data.filter((row) =>
    Array.from(selectedRows).find((sRow) => sRow[0] === row.deviceId && !!sRow[1])
  );
  const selectedDevicesStatuses = selectedDevices.map((device) => device.deviceStatus);
  const selectedDevicesDetails = selectedDevices.map((device) => ({
    serialNumber: device.deviceSerial,
    address: device.deviceBuildingAddress,
    deviceId: device.deviceId,
  }));

  return (
    <>
      {selectedView && (
        <>
          <ViewWrapper>
            <Header
              selectedView={selectedView}
              availableViews={availableViews.sort(sortObjByProp('label'))}
              handleMapClick={handleMapClick}
              isDisabled={isFetching}
              handleUpdate={() => {
                refreshViewData();
              }}
              route={route}
            />
            <TableWrapper>
              <Actions
                searchProps={{
                  searchTerm: searchTerm,
                  updateSearchTerm: (str) => updateSearch(str),
                }}
                actions={[
                  <Action
                    key="Edit Columns"
                    onClick={() => setShowColumns(true)}
                    isDisabled={isFetching}
                  >
                    Edit Columns
                  </Action>,
                  <Action
                    key="Save"
                    onClick={onSaveClick}
                    isDisabled={isFetching || !hasChanged || selectedView.builtIn}
                  >
                    Save
                  </Action>,
                  <Action
                    key="Save As"
                    onClick={() => setShowSaveAs(true)}
                    isDisabled={isFetching || isThirdParty}
                  >
                    Save As
                  </Action>,
                  <Action key="Download CSV" onClick={downloadClick} isDisabled={isFetching}>
                    Download CSV
                  </Action>,
                  <ManageView
                    key="ManageView"
                    isDisabled={isFetching || selectedView.builtIn}
                    view={selectedView}
                    route={route}
                  />,
                  <BulkActions
                    key="BulkActions"
                    deviceIds={selectedDeviceIds}
                    deviceDetails={selectedDevicesDetails}
                    selectedDevicesStatuses={selectedDevicesStatuses}
                    isDisabled={!showActions || isFetching || selected === 0}
                    refreshView={() => {
                      refreshViewData();
                    }}
                    area={pageToArea(route)}
                  />,
                ]}
              />

              <TableFilters
                filterProps={{
                  filters: filters,
                  fields: availableFields.filter((f) => f.viewFilterable),
                  isFetching: isFetching,
                  updateFilters: (filters) => {
                    updateFilters(filters);
                    setHasChanged(true);
                  },
                }}
              />

              <TableInfo
                selected={selected}
                total={pageInfo.total}
                selectAllRows={selectAllRows}
                clearAllRows={clearAllRows}
              />

              {
                <ListingTable
                  rowAction={rowAction}
                  isFetching={isFetching}
                  viewId={selectedView.viewId}
                  data={viewDataMemoised}
                  columns={columns}
                  handleColumnSort={handleColumnSort}
                  sortProp={sortProp}
                  toggleSelectedRow={toggleSelectedRow}
                  selectedRows={selectedRows}
                  selectAllRows={selectAllRows}
                  selectPageRows={selectPageRows}
                  clearAllRows={clearAllRows}
                  pageSelected={pageSelected}
                  clearPageRows={clearPageRows}
                  refreshView={() => {
                    refreshViewData();
                  }}
                  showActions={showActions}
                />
              }
              <TableFooter pageInfo={{ ...pageInfo, updateCurrentPage, currentPage }} />
            </TableWrapper>
          </ViewWrapper>
          <ColumnSelection
            availableFields={availableFields.map((f) => ({ label: f.label, id: f.fieldId }))}
            selectedFields={selectedView?.columns.map((c) => ({
              id: c,
              label: availableFields.find((f) => f.fieldId === c)?.label,
            }))}
            showModal={showColumns}
            handleClose={() => setShowColumns(false)}
            handleApply={handleColumnApply}
          />
          <SaveAsModal
            showModal={showSaveAs}
            handleClose={() => setShowSaveAs(false)}
            handleSave={onSaveAsClick}
          />
        </>
      )}
    </>
  );
};

export default ListingView;

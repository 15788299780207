import styled from 'styled-components';

export const DeviceAccessRightsTagWrapper = styled.div`
  h5 {
    font-size: 1.5rem;
  }

  .device-access-rights-tag {
    &__content {
      display: flex;
      gap: 24px;
      align-items: center;
      min-height: calc(2.5rem + 16px);

      &-select {
        display: flex;
        align-items: center;
        gap: 16px;

        &-close {
          &-icon {
            transform: rotate(45deg);
            width: 2rem;
            height: 2rem;
            color: ${(props) => props.theme.error};
          }
        }
      }
      &-buttons {
        display: flex;
        gap: 5px;

        &-edit,
        &-set,
        &-remove {
          svg {
            width: 2.5rem;
            height: 2.5rem;
          }
        }

        &-edit {
          fill: ${(props) => props.theme.darkBlue};
        }

        &-set {
          fill: ${(props) => props.theme.darkGreen};
        }

        &-remove {
          color: ${(props) => props.theme.error};
        }
      }
    }
  }

  &.device-access-rights-tag--limited {
    .device-access-rights-tag__name {
      flex-grow: 1;
    }
  }
`;

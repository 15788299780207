import { DeviceStatus } from 'types/BoilerTypes/BcmConnectDetails';

export enum DeviceTypes {
  EmergencyLighting = 'EM',
  EmergencyLightingLegacy = 'EL',
  Boiler = 'BC',
  BcmConnect = 'BX',
  AirSourceHeatPump = 'ASHP',
  LiftMonitor = 'LM',
  PeopleCounter = 'PC',
  MqttGateway = 'MG',
  ZigbeeGateway = 'ZG',
  ZigbeeExt = 'ZR',
  ZigbeeAutofill = 'AZ',
  Autofill = 'AF',
  MultiDot = 'MD',
  EnviroDot = 'ED',
  HeatInterfaceUnit = 'HIU',
  PowerMeter = 'PM',
  Adey = 'ADEY',
}

export interface Device {
  deviceId: string;
  deviceName: string;
  serialNumber: string;
  deviceType: string;
  address: string;
  building: string;
  floor: string;
  room: string;
  deviceStatus: DeviceStatus;
  long: number;
  lat: number;
  group: string;
  buildingName: string;
  connectionStatus: string;
  tags: string[];
  location?: string;
  inWholesale: boolean;
  accessRightsTag: string;
  userHasReadOnlyAccess: boolean;
  userCanUpdateAccessRightsTag: boolean;
  propertyId?: string;
}

export const batteryTypes = [
  'Alkaline',
  'Lead Acid (Pb-Ac)',
  'Lithium Ion (Li-ion)',
  'Lithium Polymer (Li-Po)',
  'Nickel Cadmium (Ni-Cd)',
  'Nickel Metal Hydride (Ni-MH)',
  'Zinc Air',
];

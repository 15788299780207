import React from 'react';

import styled from 'styled-components';

import FormsTable from '../FormsTable';

const Wrapper = styled.div`
  padding-bottom: 2rem;

  & p,
  & input,
  & textarea,
  label {
    font-size: ${(props) => props.theme.textSmall};
    margin-bottom: 2rem;
  }

  & h3 {
    font-weight: 500;
  }
`;

const Header = styled.div`
  padding: 2rem;
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.white};
`;

const FormsWrapper = styled.div`
  max-height: 70rem;
  overflow-y: auto;
  margin-top: 1.5rem;
  padding: 0px 1rem;
`;

const DeviceLogs = ({ device }) => (
  <Wrapper>
    <Header>
      <h3>Logs</h3>
    </Header>
    <FormsWrapper>
      <FormsTable {...device} type={'all'} />
    </FormsWrapper>
  </Wrapper>
);

export default DeviceLogs;

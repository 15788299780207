import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  mapDeviceTypes,
  mapStatus,
  mapDeviceTypesToUrl,
  mapConnectionStatus,
} from 'utils/deviceUtils';
import { useSelectedTenant } from 'utils/hooks';

const DeviceMarkerWrapper = styled.div<{ status: string }>`
  width: 3rem;
  height: 3rem;
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  border-radius: 50%;
  background-color: 'transparent';
  z-index: 8;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &.active {
    background-color: ${(props) => props.theme.primary};
   z-index: 11;
  }

  & svg {
    fill: ${(props) => props.theme.statusColor(props.status)};
    width: 100%;
    height: 100%;
  }

  & div {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    position: absolute;
    background-color: ${(props) => props.theme.primary};
    font-size: ${(props) => props.theme.textSmaller};
    color: ${(props) => props.theme.white};
    text-align: center;
    width: 20rem;
    padding: 0.5rem 0.25rem;
    margin-top: 0.5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 14;
    visibility: visible;
  }

  & div.hidden {
    opacity: 0;
    visibility: hidden;
  }
`;

const DeviceMarker = ({ device, $hover, selected, selectedStatus, isEdit = false }) => {
  const tenant = useSelectedTenant();
  const icon = mapDeviceTypes(device.deviceType);
  const status =
    selectedStatus === 'device'
      ? mapStatus(device.deviceStatus)
      : mapConnectionStatus(device.connectionStatus);
  if (isEdit) {
    return (
      <DeviceMarkerWrapper className={selected || $hover ? 'active' : ''} status={status}>
        <svg>
          <use xlinkHref={`images/icon-${icon}.svg#icon-${icon}`} />
        </svg>
      </DeviceMarkerWrapper>
    );
  }

  return (
    <DeviceMarkerWrapper className={selected || $hover ? 'active' : ''} status={status}>
      <Link to={`/${tenant}/${mapDeviceTypesToUrl(device.deviceType)}/${device.deviceId}`}>
        <svg>
          <use xlinkHref={`images/icon-${icon}.svg#icon-${icon}`} />
        </svg>
      </Link>
      <div className={$hover ? '' : 'hidden'}>
        <p>Device Name: {device.deviceName}</p>
      </div>
    </DeviceMarkerWrapper>
  );
};

const mapStateToProps = ({ filters }) => ({
  selectedStatus: filters.selectedStatus,
});

export default connect(mapStateToProps)(DeviceMarker);

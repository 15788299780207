import React, { useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';

const BuildingMarkerWrapper = styled.div`
  width: 6rem;
  height: 6rem;
  position: absolute;
  left: -3rem;
  top: -3rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.primaryLight};
  z-index: 8;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &.active {
    background-color: ${(props) => props.theme.primary};
   z-index: 11;
  }

  & svg {
    fill: ${(props) => props.theme.darkGreen};
    width: 100%;
    height: 100%;
  }
`;
const DraggableBuildingMarker = ({ setDrag, calcLatLong, lat, lng, mapSettings }) => {
  const markerRef = useRef<HTMLDivElement>(null);
  let initX = 0,
    initY = 0;
  let isRepos = false;
  let offset = 0;

  const onTouchStart = (e) => {
    setDrag(false);
    isRepos = true;
    initX = e.touches[0].clientX;
    initY = e.touches[0].clientY;
    offset = markerRef.current!.style.left === '0px' ? 0 : markerRef.current!.clientWidth / 2;
  };

  const onTouchEnd = () => {
    setDrag(true);
    if (isRepos) {
      isRepos = false;
      calcLatLong(
        parseInt(markerRef.current!.style.left!.slice(0, -2)),
        parseInt(markerRef.current!.style.top!.slice(0, -2)),
        markerRef.current!.clientWidth / 2
      );
      markerRef.current!.style.left = '';
      markerRef.current!.style.top = '';
    }
  };

  const onTouchMove = (e) => {
    if (isRepos) {
      markerRef.current!.style.left = `${e.touches[0].clientX - initX - offset}px`;
      markerRef.current!.style.top = `${e.touches[0].clientY - initY - offset}px`;
    }
  };

  useEffect(() => {
    document.addEventListener('touchend', onTouchEnd);
    document.addEventListener('touchmove', onTouchMove);

    return () => {
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchmove', onTouchMove);
    };

    //TODO REFACTOR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, mapSettings]);

  return (
    <BuildingMarkerWrapper
      className={'active'}
      ref={markerRef}
      onTouchStart={(e) => onTouchStart(e)}
    >
      <svg>
        <use href={'images/icon-building.svg#icon-building'} />
      </svg>
    </BuildingMarkerWrapper>
  );
};

const mapStateToProps = ({ filters }) => ({
  selectedStatus: filters.selectedStatus,
});

export default connect(mapStateToProps)(DraggableBuildingMarker);

import React, { useState } from 'react';

import LoadingOverlay from 'components/atoms/LoadingOverlay';
import ResultsGraph from 'components/elements/Graphs/ResultsGraph';
import Modal from 'components/elements/Modal';
import Sparkline from 'components/elements/Sparkline';
import UpdateTestModal from 'components/elements/UpdateTestModal';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllEmeredTests } from 'reducers/device/emeredActions';
import { selectIsLoading } from 'reducers/loading/selectors';
import styled from 'styled-components';
import { handleFormReq } from 'utils/ApiRequestHelpers';
import { formatDate } from 'utils/helpers';
import { GraphDataType, TestDataType } from 'components/Tables/TestResultsTable/types';
import useAccess from 'utils/hooks/useAccess';

import { Areas } from '../../types/roles';

const TableWrapper = styled.div<{ isFetching: boolean }>`
  max-height: 50rem;
  overflow-y: auto;
  filter: blur(${(props) => (props.isFetching ? '10px' : '0px')});
`;

const Table = styled.table`
  ${(props) => props.theme.tableStyle};

  & tr:hover {
    cursor: default;
  }
`;

const GraphInfo = styled.div`
  font-size: ${(props) => props.theme.textSmall};

  & div {
    margin-bottom: 0.5rem;
  }

  & span {
    color: ${(props) => props.theme.darkBlue};
  }
`;

const TestButton = styled.span`
  display: inline-block;
  padding: 0.25rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;

  & svg {
    width: 100%;
    height: 100%;
    fill: #666;
  }
`;

const TestResults = ({ device }) => {
  const { isDev } = useAccess(Areas.lighting);
  const dispatch = useDispatch();

  const tests = useSelector((state: any) => state.device.testResults);
  const isFetching = useSelector((state: any) => selectIsLoading(['FETCH_EMEREDTESTS'])(state));

  const [graphData, setGraphData] = useState<GraphDataType>();
  const [showModal, setShowModal] = useState(false);
  const [testData, setTestData] = useState<TestDataType>();
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleCancel = (e) => {
    e.preventDefault();
    setShowUpdateModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    if (data.get('reason') === '') {
      data.set('reason', '-');
    }

    if (testData) {
      data.append('deviceId', testData.deviceId);
    }
    await handleFormReq('UpdateTestResult', data, 'POST');
    await dispatch(fetchAllEmeredTests(device.deviceId));
    setShowUpdateModal(false);
  };

  const handleSparkClick = (data) => {
    setGraphData(data);
    setShowModal(true);
  };

  const getDeviceTest = (test, isAdmin = false) => ({
    hasData: true,
    testDate: test.testDate,
    deviceBatteryVoltage: test.deviceBatteryVoltage,
    deviceCurrentStatus: test.deviceCurrentStatus,
    testType: test.testType,
    deviceName: test.deviceName,
    xAxis: {
      name: 'Time in seconds',
      data: test.time,
    },
    series: Object.keys(test.data)
      .map((key) => ({
        name: key,
        data: test.data[key],
      }))
      .slice(0, isAdmin ? undefined : 3),
  });

  const handleEditClick = (testData) => {
    setGraphData(getDeviceTest(testData, true));
    setTestData(testData);
    setShowUpdateModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <section>
        <h5>Test Results</h5>
        <TableWrapper isFetching={isFetching}>
          {isFetching && <LoadingOverlay />}
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Test Type</th>
                <th>Result</th>
                <th>Reason</th>
                <th>Data</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {tests &&
                tests.length > 0 &&
                tests.map((t, i) => (
                  <tr key={i}>
                    <td>{formatDate(t.testDate)}</td>
                    <td>{t.testType}</td>
                    <td>{t.result}</td>
                    <td>{t.reason}</td>
                    <td>
                      <Sparkline handleOnClick={handleSparkClick} info={getDeviceTest(t)} />
                    </td>
                    <td>
                      {isDev && (
                        <TestButton onClick={() => handleEditClick(t)}>
                          <svg>
                            <use href={'images/icons.svg#icon-edit'} />
                          </svg>
                        </TestButton>
                      )}
                    </td>
                  </tr>
                ))}
              {tests.length === 0 && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    No test results
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </TableWrapper>
      </section>

      <Modal handleClose={handleClose} showModal={showModal} title="Device Test Results">
        {graphData && (
          <>
            <GraphInfo>
              <div>
                <span>Tested: </span>
                {formatDate(graphData.testDate)}
              </div>
              <div>
                <span>Test Name: </span>
                {graphData.testType}
              </div>
            </GraphInfo>
            <ResultsGraph
              legendAlignment={'right'}
              data={graphData.series}
              xAxis={graphData.xAxis}
              isAdmin={false}
            />
          </>
        )}
      </Modal>

      <UpdateTestModal
        graphData={graphData}
        testData={testData}
        setShowUpdateModal={setShowUpdateModal}
        showUpdateModal={showUpdateModal}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default TestResults;

import React from 'react';

import Button from 'components/atoms/Button';
import Modal from 'components/elements/Modal';
import ValidatedInput from 'components/elements/ValidatedInput';
import { useDispatch } from 'react-redux';
import { addLuminaire, fetchLuminaires } from 'reducers/standardLuminaires';
import styled from 'styled-components';
import { batteryTypes } from 'types/DeviceTypes/Device';
import { getInputState } from 'utils/helpers';
import useLuminaireForm from 'utils/hooks/useLuminaireForm';

const InputWrapper = styled.div`
  width: 40%;
  margin: 2rem auto;

  && label {
    font-weight: 500;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;

  & > button {
    width: 30%;
    min-width: 10rem;
    max-width: 20rem;
    margin: 0 2rem;
  }
`;

const AddLuminaireModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();

  const { luminaireState, handleLuminaireUpdate, validateSchema } = useLuminaireForm({
    name: '',
    inverterType: '',
    lampType: '',
    batteryType: '',
    batteryVoltage: 0,
    batteryCapacity: 0,
  });

  const { values, errors } = luminaireState;

  const handleSaveClick = async () => {
    if (validateSchema()) {
      await dispatch(addLuminaire({ ...luminaireState.values }));
      await dispatch(fetchLuminaires());
      setShowModal(false);
    }
  };

  return (
    <Modal showModal={showModal} handleClose={() => setShowModal(false)} title="Add Luminaire">
      <InputWrapper>
        <ValidatedInput
          type="text"
          inputData={{
            value: values.name,
            onChange: handleLuminaireUpdate,
            name: 'name',
            placeholder: 'Name',
            state: getInputState(errors.name),
          }}
          label="Name:"
          errMsg={errors.name.msg}
        />
      </InputWrapper>
      <InputWrapper>
        <ValidatedInput
          type="text"
          inputData={{
            value: values.lampType,
            onChange: handleLuminaireUpdate,
            name: 'lampType',
            placeholder: 'Lamp Type',
            state: getInputState(errors.lampType),
          }}
          label="Lamp Type:"
          errMsg={errors.lampType.msg}
        />
      </InputWrapper>
      <InputWrapper>
        <ValidatedInput
          type="text"
          inputData={{
            value: values.inverterType,
            onChange: handleLuminaireUpdate,
            name: 'inverterType',
            placeholder: 'Inverter Type',
            state: getInputState(errors.inverterType),
          }}
          label="Inverter Type:"
          errMsg={errors.inverterType.msg}
        />
      </InputWrapper>
      <InputWrapper>
        <ValidatedInput
          type="dropdown"
          inputData={{
            value: values.batteryType,
            onChange: handleLuminaireUpdate,
            name: 'batteryType',
            placeholder: 'Battery Type',
            state: getInputState(errors.batteryType),
            options: batteryTypes,
          }}
          label="Battery Type:"
          errMsg={errors.batteryType.msg}
        />
      </InputWrapper>
      <InputWrapper>
        <ValidatedInput
          type="text"
          inputData={{
            value: values.batteryVoltage,
            onChange: handleLuminaireUpdate,
            name: 'batteryVoltage',
            placeholder: 'Battery Voltage',
            state: getInputState(errors.batteryVoltage),
          }}
          label="Battery Voltage (V):"
          errMsg={errors.batteryVoltage.msg}
        />
      </InputWrapper>
      <InputWrapper>
        <ValidatedInput
          type="text"
          inputData={{
            value: values.batteryCapacity,
            onChange: handleLuminaireUpdate,
            name: 'batteryCapacity',
            placeholder: 'Battery Capacity',
            state: getInputState(errors.batteryCapacity),
          }}
          label="Battery Capacity (Ah):"
          errMsg={errors.batteryCapacity.msg}
        />
      </InputWrapper>
      <ButtonWrapper>
        <Button type="clear" clickCallback={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button type="submit" clickCallback={handleSaveClick}>
          Save
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default AddLuminaireModal;

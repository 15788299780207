import { createGlobalStyle } from 'styled-components';

import { styleVars } from './variables';
import { styledMuiXDataGridFiltersPanel } from './StyledDataGrid';

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');

*, *::after, *::before{
	margin: 0; 
	padding: 0;
	box-sizing: border-box;
}

html{
	overflow-x: hidden;

	font-size: 62.5%;

	@media ${styleVars.desktopM} {
			font-size: 62.5%;
	}

	@media ${styleVars.tablet} {
			font-size: 56.25%;
	}

	@media ${styleVars.mobileL} {
			font-size: 50%;
	}  

	@media ${styleVars.desktopL} {
			font-size: 75%;
	}

	@media ${styleVars.desktopXL} {
			font-size: 80%;
	}
}

h1,h2,h3,h4,h5,h6{
	font-weight: normal;
}

body{
	font-family: ${styleVars.textFont};
	color: ${styleVars.grey};
	font-size: ${styleVars.textSize};
	font-weight: 400;
	background: ${styleVars.backgroundColor};
	min-height: 100vh;
	/* Styles to prevent MUI dialog add extra padding and scroll to top  */
	overflow: visible !important;
	padding: 0 !important;
}

ul {
	list-style: none;
	padding: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

button {
	border: none;
	outline: none;
	cursor: pointer;
	transition: all .3s ease-in-out;
}
${styledMuiXDataGridFiltersPanel}
`;

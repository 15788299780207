import React from 'react';
import { ConnectedDevice } from 'types/BoilerTypes/BcmConnectDetails';

export const getConnectedDevicesLocation = (device: ConnectedDevice): string | React.ReactNode => {
  if (device.powerClamps && Object.keys(device.powerClamps).length > 0) {
    if (Object.keys(device.powerClamps).length === 1) {
      return device.powerClamps.A;
    } else {
      return (
        <>
          {Object.entries(device.powerClamps).map(([clamp, loc]) => (
            <div key={clamp}>
              Clamp {clamp}: {loc}
            </div>
          ))}
        </>
      );
    }
  }

  return device.location || 'none';
};

import { useDispatch } from 'react-redux';
import { addToast } from 'reducers/toasts/ToastActions';
import ToastStatusEnum from 'types/ToastStatusEnum';

const useAlert = () => {
  const dispatch = useDispatch();

  const errorAlert = (msg, dissmisalRequired = false) =>
    dispatch(addToast(msg, ToastStatusEnum.Error, dissmisalRequired));
  const warningAlert = (msg, dissmisalRequired = false) =>
    dispatch(addToast(msg, ToastStatusEnum.Warning, dissmisalRequired));
  const msgAlert = (msg, dissmisalRequired = false) =>
    dispatch(addToast(msg, ToastStatusEnum.Message, dissmisalRequired));
  const successAlert = (msg, dissmisalRequired = false) =>
    dispatch(addToast(msg, ToastStatusEnum.Success, dissmisalRequired));

  return {
    errorAlert,
    warningAlert,
    msgAlert,
    successAlert,
  };
};

export default useAlert;

import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { RemoveCircleOutline } from '@mui/icons-material';

import SvgIcon from 'components/atoms/SvgIcon';
import { EllipsisWithTooltip } from 'components/atoms';
import { ActionDialog, DeviceAccessRightsTagSelect } from 'components/molecules';
import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { DeviceAccessRightsTagWrapper } from './DeviceAccessRightsTag.styles';

interface DeviceAccessRightsTagsProps {
  accessRightsTags: AccessRightsTag[];
  deviceAccessRightsTag: AccessRightsTag | undefined;
  setDeviceAccessRightsTag: (id: string) => void;
  removeAccessRightsTag: () => void;
  userCanEdit: boolean;
  isError: boolean;
  limited?: boolean;
}

export const DeviceAccessRightsTag: React.FC<DeviceAccessRightsTagsProps> = ({
  limited,
  accessRightsTags,
  deviceAccessRightsTag,
  userCanEdit,
  isError,
  setDeviceAccessRightsTag,
  removeAccessRightsTag,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [localStateTag, setLocalStateTag] = useState(deviceAccessRightsTag);

  const isLoading =
    (localStateTag && deviceAccessRightsTag && localStateTag.id !== deviceAccessRightsTag.id) ||
    (localStateTag && !deviceAccessRightsTag) ||
    (!localStateTag && deviceAccessRightsTag);

  const handleAssignAccessRightsTag = (event) => {
    event.stopPropagation();

    const { target } = event;
    setDeviceAccessRightsTag(target.value);
    setLocalStateTag(accessRightsTags.find((tag) => tag.id === target.value));
    setIsEdit(false);
  };

  const handleRemoveAccessRightsTag = () => {
    removeAccessRightsTag();
    setLocalStateTag(undefined);
    setShowModal(false);
  };

  const handleOpenModal = (event) => {
    event?.stopPropagation();
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);
  const handleCloseEditMode = (event) => {
    event?.stopPropagation();

    setIsEdit(false);
  };
  const handleOpenEditMode = (event) => {
    event?.stopPropagation();

    setIsEdit(true);
  };

  useEffect(() => {
    setLocalStateTag(deviceAccessRightsTag);
  }, [deviceAccessRightsTag]);

  const accessRightsTagsRenderer = (): React.ReactNode => {
    if (isEdit) {
      return (
        <DeviceAccessRightsTagSelect
          handleAssignAccessRightsTag={handleAssignAccessRightsTag}
          accessRightsTags={accessRightsTags}
          handleCloseEditMode={handleCloseEditMode}
          accessRightsTag={localStateTag}
        />
      );
    }

    if (deviceAccessRightsTag) {
      return (
        <span className="device-access-rights-tag__name">
          <EllipsisWithTooltip text={deviceAccessRightsTag.displayName} />
        </span>
      );
    }

    return <span className="device-access-rights-tag__name">No Access Rights Tag</span>;
  };

  return (
    <DeviceAccessRightsTagWrapper
      {...(limited && { className: 'device-access-rights-tag--limited' })}
    >
      {!limited && <h5>Device Access Rights Tag</h5>}
      <div className="device-access-rights-tag">
        <div className="device-access-rights-tag__content">
          {accessRightsTagsRenderer()}
          {!isEdit && userCanEdit && (
            <div className="device-access-rights-tag__content-buttons">
              {isLoading && !isError ? (
                <CircularProgress data-testid="loading-spinner" size={30} />
              ) : (
                <>
                  <Tooltip
                    arrow
                    title={`${deviceAccessRightsTag ? 'Edit' : 'Set'} device access rights tag`}
                    placement="top"
                  >
                    <IconButton
                      className={`device-access-rights-tag__content-buttons-${
                        deviceAccessRightsTag ? 'edit' : 'set'
                      }`}
                      data-testid="device-access-rights-tag__content-buttons"
                      onClick={handleOpenEditMode}
                    >
                      {deviceAccessRightsTag ? <SvgIcon type="edit" /> : <SvgIcon type="plus" />}
                    </IconButton>
                  </Tooltip>
                  {deviceAccessRightsTag && (
                    <Tooltip arrow title="Remove device access rights tag" placement="top">
                      <IconButton
                        className="device-access-rights-tag__content-buttons-remove"
                        aria-label="remove-device-access-rights-tag"
                        onClick={handleOpenModal}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <ActionDialog
        open={showModal}
        onClose={handleCloseModal}
        handleSubmit={handleRemoveAccessRightsTag}
        submitButtonName="Remove"
        submitButtonIcon={<RemoveCircleOutline />}
        title="Remove Device Access Rights Tag"
      >
        Are you sure that you want to remove device access rights tag &quot;
        {deviceAccessRightsTag?.displayName}&quot;
      </ActionDialog>
    </DeviceAccessRightsTagWrapper>
  );
};

import React from 'react';

import { useSelector } from 'react-redux';
import { formatDate } from 'utils/helpers';

import { TableWrapper, Table, Centered } from './Table';

const BoilerFaultHistory = () => {
  const faults = useSelector((state: any) => state.device.faults);

  return (
    <>
      <section>
        <h5>Boiler Fault History</h5>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>Fault Code</th>
                <th>Description</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {faults &&
                faults.length > 0 &&
                faults.map((fault) => (
                  <tr key={fault.timestamp}>
                    <td>{fault.faultCode}</td>
                    <td>{fault.description}</td>
                    <td>{formatDate(fault.timestamp)}</td>
                  </tr>
                ))}
              {(!faults || faults.length === 0) && (
                <tr>
                  <td colSpan={3}>
                    <Centered>No data available</Centered>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </TableWrapper>
      </section>
    </>
  );
};

export default BoilerFaultHistory;

import React from 'react';

import styled from 'styled-components';

const TextAreaWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;

  & textarea {
    ${(props) => props.theme.textAreaStyle('100%', '100%')};
    z-index: 6;
  }
`;

const TextArea = ({ value, onChange, name, placeholder = '', readOnly = false, disabled }) => {
  const handleOnChange = (e) => onChange(e.target.value, e.target.name);

  return (
    <TextAreaWrapper>
      <textarea
        name={name}
        onChange={handleOnChange}
        value={value}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
      />
    </TextAreaWrapper>
  );
};

export default TextArea;

import React from 'react';
import { SwitchProps, Typography } from '@mui/material';

import { StyledSwitch } from 'styles/MuiComponents';
import { CustomSwitchWrapper } from './CustomSwitch.styles';

export const CustomSwitch: React.FC<SwitchProps> = ({ onChange, checked, ...rest }) => (
  <CustomSwitchWrapper>
    <Typography className={`custom-switch__text ${!checked ? 'active' : ''}`}>OFF</Typography>
    <StyledSwitch
      className="custom-switch__switch"
      onChange={onChange}
      checked={checked}
      data-testid="custom-switch"
      {...rest}
    />
    <Typography className={`custom-switch__text ${checked ? 'active' : ''}`}>ON</Typography>
  </CustomSwitchWrapper>
);

import { DeviceTypes } from 'types/DeviceTypes/Device';
import { parseSerialNumber } from './parseSerialNumber';

export const isConnectedDevice = (serialNumber: string) => {
  const { product } = parseSerialNumber(serialNumber);

  return (
    product === DeviceTypes.ZigbeeAutofill ||
    product === DeviceTypes.Autofill ||
    product === DeviceTypes.MultiDot ||
    product === DeviceTypes.PowerMeter ||
    product === DeviceTypes.EnviroDot
  );
};

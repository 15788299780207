import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

import AngleDownIcon from './AngleDownIcon';
import AngleLeftIcon from './AngleLeftIcon';
import AngleRightIcon from './AngleRightIcon';
import CaretDownIcon from './CaretDownIcon';
import CaretUpIcon from './CaretUpIcon';
import CheckIcon from './CheckIcon';
import CloseIcon from './CloseIcon';
import CogIcon from './CogIcon';
import ColumnIcon from './ColumnIcon';
import CopyIcon from './CopyIcon';
import DeleteIcon from './DeleteIcon';
import DownloadIcon from './DownloadIcon';
import EditIcon from './EditIcon';
import ExchangeIcon from './ExchangeIcon';
import KebabIcon from './KebabIcon';
import MinusIcon from './MinusIcon';
import PlusIcon from './PlusIcon';
import SaveAsIcon from './SaveAsIcon';
import SaveIcon from './SaveIcon';
import SearchIcon from './SearchIcon';
import SortDownIcon from './SortDownIcon';
import SortUpIcon from './SortUpIcon';
import SyncIcon from './SyncIcon';
import TrashIcon from './TrashIcon';

export type IconType =
  | 'copy'
  | 'plus'
  | 'trash'
  | 'minus'
  | 'search'
  | 'check'
  | 'close'
  | 'delete'
  | 'caret-up'
  | 'caret-down'
  | 'angle-down'
  | 'angle-left'
  | 'angle-right'
  | 'edit'
  | 'save'
  | 'saveas'
  | 'sync'
  | 'kebab'
  | 'column'
  | 'exchange'
  | 'download'
  | 'setting'
  | 'sortup'
  | 'sortdown';

interface Props {
  type: IconType;
}

const SvgIcon: FunctionComponent<Props> = ({ type }) => (
  <>
    {(() => {
      switch (type) {
        case 'trash':
          return <TrashIcon />;
        case 'plus':
          return <PlusIcon />;
        case 'minus':
          return <MinusIcon />;
        case 'search':
          return <SearchIcon />;
        case 'check':
          return <CheckIcon />;
        case 'close':
          return <CloseIcon />;
        case 'delete':
          return <DeleteIcon />;
        case 'caret-up':
          return <CaretUpIcon />;
        case 'caret-down':
          return <CaretDownIcon />;
        case 'angle-down':
          return <AngleDownIcon />;
        case 'angle-right':
          return <AngleRightIcon />;
        case 'angle-left':
          return <AngleLeftIcon />;
        case 'edit':
          return <EditIcon />;
        case 'save':
          return <SaveIcon />;
        case 'saveas':
          return <SaveAsIcon />;
        case 'copy':
          return <CopyIcon />;
        case 'sync':
          return <SyncIcon />;
        case 'kebab':
          return <KebabIcon />;
        case 'column':
          return <ColumnIcon />;
        case 'exchange':
          return <ExchangeIcon />;
        case 'download':
          return <DownloadIcon />;
        case 'setting':
          return <CogIcon />;
        case 'sortup':
          return <SortUpIcon />;
        case 'sortdown':
          return <SortDownIcon />;
        default:
          return null;
      }
    })()}
  </>
);

interface IconSpanProps {
  type: IconType;
  color: string;
  size: string;
}

const IconHoverWrapper = styled.span<{ size: string; color: string; hoverColor: string }>`
  display: inline-block;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  color: ${(props) => props.color};
  transition: all ease-in-out 0.3s;

  &:hover {
    cursor: pointer;
    color: ${(props) => (props.hoverColor ? props.hoverColor : props.color)};
  }

  & svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;

interface SvgHoverSpanProps extends IconSpanProps {
  hoverColor?: string;
  handleOnClick: any;
}

export const SvgHoverSpan = ({
  type,
  size,
  color,
  hoverColor = '',
  handleOnClick,
}: SvgHoverSpanProps) => (
  <IconHoverWrapper size={size} color={color} hoverColor={hoverColor} onClick={handleOnClick}>
    <SvgIcon type={type} />
  </IconHoverWrapper>
);

export default SvgIcon;

import React, { useEffect, useCallback } from 'react';

import BoilerRecords from 'components/DeviceDetails/BoilerRecords';
import DeviceLogs from 'components/DeviceDetails/DeviceLogs';
import DeviceMessages from 'components/DeviceDetails/DeviceMessages';
import DeviceTimelineViews from 'components/DeviceDetails/DeviceTimelineViews';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchBoilerDetails, refreshBoilerDetails } from 'reducers/device/boilerActions';
import { asStartDate, asEndDate } from 'utils/helpers';
import useShouldUpdateDevice from 'utils/hooks/useShouldUpdateDevice';
import { Device } from 'types/DeviceTypes/Device';
import { BcmConnectDetails } from 'types/BoilerTypes/BcmConnectDetails';

import { FeaturesAreas } from 'types';
import { useAreaFeatures } from 'utils/hooks';
import DeviceDetails from '../../components/DeviceDetails';
import BoilerDetails from '../../components/DeviceDetails/BoilerDetails';
import BoilerFaultHistory from '../../components/DeviceDetails/BoilerFaultHistory';
import BoilerMaintenanceTab from '../../components/DeviceDetails/BoilerMaintenanceTab';
import { ConnectedDevices } from '../../components/DeviceDetails/ConnectedDevices';
import DebugInfo from '../../components/DeviceDetails/DebugInfo';
import { useTabs } from './hooks/useTabs';

const tabsData = [
  {
    tab: 'info',
    display: 'Info',
    component: BoilerDetails,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'boilerFaultHistory',
    display: 'Fault History',
    component: BoilerFaultHistory,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'boilerHistoryLogs',
    display: 'History',
    limitedAccessTab: true,
    component: DeviceLogs,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'boilerMaintenance',
    display: 'Maintenance',
    component: BoilerMaintenanceTab,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'boilerTelemetry',
    display: 'Telemetry',
    component: DeviceTimelineViews,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'complianceRecord',
    display: 'Compliance',
    component: BoilerRecords,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'connectedDevices',
    display: 'Connected Devices',
    component: ConnectedDevices,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'debug',
    display: 'Debug',
    component: DebugInfo,
    access: (authObj) => authObj.isDev,
  },
  {
    tab: 'messages',
    display: 'Messages',
    component: DeviceMessages,
    access: (authObj) => authObj.isDev,
  },
];

const BoilerDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { deviceId }: any = useParams();
  const { device, boilerDetails }: { device: Device; boilerDetails: BcmConnectDetails } =
    useSelector((state: any) => state.device);
  const { HideTelemetryTab } = useAreaFeatures(FeaturesAreas.Boilers).features;

  const tabs = useTabs({ tabsData, device });
  const filteredTabs = tabs.filter(({ display }) =>
    display === 'Telemetry' ? !HideTelemetryTab : true
  );

  const refreshDeviceDetails = useCallback(
    async () =>
      await dispatch(
        refreshBoilerDetails({
          deviceId,
          startDate: asStartDate(new Date()).toISOString(),
          endDate: asEndDate(new Date()).toISOString(),
        })
      ),
    [deviceId, dispatch]
  );

  const triggerUpdateDevice = useShouldUpdateDevice(deviceId, refreshDeviceDetails);

  const stateDeviceId = device?.deviceId;
  useEffect(() => {
    const fetchDeviceDetails = async () => {
      await dispatch(
        fetchBoilerDetails({
          deviceId: deviceId,
          startDate: asStartDate(new Date()).toISOString(),
          endDate: asEndDate(new Date()).toISOString(),
        })
      );
    };
    if (deviceId !== stateDeviceId) fetchDeviceDetails();
  }, [stateDeviceId, deviceId, dispatch]);

  return (
    <>
      <DeviceDetails
        triggerUpdateDevice={triggerUpdateDevice}
        tabs={filteredTabs}
        device={device}
        details={boilerDetails}
      />
    </>
  );
};

export default BoilerDetailsPage;

import { timeFormat } from 'd3-time-format';
import * as allCurves from '@visx/curve';

export const formatDateTime = timeFormat('%d %b %H:%M');
export const paddingX = 5;
export const paddingY = 30;
export const defaultLineColor = '#0088FF';
export const bottomTooltipOffset = 35;
export const topTooltipOffset = -20;

type CurveType = keyof typeof allCurves;
export const curveType: CurveType = 'curveStepAfter';

import styled from 'styled-components';

export const NavIcon = styled.span<{ size: number }>`
  display: inline-block;
  width: ${(props) => props.size}rem;
  height: ${(props) => props.size}rem;
  border-right: 3px solid ${(props) => props.theme.default};
  border-top: 3px solid ${(props) => props.theme.default};
  transform: rotate(45deg);
  cursor: pointer;
`;

import { createSelector } from '@reduxjs/toolkit';

import { useAppSelector } from 'utils/hooks';

import { RootState } from 'reducers';
import { ApiActionNames } from 'reducers/types';
import { GenericState, getReduxApiData } from 'utils/getReduxApiData';

export const useReduxApiData = <T>(
  stateSelector: (state: RootState) => GenericState<T>,
  actionName: ApiActionNames
) => {
  const apiDataSelector = createSelector([stateSelector], (state) =>
    getReduxApiData<T>({ state, actionName })
  );

  return useAppSelector(apiDataSelector);
};

import styled, { css } from 'styled-components';

const fullScreenStyle = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ActionDialogWrapper = styled.div<{ fullScreen?: boolean }>`
  ${(props) => !!props.fullScreen && fullScreenStyle};

  .action-dialog {
    &__content {
      font-size: 18px;
      color: ${(props) => props.theme.grey};
      overflow-wrap: break-word;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      gap: 15px;
      padding: 8px 30px 24px 30px;
      position: sticky;
      bottom: 0;
      background-color: ${(props) => props.theme.white};
      z-index: 1;

      @media (min-width: 768px) {
        gap: 40px;
      }
    }
  }
`;

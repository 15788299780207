import React from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import styled from 'styled-components';

const IconWrapper = styled.span`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;

  &:hover {
    cursor: pointer;
  }

  & svg {
    height: 100%;
    width: 100%;
  }

  &.trash svg {
    fill: ${(props) => props.theme.failed};
  }

  &.close svg,
  &.copy svg {
    fill: ${(props) => props.theme.grey};
  }

  &.edit svg {
    fill: ${(props) => props.theme.blue};
  }

  &.check svg {
    fill: ${(props) => props.theme.darkGreen};
  }
`;

const Icon = ({ type, onClick }) => (
  <IconWrapper className={type} onClick={onClick}>
    <SvgIcon type={type} />
  </IconWrapper>
);

export default Icon;

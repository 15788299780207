import React from 'react';

import CheckList from './CheckList';
import DateField from './DateField';
import DropDownInput from './DropDownInput';
import NumberInput from './NumberInput';
import TextSearch from './TextSearch';

const InputSelector = ({ type, inputData, suggestOnly, filterOp }) => (
  <>
    {(() => {
      if (filterOp === 'anyof') return <CheckList {...inputData} />;
      switch (type) {
        case 'stringarray':
        case 'string':
          return suggestOnly ? <DropDownInput {...inputData} /> : <TextSearch {...inputData} />;
        case 'number':
          return <NumberInput {...inputData} />;
        case 'date':
          return <DateField {...inputData} />;
        case 'bool':
          return (
            <DropDownInput
              {...{
                ...inputData,
                options: ['true', 'false'],
              }}
            />
          );
        default:
          return null;
      }
    })()}
  </>
);

export default InputSelector;

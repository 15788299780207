import styled from 'styled-components';

export const CustomToolbarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }

  .data-grid-toolbar {
    &__left {
      display: flex;
      column-gap: 12px;
      row-gap: 4px;
      flex-wrap: wrap;
      padding-bottom: 12px;

      @media (min-width: 768px) {
        padding-bottom: 0;
      }

      &-download-csv {
        .MuiButtonBase-root {
          font-size: 1.4rem;
          color: ${(props) => props.theme.grey};
          font-weight: 500;
          line-height: 1.75;
        }
      }

      &-filters,
      &-column {
        &-button {
          font-size: 1.4rem;
          color: ${(props) => props.theme.grey};
          font-weight: 500;
          padding: 6px 16px;
          line-height: 3.214;

          @media (min-width: 768px) {
            line-height: initial;
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }

        &-icon {
          font-size: 1.4rem !important;
        }
      }
    }

    &__right {
      display: flex;
      justify-content: center;

      @media (min-width: 768px) {
        justify-content: initial;
      }
    }
  }
`;

import axios from 'axios';
import { getToken } from 'utils/contexts/AuthProvider';

type HandleReduxGetRequestProps = {
  query: string;
  onInit?: () => void;
  onSuccess?: (data) => void;
  onError?: (error) => void;
};

export const handleReduxGetRequest = async ({
  query,
  onInit,
  onSuccess,
  onError,
}: HandleReduxGetRequestProps) => {
  if (onInit) {
    onInit();
  }

  try {
    const token = await getToken();

    const response = await axios.get(`${process.env.REACT_APP_APIURL}${query}`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (error: any) {
    if (onError) {
      onError(error);
    }
  }
};

import React from 'react';

import styled from 'styled-components';
import { Areas } from 'types/roles';
import useAccess from 'utils/hooks/useAccess';

import AddBuildingButton from './AddBuildingButton';
import NoBuildings from './NoBuildings';
import Table from './Table';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 3rem;

  && h1 {
    margin: 0 2rem 0 0;
  }

  && button {
    margin: 0;
    font-weight: 1.6rem;
  }
`;

const BuildingsTable = ({ buildingInfo }) => {
  const { isManager } = useAccess(Areas.misc);
  const { isEdit } = useAccess(Areas.lighting);

  return (
    <>
      {buildingInfo.length ? (
        <>
          <HeaderWrapper>
            <h1>Buildings</h1>
            {(isManager || isEdit) && <AddBuildingButton />}
          </HeaderWrapper>
          <Table buildingInfo={buildingInfo} />
        </>
      ) : (
        <NoBuildings />
      )}
    </>
  );
};

export default BuildingsTable;

import styled from 'styled-components';

export const WidgetContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  grid-auto-rows: 6rem;
  align-items: stretch;
  justify-items: stretch;

  @media ${(props) => props.theme.laptop} {
    width: 45vw;
  }
  @media ${(props) => props.theme.tablet} {
    width: 50vw;
  }
  @media ${(props) => props.theme.tabPort} {
    width: 60vw;
  }
  @media ${(props) => props.theme.mobileL} {
    width: 65vw;
  }
  @media ${(props) => props.theme.mobileM} {
    width: 70vw;
  }
  @media ${(props) => props.theme.mobileS} {
    width: 90vw;
  }

  .managed-services-widget {
    display: flex;
    flex-direction: column;

    .widget-container__content {
      height: unset;
      flex-grow: 1;
    }

    .widget-nav {
      &-title {
        font-size: 1.4rem;

        @media (min-width: 768px) {
          font-size: 1.68rem;
        }
      }

      &-icon {
        border-color: ${(props) => props.theme.purple};
      }
    }
  }
`;

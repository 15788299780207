export interface UserPreferences {
  showMenu: boolean;
  hideNetworkModal: boolean;
}

export type UserPreferenceKey = keyof UserPreferences;

export const initialPreferences: UserPreferences = {
  showMenu: false,
  hideNetworkModal: false,
};

export const UserPreferenceKeys: UserPreferenceKey[] = Object.keys(
  initialPreferences
) as UserPreferenceKey[];

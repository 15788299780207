import styled from 'styled-components';

export const DeviceAccessRightsTagSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .device-access-rights-tag-select {
    &__input {
      width: 220px;

      @media (min-width: 768px) {
        width: 350px;
      }
    }

    &__close {
      &-icon {
        transform: rotate(45deg);
        width: 2rem;
        height: 2rem;
        color: ${(props) => props.theme.error};
      }
    }
  }
`;

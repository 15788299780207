import React from 'react';

import Chart from 'react-apexcharts';
import styled from 'styled-components';

const ChartWrapper = styled.div`
  & .apexcharts-legend.right .apexcharts-legend-series,
  & .apexcharts-legend.left .apexcharts-legend-series {
    display: flex;
  }

  & .chart-text {
    font-family: inherit !important;
    color: ${(props) => props.theme.primary};
  }

  & .chart-title--axis {
    font-weight: 600;
  }
`;
const getMapOptions = (isAdmin, data, horizontalAlign) => ({
  chart: {
    id: 'device test results',
    type: 'area',
    toolbar: {
      show: isAdmin,
    },
  },
  legend: {
    show: true,
    position: 'top',
    horizontalAlign: horizontalAlign,
    fontFamily: 'inherit',
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.25,
      opacityTo: 0.05,
    },
  },
  xaxis: {
    type: 'numeric',
    categories: data,
    axisTicks: {
      show: true,
    },
    labels: {
      show: isAdmin,
      hideOverlappingLabels: false,
      style: {
        cssClass: 'chart-text',
      },
    },
    title: {
      text: '',
      style: {
        cssClass: 'chart-text chart-title--axis',
      },
    },
  },

  yaxis: [
    {
      seriesName: 'Battery',
      show: true,
    },
    {
      seriesName: 'Battery',
      show: false,
    },
    {
      seriesName: 'Battery',
      show: false,
    },
    {
      opposite: true,
      seriesName: 'PLive',
      show: true,
    },
    {
      opposite: true,
      seriesName: 'PLive',
      show: false,
    },
    {
      opposite: true,
      seriesName: 'PLive',
      show: false,
    },
  ],
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: true,
  },

  stroke: {
    width: 2,
  },
  markers: {
    size: 5,
    //colors: "#fff",
    strokeWidth: 3,
    fillOpacity: 1,
    hover: {
      size: undefined,
      sizeOffset: 2,
    },
  },
});

const ResultsGraph = ({ xAxis, legendAlignment, data, isAdmin = false }) => {
  const mapOptions: any = getMapOptions(isAdmin, xAxis.data, legendAlignment);

  return (
    <ChartWrapper>
      <Chart options={mapOptions} series={isAdmin ? data : data.slice(0, 3)} type="area" />
    </ChartWrapper>
  );
};

export default ResultsGraph;

import React, { useState } from 'react';

import { handleApiReq } from 'utils/ApiRequestHelpers';
import { formatDate } from 'utils/helpers';

import useAlert from '../../../utils/hooks/useAlert';
import DeviceFault from '../DeviceFault';
import { ActionButton } from './atoms';

const ResetBcmLockout = ({ deviceId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleResetClick = async () => {
    setIsLoading(true);
    await handleApiReq('BoilerReset', { deviceId });
    setIsLoading(false);
  };

  return <ActionButton label="Reset Lockout" isLoading={isLoading} onClick={handleResetClick} />;
};

const ResetAutofillLockout = ({ deviceId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { successAlert, errorAlert } = useAlert();

  const handleResetClick = async () => {
    setIsLoading(true);
    const response = await handleApiReq('AutofillReset', { deviceId });

    if (response) {
      if (response.isSuccessful) {
        successAlert(response.message, false);
      } else {
        errorAlert(response.message, false);
      }
    }
    setIsLoading(false);
  };

  return (
    <ActionButton label="Reset Autofill Lockout" isLoading={isLoading} onClick={handleResetClick} />
  );
};

const Action = ({ isBoilerLockout, isAutofillLockout, deviceId }) => {
  if (isBoilerLockout) return <ResetBcmLockout deviceId={deviceId} />;
  if (isAutofillLockout) return <ResetAutofillLockout deviceId={deviceId} />;

  return null;
};

const BoilerFault = ({ deviceId, activeFault, isResettable }) => {
  const isBoilerLockout =
    activeFault && isResettable && activeFault.isResettable && activeFault.faultSource === 'boiler';
  const isAutofillLockout = activeFault && activeFault.faultSource === 'autofill';

  const faultDetails = [
    { label: 'Date', text: formatDate(activeFault?.timestamp) },
    { label: 'Description', text: activeFault?.description },
  ];

  return (
    <DeviceFault
      message="The device has not passed a test and has generated an alarm with the following details:"
      faultDetails={faultDetails}
      action={
        <Action
          isAutofillLockout={isAutofillLockout}
          isBoilerLockout={isBoilerLockout}
          deviceId={deviceId}
        />
      }
    />
  );
};

export default BoilerFault;

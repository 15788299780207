import styled from 'styled-components';

export const ResidenceTableMarkDownDialogWrapper = styled('div')`
  .residence-table-markdown-dialog {
    &__top {
      position: sticky;
      top: 0;
      background-color: ${(props) => props.theme.white};
      z-index: 1;
    }

    &__title {
      font-size: 18px;
      color: ${(props) => props.theme.grey};
      padding-right: 64px;

      @media (min-width: 768px) {
        padding-right: 96px;
        font-size: 20px;
      }
    }

    &__content {
      position: relative;
      padding: 24px;
    }

    &__buttons {
      display: flex;
      gap: 2px;
      justify-content: space-around;
      padding: 8px 16px 24px;
      position: sticky;
      bottom: 0;
      background-color: ${(props) => props.theme.white};
      z-index: 1;

      @media (min-width: 768px) {
        justify-content: center;
        padding: 8px 24px 24px;
        gap: 40px;
      }
    }
  }
`;

import { useMemo } from 'react';
import moment from 'moment';
import { scaleLinear, scaleTime } from '@visx/scale';
import { min, max } from 'd3-array';

import { getY } from '../utils';
import { paddingX, paddingY } from '../constants';

export const useTooltipAxisCalculation = ({ chart, dateRange, graphWidth, graphHeight }) => {
  const dataSeries = chart.dataSeries;

  // Scaling Y Axis
  const { domainMin, domainMax } = useMemo(() => {
    const minConfig = chart.rangeMin;
    const minSeries = min(dataSeries, getY);
    const domainMin = minConfig !== null ? Math.min(minConfig, minSeries) : minSeries ?? 0;
    const maxConfig = chart.rangeMax;
    const maxSeries = max(dataSeries, getY);
    const domainMax = maxConfig !== null ? Math.max(maxConfig, maxSeries) : maxSeries ?? 0;

    return { domainMin, domainMax };
  }, [chart.rangeMax, chart.rangeMin, dataSeries]);

  // Scaling X Axis
  const dateScale = useMemo(
    () =>
      scaleTime({
        domain: [
          moment(dateRange[0]).startOf('day').toDate(),
          moment(dateRange[1]).endOf('day').toDate(),
        ],
        range: [0 + paddingX, graphWidth - paddingX],
        clamp: true,
      }),

    [dateRange, graphWidth]
  );

  const metricScale = useMemo(
    () =>
      scaleLinear({
        domain: [domainMin as number, domainMax as number],
        range: [graphHeight - paddingY, paddingY],
        clamp: true,
      }),

    [domainMax, domainMin, graphHeight]
  );

  return { dateScale, metricScale };
};

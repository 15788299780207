export const size = {
  mobileS: '20em',
  mobileM: '25em',
  mobileL: '32em',
  tabletPort: '40em',
  tablet: '48em',
  laptop: '64em',
  laptopM: '76em',
  laptopL: '90em',
  desktopS: '100em',
  desktopM: '112.5em',
  desktopL: '113em',
  desktopXL: '160em',
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileLand: `(max-width: ${size.tabletPort}) and (orientation : landscape), (max-width: ${size.mobileL})`,
  tabPort: `(max-width: ${size.tabletPort})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktopS: `(max-width: ${size.desktopS})`,
  desktopM: `(max-width: ${size.desktopM})`,
  desktopL: `(min-width: ${size.desktopL})`,
  desktopXL: `(min-width: ${size.desktopXL})`,
};

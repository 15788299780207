import styled from 'styled-components';

export const UsersWrapper = styled.section`
  position: relative;

  .users {
    &__heading {
      display: flex;
      justify-content: space-between;

      &-add-user {
        display: flex;
        align-items: flex-end;
        padding-bottom: 24px;
      }
    }
    &__content {
      & .users-table-wrapper:first-child {
        padding-top: 0;
        padding-bottom: 32px;
      }

      & .users-table-wrapper:last-child {
        padding-bottom: 0;
      }

      &-separator {
        color: ${(props) => props.theme.primaryLight};
        font-weight: 500;
        font-size: 12px;
      }
    }

    &__no-users {
      text-align: center;

      &-text {
        font-size: 1.44rem;
      }
    }

    &__failed {
      text-align: center;

      &-text {
        color: red;
        font-size: 1.44rem;
      }
    }

    &__progress {
      position: absolute;
      width: 100%;
    }
  }
`;

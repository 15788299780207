import { ChartConfig, ChartNames, MetricKey } from 'types/Telemetry';
import { PropertyDevice } from 'types';
import {
  COLOR_BLUE,
  COLOR_DARK_PINK,
  COLOR_GREEN,
  COLOR_GREY,
  COLOR_ORANGE,
  COLOR_PURPLE,
  COLOR_RED,
} from '../constants';

type GetChartsProps = {
  label: string;
  title?: string;
  subTitle?: string;
  propertyLocationId: string;
};

type BoilerMonitoringProps = GetChartsProps & {
  zigbeeAutoFill?: PropertyDevice;
  legacyAutoFill?: PropertyDevice;
};

type GetPowerMeterProps = GetChartsProps & {
  powerClamps?: { A?: string; B?: string; C?: string };
};

export const handleTelemetryChartsConfig = () => {
  const getBoilerCharts = ({ label, propertyLocationId }: GetChartsProps): ChartConfig[] => [
    {
      title: 'CH Flow Temp',
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.BoilerFlowTempMean,
      color: COLOR_RED,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
    },
    {
      title: 'Room Temp',
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.BoilerRoomActualMean,
      color: COLOR_GREEN,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
    },
    {
      title: 'CH Return Temp',
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.BoilerRtnTempMean,
      color: COLOR_BLUE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
    },
    {
      title: 'Room Target',
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.BoilerRoomSetPointMean,
      color: COLOR_ORANGE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
    },
  ];

  const getHeatPumpCharts = ({
    label,
    propertyLocationId,
    subTitle,
  }: GetChartsProps): ChartConfig[] => [
    {
      title: 'Room Temp',
      type: ChartNames.Linear,
      gridWidth: 4,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.BoilerRoomActualMean,
      color: COLOR_GREEN,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      hideWhenNoData: true,
    },
    {
      title: 'Outdoor Unit Flow Temp',
      type: ChartNames.Linear,
      gridWidth: 4,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.HeatPumpOutdoorUnitFlowTempMean,
      color: COLOR_RED,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      hideWhenNoData: true,
    },
    {
      title: 'Outdoor Unit Return Temp',
      type: ChartNames.Linear,
      gridWidth: 4,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.HeatPumpOutdoorUnitRtnTempMean,
      color: COLOR_BLUE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      hideWhenNoData: true,
    },
    {
      title: 'Outside Temp',
      type: ChartNames.Linear,
      gridWidth: 4,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.HeatingUnitOutsideTempMean,
      color: COLOR_BLUE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      hideWhenNoData: true,
    },
    {
      title: 'Primary Flow Rate',
      type: ChartNames.Linear,
      gridWidth: 4,
      chartLabel: label,
      units: 'l/min',
      metricKey: MetricKey.HeatPumpPrimaryFlowRateMean,
      color: COLOR_BLUE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      hideWhenNoData: true,
    },
    {
      title: 'DHW Storage Temp',
      type: ChartNames.Linear,
      gridWidth: 4,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.HeatingUnitDHWStorageTempMean,
      color: COLOR_BLUE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      hideWhenNoData: true,
    },
    {
      title: 'Fan Speed (RPM)',
      type: ChartNames.Linear,
      gridWidth: 4,
      chartLabel: label,
      units: 'RPM',
      metricKey: MetricKey.BoilerFanSpeedActualRPMMean,
      color: COLOR_BLUE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      hideWhenNoData: true,
    },
    {
      title: 'Electricity Consumption',
      type: ChartNames.Numeric,
      gridWidth: 2,
      chartLabel: label,
      units: 'kW',
      metricKey: MetricKey.HeatPumpHeatingElectricityConsumption,
      propertyLocationId,
      subTitle,
      hideWhenNoData: true,
    },
    {
      title: 'DHW Electricity Consumption',
      type: ChartNames.Numeric,
      gridWidth: 2,
      chartLabel: label,
      units: 'kW',
      metricKey: MetricKey.HeatPumpDHWElectricityConsumption,
      propertyLocationId,
      subTitle,
      hideWhenNoData: true,
    },
  ];

  const getBoilerAssetManagementChartForDevice = ({
    label,
    propertyLocationId,
    subTitle,
  }: GetChartsProps): ChartConfig[] => [
    {
      title: 'DHW Burner Time',
      type: ChartNames.Numeric,
      gridWidth: 3,
      chartLabel: label,
      units: 'Hours',
      metricKey: MetricKey.BoilerDHWBurnerHours,
      propertyLocationId,
      subTitle,
    },
    {
      title: 'CH Burner Time',
      type: ChartNames.Numeric,
      gridWidth: 3,
      chartLabel: label,
      units: 'Hours',
      metricKey: MetricKey.BoilerCHBurnerHours,
      propertyLocationId,
      subTitle,
    },
    {
      title: 'Total Burner Time',
      type: ChartNames.Numeric,
      gridWidth: 3,
      chartLabel: label,
      units: 'Hours',
      metricKey: MetricKey.BoilerTotalBurnerHours,
      propertyLocationId,
      subTitle,
    },
    {
      title: 'Total Pump Time',
      type: ChartNames.Numeric,
      gridWidth: 3,
      chartLabel: label,
      units: 'Hours',
      metricKey: MetricKey.BoilerTotalPumpHours,
      propertyLocationId,
      subTitle,
    },
  ];

  const getBoilerMonitoringChartForDevice = ({
    label,
    propertyLocationId,
    subTitle,
    zigbeeAutoFill,
    legacyAutoFill,
  }: BoilerMonitoringProps): ChartConfig[] => [
    {
      title: 'CH Flow Temp',
      type: ChartNames.Linear,
      gridWidth: 3,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.BoilerFlowTempMean,
      color: COLOR_RED,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      subTitle,
    },
    {
      title: 'DHW Flow',
      type: ChartNames.Linear,
      gridWidth: 3,
      chartLabel: label,
      units: 'l/min',
      metricKey: MetricKey.BoilerDHWFlowRateMean,
      color: COLOR_PURPLE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      subTitle,
    },
    ...(zigbeeAutoFill
      ? [
        {
          title: 'AZ Pressure',
          type: ChartNames.Linear,
          gridWidth: 3,
          chartLabel: zigbeeAutoFill.serialNumber,
          units: '%',
          metricKey: MetricKey.ZigbeeAutofillPressureMean,
          color: COLOR_BLUE,
          rangeMin: 0,
          rangeMax: null,
          propertyLocationId,
          subTitle,
        },
      ]
      : [
        {
          title: 'CH Pressure',
          type: ChartNames.Linear,
          gridWidth: 3,
          chartLabel: label,
          units: 'bar',
          metricKey: MetricKey.BoilerCHPressureMean,
          color: COLOR_BLUE,
          rangeMin: 0,
          rangeMax: null,
          propertyLocationId,
          subTitle,
        },
      ]),
    {
      title: 'Boiler Modulation',
      type: ChartNames.Linear,
      gridWidth: 3,
      chartLabel: label,
      units: '%',
      metricKey: MetricKey.BoilerPumpModulationMean,
      color: COLOR_ORANGE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      subTitle,
    },
    {
      title: 'CH Return Temp',
      type: ChartNames.Linear,
      gridWidth: 3,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.BoilerRtnTempMean,
      color: COLOR_BLUE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      subTitle,
    },
    {
      title: 'CH Set Point',
      type: ChartNames.Linear,
      gridWidth: 3,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.BoilerCHSetPointActualMean,
      color: COLOR_GREEN,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      subTitle,
    },
    ...(legacyAutoFill
      ? [
        {
          title: 'Legacy Autofill Burst',
          type: ChartNames.Linear,
          gridWidth: 3,
          chartLabel: legacyAutoFill.serialNumber,
          units: 'count',
          metricKey: MetricKey.AutofillLastFillNumBursts,
          color: COLOR_ORANGE,
          rangeMin: 0,
          rangeMax: null,
          propertyLocationId,
          subTitle,
        },
      ]
      : []),
    {
      title: 'Fan',
      type: ChartNames.Linear,
      gridWidth: 3,
      chartLabel: label,
      units: 'RPM',
      metricKey: MetricKey.BoilerFanSpeedActualRPMMean,
      color: COLOR_PURPLE,
      rangeMin: 0,
      rangeMax: null,
      propertyLocationId,
      subTitle,
    },
  ];

  const getBoilerDiagnosticChartForDevice = ({
    label,
    propertyLocationId,
    subTitle,
  }: GetChartsProps): ChartConfig[] => [
    {
      title: 'Unsuccessful Burner Starts',
      type: ChartNames.Numeric,
      gridWidth: 3,
      chartLabel: label,
      units: 'count',
      metricKey: MetricKey.BoilerFailedBurnerStarts,
      propertyLocationId,
      subTitle,
    },
    {
      title: 'Total Burner Starts',
      type: ChartNames.Numeric,
      gridWidth: 3,
      chartLabel: label,
      units: 'count',
      metricKey: MetricKey.BoilerTotalBurnerStarts,
      propertyLocationId,
      subTitle,
    },
    {
      title: 'CH Pump Starts',
      type: ChartNames.Numeric,
      gridWidth: 3,
      chartLabel: label,
      units: 'count',
      metricKey: MetricKey.BoilerCHPumpStarts,
      propertyLocationId,
      subTitle,
    },
    {
      title: 'DHW Pump Starts',
      type: ChartNames.Numeric,
      gridWidth: 3,
      chartLabel: label,
      units: 'count',
      metricKey: MetricKey.BoilerDHWPumpStarts,
      propertyLocationId,
      subTitle,
    },
  ];

  const getVericonDiagnosticChartForDevice = ({
    label,
    propertyLocationId,
  }: GetChartsProps): ChartConfig[] => [
    {
      title: 'Charge Status',
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: '',
      metricKey: MetricKey.DeviceChargeStatus,
      color: COLOR_RED,
      rangeMin: null,
      rangeMax: null,
      propertyLocationId,
    },
    {
      title: 'Signal Strength',
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: 'RSSI',
      metricKey: MetricKey.GsmSignalStrength,
      color: COLOR_PURPLE,
      rangeMin: null,
      rangeMax: -50,
      propertyLocationId,
    },
    {
      title: 'Telemetry Period',
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: 'seconds',
      metricKey: MetricKey.TelemetryPeriod,
      color: COLOR_BLUE,
      rangeMin: null,
      rangeMax: null,
      propertyLocationId,
    },
    {
      title: 'Fault Code',
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: '',
      metricKey: MetricKey.BoilerFaultCode,
      color: COLOR_ORANGE,
      rangeMin: null,
      rangeMax: null,
      propertyLocationId,
    },
    {
      title: 'Battery Voltage',
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: 'Volts',
      metricKey: MetricKey.DeviceBatteryVoltage,
      color: COLOR_GREEN,
      rangeMin: null,
      rangeMax: null,
      propertyLocationId,
    },
    {
      title: 'ST Chip Temperature',
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.DeviceStTemperature,
      color: COLOR_RED,
      rangeMin: null,
      rangeMax: null,
      propertyLocationId,
    },
  ];

  const getMultiDotCharts = ({
    title,
    label,
    propertyLocationId,
    subTitle,
  }: GetChartsProps): ChartConfig[] => [
    {
      title: `${title} Temperature`,
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: '°C',
      metricKey: MetricKey.ZDeviceTemperature,
      color: COLOR_GREEN,
      rangeMin: 0,
      rangeMax: null,
      subTitle,
      propertyLocationId,
    },
    {
      title: `${title} Humidity`,
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: '%',
      metricKey: MetricKey.ZDeviceHumidity,
      color: COLOR_PURPLE,
      rangeMin: 0,
      rangeMax: null,
      subTitle,
      propertyLocationId,
    },
  ];

  const getEnviroDotCharts = ({
    title,
    label,
    propertyLocationId,
    subTitle,
  }: GetChartsProps): ChartConfig[] => [
    ...getMultiDotCharts({ title, label, propertyLocationId, subTitle }).map((chart) => ({
      ...chart,
      gridWidth: 4,
    })),
    {
      title: `${title} CO2`,
      type: ChartNames.Linear,
      gridWidth: 4,
      chartLabel: label,
      units: 'ppm',
      metricKey: MetricKey.ZDeviceCo2,
      color: COLOR_ORANGE,
      rangeMin: 400,
      rangeMax: null,
      propertyLocationId,
      subTitle,
    },
  ];

  const getMultiDotAndEnviroDotBatteryCards = ({
    title,
    label,
    propertyLocationId,
    subTitle,
  }: GetChartsProps): ChartConfig[] => [
    {
      title: `${title} Battery`,
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: 'V',
      metricKey: MetricKey.ZDeviceBattery,
      color: COLOR_GREY,
      rangeMin: null,
      rangeMax: null,
      propertyLocationId,
      subTitle,
    },
    {
      title: `${title} Link Quality`,
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: 'LQI',
      metricKey: MetricKey.ZDeviceLqiMin,
      color: COLOR_ORANGE,
      rangeMin: 0,
      rangeMax: 100,
      propertyLocationId,
      subTitle,
    },
  ];

  const getMultiDotAndEnviroDotTamperCards = ({
    title,
    label,
    propertyLocationId,
    subTitle,
  }: GetChartsProps): ChartConfig[] => [
    {
      title: `${title} Tamper`,
      type: ChartNames.Linear,
      gridWidth: 3,
      chartLabel: label,
      units: 'Occurence',
      metricKey: MetricKey.ZDeviceTampered,
      color: COLOR_RED,
      rangeMin: null,
      rangeMax: null,
      propertyLocationId,
      subTitle,
    },
  ];

  const getPowerMeterCard = ({
    title,
    label,
    propertyLocationId,
    powerClamps,
    subTitle,
  }: GetPowerMeterProps): ChartConfig[] => {
    const powerMeter = {
      type: ChartNames.Linear,
      gridWidth: 6,
      chartLabel: label,
      units: 'W',
      color: COLOR_DARK_PINK,
      rangeMin: null,
      rangeMax: null,
      propertyLocationId,
      subTitle,
    };

    if (powerClamps) {
      return Object.entries(powerClamps).map(([clamp, location]) => ({
        ...powerMeter,
        metricKey: `${MetricKey.ZDevicePower}${clamp}` as MetricKey,
        title: `${location || label} Power Usage`,
      }));
    }

    return [
      {
        ...powerMeter,
        title: `${title} Power Usage`,
        metricKey: MetricKey.ZDevicePower,
      },
    ];
  };

  return {
    getBoilerCharts,
    getHeatPumpCharts,
    getMultiDotCharts,
    getEnviroDotCharts,
    getPowerMeterCard,
    getMultiDotAndEnviroDotTamperCards,
    getMultiDotAndEnviroDotBatteryCards,
    getVericonDiagnosticChartForDevice,
    getBoilerAssetManagementChartForDevice,
    getBoilerDiagnosticChartForDevice,
    getBoilerMonitoringChartForDevice,
  };
};

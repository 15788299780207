import React, { useMemo } from 'react';

import { DevicePartial } from 'types/DevicePartial';
import { AllDevices } from 'types/AllDevices';
import { useAppSelector, useSetDeviceAccessRightsTag } from 'utils/hooks';
import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { DeviceAccessRightsTag } from '../DeviceAccessRightsTag';

type DeviceAccessRightsTagWrapperApiV1Props = {
  limited?: boolean;
  device: AllDevices | DevicePartial;
  onSuccess: () => void;
};

export const DeviceAccessRightsTagWrapperApiV1: React.FC<
  DeviceAccessRightsTagWrapperApiV1Props
> = ({ limited, device, onSuccess }) => {
  const accessRightsTags: AccessRightsTag[] = useAppSelector(
    (state) => state.accessRightsTags.accessRightsTags
  );

  const deviceAccessRightsTag = useMemo(
    () => accessRightsTags.find((tag) => tag.id === device.accessRightsTag),
    [accessRightsTags, device.accessRightsTag]
  );

  const { setDeviceAccessRightsTag, isError } = useSetDeviceAccessRightsTag({
    deviceId: device.deviceId,
    onSuccess,
  });

  return (
    <DeviceAccessRightsTag
      limited={limited}
      userCanEdit={device.userCanUpdateAccessRightsTag}
      isError={isError}
      accessRightsTags={accessRightsTags}
      deviceAccessRightsTag={deviceAccessRightsTag}
      setDeviceAccessRightsTag={(accessRightsTagId) =>
        setDeviceAccessRightsTag({ accessRightsTagId })
      }
      removeAccessRightsTag={() => setDeviceAccessRightsTag({ accessRightsTagId: null })}
    />
  );
};

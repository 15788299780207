export const contains = (target, options) => options.some((opt) => target.includes(opt));
export const containsExact = (target, options) => options.some((opt) => target === opt);

export const some = (target, options, fn) => options.some((opt) => fn(target, opt));

export const isNullOrUndefined = (x) => x === null || x === undefined;

export const searchObject = (str, obj, keys: string[] = []) =>
  Object.keys(obj)
    .filter((k) => (keys.length > 0 ? keys.includes(k) : true))
    .some((prop) =>
      !isNullOrUndefined(obj[prop])
        ? obj[prop].toString().toLowerCase().includes(str.toLowerCase())
        : false
    );

export const pick = (object, keys) =>
  keys.reduce((obj, key) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key];
    } else {
      obj[key] = '';
    }

    return obj;
  }, {});

export const displayValue = (selectedValue, values) => {
  if (!selectedValue && selectedValue !== 0) return '';
  if (values?.length === 0) return selectedValue;
  if (typeof values[0] === 'string' || typeof values[0] === 'number') {
    const val = values.find((v) => v === selectedValue);

    return val ? val : selectedValue;
  }
  const evaluatedValue = isNaN(Number(selectedValue)) ? selectedValue : Number(selectedValue);
  const val = values.find((v) => v.value === evaluatedValue);

  return val ? val.display : selectedValue;
};

export const setValue = (selectedValue, values) => {
  if (!selectedValue) return '';
  if (values?.length === 0) return selectedValue;
  if (typeof values[0] === 'string' || typeof values[0] === 'number') {
    return values.find((v) => v === selectedValue);
  }

  const evaluatedValue = isNaN(Number(selectedValue)) ? selectedValue : Number(selectedValue);
  const val = values.find((v) => v.value === evaluatedValue);

  return val ? val.value : '';
};

export const camelCaseToTitleCase = (str) => {
  if (str) {
    const camelEdges = /([A-Z](?=[A-Z][a-z])|[^A-Z](?=[A-Z])|[a-zA-Z](?=[^a-zA-Z]))/g;
    let newStr = str;
    newStr = newStr.replace(camelEdges, '$1 ');
    newStr = newStr.charAt(0).toUpperCase() + newStr.slice(1);

    return newStr;
  }
};

export const titleCaseToLower = (str) => {
  if (str) return str.replace(/[^\w]|_/g, '').toLowerCase();
};

export const getMappedValues = (arr, f) =>
  arr.map((i) => ({
    value: i,
    display: f(i),
  }));

export const sortObjByProp = (prop) => (objA, objB) => {
  if (!objA[prop] || !objB[prop]) return 0;

  return objA[prop] > objB[prop] ? 1 : -1;
};

export const filter = (fn) => (arr) => arr.filter(fn);

const getEndOfDay = (str) => new Date(new Date(str).getTime() + 24 * 60 * 60 * 1000 - 1);

export const filterDates = (minStr, maxStr) => (target) => {
  const targetDate = new Date(target);
  const minDate = new Date(minStr);
  const maxDate = getEndOfDay(maxStr);

  return targetDate >= minDate && targetDate <= maxDate;
};

export const isDate = (str) => !isNaN(Date.parse(str));
export const formatDate = (date) =>
  isDate(date) ? new Date(date).toLocaleString('en-GB').replace(',', '') : date;
export const formatAsDate = (date) =>
  isDate(date) ? new Date(date).toLocaleDateString('en-GB').replace(',', '') : date;

export const getInputState = (obj, name = '') => {
  if (name) {
    const inp = obj[name];
    if (inp.isValid) return 'valid';
    if (inp.err) return 'error';

    return '';
  }

  if (obj?.isValid) return 'valid';
  if (obj?.err || obj?.msg) return 'error';

  return '';
};

export const deleteProperty = (obj, key) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: _, ...newObj } = obj;

  return newObj;
};

export const getDelayDisplay = (alert) => `${alert.delay} ${alert.delayUnit}`;
export const getDelay = (alert) => {
  const vals = alert.delayDisplay.split(' ');

  return {
    delay: parseInt(vals[0]),
    delayUnit: vals[1],
  };
};

export const truncate = (s: string, length = 10) =>
  s.length > length ? `${s.slice(0, length)}...` : s;

export const subtractDays = (date: Date, numOfDays: number) =>
  new Date(new Date(date).setDate(date.getDate() - numOfDays));

export const asEndDate = (date: Date) => new Date(new Date(date).setHours(23, 59, 59, 999));
export const asStartDate = (date: Date) => new Date(new Date(date).setHours(0, 0, 0, 0));

export const toLocalIsoString = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

export const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  const isMatchingMobileDevices = toMatch.some((toMatchItem) =>
    navigator.userAgent.match(toMatchItem)
  );

  const hasTouchPoints = navigator?.maxTouchPoints > 0;

  return hasTouchPoints || isMatchingMobileDevices;
};

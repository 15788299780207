import React, { useState, useEffect, useReducer } from 'react';

import Button from 'components/atoms/Button';
import Modal from 'components/elements/Modal';
import BuildingPositioningMap from 'components/FloorPlan/BuildingPositioningMap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchBuildingInfo } from 'reducers/buildingInfo/actions';
import { selectBuildingNamesOnly } from 'reducers/buildingInfo/selectors';
import styled from 'styled-components';
import { handleApiReq } from 'utils/ApiRequestHelpers';
import { pick, getInputState, titleCaseToLower } from 'utils/helpers';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';
import useValidateForm from 'utils/hooks/useValidateForm';
import { isRequired, isUnique } from 'utils/inputValidation';

import ValidatedInput from '../../elements/ValidatedInput';
import FloorTable from '../../FloorTable';
import { floorReducer } from './floorReducer';

const SaveButton = styled.button`
  ${(props) => props.theme.submitButton('18rem')};
`;

const BackButton = styled.button`
  ${(props) => props.theme.defaultButton('18rem')};
  margin-right: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => props.theme.textAlt};
`;

const Title = styled.h2`
  margin-bottom: 2rem;
`;

const Section = styled.section`
  margin-bottom: 3rem;
  font-size: ${(props) => props.theme.textSmall};
`;

const InputWrapper = styled.div`
  width: 30%;
  margin-bottom: 2rem;

  @media ${(props) => props.theme.laptop} {
    width: 40%;
  }

  @media ${(props) => props.theme.tablet} {
    width: 50%;
  }

  @media ${(props) => props.theme.mobileL} {
    width: 60%;
  }
`;

const SectionLabel = styled.span`
  display: inline-block;
  margin-bottom: 1rem;
`;

const PostCodeButton = styled.button`
  ${(props) => props.theme.defaultButton()};
  display: block;
  margin-bottom: 1rem;
`;

const PositionButtonComp = ({ onClick, disabledClick, isDisabled, children }) => (
  <Button
    type="borderDefault"
    clickCallback={onClick}
    disabledClickCallback={disabledClick}
    width="15rem"
    isDisabled={isDisabled}
  >
    {children}
  </Button>
);

const PositionButton = styled(PositionButtonComp)`
  display: block;
  margin-bottom: 1rem;
`;

const NewBuilding = ({ building, setBuilding }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tenant = useSelectedTenant();
  const buildingNames = useSelector((state: any) => selectBuildingNamesOnly(state));

  const [floors, floorDispatch] = useReducer(floorReducer, building.floors);
  const [isPostCodeInvalid, setisPostCodeInvalid] = useState(false);
  const [isGeoSet, setIsGeoSet] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [formState, setFormState] = useState({
    ...pick(building, ['name']),
    postCode: '',
  });

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      ...pick(building, ['name']),
    }));
  }, [building]);

  const validationSchema = {
    name: [
      { fn: isRequired, msg: 'Building name is required' },
      {
        fn: isUnique(buildingNames.map((n) => titleCaseToLower(n))),
        msg: 'Name is already being used',
      },
    ],
    postCode: [{ fn: isRequired, msg: 'Post Code is required' }],
  };
  const { validationState, validateForm, validateInput } = useValidateForm(
    formState,
    validationSchema
  );

  const handleNameChange = (value, name) => {
    validateInput(titleCaseToLower(value), name);
    setBuilding({ ...building, name: value });
  };

  const handlePostCodeChange = (value, name) => {
    validateInput(value, name);
    setFormState({ ...formState, postCode: value });
  };

  const getPostCodeLatLong = async () => {
    const res = await handleApiReq('GetLatLong', { postCode: formState.postCode });
    if (res) {
      setBuilding({
        ...building,
        lat: res.lat,
        lng: res.lng,
      });
      floorDispatch({
        type: 'UPDATE_GEO',
        lat: res.lat,
        lng: res.lng,
      });
      setisPostCodeInvalid(false);
      setIsGeoSet(true);
    } else {
      setisPostCodeInvalid(true);
      setIsGeoSet(false);
    }

    return !!res;
  };

  const onPositionClick = () => setShowModal(true);

  const onPositionClickDisabled = () => {
    if (validateForm()) getPostCodeLatLong();
  };

  const onSaveClick = async () => {
    const isValid = validateForm();
    if (isValid && isGeoSet) {
      const newBuildingId = titleCaseToLower(building.name);
      const newBuilding = {
        ...building,
        floors,
        building: newBuildingId,
      };
      const res = await handleApiReq('AddBuilding', { building: newBuilding });
      const buildingId = res.buildingId ? res.buildingId : newBuildingId;
      if (res) {
        dispatch(fetchBuildingInfo());
        navigate(`/${tenant}/Buildings/${buildingId}`);
      }
    }
  };

  const onBackClick = () => {
    navigate(`/${tenant}/Buildings`);
  };

  const onGeoSave = ({ lat, lng }) => {
    setBuilding({
      ...building,
      lat,
      lng,
    });
    floorDispatch({
      type: 'UPDATE_GEO',
      lat,
      lng,
    });
    setShowModal(false);
  };

  const handleCancel = () => setShowModal(false);

  return (
    <>
      <Title>Add new building</Title>
      <Section>
        <InputWrapper>
          <ValidatedInput
            type="text"
            label="Building Name:"
            errMsg={validationState['name'].err}
            inputData={{
              placeholder: 'Enter building name',
              name: 'name',
              state: getInputState(validationState, 'name'),
              value: building.name,
              onChange: handleNameChange,
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <ValidatedInput
            type="text"
            label="Post Code:"
            errMsg={isPostCodeInvalid ? 'Post code is invalid' : validationState['postCode'].err}
            inputData={{
              placeholder: 'Enter post code',
              name: 'postCode',
              state: isPostCodeInvalid ? 'error' : getInputState(validationState, 'postCode'),
              value: formState.postCode,
              onChange: handlePostCodeChange,
            }}
          />
        </InputWrapper>
        <PostCodeButton onClick={getPostCodeLatLong}>Check Post Code</PostCodeButton>
        <PositionButton
          onClick={onPositionClick}
          isDisabled={!isGeoSet}
          disabledClick={onPositionClickDisabled}
        >
          Position Building
        </PositionButton>
      </Section>
      <Section>
        <SectionLabel>Floors:</SectionLabel>
        <FloorTable floors={floors} dispatch={floorDispatch} />
        <InputWrapper>
          <ValidatedInput
            type="dropdown"
            label="Default Floor"
            errMsg={null}
            inputData={{
              name: 'defaultFloor',
              value: building.defaultFloor,
              options: floors.map((f) => f.name),
              readOnly: true,
              onChange: (value) => {
                setBuilding({ ...building, defaultFloor: value });
              },
            }}
          />
        </InputWrapper>
      </Section>
      <ButtonWrapper>
        <BackButton onClick={onBackClick}>Back</BackButton>
        <SaveButton onClick={onSaveClick}>Save Building</SaveButton>
      </ButtonWrapper>
      <Modal handleClose={handleCancel} showModal={showModal} title="" width="95% !important">
        <BuildingPositioningMap building={building} onSave={onGeoSave} onCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default NewBuilding;

import { useMemo } from 'react';

import { addDataGridPinColumn } from 'utils';
import { useAreaFeatures, useDataGridPinnedRows, useGetFetchData } from 'utils/hooks';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';
import { FeaturesAreas } from 'types';
import { transformProperties } from '../utils/transformProperties';
import { columnsList } from '../constants/columns';
import { DataGridColumns } from '../types';

export const useTableData = () => {
  const tenant = useSelectedTenant();
  const propertyFeatures = useAreaFeatures(FeaturesAreas.Properties).features;
  const filteredColumns = columnsList.filter((col) =>
    col.field === DataGridColumns.VoidStatus && !propertyFeatures.VoidFlag ? false : true
  );

  const { data, isLoading, isFetching } = useGetFetchData({
    url: process.env.REACT_APP_API_URL_V2 as string,
    query: `tenants/${tenant}/properties`,
    enableErrorHandler: true,
  });

  const transformedRows = useMemo(
    () => transformProperties({ properties: data?.results }) || [],
    [data]
  );

  const { pinnedRowsIds, setPinnedRowsIds, pinnedRows, rows } = useDataGridPinnedRows({
    rows: transformedRows,
  });

  const columns = useMemo(
    () => addDataGridPinColumn({ pinnedRowsIds, setPinnedRowsIds, columnsList: filteredColumns }),
    [filteredColumns, pinnedRowsIds, setPinnedRowsIds]
  );

  return { rows, pinnedRows, columns, isLoading, isFetching };
};

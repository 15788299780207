import React from 'react';

import styled from 'styled-components';
import { mapConnectionStatus } from 'utils/deviceUtils';

import { DeviceStatusSpan } from '../../atoms/DeviceStatusSpan';

const Wrapper = styled.span`
  display: flex;
  align-items: center;
`;
const ConnectionStatusIndicator = ({ connectionStatus }) => (
  <Wrapper>
    <DeviceStatusSpan status={mapConnectionStatus(connectionStatus)} />
    {connectionStatus}
  </Wrapper>
);

export default ConnectionStatusIndicator;

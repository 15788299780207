import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Chip,
  OutlinedInput,
  Box,
  FormControl,
  InputLabel,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material';

import { useDownloadReport, useReportDates, useReportTags } from '../../hooks';
import { ApiData } from '../../types';
import {
  MenuPropsStyles,
  ReportCustomizationDialogWrapper,
} from './ReportCustomizationDialog.styles';

type ReportCustomizationDialogProps = {
  open: boolean;
  onClose: () => void;
  tenantID: string;
  api: ApiData;
};

export const ReportCustomizationDialog: React.FC<ReportCustomizationDialogProps> = ({
  open,
  onClose,
  tenantID,
  api,
}) => {
  const { handleMonthChange, reportDates, months, years, handleYearChange } = useReportDates();
  const { handleTagChange, selectedTags, tags } = useReportTags();

  const { handleDownloadReport, isLoading } = useDownloadReport({
    tenantID,
    reportDates,
    onSuccess: onClose,
    selectedTags,
    ...api,
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <ReportCustomizationDialogWrapper>
        <DialogTitle>
          <div className="report-customization-dialog__title">
            <Typography className="report-customization-dialog__title-text" variant="h4">
              Report customization
            </Typography>
            <IconButton onClick={onClose} className="report-customization-dialog__title-close">
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="report-customization-dialog__dates">
            <Typography variant="h6" className="report-customization-dialog__dates-title">
              Date
            </Typography>
            <div className="report-customization-dialog__dates-inputs">
              <Select
                value={months[reportDates.month]}
                IconComponent={KeyboardArrowDownIcon}
                onChange={handleMonthChange}
                fullWidth
                disabled={isLoading}
              >
                {months.map((month, monthIndex) => (
                  <MenuItem key={monthIndex.toString()} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
              <Select
                value={reportDates.year}
                IconComponent={KeyboardArrowDownIcon}
                onChange={handleYearChange}
                fullWidth
                disabled={isLoading}
              >
                {years.map((year, yearIndex) => (
                  <MenuItem key={yearIndex.toString()} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="report-customization-dialog__tags">
            <Typography variant="h6" className="report-customization-dialog__tags-title">
              Tags
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="select-multiple-tags">Tags</InputLabel>
              <Select
                multiple
                fullWidth
                disabled={isLoading}
                value={selectedTags}
                IconComponent={KeyboardArrowDownIcon}
                onChange={handleTagChange}
                input={<OutlinedInput id="select-multiple-tags" label="Tags" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((tag, tagIndex) => (
                      <Chip key={tagIndex.toString()} label={tag.name} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuPropsStyles}
              >
                {tags.map((tag, tagIndex) => (
                  <MenuItem key={tagIndex.toString()} value={tag}>
                    {tag.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="report-customization-dialog__button">
          <Button
            onClick={handleDownloadReport}
            disabled={isLoading}
            variant="contained"
            endIcon={isLoading ? <CircularProgress size={20} /> : <GetAppIcon />}
          >
            Download
          </Button>
        </DialogActions>
      </ReportCustomizationDialogWrapper>
    </Dialog>
  );
};

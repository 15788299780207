import React, { useMemo } from 'react';

import { CapabilitiesNames } from 'types';
import { useAppSelector, useCapabilities, usePutFetchData, useSelectedTenant } from 'utils/hooks';
import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { DeviceAccessRightsTag } from '../DeviceAccessRightsTag';

type DeviceAccessRightsTagWrapperApiV2Props = {
  limited?: boolean;
  accessRightsTag: string;
  deviceId: string;
  onSuccess?: () => void;
};

export const DeviceAccessRightsTagWrapperApiV2: React.FC<
  DeviceAccessRightsTagWrapperApiV2Props
> = ({ limited, accessRightsTag, onSuccess, deviceId }) => {
  const tenant = useSelectedTenant();

  const accessRightsTags: AccessRightsTag[] = useAppSelector(
    (state) => state.accessRightsTags.accessRightsTags
  );
  const { capabilities, isSuccess: isCapabilitiesSuccess } = useCapabilities(
    CapabilitiesNames.Devices
  );

  const deviceAccessRightsTag = useMemo(
    () => accessRightsTags.find((tag) => tag.id === accessRightsTag),
    [accessRightsTags, accessRightsTag]
  );

  const { mutate, isError } = usePutFetchData({
    url: `${process.env.REACT_APP_API_URL_V2}tenants/${tenant}/devices/${deviceId}/tags`,
    onSuccess,
    enableErrorHandler: true,
  });

  return (
    <DeviceAccessRightsTag
      limited={limited}
      userCanEdit={!!(capabilities.EditAccessRightsTags && isCapabilitiesSuccess)}
      isError={isError}
      accessRightsTags={accessRightsTags}
      deviceAccessRightsTag={deviceAccessRightsTag}
      setDeviceAccessRightsTag={(accessRightsTagId) =>
        mutate({ accessRightsTags: [{ id: accessRightsTagId }] })
      }
      removeAccessRightsTag={() => mutate({ accessRightsTags: [] })}
    />
  );
};

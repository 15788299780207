import styled from 'styled-components';

export const VoidFlagDialogContentWrapper = styled.div`
  .void-flag-dialog {
    &__paragraph {
      font-size: 1.44rem;
    }

    &__list {
      list-style: initial;

      &-item {
        margin-left: 24px;
        margin-top: 1rem;
        font-size: 1.44rem;

        @media (min-width: 768px) {
          margin-left: 32px;
        }

        &-red {
          color: ${(props) => props.theme.error};
        }
      }
    }
  }
`;

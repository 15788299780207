import React, { useState, Fragment } from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import FilterInput from 'components/elements/FilterInput';
import styled from 'styled-components';
import { Field, Filter, FilterDisplay } from 'types/Listing';

import FilterPill from './FilterPill';

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export interface FiltersProps {
  filters: FilterDisplay[];
  fields: Field[];
  isFetching: boolean;
  updateFilters(filters: Filter[]): void;
}

const defaultFilter: Filter = {
  fieldId: '',
  filterOp: 'equals',
  value: '',
};

const AddFilter = styled.span`
  font-size: 1.4rem;
  display: inline-flex;

  align-items: center;

  & > svg {
    margin-left: 0.5rem;
    width: 2rem;
    height: 2rem;
    fill: currentColor;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Filters = ({ filters, updateFilters, fields, isFetching }: FiltersProps) => {
  const [isAdd, setIsAdd] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [editFilter, setEditFilter] = useState(-1);

  const handleOnOPillClick = (i) => setEditFilter(i);

  const handleAddClose = () => {
    setIsAdd(false);
    setFilter(defaultFilter);
  };

  const handleEditClose = () => {
    setEditFilter(-1);
    setFilter(defaultFilter);
  };

  const handleRemoveFilter = (index) => {
    if (!isFetching) updateFilters(filters.filter((_, i) => i !== index));
  };

  const handleAddFilter = (filter) => {
    if (!isFetching) {
      updateFilters([...filters, filter]);
      setIsAdd(false);
    }
  };

  const handleUpdateFilter = (filter) => {
    if (!isFetching) {
      updateFilters(filters.map((f, i) => (i === editFilter ? filter : f)));
      setEditFilter(-1);
    }
  };

  return filters.length === 0 && fields.length === 0 ? (
    <></>
  ) : (
    <FilterWrapper>
      {filters.map((f, i) => (
        <Fragment key={i.toString()}>
          {i === editFilter ? (
            <FilterInput
              fields={fields}
              filter={f}
              handleClose={handleEditClose}
              handleSave={(filter) => handleUpdateFilter(filter)}
            />
          ) : (
            <FilterPill
              filter={f}
              handleRemove={() => handleRemoveFilter(i)}
              key={f.fieldId + i}
              removeable={i > 0}
              handleClick={() => handleOnOPillClick(i)}
            />
          )}
        </Fragment>
      ))}

      {isAdd ? (
        <FilterInput
          fields={fields}
          filter={filter}
          handleClose={handleAddClose}
          handleSave={(filter) => handleAddFilter(filter)}
        />
      ) : (
        <AddFilter onClick={() => setIsAdd(true)}>
          {' '}
          Add Filter <SvgIcon type="plus" />
        </AddFilter>
      )}
    </FilterWrapper>
  );
};

export default Filters;

import { ActionTypes, ClearAction, CreateAction, DeleteAction, FetchAction } from './actionTypes';
import { State } from './types';

const initialState: State = {
  accessRightsTags: [],
  isLoading: false,
  errors: {
    create: '',
    delete: '',
  },
};

export const accessRightsTagsReducer = (
  state = initialState,
  action: CreateAction | FetchAction | DeleteAction | ClearAction
): State => {
  switch (action.type) {
    // CREATE_ACCESS_RIGHTS_TAGS actions
    case ActionTypes.CREATE_ACCESS_RIGHTS_TAGS_REQUEST_START:
      return {
        ...state,
        isLoading: true,
        errors: { ...state.errors, create: '' },
      };
    case ActionTypes.CREATE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: { ...state.errors, create: action.errorMessage },
      };

    // FETCH_ACCESS_RIGHTS_TAGS actions
    case ActionTypes.FETCH_ACCESS_RIGHTS_TAGS_REQUEST_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS:
      return {
        ...state,
        accessRightsTags: action.payload,
        isLoading: false,
      };
    case ActionTypes.FETCH_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    // DELETE_ACCESS_RIGHTS_TAGS actions
    case ActionTypes.DELETE_ACCESS_RIGHTS_TAGS_REQUEST_START:
      return {
        ...state,
        isLoading: true,
        errors: { ...state.errors, delete: '' },
      };
    case ActionTypes.DELETE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DELETE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: { ...state.errors, delete: action.errorMessage },
      };

    // CLEAR actions
    case ActionTypes.CLEAR_CREATE_ACCESS_RIGHTS_TAGS_ERROR:
      return {
        ...state,
        errors: { ...state.errors, create: '' },
      };
    case ActionTypes.CLEAR_DELETE_ACCESS_RIGHTS_TAGS_ERROR:
      return {
        ...state,
        errors: { ...state.errors, delete: '' },
      };
    case ActionTypes.CLEAR_ACCESS_RIGHTS_TAGS:
      return initialState;

    default:
      return state;
  }
};

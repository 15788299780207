import React from 'react';

import styled from 'styled-components';

const RadioInputWrapper = styled.div`
  display: flex;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);

  & input[type='radio'] {
    position: absolute;
    visibility: hidden;
    display: none;
  }

  label {
    display: block;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease-in-out;
    background: ${(props) => props.theme.offWhite};
    text-align: center;
    flex: 1 1 0px;

    &:hover {
      background: ${(props) => props.theme.backgroundColor};
    }
  }

  input[type='radio']:checked + label {
    color: ${(props) => props.theme.white};
    background: ${(props) => props.theme.primaryLight};

    &:hover {
      background: ${(props) => props.theme.primary};
    }
  }
`;

const RadioElement = ({ name, option, handleOnClick, checked }) => (
  <>
    <input type="radio" id={option} name={name} checked={checked} />
    <label onClick={handleOnClick} htmlFor={option} role="presentation">
      {option}
    </label>
  </>
);

const RadioInput = ({ value, onChange, name, options }) => (
  <RadioInputWrapper>
    {options.map((o, i) => (
      <RadioElement
        key={o + i}
        handleOnClick={() => onChange(o, name)}
        name={name}
        option={o}
        checked={o === value}
      />
    ))}
  </RadioInputWrapper>
);

export default RadioInput;

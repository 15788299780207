import React from 'react';

import { ConnectedDevice } from 'types/BoilerTypes/BcmConnectDetails';
import { EllipsisWithTooltip } from 'components/atoms';
import { ConnectedDeviceActions } from 'components/DeviceActions';
import { DeviceAccessRightsTagWrapperApiV1 } from 'components/molecules';
import { getConnectedDevicesLocation } from '../utils/getConnectedDevicesLocation';
import { isThermostat } from '../utils/isThermostat';

type ConnectedDevicesTableRowProps = {
  device: ConnectedDevice;
  isEdit: boolean;
  deviceId: string;
  refreshDeviceDetails: () => void;
  triggerUpdateDevice: () => void;
};

export const ConnectedDevicesTableRow: React.FC<ConnectedDevicesTableRowProps> = ({
  device,
  isEdit,
  deviceId,
  refreshDeviceDetails,
  triggerUpdateDevice,
}) => {
  const location = getConnectedDevicesLocation(device);

  return (
    <tr>
      <td>{device.deviceType || 'none'}</td>
      <td>{device.status || 'none'}</td>
      <td>{location}</td>
      <td style={{ maxWidth: 0 }}>
        <EllipsisWithTooltip text={device.locationDetails} />
      </td>
      <td>{device.serialNumber || 'none'}</td>
      <td>{device.eui64 || 'none'}</td>
      <td className="access-rights-tag-cell">
        {!isThermostat(device.deviceType) && (
          <DeviceAccessRightsTagWrapperApiV1
            limited
            device={device}
            onSuccess={triggerUpdateDevice}
          />
        )}
      </td>
      <td>
        {isEdit && (
          <ConnectedDeviceActions
            device={device}
            bcmId={deviceId}
            refreshView={refreshDeviceDetails}
          />
        )}
      </td>
    </tr>
  );
};

import React from 'react';
import {
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { FaPlus } from 'react-icons/fa';

import { CustomToolbarWrapper } from './CustomToolbar.styles';

export const CustomToolbar: React.FC = () => (
  <GridToolbarContainer>
    <CustomToolbarWrapper>
      <div className="data-grid-toolbar__left">
        <div className="data-grid-toolbar__left-download-csv">
          <GridCsvExportMenuItem />
        </div>
        <GridToolbarColumnsButton
          slotProps={{
            button: { className: 'data-grid-toolbar__left-column-button', size: 'large' },
          }}
        />
        <GridToolbarFilterButton
          slotProps={{
            button: {
              endIcon: <FaPlus className="data-grid-toolbar__left-filters-icon" />,
              startIcon: null,
              size: 'large',
              className: 'data-grid-toolbar__left-filters-button',
            },
          }}
        />
      </div>
      <div className="data-grid-toolbar__right">
        <GridToolbarQuickFilter variant="outlined" />
      </div>
    </CustomToolbarWrapper>
  </GridToolbarContainer>
);

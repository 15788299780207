import styled, { css } from 'styled-components';

const AccessRightsTagWidth = css`
  width: 180px;
  @media (min-width: 768px) {
    width: 200px;
  }
  @media (min-width: 1024px) {
    width: 250px;
  }

  @media (min-width: 1800px) {
    width: 300px;
  }
`;

export const TableWrapper = styled.div<{ isFetching: boolean }>`
  margin: 1rem 0;
  ${(props) => props.theme.wrapper}
  padding: 2.5rem 1rem;
  height: 500px;
  overflow-y: auto;
  filter: blur(${(props) => (props.isFetching ? '10px' : '0px')});
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  & th,
  td {
    padding: 1rem;
  }

  & thead tr {
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};

    & th {
      font-weight: 400;
      text-align: left;
    }
  }

  & tbody tr {
    transition: all 0.4s ease-in-out;
  }

  & tbody tr:nth-child(even) {
    background-color: ${(props) => props.theme.offWhite};
  }

  & tbody tr:hover {
    background-color: #ececf7;
  }

  & tbody td:last-child,
  thead th:last-child {
    display: flex;
    justify-content: center;
  }

  & .access-rights-tag-cell {
    ${AccessRightsTagWidth}
  }

  & .device-access-rights-tag {
    ${AccessRightsTagWidth}

    padding: 0;

    &__content {
      min-height: calc(2rem + 16px);
      justify-content: space-between;

      &-buttons {
        &-edit,
        &-set,
        &-remove {
          svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }

    &__name {
      font-size: 1.44rem;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .device-access-rights-tag-select {
      justify-content: space-between;
      width: 100%;
      gap: 5px;

      &__input {
        ${AccessRightsTagWidth}
      }
    }
  }
`;

import { PropertyDevice } from 'types';

type GetUniqueTagIdsProps = {
  devices: PropertyDevice[] | undefined;
};

export const getUniqueDevicesTagIds = ({ devices }: GetUniqueTagIdsProps) => {
  if (!devices) return [];

  const devicesTagIds = devices.flatMap((device) => device?.tagIds || []);

  return Array.from(new Map(devicesTagIds.map((tagId) => [tagId.id, tagId])).values());
};

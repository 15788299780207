import React, { useState, useEffect, useReducer } from 'react';

import ValidatedInput from 'components/elements/ValidatedInput';
import FloorTable from 'components/FloorTable';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateBuilding } from 'reducers/buildingInfo/actions';
import styled from 'styled-components';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';

const Title = styled.h2`
  margin-bottom: 2rem;
`;

const Section = styled.section`
  margin-bottom: 3rem;
  font-size: ${(props) => props.theme.textSmall};
`;

const InputWrapper = styled.div`
  width: 30%;
  margin-bottom: 2rem;
  margin-top: 1rem;
  @media ${(props) => props.theme.laptop} {
    width: 40%;
  }

  @media ${(props) => props.theme.tablet} {
    width: 50%;
  }

  @media ${(props) => props.theme.mobileL} {
    width: 60%;
  }
`;

const SectionLabel = styled.span`
  display: inline-block;
  margin-bottom: 1rem;
  font-weight: 500;
`;

const SaveButton = styled.button`
  ${(props) => props.theme.submitButton('18rem')}
  font-size: ${(props) => props.theme.textAlt};
`;
const BackButton = styled.button`
  ${(props) => props.theme.defaultButton('18rem')}
  font-size: ${(props) => props.theme.textAlt};
  margin-right: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const floorReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_FLOOR':
      return [
        ...state,
        {
          name: state.length,
          lat: state[0].lat,
          lng: state[0].lng,
          width: state[0].width,
          height: state[0].height,
          image: '',
        },
      ];
    case 'RE_INIT':
      return action.floors;
    default:
      return state;
  }
};

const BuildingDetails = ({ building }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tenant = useSelectedTenant();
  const [floors, floorDispatch] = useReducer(floorReducer, building?.floors);
  const [updatedBuilding, setUpdatedBuilding] = useState(building);

  useEffect(() => {
    floorDispatch({ type: 'RE_INIT', floors: building.floors });
    setUpdatedBuilding(building);
  }, [building]);

  const setDefaultFloor = (value) => setUpdatedBuilding({ ...building, defaultFloor: value });

  const handleSaveClick = () => {
    const b = {
      ...updatedBuilding,
      floors: floors,
    };
    dispatch(updateBuilding(b));
    navigate(`/${tenant}/Buildings`);
  };

  const handleBackClick = () => {
    navigate(`/${tenant}/Buildings`);
  };

  return (
    <>
      <Title>{building?.name}</Title>
      <Section>
        <SectionLabel>Floors:</SectionLabel>
        <FloorTable
          floors={floors}
          dispatch={floorDispatch}
          canEdit={false}
          building={building.building}
        />
        <InputWrapper>
          <ValidatedInput
            type="dropdown"
            label="Default Floor"
            errMsg={null}
            inputData={{
              name: 'defaultFloor',
              value: updatedBuilding.defaultFloor,
              options: floors.map((f) => f.name),
              readOnly: true,
              onChange: (value) => setDefaultFloor(value),
            }}
          />
        </InputWrapper>
        <ButtonWrapper>
          <BackButton onClick={handleBackClick}>Back</BackButton>
          <SaveButton onClick={handleSaveClick}>Save Building</SaveButton>
        </ButtonWrapper>
      </Section>
    </>
  );
};

export default BuildingDetails;

import { useState } from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';

import { getToken } from 'utils/contexts/AuthProvider';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';
import { BulkDeviceDetails } from 'components/DeviceActions/DeviceActionProps';

type DevicesStatusesState = {
  [key: string]: {
    success: boolean;
    error: boolean;
    loading: boolean;
  };
};

type UseBulkUpdateDeviceAccessTagProps = {
  deviceDetails: BulkDeviceDetails;
};

export const useBulkUpdateDeviceAccessTag = ({
  deviceDetails,
}: UseBulkUpdateDeviceAccessTagProps) => {
  const devicesStatusesInitialState: DevicesStatusesState = Object.fromEntries(
    deviceDetails.map(({ deviceId }) => [
      deviceId,
      { success: false, error: false, loading: false },
    ])
  );

  const tenant = useSelectedTenant();
  const [devicesStatuses, setDevicesStatuses] = useState<DevicesStatusesState>(
    devicesStatusesInitialState
  );

  const mutation = useMutation({
    mutationFn: async (variables: {
      body: { accessRightsTagId: string | null };
      deviceId: string;
    }) => {
      const token = await getToken();

      await axios.put(
        `${process.env.REACT_APP_APIURL}Tenants/${tenant}/Device/${variables.deviceId}/AccessRightsTag`,
        variables.body,
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onMutate: ({ deviceId }) =>
      setDevicesStatuses((prev) => ({
        ...prev,
        [deviceId]: { success: false, error: false, loading: true },
      })),
    onSuccess: (_, { deviceId }) =>
      setDevicesStatuses((prev) => ({
        ...prev,
        [deviceId]: { success: true, error: false, loading: false },
      })),
    onError: (_, { deviceId }) =>
      setDevicesStatuses((prev) => ({
        ...prev,
        [deviceId]: { success: false, error: true, loading: false },
      })),
  });

  return { ...mutation, devicesStatuses };
};

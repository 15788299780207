import React from 'react';

import styled from 'styled-components';

const Input = styled.input`
  width: inherit;
  background: transparent;
  outline: none;
  font-size: inherit;
  font-family: inherit;
  border: none;
  color: inherit;
  padding-left: 0.5rem;
`;

const NumberInput = ({ value, onChange }) => {
  const handleOnChange = (v) => {
    const x = v.replace(/\D+/g, '');
    onChange(x.length > 12 ? value : x);
  };

  return (
    <Input type="text" value={value} onChange={({ target }) => handleOnChange(target.value)} />
  );
};

export default NumberInput;

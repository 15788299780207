import styled from 'styled-components';

export const ReportCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 16px;

  .card {
    display: flex;
    flex-direction: column;

    &__title {
      padding-bottom: 24px;
      font-weight: 600;
    }
    &__description {
      padding-bottom: 24px;
      font-size: 14px;
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
`;

import styled from 'styled-components';

export const WidgetWrapper = styled.div<{ isFetching: boolean }>`
  ${(props) => props.theme.wrapper};
  padding: 2rem;
  margin: 1.5rem;
  color: ${(props) => props.theme.primary};
  font-size: ${(props) => props.theme.textSmall};
  position: relative;

  filter: blur(${(props) => (props.isFetching ? '10px' : '0px')});
`;

export const WidgetHeader = styled.div`
  margin: 0 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.secondary};

  & a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & h3 {
    font-weight: 600;
  }
`;

export const WidgetContent = styled.div`
  margin: 1rem;
  height: 90%;
`;

import { ApiMethod } from 'types';
import { handleReduxToolkitApiRequest } from 'utils/hooks/APIRequestsHandlers/handleReduxToolkitApiRequest';

export const updateUserDataInThunk =
  (urlSuffix: string) =>
    async ({ id, data }, { rejectWithValue, getState, dispatch }) => {
      const state: any = getState();
      const currentTenant = state.auth.user?.selectedTenant ?? '';

      await handleReduxToolkitApiRequest({
        method: ApiMethod.PUT,
        url: `${process.env.REACT_APP_API_URL_V2}tenants/${currentTenant}/users/${id}/${urlSuffix}`,
        rejectWithValue,
        data,
        dispatch,
        enableErrorHandler: true,
      });

      const changeData =
      Object.keys(data)[0] === 'tags' ? { accessRightsTag: data.tags?.[0]?.value || null } : data;

      return { id, data: changeData };
    };

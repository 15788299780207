import React, { useState, useEffect } from 'react';

import Modal from 'components/elements/Modal';
import AddButton from 'components/SettingsContainer/AddButton';
import styled from 'styled-components';

import EditRow from './EditRow';
import Icon from './Icon';
import Row from './Row';

const TableWrapper = styled.div<{ withInnerScroll?: boolean }>`
  position: relative;
  ${(props) => props.withInnerScroll && { overflow: 'auto' }};
`;

const STable = styled.table`
  font-size: ${(props) => props.theme.textSmall};
  width: 100%;
  border-collapse: collapse;
  & thead tr {
    & th {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.white};
      position: sticky;
      top: 0;
      font-weight: 500;
      text-align: left;
      padding: 1rem;
      z-index: 4;
    }
  }
  & tbody tr {
    transition: all 0.4s ease-in-out;
    cursor: pointer;

    & td {
      padding: 0.5rem;
      position: relative;
      &:last-of-type {
        text-align: right;
      }
    }
  }
  & tbody tr:nth-child(even) {
    background-color: ${({ theme }) => theme.offWhite};
  }
  & tbody tr:hover {
    background-color: #ececf7;
    & td {
      &:last-of-type {
        opacity: 1;
      }
    }
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  && h5 {
    font-size: inherit;
    margin: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem;

  & > button {
    margin: 0 1rem;
  }
`;

const SubmitButton = styled.button`
  ${(props) => props.theme.deleteButton()};
`;

const CancelButton = styled.button`
  ${(props) => props.theme.clearButton()};
`;

type TableProps = {
  headers: any;
  editHeaders: any;
  data: any;
  editData: any;
  setEditData: any;
  actions: any;
  canEdit?: boolean;
  canDelete?: boolean;
  canAdd?: boolean;
  validationSchema: any;
  title: any;
  styles?: {};
  showActionsInRow: any;
  withInnerScroll?: boolean;
};

const Table: React.FC<TableProps> = ({
  headers,
  editHeaders,
  data,
  editData,
  setEditData,
  actions,
  canEdit = false,
  canDelete = false,
  canAdd = false,
  validationSchema,
  title,
  styles = {},
  showActionsInRow,
  withInnerScroll,
}) => {
  const { handleAdd, handleEdit, handleDelete, handleReset, rowActions } = actions;
  const [editRow, setEditRow] = useState(-1);
  const [isAdd, setIsAdd] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [delIndex, setDelIndex] = useState(-1);
  const headerDisplay = headers.map((h) => h.display);

  const openModal = (i) => {
    setShowModal(true);
    setDelIndex(i);
  };

  const handleDeleteClick = () => {
    setShowModal(false);
    handleDelete(delIndex);
    setDelIndex(-1);
  };

  const handleAddClick = () => {
    if (!isAdd) {
      if (editRow !== -1) {
        handleEdit(editData);
      }
      handleAdd();
      setIsAdd(true);
      setEditRow(0);
    }
  };

  useEffect(() => {
    if (editRow !== -1) {
      setEditData(data[editRow]);
    } else {
      setEditData(null);
    }
    //TODO REFACTOR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRow]);
  const handleEditClick = (i) => setEditRow(i);
  const handleCancelClick = () => {
    if (isAdd) {
      setIsAdd(false);
      handleReset();
    }
    setEditRow(-1);
  };

  const handleOnChange = (value, name) =>
    setEditData({
      ...editData,
      [name]: value,
    });

  const handleUpdateClick = () => {
    handleEdit(editData, isAdd);
    if (isAdd) {
      setIsAdd(false);
      handleReset();
    }
    setEditRow(-1);
  };

  return (
    <>
      <HeadingWrapper>
        <h5>{title}</h5>
        {canAdd && <AddButton handleAddClick={handleAddClick} />}
      </HeadingWrapper>
      <TableWrapper style={styles} withInnerScroll={withInnerScroll}>
        <STable>
          <thead>
            <tr>
              {headerDisplay.map((h, i) => (
                <th key={h + i}>{h}</th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) =>
              editData && editRow === i ? (
                <EditRow
                  key={i}
                  rowData={editData}
                  properties={editHeaders}
                  validationSchema={validationSchema}
                  handleOnChange={handleOnChange}
                  rowActions={{
                    handleUpdateClick: () => handleUpdateClick(),
                    handleCancelClick: () => handleCancelClick(),
                  }}
                />
              ) : (
                <Row
                  key={i}
                  rowData={d}
                  properties={headers}
                  rowActions={
                    <>
                      {rowActions && rowActions(i)}
                      {canEdit && <Icon type="edit" onClick={() => handleEditClick(i)} />}
                      {canDelete && <Icon type="trash" onClick={() => openModal(i)} />}
                    </>
                  }
                  showActionsInRow={showActionsInRow}
                />
              )
            )}
          </tbody>
        </STable>
      </TableWrapper>
      <Modal showModal={showModal} handleClose={() => setShowModal(false)} title="Delete">
        <p>Are you sure you want to delete this {title.slice(0, -1).toLowerCase()}?</p>
        <ButtonWrapper>
          <SubmitButton onClick={() => handleDeleteClick()}>Delete</SubmitButton>
          <CancelButton onClick={() => setShowModal(false)}>Cancel</CancelButton>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default Table;

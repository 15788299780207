import React from 'react';

import styled from 'styled-components';

import Search from './Search';

const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ActionsLeft = styled.div`
  flex: 1;
`;

const ActionSpan = styled.span<{ isDisabled: boolean }>`
  display: inline-block;
  padding: 0.75rem 1rem;
  font-size: 1.4rem;
  opacity: ${(props) => (props.isDisabled ? 0.3 : 0.7)};
  transition: all 0.5s ease-in-out;
  background: transparent;
  color: inherit;

  ${(props) =>
    props.isDisabled
      ? ''
      : `&:hover {
        background: #ececf7;
        cursor: pointer;
        opacity: 1;
    }`};
`;

const Sep = styled.span`
  color: inherit;
  opacity: 0.8;
`;

const Seperator = () => <Sep>|</Sep>;

export const Action = ({
  onClick,
  isDisabled,
  children,
}: {
  onClick: (key?: any) => void;
  isDisabled: any;
  children?: React.ReactNode;
}) => {
  const handleOnClick = () => {
    if (!isDisabled) onClick();
  };

  return (
    <ActionSpan isDisabled={isDisabled} onClick={handleOnClick}>
      {children}
    </ActionSpan>
  );
};

const Actions = ({ searchProps, actions }) => (
  <ActionsWrapper>
    <ActionsLeft>
      {actions.map((action, i: number) => (
        <span key={`${action.key}_${i}`}>
          {action}
          {i < actions.length - 1 && <Seperator />}
        </span>
      ))}
    </ActionsLeft>
    <Search {...searchProps} />
  </ActionsWrapper>
);

export default Actions;

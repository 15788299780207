import React from 'react';

import styled from 'styled-components';

import FormsTable from '../FormsTable';

const Wrapper = styled.div`
  padding-bottom: 2rem;

  & p,
  & input,
  & textarea,
  label {
    font-size: ${(props) => props.theme.textSmall};
    margin-bottom: 2rem;
  }

  & h3 {
    font-weight: 500;
  }
`;

const Header = styled.div`
  padding: 2rem;
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.white};
`;

const WrapperTop = styled.div`
  padding: 2rem 2rem 0 2rem;

  & hr {
    background-color: ${(props) => props.theme.secondary};
    height: 1px;
    border: none;
  }
`;

const WrapperBottom = styled.div`
  padding: 2rem;

  & h3 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
`;

const FormsWrapper = styled.div`
  max-height: 35rem;
  overflow-y: auto;
  margin-top: 1.5rem;
  padding: 0px 1rem;
`;

const DeviceActions = ({ device, title, fault, form }) => {
  const getType = () => {
    if (title === 'Notes') {
      return 'notes';
    }

    return title === 'Device Logs' ? 'all' : 'repair';
  };

  const type = getType();

  return (
    <Wrapper>
      <Header>
        <h3>{title}</h3>
      </Header>
      {fault && <WrapperTop>{fault}</WrapperTop>}
      <WrapperBottom>{form}</WrapperBottom>
      <FormsWrapper>
        <FormsTable {...device} type={type} />
      </FormsWrapper>
    </Wrapper>
  );
};

export default DeviceActions;

import React from 'react';

import styled from 'styled-components';
import { mapDeviceTypes, mapStatus } from 'utils/deviceUtils';

const IconWrapper = styled.span<{ status: string; size: number }>`
  display: inline-block;
  height: ${(props) => props.size}rem;
  width: ${(props) => props.size}rem;

  & svg {
    fill: ${(props) => props.theme.statusColor(props.status)};
    width: 100%;
    height: 100%;
  }
`;

const DeviceIcon = ({ deviceType, status, size }) => {
  const icon = mapDeviceTypes(deviceType);

  return (
    <IconWrapper className="device-icon__wrapper" status={mapStatus(status)} size={size}>
      <svg>
        <use href={`images/icon-${icon}.svg#icon-${icon}`} />
      </svg>
    </IconWrapper>
  );
};

export default DeviceIcon;

import React from 'react';

import styled from 'styled-components';

const FaultDetails = styled.div`
  padding: 2rem;
  margin-bottom: 3rem;
  font-size: ${(props) => props.theme.textSmall};
  background: ${(props) => props.theme.error};
  color: ${(props) => props.theme.white};

  & span {
    font-weight: 600;
  }

  & div:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const DeviceFault = ({ message, faultDetails, action }) => (
  <>
    <p>{message}</p>
    <FaultDetails>
      {faultDetails.map((d) => (
        <div key={d.label}>
          <span>{d.label} :</span> {d.text}
        </div>
      ))}
    </FaultDetails>
    {action}
    <hr />
  </>
);

export default DeviceFault;

import React, { useState } from 'react';
import { Button } from '@mui/material';

import { ActionDialog } from 'components/molecules';
import { useAlert } from 'utils/hooks';
import { usePostFetchData } from 'utils/hooks/APIRequestsHandlers';

type ResetDeviceProps = {
  serialNumber: string;
  deviceId: string;
  refresh?: () => void;
  deviceName: string;
  apiData: {
    url: string;
    errorMessage: string;
    successMessage: string;
  };
};

export const ResetDevice: React.FC<ResetDeviceProps> = ({
  serialNumber,
  deviceId,
  apiData,
  deviceName,
  refresh,
}) => {
  const [open, setOpen] = useState(false);
  const { errorAlert } = useAlert();

  const handleClose = () => {
    setOpen(false);
  };

  const { mutate, isLoading, isSuccess, reset } = usePostFetchData({
    url: apiData.url,
    onError: (e) => {
      const message = typeof e.response.data === 'string' ? e.response.data : apiData.errorMessage;

      errorAlert(message);
      handleClose();
    },
  });

  const handleSubmit = () => {
    mutate({ deviceId });
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleOnSuccess = () => {
    handleClose();
    refresh?.();
    reset();
  };

  return (
    <>
      <Button variant="contained" data-testid="reset-button" color="info" onClick={handleOpen}>
        Reset {deviceName}
      </Button>
      <ActionDialog
        open={open}
        onClose={handleClose}
        handleSubmit={handleSubmit}
        title={`${deviceName} Reset`}
        submitButtonName="Reset"
        sendingRequest={isLoading}
        successMessageProps={{
          message: apiData.successMessage,
          showMessage: isSuccess,
          onClose: handleOnSuccess,
        }}
      >
        Are you sure you want to reset {serialNumber}?
      </ActionDialog>
    </>
  );
};

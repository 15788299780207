import React, { useState, useEffect } from 'react';

import { CsvButton } from 'components/atoms/CsvLink';
import ResultsGraph from 'components/elements/Graphs/ResultsGraph';
import Modal from 'components/elements/Modal';
import UpdateTestModal from 'components/elements/UpdateTestModal';
import { useDispatch } from 'react-redux';
import { updateTest } from 'reducers/testResults';
import styled from 'styled-components';
import { handleFormReq } from 'utils/ApiRequestHelpers';
import { formatDate } from 'utils/helpers';
import useFilters from 'utils/hooks/useFilters';

import DataTable from '../DataTable';
import { GraphDataType, TestDataType } from './types';

const GraphInfo = styled.div`
  font-size: ${(props) => props.theme.textSmall};

  & div {
    margin-bottom: 0.5rem;
  }

  & span {
    color: ${(props) => props.theme.darkBlue};
  }
`;

const TestResultsTable = ({ data, headers, csvHeaders, isFetching }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [graphData, setGraphData] = useState<GraphDataType>();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [testData, setTestData] = useState<TestDataType>();
  const [testUpdated, setTestUpdated] = useState(false);

  const { filters, clearFilters, filteredData } = useFilters(data, [
    'testType',
    'testResult',
    'date',
    'minDate',
    'maxDate',
  ]);

  const handleOnClear = () => {
    clearFilters();
  };

  useEffect(() => {
    setTestUpdated(true);
  }, [data]);

  useEffect(() => {
    setTestUpdated(false);
  }, [testUpdated]);

  const getTestData = (data) => ({
    hasData: true,
    deviceName: data.deviceName,
    testDate: data.testDate,
    deviceBatteryVoltage: data.deviceBatteryVoltage,
    deviceCurrentStatus: data.deviceCurrentStatus,
    testType: data.testType,
    xAxis: {
      name: 'Time in seconds',
      data: data.time,
    },
    series: Object.keys(data.data).map((key) => ({
      name: key,
      data: data.data[key],
    })),
  });

  const handleCSVClick = async () => filteredData;

  const handleSparkClick = (data) => {
    setGraphData(data);
    setShowModal(true);
  };

  const handleEditClick = (data) => {
    setTestData(data);
    setGraphData(getTestData(data));
    setShowUpdateModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleCancel = (e) => {
    e.preventDefault();
    setShowUpdateModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    if (testData) {
      data.append('deviceId', testData?.deviceId);
    }

    const updatedTest = await handleFormReq('UpdateTestResult', data, 'POST');
    dispatch(updateTest(updatedTest));
    setShowUpdateModal(false);
  };

  const rowFunctions = {
    handleSparkClick: handleSparkClick,
    getSparkData: getTestData,
    handleEditClick: handleEditClick,
  };

  const TableButtons = () => (
    <CsvButton headers={csvHeaders} handleClickCallBack={() => handleCSVClick()}>
      Download report
    </CsvButton>
  );

  return (
    <>
      {filteredData && (
        <DataTable
          data={filteredData}
          headers={headers}
          isFetching={isFetching}
          hasUpdate={false}
          handleOnClear={handleOnClear}
          filters={filters}
          tblButtons={<TableButtons />}
          pageUpdateCb={undefined}
          rowFunctions={rowFunctions}
        />
      )}
      <Modal handleClose={handleClose} showModal={showModal} title="Test Results">
        {graphData && (
          <>
            <GraphInfo>
              <div>
                <span>Device Name: </span>
                {graphData.deviceName}
              </div>
              <div>
                <span>Tested: </span>
                {formatDate(graphData.testDate)}
              </div>
              <div>
                <span>Test Name: </span>
                {graphData.testType}
              </div>
            </GraphInfo>
            <ResultsGraph
              legendAlignment={'left'}
              data={graphData.series}
              xAxis={graphData.xAxis}
              isAdmin
            />
          </>
        )}
      </Modal>
      <UpdateTestModal
        graphData={graphData}
        testData={testData}
        setShowUpdateModal={setShowUpdateModal}
        showUpdateModal={showUpdateModal}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default TestResultsTable;

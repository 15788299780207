import React from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import styled from 'styled-components';

const SAddButton = styled.button`
  ${(props) => props.theme.submitButton('8rem')};
  display: flex;
  margin: 1rem;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.textSmall};

  & svg {
    display: inline-block;
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    fill: ${(props) => props.theme.white};
  }
`;

const AddButton = ({ handleAddClick }) => (
  <SAddButton onClick={() => handleAddClick()}>
    Add
    <SvgIcon type="plus" />
  </SAddButton>
);

export default AddButton;

import { styled as styledMui } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';

export const StyledTableCell = styledMui(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    backgroundColor: '#3f4553',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingTop: 4,
    paddingBottom: 4,
  },
}));

export const StyledTableRow = styledMui(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const TagsTableWrapper = styled.div`
  padding-bottom: 12px;
  position: relative;

  .tags-table {
    position: relative;
    margin-top: 4px;
    border-radius: 2px;

    &__container {
      border-radius: 2px;
    }

    &__linear-progress {
      position: absolute;
      top: -4px;
      left: 0;
      width: 100%;
    }

    &__icons {
      display: flex;
      flex-wrap: nowrap;
      gap: 5px;
      justify-content: flex-end;
    }

    &__delete-icon {
      &:hover {
        color: ${(props) => props.theme.vericonRed};
      }
    }
  }
`;

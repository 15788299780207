import { batteryTypes } from 'types/DeviceTypes/Device';
import useSchemaValidation from 'utils/hooks/useSchemaValidation';
import {
  isRequired,
  isNumber,
  allowedValuesValidator,
  isNotZero,
  isLessThanOrEqualTo,
  isGreaterThanOrEqualTo,
} from 'utils/inputValidation';

const useLuminaireForm = (lumninaireInfo) => {
  const locationValidationSchema = {
    name: [{ fn: isRequired, msg: 'Name is required' }],
    lampType: [{ fn: isRequired, msg: 'Lamp type is required' }],
    inverterType: [{ fn: isRequired, msg: 'Inverter type is required' }],
    batteryType: [
      { fn: isRequired, msg: 'Battery type is required' },
      { fn: allowedValuesValidator(batteryTypes), msg: 'Please select battery type from list' },
    ],
    batteryVoltage: [
      { fn: isRequired, msg: 'Battery Voltage is required' },
      { fn: isNumber, msg: 'Battery Voltage needs to be a number' },
      { fn: isGreaterThanOrEqualTo(2.6), msg: 'Battery Voltage must be greater than 2.6V' },
      { fn: isLessThanOrEqualTo(12), msg: 'Battery Voltage can not be greater than 12V' },
    ],
    batteryCapacity: [
      { fn: isRequired, msg: 'Battery Capacity is required' },
      { fn: isNumber, msg: 'Battery Capacity needs to be a number' },
      { fn: isNotZero, msg: 'Battery Capacity must be greater than 0' },
      { fn: isLessThanOrEqualTo(20), msg: 'Battery Capacity must be less than 20Ah' },
    ],
  };

  const { validationState, handleValueUpdate, validateSchema } = useSchemaValidation(
    lumninaireInfo,
    locationValidationSchema
  );

  const handleLuminaireUpdate = (value, name) => handleValueUpdate(value, name, false);

  return {
    luminaireState: validationState,
    handleLuminaireUpdate,
    validateSchema,
  };
};

export default useLuminaireForm;

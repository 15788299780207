import { useCallback, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { fetchData } from 'utils';
import { PropertyTelemetryOptions, Property } from 'types';
import { useAlert, useSelectedTenant, useEffectOnce, useApiStatus } from 'utils/hooks';
import {
  ChartNames,
  DeviceMetricsResponse,
  EventTimelineResponse,
  TelemetryView,
} from 'types/Telemetry';
import { PropertyContextProps } from '../../types';
import { getTelemetryUrls, setMetricData } from '../utils';
import { useTelemetryViews } from './useTelemetryViews';
import { useTransformEventTimelineData } from '.';

type UseFetchTelemetryProps = {
  startTime: string;
  endTime: string;
};

type HandleTelemetryProps = {
  start: string;
  end: string;
  currentTelemetryView?: TelemetryView;
};

export const useTelemetry = ({ startTime, endTime }: UseFetchTelemetryProps) => {
  const { propertyInfo } = useOutletContext<PropertyContextProps>();
  const { propertyId } = propertyInfo?.data as Property;

  const tenant = useSelectedTenant();
  const { errorAlert } = useAlert();
  const { TELEMETRY_EVENTS_URL, TELEMETRY_METRICS_URL } = getTelemetryUrls(tenant);

  const propertyTelemetryViewsCharts = useTelemetryViews();

  const [currentView, setCurrentView] = useState<TelemetryView>({
    charts: propertyTelemetryViewsCharts[PropertyTelemetryOptions.PropertySummary],
    name: PropertyTelemetryOptions.PropertySummary,
  });
  const currentViewIndexes: string[] = currentView.charts?.map((_, chatIndex) =>
    chatIndex.toString()
  );

  const {
    status: chartsStatus,
    setLoadingStatus,
    setSuccessStatus,
    setErrorStatus,
    updateStatus,
  } = useApiStatus(currentViewIndexes);

  const transformTelemetryEventData = useTransformEventTimelineData();

  const handleTelemetryApi = useCallback(
    async ({ start, end, currentTelemetryView }: HandleTelemetryProps) => {
      if (!start || !end) {
        return;
      }

      (currentTelemetryView || currentView).charts?.forEach(async (chart, chartIndex) => {
        setLoadingStatus(chartIndex.toString());
        const isEventChart = chart.type === ChartNames.EventTimeline;

        let response: EventTimelineResponse | DeviceMetricsResponse;

        if (isEventChart) {
          response = await fetchData({
            url: `${TELEMETRY_EVENTS_URL}?propertyId=${propertyId}&from=${start}&to=${end}`,
            errorAlert,
            enableErrorHandler: true,
          });
        } else {
          const queryString = `?propertyId=${
            propertyId
          }&from=${start}&to=${end}&metricKey=${chart.metricKey}&propertyLocationId=${chart.propertyLocationId}`;

          response = await fetchData({
            url: `${TELEMETRY_METRICS_URL}${queryString}`,
            errorAlert,
            enableErrorHandler: true,
          });
        }

        if (response) {
          setCurrentView((prev: TelemetryView) =>
            isEventChart
              ? transformTelemetryEventData({ prev, data: response as EventTimelineResponse })
              : setMetricData({ prev, chart, response: response as DeviceMetricsResponse })
          );

          setSuccessStatus(chartIndex.toString());
        } else {
          setErrorStatus(chartIndex.toString());
        }
      });
    },
    [
      TELEMETRY_EVENTS_URL,
      TELEMETRY_METRICS_URL,
      currentView,
      errorAlert,
      propertyId,
      setErrorStatus,
      setLoadingStatus,
      setSuccessStatus,
      transformTelemetryEventData,
    ]
  );

  const handleTelemetryViewChange = ({ target }) => {
    const currentTelemetryView = {
      charts: propertyTelemetryViewsCharts[target.value],
      name: target.value,
    } as TelemetryView;

    const currentTelemetryViewTitles: string[] = currentTelemetryView.charts?.map(
      (chart) => chart.title
    );
    updateStatus(currentTelemetryViewTitles);

    setCurrentView(currentTelemetryView);
    handleTelemetryApi({
      start: startTime,
      end: endTime,
      currentTelemetryView,
    });
  };

  const handleUpdateDateRange = ([start, end]) => {
    if (start && end) {
      handleTelemetryApi({
        start: start.utc().startOf('day').toISOString(),
        end: end.utc().endOf('day').toISOString(),
      });
    }
  };

  const propertyTelemetryViewsList = Object.keys(propertyTelemetryViewsCharts);

  useEffectOnce(() => {
    handleTelemetryApi({ start: startTime, end: endTime });
  });

  return {
    handleUpdateDateRange,
    handleTelemetryViewChange,
    propertyTelemetryViewsList,
    currentView,
    chartsStatus,
  };
};

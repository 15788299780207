import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { fetchRepairForms } from 'reducers/repairForms';

import DeviceMaintenanceForm from '../DeviceMaintenanceForm';

const BoilerForm = ({ device }) => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const formItems = [
    {
      name: 'description',
      type: 'textArea',
      label: 'Action(s)',
    },
  ];

  const handleRefetch = () => dispatch(fetchRepairForms(device.deviceId, device.deviceType));

  return (
    <>
      <DeviceMaintenanceForm
        title="Fault Resolution Action"
        message="Please type a short description of the action(s) taken to resolve the fault"
        formItems={formItems}
        device={device}
        endPoint="BoilerRepairForm"
        fetchAction={handleRefetch}
        btnText="Repair Device"
        disabled={isButtonDisabled}
        setIsButtonDisabled={setIsButtonDisabled}
      />
    </>
  );
};

export default BoilerForm;

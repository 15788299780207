import styled from 'styled-components';

export const UpdateAccessRightsTagConstrainer = styled.div`
  padding: 20px;
  min-width: 300px;
  font-size: 1.44rem;

  .device-access-rights-tag__content {
    justify-content: space-between;
  }

  & .MuiInputBase-root {
    font-size: 12px;
  }

  @media (min-width: 768px) {
    min-width: 450px;

    & .MuiInputBase-root {
      font-size: 16px;
    }
  }
`;


import React from 'react';

import { useSelector } from 'react-redux';

import FloorPlanMap from '../components/FloorPlan';

const FloorPlan = () => {
  const devices = useSelector((state: any) => state.mapData);
  const buildingInfo = useSelector((state: any) => state.buildingInfo.buildings);

  return (
    <>
      {(buildingInfo.length > 0 || devices.length > 0) && (
        <FloorPlanMap buildingInfo={buildingInfo} devices={devices} />
      )}
    </>
  );
};

export default FloorPlan;

import { DeviceTypes } from '../types/DeviceTypes/Device';
import { Areas } from '../types/roles';
import { contains, filter } from './helpers';

const isObject = (obj) => typeof obj === 'object' && obj !== null && !Array.isArray(obj);

export const isOnline = (obj) => {
  const onlineValues = ['OK'];
  if (isObject(obj)) return onlineValues.includes(obj['deviceStatus'].toUpperCase());

  return onlineValues.includes(obj?.toUpperCase());
};

export const isOffline = (obj) => {
  const onlineValues = [
    'INVENTORY',
    'UNKNOWN',
    'FACTORY',
    'REMOVED',
    'REPAIR',
    'DISPOSED',
    'AWAITINGRECYCLING',
  ];
  if (isObject(obj)) return onlineValues.includes(obj['deviceStatus'].toUpperCase());

  return onlineValues.includes(obj?.toUpperCase());
};

export const isWarning = (obj) => {
  const warningValues = [
    'DISABLEDNONPAYMENT',
    'DISABLEDOTHER',
    'PREVENTATIVEMAINTENANCE',
    'REPAIREDDEVICE',
    'REPAIREDEQUIPMENT',
    'ALLOCATED',
    'INSTALLED',
  ];
  if (isObject(obj)) return warningValues.includes(obj['deviceStatus'].toUpperCase());

  return warningValues.includes(obj?.toUpperCase());
};

export const isFailed = (obj) => {
  const warningValues = ['FAILEDEQUIPMENT'];
  if (isObject(obj)) return warningValues.includes(obj['deviceStatus'].toUpperCase());

  return warningValues.includes(obj?.toUpperCase());
};

export const isFailedDevice = (obj) => {
  const warningValues = ['FAILEDDEVICE'];
  if (isObject(obj)) return warningValues.includes(obj['deviceStatus'].toUpperCase());

  return warningValues.includes(obj?.toUpperCase());
};

export const isInventory = (obj) => {
  if (isObject(obj)) return obj['deviceStatus']?.toUpperCase() === 'INVENTORY';

  return obj?.toUpperCase() === 'INVENTORY';
};

export const isInWholesale = (obj) => {
  if (isObject(obj)) return obj.inWholesale === true;

  return obj === true;
};

const _isDeviceType = (fn) => (obj) => isObject(obj) ? fn(obj.deviceType) : fn(obj);
const _isEmergencyLight = (str) =>
  str?.includes(DeviceTypes.EmergencyLighting) ||
  str?.includes(DeviceTypes.EmergencyLightingLegacy);
const _isBoiler = (str) =>
  str?.includes(DeviceTypes.Boiler) || str?.includes(DeviceTypes.BcmConnect);
const _isIdealBC = (str) => str?.includes(DeviceTypes.Boiler);
const _isBcmConnect = (str) => str?.includes(DeviceTypes.BcmConnect);
const _isLiftMonitor = (str) => str?.includes(DeviceTypes.LiftMonitor);
const _isPeopleCounter = (str) => str?.includes(DeviceTypes.PeopleCounter);
const _isNetworkDevice = (str) =>
  contains(str, [DeviceTypes.ZigbeeGateway, DeviceTypes.ZigbeeExt, DeviceTypes.MqttGateway]);
const _isZigbeeGateway = (str) => str?.includes(DeviceTypes.ZigbeeGateway);
const _isZigbeeExt = (str) => str?.includes(DeviceTypes.ZigbeeExt);
const _isMqttGateway = (str) => str?.includes(DeviceTypes.MqttGateway);
const _isAutofill = (str) => str?.includes(DeviceTypes.Autofill);
const _isAirSourceHeatPump = (str) => str?.includes(DeviceTypes.AirSourceHeatPump);
const _isMultiDot = (str) => str?.includes(DeviceTypes.MultiDot);
const _isEnviroDot = (str) => str?.includes(DeviceTypes.EnviroDot);
const _isHeatInterfaceUnit = (str) => str?.includes(DeviceTypes.HeatInterfaceUnit);
const _isPowerMeter = (str) => str?.includes(DeviceTypes.PowerMeter);
const _isAdey = (str) => str?.includes(DeviceTypes.Adey);

export const isEmergencyLight = _isDeviceType(_isEmergencyLight);
export const isBoiler = _isDeviceType(_isBoiler);
export const isIdealBC = _isDeviceType(_isIdealBC);
export const isBcmConnect = _isDeviceType(_isBcmConnect);
export const isAutofill = _isDeviceType(_isAutofill);
export const isAirSourceHeatPump = _isDeviceType(_isAirSourceHeatPump);
export const isMultiDot = _isDeviceType(_isMultiDot);
export const isEnviroDot = _isDeviceType(_isEnviroDot);
export const isHeatInterfaceUnit = _isDeviceType(_isHeatInterfaceUnit);
export const isPowerMeter = _isDeviceType(_isPowerMeter);
export const isAdey = _isDeviceType(_isAdey);

const isLiftMonitor = _isDeviceType(_isLiftMonitor);
const isPeopleCounter = _isDeviceType(_isPeopleCounter);
export const isNetworkDevice = _isDeviceType(_isNetworkDevice);
const isZigbeeGateway = _isDeviceType(_isZigbeeGateway);
export const isZigbeeExt = _isDeviceType(_isZigbeeExt);
const isMqttGateway = _isDeviceType(_isMqttGateway);

export const mapStatus = (status: string) => {
  if (status) {
    const str = status.toUpperCase();
    if (isOnline(str)) return 'Passed';
    if (isWarning(str)) return 'Warning';
    if (isOffline(str)) return 'NotInstalled';
    if (isFailed(str)) return 'Failed';
    if (isFailedDevice(str)) return 'FailedDevice';
  }

  return 'Default';
};

export const mapConnectionStatus = (status: string) => {
  if (status) {
    const str = status.toLowerCase();
    switch (str) {
      case 'online':
        return 'Connected';
      case 'offline':
        return 'NotConnected';
      case 'intermittent':
        return 'Intermittent';
      case 'neverconnected':
        return 'NeverConnected';
      default:
        return 'Default';
    }
  }

  return 'Default';
};

export const mapDeviceTypes = (deviceType) => {
  if (deviceType) {
    if (isEmergencyLight(deviceType)) return 'emergency-light';
    if (isBoiler(deviceType)) return 'boiler';
    if (isLiftMonitor(deviceType)) return 'lift-monitor';
    if (isPeopleCounter(deviceType)) return 'people-counter';
    if (isZigbeeGateway(deviceType)) return 'zigbee-gateway';
    if (isZigbeeExt(deviceType)) return 'zigbee-repeater';
    if (isMqttGateway(deviceType)) return 'mqtt-gateway';
    if (isAutofill(deviceType)) return 'autofill';
    if (isAirSourceHeatPump(deviceType)) return 'air-source-heat-pump';
    if (isMultiDot(deviceType)) return 'multidot';
    if (isEnviroDot(deviceType)) return 'envirodot';
    if (isHeatInterfaceUnit(deviceType)) return 'heat-interface-unit';
    if (isPowerMeter(deviceType)) return 'power-meter';
    if (isAdey(deviceType)) return 'adey';
  }

  return 'unknown';
};

export const mapDeviceTypesTo2Let = (deviceType) => {
  if (deviceType) {
    if (isEmergencyLight(deviceType)) return 'EL';
    if (isBoiler(deviceType)) return 'BC';
    if (isLiftMonitor(deviceType)) return 'LM';
    if (isPeopleCounter(deviceType)) return 'PC';
    if (isZigbeeGateway(deviceType)) return 'ZG';
    if (isZigbeeExt(deviceType)) return 'ZR';
    if (isMqttGateway(deviceType)) return 'MG';
  }

  return 'unknown';
};

export const deviceTypeToArea = (deviceType) => {
  if (deviceType) {
    if (isEmergencyLight(deviceType)) return Areas.lighting;
    if (isBoiler(deviceType)) return Areas.boilers;
    if (isLiftMonitor(deviceType)) return Areas.liftMonitors;
    if (isPeopleCounter(deviceType)) return Areas.peopleCounters;
    if (isZigbeeGateway(deviceType)) return Areas.networking;
    if (isZigbeeExt(deviceType)) return Areas.networking;
    if (isMqttGateway(deviceType)) return Areas.networking;
  }

  return Areas.misc;
};

export const mapDeviceTypesToUrl = (deviceType) => {
  if (deviceType) {
    if (isEmergencyLight(deviceType)) return 'EmergencyLights';
    if (isBoiler(deviceType)) return 'Boilers';
    if (isAutofill(deviceType)) return 'Boilers';
    if (isMultiDot(deviceType)) return 'Boilers';
    if (isEnviroDot(deviceType)) return 'Boilers';
    if (isPowerMeter(deviceType)) return 'Boilers';
    if (isNetworkDevice(deviceType)) return 'NetworkingDevices';
  }

  return 'Dashboard';
};

export const mapDeviceTypesToDisplay = (deviceType) => {
  if (deviceType) {
    if (isEmergencyLight(deviceType)) return `EmeRed (${deviceType.split('.')[0]})`;
    if (isIdealBC(deviceType))
      return `Boiler Controller Model ${deviceType.split('.')[1] ? deviceType.split('.')[1] : ''}`;
    if (isBcmConnect(deviceType)) return 'Boiler Control Module';
    if (isLiftMonitor(deviceType)) return 'Lift Monitor';
    if (isPeopleCounter(deviceType)) return 'People Counter';
    if (isZigbeeExt(deviceType)) return 'Zigbee Extender';
    if (isZigbeeGateway(deviceType)) return 'Zigbee Gateway';
    if (isMqttGateway(deviceType)) return 'MQTT Gateway';
  }

  return 'unknown';
};

export const deviceToString = (deviceType) => {
  if (deviceType) {
    if (isEmergencyLight(deviceType)) return 'Emergency Lights';
    if (isBoiler(deviceType)) return 'Boilers';
    if (isLiftMonitor(deviceType)) return 'Lift Monitors';
    if (isPeopleCounter(deviceType)) return 'People Counters';
    if (isNetworkDevice(deviceType)) return 'Network Devices';
    if (isMultiDot(deviceType)) return 'Multidots';
    if (isEnviroDot(deviceType)) return 'Envirodots';
    if (isAutofill(deviceType)) return 'Autofills';
    if (isHeatInterfaceUnit(deviceType)) return 'Heat Interface Units';
    if (isAirSourceHeatPump(deviceType)) return 'Air Source Heat Pumps';
    if (isPowerMeter(deviceType)) return 'PowerSenses';
    if (isAdey(deviceType)) return 'ADEY Devices';
  }

  return 'unknown';
};

export const getDeviceFormsEndpoint = (deviceType) => {
  if (isEmergencyLight(deviceType)) return 'GetEmeredRepairForms';
  if (isBoiler(deviceType)) return 'GetBoilerRepairForms';
  if (isLiftMonitor(deviceType)) return 'GetLiftMonitorsForms';
  if (isPeopleCounter(deviceType)) return 'GetPeopleCountersForms';
  if (isNetworkDevice(deviceType)) return 'GetNetworkDevicesForms';
};

const filterDevices =
  (...fns) =>
    (devices) =>
      fns.reduce((y, f) => f(y), devices);

export const getBuildingDevices = (building) => filter((d) => d.building === building);
export const getEmereds = filter((d) => isEmergencyLight(d.deviceType));
export const getBuildingEmereds = (building) =>
  filterDevices(getBuildingDevices(building), getEmereds);

const DeviceDetailsUrl = (deviceType) => {
  if (isEmergencyLight(deviceType)) return 'EmeredDetails';
  if (isBoiler(deviceType)) return 'BcmDetails';
  if (isNetworkDevice(deviceType)) return 'NetworkDetails';

  return '';
};

const DeviceInstallUrl = (deviceType) => {
  if (isEmergencyLight(deviceType)) return 'EmeredInstall';
  if (isBoiler(deviceType)) return 'BcmInstall';
  if (isNetworkDevice(deviceType)) return 'NetworkInstall';

  return '';
};

const DeviceClaimFromWholesaleUrl = (deviceSerial) => `MoveFromWholesale/${deviceSerial}`;

export const getEngAppUrl = ({ deviceId, deviceType, deviceStatus, serialNumber, inWholesale }) => {
  let url = process.env.REACT_APP_ENG_URL;
  if (isInWholesale(inWholesale)) {
    return url + DeviceClaimFromWholesaleUrl(serialNumber);
  } else if (isInventory(deviceStatus)) {
    url += DeviceInstallUrl(deviceType);
  } else {
    url += DeviceDetailsUrl(deviceType);
  }

  return `${url}/${deviceId}`;
};

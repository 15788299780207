import styled from 'styled-components';

export const SidebarItemWrapper = styled.li`
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  display: block;

  .sidebar-item {
    &__button {
      color: ${(props) => props.theme.white};
      height: 4.5rem;
      padding-right: 14px;
      padding-left: 14px;
      transition: all 0.2s;

      @media ${(props) => props.theme.mobileLand} {
        padding-right: 16px;
        padding-left: 16px;
        height: 48px;
      }

      &:hover {
        background: ${(props) => props.theme.gradient} var(--x, 0) / 180%;
      }

      &-icon {
        min-width: 0;
        justify-content: center;
        margin-right: 1.5rem;

        &-svg {
          color: ${(props) => props.theme.white};
          fill: ${(props) => props.theme.white};
          width: 25px;
          height: 25px;
        }
      }

      &-text {
        span {
          font-size: inherit;
          line-height: 1.25;
          white-space: nowrap;
        }

        font-size: 1.44rem;

        @media ${(props) => props.theme.mobileLand} {
          font-size: 1.8rem;
        }
      }

      &.selected {
        background: ${(props) => props.theme.gradient};
      }

      &.sub-menu-item {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

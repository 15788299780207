import { AccessRightsTag } from './types';

export enum ActionTypes {
  CREATE_ACCESS_RIGHTS_TAGS_REQUEST_START = 'CREATE_ACCESS_RIGHTS_TAGS_REQUEST_START',
  CREATE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS = 'CREATE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS',
  CREATE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE = 'CREATE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE',
  FETCH_ACCESS_RIGHTS_TAGS_REQUEST_START = 'FETCH_ACCESS_RIGHTS_TAGS_REQUEST_START',
  FETCH_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS = 'FETCH_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS',
  FETCH_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE = 'FETCH_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE',
  UPDATE_ACCESS_RIGHTS_TAGS_REQUEST_START = 'UPDATE_ACCESS_RIGHTS_TAGS_REQUEST_START',
  UPDATE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS = 'UPDATE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS',
  UPDATE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE = 'UPDATE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE',
  DELETE_ACCESS_RIGHTS_TAGS_REQUEST_START = 'DELETE_ACCESS_RIGHTS_TAGS_REQUEST_START',
  DELETE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS = 'DELETE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS',
  DELETE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE = 'DELETE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE',
  CLEAR_CREATE_ACCESS_RIGHTS_TAGS_ERROR = 'CLEAR_CREATE_ACCESS_RIGHTS_TAGS_ERROR',
  CLEAR_DELETE_ACCESS_RIGHTS_TAGS_ERROR = 'CLEAR_DELETE_ACCESS_RIGHTS_TAGS_ERROR',
  CLEAR_ACCESS_RIGHTS_TAGS = 'CLEAR_ACCESS_RIGHTS_TAGS',
}
interface FetchRequestStartAction {
  type: ActionTypes.FETCH_ACCESS_RIGHTS_TAGS_REQUEST_START;
}

interface FetchRequestSuccessAction {
  type: ActionTypes.FETCH_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS;
  payload: AccessRightsTag[];
}

interface FetchRequestFailureAction {
  type: ActionTypes.FETCH_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE;
}

export type FetchAction =
  | FetchRequestStartAction
  | FetchRequestSuccessAction
  | FetchRequestFailureAction;

interface CreateRequestStartAction {
  type: ActionTypes.CREATE_ACCESS_RIGHTS_TAGS_REQUEST_START;
}

interface CreateRequestSuccessAction {
  type: ActionTypes.CREATE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS;
}

interface CreateRequestFailureAction {
  type: ActionTypes.CREATE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE;
  errorMessage: string;
}

interface ClearCreateErrorAction {
  type: ActionTypes.CLEAR_CREATE_ACCESS_RIGHTS_TAGS_ERROR;
}

export type CreateAction =
  | CreateRequestStartAction
  | CreateRequestSuccessAction
  | CreateRequestFailureAction
  | ClearCreateErrorAction;

interface DeleteRequestStartAction {
  type: ActionTypes.DELETE_ACCESS_RIGHTS_TAGS_REQUEST_START;
}

interface DeleteRequestSuccessAction {
  type: ActionTypes.DELETE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS;
}

interface DeleteRequestFailureAction {
  type: ActionTypes.DELETE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE;
  errorMessage: string;
}

interface ClearDeleteErrorAction {
  type: ActionTypes.CLEAR_DELETE_ACCESS_RIGHTS_TAGS_ERROR;
}

export type DeleteAction =
  | DeleteRequestStartAction
  | DeleteRequestSuccessAction
  | DeleteRequestFailureAction
  | ClearDeleteErrorAction;

interface ClearAccessRightsTagsAction {
  type: ActionTypes.CLEAR_ACCESS_RIGHTS_TAGS;
}

export type ClearAction = ClearAccessRightsTagsAction;

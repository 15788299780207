import React from 'react';

import { FormHelperText } from '@mui/material';
import { FieldErrorMessagesWrapper } from './FieldErrorMessages.styles';

type FieldErrorMessagesProps = {
  errors: string[];
  className?: string;
};

export const FieldErrorMessages: React.FC<FieldErrorMessagesProps> = ({ errors, className }) => (
  <FieldErrorMessagesWrapper {...(className ? { className } : {})}>
    {errors.map((error, errorIndex) => (
      <FormHelperText className="field-error-messages__error" key={errorIndex.toString()} error>
        {error}
      </FormHelperText>
    ))}
  </FieldErrorMessagesWrapper>
);

import React, { useState } from 'react';

import { CustomSwitch } from 'components/atoms';
import { VoidStatus } from 'types';
import { ActionDialog } from 'components/molecules';
import { usePutFetchData } from 'utils/hooks';
import { VoidFlagDialogContent } from '../VoidFlagDialogContent/VoidFlagDialogContent';
import { VoidFlaDialogStyles } from './VoidFlagSwitch.styles';

type VoidFlagSwitchProps = {
  voidStatus: VoidStatus;
  url: string;
  payloadFields?: { [key: string]: string | number | null };
  onSuccess: () => void;
};

export const VoidFlagSwitch: React.FC<VoidFlagSwitchProps> = ({
  voidStatus,
  url,
  payloadFields,
  onSuccess,
}) => {
  const [switchConformation, setSwitchConformation] = useState<VoidStatus | null>(null);

  const { mutate, isLoading, isSuccess, reset } = usePutFetchData({
    url,
    onSuccess,
    onError: () => {
      setSwitchConformation(null);
    },
    enableErrorHandler: true,
  });

  const onSwitchChange = ({ target }) =>
    setSwitchConformation(target.checked ? VoidStatus.Void : VoidStatus.NotVoid);

  const handleSubmitVoid = () => mutate({ ...payloadFields, voidStatus: VoidStatus.Void });
  const handleSubmitNotVoid = () => mutate({ ...payloadFields, voidStatus: VoidStatus.NotVoid });

  const handleOnClose = () => {
    setSwitchConformation(null);
    reset();
  };

  return (
    <>
      <CustomSwitch checked={voidStatus === VoidStatus.Void} onChange={onSwitchChange} />
      <ActionDialog
        open={switchConformation === VoidStatus.Void}
        onClose={handleOnClose}
        handleSubmit={handleSubmitVoid}
        title="Warning"
        submitButtonName="Confirm"
        sendingRequest={isLoading}
        component={VoidFlaDialogStyles}
        successMessageProps={{
          message: 'Void property flag ON successfully!',
          showMessage: isSuccess,
          timeout: 2000,
          onClose: handleOnClose,
        }}
      >
        <VoidFlagDialogContent type={VoidStatus.Void} />
      </ActionDialog>
      <ActionDialog
        open={switchConformation === VoidStatus.NotVoid}
        onClose={handleOnClose}
        handleSubmit={handleSubmitNotVoid}
        title="Warning"
        submitButtonName="Confirm"
        sendingRequest={isLoading}
        component={VoidFlaDialogStyles}
        successMessageProps={{
          message: 'Void property flag OFF successfully!',
          showMessage: isSuccess,
          timeout: 2000,
          onClose: handleOnClose,
        }}
      >
        <VoidFlagDialogContent type={VoidStatus.NotVoid} />
      </ActionDialog>
    </>
  );
};

import { Moment } from 'moment';

export enum MetricKey {
  BoilerFlowTempMean = 'Boiler_FlowTemp:Mean',
  BoilerRoomActualMean = 'Boiler_RoomActual:Mean',
  BoilerRtnTempMean = 'Boiler_RtnTemp:Mean',
  BoilerRoomSetPointMean = 'Boiler_RoomSetPoint:Mean',
  HeatPumpOutdoorUnitFlowTempMean = 'HeatPump_OutdoorUnitFlowTemp:Mean',
  HeatPumpOutdoorUnitRtnTempMean = 'HeatPump_OutdoorUnitRtnTemp:Mean',
  HeatingUnitOutsideTempMean = 'HeatingUnit_OutsideTemp:Mean',
  HeatPumpPrimaryFlowRateMean = 'HeatPump_PrimaryFlowRate:Mean',
  HeatingUnitDHWStorageTempMean = 'HeatingUnit_DHWStorageTemp:Mean',
  HeatPumpHeatingElectricityConsumption = 'HeatPump_HeatingElectricityConsumption',
  HeatPumpDHWElectricityConsumption = 'HeatPump_DHWElectricityConsumption',
  BoilerDHWBurnerHours = 'Boiler_DHWBurnerHours',
  BoilerCHBurnerHours = 'Boiler_CHBurnerHours',
  BoilerTotalBurnerHours = 'Boiler_TotalBurnerHours',
  BoilerTotalPumpHours = 'Boiler_TotalPumpHours',
  DeviceChargeStatus = 'device_chargestatus',
  GsmSignalStrength = 'gsm_signalstrength',
  TelemetryPeriod = 'telemetry_period',
  BoilerFaultCode = 'boiler_faultcode',
  DeviceBatteryVoltage = 'device_batteryvoltage',
  DeviceStTemperature = 'device_sttemperature',
  ZDeviceTemperature = 'ZDevice_Temperature',
  ZDeviceHumidity = 'ZDevice_Humidity',
  ZDeviceCo2 = 'zdevice_co2',
  ZDeviceBattery = 'ZDevice_Battery',
  ZDeviceLqiMin = 'ZDevice_Lqi:Min',
  ZDeviceTampered = 'zdevice_tampered',
  ZDevicePower = 'ZDevice_Power',
  BoilerFailedBurnerStarts = 'Boiler_FailedBurnerStarts',
  BoilerTotalBurnerStarts = 'Boiler_TotalBurnerStarts',
  BoilerCHPumpStarts = 'Boiler_CHPumpStarts',
  BoilerDHWPumpStarts = 'Boiler_DHWPumpStarts',
  BoilerDHWFlowRateMean = 'Boiler_DHWFlowRate:Mean',
  BoilerCHPressureMean = 'Boiler_CHPressure:Mean',
  BoilerPumpModulationMean = 'Boiler_PumpModulation:Mean',
  BoilerCHSetPointActualMean = 'Boiler_CHSetPointActual:Mean',
  BoilerFanSpeedActualRPMMean = 'Boiler_FanSpeedActualRPM:Mean',
  AutofillLastFillNumBursts = 'Autofill_LastFillNumBursts',
  ZigbeeAutofillPressureMean = 'ZigbeeAutofill_Pressure:Mean',
}

export enum ChartNames {
  EventTimeline = 'EventTimeline',
  Linear = 'Linear',
  Numeric = 'Numeric',
}

export type ChartConfig = {
  title: string;
  type: ChartNames;
  gridWidth: number;
  chartLabel: string;
  units: string;
  metricKey: MetricKey;
  color?: string;
  rangeMin?: number | null;
  rangeMax?: number | null;
  propertyLocationId: string;
  subTitle?: string;
  hideWhenNoData?: boolean;
};

export type CardExtended = ChartConfig & {
  dataSeries?: TransformedDataProps[];
  eventSeries?: EventProps[];
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
};

export interface TelemetryView {
  name: string;
  charts: CardExtended[];
}

export interface TransformedDataProps {
  date?: Moment;
  value: number;
}

export interface DataProps {
  propertyId: string;
  propertyLocationId: string | null;
  deviceId: string | null;
  key: any;
  timestamp: string;
  value: number;
}

export interface EventProps {
  id: string;
  description: string;
  start: string;
  end: string;
  severity: string;
  category: string;
  userId: string;
  deviceId: string | null;
  propertyId: string;
  propertyLocationId: string | null;
  cardTitle: string;
  startNormalized?: Date;
  endNormalized?: Date;
}

export interface DataSeriesGroupedByContextId {
  contextId: string;
  dataSeriesList: Array<string>;
}

export type DataSeriesRequestList = Array<DataSeriesGroupedByContextId>;

export type EventTimelineResponse = {
  results: EventProps[];
};

export type DeviceMetricsResponse = {
  results: DataProps[];
};

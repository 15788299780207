import styled from 'styled-components';

export const SubmitButton = styled.button`
  ${(props) => props.theme.defaultButton()};
`;

export const CancelButton = styled.button`
  ${(props) => props.theme.clearButton()};
`;

export const Wrapper = styled.div`
  font-size: ${(props) => props.theme.textDefault};
  color: ${(props) => props.theme.grey};
  margin-top: -1rem;

  & > h3 {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 1.4rem;
  }

  & p {
    text-align: left;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 2rem auto 0;

  && > button {
    margin: 0 1rem;
  }
`;

export const SpinnerWrapper = styled.div`
  height: 40px;
  width: 40px;
`;

export const ModalMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 10px 0;
`;

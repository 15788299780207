import React from 'react';

import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: ${(props) => (props.isEdit ? 'solid 2px red' : 'solid 2px transparent')};
`;

const MapContainer = () => {
  const mapSettings = {
    center: {
      lat: 0.0,
      lng: 0.0,
    },
    zoom: 1,
  };

  return (
    <>
      <MapWrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS }}
          center={mapSettings.center}
          zoom={mapSettings.zoom}
          options={{
            mapTypeControl: true,
            fullscreenControl: false,
            mapTypeControlOptions: {
              position: 7,
              mapTypeIds: ['roadmap', 'satellite'],
            },
            rotateControl: false,
            tilt: 0,
            styles: [
              {
                stylers: [{ visibility: 'simplified' }],
              },
              {
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'landscape.man_made',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }}
        />
      </MapWrapper>
    </>
  );
};

export default MapContainer;

import React from 'react';

import styled from 'styled-components';

import DateField from './DateField';
import Dropdown from './Dropdown';
import TextSearch from './TextSearch';

const FilterWrapperLarge = styled.div<{ width: string; margin: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};

  & span {
    margin: 0 0 0.5rem 0.5rem;
  }

  & select {
    width: 100%;
  }

  & input {
    width: 100%;
  }
`;
const FilterWrapperSmall = styled.div`
  display: flex;
  align-items: center;
  width: 10rem;

  & span {
    margin: 0 0.5rem 0 0;
  }

  & select {
    width: 6.5rem;
  }
`;

const FilterInput = ({
  data,
  onChangeCallback,
  label = '',
  isSmall = false,
  name = '',
  inputType = 'dropdown',
  placeholder = '',
  hasLabel = true,
  selectedValue = '',
  width = '24rem',
  margin = '',
  readOnly = false,
  hasOnChange = false,
  isDisabled = false,
}) => {
  if (isSmall) {
    return (
      <FilterWrapperSmall>
        <span>{label}</span>
        <Dropdown
          name={name}
          onChange={onChangeCallback}
          selectedValue={selectedValue}
          values={data}
          readOnly
          hasOnChange={hasOnChange}
          isDisabled={isDisabled}
        />
      </FilterWrapperSmall>
    );
  }

  if (inputType === 'text') {
    return (
      <FilterWrapperLarge margin={margin} width={width}>
        {hasLabel && <span>{label}</span>}
        <TextSearch
          values={data}
          name={name}
          onChange={onChangeCallback}
          placeholder={placeholder}
          selectedValue={selectedValue}
          readOnly={readOnly}
          hasOnChange={hasOnChange}
        />
      </FilterWrapperLarge>
    );
  }

  if (inputType === 'date') {
    return (
      <FilterWrapperLarge margin={margin} width={width}>
        {hasLabel && <span>{label}</span>}
        <DateField
          name={name}
          onChange={onChangeCallback}
          selectedValue={selectedValue}
          hasOnChange={hasOnChange}
        />
      </FilterWrapperLarge>
    );
  }

  return (
    <FilterWrapperLarge margin={margin} width={width}>
      {hasLabel && <span>{label}</span>}
      <Dropdown
        name={name}
        onChange={onChangeCallback}
        placeholder={placeholder}
        selectedValue={selectedValue}
        values={data}
        hasOnChange={hasOnChange}
        isDisabled={isDisabled}
        readOnly={readOnly}
      />
    </FilterWrapperLarge>
  );
};

export default FilterInput;

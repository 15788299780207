import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';

import { getColumnVisibilityModel } from 'utils';
import { BcmsDisplayType, FeaturesAreas } from 'types';
import { useAreaFeatures, useNavigate } from 'utils/hooks';
import { StyledDataGrid } from 'styles/StyledDataGrid';
import { CustomToolbar } from 'components/molecules';
import { useTableData } from './hooks/useTableData';

import { BcmsWrapper } from './Bcms.styles';
import { DataGridColumnsHidden, DataGridColumnsHiddenBcms } from './types';

export const Bcms: React.FC = () => {
  const { rows, pinnedRows, columns, isLoading } = useTableData();
  const showNewBcmsPage = useAreaFeatures(FeaturesAreas.Boilers).features.NewListingView;

  const { goTo } = useNavigate();
  const onRowClick = ({ id }) => goTo(`Boilers/${id}`);

  const [searchParams] = useSearchParams();
  const displayType = searchParams.get('type') as BcmsDisplayType | null;
  const showAshpPage = displayType === BcmsDisplayType.ASHP || !showNewBcmsPage;

  const hiddenColumns = showAshpPage ? DataGridColumnsHidden : DataGridColumnsHiddenBcms;
  const bcmsPageTitle = showAshpPage ? 'Air Source Heat Pumps' : 'Boilers';

  const { columnVisibilityModel } = useMemo(
    () => getColumnVisibilityModel(hiddenColumns),
    [hiddenColumns]
  );

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
    }
  }, [apiRef, columnVisibilityModel]);

  return (
    <BcmsWrapper>
      <div className="table-page__header">
        <div className="table-page__header-title">
          <Typography className="table-page__header-title-text">{bcmsPageTitle}</Typography>
        </div>
      </div>
      <div className="table-page__table">
        <StyledDataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          pinnedRows={pinnedRows}
          loading={isLoading}
          disableDensitySelector
          density="standard"
          columnHeaderHeight={50}
          disableRowSelectionOnClick
          onRowClick={onRowClick}
          pageSizeOptions={[25, 50, 100]}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
            columns: {
              columnVisibilityModel,
            },
          }}
          checkboxSelection
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </BcmsWrapper>
  );
};

import styled from 'styled-components';

export const TableWrapper = styled.div`
  margin: 1rem 0;
  ${(props) => props.theme.wrapper}
  padding: 2.5rem 1rem;
  height: 500px;
  overflow-y: auto;
`;

export const Table = styled.table<{ rowAction?: boolean }>`
  width: 100%;
  border-collapse: collapse;

  & th,
  td {
    padding: 1rem;
  }

  & thead tr {
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};

    & th {
      font-weight: 400;
      text-align: left;
    }
  }

  & tbody tr {
    transition: all 0.4s ease-in-out;
  }

  & tbody tr:nth-child(even) {
    background-color: ${(props) => props.theme.offWhite};
  }

  & tbody tr:hover {
    background-color: #ececf7;
    ${(props) => (props.rowAction ? 'cursor: pointer' : '')};
  }
`;

Table.defaultProps = {
  rowAction: false,
};

export const Centered = styled.p`
  text-align: center;
`;

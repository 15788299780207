import { Property, VoidStatus } from 'types/Properties/Properties';

type TransformPropertiesProps = {
  properties: Property[];
};

export const transformProperties = ({ properties }: TransformPropertiesProps) =>
  properties?.map(
    ({
      propertyReference,
      propertyId,
      tenantId,
      postcode,
      address1,
      address2,
      address3,
      latitude,
      longitude,
      epcRating,
      town,
      county,
      voidStatus,
    }) => ({
      id: propertyId,
      propertyReference,
      postcode,
      address: [address1, address2, address3, town, county].filter((address) => address).join(', '),
      propertyId,
      tenantId,
      address1,
      address2,
      address3,
      latitude,
      longitude,
      epcRating,
      town,
      county,
      voidStatus: voidStatus === VoidStatus.Void ? 'ON' : 'OFF',
    })
  );

import React from 'react';

import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

const Border = styled.div`
  width: 100%;
  height: 100%;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(40, 190, 230);
  background: linear-gradient(0deg, rgba(40, 190, 230, 0.1) 33%, rgba(40, 190, 230, 1) 100%);
  animation: ${rotate} 0.8s linear 0s infinite;
`;

const Core = styled.div<{ backgroundColor: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme[props.backgroundColor]};
  border-radius: 50%;
`;

const Spinner = ({ backgroundColor = 'white' }) => (
  <Wrapper>
    <Border>
      <Core backgroundColor={backgroundColor} />
    </Border>
  </Wrapper>
);

export default Spinner;

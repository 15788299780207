import styled, { css } from 'styled-components';
import { styled as muiStyled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = '26rem';
const MobileDrawerWidth = '32rem';

const sidebarMixin = css`
  .sidebar {
    &__paper {
      overflow: unset;
      padding-bottom: 50px;
      border-right: 4px solid ${(props) => props.theme.blue};
      background-color: ${(props) => props.theme.primary};

      &--mobile {
        border: none;
        width: ${MobileDrawerWidth};
      }
    }

    &__header {
      min-height: 7rem;
    }

    &__content {
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid transparent;
      }
    }

    &__toggle-button {
      min-height: 48px;
      flex-grow: unset;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      svg {
        color: ${(props) => props.theme.white};
      }
    }

    &__management {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      gap: 2px;

      &-button {
        padding: 2rem;
        border: none;
        color: ${(props) => props.theme.white};
        background-color: ${(props) => props.theme.primaryLight};
        font-family: inherit;
        font-weight: 500;
        width: 50%;

        &:hover,
        &:focus {
          background-color: #575c6a;
        }
      }
    }
  }
`;

export const SidebarWrapper = styled.div`
  z-index: 0;

  ${sidebarMixin}
`;

export const MobileSidebar = styled(MuiDrawer)`
  ${sidebarMixin}
`;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  '& .MuiDrawer-paper .sidebar-item__button': {
    paddingRight: 20,
    paddingLeft: 20,
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
    '& .sidebar-item__button-icon': {
      marginRight: 0,
    },
  },
});

export const DesktopSidebar = muiStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

import React from 'react';

import { FeaturesAreas, PropertiesFeatures } from 'types';
import { useAreaFeatures, useSelectedTenant } from 'utils/hooks';
import { AdeyWidget, ManagedServiceWidget, VericonWidget } from './organism';
import { dashboardContent } from './constants/dashboard';
import { WidgetContainer } from './DashboardView.styles';

export const DashboardView: React.FC = () => {
  const propertyFeatures = useAreaFeatures(FeaturesAreas.Properties).features;
  const tenantId = useSelectedTenant();

  return (
    <WidgetContainer>
      {propertyFeatures[PropertiesFeatures.ManagedService] && <ManagedServiceWidget />}
      {dashboardContent(tenantId).map((widgetProps, widgetIndex) => (
        <VericonWidget key={widgetIndex.toString()} {...widgetProps} />
      ))}
      <AdeyWidget />
    </WidgetContainer>
  );
};

import styled from 'styled-components';
import { disableMuiXTableHeaderMixin } from 'styles/commonStyles';

export const PropertyDevicesWrapper = styled.div`
  ${disableMuiXTableHeaderMixin}
  padding-bottom: 1rem;

  .device-table {
    &__access-rights-tag {
      min-width: 240px;
      width: 100%;

      .MuiIconButton-root:not(.device-access-rights-tag-select__close) {
        svg {
          width: 1.8rem;
          height: 1.8rem;
        }
      }

      .device-access-rights-tag__name {
        text-wrap: nowrap;
      }

      .device-access-rights-tag__content {
        justify-content: space-between;
        width: 100%;
        gap: 4px;
      }

      .device-access-rights-tag-select {
        gap: 4px;
        justify-content: space-between;
        width: 100%;

        &__input {
          flex-grow: 1;

          .MuiFormLabel-root,
          .MuiOutlinedInput-notchedOutline,
          .MuiSelect-nativeInput,
          .MuiSelect-select,
          .MuiInputBase-root {
            font-size: 13px;
          }
        }

        &__close-icon {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }
`;

import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import CountryDialCode from 'types/CountryDialCode';
import { splitCountryCodeAndNumber } from 'utils/phoneNumberHelpers';
import CountryInput, { IPhoneNumber } from './CountryInput';
import NumberInput from './NumberInput';

const InputWrapper = styled.div<{ state: string }>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #d8d8d8;
  ${(props) => {
    if (props.state) return 'border-color: transparent';
  }};
  background: ${({ theme }) => theme.offWhite};
  box-shadow: ${(props) => {
    if (props.state) {
      return props.state === 'error'
        ? `0 0 4px 1px ${props.theme.error}`
        : `0 0 4px 1px ${props.theme.success}`;
    }

    return 'none';
  }};
`;

const DialCode = styled.span``;

const PhoneNumberInput = ({ value, onChange, state = '' }) => {
  const phoneNumberObj: IPhoneNumber = useMemo(() => splitCountryCodeAndNumber(value), [value]);

  const [number, setNumber] = useState<string>(phoneNumberObj.subscriberNumber);
  const [selectedCountry, setSelectedCountry] = useState<CountryDialCode>(
    phoneNumberObj.countryDialCode
  );

  useEffect(() => {
    if (
      phoneNumberObj.countryDialCode.dialCode !== selectedCountry.dialCode &&
      phoneNumberObj.subscriberNumber !== number
    ) {
      setSelectedCountry(phoneNumberObj.countryDialCode);
      setNumber(phoneNumberObj.subscriberNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberObj]);

  const handleCountrySelect = (countryDialCode: CountryDialCode) => {
    setSelectedCountry(countryDialCode);
    onChange(`${countryDialCode.dialCode}${number}`);
  };

  const handleNumberChange = (value) => {
    setNumber(value);
    onChange(`${selectedCountry.dialCode}${value}`);
  };

  return (
    <InputWrapper state={state}>
      {number !== null && selectedCountry && (
        <>
          <CountryInput value={selectedCountry} onChange={handleCountrySelect} />

          <DialCode>{selectedCountry.dialCode}</DialCode>

          <NumberInput value={number} onChange={handleNumberChange} />
        </>
      )}
    </InputWrapper>
  );
};

export default PhoneNumberInput;

import React, { useState } from 'react';

import { DeviceActionMainDialog } from 'components/DeviceActions/Dialogs';
import Input from 'components/elements/Input';
import TagItem from 'components/elements/Tag';
import { useSelector } from 'react-redux';
import { selectTags } from 'reducers/tags/selectors';
import styled from 'styled-components';
import { handleApiReq } from 'utils/ApiRequestHelpers';
import { contains } from 'utils/helpers';
import useAlert from 'utils/hooks/useAlert';

import { BulkActionProps } from '../../DeviceActionProps';

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  width: 90%;
  margin: 2rem;
  min-height: 15rem;
`;

const BulkAddTags = ({ deviceIds, handleResetAction }: BulkActionProps) => {
  const tags = useSelector((state: any) => selectTags(state));
  const { errorAlert, successAlert } = useAlert();

  const [selectedTags, setSelectedTags] = useState([] as string[]);

  const addTag = (tagId) => setSelectedTags([...selectedTags, tagId]);
  const removeTag = (tagId) => setSelectedTags(selectedTags.filter((t) => t !== tagId));

  const handleSubmit = async () => {
    if (selectedTags.length > 0) {
      const res = await handleApiReq('BulkAddTags', { deviceIds: deviceIds, tags: selectedTags });

      if (res) {
        if (res.isSuccessful) {
          successAlert('Request Successful');
        } else {
          errorAlert(res.message);
        }
      } else {
        errorAlert('Something has gone wrong, please try again');
      }
    }

    handleResetAction();
  };

  return (
    <DeviceActionMainDialog handleResetAction={handleResetAction} handleSubmit={handleSubmit}>
      <h3>Tags</h3>
      <Input
        type="dropdown"
        width="20rem"
        inputData={{
          name: 'newTag',
          options: tags
            .map((t) => ({ value: t.id, display: t.name }))
            .filter((t) => !contains(t.value, selectedTags)),
          placeholder: 'Select Tag',
          onChange: addTag,
        }}
      />
      <p style={{ marginTop: '1rem' }}>Selected tags will be added to selected devices</p>
      <TagsWrapper>
        {selectedTags.map((t) => (
          <TagItem
            key={t}
            tag={tags.find((tg) => tg.id === t)}
            handleRemoveClick={removeTag}
            showRemove
          />
        ))}
      </TagsWrapper>
    </DeviceActionMainDialog>
  );
};

export default BulkAddTags;

import React from 'react';

import styled from 'styled-components';

const UserInfoContainer = styled.div`
  position: relative;
  display: flex;
  margin: 0 4rem;
  justify-content: flex-end;
  align-items: center;

  @media ${(props) => props.theme.tabPort} {
    margin: 0 1rem;
  }

  @media ${(props) => props.theme.mobileLand} {
    margin: 0;
  }
`;

const UserInfoImageWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;

  @media ${(props) => props.theme.tabPort} {
    display: none;
  }
`;

const UserInfoNameWrapper = styled.div`
  @media ${(props) => props.theme.mobileLand} {
    display: none;
  }
`;

const UserInfoLogOut = styled.div`
  background: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.white};
  font-size: ${(props) => props.theme.textSmaller};
  position: absolute;
  width: 15rem;
  right: 0;
  top: 5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 6;

  & ul li {
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    background: transparent;
    transition: all 0.3s ease-in-out;
    position: relative;
    text-align: center;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: ${(props) => props.theme.gradient};
      opacity: 0;
      z-index: -1;
      transition: opacity 0.3s ease-in-out;
    }

    &:not(:last-child):after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 12.5%;
      width: 75%;
      height: 1px;
      background: rgba(200, 200, 200, 0.3);
    }
  }
`;

const UserInfoImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
`;

const UserInfoText = styled.p`
  font-size: ${(props) => props.theme.textSmaller};
`;

const UserInfoName = styled.div`
  font-size: ${(props) => props.theme.textSmall};
  font-weight: 600;

  cursor: pointer;

  &:focus {
    outline: none;
    & ~ .show {
      opacity: 1;
      visibility: visible;
      outline: none;

      & ul li {
        &:hover {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
`;

const DropDownIcon = styled.span`
  display: inline-block;
  margin-bottom: 1px;
  margin-left: 3px;
  width: 1rem;
  height: 1rem;
  border-right: 2px solid ${(props) => props.theme.default};
  border-top: 2px solid ${(props) => props.theme.default};
  transform: rotate(135deg);
`;

const UserInfo = ({ profile, handleLogout, handleChangeTenant }) => (
  <UserInfoContainer>
    <UserInfoImageWrapper>
      <UserInfoImage src={profile.pictureUrl} alt={profile.name} />
    </UserInfoImageWrapper>
    <UserInfoNameWrapper>
      <UserInfoText>Logged in as</UserInfoText>
      <UserInfoName tabIndex={-1}>
        {profile.name} <DropDownIcon />
      </UserInfoName>
      <UserInfoLogOut className="show">
        <ul>
          <li onClick={handleChangeTenant} role="presentation">
            Change Client
          </li>
          <li onClick={handleLogout} role="presentation">
            Logout
          </li>
        </ul>
      </UserInfoLogOut>
    </UserInfoNameWrapper>
  </UserInfoContainer>
);
export default UserInfo;

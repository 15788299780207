import React from 'react';

import styled from 'styled-components';

const ImageWrapper = styled('div')<{ width: string; height: string; rotation: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: 'transparent';
  border-radius: 50%;
  width: 0px;
  height: 0px;
  transform: translate(-50%, -50%);

  & img {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    transform: rotate(${(props) => props.rotation}deg);
    position: absolute;
    user-select: none;
    border: '1px solid transparent';
  }
`;

const ImageOverlay = ({ width, height, image, rotation }) => (
  <ImageWrapper width={width} height={height} rotation={rotation}>
    <img src={image} alt="" />
  </ImageWrapper>
);

export default ImageOverlay;

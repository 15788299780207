import React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license';
import { HashRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@mui/material/styles';

import { GlobalStyle } from 'styles/globalStyles';
import AuthProvider from 'utils/contexts/AuthProvider';
import theme from 'styles/muiTheme';
import Layout from './components/layout';
import store from './reducers';
import * as serviceWorker from './serviceWorker';

const container = createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENCE as string);

container.render(
  <Provider store={store}>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient} contextSharing>
        <Router>
          <AuthProvider>
            <Layout />
          </AuthProvider>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  </Provider>
);

serviceWorker.unregister();

import React from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';

const AddButton = styled.button`
  ${(props) => props.theme.submitButton('fit-content')}
  padding: .5rem 1.5rem;
  display: flex;
  align-items: center;
  margin: 0 auto;

  & svg {
    display: inline-block;
    margin-left: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    fill: currentColor;
  }
`;

const AddBuildingButton = () => {
  const tenant = useSelectedTenant();

  return (
    <Link to={`/${tenant}/Buildings/Add`}>
      <AddButton>
        Add a building
        <SvgIcon type="plus" />
      </AddButton>
    </Link>
  );
};

export default AddBuildingButton;

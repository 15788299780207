import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import Input from './index';

const UnitInputWrapper = styled.div`
  display: flex;
  align-items: center;

  & div:first-child {
    margin-right: 1rem;
  }
`;

const UnitInput = ({ value, onChange, name, options }) => {
  const [val, setVal] = useState();
  const [unit, setUnit] = useState();

  useEffect(() => {
    if (value) {
      const valArr = value.split(' ');
      setVal(valArr[0]);
      setUnit(valArr[1]);
    }
  }, [value]);

  const handleValueChange = (v) => onChange(`${v} ${unit}`, name);

  const handleUnitChange = (v) => onChange(`${val} ${v}`, name);

  return (
    <UnitInputWrapper>
      <Input
        width="6rem"
        type="text"
        inputData={{
          value: val,
          onChange: handleValueChange,
          name: `${name}-val`,
        }}
      />
      <Input
        type="radio"
        inputData={{
          value: unit,
          onChange: handleUnitChange,
          name: `${name}-unit`,
          options: options,
        }}
      />
    </UnitInputWrapper>
  );
};

export default UnitInput;

import { Switch, styled } from '@mui/material';

export const StyledSwitch = styled(Switch)(() => ({
  padding: '0 14px',
  height: 16,
  display: 'flex',

  '@media screen and (min-width: 768px)': {
    padding: '0 8px',
    height: 22,
  },

  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,

    '@media screen and (min-width: 768px)': {
      borderRadius: 22 / 2,
    },

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 12,
      height: 12,

      '@media screen and (min-width: 768px)': {
        width: 16,
        height: 16,
      },
    },
  },

  '& .MuiSwitch-switchBase': {
    top: '-8px',
    left: 6,

    '@media screen and (min-width: 768px)': {
      left: 0,
    },

    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: 'white',

      '@media screen and (min-width: 768px)': {
        transform: 'translateX(20px)',
      },

      '&+.MuiSwitch-track': {
        opacity: 0.8,
      },
    },
  },

  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 12,
    height: 12,
    margin: 1,

    '@media screen and (min-width: 768px)': {
      width: 16,
      height: 16,
      margin: 2,
    },
  },
}));

import CountryDialCode from 'types/CountryDialCode';

export const countryDialCodes: CountryDialCode[] = [
  { label: 'Afghanistan (‫افغانستان‬‎)', id: 'AF', dialCode: '+93' },
  { label: 'Albania (Shqipëri)', id: 'AL', dialCode: '+355' },
  { label: 'Algeria (‫الجزائر‬‎)', id: 'DZ', dialCode: '+213' },
  { label: 'Andorra', id: 'AD', dialCode: '+376' },
  { label: 'Angola', id: 'AO', dialCode: '+244' },
  { label: 'Antigua and Barbuda', id: 'AG', dialCode: '+1268' },
  { label: 'Argentina', id: 'AR', dialCode: '+54' },
  { label: 'Armenia (Հայաստան)', id: 'AM', dialCode: '+374' },
  { label: 'Aruba', id: 'AW', dialCode: '+297' },
  { label: 'Australia', id: 'AU', dialCode: '+61' },
  { label: 'Austria (Österreich)', id: 'AT', dialCode: '+43' },
  { label: 'Azerbaijan (Azərbaycan)', id: 'AZ', dialCode: '+994' },
  { label: 'Bahamas', id: 'BS', dialCode: '+1242' },
  { label: 'Bahrain (‫البحرين‬‎)', id: 'BH', dialCode: '+973' },
  { label: 'Bangladesh (বাংলাদেশ)', id: 'BD', dialCode: '+880' },
  { label: 'Barbados', id: 'BB', dialCode: '+1246' },
  { label: 'Belarus (Беларусь)', id: 'BY', dialCode: '+375' },
  { label: 'Belgium (België)', id: 'BE', dialCode: '+32' },
  { label: 'Belize', id: 'BZ', dialCode: '+501' },
  { label: 'Benin (Bénin)', id: 'BJ', dialCode: '+229' },
  { label: 'Bhutan (འབྲུག)', id: 'BT', dialCode: '+975' },
  { label: 'Bolivia', id: 'BO', dialCode: '+591' },
  { label: 'Bosnia and Herzegovina (Босна и Херцеговина)', id: 'BA', dialCode: '+387' },
  { label: 'Botswana', id: 'BW', dialCode: '+267' },
  { label: 'Brazil (Brasil)', id: 'BR', dialCode: '+55' },
  { label: 'British Indian Ocean Territory', id: 'IO', dialCode: '+246' },
  { label: 'Brunei', id: 'BN', dialCode: '+673' },
  { label: 'Bulgaria (България)', id: 'BG', dialCode: '+359' },
  { label: 'Burkina Faso', id: 'BF', dialCode: '+226' },
  { label: 'Burundi (Uburundi)', id: 'BI', dialCode: '+257' },
  { label: 'Cambodia (កម្ពុជា)', id: 'KH', dialCode: '+855' },
  { label: 'Cameroon (Cameroun)', id: 'CM', dialCode: '+237' },
  { label: 'Canada', id: 'CA', dialCode: '+1', priority: 2 },
  { label: 'Cape Verde (Kabu Verdi)', id: 'CV', dialCode: '+238' },
  { label: 'Central African Republic (République centrafricaine)', id: 'CF', dialCode: '+236' },
  { label: 'Chad (Tchad)', id: 'TD', dialCode: '+235' },
  { label: 'Chile', id: 'CL', dialCode: '+56' },
  { label: 'China (中国)', id: 'CN', dialCode: '+86' },
  { label: 'Colombia', id: 'CO', dialCode: '+57' },
  { label: 'Comoros (‫جزر القمر‬‎)', id: 'KM', dialCode: '+269' },
  { label: 'Congo (DRC) (Jamhuri ya Kisoemokrasia ya Kongo)', id: 'CD', dialCode: '+243' },
  { label: 'Congo (Republic) (Congo-Brazzaville)', id: 'CG', dialCode: '+242' },
  { label: 'Costa Rica', id: 'CR', dialCode: '+506' },
  { label: 'Côte d’Ivoire', id: 'CI', dialCode: '+225' },
  { label: 'Croatia (Hrvatska)', id: 'HR', dialCode: '+385' },
  { label: 'Cuba', id: 'CU', dialCode: '+53' },
  { label: 'Curaçao', id: 'CW', dialCode: '+599' },
  { label: 'Cyprus (Κύπρος)', id: 'CY', dialCode: '+357' },
  { label: 'Czech Republic (Česká republika)', id: 'CZ', dialCode: '+420' },
  { label: 'Denmark (Danmark)', id: 'DK', dialCode: '+45' },
  { label: 'Djibouti', id: 'DJ', dialCode: '+253' },
  { label: 'Dominica', id: 'DM', dialCode: '+1767' },
  { label: 'Dominican Republic (República Dominicana)', id: 'DO', dialCode: '+1', priority: 3 },
  { label: 'Ecuador', id: 'EC', dialCode: '+593' },
  { label: 'Egypt (‫مصر‬‎)', id: 'EG', dialCode: '+20' },
  { label: 'El Salvador', id: 'SV', dialCode: '+503' },
  { label: 'Equatorial Guinea (Guinea Ecuatorial)', id: 'GQ', dialCode: '+240' },
  { label: 'Eritrea', id: 'ER', dialCode: '+291' },
  { label: 'Estonia (Eesti)', id: 'EE', dialCode: '+372' },
  { label: 'Ethiopia', id: 'ET', dialCode: '+251' },
  { label: 'Fiji', id: 'FJ', dialCode: '+679' },
  { label: 'Finland (Suomi)', id: 'FI', dialCode: '+358' },
  { label: 'France', id: 'FR', dialCode: '+33' },
  { label: 'French Guiana (Guyane française)', id: 'GF', dialCode: '+594' },
  { label: 'French Polynesia (Polynésie française)', id: 'PF', dialCode: '+689' },
  { label: 'Gabon', id: 'GA', dialCode: '+241' },
  { label: 'Gambia', id: 'GM', dialCode: '+220' },
  { label: 'Georgia (საქართველო)', id: 'GE', dialCode: '+995' },
  { label: 'Germany (Deutschland)', id: 'DE', dialCode: '+49' },
  { label: 'Ghana (Gaana)', id: 'GH', dialCode: '+233' },
  { label: 'Greece (Ελλάδα)', id: 'GR', dialCode: '+30' },
  { label: 'Grenada', id: 'GD', dialCode: '+1473' },
  { label: 'Guadeloupe', id: 'GP', dialCode: '+590' },
  { label: 'Guam', id: 'GU', dialCode: '+1671' },
  { label: 'Guatemala', id: 'GT', dialCode: '+502' },
  { label: 'Guinea (Guinée)', id: 'GN', dialCode: '+224' },
  { label: 'Guinea-Bissau (Guiné Bissau)', id: 'GW', dialCode: '+245' },
  { label: 'Guyana', id: 'GY', dialCode: '+592' },
  { label: 'Haiti', id: 'HT', dialCode: '+509' },
  { label: 'Honduras', id: 'HN', dialCode: '+504' },
  { label: 'Hong Kong (香港)', id: 'HK', dialCode: '+852' },
  { label: 'Hungary (Magyarország)', id: 'HU', dialCode: '+36' },
  { label: 'Iceland (Ísland)', id: 'IS', dialCode: '+354' },
  { label: 'India (भारत)', id: 'IN', dialCode: '+91' },
  { label: 'Indonesia', id: 'ID', dialCode: '+62' },
  { label: 'Iran (‫ایران‬‎)', id: 'IR', dialCode: '+98' },
  { label: 'Iraq (‫العراق‬‎)', id: 'IQ', dialCode: '+964' },
  { label: 'Ireland', id: 'IE', dialCode: '+353' },
  { label: 'Israel (‫ישראל‬‎)', id: 'IL', dialCode: '+972' },
  { label: 'Italy (Italia)', id: 'IT', dialCode: '+39', priority: 0 },
  { label: 'Jamaica', id: 'JM', dialCode: '+876' },
  { label: 'Japan (日本)', id: 'JP', dialCode: '+81' },
  { label: 'Jordan (‫الأردن‬‎)', id: 'JO', dialCode: '+962' },
  { label: 'Kazakhstan (Казахстан)', id: 'KZ', dialCode: '+7', priority: 2 },
  { label: 'Kenya', id: 'KE', dialCode: '+254' },
  { label: 'Kiribati', id: 'KI', dialCode: '+686' },
  { label: 'Kosovo', id: 'XK', dialCode: '+383' },
  { label: 'Kuwait (‫الكويت‬‎)', id: 'KW', dialCode: '+965' },
  { label: 'Kyrgyzstan (Кыргызстан)', id: 'KG', dialCode: '+996' },
  { label: 'Laos (ລາວ)', id: 'LA', dialCode: '+856' },
  { label: 'Latvia (Latvija)', id: 'LV', dialCode: '+371' },
  { label: 'Lebanon (‫لبنان‬‎)', id: 'LB', dialCode: '+961' },
  { label: 'Lesotho', id: 'LS', dialCode: '+266' },
  { label: 'Liberia', id: 'LR', dialCode: '+231' },
  { label: 'Libya (‫ليبيا‬‎)', id: 'LY', dialCode: '+218' },
  { label: 'Liechtenstein', id: 'LI', dialCode: '+423' },
  { label: 'Lithuania (Lietuva)', id: 'LT', dialCode: '+370' },
  { label: 'Luxembourg', id: 'LU', dialCode: '+352' },
  { label: 'Macau (澳門)', id: 'MO', dialCode: '+853' },
  { label: 'North Macedonia (FYROM) (Македонија)', id: 'MK', dialCode: '+389' },
  { label: 'Madagascar (Madagasikara)', id: 'MG', dialCode: '+261' },
  { label: 'Malawi', id: 'MW', dialCode: '+265' },
  { label: 'Malaysia', id: 'MY', dialCode: '+60' },
  { label: 'Maldives', id: 'MV', dialCode: '+960' },
  { label: 'Mali', id: 'ML', dialCode: '+223' },
  { label: 'Malta', id: 'MT', dialCode: '+356' },
  { label: 'Marshall Islands', id: 'MH', dialCode: '+692' },
  { label: 'Martinique', id: 'MQ', dialCode: '+596' },
  { label: 'Mauritania (‫موريتانيا‬‎)', id: 'MR', dialCode: '+222' },
  { label: 'Mauritius (Moris)', id: 'MU', dialCode: '+230' },
  { label: 'Mexico (México)', id: 'MX', dialCode: '+52' },
  { label: 'Micronesia', id: 'FM', dialCode: '+691' },
  { label: 'Moldova (Republica Moldova)', id: 'MD', dialCode: '+373' },
  { label: 'Monaco', id: 'MC', dialCode: '+377' },
  { label: 'Mongolia (Монгол)', id: 'MN', dialCode: '+976' },
  { label: 'Montenegro (Crna Gora)', id: 'ME', dialCode: '+382' },
  { label: 'Morocco (‫المغرب‬‎)', id: 'MA', dialCode: '+212' },
  { label: 'Mozambique (Moçambique)', id: 'MZ', dialCode: '+258' },
  { label: 'Myanmar (Burma) (မြန်မာ)', id: 'MM', dialCode: '+95' },
  { label: 'Namibia (Namibië)', id: 'NA', dialCode: '+264' },
  { label: 'Nauru', id: 'NR', dialCode: '+674' },
  { label: 'Nepal (नेपाल)', id: 'NP', dialCode: '+977' },
  { label: 'Netherlands (Nederland)', id: 'NL', dialCode: '+31' },
  { label: 'New Caledonia (Nouvelle-Calédonie)', id: 'NC', dialCode: '+687' },
  { label: 'New Zealand', id: 'NZ', dialCode: '+64' },
  { label: 'Nicaragua', id: 'NI', dialCode: '+505' },
  { label: 'Niger (Nijar)', id: 'NE', dialCode: '+227' },
  { label: 'Nigeria', id: 'NG', dialCode: '+234' },
  { label: 'North Korea (조선 민주주의 인민 공화국)', id: 'KP', dialCode: '+850' },
  { label: 'Norway (Norge)', id: 'NO', dialCode: '+47' },
  { label: 'Oman (‫عُمان‬‎)', id: 'OM', dialCode: '+968' },
  { label: 'Pakistan (‫پاکستان‬‎)', id: 'PK', dialCode: '+92' },
  { label: 'Palau', id: 'PW', dialCode: '+680' },
  { label: 'Palestine (‫فلسطين‬‎)', id: 'PS', dialCode: '+970' },
  { label: 'Panama (Panamá)', id: 'PA', dialCode: '+507' },
  { label: 'Papua New Guinea', id: 'PG', dialCode: '+675' },
  { label: 'Paraguay', id: 'PY', dialCode: '+595' },
  { label: 'Peru (Perú)', id: 'PE', dialCode: '+51' },
  { label: 'Philippines', id: 'PH', dialCode: '+63' },
  { label: 'Poland (Polska)', id: 'PL', dialCode: '+48' },
  { label: 'Portugal', id: 'PT', dialCode: '+351' },
  { label: 'Puerto Rico', id: 'PR', dialCode: '+1', priority: 4 },
  { label: 'Qatar (‫قطر‬‎)', id: 'QA', dialCode: '+974' },
  { label: 'Réunion (La Réunion)', id: 'RE', dialCode: '+262' },
  { label: 'Romania (România)', id: 'RO', dialCode: '+40' },
  { label: 'Russia (Россия)', id: 'RU', dialCode: '+7', priority: 1 },
  { label: 'Rwanda', id: 'RW', dialCode: '+250' },
  { label: 'Saint Kitts and Nevis', id: 'KN', dialCode: '+1869' },
  { label: 'Saint Lucia', id: 'LC', dialCode: '+1758' },
  { label: 'Saint Vincent and the Grenadines', id: 'VC', dialCode: '+1784' },
  { label: 'Samoa', id: 'WS', dialCode: '+685' },
  { label: 'San Marino', id: 'SM', dialCode: '+378' },
  { label: 'São Tomé and Príncipe (São Tomé e Príncipe)', id: 'ST', dialCode: '+239' },
  { label: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)', id: 'SA', dialCode: '+966' },
  { label: 'Senegal (Sénégal)', id: 'SN', dialCode: '+221' },
  { label: 'Serbia (Србија)', id: 'RS', dialCode: '+381' },
  { label: 'Seychelles', id: 'SC', dialCode: '+248' },
  { label: 'Sierra Leone', id: 'SL', dialCode: '+232' },
  { label: 'Singapore', id: 'SG', dialCode: '+65' },
  { label: 'Slovakia (Slovensko)', id: 'SK', dialCode: '+421' },
  { label: 'Slovenia (Slovenija)', id: 'SI', dialCode: '+386' },
  { label: 'Solomon Islands', id: 'SB', dialCode: '+677' },
  { label: 'Somalia (Soomaaliya)', id: 'SO', dialCode: '+252' },
  { label: 'South Africa', id: 'ZA', dialCode: '+27' },
  { label: 'South Korea (대한민국)', id: 'KR', dialCode: '+82' },
  { label: 'South Sudan (‫جنوب السودان‬‎)', id: 'SS', dialCode: '+211' },
  { label: 'Spain (España)', id: 'ES', dialCode: '+34' },
  { label: 'Sri Lanka (ශ්‍රී ලංකාව)', id: 'LK', dialCode: '+94' },
  { label: 'Sudan (‫السودان‬‎)', id: 'SD', dialCode: '+249' },
  { label: 'Suriname', id: 'SR', dialCode: '+597' },
  { label: 'Swaziland', id: 'SZ', dialCode: '+268' },
  { label: 'Sweden (Sverige)', id: 'SE', dialCode: '+46' },
  { label: 'Switzerland (Schweiz)', id: 'CH', dialCode: '+41' },
  { label: 'Syria (‫سوريا‬‎)', id: 'SY', dialCode: '+963' },
  { label: 'Taiwan (台灣)', id: 'TW', dialCode: '+886' },
  { label: 'Tajikistan', id: 'TJ', dialCode: '+992' },
  { label: 'Tanzania', id: 'TZ', dialCode: '+255' },
  { label: 'Thailand (ไทย)', id: 'TH', dialCode: '+66' },
  { label: 'Timor-Leste', id: 'TL', dialCode: '+670' },
  { label: 'Togo', id: 'TG', dialCode: '+228' },
  { label: 'Tonga', id: 'TO', dialCode: '+676' },
  { label: 'Trinisoad and Tobago', id: 'TT', dialCode: '+1868' },
  { label: 'Tunisia (‫تونس‬‎)', id: 'TN', dialCode: '+216' },
  { label: 'Turkey (Türkiye)', id: 'TR', dialCode: '+90' },
  { label: 'Turkmenistan', id: 'TM', dialCode: '+993' },
  { label: 'Tuvalu', id: 'TV', dialCode: '+688' },
  { label: 'Uganda', id: 'UG', dialCode: '+256' },
  { label: 'Ukraine (Україна)', id: 'UA', dialCode: '+380' },
  { label: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', id: 'AE', dialCode: '+971' },
  { label: 'United Kingdom', id: 'GB', dialCode: '+44', priority: 1 },
  { label: 'United States', id: 'US', dialCode: '+1', priority: 1 },
  { label: 'Uruguay', id: 'UY', dialCode: '+598' },
  { label: 'Uzbekistan (Oʻzbekiston)', id: 'UZ', dialCode: '+998' },
  { label: 'Vanuatu', id: 'VU', dialCode: '+678' },
  { label: 'Vatican City (Città del Vaticano)', id: 'VA', dialCode: '+39', priority: 1 },
  { label: 'Venezuela', id: 'VE', dialCode: '+58' },
  { label: 'Vietnam (Việt Nam)', id: 'VN', dialCode: '+84' },
  { label: 'Yemen (‫اليمن‬‎)', id: 'YE', dialCode: '+967' },
  { label: 'Zambia', id: 'ZM', dialCode: '+260' },
  { label: 'Zimbabwe', id: 'ZW', dialCode: '+263' },
];

import { BcmsDisplayType } from 'types';
import { DeviceTypes } from 'types/DeviceTypes/Device';

export type DashboardContentProps = {
  style: { gridColumn: string; gridRow: string };
  title: string;
  deviceType: DeviceTypes[];
  links: WidgetLinks;
};

export type DeviceSummaryProps = {
  total: number;
  fail: number;
  pass: number;
  warn: number;
  installed: number;
  inventory: number;
};

export type DashboardDeviceInfoProps = {
  deviceSummary?: DeviceSummaryProps;
};

export type WidgetLinks = {
  main: string | null;
  toInventory: string | null;
  toInstalled: string | null;
  toFailed: string | null;
  toWarning: string | null;
};

export const dashboardContent = (tenantId: string): DashboardContentProps[] => [
  {
    style: { gridColumn: 'span 1', gridRow: 'span 6' },
    title: 'Emergency Lighting',
    deviceType: [DeviceTypes.EmergencyLighting, DeviceTypes.EmergencyLightingLegacy],
    links: {
      main: `/${tenantId}/EmergencyLights/Listing/1e02985c-5924-467b-8a77-819229a97ab1`,
      toInventory: `/${tenantId}/EmergencyLights/Listing/3dc7ffa0-8985-4176-87d1-750cda5c5256`,
      toInstalled: `/${tenantId}/EmergencyLights/Listing/1e02985c-5924-467b-8a77-819229a97ab1`,
      toFailed: `/${tenantId}/EmergencyLights/Listing/8c17b32e-6e86-415a-83a4-6857bf14b28c`,
      toWarning: `/${tenantId}/EmergencyLights/Listing/f66cc9d7-a080-45b8-80a3-7a453f32d2b3`,
    },
  },
  {
    style: { gridColumn: 'span 1', gridRow: 'span 6' },
    title: 'Boilers',
    deviceType: [DeviceTypes.Boiler, DeviceTypes.BcmConnect],
    links: {
      main: `/${tenantId}/Boilers/Listing/6454913a-e60b-4468-992c-0c8aa18cef01`,
      toInventory: `/${tenantId}/Boilers/Listing/5e9cbc5a-55dc-4f25-afd0-f5942c1d59cf`,
      toInstalled: `/${tenantId}/Boilers/Listing/6454913a-e60b-4468-992c-0c8aa18cef01`,
      toFailed: `/${tenantId}/Boilers/Listing/9eb1cbcc-a7e5-4127-925a-f787bcbbb6e5`,
      toWarning: `/${tenantId}/Boilers/Listing/14628e41-c01f-4d20-9d09-5f58d8cada25`,
    },
  },
  {
    style: { gridColumn: 'span 1', gridRow: 'span 6' },
    title: 'Air Source Heat Pumps',
    deviceType: [DeviceTypes.AirSourceHeatPump],
    links: {
      main: `/${tenantId}/Bcms?type=${BcmsDisplayType.ASHP}`,
      toInventory: null,
      toInstalled: null,
      toFailed: null,
      toWarning: null,
    },
  },
  {
    style: { gridColumn: 'span 1', gridRow: 'span 6' },
    title: 'AutoFills',
    deviceType: [DeviceTypes.Autofill],
    links: {
      main: `/${tenantId}/Boilers/Listing/5002fda6-2439-44ab-9c84-c10aac55f17b`,
      toInventory: `/${tenantId}/Boilers/Listing/6ec0228a-d471-4a4b-8e8f-a76a7835e849`,
      toInstalled: `/${tenantId}/Boilers/Listing/5002fda6-2439-44ab-9c84-c10aac55f17b`,
      toFailed: null,
      toWarning: `/${tenantId}/Boilers/Listing/8c61f509-124a-4653-8aff-54ed1d4b8bb1`,
    },
  },
  {
    style: { gridColumn: 'span 1', gridRow: 'span 6' },
    title: 'MultiDOTs',
    deviceType: [DeviceTypes.MultiDot],
    links: {
      main: `/${tenantId}/Boilers/Listing/f593e047-ff98-4086-bb91-04d9cd066729`,
      toInventory: `/${tenantId}/Boilers/Listing/f717e5fc-a5e0-47c5-9b73-0138ccc960be`,
      toInstalled: `/${tenantId}/Boilers/Listing/f593e047-ff98-4086-bb91-04d9cd066729`,
      toFailed: null,
      toWarning: `/${tenantId}/Boilers/Listing/85df6970-6cfd-4db8-8363-e3b0001b321f`,
    },
  },
  {
    style: { gridColumn: 'span 1', gridRow: 'span 6' },
    title: 'EnviroDOTs',
    deviceType: [DeviceTypes.EnviroDot],
    links: {
      main: `/${tenantId}/Boilers/Listing/186add65-df2c-4964-bcc6-36c92dc64c88`,
      toInventory: `/${tenantId}/Boilers/Listing/d222f6cf-34f5-449e-8b55-11a1e35d1a03`,
      toInstalled: `/${tenantId}/Boilers/Listing/186add65-df2c-4964-bcc6-36c92dc64c88`,
      toFailed: null,
      toWarning: `/${tenantId}/Boilers/Listing/b6edac1b-6ab7-442c-96d1-852c431e9b9a`,
    },
  },
  {
    style: { gridColumn: 'span 1', gridRow: 'span 6' },
    title: 'Heat Interface Units',
    deviceType: [DeviceTypes.HeatInterfaceUnit],
    links: {
      main: null,
      toInventory: null,
      toInstalled: null,
      toFailed: null,
      toWarning: null,
    },
  },
  {
    style: { gridColumn: 'span 1', gridRow: 'span 6' },
    title: 'PowerSenses',
    deviceType: [DeviceTypes.PowerMeter],
    links: {
      main: `/${tenantId}/Boilers/Listing/12fc9d19-7ceb-4dbf-be3b-174474ab3c76`,
      toInventory: `/${tenantId}/Boilers/Listing/4b48c5fb-0198-4bc3-9395-42ecc8b8a7b2`,
      toInstalled: `/${tenantId}/Boilers/Listing/12fc9d19-7ceb-4dbf-be3b-174474ab3c76`,
      toFailed: null,
      toWarning: `/${tenantId}/Boilers/Listing/e75e0ef2-5e73-46fe-8d9f-401e61da226d`,
    },
  },
];

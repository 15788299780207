import React, { useState } from 'react';

import Spinner from 'components/atoms/Spinner';
import styled from 'styled-components';
import { handleFormReq } from 'utils/ApiRequestHelpers';
import { deviceTypeToArea } from 'utils/deviceUtils';
import useAccess from 'utils/hooks/useAccess';
import useAlert from 'utils/hooks/useAlert';

import FormItem from './FormItem';

const SubmitButton = styled.button`
  ${(props) =>
    props.theme.buttonStyle(
      props.theme.white,
      props.theme.success,
      props.theme.darkGreen,
      '100%',
      props.disabled
    )};
`;
const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const SpinnerWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: white;
  border-radius: 50%;
  margin-left: 1rem;
`;
const DeviceMaintenanceForm = ({
  device,
  title,
  message,
  endPoint,
  fetchAction,
  btnText,
  formItems,
  disabled,
  setIsButtonDisabled,
  successMsg,
}: any) => {
  const { errorAlert, successAlert } = useAlert();
  const { isEdit } = useAccess(deviceTypeToArea(device));
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = async (e) => {
    if (!disabled) {
      setIsButtonDisabled(true);
      const validateForm = (form) => {
        const hasCheck = form.querySelectorAll('input[type="checkbox"]:checked').length > 0;
        let hasInput = false;
        let hasTextArea = false;
        form.querySelectorAll('textarea').forEach((i) => {
          if (i.value !== '') hasTextArea = true;
        });
        form.querySelectorAll('input[type="text"]').forEach((i) => {
          if (i.value !== '') hasInput = true;
        });

        return hasCheck || hasInput || hasTextArea;
      };

      e.preventDefault();
      const data = new FormData(e.target);
      data.append('deviceId', device.deviceId);
      const isValid = validateForm(e.target);
      e.target.reset();

      if (isValid) {
        setIsLoading(true);
        const res = await handleFormReq(endPoint, data, 'POST');
        if (res) {
          successAlert(successMsg ?? 'Form has successfully been submitted');
          fetchAction();
        } else {
          errorAlert('Error! Please try again. If problem persists please contact support');
        }
        setIsLoading(false);
      } else {
        errorAlert('Form has not been completed');
      }
      setIsButtonDisabled(false);
    }
  };

  return (
    <>
      <h3>{title}</h3>
      <p>{message}</p>
      {isEdit && (
        <form onSubmit={handleOnSubmit}>
          {formItems.map((f, i) => (
            <FormItem key={i} formItem={f} />
          ))}
          <SubmitButton disabled={disabled}>
            <SubmitWrapper>
              {btnText}
              {isLoading && (
                <SpinnerWrapper>
                  <Spinner />
                </SpinnerWrapper>
              )}
            </SubmitWrapper>
          </SubmitButton>
        </form>
      )}
    </>
  );
};

export default DeviceMaintenanceForm;

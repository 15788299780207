import { TagsType } from '../types';

export const transformTagsToQuery = (tags: TagsType[]): string => {
  const searchParams = new URLSearchParams();

  tags.forEach(({ id }) => {
    searchParams.append('tag', id);
  });

  return searchParams.toString();
};

import styled from 'styled-components';

export const FailedToLoadingOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;

  .failed-to-load-text {
    color: #e65d6d;
    background-color: rgba(0, 0, 0, 0.1);
    font-weight: 500;
    padding: 15px;
    font-size: 20px;
  }
`;

import React from 'react';
import { useDispatch } from 'react-redux';
import { FormHelperText, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { ActionDialog } from 'components/molecules';
import { clearUsersStatus, deleteUser } from 'reducers/users';
import { useReduxApiData } from 'utils/hooks';
import { ApiActionNames } from 'reducers/types';

type DeleteUserDialogProps = {
  onClose: () => void;
  id: string;
  name: string;
};

export const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({ onClose, id, name }) => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, error } = useReduxApiData(
    (state) => state.users,
    ApiActionNames.DeleteUser
  );

  const handleDeleteUser = () => {
    dispatch(deleteUser({ id }));
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      dispatch(clearUsersStatus({ statusKey: 'deleteUser' }));
    });
  };

  return (
    <ActionDialog
      open
      onClose={handleClose}
      handleSubmit={handleDeleteUser}
      submitButtonName="Delete"
      submitButtonColor="error"
      submitButtonIcon={<DeleteIcon />}
      title="Delete user"
      sendingRequest={isLoading}
      successMessageProps={{
        message: 'User deleted successfully',
        showMessage: isSuccess,
        onClose: handleClose,
      }}
    >
      <Typography fontSize={'1.44rem'}>
        Are you sure that you want to delete user {name}?
      </Typography>
      {isError && (
        <FormHelperText error sx={{ fontSize: 14, textAlign: 'center', pt: 1 }}>
          {error}
        </FormHelperText>
      )}
    </ActionDialog>
  );
};

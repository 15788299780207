import { useCallback } from 'react';
import { useNavigate as useRouterNavigate } from 'react-router-dom';

import { isEmergencyLight, isBoiler, isNetworkDevice, isAdey } from 'utils/deviceUtils';

import useSelectedTenant from './useSelectedTenant';

export type Page =
  | 'Dashboard'
  | 'EmergencyLights'
  | 'Boilers'
  | 'NetworkingDevices'
  | 'ThirdParty'
  | 'FloorPlan'
  | 'Buildings'
  | 'Reporting'
  | 'Users'
  | 'Settings'
  | 'Support';

const mapToPage = (pageType): Page => {
  if (isEmergencyLight(pageType)) return 'EmergencyLights';
  if (isBoiler(pageType)) return 'Boilers';
  if (isNetworkDevice(pageType)) return 'NetworkingDevices';
  if (isAdey(pageType)) return 'ThirdParty';

  return pageType;
};

const useNavigate = () => {
  const navigate = useRouterNavigate();
  const tenant = useSelectedTenant();

  const goTo = useCallback(
    (page: Page | string, pageType: string = '', id: string = '') => {
      const pg = mapToPage(page);
      let url = `/${tenant}/${pg}`;
      if (pageType) url += `/${pageType}`;
      if (id) url += `/${id}`;

      navigate(url);
    },
    [navigate, tenant]
  );

  const goBack = () => navigate(-1);

  return { goTo, goBack };
};

export default useNavigate;

import { useState, useRef, useLayoutEffect } from 'react';

const useDimensions = () => {
  const ref = useRef();
  const [dimensions, setDimensions] = useState({});

  // useLayoutEffect(() => {
  //     if(ref && ref.current) {
  //         //@ts-ignore
  //         setDimensions(ref.current.getBoundingClientRect().toJSON());
  //     }
  // }, [ref.current]);

  useLayoutEffect(() => {
    if (ref && ref.current) {
      //@ts-ignore
      setDimensions(ref.current.getBoundingClientRect().toJSON());
    }
  }, []);

  return [ref, dimensions];
};

export default useDimensions;

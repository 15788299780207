export interface MapItem {
  lat: number;
  lng: number;
  deviceId: string;
  serialNumber: string;
  deviceType: string;
  deviceStatus: string;
  connectionStatus: string;
  deviceName?: string;
  floor?: string;
  building?: string;
}

const intialState: MapItem[] = [];

const SET_MAPDATA = 'SET_MAPDATA';
const CLEAR_MAPDATA = 'CLEAR_MAPDATA';

export const viewDataTypes = {
  SET_MAPDATA,
  CLEAR_MAPDATA,
};

export const setMapData = (data: MapItem[]) => ({
  type: SET_MAPDATA,
  payload: data,
});

export const mapDataReducer = (state = intialState, action) => {
  switch (action.type) {
    case viewDataTypes.SET_MAPDATA:
      return action.payload;
    case viewDataTypes.CLEAR_MAPDATA:
      return intialState;
    default:
      return state;
  }
};

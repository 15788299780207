import { useState } from 'react';

import Validator from 'types/Validator';

const useValidateInput = (validators: Validator[]) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [state, setState] = useState('');

  const validateValue = (v) =>
    validators.reduce((acc, cur) => (acc === '' && !cur.fn(v) ? cur.msg : acc), '');

  const validateInput = (value) => {
    const err = validateValue(value);
    setErrorMsg(err);
    setState(err ? 'error' : 'valid');

    return err;
  };

  return { errorMsg, state, validateInput };
};

export default useValidateInput;

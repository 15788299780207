import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { isEqual } from 'lodash';

import { FieldErrorMessages } from 'components/atoms';
import { usePutFetchData } from 'utils/hooks';
import { getErrorsList } from 'utils';
import { EditFormFieldsContainer } from '..';
import { EditFieldDropdownWrapper } from './EditFieldDropdown.styles';

type EditFieldDropdownProps = {
  url: string;
  onSuccess: () => void;
  getPayload: (val) => unknown;
  initialFormState?: string | { [key: string]: string | number | boolean } | null;
  options?: string[];
  renderList?: React.ReactNode;
  className?: string;
  inputLabel: string;
  editContainerProps?: {
    fieldValue?: string;
    fieldTitle?: string;
    editIcon?: React.ReactNode;
    allowEdit?: boolean;
  };
};

export const EditFieldDropdown: React.FC<EditFieldDropdownProps> = ({
  url,
  onSuccess,
  getPayload,
  initialFormState = '',
  options,
  renderList,
  className,
  editContainerProps,
  inputLabel,
}) => {
  const [selectedValue, setSelectedValue] = useState(initialFormState);
  const { mutate, isLoading, isError, isSuccess, reset, error } = usePutFetchData({
    url,
    onSuccess,
  });

  const isSubmitDisabled = isEqual(initialFormState, selectedValue) || isLoading || isError;

  const handleSubmit = () => mutate(getPayload(selectedValue));

  const handleOnChange = ({ target }) => {
    setSelectedValue(target.value);
    reset();
  };

  const handleFormClose = () => {
    reset();
    setSelectedValue(initialFormState);
  };

  const errorsList = getErrorsList({ apiError: error, isError: isError });

  return (
    <EditFieldDropdownWrapper {...(className ? { className } : {})}>
      <EditFormFieldsContainer
        onSubmit={handleSubmit}
        onClose={handleFormClose}
        isLoading={isLoading || isSuccess}
        isSuccess={isSuccess}
        submitDisabled={isSubmitDisabled}
        {...(editContainerProps ? editContainerProps : {})}
      >
        <FormControl className="edit-field-dropdown__select">
          <InputLabel id="edit-field-dropdown">{inputLabel}</InputLabel>
          <Select
            onChange={handleOnChange}
            labelId="edit-field-dropdown"
            error={isError}
            disabled={isLoading}
            fullWidth
            aria-label="edit-field-dropdown"
            label={inputLabel}
            value={selectedValue}
            MenuProps={{
              disableScrollLock: true,
            }}
          >
            {options
              ? !renderList &&
                options.map((option, optionIndex) => (
                  <MenuItem key={optionIndex.toString()} value={option}>
                    {option}
                  </MenuItem>
                ))
              : !!renderList && renderList}
          </Select>
        </FormControl>
        <FieldErrorMessages errors={errorsList} />
      </EditFormFieldsContainer>
    </EditFieldDropdownWrapper>
  );
};

import { handleReduxApiReq } from '../../utils/ApiRequestHelpers';

const FETCH_NOTES_REQUEST = 'FETCH_NOTES_REQUEST';
const FETCH_NOTES_ERROR = 'FETCH_NOTES_ERROR';
const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

export const noteTypes = {
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_ERROR,
  FETCH_NOTES_SUCCESS,
};

const fetchNoteRequest = () => ({
  type: FETCH_NOTES_REQUEST,
});

const fetchNoteSuccess = (notes) => ({
  type: FETCH_NOTES_SUCCESS,
  payload: { notes },
});

const fetchNoteError = (error) => ({
  type: FETCH_NOTES_ERROR,
  payload: { error },
});

export const fetchNoteAction = async (dispatch, deviceId) => {
  dispatch(fetchNoteRequest());
  try {
    const reqBody = {
      deviceId: deviceId,
    };
    const data = await handleReduxApiReq('GetDeviceNotes', reqBody, 'POST');
    dispatch(
      fetchNoteSuccess({
        data,
        deviceId,
      })
    );
  } catch (e) {
    dispatch(fetchNoteError(e));
  }
};

export const fetchNotes = (deviceId) => async (dispatch) => {
  await fetchNoteAction(dispatch, deviceId);
};

const initialState = {
  notes: null,
};

export const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case noteTypes.FETCH_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload.notes,
      };
    default:
      return state;
  }
};

import axios from 'axios';
import { useHandleApiErrors } from 'utils/hooks/APIRequestsHandlers';
import { useMutation } from 'react-query';
import { RESET_USER_PASSWORD_URL } from '../constants/users';

type MutationBody = { email: string };

export const useResetUserPassword = () => {
  const onError = useHandleApiErrors();

  return useMutation({
    mutationFn: async (body: MutationBody) => {
      const res = await axios.post(RESET_USER_PASSWORD_URL, {
        client_id: process.env.REACT_APP_CLIENTID,
        connection: 'Username-Password-Authentication',
        ...body,
      });

      return res;
    },
    onError,
  });
};

import React, { useEffect, useState } from 'react';

import ParentSize from '@visx/responsive/lib/components/ParentSize';
import styled from 'styled-components';

import LoadingOverlay from '../../../../atoms/LoadingOverlay';
import { Card } from '../../Configs/TelemetryConfigs';
import NumericDisplay from './NumericDisplay';

const CardContainer = styled.div<{ gridWidth: number }>`
  padding: 5px;
  background-color: #ffffff;
  margin: 1rem;
  grid-column: span ${(props) => props.gridWidth};
  @media ${(props) => props.theme.laptop} {
    grid-column: span ${(props) => props.gridWidth <= 6 && props.gridWidth * 2};
  }
  @media ${(props) => props.theme.mobileL} {
    grid-column: span 12;
  }
  border-radius: 5px;
  border: 1px solid #e2e2e2;
`;

const CardHeader = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.9em;
  height: 2.4em;
`;

const CardTitle = styled.div`
  font-size: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: bold;
`;

const CardSubtitle = styled.div`
  font-size: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const GraphWrapper = styled.div`
  height: 150px;
`;

const LoadingWrapper = styled.div`
  position: relative;
  padding: 2.5rem 1rem;
  overflow-x: auto;
  height: 100%;
  width: 100%;
  filter: blur(5px);
`;

const NoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.secondary};
`;

const ErrorDataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.vericonRed};
`;

const NumericCard = (props: { cardConfig: Card; isFetchingMetrics: boolean }) => {
  const [hasData, setHasData] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let dataCount = 0;
    let errorCount = 0;
    props.cardConfig.dataSeries.forEach((series) => {
      if (series.data?.series) {
        if (series.data?.series?.length > 0) dataCount++;
      } else if (series.data) {
        if (series.data?.series === null) errorCount++;
      }
    });
    setHasData(dataCount > 0);
    setHasError(errorCount > 0);
  }, [props.cardConfig, props.isFetchingMetrics]);

  return (
    <CardContainer gridWidth={props.cardConfig.gridWidth}>
      <CardHeader>
        <CardTitle>{props.cardConfig.title}</CardTitle>
        {!props.isFetchingMetrics && props.cardConfig.dataSeries[0].data && (
          <CardSubtitle>({props.cardConfig.dataSeries[0].data.unit})</CardSubtitle>
        )}
      </CardHeader>
      <GraphWrapper>
        {props.isFetchingMetrics ? (
          <LoadingWrapper>
            <LoadingOverlay />
          </LoadingWrapper>
        ) : (
          <ParentSize>
            {({ width, height }) => {
              if (width > 0 && height > 0) {
                if (!hasError && hasData) {
                  return (
                    <NumericDisplay
                      cardWidth={width}
                      cardHeight={height}
                      cardConfig={props.cardConfig}
                    />
                  );
                } else if (hasError) {
                  return (
                    <ErrorDataContainer>Something went wrong, please retry.</ErrorDataContainer>
                  );
                } else {
                  return <NoDataContainer>No data available</NoDataContainer>;
                }
              }
            }}
          </ParentSize>
        )}
      </GraphWrapper>
    </CardContainer>
  );
};

export default NumericCard;

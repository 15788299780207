import { createThunkEffect } from 'utils/ReduxUtils';

const FETCH_EMEREDDETAILS = 'FETCH_EMEREDDETAILS';
const REFRESH_EMEREDDETAILS = 'REFRESH_EMEREDDETAILS';
const FETCH_EMEREDTESTS = 'FETCH_EMEREDTESTS';
const UPDATE_LUMINAIRE = 'UPDATE_LUMINAIRE';

export const emeredActionTypes = {
  REFRESH_EMEREDDETAILS,
  FETCH_EMEREDDETAILS,
  FETCH_EMEREDTESTS,
  UPDATE_LUMINAIRE,
};

interface EmeredReq {
  deviceId: string;
  startDate: Date;
}

export const fetchEmeredDetails = (req: EmeredReq) => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_EMEREDDETAILS, 'GetEmered', req);

export const refreshEmeredDetails = (req: EmeredReq) => async (dispatch) =>
  await createThunkEffect(dispatch, REFRESH_EMEREDDETAILS, 'GetEmered', req);

export const fetchAllEmeredTests = (deviceId) => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_EMEREDTESTS, 'GetAllDeviceTests', { deviceId });

export const updateEmeredLuminaire = (deviceId, luminaire) => async (dispatch) =>
  await createThunkEffect(dispatch, UPDATE_LUMINAIRE, 'UpdateEmeredLuminaire', {
    deviceId,
    ...luminaire,
  });

import { useAlert } from 'utils/hooks';
import { handleApiErrors } from '../../api/handleApiErrors';

type UseHandleApiErrorsProps = {
  showValidationErrors?: boolean;
  showDomainErrors?: boolean;
};

export const useHandleApiErrors = (props?: UseHandleApiErrorsProps) => {
  const showValidationErrors = props?.showValidationErrors;
  const showDomainErrors = props?.showDomainErrors;
  const { errorAlert } = useAlert();

  return handleApiErrors({ showValidationErrors, showDomainErrors, errorToast: errorAlert });
};

import React, { useState } from 'react';

import { DevicePartial } from 'types/DevicePartial';
import useAlert from 'utils/hooks/useAlert';
import useNavigate from 'utils/hooks/useNavigate';
import { ActionDialog } from 'components/molecules';
import { AllDevices } from 'types/AllDevices';

import { commonText } from 'components/constants';
import { handleUninstallDeviceRequest } from './utils/uninstallUtils';

export interface UninstallDeviceProps {
  device: AllDevices | DevicePartial;
  handleResetAction: () => void;
  shouldBeRedirected?: boolean;
  isOpen: boolean;
}

export const UninstallDevice: React.FC<UninstallDeviceProps> = ({
  device,
  handleResetAction,
  shouldBeRedirected,
  isOpen,
}) => {
  const [sendingRequest, setSendingRequest] = useState(false);
  const [open, setOpen] = React.useState(isOpen);
  const { goTo } = useNavigate();
  const { errorAlert, successAlert } = useAlert();

  const handleReset = () => {
    setSendingRequest(false);
    setOpen(false);
    handleResetAction();
  };

  const handleUninstall = async () => {
    setSendingRequest(true);

    const { error, message } = await handleUninstallDeviceRequest(device.deviceId);

    if (error) {
      errorAlert(message, false);
      handleReset();

      return;
    }

    successAlert(message, false);

    if (shouldBeRedirected) {
      goTo(device.deviceType);
    }

    handleReset();
  };

  const handleCloseDialog = () => {
    if (!sendingRequest) {
      setOpen(false);
      handleResetAction();
    }
  };

  return (
    <ActionDialog
      open={open}
      title={`Uninstall Device ${device.serialNumber}`}
      submitButtonName="Uninstall"
      sendingRequest={sendingRequest}
      handleSubmit={handleUninstall}
      onClose={handleCloseDialog}
    >
      {commonText.uninstallDescription}
    </ActionDialog>
  );
};

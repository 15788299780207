import React from 'react';

import FilterInput from 'components/elements/Filters';
import styled from 'styled-components';
import useWindowWidth from 'utils/hooks/useWindowWidth';

const FiltersWrapper = styled.div<{ count: number }>`
  ${(props) => props.theme.wrapper};
  padding: 2.5rem 2rem;
  margin: 3rem 0;
  display: flex;
  font-size: ${(props) => props.theme.textSmall};
  /* justify-content: space-around; */
  align-items: flex-end;
  flex-wrap: wrap;

  & > div:not(:last-of-type) {
    margin: 0 ${(props) => (props.count === 2 ? '2rem' : 'auto')};

    @media ${(props) => props.theme.laptop} {
      margin: 0 auto 1rem auto;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;

  @media ${(props) => props.theme.laptop} {
    margin: 1rem auto 0 auto;
  }

  & button {
    margin: 0 1rem;

    @media ${(props) => props.theme.laptopL} {
      margin: 0 0.5rem;
    }
  }
`;

const ClearButton = styled.button`
  ${(props) => props.theme.clearButton('10rem')};
`;

const Filters = ({ filters, handleOnClear }) => {
  const width = useWindowWidth();
  const count = filters ? filters.length : 0;

  return (
    <FiltersWrapper count={count}>
      {filters.map((item, i) => (
        <FilterInput key={i} {...item} width={width > 1024 ? '20%' : '44%'} />
      ))}
      <ButtonWrapper>
        <ClearButton onClick={() => handleOnClear()}>Clear</ClearButton>
      </ButtonWrapper>
    </FiltersWrapper>
  );
};

export default Filters;

import React, { useEffect, useState } from 'react';

import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { timeFormat } from 'd3-time-format';
import moment from 'moment';
import styled from 'styled-components';

import LoadingOverlay from '../../../../atoms/LoadingOverlay';
import { Card, EventDataProps } from '../../Configs/TelemetryConfigs';
import EventTimeline from './EventTimeline';

const CardContainer = styled.div<{ gridWidth: number }>`
  padding: 5px;
  background-color: #ffffff;
  margin: 1rem;
  grid-column: span ${(props) => props.gridWidth};
  @media ${(props) => props.theme.laptop} {
    grid-column: span ${(props) => props.gridWidth <= 6 && props.gridWidth * 2};
  }
  @media ${(props) => props.theme.mobileL} {
    grid-column: span 12;
  }
  border-radius: 5px;
  border: 1px solid #e2e2e2;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
  font-size: 0.9em;
`;

const CardTitle = styled.div`
  font-size: 1.2em;
`;

const GraphWrapper = styled.div`
  height: 150px;
  width: 100%;
`;

const LoadingWrapper = styled.div`
  position: relative;
  padding: 2.5rem 1rem;
  overflow-x: auto;
  height: 100%;
  width: 100%;
  filter: blur(5px);
`;

const ErrorDataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.vericonRed};
`;

const EventListContainer = styled.div`
  border-top: 1px solid lightgray;
  padding: 5px;
`;

const EventList = styled.div`
  width: 100%;
  height: 6.5rem;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const EventItem = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const EventDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

const EventDescription = styled.span`
  font-size: 0.9em;
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
`;

const EventMeta = styled.span`
  font-size: 0.8em;
  font-weight: bold;
`;

const EventMarker = styled.div<{ severity: string }>`
  width: 10px;
  background-color: ${(props) =>
    (props.severity === 'High' && '#E65D6D') ||
    (props.severity === 'Medium' && '#EDB760') ||
    '#0187B5'};
  border-radius: 5px;
  margin-right: 5px;
`;

const DetailsContainer = styled.div`
  padding-right: 5px;
  min-width: 350px;
  max-width: 450px;
`;

const NoEventsPlaceholder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.secondary};
`;

const OngoingText = styled.span`
  color: ${(props) => props.theme.vericonRed};
`;

interface DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const formatDateTime = timeFormat('%d %b %H:%M');
const formatTime = timeFormat('%H:%M');

const EventCard = (props: {
  cardConfig: Card;
  isFetchingMetrics: boolean;
  dateRange: DateRange;
}) => {
  const [hasError, setHasError] = useState(false);

  const [selectedEventList, setSelectedEventList] = useState<Array<EventDataProps>>([]);

  useEffect(() => {
    let errorCount = 0;

    props.cardConfig.dataSeries.forEach((series) => {
      if (series.data) {
        if (series.data?.eventSeries === null) errorCount++;
      }
    });

    setHasError(errorCount > 0);
  }, [props.cardConfig, props.isFetchingMetrics]);

  const updateSelectedEventList = (eventList: Array<EventDataProps>): void => {
    setSelectedEventList(eventList);
  };

  const formatEventDescriptionDates = (event: EventDataProps) => {
    const eventStartDate = moment(event.start.setSeconds(0));
    const eventEndDate = moment(event.end.setSeconds(0));
    if (eventEndDate.isAfter(eventStartDate)) {
      if (eventEndDate.isAfter(moment(new Date()))) {
        return (
          <>
            {`${formatDateTime(event.start)} - `}
            <OngoingText>ongoing</OngoingText>
          </>
        );
      } else {
        if (eventEndDate.diff(eventStartDate, 'days') > 0) {
          return <>{`${formatDateTime(event.start)} - ${formatDateTime(event.end)}`}</>;
        } else {
          return <>{`${formatDateTime(event.start)} - ${formatTime(event.end)}`}</>;
        }
      }
    } else {
      return <>{formatDateTime(event.start)}</>;
    }
  };

  return (
    <CardContainer gridWidth={props.cardConfig.gridWidth}>
      <CardHeader>
        <CardTitle>{props.cardConfig.title}</CardTitle>
        {props.cardConfig.dataSeries.map(({ contextLabel }) => (
          <div key={contextLabel}>{contextLabel}</div>
        ))}
      </CardHeader>
      <GraphWrapper>
        {props.isFetchingMetrics ? (
          <LoadingWrapper>
            <LoadingOverlay />
          </LoadingWrapper>
        ) : (
          <ParentSize>
            {({ width, height }) => {
              if (width > 0 && height > 0) {
                if (!hasError) {
                  return (
                    <EventTimeline
                      graphWidth={width}
                      graphHeight={height}
                      cardConfig={props.cardConfig}
                      dateRange={props.dateRange}
                      selectedEventList={selectedEventList}
                      updateSelectedEventList={updateSelectedEventList}
                    />
                  );
                } else {
                  return (
                    <ErrorDataContainer>Something went wrong, please retry.</ErrorDataContainer>
                  );
                }
              }
            }}
          </ParentSize>
        )}
      </GraphWrapper>

      {!props.isFetchingMetrics && (
        <EventListContainer>
          <EventList>
            {selectedEventList.length > 0 ? (
              selectedEventList.map((event, i) => (
                <EventItem key={`${event.category}-${i}`}>
                  <EventMarker severity={event.severity} />

                  <DetailsContainer>
                    <EventDetails>
                      <EventMeta>{formatEventDescriptionDates(event)}</EventMeta>
                      <EventMeta style={{ textAlign: 'right' }}>{event.contextLabel}</EventMeta>
                    </EventDetails>
                    <EventDescription title={event.description}>
                      {event.description}
                    </EventDescription>
                  </DetailsContainer>
                </EventItem>
              ))
            ) : (
              <NoEventsPlaceholder>
                <span>No events selected</span>
              </NoEventsPlaceholder>
            )}
          </EventList>
        </EventListContainer>
      )}
    </CardContainer>
  );
};

export default EventCard;

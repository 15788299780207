import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { shouldDeviceUpdate } from 'reducers/device/deviceActions';

import useInterval from './useInterval';

const useShouldUpdateDevice = (deviceId, callback) => {
  const [forceUpdate, setForceUpdate] = useState(0);
  const dispatch = useDispatch();
  const lastUpdate = useSelector((state: any) => state.device.lastUpdate);
  const shouldUpdate = useSelector((state: any) => state.device.shouldUpdate);

  const checkStatus = () => dispatch(shouldDeviceUpdate(deviceId, lastUpdate));
  const interval = process.env.REACT_APP_ENV !== 'dev' ? 3000 : 60000;
  useInterval(checkStatus, shouldUpdate ? null : interval);

  useEffect(() => {
    if (shouldUpdate || forceUpdate) callback();
  }, [shouldUpdate, callback, forceUpdate]);

  const triggerUpdate = () => setForceUpdate((prev) => prev + 1);

  return triggerUpdate;
};

export default useShouldUpdateDevice;

import React from 'react';

import styled from 'styled-components';

import Filters, { FiltersProps } from './Filters';

const Wrapper = styled.div`
  margin: 1rem 0;
`;

interface TableHeaderProps {
  filterProps: FiltersProps;
}

const TableFilters = ({ filterProps }: TableHeaderProps) => (
  <Wrapper>
    <Filters {...filterProps} />
  </Wrapper>
);

export default TableFilters;

import React, { useMemo, useEffect, useRef } from 'react';
import * as allCurves from '@visx/curve';
import { Moment } from 'moment';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import { Axis } from '@visx/axis';
import { LinePath, Line, Bar } from '@visx/shape';
import { useTooltip, useTooltipInPortal, defaultStyles, TooltipWithBounds } from '@visx/tooltip';

import { CardExtended } from 'types/Telemetry';
import { useChartContext } from 'utils/contexts';
import { CustomTooltip, LineGraphWrapper, TooltipMarker } from './LineChart.styles';
import { getNumTicks, getX, getY, handleTooltipData } from './utils';
import {
  curveType,
  defaultLineColor,
  formatDateTime,
  bottomTooltipOffset,
  topTooltipOffset,
  paddingX,
  paddingY,
} from './constants';
import { useManageTooltip, useTooltipAxisCalculation } from './hooks';

export type CurveProps = {
  graphWidth: number;
  graphHeight: number;
  chart: CardExtended;
  dateRange: Moment[];
};

export const LineChart: React.FC<CurveProps> = ({ chart, graphHeight, graphWidth, dateRange }) => {
  const chartContext = useChartContext();

  const { tooltipData, tooltipLeft, tooltipTop, showTooltip } = useTooltip();

  const { containerRef, containerBounds } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
    polyfill: ResizeObserverPolyfill,
  });

  const metricTooltipRef = useRef<HTMLDivElement>(null);

  const dataSeries = chart.dataSeries;
  const lineColor = chart.color ?? defaultLineColor;

  const numTicks = useMemo(() => getNumTicks(graphWidth), [graphWidth]);

  const { dateScale, metricScale } = useTooltipAxisCalculation({
    chart,
    dateRange,
    graphWidth,
    graphHeight,
  });

  const {
    getMetricTooltipXPosition,
    getDateTimeTooltipXPosition,
    handleTooltipPause,
    handleTooltipDefault,
    handleTooltip,
    getTooltipData,
  } = useManageTooltip({ dateScale, metricScale, metricTooltipRef, tooltipLeft, containerBounds });

  useEffect(() => {
    if (dataSeries && dataSeries.length > 0 && chartContext.selectedDateTime) {
      const tooltipToShow = handleTooltipData({ dataSeries, getTooltipData, chartContext });

      showTooltip({
        tooltipData: tooltipToShow.data,
        tooltipLeft: tooltipToShow.xPosition,
        tooltipTop: tooltipToShow.yPosition,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContext]);

  return (
    <LineGraphWrapper ref={containerRef}>
      <svg width={graphWidth} height={graphHeight}>
        <rect
          x={0}
          y={0}
          width={graphWidth > 0 ? graphWidth : 0}
          height={graphHeight > 0 ? graphHeight : 0}
          fill="transparent"
        />

        <Axis
          scale={dateScale}
          top={graphHeight - paddingY}
          orientation="bottom"
          stroke="darkgrey"
          strokeWidth={1}
          tickStroke="darkgrey"
          numTicks={numTicks}
          tickLabelProps={() => ({
            fill: 'grey',
            textAnchor: 'start',
            verticalAnchor: 'middle',
            fontSize: '0.9em',
            transform: `translate(${-paddingX})`,
          })}
        />

        <Axis
          hideZero
          scale={metricScale}
          left={paddingX}
          orientation="right"
          numTicks={3}
          stroke={'darkGrey'}
          strokeWidth={1}
          tickStroke={'darkGrey'}
          tickLabelProps={() => ({
            fill: 'grey',
            verticalAnchor: 'middle',
            fontSize: '0.9em',
          })}
          tickFormat={(value) => `${value}`}
        />

        {dataSeries && (
          <LinePath
            curve={allCurves[curveType]}
            data={dataSeries}
            x={(d) => dateScale(getX(d)) ?? 0}
            y={(d) => metricScale(getY(d)) ?? 0}
            stroke={lineColor}
            fill="transparent"
            strokeWidth={3}
            strokeOpacity={1}
            shapeRendering="geometricPrecision"
          />
        )}
        <Bar
          x={paddingX}
          y={paddingY}
          width={graphWidth - paddingX * 2}
          height={graphHeight - paddingY * 2}
          fill="transparent"
          rx={14}
          onTouchStart={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onTouchMove={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onMouseMove={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onMouseLeave={() => !chartContext.pauseSelection && handleTooltipDefault()}
          onClick={() => handleTooltipPause()}
        />

        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: paddingY - 10 }}
              to={{ x: tooltipLeft, y: graphHeight - paddingY }}
              stroke={'#666'}
              strokeWidth={2}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={5}
              fill={'#666'}
              stroke="white"
              strokeWidth={2}
              pointerEvents="none"
            />
          </g>
        )}
      </svg>
      {tooltipData && (
        <div className="tooltipcontainerbla">
          <TooltipWithBounds
            key={Math.random()}
            top={topTooltipOffset}
            left={getMetricTooltipXPosition()}
            style={{
              ...defaultStyles,
              textAlign: 'right',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: 'black',
            }}
          >
            <CustomTooltip ref={metricTooltipRef}>
              <TooltipMarker lineColor={lineColor} />
              {`${getY(tooltipData) !== undefined ? getY(tooltipData).toFixed(2) : ' - '} ${
                chart.units
              }`}
            </CustomTooltip>
          </TooltipWithBounds>
          <TooltipWithBounds
            key={Math.random()}
            top={graphHeight - bottomTooltipOffset}
            left={getDateTimeTooltipXPosition()}
            style={{
              ...defaultStyles,
              textAlign: 'center',
              minWidth: '72px',
              color: 'black',
            }}
          >
            {`${formatDateTime(getX(tooltipData))}`}
          </TooltipWithBounds>
        </div>
      )}
    </LineGraphWrapper>
  );
};

import { usePutFetchData } from 'utils/hooks/APIRequestsHandlers/usePutFetchData';
import useAlert from 'utils/hooks/useAlert';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';

type UseSetDeviceAccessRightsTagProps = {
  deviceId: string;
  onSuccess: () => void;
  onError?: () => void;
  noAlert?: boolean;
};

export const useSetDeviceAccessRightsTag = ({
  deviceId,
  onSuccess,
  onError,
  noAlert,
}: UseSetDeviceAccessRightsTagProps) => {
  const tenant = useSelectedTenant();
  const { errorAlert } = useAlert();

  const mutation = usePutFetchData({
    url: `${process.env.REACT_APP_APIURL}Tenants/${tenant}/Device/${deviceId}/AccessRightsTag`,
    onSuccess: () => {
      onSuccess();
    },
    onError: () => {
      onError?.();

      if (!noAlert) {
        errorAlert('Failed to update device access rights tag!');
      }
    },
  });

  return {
    ...mutation,
    setDeviceAccessRightsTag: mutation.mutate,
  };
};

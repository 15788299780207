import styled from 'styled-components';

export const ReportCustomizationDialogWrapper = styled.div`
  max-width: 340px;

  .report-customization-dialog {
    min-width: 400px;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 42px;

      &-text {
        font-size: 21px;
      }

      &-close {
        position: absolute;
        top: 6px;
        right: 6px;
      }
    }

    &__dates {
      &-title {
        padding-bottom: 12px;
      }

      &-inputs {
        display: flex;
        gap: 15px;
        padding-bottom: 16px;
      }
    }

    &__tags {
      &-title {
        padding-bottom: 12px;
      }

      .MuiFormControl-root {
        .MuiFormLabel-root {
          transform: translate(14px, 6px) scale(1);
          font-size: 16px;

          &.Mui-focused {
            transform: translate(14px, -11px) scale(0.75);
          }

          @media (min-width: 2560px) {
            transform: translate(14px, 7px) scale(1);

            &.Mui-focused {
              transform: translate(14px, -11px) scale(0.75);
            }
          }
        }
      }
    }

    &__button {
      padding-bottom: 12px;
      padding-right: 12px;
    }
  }
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuPropsStyles = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

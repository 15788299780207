export enum DataGridColumns {
  PropertyReference = 'propertyReference',
  Postcode = 'postcode',
  Address = 'address',
  SerialNumber = 'serialNumber',
  Status = 'status',
  AccessRightTag = 'accessRightTag',
  Manufacturer = 'manufacturer',
  Tags = 'tags',
  Model = 'model',
  BoilerGC = 'boilerGC',
  FirmwareVersion = 'firmwareVersion',
  DeviceId = 'deviceId',
  ProductName = 'productName',
  Location = 'location',
  LocationDetails = 'locationDetails',
  Product = 'product',
  FaultCode = 'faultCode',
}

export enum DataGridColumnsHidden {
  AccessRightTag = DataGridColumns.AccessRightTag,
  Manufacturer = DataGridColumns.Manufacturer,
  Tags = DataGridColumns.Tags,
  Model = DataGridColumns.Model,
  BoilerGC = DataGridColumns.BoilerGC,
  FirmwareVersion = DataGridColumns.FirmwareVersion,
  DeviceId = DataGridColumns.DeviceId,
  ProductName = DataGridColumns.ProductName,
  Location = DataGridColumns.Location,
  LocationDetails = DataGridColumns.LocationDetails,
  Product = DataGridColumns.Product,
}

export enum DataGridColumnsHiddenBcms {
  AccessRightTag = DataGridColumns.AccessRightTag,
  Manufacturer = DataGridColumns.Manufacturer,
  Tags = DataGridColumns.Tags,
  Model = DataGridColumns.Model,
  BoilerGC = DataGridColumns.BoilerGC,
  FirmwareVersion = DataGridColumns.FirmwareVersion,
  DeviceId = DataGridColumns.DeviceId,
  ProductName = DataGridColumns.ProductName,
  Location = DataGridColumns.Location,
  Address = DataGridColumns.Address,
  Postcode = DataGridColumns.Postcode,
  LocationDetails = DataGridColumns.LocationDetails,
  FaultCode = DataGridColumns.FaultCode,
}

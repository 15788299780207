import React, { useState } from 'react';
import {
  LinearProgress,
  TableHead,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { ActionDialog } from '../ActionDialog/ActionDialog';
import { TagsTableWrapper, StyledTableCell, StyledTableRow } from './TagsTable.styles';

export const DELETE_ACCESS_RIGHTS_TAG_DIALOG_INITIAL_STATE = {
  open: false,
  tagId: '',
  tagName: '',
};

type TagsTableProps = {
  tags: AccessRightsTag[];
  isLoading: boolean;
  isManager: boolean;
  handleDeleteAccessRightsTag: (tagId: string) => void;
};

export const TagsTable: React.FC<TagsTableProps> = ({
  tags,
  isLoading,
  isManager,
  handleDeleteAccessRightsTag,
}) => {
  const [deleteDialog, setDeleteDialog] = useState(DELETE_ACCESS_RIGHTS_TAG_DIALOG_INITIAL_STATE);

  return (
    <TagsTableWrapper className="tags-table-wrapper">
      <Paper elevation={3} className="tags-table">
        {isLoading && (
          <div className="tags-table__linear-progress">
            <LinearProgress />
          </div>
        )}
        <TableContainer sx={{ maxHeight: '60vh' }} className="tags-table__container">
          <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 300 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                {isManager && <StyledTableCell align="right">Action</StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map(({ displayName, id }, rowIndex) => (
                <StyledTableRow key={rowIndex.toString()}>
                  <StyledTableCell {...(!isManager && { style: { height: 42 } })} align="left">
                    {displayName}
                  </StyledTableCell>
                  {isManager && (
                    <StyledTableCell align="right">
                      <div className="tags-table__icons">
                        <Tooltip arrow title="Delete tag" placement="left">
                          <IconButton
                            aria-label="delete-access-rights-tag-button"
                            onClick={() =>
                              setDeleteDialog({ open: true, tagId: id, tagName: displayName })
                            }
                            className="tags-table__delete-icon"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ActionDialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog(DELETE_ACCESS_RIGHTS_TAG_DIALOG_INITIAL_STATE)}
        handleSubmit={() => {
          handleDeleteAccessRightsTag(deleteDialog.tagId);
          setDeleteDialog(DELETE_ACCESS_RIGHTS_TAG_DIALOG_INITIAL_STATE);
        }}
        submitButtonName="Delete"
        submitButtonColor="error"
        submitButtonIcon={<DeleteIcon />}
        title="Delete tag"
        sendingRequest={isLoading}
      >
        Are you sure that you want to delete tag {deleteDialog.tagName}
      </ActionDialog>
    </TagsTableWrapper>
  );
};

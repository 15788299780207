import React, { useState } from 'react';

import Modal from 'components/elements/Modal';
import Spinner from 'components/atoms/Spinner';
import {
  ButtonWrapper,
  CancelButton,
  ModalMessageWrapper,
  SpinnerWrapper,
  SubmitButton,
  Wrapper,
} from './DeviceActionMainDialog.styles';

interface DeviceActionMainDialogProps {
  handleResetAction: () => void;
  handleSubmit: () => Promise<void>;
  submitLabel?: string;
  hideButtons?: boolean;
  children?: React.ReactNode;
  withSpinner?: boolean;
  title?: string;
}

export const DeviceActionMainDialog: React.FC<DeviceActionMainDialogProps> = ({
  handleResetAction,
  handleSubmit,
  submitLabel = 'Submit',
  hideButtons,
  withSpinner,
  children,
  title = '',
}) => {
  const [sendingRequest, setSendingRequest] = useState(false);

  const handleLocalSubmit = () => {
    if (withSpinner) {
      setSendingRequest(true);
    }

    handleSubmit();
  };

  const isButtonsHidden = hideButtons || (withSpinner && sendingRequest);

  return (
    <Modal showModal handleClose={handleResetAction} title={title} overflow="intial">
      <Wrapper>
        {children}
        {!isButtonsHidden && (
          <ButtonWrapper>
            <SubmitButton className="device-action__submit-button" onClick={handleLocalSubmit}>
              {submitLabel}
            </SubmitButton>
            <CancelButton onClick={handleResetAction}>Cancel</CancelButton>
          </ButtonWrapper>
        )}
        {sendingRequest && (
          <ModalMessageWrapper>
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          </ModalMessageWrapper>
        )}
      </Wrapper>
    </Modal>
  );
};

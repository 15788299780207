import { ThermostatConnection } from 'types/BoilerTypes/BcmConnectDetails';

export const thermostatConnectionText: { [key in ThermostatConnection]: string } = {
  [ThermostatConnection.None]: 'None',
  [ThermostatConnection.BusThermostatConnectedToBoiler]:
    'Bus thermostat is connected to Boiler (non-OpenTherm)',
  [ThermostatConnection.ThirdPartyOpenThermConnectedToBcm]:
    'Third Party OpenTherm thermostat is connected to BCM',
  [ThermostatConnection.VericonOpenThermConnectedToBcm]:
    'Vericon thermostat is connected to BCM (OpenTherm)',
  [ThermostatConnection.CallForHeatThermostatConnectedToBoiler]:
    'Call-for-heat thermostat is connected to Boiler',
  [ThermostatConnection.CallForHeatThermostatConnectedToBcm]:
    'Call-for-heat thermostat is connected to BCM',
};

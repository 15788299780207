import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { fetchTestSchedules } from 'reducers/tenantInfo';
import styled from 'styled-components';
import { handleApiReq } from 'utils/ApiRequestHelpers';
import useValidateForm from 'utils/hooks/useValidateForm';
import { isRequired, isUnique, isInList } from 'utils/inputValidation';

import GroupInputs from './GroupInputs';
import MapContainer from './MapContainer';
import MapLegend from './MapLegend';

const Wrapper = styled.div`
  min-height: 80vh;
`;

const MapWrapper = styled.div`
  position: relative;
  height: 70vh;
`;

const UpdateEmeredTestGroup = ({ group, buildingInfo, isUpdate, closeModal, groups }) => {
  const dispatch = useDispatch();
  const [groupData, setGroupData] = useState({
    ...group,
    t180StartDate: group.t180StartDate
      ? group.t180StartDate
      : new Date(new Date().setMinutes(0, 0, 0)),
  });

  const [selectedBuilding, setSelectedBuilding] = useState();
  const [conflictingGroups, setConflictingGroups] = useState([]);
  const getBuildingIds = (buildingInfo) => buildingInfo.map((b) => b.building);

  const validationSchema = {
    groupName: [
      { fn: isRequired, msg: 'Group Name is required' },
      { fn: isUnique(groups), msg: 'Group Name already being used' },
    ],
    building: [
      { fn: isRequired, msg: 'Building is required' },
      { fn: isInList(getBuildingIds(buildingInfo)), msg: 'Building is not in list' },
    ],
  };

  const { validationState, validateForm, validateInput } = useValidateForm(
    groupData,
    validationSchema
  );

  useEffect(() => {
    setSelectedBuilding(buildingInfo.find((b) => b.building === groupData.building));

    if (groupData.building) {
      setSelectedBuilding(buildingInfo.find((b) => b.building === groupData.building));
    } else if (buildingInfo.length === 1) {
      setSelectedBuilding(buildingInfo[0]);
      setGroupData({
        ...groupData,
        building: buildingInfo[0].building,
      });
    }

    const fetchConflictingGroups = async () => {
      const body = {
        ...groupData,
      };
      const data = await handleApiReq('GetConflictingTestSchedules', body, 'POST');
      setConflictingGroups(data);
    };
    fetchConflictingGroups();
  }, [groupData, buildingInfo]);

  const handleAddGroup = async (deviceIds) => {
    const isValid = validateForm(true);
    if (isValid) {
      const data = {
        ...groupData,
        deviceIds,
      };
      const res = await handleApiReq('AddEmeredGroup', data, 'POST');
      if (res) {
        closeModal();
        dispatch(fetchTestSchedules());
      }
    }
  };

  const handleUpdateGroup = async (deviceIds) => {
    const isValid = validateForm(true);

    if (isValid) {
      const data = {
        groupName: groupData.groupName,
        t180StartDate: groupData.t180StartDate,
        deviceIds: deviceIds,
      };

      const res = await handleApiReq('UpdateEmeredGroup', data, 'POST');
      if (res) {
        closeModal();
        dispatch(fetchTestSchedules());
      }
    }
  };

  const onInputChange = (value, name) => {
    setGroupData({
      ...groupData,
      [name]: value,
    });
    validateInput(value, name);
  };

  return (
    <Wrapper>
      <GroupInputs
        groupData={groupData}
        onInputChange={onInputChange}
        buildingInfo={buildingInfo}
        isUpdate={isUpdate}
        validationState={validationState}
      />
      {selectedBuilding ? (
        <MapWrapper>
          <MapLegend />
          <MapContainer
            group={groupData}
            conflictingGroups={conflictingGroups}
            selectedBuilding={selectedBuilding}
            handleSubmit={isUpdate ? handleUpdateGroup : handleAddGroup}
          />
        </MapWrapper>
      ) : (
        <MapWrapper>
          <h1>Please select a building</h1>
        </MapWrapper>
      )}
    </Wrapper>
  );
};

export default UpdateEmeredTestGroup;

import styled from 'styled-components';

export const PropertyWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  .property {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0 5rem 0;

      & h2 {
        margin-left: 2rem;
      }

      @media ${(props) => props.theme.mobileL} {
        align-items: center;
      }

      @media ${(props) => props.theme.laptopM} {
        align-items: flex-start;
        flex-direction: column;
        margin: 1rem 0 5rem 0;
      }

      &-left {
        display: flex;
        @media ${(props) => props.theme.tabPort} {
          margin-bottom: 1rem;
        }
      }
    }

    &__container {
      display: flex;
      flex-direction: column;

      &-content {
        padding: 1rem 2rem;
        position: relative;
        ${(props) => props.theme.wrapper};
      }
    }
  }
`;



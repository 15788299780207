import React from 'react';

import styled from 'styled-components';

const TableInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  height: 1.6rem;
  justify-content: start;
  width: 40rem;
`;
const SelectedWrapper = styled.div`
  padding-left: 0.5rem;
  width: 100%;
  text-align: left;
`;

const Span = styled.span`
  display: inline-block;
  margin-left: 1rem;

  border-bottom: 1px solid transparent;

  transition: all 0.3s ease-in-out;
  font-weight: 500;
  font-size: 1.4rem;
  opacity: 0.6;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const SelectAll = styled(Span)`
  color: ${({ theme }) => theme.spanBlue};
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.spanBlue};
  }
`;

const ClearAll = styled(Span)`
  color: ${({ theme }) => theme.grey};
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

export interface TableInfoProps {
  selected: number;
  total: number;
  selectAllRows;
  clearAllRows;
}

const TableInfo = ({ selected, total, selectAllRows, clearAllRows }: TableInfoProps) => (
  <TableInfoWrapper>
    {selected > 0 && (
      <SelectedWrapper>
        {selected} of {total} selected
        {selected !== total && <SelectAll onClick={selectAllRows}>Select all rows</SelectAll>}
        <ClearAll onClick={clearAllRows}>Clear selection</ClearAll>
      </SelectedWrapper>
    )}
  </TableInfoWrapper>
);

export default TableInfo;

import React from 'react';

import styled from 'styled-components';

import SvgIcon from '../SvgIcon';

const ChkBox = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  height: 25px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & span {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 20px;
    background: ${(props) => props.theme.offWhite};
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;

    & svg {
      width: 100%;
      height: 100%;
      opacity: 0;
      fill: ${(props) => props.theme.white};
      transition: all 0.3s ease-in-out;
    }
  }

  & input:checked ~ span {
    background-color: ${(props) => props.theme.primary};

    & svg {
      opacity: 1;
    }
  }
`;
const CheckBox = ({ name, label, ...props }) => (
  <>
    {props.handleOnClick && (
      <ChkBox>
        {label}
        <input type="checkbox" id={name} name={name} onClick={props.handleOnClick} />
        <span>
          <SvgIcon type="check" />
        </span>
      </ChkBox>
    )}
    {!props.handleOnClick && (
      <ChkBox>
        {label}
        <input type="checkbox" id={name} name={name} />
        <span>
          <SvgIcon type="check" />
        </span>
      </ChkBox>
    )}
  </>
);
export default CheckBox;

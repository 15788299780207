import styled from 'styled-components';

export const LinkLess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & div {
    margin-top: 1rem;
    font-weight: 600;
  }
`;

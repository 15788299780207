import styled from 'styled-components';

export const TagsRowWrapper = styled.div`
  .tags-row {
    row-gap: 12px;
    column-gap: 6px;

    &__chip {
      background-color: ${(props) => props.theme.primaryLight};
      color: ${(props) => props.theme.white};
      font-size: 1.44rem;

      .MuiChip-deleteIcon {
        color: ${(props) => props.theme.white};

        &:hover {
          color: ${(props) => props.theme.secondary};
        }
      }
    }
  }
`;

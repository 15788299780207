import React from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import styled from 'styled-components';

import { IconType } from '../SvgIcon';

const StyledButton = styled.button<{ width: string; isDisabled: boolean; btntype: string }>`
  ${(props) => props.theme.selectButton(props.btntype, props.width, props.isDisabled)}
  padding: .5rem 1.5rem;
`;
interface ButtonProps {
  type: string;
  children?: React.ReactNode;
  width?: string;
  isDisabled?: boolean;
  clickCallback: () => any;
  disabledClickCallback?: () => any;
}

const Button = ({
  type,
  children,
  width = 'fit-content',
  isDisabled = false,
  clickCallback,
  disabledClickCallback,
}: ButtonProps) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    if (isDisabled) {
      if (disabledClickCallback) disabledClickCallback();
    } else {
      clickCallback();
    }
  };

  return (
    <StyledButton width={width} isDisabled={isDisabled} btntype={type} onClick={handleOnClick}>
      {children}
    </StyledButton>
  );
};

const IconWrapper = styled.span`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 0.75rem;
  color: ${(props) => props.theme.white};

  & svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;

const IconButton = styled.button<{ disabled: boolean; btntype: string }>`
  ${(props) => props.theme.selectButton(props.btntype, 'fit-content', props.disabled)}
  padding: .5rem 1.5rem;
  display: inline-flex !important;
  justify-content: space-between;
  align-items: center;
`;

interface IconButtonProps {
  btnType: string;
  iconType: IconType;
  isDisabled?: boolean;
  children: any;
  clickCallback: () => any;
}

export const ButtonWithIcon = ({
  btnType,
  iconType,
  clickCallback,
  isDisabled = false,
  children,
}: IconButtonProps) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    if (!isDisabled) {
      clickCallback();
    }
  };

  return (
    <IconButton btntype={btnType} onClick={handleOnClick} disabled={isDisabled}>
      {children}
      <IconWrapper>
        <SvgIcon type={iconType} />
      </IconWrapper>
    </IconButton>
  );
};

export default Button;

import { useSelector } from 'react-redux';
import { UserProfile } from 'types/userProfile';

const useProfile = (): UserProfile => {
  const profile = useSelector((state: any) => state.auth.user);

  return profile;
};

export default useProfile;

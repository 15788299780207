import { openDB, IDBPDatabase } from 'idb';
import { DbStore } from 'types/DbTypes';
import { initialPreferences, UserPreferenceKeys, UserPreferenceKey } from 'types/UserPreferences';

import { addRecord, deleteRecord, getRecord, updateRecord, getAllRecords } from './databaseHelpers';
import { USER_PREFERENCES, DbSchema, DbStores } from './storeDefinitions';

const DB_NAME = 'vericonDb';
const VERSION = 1;

const initialiseDatabase = async (): Promise<DbStores> => {
  let initFromDefault = false;

  const db: IDBPDatabase<DbSchema> = await openDB<DbSchema>(DB_NAME, VERSION, {
    upgrade(db) {
      initFromDefault = true;
      // @ts-ignore: Unreachable code error
      db.createObjectStore(USER_PREFERENCES);
    },
  });

  if (initFromDefault) {
    UserPreferenceKeys.forEach((p) => {
      // @ts-ignore: Unreachable code error
      db.add(USER_PREFERENCES, initialPreferences[p], p);
    });
  }

  const userPreferenceStore: DbStore<UserPreferenceKey, string | boolean> = {
    add: async (key, value) => await addRecord(db, 'userPreferences', key, value),
    delete: async (key) => await deleteRecord(db, 'userPreferences', key),
    get: async (key) => await getRecord(db, 'userPreferences', key),
    getAll: async () => await getAllRecords(db, 'userPreferences'),
    update: async (key, value) => await updateRecord(db, 'userPreferences', key, value),
  };

  return {
    userPreferenceStore,
  };
};

export default initialiseDatabase;

import React from 'react';

import Input from 'components/elements/Input';
import styled from 'styled-components';
import { getInputState } from 'utils/helpers';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
`;

const InputWrapper = styled.div`
  width: 30%;
  position: relative;
`;

const ErrorMsg = styled.span`
  position: absolute;
  display: inline-block;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.error};
  font-weight: 500;
  margin: 0 0 0.5rem 1rem;
  top: -2rem;
`;

const GroupInputs = ({ groupData, onInputChange, buildingInfo, isUpdate, validationState }) => {
  const getBuildingNames = (buildingInfo) =>
    buildingInfo.map((b) => ({ display: b.name, value: b.building }));

  return (
    <Wrapper>
      <InputWrapper>
        <ErrorMsg>{validationState['groupName'].err}</ErrorMsg>
        <Input
          type="text"
          inputData={{
            value: groupData.groupName,
            onChange: onInputChange,
            name: 'groupName',
            state: getInputState(validationState, 'groupName'),
            placeholder: 'Please Enter Group Name',
            readOnly: isUpdate,
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <ErrorMsg>{validationState['building'].err}</ErrorMsg>
        <Input
          type="dropdown"
          // label='Building'
          inputData={{
            value: groupData.building,
            onChange: onInputChange,
            name: 'building',
            options: getBuildingNames(buildingInfo),
            state: getInputState(validationState, 'building'),
            placeholder: 'Please Select Building',
            readOnly: isUpdate,
          }}
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          type="datetime"
          // label='3 Hour Test Date'
          inputData={{
            value: groupData.t180StartDate,
            onChange: onInputChange,
            name: 't180StartDate',
          }}
        />
      </InputWrapper>
    </Wrapper>
  );
};

export default GroupInputs;

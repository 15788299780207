import React, { useEffect, useState } from 'react';

import Input from 'components/elements/Input';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { handleApiReq } from 'utils/ApiRequestHelpers';

import Chart from './Chart';
import ChartProvider from './Chart/ChartContext';
import { Views, View } from './Configs/TelemetryConfigs';

const TimelineViewContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const ViewToolbar = styled.div`
  padding: 10px;
  margin: 5px;
`;

const InputWrapper = styled.div``;

const DeviceTimelineViews = () => {
  const { device } = useSelector((state: any) => state.device);

  const [telemetryViewConfig, setTelemeryViewConfig] = useState<Views>();
  const [selectedView, setSelectedView] = useState<View>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (telemetryViewConfig) {
      setSelectedView(
        telemetryViewConfig.views.find((view) => view.id === telemetryViewConfig.defaultChartId)
      );
    }
  }, [telemetryViewConfig]);

  useEffect(() => {
    if (selectedView) {
      setLoading(false);
    }
  }, [selectedView]);

  const getTelemetryViewConfig = async (deviceId) => {
    try {
      const telemetryConfig = await handleApiReq('GetDeviceTelemetryCharts', { deviceId }, 'POST');
      if (telemetryConfig) {
        setTelemeryViewConfig(telemetryConfig);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleViewSelection = (selectedViewId: any) => {
    if (telemetryViewConfig) {
      setSelectedView(telemetryViewConfig.views.find((view: View) => view.id === selectedViewId));
    }
  };

  useEffect(() => {
    const deviceId = device.deviceId;

    getTelemetryViewConfig(deviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TimelineViewContainer>
      {loading ? (
        <div>Loading Views...</div>
      ) : (
        <>
          {selectedView && (
            <>
              <ViewToolbar>
                {telemetryViewConfig && (
                  <>
                    <InputWrapper>
                      <Input
                        type="dropdown"
                        width="28rem"
                        inputData={{
                          value: selectedView.id,
                          name: 'selectedView',
                          options: telemetryViewConfig.views.map((view) => ({
                            value: view.id,
                            display: view.name,
                          })),
                          onChange: (selectedId) => handleViewSelection(selectedId),
                        }}
                      />
                    </InputWrapper>
                  </>
                )}
              </ViewToolbar>
              <ChartProvider>
                <Chart cards={selectedView.cards} id={selectedView.id} />
              </ChartProvider>
            </>
          )}
        </>
      )}
    </TimelineViewContainer>
  );
};

export default DeviceTimelineViews;

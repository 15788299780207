import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { ActionButtonWrapper } from './ActionButton.style';

type ActionButtonProps = {
  label: string;
  onClick: () => void;
  isLoading: boolean;
};

export const ActionButton: React.FC<ActionButtonProps> = ({ label, onClick, isLoading }) => (
  <ActionButtonWrapper>
    <Button
      onClick={onClick}
      fullWidth
      disabled={isLoading}
      variant="contained"
      startIcon={isLoading && <CircularProgress size={20} />}
    >
      {label}
    </Button>
  </ActionButtonWrapper>
);

export const APPROVE_URL = `${process.env.REACT_APP_APIURL}${'Residences/Access/Approve'}`;
export const REVOKE_URL = `${process.env.REACT_APP_APIURL}${'Residences/Access/Revoke'}`;

export const FILTERING_OPTION_ALL = 'All';
export const FILTERING_OPTION_APPROVED = 'Approved';
export const FILTERING_OPTION_WAITING_APPROVAL = 'Waiting for approval';
export const FILTERING_OPTIONS = [
  FILTERING_OPTION_ALL,
  FILTERING_OPTION_APPROVED,
  FILTERING_OPTION_WAITING_APPROVAL,
];

import { GridRowId, GridRowModel } from '@mui/x-data-grid-pro';
import { useState, useMemo } from 'react';

export const useDataGridPinnedRows = ({ rows }) => {
  const [pinnedRowsIds, setPinnedRowsIds] = useState<{
    top: GridRowId[];
  }>({
    top: [],
  });

  const pinned = useMemo(() => {
    const rowsData: GridRowModel[] = [];
    const pinnedRowsData: { top: GridRowModel[] } = {
      top: [],
    };

    rows?.forEach((row) => {
      if (pinnedRowsIds.top.includes(row.id)) {
        pinnedRowsData.top.push(row);
      } else {
        rowsData.push(row);
      }
    });

    return {
      rows: rowsData,
      pinnedRows: pinnedRowsData,
    };
  }, [pinnedRowsIds.top, rows]);

  return { ...pinned, pinnedRowsIds, setPinnedRowsIds };
};

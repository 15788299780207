import React from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';

import { StatusChip } from 'components/atoms';
import { DeviceAccessRightsTagWrapperApiV2 } from 'components/molecules';
import { ActionCellContent } from '../molecules';

export const propertyDevicesColumns = (refresh): GridColDef[] => [
  { field: 'name', headerName: 'Device', display: 'flex', flex: 1, minWidth: 100 },
  {
    field: 'status',
    headerName: 'Status',
    display: 'flex',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div className="devices-status-chips" style={{ maxWidth: '100%' }}>
        <StatusChip status={params.value} />
      </div>
    ),
  },
  {
    field: 'accessRightsTagId',
    headerName: 'Access Rights Tag',
    display: 'flex',
    flex: 1,
    minWidth: 260,
    renderCell: ({ row }) => (
      <div className="device-table__access-rights-tag">
        <DeviceAccessRightsTagWrapperApiV2
          limited
          deviceId={row.deviceId}
          accessRightsTag={row.accessRightsTagId}
          onSuccess={() => {
            refresh();
          }}
        />
      </div>
    ),
  },
  { field: 'serialNumber', headerName: 'Serial', display: 'flex', flex: 1, minWidth: 100 },
  { field: 'eui64', headerName: 'Eui64', display: 'flex', flex: 1, minWidth: 100 },
  { field: 'location', headerName: 'Location', display: 'flex', flex: 1, minWidth: 100 },
  {
    field: 'locationDetails',
    headerName: 'Additional Location Details',
    display: 'flex',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    display: 'flex',
    flex: 1,
    minWidth: 210,
    renderCell: ({ row }) => (
      <ActionCellContent
        deviceId={row.deviceId}
        serialNumber={row.serialNumber}
        location={row.location}
        locationDetails={row.locationDetails}
        bcmId={row.bcmId}
        powerClamps={row?.powerClamps}
        status={row.status}
      />
    ),
  },
];

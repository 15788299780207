import styled from 'styled-components';

const iconSize = '9rem';
const iconSizeSmall = '8rem';

export const ManagedServiceContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .widget-nav-icon {
    border-color: ${(props) => props.theme.purple} !important;
  }

  .managed-services {
    &__top {
      display: flex;
      justify-content: space-around;
      gap: 12px;
      margin: 0.9rem 0;

      &-icon {
        width: calc(50% - 6px);

        svg {
          color: ${(props) => props.theme.purple};
          fill: ${(props) => props.theme.purple};
          width: ${iconSizeSmall};
          height: ${iconSizeSmall};

          @media (min-width: 768px) {
            width: ${iconSize};
            height: ${iconSize};
          }
        }

        .device-icon__wrapper {
          width: ${iconSizeSmall};
          height: ${iconSizeSmall};

          @media (min-width: 768px) {
            width: ${iconSize};
            height: ${iconSize};
          }
        }

        .device-widget-icon__text {
          display: flex;
          align-items: center;
          gap: 3px;
          margin-top: 1rem;
          justify-content: center;

          &-count {
            font-size: 2rem;
            padding-right: 6px;
            color: ${(props) => props.theme.purple};
          }

          &-msg {
            font-size: 1.4rem;
          }
        }
      }
    }

    &__progress {
      padding-top: 16px;
    }

    &__bottom-link {
      margin: 0;

      .link {
        &-number {
          color: ${(props) => props.theme.purple};
          font-size: 3rem;
        }

        &-text {
          font-size: 1.1rem;
          font-weight: 600;
        }
      }
    }
  }
`;

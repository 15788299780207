import styled from 'styled-components';

const padding1Rem = '1rem';
const paperMargin = '12px';
const heightOfTitle = '4.3rem';
const VerticalOffset = '5px';

export const titleRightPadding = 50;
export const titleRightPaddingXL = 70;

export const ImageDialogWrapper = styled.div<{ showDownload: boolean }>`
  & .MuiPaper-root {
    max-width: unset;
    margin: ${paperMargin} !important;
    max-height: unset !important;
  }

  .dialog-header {
    display: flex;

    &__title {
      font-size: 1.44rem;
      height: ${heightOfTitle};
      position: relative;
      flex-grow: 1;
      pointer-events: auto;
      pointer-events: none;

      .ellipsis-with-tooltip {
        position: absolute;
        min-width: calc(100% - ${titleRightPadding}px);
        width: calc(100% - ${titleRightPadding}px);
        left: ${padding1Rem};
        top: ${padding1Rem};

        @media (min-width: 768px) {
          min-width: calc(100% - ${titleRightPaddingXL}px);
          width: calc(100% - ${titleRightPaddingXL}px);
        }
      }
    }

    &__button {
      padding: 3px;
    }
  }

  .image-dialog {
    &__bottom-padding {
      position: sticky;
      width: 100%;
      padding-bottom: ${padding1Rem};
    }

    &__content {
      padding: 0 ${padding1Rem};
    }

    &__header {
      &-button {
        padding-left: ${padding1Rem};
        padding-top: ${padding1Rem};

        .MuiButtonBase-root {
          padding: 6px;
        }
      }

      &--no-start-icon {
        .dialog-header__title {
          padding-left: 2rem;
        }
      }
    }
  }
`;

export const ImageDialogContentWrapper = styled.div`
  .image-dialog {
    &__image {
      display: block;
      max-width: 100%;
      max-height: calc(
        100vh - (${paperMargin} * 2) - ${heightOfTitle} - ${padding1Rem} - ${VerticalOffset}
      );
      border-radius: 4px;
    }

    &__progress {
      padding: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__error {
      padding: 3rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;
    }
  }
`;

import { useSelector } from 'react-redux';
import { UserAuth } from 'types/userProfile';

import { Areas } from '../../types/roles';

const useAccess = (area: Areas | null): UserAuth => {
  const profile = useSelector((state: any) => state.auth.user);

  return profile.getAccessLevels(area);
};

export default useAccess;

import IAction from '../IAction';
import { IToastState, IToast } from './IToastState';
import { toastTypes } from './ToastActions';

const initialState: IToastState = {
  alerts: [],
};

const toastReducer = (state: IToastState = initialState, action: IAction<IToast>): IToastState => {
  switch (action.type) {
    case toastTypes.ADD_TOAST:
      return {
        alerts: [...state.alerts, action.payload!],
      };
    case toastTypes.REMOVE_TOAST:
      return {
        alerts: state.alerts.filter((t) => t.id !== action.payload?.id),
      };
    default:
      return state;
  }
};

export default toastReducer;

import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { addDataGridPinColumn } from 'utils';
import { useDataGridPinnedRows, useGetFetchData, useSelectedTenant } from 'utils/hooks';
import { transManagedServiceDataToRows } from '../utils/transManagedServiceDataToRows';
import { columnsList } from '../constants/columns';

export const useTableData = () => {
  const tenant = useSelectedTenant();

  const {
    data = [],
    isLoading,
    isSuccess,
    refetch,
  } = useGetFetchData({
    url: process.env.REACT_APP_API_URL_V2 as string,
    query: `tenants/${tenant}/licences/propertySummary`,
    enableErrorHandler: true,
  });

  const transformedRows = transManagedServiceDataToRows({ licences: data?.results });

  const { pinnedRowsIds, setPinnedRowsIds, pinnedRows, rows } = useDataGridPinnedRows({
    rows: transformedRows,
  });

  const columns = useMemo(
    () => addDataGridPinColumn({ pinnedRowsIds, setPinnedRowsIds, columnsList }),
    [pinnedRowsIds, setPinnedRowsIds]
  );

  const navigate = useNavigate();

  const onRowClick = () => navigate(`/${tenant}/Properties`);

  return { rows, columns, isLoading, onRowClick, isSuccess, refetch, pinnedRows };
};

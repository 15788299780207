import { IDBPDatabase } from 'idb';

import { DbResponse } from '../types/DbTypes';
import { DbSchema, DbStoreNames } from './storeDefinitions';

export const addRecord = async <K extends string, T>(
  db: IDBPDatabase<DbSchema>,
  store: DbStoreNames,
  key: K,
  value: T
): Promise<DbResponse<T>> => {
  try {
    await (db as IDBPDatabase).add(store, value, key);

    return { isSuccessful: true };
  } catch (e) {
    return {
      isSuccessful: false,
      message: 'Failed to add record',
    };
  }
};

export const deleteRecord = async <K extends string>(
  db: IDBPDatabase<DbSchema>,
  store: DbStoreNames,
  key: K
): Promise<DbResponse> => {
  try {
    await (db as IDBPDatabase).delete(store, key);

    return { isSuccessful: true };
  } catch (e) {
    return {
      isSuccessful: false,
      message: 'Failed to delete record',
    };
  }
};

export const getRecord = async <K extends string, T>(
  db: IDBPDatabase<DbSchema>,
  store: DbStoreNames,
  key: K
): Promise<DbResponse<T>> => {
  try {
    const value = await (db as IDBPDatabase).get(store, key);

    return { isSuccessful: true, data: value };
  } catch (e) {
    return {
      isSuccessful: false,
      message: 'Failed to get record',
    };
  }
};

export const getAllRecords = async (
  db: IDBPDatabase<DbSchema>,
  store: DbStoreNames
): Promise<DbResponse> => {
  try {
    const keys = await (db as IDBPDatabase).getAllKeys(store);
    const values = await (db as IDBPDatabase).getAll(store);

    const data = keys.reduce(
      (acc, curr: any, i) => ({
        ...acc,
        [curr]: values[i],
      }),
      {}
    );

    return { isSuccessful: true, data: data };
  } catch (e) {
    return {
      isSuccessful: false,
      message: 'Failed to get record',
    };
  }
};

export const updateRecord = async <K extends string, T>(
  db: IDBPDatabase<DbSchema>,
  store: DbStoreNames,
  key: K,
  value: T
): Promise<DbResponse<T>> => {
  try {
    await (db as IDBPDatabase).put(store, value, key);

    return { isSuccessful: true, data: value };
  } catch (e) {
    return {
      isSuccessful: false,
      message: 'Failed to update record',
    };
  }
};

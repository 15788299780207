import { device } from './devices';

// default fonts
const defaultFont = 1.8;

// site colours
const steelGrey = '#3F4553',
  steelGreyLight = '#616674',
  notDarkGrey = '#999',
  grey = '#666666',
  lightGrey = '#D0D4DD',
  tealBlue = '#2BBEE6',
  darkestBlue = '#015069',
  notQuiteDarkestBlue = '#017094',
  darkBlue = '#0187B5',
  darkBlue60 = '#0187B599',
  darkBlue20 = '#0187B533',
  blue = '#28BEE6',
  lightBlue = '#86CAF9',
  red = '#E65D6D',
  orange = '#EDB760',
  orangeSaturated = '#e69d29',
  lightGreen = '#A5D271',
  darkGreen = '#8EC12A',
  offWhite = '#F5F5F5',
  offWhite2 = '#FCFCFC',
  offWhite3 = '#F7F7F7',
  white = '#ffffff',
  hover = '#ececf7',
  selected = '#e4ebf2',
  purple = '#3b0f62',
  purpleLight = '#9725fa';

export const colors = {
  edit: '#3F4553',
  editHover: '#616674',
  default: '#86CAF9',
  defaultHover: '#55b3f7',
  error: '#E65D6D',
  errorHover: '#e3474d',
  submit: '#A5D271',
  submitHover: '#8EC12A',
  clear: '#ddd',
  clearHover: '#ccc',
  disabledButton: 'rgba(0, 0, 0, 0.12)',
  disabledButtonText: 'rgba(0, 0, 0, 0.26)',
  blue,
  darkBlue,
  orange,
  orangeSaturated,
};

// transitions
const transition = 'all .3s ease-in-out';

const wrapper = {
  'background-color': white,
  'border-radius': '2px',
  'box-shadow': '2px 2px 5px rgba(0,0,0,.3)',
};

//inputs
const inputStyles = {
  'border-radius': '20px',
  padding: '.5rem 1rem',
  outline: 'none',
  background: offWhite,
  cursor: 'pointer',
  color: 'inherit',
  'font-size': 'inherit',
  'font-family': 'inherit',
  border: '1px solid #d8d8d8',
};

const defaultInputStyles = `
    border-radius: 20px;
    padding: .5rem 1rem;
    outline: none;
    background: ${offWhite};
    cursor: pointer;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    border: 1px solid #d8d8d8;
`;

const inputStyle = (width = '20rem') => `
        width: ${width};
        ${defaultInputStyles}        
    `;

const textAreaStyle = (width = '100%', height = '15rem') => `
        ${defaultInputStyles}
        width: ${width};
        height: ${height};
        padding: 1.5rem;
        resize: none;
    `;

//buttons
const buttonStyle = (
  textColor,
  backgroundColor,
  hoverColor,
  width = '15rem',
  isDisabled = false
) => {
  const enabledStyles = `
        background-color: ${hoverColor};
        border-color: ${hoverColor};
    `;

  const disabledStyles = `
        cursor: not-allowed
    `;

  return `
        width: ${width};
        padding: .5rem .75rem;
        text-align: center;
        border: 2px ${backgroundColor} solid;
        outline: none;
        border-radius: 20px;
        font-family: inherit;
        font-weight: 500;
        font-size: inherit;
        background-color: ${backgroundColor};
        color: ${textColor};
        box-shadow: 2px 2px 3px rgba(0,0,0,0.3);
        transition: all .3s ease-in-out;
        opacity: ${isDisabled ? '.5' : '1'}

        &:hover {
            ${isDisabled ? disabledStyles : enabledStyles}
        }
       
    `;
};

const toggleButtonStyle = (
  textColor,
  backgroundColor,
  hoverColor,
  width = '15rem',
  toggled = false
) => {
  const toggledOnStyles = `
        background-color: ${hoverColor};
        border: 1px solid grey;
    `;

  const toggledOffStyles = `
        border: 1px solid grey;
    `;

  return `
        width: ${width};
        padding: .5rem .75rem;
        text-align: center;
        border: 2px ${backgroundColor} solid;
        outline: none;
        border-radius: 20px;
        font-family: inherit;
        font-weight: 500;
        font-size: inherit;
        background-color: ${backgroundColor};
        color: ${textColor};
        box-shadow: 2px 2px 3px rgba(0,0,0,0.3);
        transition: all .3s ease-in-out;
        opacity: ${toggled ? '.5' : '1'}

        &:hover {
            ${toggled ? toggledOnStyles : toggledOffStyles}
        }
       
    `;
};

const borderButtonStyle = (
  textColor,
  borderColor,
  hoverColor,
  width = '15rem',
  isDisabled = false
) => {
  const enabledStyles = `
        border-color: ${hoverColor};
    `;

  const disabledStyles = `
        cursor: default
    `;

  return `
        width: ${width};
        padding: .5rem .75rem;
        text-align: center;
        outline: none;
        border-radius: 20px;
        font-family: inherit;
        font-weight: 500;
        font-size: inherit;
        background-color: transparent;
        border: 2px ${borderColor} solid;
        color: ${textColor};
        box-shadow: 2px 2px 3px rgba(0,0,0,0.3);
        transition: all .3s ease-in-out;
        opacity: ${isDisabled ? '.5' : '1'}

        &:hover {
            ${isDisabled ? disabledStyles : enabledStyles}
        }
`;
};

const tabs = `
    & li {
        font-size: ${defaultFont * 0.8}rem;
        display: inline-block;
        min-width: 7.5rem;
        text-align: center;
        padding: 1rem .5rem;
        // padding-bottom: .5rem;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        box-shadow: 6px 0px 5px -5px rgba(0,0,0,.3);
        z-index: 1;
        transform: translateY(0);
        background-color: #e9e9e9;
        transition: all .1s ease-in-out;

        &:hover {
            cursor: pointer;
            padding-bottom: 1rem;
            transform: translateY(-.25rem);
        }
        
        &.active {
            background-color: #ffffff;
            padding-bottom: 1.5rem;
            transform: translateY(-.5rem);
            margin-bottom: -0.5rem;
            z-index: 7;
        }
    }
`;

const defaultButton = (width = '15rem', isDisabled = false) =>
  buttonStyle(white, lightBlue, '#55b3f7', width, isDisabled);
const clearButton = (width = '15rem', isDisabled = false) =>
  buttonStyle(white, '#ddd', '#ccc', width, isDisabled);
const submitButton = (width = '15rem', isDisabled = false) =>
  buttonStyle(white, lightGreen, darkGreen, width, isDisabled);
const deleteButton = (width = '15rem', isDisabled = false) =>
  buttonStyle(white, red, '#e3474d', width, isDisabled);
const editButton = (width = '15rem', isDisabled = false) =>
  buttonStyle(white, steelGreyLight, steelGrey, width, isDisabled);
const borderClearButton = (width = '15rem', isDisabled = false) =>
  borderButtonStyle(grey, notDarkGrey, steelGrey, width, isDisabled);
const borderDefaultButton = (width = '15rem', isDisabled = false) =>
  borderButtonStyle('#707070', lightBlue, '#55b3f7', width, isDisabled);
const toggleButton = (width = '15rem', toggled = false) =>
  toggleButtonStyle(white, lightBlue, '#55b3f7', width, toggled);

const selectButton = (type, width = '15rem', isDisabled = false) => {
  switch (type) {
    case 'clear':
      return clearButton(width, isDisabled);
    case 'submit':
      return submitButton(width, isDisabled);
    case 'delete':
      return deleteButton(width, isDisabled);
    case 'edit':
      return defaultButton(width, isDisabled);
    case 'borderClear':
      return borderClearButton(width, isDisabled);
    case 'borderDefault':
      return borderDefaultButton(width, isDisabled);
    case 'toggle':
      return toggleButton(width, isDisabled);
    case 'default':
    default:
      return defaultButton(width, isDisabled);
  }
};

// tables
const tableStyle = `
    width: 100%;
    border-collapse: collapse;

    & th, td {
        padding: 1rem;
    }

    & thead tr {
        background-color: ${steelGrey};
        color: ${white};
        
        & th {
            font-weight: 400;
            text-align: left;
        }        
    }

    & tbody tr{
        transition: all .4s ease-in-out;
        cursor: pointer;
    }

    & tbody tr:nth-child(even) {
        background-color: ${offWhite3};
    }

    & tbody tr:hover{
        background-color: #ececf7;
    }
`;

const tableStaticHeader = (height, overflow = 'auto') => `
    width: 100%;
    border-collapse: collapse;
    display: block;
    empty-cells: show;  
    border-spacing: 0;

    & thead {
        position:relative;
        display: block;
        width:100%;
    }

    & tbody{
        display: block; 
        position:relative;
        width:100%; 
        max-height: ${height};
        overflow-y: ${overflow};
    }

    & tr{
        width: 100%;
        display:flex;
    }

    & th, td {
        padding: 1rem;
        flex-basis:20%;
        flex-grow:1;
    }

    & thead tr {
        background-color: ${steelGrey};
        color: ${white};
        
        & th {
            font-weight: 400;
            text-align: left;
        }        
    }

    & tbody tr{
        transition: all .4s ease-in-out;
    }

    & tbody tr:nth-child(even) {
        background-color: ${offWhite3};
    }

    & tbody tr:hover{
        background-color: #ececf7;
    }
`;

const statusColor = (status) => {
  switch (status) {
    case 'Passed':
      return darkGreen;
    case 'Assigned':
      return purple;
    case 'Expiring':
      return purpleLight;
    case 'Warning':
      return orange;
    case 'NotInstalled':
      return grey;
    case 'Failed':
      return red;
    case 'FailedDevice':
      return darkBlue;
    case 'Connected':
      return blue;
    case 'NotConnected':
      return darkestBlue;
    case 'Intermittent':
      return notQuiteDarkestBlue;
    case 'NeverConnected':
      return notDarkGrey;
    case 'Occupied':
      return darkBlue;
    case 'Occasional':
      return darkBlue60;
    case 'Holiday':
      return darkBlue20;
    case 'Empty':
      return lightGrey;
    default:
      return steelGreyLight;
  }
};

const resultColor = (result) => {
  switch (result) {
    case 'Passed':
      return darkGreen;
    case 'Degraded':
      return orange;
    case 'Indeterminate':
      return grey;
    case 'Failed':
      return red;
    default:
      return steelGreyLight;
  }
};

const toastColor = (type) => {
  switch (type) {
    case 'error':
      return red;
    case 'warning':
      return orange;
    case 'message':
      return darkBlue;
    case 'success':
      return darkGreen;
    default:
      return steelGreyLight;
  }
};

const groupStatusColor = (status) => {
  switch (status) {
    case 'inGroup':
    case 'addedToGroup':
      return darkGreen;
    case 'noGroup':
      return '#bbb';
    case 'anotherGroup':
      return grey;
    case 'removedFromGroup':
      return red;
    case 'conflictingGroup':
      return orange;
    default:
      return steelGreyLight;
  }
};

const groupStatusBackgroundColor = (status) => {
  switch (status) {
    case 'addedToGroup':
      return steelGreyLight;
    case 'removedFromGroup':
      return lightGrey;
    default:
      return 'transparent';
  }
};

export const styleVars = {
  // colours
  vericonRed: red,
  vericonBlue: darkBlue,
  vericonTeal: tealBlue,
  vericonOrange: orange,
  primary: steelGrey,
  primaryLight: steelGreyLight,
  secondary: lightGrey,
  error: red,
  warning: orange,
  success: lightGreen,
  default: lightBlue,
  backgroundColor: offWhite,
  headerColor: offWhite2,
  offWhite: offWhite3,
  white,
  darkBlue,
  blue,
  notQuiteDarkestBlue,
  grey,
  notDarkGrey,
  darkGreen,
  purple,
  online: darkGreen,
  failed: red,
  offline: grey,
  submitGreen: colors.submit,
  gradient: `linear-gradient(to right, ${darkGreen}, ${blue})`,
  hover,
  selected,
  // text styles
  spanBlue: darkBlue,

  textSize: `${defaultFont}rem`,
  textAlt: '1.6rem',
  textSmall: `${defaultFont * 0.8}rem`,
  textSmaller: `${defaultFont * 0.7}rem`,
  textSmallest: `${defaultFont * 0.6}rem`,
  textBig: `${defaultFont * 1.2}rem`,
  textBigger: `${defaultFont * 1.4}rem`,
  textBiggest: `${defaultFont * 2}rem`,
  textFont: '"Montserrat", sans-serif',
  //shadow
  shadow: '2px 2px 2px rgba(0,0,0,0.8)',
  //transitions
  transition,
  wrapper,
  inputStyles,
  inputStyle,
  textAreaStyle,
  buttonStyle,
  defaultButton,
  clearButton,
  submitButton,
  deleteButton,
  editButton,
  borderClearButton,
  borderDefaultButton,
  toggleButton,
  statusColor,
  tableStyle,
  tableStaticHeader,
  resultColor,
  groupStatusBackgroundColor,
  groupStatusColor,
  tabs,
  toastColor,
  selectButton,
  ...device,
};

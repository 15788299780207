export enum DataGridColumns {
  PropertyReference = 'propertyReference',
  Postcode = 'postcode',
  Address = 'address',
  // Hidden by default columns
  PropertyId = 'propertyId',
  TenantId = 'tenantId',
  Address1 = 'address1',
  Address2 = 'address2',
  Address3 = 'address3',
  Town = 'town',
  County = 'county',
  Latitude = 'latitude',
  Longitude = 'longitude',
  EpcRating = 'epcRating',
  VoidStatus = 'voidStatus',
}

export enum DataGridColumnsHidden {
  PropertyId = DataGridColumns.PropertyId,
  TenantId = DataGridColumns.TenantId,
  Address1 = DataGridColumns.Address1,
  Address2 = DataGridColumns.Address2,
  Address3 = DataGridColumns.Address3,
  Town = DataGridColumns.Town,
  County = DataGridColumns.County,
  Latitude = DataGridColumns.Latitude,
  Longitude = DataGridColumns.Longitude,
  EpcRating = DataGridColumns.EpcRating,
  VoidStatus = DataGridColumns.VoidStatus,
}

import { useDispatch } from 'react-redux';

import { updateUserDetails, updateUserTag, updateUserRole, User } from 'reducers/users';
import { useAppSelector } from 'utils/hooks';
import { combineDataStatuses } from 'reducers/utils';
import { ACCESS_RIGHTS_TAG_RESET } from '../constants/users';

export const useUpdateUser = (users: User[]) => {
  const dispatch = useDispatch();

  const userStatuses = useAppSelector((state) => state.users.statuses);
  const updateStatus = combineDataStatuses([
    userStatuses.updateUserDetails,
    userStatuses.updateUserTag,
    userStatuses.updateUserRole,
  ]);

  const handleUpdateUser = ({ data, id }: { id: string; data: Partial<User> }) => {
    const user = users.find((user) => user.id === id) as User;
    const isUserDetailsChanged = user?.name !== data.name || user?.email !== data.email;
    const isUserTagChanged =
      data.accessRightsTag !== '' && user?.accessRightsTag !== data.accessRightsTag;
    const isUserRoleChanged = user?.role !== data.role;

    if (isUserDetailsChanged) {
      dispatch(updateUserDetails({ data: { name: data.name }, id }));
    }

    if (isUserTagChanged) {
      dispatch(
        updateUserTag({
          data: {
            tags: [
              ...(data.accessRightsTag && data.accessRightsTag !== ACCESS_RIGHTS_TAG_RESET
                ? [
                  {
                    type: 'AccessRight',
                    value: data.accessRightsTag,
                  },
                ]
                : []),
            ],
          },
          id,
        })
      );
    }

    if (isUserRoleChanged) {
      dispatch(updateUserRole({ data: { role: data.role }, id }));
    }
  };

  return { handleUpdateUser, updateStatus };
};

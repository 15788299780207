import styled from 'styled-components';

export const UserDialogWrapper = styled.div`
  .user-dialog {
    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      min-width: 300px;
      padding-top: 10px !important;
    }

    &__error {
      font-size: 14px;
      text-align: center;
    }
  }
`;

import React, { useState, useRef } from 'react';

import Input from 'components/elements/Input';
import { useSelector, useDispatch } from 'react-redux';
import { updateViewDefinition, deleteViewDefinition } from 'reducers/viewDefinitions';
import styled from 'styled-components';
import useClickAway from 'utils/hooks/useClickAway';
import useNavigate from 'utils/hooks/useNavigate';

import DeleteModal from './DeleteModal';
import RenameModal from './RenameModal';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  z-index: 2;
`;

const Span = styled.span<{ isDisabled: boolean }>`
  display: inline-block;
  padding: 0.75rem 1rem;
  font-size: 1.4rem;
  opacity: ${(props) => (props.isDisabled ? 0.3 : 0.7)};
  transition: all 0.5s ease-in-out;
  background: transparent;
  color: inherit;

  ${(props) =>
    props.isDisabled
      ? ''
      : `&:hover {
        background: #ececf7;
        cursor: pointer;
        opacity: 1;
    }`};
`;

const Dropdown = styled.div`
  ${({ theme }) => theme.wrapper};
  width: 20rem;
  font-size: 1.4rem;
  position: absolute;
  right: 0;
  bottom: 0;

  transform: translateY(105%);
  transition: all 0.3s ease-in-out;

  & ul {
    & li {
      padding: 0.75rem 1rem;
      transition: all 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        background-color: #ececf7;
      }
    }
  }

  visibility: hidden;
  opacity: 0;

  &.open {
    opacity: 1;
    visibility: visible;
  }
`;

const AuthorsList = styled.div`
  ${({ theme }) => theme.wrapper};
  text-align: center;
  font-size: 1.4rem;
  width: max-content;

  max-height: 40rem;
  overflow-y: auto;

  position: absolute;

  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.open {
    opacity: 1;
    visibility: visible;
  }
  left: 70%;
`;

const ManageView = ({ view, isDisabled, route }: { view: any; isDisabled: any; route: any }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showAuthors, setShowAuthors] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showRename, setShowRename] = useState(false);

  const users = useSelector((state: any) => state.users.data);
  const dispatch = useDispatch();

  const wrapper = useRef<HTMLDivElement>(null);

  const { goTo } = useNavigate();

  const handleViewUpdate = async (value) => {
    await dispatch(
      updateViewDefinition({
        ...view,
        ...value,
      })
    );
  };

  const toggleOptions = () => {
    if (!isDisabled) {
      if (showOptions) setShowAuthors(false);
      setShowOptions(!showOptions);
    }
  };

  const handleCheck = (isChecked, userId) => {
    let authors = view.authors;
    if (isChecked) {
      authors = [...authors, userId];
    } else {
      authors = authors.filter((u) => u !== userId);
    }
    handleViewUpdate({ authors });
  };

  const handleRename = async (name) => {
    const newView = {
      ...view,
      label: name,
    };
    await dispatch(updateViewDefinition(newView));
  };

  const handleRenameClick = () => {
    setShowRename(true);
    setShowOptions(false);
  };

  const handleDeleteClick = () => {
    setShowDelete(true);
    setShowOptions(false);
  };

  const handleDeleteView = async () => {
    goTo(route);
    await dispatch(deleteViewDefinition(view.viewId));
  };

  const closeOptions = () => {
    if (showAuthors) setShowAuthors(false);
    setShowOptions(false);
  };

  useClickAway(wrapper, closeOptions);

  return (
    <Wrapper ref={wrapper}>
      <Span onClick={toggleOptions} isDisabled={isDisabled}>
        Manage
      </Span>
      <Dropdown className={showOptions ? 'open' : ''}>
        <ul>
          <li role="presentation" onClick={() => handleViewUpdate({ private: !view.private })}>
            Set view to {view.private ? 'public' : 'private'}
          </li>
          <li role="presentation" onClick={handleRenameClick}>
            Rename view
          </li>
          <li role="presentation" onClick={handleDeleteClick}>
            Delete View
          </li>
          <li role="presentation" onClick={() => setShowAuthors(!showAuthors)}>
            Manage view authors
          </li>
        </ul>
        <AuthorsList className={showAuthors ? 'open' : ''}>
          <ul>
            {users.map((u, i) => (
              <li key={i.toString()}>
                <Input
                  type="check"
                  inputData={{
                    label: u.name,
                    checked: view.authors.includes(u.id),
                    name: u.id,
                    onChange: handleCheck,
                  }}
                />
              </li>
            ))}
          </ul>
        </AuthorsList>
      </Dropdown>
      <DeleteModal
        showModal={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={handleDeleteView}
      />
      <RenameModal
        showModal={showRename}
        handleClose={() => setShowRename(false)}
        handleSave={handleRename}
        viewName={view.label}
      />
    </Wrapper>
  );
};

export default ManageView;

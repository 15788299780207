import React, { useEffect, useState } from 'react';

import Input, { inputTypes } from 'components/elements/Input';
import styled from 'styled-components';

const InputWrapper = styled.div<{ width: string }>`
  width: ${(props) => props.width}; ;
`;

interface DetailEditInputProps {
  value: string;
  width: string;
  type: inputTypes;
  handleValueChange: (v: string) => void;
  state: any;
  options: any;
}

const DetailEditInput = ({
  value,
  width,
  type,
  handleValueChange,
  state,
  options,
}: DetailEditInputProps) => {
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    //Set the initial parent edit value to the current value on first render
    handleValueChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputWrapper width={width}>
      <Input
        type={type}
        inputData={{
          value: inputValue,
          onChange: (value) => {
            setInputValue(value);
            handleValueChange(value);
          },
          state,
          ...options,
        }}
      />
    </InputWrapper>
  );
};

export default DetailEditInput;

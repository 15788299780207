import styled from 'styled-components';

const iconSize = `
  width: 24px !important;
  height: 24px !important;
`;

export const StyledTableRow = styled.tr`
  border: 0;

  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .bulk-action-row {
    &__cell {
      font-size: 10px;
      padding-top: 4px;
      padding-bottom: 4px;
      border: 0;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }

    &__controls {
      display: flex;
      width: 52px;
      min-height: 24px;
    }

    &__success {
      color: ${(props) => props.theme.success};
    }

    &__success,
    &__progress {
      ${iconSize}
    }

    &__error {
      display: flex;
      gap: 4px;

      &-icon {
        color: ${(props) => props.theme.error};
      }

      &-reset-icon {
        color: ${(props) => props.theme.vericonTeal};
      }

      &-icon,
      &-reset-icon {
        ${iconSize}
      }

      &-reset {
        padding: 0;
      }
    }
  }
`;

export type BuildDetailsProps = {
  buildNumber: string;
  commitId: string;
  branchName: string;
};

export const getBuildDetails = async (): Promise<BuildDetailsProps | undefined> => {
  try {
    const res = await fetch('build.json', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    });

    if (res.ok) {
      const resText = await res.text();
      const clearedText = resText.replace(/[^\w\s:",{}_-]/g, '');

      return JSON.parse(clearedText);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

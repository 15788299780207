import React from 'react';

import useSelectedTenant from 'utils/hooks/useSelectedTenant';
import { DeviceTypes } from 'types/DeviceTypes/Device';
import { Widget } from '../Widget/Widget';
import { useGetAdeyWidgetData } from '../../hooks/useGetAdeyWidgetData';

export const AdeyWidget: React.FC = () => {
  const tenant = useSelectedTenant();
  const { data, isLoading, isFetching, isError } = useGetAdeyWidgetData({
    tenant,
  });

  return (
    <Widget
      style={{ gridColumn: 'span 1', gridRow: 'span 6' }}
      title="ADEY Devices"
      deviceType={[DeviceTypes.Adey]}
      deviceSummary={data?.adeyDeviceSummary}
      isFetching={isLoading || isFetching}
      isFailedToLoad={isError}
      links={{
        main: `/${tenant}/ThirdParty/Listing/935ecfb8-294d-4c2d-aaac-f03f32fb9362`,
        toInventory: null,
        toInstalled: null,
        toFailed: null,
        toWarning: null,
      }}
    />
  );
};

import { DeviceTypes } from './DeviceTypes/Device';

export enum AccessLevel {
  None = 0,
  Read = 1,
  Edit = 2,
  Manage = 3,
  Admin = 4,
  Dev = 5,
}

export enum UserRole {
  LightingEngineer = 'LightingEngineer',
  BoilerEngineer = 'BoilerEngineer',
  Manager = 'Manager',
  Admin = 'Admin',
  Developer = 'Developer',
  Guest = 'Guest',
}

export enum Areas {
  misc = 'misc',
  lighting = 'lighting',
  boilers = 'boilers',
  fireAlarms = 'fireAlarms',
  liftMonitors = 'liftMonitors',
  peopleCounters = 'peopleCounters',
  networking = 'networking',
  users = 'users',
  reports = 'reports',
  all = 'all',
  thirdParty = 'thirdParty',
}

type Permissions = {
  misc: AccessLevel;
  lighting: AccessLevel;
  boilers: AccessLevel;
  fireAlarms: AccessLevel;
  liftMonitors: AccessLevel;
  peopleCounters: AccessLevel;
  networking: AccessLevel;
  users: AccessLevel;
  reports: AccessLevel;
  thirdParty: AccessLevel;
};

export interface IRole {
  name: string;
  devices: DeviceTypes[];
  permissions: Permissions;
}

export class LightingEngineer implements IRole {
  readonly name = 'LightingEngineer';
  readonly devices = [DeviceTypes.EmergencyLighting, DeviceTypes.EmergencyLightingLegacy];
  readonly permissions = {
    misc: AccessLevel.Read,
    lighting: AccessLevel.Edit,
    boilers: AccessLevel.None,
    fireAlarms: AccessLevel.None,
    liftMonitors: AccessLevel.None,
    peopleCounters: AccessLevel.None,
    networking: AccessLevel.Edit,
    users: AccessLevel.Read,
    reports: AccessLevel.Read,
    thirdParty: AccessLevel.Read,
  };
}

export class BoilerEngineer implements IRole {
  readonly name = 'BoilerEngineer';
  readonly devices = [DeviceTypes.Boiler, DeviceTypes.BcmConnect];
  readonly permissions = {
    misc: AccessLevel.Read,
    lighting: AccessLevel.None,
    boilers: AccessLevel.Edit,
    fireAlarms: AccessLevel.None,
    liftMonitors: AccessLevel.None,
    peopleCounters: AccessLevel.None,
    networking: AccessLevel.Edit,
    users: AccessLevel.Read,
    reports: AccessLevel.Read,
    thirdParty: AccessLevel.Edit,
  };
}

// export class LiftEngineer implements IRole {
//     readonly name = 'LiftEngineer';
//     readonly devices = [DeviceTypes.LiftMonitor];
//     readonly permissions = {
//         misc: AccessLevel.Read,
//         lighting: AccessLevel.None,
//         boilers: AccessLevel.None,
//         fireAlarms: AccessLevel.None,
//         liftMonitors: AccessLevel.Edit,
//         peopleCounters: AccessLevel.None,
//         networking: AccessLevel.None,
//         users: AccessLevel.None,
//         reports: AccessLevel.Read,
//     }
// };

// export class PeopleCounterEngineer implements IRole {
//     readonly name = 'PeopleCounterEngineer';
//     readonly devices = [DeviceTypes.PeopleCounter];
//     readonly permissions = {
//         misc: AccessLevel.Read,
//         lighting: AccessLevel.None,
//         boilers: AccessLevel.None,
//         fireAlarms: AccessLevel.None,
//         liftMonitors: AccessLevel.None,
//         peopleCounters: AccessLevel.Edit,
//         networking: AccessLevel.Edit,
//         users: AccessLevel.Read,
//         reports: AccessLevel.Read,
//     }
// };

export class Manager implements IRole {
  readonly name = 'Manager';
  readonly devices = [
    DeviceTypes.Boiler,
    DeviceTypes.BcmConnect,
    DeviceTypes.EmergencyLighting,
    DeviceTypes.EmergencyLightingLegacy,
    DeviceTypes.MqttGateway,
    DeviceTypes.ZigbeeExt,
    DeviceTypes.ZigbeeGateway,
  ];
  readonly permissions = {
    misc: AccessLevel.Manage,
    lighting: AccessLevel.Manage,
    boilers: AccessLevel.Manage,
    fireAlarms: AccessLevel.Manage,
    liftMonitors: AccessLevel.Manage,
    peopleCounters: AccessLevel.Manage,
    networking: AccessLevel.Manage,
    users: AccessLevel.Manage,
    reports: AccessLevel.Manage,
    thirdParty: AccessLevel.Manage,
  };
}

export class Guest implements IRole {
  readonly name = 'Guest';
  readonly devices = [
    DeviceTypes.Boiler,
    DeviceTypes.BcmConnect,
    DeviceTypes.EmergencyLighting,
    DeviceTypes.EmergencyLightingLegacy,
    DeviceTypes.MqttGateway,
    DeviceTypes.ZigbeeExt,
    DeviceTypes.ZigbeeGateway,
  ];
  readonly permissions = {
    misc: AccessLevel.Read,
    lighting: AccessLevel.Read,
    boilers: AccessLevel.Read,
    fireAlarms: AccessLevel.Read,
    liftMonitors: AccessLevel.Read,
    peopleCounters: AccessLevel.Read,
    networking: AccessLevel.Read,
    users: AccessLevel.Read,
    reports: AccessLevel.Read,
    thirdParty: AccessLevel.Read,
  };
}

export class Admin implements IRole {
  readonly name = 'Admin';
  readonly permissions = {
    misc: AccessLevel.Admin,
    lighting: AccessLevel.Admin,
    boilers: AccessLevel.Admin,
    fireAlarms: AccessLevel.Admin,
    liftMonitors: AccessLevel.Admin,
    peopleCounters: AccessLevel.Admin,
    networking: AccessLevel.Admin,
    users: AccessLevel.Admin,
    reports: AccessLevel.Admin,
    thirdParty: AccessLevel.Admin,
  };
  readonly devices = [
    DeviceTypes.Boiler,
    DeviceTypes.BcmConnect,
    DeviceTypes.EmergencyLighting,
    DeviceTypes.EmergencyLightingLegacy,
    DeviceTypes.MqttGateway,
    DeviceTypes.ZigbeeExt,
    DeviceTypes.ZigbeeGateway,
  ];
}

export class Dev implements IRole {
  readonly name = 'Developer';
  readonly permissions = {
    misc: AccessLevel.Dev,
    lighting: AccessLevel.Dev,
    boilers: AccessLevel.Dev,
    fireAlarms: AccessLevel.Dev,
    liftMonitors: AccessLevel.Dev,
    peopleCounters: AccessLevel.Dev,
    networking: AccessLevel.Dev,
    users: AccessLevel.Dev,
    reports: AccessLevel.Dev,
    all: AccessLevel.Dev,
    thirdParty: AccessLevel.Dev,
  };
  readonly devices = [
    DeviceTypes.Boiler,
    DeviceTypes.BcmConnect,
    DeviceTypes.EmergencyLighting,
    DeviceTypes.EmergencyLightingLegacy,
    DeviceTypes.MqttGateway,
    DeviceTypes.ZigbeeExt,
    DeviceTypes.ZigbeeGateway,
  ];
}

export const Roles: any = {
  [UserRole.LightingEngineer]: LightingEngineer,
  [UserRole.BoilerEngineer]: BoilerEngineer,
  [UserRole.Manager]: Manager,
  [UserRole.Admin]: Admin,
  [UserRole.Developer]: Dev,
  [UserRole.Guest]: Guest,
};

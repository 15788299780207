import { DeviceTypes } from 'types/DeviceTypes/Device';
import { parseSerialNumber } from 'utils';

export const getAvailableDevices = ({ devices }) => {
  const boiler = devices.filter((device) => {
    const product = parseSerialNumber(device.serialNumber).product;

    return product === DeviceTypes.Boiler || product === DeviceTypes.BcmConnect;
  });
  const multiDOTs = devices.filter(
    (device) => parseSerialNumber(device.serialNumber).product === DeviceTypes.MultiDot
  );
  const enviroDOTs = devices.filter(
    (device) => parseSerialNumber(device.serialNumber).product === DeviceTypes.EnviroDot
  );
  const powerMeters = devices.filter(
    (device) => parseSerialNumber(device.serialNumber).product === DeviceTypes.PowerMeter
  );
  const zigbeeAutofill = devices.filter(
    (device) => parseSerialNumber(device.serialNumber).product === DeviceTypes.ZigbeeAutofill
  );
  const legacyAutoFill = devices.filter(
    (device) => parseSerialNumber(device.serialNumber).product === DeviceTypes.Autofill
  );

  return {
    boiler,
    multiDOTs,
    enviroDOTs,
    powerMeters,
    zigbeeAutofill,
    legacyAutoFill,
  };
};

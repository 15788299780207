import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import { ChartNames, EventTimelineResponse, TelemetryView } from 'types/Telemetry';
import { Property } from 'types';
import { PropertyContextProps } from '../../types';

export const useTransformEventTimelineData = () => {
  const { propertyInfo, devicesInfo } = useOutletContext<PropertyContextProps>();
  const { propertyReference } = propertyInfo.data as Property;

  return useMemo(
    () =>
      ({ prev, data }: { prev: TelemetryView; data: EventTimelineResponse }) => {
        const { charts } = prev;
        const { results } = devicesInfo?.data || {};

        const updatedData = charts.map((chart) => {
          if (chart.type === ChartNames.EventTimeline) {
            const transformResults = data.results.map((chart) => ({
              ...chart,
              cardTitle:
                results?.find((device) => device.deviceId === chart.deviceId)?.serialNumber || '',
            }));

            return { ...chart, eventSeries: transformResults, chartLabel: propertyReference };
          }

          return chart;
        });

        return { ...prev, charts: updatedData };
      },
    [devicesInfo?.data, propertyReference]
  );
};

import React from 'react';

import Logs from './Logs';
import Notes from './Notes';
import RepairForms from './RepairForms';

const FormsTable = ({ deviceId, deviceType, type = 'repair' }) => (
  <>
    {(() => {
      switch (type) {
        case 'repair':
          return (
            <RepairForms deviceId={deviceId} deviceType={deviceType} title={'Maintenance Logs'} />
          );
        case 'notes':
          return <Notes deviceId={deviceId} title={'Notes'} />;
        case 'all':
          return <Logs deviceId={deviceId} deviceType={deviceType} title={''} />;
        default:
          return null;
      }
    })()}
  </>
);

export default FormsTable;

import React, { useState, useEffect } from 'react';

import Spinner from 'components/atoms/Spinner';
import SvgIcon from 'components/atoms/SvgIcon';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'reducers/loading/selectors';
import styled from 'styled-components';
import useAlert from 'utils/hooks/useAlert';
import useValidateInput from 'utils/hooks/useValidateInput';

import DetailEditInput from './DetailEditInput';

const Wrapper = styled.div`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  flex: 1;
`;

const StyledIcon = styled.span`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.25rem;

  &:hover {
    cursor: pointer;
  }

  & svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }
`;

const SpinnerWrapper = styled.span`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.25rem;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
`;

const EditIcon = styled(StyledIcon)`
  color: ${(props) => props.theme.darkBlue} !important;
`;

const SaveIcon = styled(StyledIcon)`
  color: ${(props) => props.theme.success} !important;
`;

const CancelIcon = styled(StyledIcon)`
  color: ${(props) => props.theme.grey} !important;
`;

const DetailsEditInput = ({
  value,
  type,
  options = {},
  action,
  actionType,
  unit = '',
  validators = [] as any[],
  width = '60%',
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const isLoading = useSelector((state: any) => selectIsLoading([actionType])(state));
  const shouldValidate = validators.length > 0;
  const { state, validateInput } = useValidateInput(validators);
  const { errorAlert } = useAlert();

  useEffect(() => {
    if (value !== editValue) {
      setEditValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleValueChange = (v) => {
    if (shouldValidate) {
      validateInput(v);
      setEditValue(v);
    } else {
      setEditValue(v);
    }
  };

  const handleSaveClick = async () => {
    let isValid = true;
    if (shouldValidate) {
      const err = validateInput(editValue);
      isValid = !err;
      if (err) errorAlert(err);
    }
    if (isValid) {
      await action(editValue);
      setIsEdit(false);
    }
  };

  const handleEditClick = () => setIsEdit(true);

  const handleCancelClick = () => {
    setIsEdit(false);
  };

  return (
    <Wrapper>
      {isEdit && (
        <DetailEditInput
          value={editValue}
          type={type}
          width={width}
          options={options}
          handleValueChange={handleValueChange}
          state={state}
        />
      )}

      {!isEdit && editValue + unit}

      <IconContainer>
        {isLoading && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}

        {!isLoading && isEdit && (
          <>
            <SaveIcon onClick={handleSaveClick}>
              <SvgIcon type="check" />
            </SaveIcon>

            <CancelIcon onClick={handleCancelClick}>
              <SvgIcon type="close" />
            </CancelIcon>
          </>
        )}

        {!isLoading && !isEdit && (
          <EditIcon onClick={handleEditClick}>
            <SvgIcon type="edit" />
          </EditIcon>
        )}
      </IconContainer>
    </Wrapper>
  );
};

export default DetailsEditInput;

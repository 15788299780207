import React from 'react';

import styled from 'styled-components';

import Pagination from './Pagination';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.2rem;
`;

const TableFooter = ({ pageInfo }) => {
  const { start, end, total, viewTotal, currentPage, updateCurrentPage } = pageInfo;

  return (
    <Wrapper>
      <div>
        Showing {start + 1} - {end < viewTotal ? end : viewTotal} of {total}
      </div>
      <div>
        <Pagination
          total={viewTotal}
          numPerPage={100}
          currentPage={currentPage}
          updateCurrentPage={updateCurrentPage}
        />
      </div>
    </Wrapper>
  );
};

export default TableFooter;

import React from 'react';
import { Typography } from '@mui/material';

import { StyledDataGrid } from 'styles/StyledDataGrid';
import { CustomToolbar } from 'components/molecules';
import { useTableData } from './hooks/useTableData';
import { ManagedServiceWrapper } from './ManagedService.styles';

export const ManagedService: React.FC = () => {
  const { rows, columns, isLoading, onRowClick, pinnedRows } = useTableData();

  return (
    <ManagedServiceWrapper>
      <div className="table-page__header">
        <div className="table-page__header-title">
          <Typography className="table-page__header-title-text">Managed Service</Typography>
        </div>
      </div>
      <div className="table-page__table">
        <StyledDataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          pinnedRows={pinnedRows}
          disableDensitySelector
          density="standard"
          columnHeaderHeight={50}
          disableRowSelectionOnClick
          onRowClick={onRowClick}
          pageSizeOptions={[25, 50, 100]}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          checkboxSelection
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </ManagedServiceWrapper>
  );
};

import styled from 'styled-components';

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(#f0f0f0 0%, #0187b5 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 2s ease-in-out infinite;
  opacity: 0.9;

  z-index: 4;
  mix-blend-mode: screen;

  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 0% -125%;
    }
  }
`;

export default LoadingOverlay;

import React from 'react';
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { EllipsisWithTooltip } from '../EllipsisWithTooltip/EllipsisWithTooltip';
import { DialogHeaderWrapper } from './DialogHeader.styles';

type DialogHeaderProps = {
  onClose: () => void;
  disabled?: boolean;
  title: string;
  withEllipses?: boolean;
  endElement?: React.ReactNode;
  startElement?: React.ReactNode;
  maxTitleWidth?: string;
};
export const DialogHeader: React.FC<DialogHeaderProps> = ({
  onClose,
  disabled,
  title,
  withEllipses,
  endElement = null,
  startElement = null,
  maxTitleWidth,
}) => (
  <DialogHeaderWrapper className="dialog-header">
    <IconButton
      aria-label="close"
      disabled={disabled}
      className="dialog-header__button"
      onClick={onClose}
      sx={{ position: 'absolute', top: 8, right: 8 }}
    >
      <CloseIcon fontSize="large" />
    </IconButton>
    {startElement}
    <DialogTitle className="dialog-header__title">
      {withEllipses ? (
        <EllipsisWithTooltip text={title} {...(!!maxTitleWidth && { maxWidth: maxTitleWidth })} />
      ) : (
        title
      )}
    </DialogTitle>
    {endElement}
  </DialogHeaderWrapper>
);

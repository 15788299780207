import { Route } from 'types';
import { routerGroups } from 'routes';

export const getTransformedRoutes = (routes: Route[]) =>
  routes.reduce((acc: Route[], route) => {
    if (route.routerGroup) {
      const parentIndex = acc.findIndex((r) => r.routerGroup === route.routerGroup);

      if (parentIndex === -1) {
        const parentRoute = routerGroups[route.routerGroup];

        if (parentRoute) {
          const { area, name, icon } = parentRoute;

          return [
            ...acc,
            {
              area,
              routerGroup: route.routerGroup,
              name,
              icon,
              subMenuItems: [route],
            } as Route,
          ];
        }
      } else {
        acc[parentIndex]?.subMenuItems?.push(route);

        return acc;
      }
    }

    return [...acc, route];
  }, []);

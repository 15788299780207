import React, { useEffect } from 'react';

import ListingView from 'components/ListingView';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectNetworkingViewFields } from 'reducers/fields';
import {
  ViewDefinition,
  selectNetworkingViewDefinitions,
  setStoredView,
  selectSelectedView,
  ViewContext,
  selectCurrentViewContext,
} from 'reducers/viewDefinitions';
import { Field } from 'types/Listing';
import useListingViewHelpers from 'utils/hooks/useListingViewHelpers';
import useNavigate from 'utils/hooks/useNavigate';
import useTenantInfo from 'utils/hooks/useTenantInfo';

const NetworkingDevices = () => {
  const dispatch = useDispatch();
  const viewDefinitions: ViewDefinition[] = useSelector((state: any) =>
    selectNetworkingViewDefinitions(state)
  );
  const fields: Field[] = useSelector((state: any) => selectNetworkingViewFields(state));
  const selectedView: ViewDefinition = useSelector((state: any) => selectSelectedView(state));
  const { goTo } = useNavigate();
  const currentViewContext: ViewContext = useSelector((state: any) =>
    selectCurrentViewContext(state)
  );
  const { viewId } = useParams<any>();
  const tenantInfo = useTenantInfo();

  const {
    setDefaultContext,
    setListingViewContext,
    refreshViewData,
    updateSelectedView,
    updateFilters,
    updateViewColumns,
  } = useListingViewHelpers();

  useEffect(() => {
    //This is messy because of the way the routing has been setup, there are two cases to handle.
    const defaultRenderConditionA =
      currentViewContext?.page !== 'NetworkingDevices' &&
      viewDefinitions &&
      viewDefinitions.length > 0;
    const defaultRenderConditionB =
      !viewId &&
      currentViewContext?.page === 'NetworkingDevices' &&
      viewDefinitions &&
      viewDefinitions.length > 0;

    if (defaultRenderConditionA || defaultRenderConditionB) {
      const defaultView = viewDefinitions.find(
        (viewDef) => viewDef.label === 'Networking Devices' && viewDef.builtIn
      );
      const defaultViewId = viewId ? viewId : defaultView?.viewId;
      if (defaultViewId && tenantInfo && tenantInfo.id !== '') {
        setDefaultContext({
          tenantId: tenantInfo.id,
          page: 'NetworkingDevices',
          viewId: defaultViewId,
        });
      }
    }

    return () => {
      dispatch(setStoredView());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedView && viewId && selectedView.viewId !== viewId) {
      setListingViewContext({
        tenantId: tenantInfo.id,
        page: 'NetworkingDevices',
        viewId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId]);

  const handleRowClick = (d) => {
    goTo('NetworkingDevices', '', d.deviceId);
  };

  return (
    <>
      {viewDefinitions.length > 0 && selectedView && (
        <ListingView
          availableViews={viewDefinitions}
          availableFields={fields}
          rowAction={handleRowClick}
          route="NetworkingDevices"
          refreshViewData={refreshViewData}
          updateSelectedView={updateSelectedView}
          updateFilters={updateFilters}
          updateViewColumns={updateViewColumns}
        />
      )}
      {viewDefinitions.length === 0 && (
        <>
          <h1>No views available</h1>
        </>
      )}
    </>
  );
};

export default NetworkingDevices;

import React from 'react';

import styled from 'styled-components';

const List = styled.ul`
  font-size: ${(props) => props.theme.textSmall};
  max-height: 100vh;
  overflow-y: auto;
`;

const Item = styled.li`
  padding: 1rem;
  transition: all 0.3s ease-in-out;

  &:nth-child(even) {
    background: ${(props) => props.theme.offWhite};
  }

  &:hover {
    background: #ececf7;
  }

  & span {
    color: ${(props) => props.theme.spanBlue};
    font-weight: 500;
  }

  & p {
    margin-bottom: 0.5rem;
  }
  & div pre code {
    font-size: xx-small;
  }
`;

const MessageList = ({ messages }) => (
  <List>
    {messages.map((msg, i) => (
      <Item key={i}>
        <p>
          <span>Time: </span> {msg.time}{' '}
        </p>
        <p>
          <span>Source: </span> {msg.source}{' '}
        </p>
        <p>
          <span>Content: </span> {msg.message}
        </p>
        <p>
          <span>Parsed: </span>
        </p>
        <div>
          <pre>
            <code>{msg.parsedMessage && JSON.stringify(msg.parsedMessage, null, 2)}</code>
          </pre>
        </div>
      </Item>
    ))}
  </List>
);

export default MessageList;

import React, { useState } from 'react';
import { Button } from '@mui/material';

import { ActionDialog } from 'components/molecules';
import { usePostFetchData } from 'utils/hooks/APIRequestsHandlers';
import { commonText, UNINSTALL_DEVICE_ENDPOINT } from 'components/constants';

type UninstallDeviceProps = {
  deviceId: string;
  serialNumber: string;
  refresh?: () => void;
};

export const UninstallDevice: React.FC<UninstallDeviceProps> = ({
  deviceId,
  serialNumber,
  refresh,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { mutate, isLoading, isSuccess } = usePostFetchData({
    url: UNINSTALL_DEVICE_ENDPOINT,
    onError: () => {
      handleClose();
    },
    enableErrorHandler: true,
  });

  const handleUninstall = () => {
    mutate({ deviceId });
  };

  const handleOpenDialog = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleOnSuccess = () => {
    refresh?.();
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        data-testid="uninstall-open"
        color="info"
        onClick={handleOpenDialog}
      >
        Uninstall
      </Button>
      <ActionDialog
        open={open}
        title={`Uninstall Device ${serialNumber}`}
        submitButtonName="Uninstall"
        sendingRequest={isLoading}
        handleSubmit={handleUninstall}
        onClose={handleClose}
        successMessageProps={{
          message: 'Uninstalled successfully!',
          showMessage: isSuccess,
          onClose: handleOnSuccess,
        }}
      >
        {commonText.uninstallDescription}
      </ActionDialog>
    </>
  );
};

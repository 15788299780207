import { getToken } from 'utils/contexts/AuthProvider';
import { UNINSTALL_DEVICE_ENDPOINT } from 'components/constants/urls';

interface UninstallDeviceResponse {
  error: boolean;
  message: string;
}

export const handleUninstallDeviceRequest = async (
  deviceId: string
): Promise<UninstallDeviceResponse> => {
  try {
    const body = { deviceId };

    const response = await fetch(UNINSTALL_DEVICE_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${await getToken()}`,
      },
      body: JSON.stringify(body),
    });

    const { message, isSuccessful } = await response.json();

    return { error: !isSuccessful, message };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);

    return {
      error: true,
      message: 'Something has gone wrong, please try again',
    };
  }
};

import React, { useRef, useEffect } from 'react';

import styled from 'styled-components';

import SortIcon from './SortIcon';

const Wrapper = styled.div<{ sortable: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: stretch;
  min-width: 5rem;

  & > div {
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    ${(props) => props.sortable && 'cursor: pointer'}
  }
`;

const Handle = styled.span`
  position: absolute;
  right: 0;
  display: inline-block;
  width: 6px;
  height: 100%;
  background: transparent;

  &:hover {
    cursor: ew-resize;
  }
`;

const TableHead = ({ children, sortable, handleSort, sort }) => {
  const headRef = useRef<HTMLTableHeaderCellElement>(null);

  let isDragging = false;
  let width, siblingWidth, initX, deltaX;

  const isAllowedWidth = (width) => width > 60 && width < 1200;

  const handleMouseDown = (e) => {
    if (headRef.current && headRef.current.nextElementSibling) {
      isDragging = true;
      initX = e.clientX;
      width = headRef.current.offsetWidth;
      const sibling = headRef.current.nextElementSibling as HTMLElement;
      siblingWidth = sibling.offsetWidth;
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && headRef.current && headRef.current.nextElementSibling) {
      e.preventDefault();

      deltaX = e.clientX - initX;
      const newWidth = width + deltaX;
      const newSiblingWidth = siblingWidth - deltaX;

      if (isAllowedWidth(newWidth) && isAllowedWidth(newSiblingWidth)) {
        headRef.current.style.width = `${newWidth}px`;
        const sibling = headRef.current.nextElementSibling as HTMLElement;
        sibling.style.width = `${newSiblingWidth}px`;
      }
    }
  };

  const handleMouseUp = () => {
    isDragging = false;
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    // TODO REFACTOR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, sortable, handleSort, sort]);

  return (
    <th ref={headRef}>
      <Wrapper sortable>
        <div onClick={handleSort} role="button" tabIndex={0} onKeyDown={handleSort}>
          {children}
          {sortable && <SortIcon sort={sort} />}
        </div>
        <Handle onMouseDown={handleMouseDown}>&nbsp;</Handle>
      </Wrapper>
    </th>
  );
};

export default TableHead;

import React, { useState, useEffect, useCallback } from 'react';

import Button from 'components/atoms/Button';
import SvgIcon from 'components/atoms/SvgIcon';
import Input from 'components/elements/Input';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import { containsExact } from 'utils/helpers';

const ColumnSelectionWrapper = styled.div<{ state: string }>`
  max-width: 120rem;
  width: 80vw;
  border-radius: 5px;
  padding: 2rem 3rem;
  background-color: ${(props) => props.theme.white};
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);

  max-height: 95vh;
  overflow-y: auto;
`;

const ColumnSelectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid #999;
  padding-bottom: 1rem;

  & > h3 {
    font-weight: 600;
  }

  & > div {
    font-size: 1.6rem;
    & > button {
      margin-left: 1rem;
    }
  }
`;

const ColumnSelectionBody = styled.div`
  margin: 4rem auto;
  padding: 0 3vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Column = styled.div`
  width: 12rem;
  background: ${(props) => props.theme.backgroundColor};
  flex: 1;
  height: 60vh;
  border-radius: 4px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
  padding: 2rem;

  display: flex;
  flex-direction: column;

  & h4 {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  & > p,
  & > div {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
`;

const ExchangeIcon = styled.span`
  display: inline-block;
  flex: 0.8;
  width: 6rem;
  height: 5rem;
  color: #999;

  & svg {
    height: 100%;
    width: 100%;
    fill: currentColor;
  }
`;

const Overlay = styled.section<{ state: string }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1300px;
  transition: 0.5s;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  z-index: 8;
`;

const List = styled.ul`
  font-size: 1.6rem;
  margin: 2rem 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  & li {
    padding: 2rem;
    background: ${(props) => props.theme.white};
    transition: all 0.3s ease-in-out;

    &:nth-of-type(even) {
      background: #ebebeb;
    }

    &:hover {
      background: #d7d7d7;
    }
  }
`;

const DragItem = ({ item, index }) => (
  <Draggable draggableId={item.id} index={index}>
    {(provided) => (
      <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
        {item.label}
      </li>
    )}
  </Draggable>
);

const DraggableList = ({ id, list }) => (
  <Droppable droppableId={id}>
    {(provided) => (
      <List ref={provided.innerRef} {...provided.droppableProps}>
        {list.map((c, i) => (
          <DragItem key={c.id} item={c} index={i} />
        ))}
        {provided.placeholder}
      </List>
    )}
  </Droppable>
);

const ColumnSelection = ({
  availableFields,
  selectedFields,
  showModal,
  handleClose,
  handleApply,
}) => {
  const getAvailableFields = useCallback(
    () =>
      availableFields.filter(
        (f) =>
          !containsExact(
            f.id,
            selectedFields.map((x) => x.id)
          )
      ),
    [availableFields, selectedFields]
  );

  const getAvailableColumns = useCallback(
    (selectedFields) =>
      availableFields.filter(
        (f) =>
          !containsExact(
            f.id,
            selectedFields.map((x) => x.id)
          )
      ),
    [availableFields]
  );

  const [columns, setColumns] = useState({
    availableColumns: getAvailableFields(),
    selectedColumns: [...selectedFields],
  });

  const [search, setSearch] = useState('');
  const handleOnSearchChange = (v) => setSearch(v);

  useEffect(() => {
    setColumns({
      availableColumns: getAvailableFields(),
      selectedColumns: [...selectedFields],
    });
  }, [selectedFields, availableFields, getAvailableFields]);

  useEffect(() => {
    setColumns((prev) => ({
      selectedColumns: prev.selectedColumns,
      availableColumns: getAvailableColumns(prev.selectedColumns).filter((f) =>
        f.label.toLowerCase().includes(search.toLowerCase())
      ),
    }));
  }, [getAvailableColumns, search]);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    const startId = source.droppableId;
    const finishId = destination.droppableId;

    if (startId === finishId) {
      const items = columns[startId];

      const item = items.splice(source.index, 1);
      items.splice(destination.index, 0, item[0]);

      setColumns({
        ...columns,
        [startId]: items,
      });
    } else {
      const startItems = columns[startId];
      const finishItems = columns[finishId];

      const item = startItems.splice(source.index, 1);
      finishItems.splice(destination.index, 0, item[0]);

      setColumns({
        ...columns,
        [startId]: startItems,
        [finishId]: finishItems,
      });
    }
  };

  const resetClick = () => {
    setSearch('');
    setColumns({
      availableColumns: getAvailableFields(),
      selectedColumns: [...selectedFields],
    });
  };

  const closeClick = () => {
    resetClick();
    handleClose();
  };

  const applyClick = () => {
    setSearch('');
    handleApply(columns.selectedColumns);
  };

  return (
    <Transition
      in={showModal}
      timeout={{ appear: 0, enter: 0, exit: 300 }}
      mountOnEnter
      unmountOnExit
    >
      {(state: any) => (
        <Overlay state={state}>
          <DragDropContext onDragEnd={onDragEnd}>
            <ColumnSelectionWrapper state={state}>
              <ColumnSelectionHeader>
                <h3>Edit Columns</h3>
                <div>
                  <Button width="10rem" type="submit" clickCallback={applyClick}>
                    {' '}
                    Apply
                  </Button>
                  <Button width="10rem" type="default" clickCallback={resetClick}>
                    Reset
                  </Button>
                  <Button width="10rem" type="clear" clickCallback={closeClick}>
                    Cancel
                  </Button>
                </div>
              </ColumnSelectionHeader>
              <ColumnSelectionBody>
                <Column>
                  <h4>Available Columns</h4>
                  <p>Drag and drop columns to select</p>
                  <Input
                    type="text"
                    inputData={{
                      value: search,
                      name: 'search',
                      placeholder: 'Filter Columns',
                      onChange: handleOnSearchChange,
                    }}
                  />
                  <DraggableList id="availableColumns" list={columns.availableColumns} />
                </Column>
                <ExchangeIcon>
                  <SvgIcon type="exchange" />
                </ExchangeIcon>
                <Column>
                  <h4>Selected Columns</h4>
                  <p>Drag to reorder columns</p>
                  <DraggableList id="selectedColumns" list={columns.selectedColumns} />
                </Column>
              </ColumnSelectionBody>
            </ColumnSelectionWrapper>
          </DragDropContext>
        </Overlay>
      )}
    </Transition>
  );
};

export default ColumnSelection;

import React, { ChangeEventHandler } from 'react';
import ReactMarkdown from 'react-markdown';

import { MarkDownWrapper } from './MarkDown.styles';

type MarkDownProps = {
  placeholder: string;
  markdownText: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
};

export const MarkDown: React.FC<MarkDownProps> = ({ markdownText, placeholder, onChange }) => (
  <MarkDownWrapper className="markdown">
    <textarea
      className="markdown__textarea"
      aria-label="markdown-textarea"
      rows={20}
      placeholder={placeholder}
      value={markdownText}
      onChange={onChange}
    />
    {markdownText && (
      <div className="markdown__preview">
        <h2 className="markdown__preview-title">Preview</h2>
        <div aria-label="markdown-preview" className="markdown__preview-container">
          <ReactMarkdown>{markdownText}</ReactMarkdown>
        </div>
      </div>
    )}
  </MarkDownWrapper>
);

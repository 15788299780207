interface Alert {
  tagTenant: string;
  tagId: string;
  tagName: string;
  alertId: string;
  definedByTenant: string;
  delay: number;
  delayUnit: 'mins' | 'hours' | 'days';
  delayDisplay: string;
  notificationType: NotificationTypeEnum;
  notificationTarget: string;
  triggerType: TriggerTypeEnum;
  triggerCondition: string;
}

export enum TriggerTypeEnum {
  None = 'NONE',
  DeviceStatus = 'DEVICESTATUS',
  ConnectionStatus = 'CONNECTIONSTATUS',
  AlertableEvent = 'ALERTABLEEVENT',
}

export const triggerDisplayOptions = [
  { value: TriggerTypeEnum.None, display: 'None' },
  { value: TriggerTypeEnum.DeviceStatus, display: 'Device Status' },
];

export const triggerDisplay = [
  ...triggerDisplayOptions,
  { value: TriggerTypeEnum.AlertableEvent, display: 'Alertable Event' },
];

export const triggerAllowedValues = {
  [TriggerTypeEnum.None]: [''],
  [TriggerTypeEnum.DeviceStatus]: [
    '',
    'FailedEquipment',
    'PreventativeMaintenance',
    'RepairedEquipment',
    'Ok',
  ],
};

export enum NotificationTypeEnum {
  None = 'NONE',
  Email = 'EMAIL',
  EmailUneditable = 'EMAILUNEDITABLE',
}

export const notificationDisplay = [
  { value: NotificationTypeEnum.None, display: 'None' },
  { value: NotificationTypeEnum.Email, display: 'Email' },
  { value: NotificationTypeEnum.EmailUneditable, display: 'Email' },
];

export const notificationEditDisplay = [
  { value: NotificationTypeEnum.None, display: 'None' },
  { value: NotificationTypeEnum.Email, display: 'Email' },
];

export const getTriggerConditionValues = (alert: Alert) =>
  alert ? triggerAllowedValues[alert.triggerType] : [''];

export default Alert;

import { useEffect } from 'react';

const useClickAway = (ref, callback) => {
  useEffect(() => {
    const handleClick = ({ target }) => {
      if (ref.current?.contains(target)) return;
      callback();
    };

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, [ref, callback]);
};

export default useClickAway;

import AddBuilding from 'scenes/AssetManagement/AddBuilding';
import BuildingDetails from 'scenes/AssetManagement/BuildingDetailsPage';
import PositionFloor from 'scenes/AssetManagement/PositionFloor';
import Buildings from 'scenes/Building';
import { PropertiesFeatures, PropertyCapabilities } from 'types';

import { Properties } from 'scenes/Properties';
import { ManagedService } from 'scenes/ManagedService';
import {
  Property,
  PropertyDevices,
  PropertyInfo,
  PropertyServices,
  PropertyTelemetry,
  PropertyRecords,
} from 'routes/Property';
import {
  AccessRightsTags,
  ResidentsSettings,
  StandardLuminaires,
  Tags,
  TenantSettings,
} from 'components/SettingsContainer';
import { Bcms } from 'scenes/Bcms/Bcms';
import Boilers from './scenes/Boilers';
import Dashboard from './scenes/Dashboard';
import BcmConnectedDeviceDetails from './scenes/DeviceDetailsPage/BcmConnectedDevicePage';
import BoilerDetails from './scenes/DeviceDetailsPage/BoilerDetailsPage';
import EmeredDetails from './scenes/DeviceDetailsPage/EmergencyLightsPage';
import NetworkDeviceDetails from './scenes/DeviceDetailsPage/NetworkDeviceDetailsPage';
import EmergencyLights from './scenes/EmergencyLights';
import ErrorPage from './scenes/ErrorPage';
import FloorPlan from './scenes/FloorPlan';
import NetworkingDevices from './scenes/NetworkingDevices';
import ThirdParty from './scenes/ThirdParty';
import Reporting from './scenes/Reporting';
import Settings from './scenes/Settings';
import AddTenant from './scenes/Settings/AddTenant';
import ListTenants from './scenes/Settings/ListTenants';
import Support from './scenes/Support';
import ELTestResults from './scenes/TestResults/EmergencyLights';
import ELTestSchedule from './scenes/TestSchedules/EmergencyLights';
import { Users } from './scenes/Users/Users';
import { Areas } from './types/roles';

export enum RouterGroupNames {
  Lighting = 'Lighting',
}

export const routerGroups = {
  [RouterGroupNames.Lighting]: {
    name: 'Lighting',
    icon: 'emergency-light',
    area: Areas.lighting,
  },
};

export default [
  {
    path: '/Dashboard',
    component: Dashboard,
    icon: 'home',
    name: 'Dashboard',
    area: Areas.misc,
  },
  {
    path: '/Properties',
    component: Properties,
    icon: 'property',
    name: 'Properties',
    area: Areas.misc,
    feature: PropertiesFeatures.PropertyCentric,
    capability: PropertyCapabilities.ViewProperties,
    routes: [
      {
        path: '/Properties/:propertyId',
        component: Property,
        nested: [
          {
            path: 'info',
            component: PropertyInfo,
          },
          {
            path: 'services',
            component: PropertyServices,
          },
          {
            path: 'devices',
            component: PropertyDevices,
          },
          {
            path: 'telemetry',
            component: PropertyTelemetry,
          },
          {
            path: 'propertyRecords',
            component: PropertyRecords,
          },
        ],
      },
    ],
  },
  {
    path: '/ManagedService',
    component: ManagedService,
    feature: PropertiesFeatures.ManagedService,
    icon: '',
    name: '',
    area: Areas.misc,
  },
  {
    path: '/EmergencyLights',
    component: EmergencyLights,
    icon: 'emergency-light',
    name: 'Emergency Lights',
    area: Areas.lighting,
    routerGroup: RouterGroupNames.Lighting,
    routes: [
      {
        path: '/EmergencyLights/Listing/:viewId',
        component: EmergencyLights,
      },
      {
        path: '/EmergencyLights/:deviceId',
        component: EmeredDetails,
      },
    ],
  },
  {
    path: '/NetworkingDevices',
    component: NetworkingDevices,
    icon: 'zigbee-gateway',
    name: 'Networking Devices',
    area: Areas.networking,
    routerGroup: RouterGroupNames.Lighting,
    routes: [
      {
        path: '/NetworkingDevices/Listing/:viewId',
        component: NetworkingDevices,
      },
      {
        path: '/NetworkingDevices/:deviceId',
        component: NetworkDeviceDetails,
      },
    ],
  },
  {
    path: '/Buildings',
    component: Buildings,
    limitedAccessRoute: true,
    icon: 'building',
    name: 'Buildings',
    area: Areas.misc,
    routerGroup: RouterGroupNames.Lighting,
    routes: [
      {
        path: '/Buildings/Add',
        component: AddBuilding,
      },
      {
        path: '/Buildings/:buildingName',
        component: BuildingDetails,
      },
    ],
  },
  {
    path: '/EmergencyLightSchedules',
    component: ELTestSchedule,
    limitedAccessRoute: true,
    icon: 'emergency-light-schedule',
    name: 'Test Schedules',
    area: Areas.lighting,
    routerGroup: RouterGroupNames.Lighting,
  },
  {
    path: '/EmergencyTestResults',
    component: ELTestResults,
    icon: 'emergency-light-results',
    name: 'Test Results',
    area: Areas.all,
    routerGroup: RouterGroupNames.Lighting,
  },
  {
    path: '/Boilers',
    component: Boilers,
    icon: 'boiler',
    name: 'Boilers',
    area: Areas.boilers,
    routes: [
      {
        path: '/Boilers/Listing/:viewId',
        component: Boilers,
      },
      {
        path: '/Boilers/:deviceId',
        component: BoilerDetails,
      },
      {
        path: '/BoilerConnectedDevice/:deviceId',
        component: BcmConnectedDeviceDetails,
      },
    ],
  },
  {
    path: '/Bcms',
    icon: '',
    name: '',
    component: Bcms,
    area: Areas.misc,
  },
  {
    path: '/ThirdParty',
    component: ThirdParty,
    icon: 'thirdparty',
    name: 'API Integration',
    area: Areas.thirdParty,
    routes: [
      {
        path: '/ThirdParty/Listing/:viewId',
        component: ThirdParty,
      },
    ],
  },
  {
    path: '/FloorPlan',
    component: FloorPlan,
    icon: '',
    name: '',
    area: Areas.misc,
    routes: [
      {
        path: '/PositionFloor',
        component: PositionFloor,
      },
    ],
  },
  {
    path: '/Reporting',
    component: Reporting,
    icon: 'reporting',
    name: 'Reporting',
    area: Areas.reports,
  },
  {
    path: '/Users',
    component: Users,
    icon: 'users',
    name: 'Users',
    area: Areas.users,
  },
  {
    path: '/Settings',
    component: Settings,
    icon: 'settings',
    name: 'Settings',
    area: Areas.misc,
    limitedAccessRoute: true,
    nested: [
      {
        path: '',
        component: TenantSettings,
      },
      {
        path: 'tags',
        component: Tags,
      },
      {
        path: 'accessRightsTags',
        component: AccessRightsTags,
      },
      {
        path: 'luminaire',
        component: StandardLuminaires,
      },
      {
        path: 'residents',
        component: ResidentsSettings,
      },
    ],
    routes: [
      {
        path: '/Settings/Tenants/Add',
        component: AddTenant,
      },
      {
        path: '/Settings/Tenants/:tenantId',
        component: AddTenant,
      },
      {
        path: '/Settings/Tenants',
        component: ListTenants,
      },
    ],
  },
  {
    path: '/Support',
    icon: 'support',
    name: 'Support',
    area: Areas.misc,
    component: Support,
  },
  {
    path: '/Error',
    icon: '',
    name: '',
    area: Areas.misc,
    component: ErrorPage,
  },
];

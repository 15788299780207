import React from 'react';
import { Chip } from '@mui/material';
import { DeviceStatus } from 'types/BoilerTypes/BcmConnectDetails';
import { getStatusChipAssets } from './utils/getStatusChipAssets';

type StatusChipProps = {
  status: DeviceStatus;
  className?: string;
};

export const StatusChip: React.FC<StatusChipProps> = ({ status, className }) => {
  const { color, icon } = getStatusChipAssets(status);

  return (
    <Chip
      {...(className && { className })}
      label={status}
      variant="outlined"
      color={color}
      icon={icon}
    />
  );
};

import { createSelector } from 'reselect';

export const selectBuildings = (state: any) => state.buildingInfo.buildings;

export const selectBuildingNamesOnly = createSelector(selectBuildings, (buildings) =>
  buildings.map((building) => building.name)
);

export const selectBuilding = (name) =>
  createSelector(selectBuildings, (buildings) => buildings?.find((b) => b.building === name));

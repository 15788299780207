import styled from 'styled-components';

export const DialogHeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;

  .dialog-header {
    &__title {
      font-size: 20px;
      color: ${(props) => props.theme.grey};
      padding-right: 96px;

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }
  }
`;

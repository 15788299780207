import React, { useRef } from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import styled from 'styled-components';

const Input = styled.input`
  ${(props) => props.theme.inputStyle('100%')};
`;

const InputWrapper = styled.div`
  display: inline-flex;
  position: relative;
  width: 7rem !important;
`;

const InputButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  right: 0;
  height: 100%;

  & span {
    background-color: ${(props) => props.theme.darkBlue};
    cursor: pointer;
    display: inline-block;
    padding: 0 1.2rem 0 0.8rem;
    font-size: 0;
    height: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: ${(props) => props.theme.notQuiteDarkestBlue};
    }

    & svg {
      width: 0.75rem;
      height: 1rem;
      fill: ${(props) => props.theme.white};
    }

    &:first-child {
      //border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom: 1px solid ${(props) => props.theme.notQuiteDarkestBlue};
    }

    &:last-child {
      //border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
`;

const NumberInput = ({ value, onChange, name, min, max }) => {
  const timeOut = useRef<ReturnType<typeof setTimeout>>();
  const timer = 600;

  const decrementValue = (value, timer) => {
    if (value > min) {
      onChange(value - 1, name);
      const newTimer = timer <= 100 ? timer : timer - 200;
      timeOut.current = setTimeout(() => decrementValue(value - 1, newTimer), timer);
    }
  };

  const incrementValue = (value, timer) => {
    if (value < max) {
      onChange(value + 1, name);
      const newTimer = timer <= 100 ? timer : timer - 200;
      timeOut.current = setTimeout(() => incrementValue(value + 1, newTimer), timer);
    }
  };

  const handleMouseDown = (value, fn) => fn(value, timer);

  const handleMouseUp = () => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
  };

  const handleMouseLeave = () => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
  };

  return (
    <InputWrapper>
      <Input
        value={value}
        min={min}
        max={max}
        name={name}
        onChange={(e) => {
          const chValue = e.target.value;
          if (chValue) {
            const isValidNumber = !isNaN(chValue as any);
            if (isValidNumber) {
              onChange(parseInt(e.target.value), name);
            }
          } else {
            onChange(0);
          }
        }}
      />
      <InputButtonWrapper>
        <span
          onMouseDown={() => handleMouseDown(value, incrementValue)}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onKeyDown={() => handleMouseDown(value, incrementValue)}
          role="button"
          tabIndex={0}
        >
          <SvgIcon type="plus" />
        </span>
        <span
          onMouseDown={() => handleMouseDown(value, decrementValue)}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onKeyDown={() => handleMouseDown(value, decrementValue)}
          role="button"
          tabIndex={0}
        >
          <SvgIcon type="minus" />
        </span>
      </InputButtonWrapper>
    </InputWrapper>
  );
};

export default NumberInput;

import React from 'react';

import Input from 'components/elements/Input';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const Error = styled.span`
  color: ${(props) => props.theme.failed};
  font-size: ${(props) => props.theme.textSmaller};
  display: inline-block;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  font-weight: 500;
`;

const ValidatedInput = ({ type, inputData, errMsg }) => (
  <Wrapper>
    <Input type={type} inputData={inputData} />
    {errMsg && <Error>{errMsg}</Error>}
  </Wrapper>
);

export default ValidatedInput;

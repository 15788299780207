import { useEffect } from 'react';

export const useSidebarOffsetTop = () => {
  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('mainHeader');
      const sidebarTopStub = document.getElementById('sidebarTopStub');

      const headerBottomOffset = header?.getBoundingClientRect().bottom;

      if (!!headerBottomOffset && headerBottomOffset >= 0 && sidebarTopStub) {
        sidebarTopStub.style.minHeight = `${headerBottomOffset}px`;
        sidebarTopStub.style.height = `${headerBottomOffset}px`;
      }

      if (!!headerBottomOffset && headerBottomOffset < 0 && sidebarTopStub) {
        sidebarTopStub.style.minHeight = '0px';
        sidebarTopStub.style.height = '0px';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
};

import React, { useEffect, useState } from 'react';

import LoadingOverlay from 'components/atoms/LoadingOverlay';
import { useSelector, useDispatch } from 'react-redux';
import { fetchForms } from 'reducers/forms';
import styled from 'styled-components';

import Table from './Table';

const LoadingWrapper = styled.div`
  width: 100%;
  filter: blur(2px);
`;

const LodingLogPlaceholder = styled.div`
  font-size: ${(props) => props.theme.textSmall};
  margin: 1rem;
  padding: 1rem;
  border-radius: 4px;
  background-color: ${(props) => props.theme.offWhite};
`;

const SubtextDiv = styled.span`
  display: block;
  font-size: 0.8em;
  text-align: right;
  color: #a9a9a9;
`;

const Logs = ({ deviceId, deviceType, title }) => {
  const dispatch = useDispatch();
  const forms = useSelector((state: any) => state.forms.forms);
  const [loading, setLoading] = useState(false);

  const fetchNewFormData = async () => {
    try {
      setLoading(true);
      await dispatch(fetchForms(deviceId, deviceType));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateFormData = async () => {
    try {
      await dispatch(fetchForms(deviceId, deviceType));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    if (forms) {
      if (forms.deviceId !== deviceId) {
        fetchNewFormData();
      } else {
        updateFormData();
      }
    } else {
      fetchNewFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  return (
    <>
      {loading ? (
        <LoadingWrapper>
          <LoadingOverlay />
          <LodingLogPlaceholder>
            <div>New logs loading...</div>
            <SubtextDiv>Log added by...</SubtextDiv>
          </LodingLogPlaceholder>
        </LoadingWrapper>
      ) : (
        <>
          {forms && forms.data.length > 0 ? (
            <Table forms={forms.data} title={title} />
          ) : (
            <h4>No logs to show</h4>
          )}
        </>
      )}
    </>
  );
};

export default Logs;

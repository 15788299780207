import styled from 'styled-components';

export const MarkDownWrapper = styled('div')`
  padding-top: 5px;

  .markdown {
    &__textarea {
      resize: none;
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      outline: none;
      border-color: #f3f3f3;
      background-color: #f7f7f7;

      &:focus {
        box-shadow: 0 0 4px 1px #55b3f7;
      }
    }

    &__preview {
      padding-top: 20px;

      &-title {
        font-size: 2rem;
        padding-bottom: 5px;
      }

      &-container {
        border: 1px solid #f3f3f3;
        padding: 10px;
        border-radius: 8px;

        h1 {
          font-size: 8vw;
          margin: 12px 0;

          @media (min-width: 768px) {
            font-size: 56px;
          }
        }

        h2 {
          font-size: 7vw;
          margin: 10px 0;

          @media (min-width: 768px) {
            font-size: 50px;
          }
        }

        h3 {
          font-size: 6.5;
          margin: 8px 0;

          @media (min-width: 768px) {
            font-size: 46px;
          }
        }

        h4 {
          font-size: 6;
          margin: 6px 0;

          @media (min-width: 768px) {
            font-size: 40px;
          }
        }

        h5 {
          font-size: 5.5;
          margin: 5px 0;

          @media (min-width: 768px) {
            font-size: 34px;
          }
        }

        h6 {
          font-size: 5;
          margin: 4px 0;

          @media (min-width: 768px) {
            font-size: 28px;
          }
        }

        ul {
          list-style: initial;
          padding: initial;
          padding-left: 25px;

          li {
            margin: 10px 0;
            font-size: 4vw;
            list-style-type: '➤ ';

            @media (min-width: 768px) {
              font-size: 1.5rem;
            }
          }
        }

        p {
          font-size: 4vw;
          margin: 10px 0;

          @media (min-width: 768px) {
            font-size: 1.5rem;
          }
        }

        a {
          text-decoration: underline;
          color: ${(props) => props.theme.blue};
        }
      }
    }
  }
`;

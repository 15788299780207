import React from 'react';

import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const LoginContainer = styled.div`
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
`;

const LoginWrapper = styled.div`
  width: 34rem;
  height: 42rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.5);
`;

const LoginTop = styled.div`
  background-image: url('images/loginBackground.jpg');
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
  background-size: cover;
  border-radius: 5px 5px 0 0;
  height: 65%;
  color: ${(props) => props.theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & h1,
  h2 {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  }
`;

const LoginBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  height: 35%;
`;
const StyledButton = styled.button`
  ${(props) => props.theme.defaultButton()}
`;

const Login = ({ login }) => {
  const location = useLocation();

  return (
    <>
      <LoginContainer>
        <LoginWrapper>
          <LoginTop>
            <h2>Welcome to</h2>
            <h1>VS:Connect Portal</h1>
          </LoginTop>
          <LoginBottom>
            <StyledButton onClick={() => login({ appState: { targetUrl: location.pathname } })}>
              Login
            </StyledButton>
          </LoginBottom>
        </LoginWrapper>
      </LoginContainer>
    </>
  );
};

export default Login;

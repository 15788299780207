type DownloadImageProps = {
  imageData: ArrayBuffer | undefined;
  fileName: string;
  imageType?: string;
};

export const downloadImage = ({
  imageData,
  fileName,
  imageType = 'image/jpeg',
}: DownloadImageProps): void => {
  if (imageData) {
    const blob = new Blob([imageData], { type: imageType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
};

import moment from 'moment';
import { DeviceLicence, ManagedServiceLicence, PropertyDevice } from 'types';

export const transformDeviceTableDataToRows = ({
  deviceLicences,
  devicesData,
}: {
  deviceLicences: DeviceLicence[];
  devicesData: PropertyDevice[];
}) => {
  const dataTransform = deviceLicences.map((licence: DeviceLicence) => {
    const currentDevice = devicesData?.find((device) => device.deviceId === licence.deviceId);

    return {
      ...licence,
      id: licence.deviceId,
      name: currentDevice?.name,
      serialNumber: currentDevice?.serialNumber,
      saasStartDate: licence.saasStartDate
        ? moment.utc(licence.saasStartDate).format('DD/MM/YYYY')
        : '',
      saasEndDate: licence.saasEndDate ? moment.utc(licence.saasEndDate).format('DD/MM/YYYY') : '',
      saasRenewedDate: licence.saasRenewedDate
        ? moment.utc(licence.saasRenewedDate).format('DD/MM/YYYY')
        : '',
    };
  });

  const gatewayDeviceLicence = dataTransform.filter((licence) => licence?.name?.[0] === 'B');
  const restDeviceLicences = dataTransform.filter((licence) => licence?.name?.[0] !== 'B');

  return [...gatewayDeviceLicence, ...restDeviceLicences];
};

export const transformServiceTableDataToRows = (licences: ManagedServiceLicence[]) =>
  licences.map((licence: ManagedServiceLicence) => ({
    ...licence,
    id: licence.licenceId,
    saasStartDate: licence.saasStartDate
      ? moment.utc(licence.saasStartDate).format('DD/MM/YYYY')
      : '',
    saasEndDate: licence.saasEndDate ? moment.utc(licence.saasEndDate).format('DD/MM/YYYY') : '',
    saasRenewedDate: licence.saasRenewedDate
      ? moment.utc(licence.saasRenewedDate).format('DD/MM/YYYY')
      : '',
  }));

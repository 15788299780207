import React from 'react';

import Sparkline from 'components/elements/Sparkline';
import DeleteIcon from 'components/Icons/DeleteIcon';
import DeviceStatusIcon from 'components/Icons/DeviceStatus';
import NavIcon from 'components/Icons/NavIcon';
import styled from 'styled-components';
import { mapDeviceTypesToDisplay } from 'utils/deviceUtils';
import { formatDate } from 'utils/helpers';

const TestButton = styled.span`
  display: inline-block;
  padding: 0.25rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;

  & svg {
    width: 100%;
    height: 100%;
    fill: #666;
  }
`;

const TableRow = ({ data, headers, rowFunctions }) => (
  <tr
    onClick={rowFunctions.handleRowClick ? (e) => rowFunctions.handleRowClick(e, data) : undefined}
  >
    {headers.map((h, i) => {
      switch (h.property) {
        case 'deviceType':
          return <td key={i}>{mapDeviceTypesToDisplay(data.deviceType)}</td>;
        case 'status':
          return (
            <td key={i}>
              <DeviceStatusIcon device={data} />
            </td>
          );
        case 'testData':
        case 'data':
          return (
            <td key={i}>
              <Sparkline
                handleOnClick={rowFunctions.handleSparkClick}
                info={rowFunctions.getSparkData(data)}
              />
            </td>
          );
        case 'edit':
          return (
            <td key={i}>
              <TestButton onClick={() => rowFunctions.handleEditClick(data)}>
                <svg>
                  <use href={'images/icons.svg#icon-edit'} />
                </svg>
              </TestButton>
            </td>
          );
        case 'delete':
          return (
            <td key={i}>
              <DeleteIcon handleClick={(e) => rowFunctions.handleDeleteClick(e, data)} />
            </td>
          );
        case 'date':
        case 'testDate':
        case 't180StartDate':
          return <td key={i}>{formatDate(data[h.property])}</td>;
        case '':
          return (
            <td key={i}>
              <NavIcon size={1.5} />
            </td>
          );
        default:
          return <td key={i}>{data[h.property]}</td>;
      }
    })}
  </tr>
);

export default TableRow;

import React, { useState } from 'react';

import NewBuilding from 'components/BuildingManagement/NewBuilding';
import styled from 'styled-components';
import { BuildingInfo } from 'types/BuildingInfo';

const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
  width: 70%;
  padding: 4rem 6rem;
  margin: 2rem auto;

  @media ${(props) => props.theme.laptop} {
    width: 80%;
  }

  @media ${(props) => props.theme.tabPort} {
    padding: 4rem;
    width: 90%;
  }

  @media ${(props) => props.theme.mobileL} {
    padding: 4rem 2rem;
    width: 95%;
  }
`;

const initialState: BuildingInfo = {
  name: '',
  building: '',
  lat: 0,
  lng: 0,
  floors: [
    {
      name: '0',
      lat: 0,
      lng: 0,
      width: 30,
      height: 30,
      rotation: 0,
      image: '',
    },
  ],
  defaultFloor: '0',
};

const AddBuilding = () => {
  const [building, setBuilding] = useState(initialState);

  return (
    <Wrapper>
      <NewBuilding building={building} setBuilding={setBuilding} />
    </Wrapper>
  );
};

export default AddBuilding;

import { DBSchema } from 'idb';
import { DbStore } from 'types/DbTypes';
import { UserPreferenceKey } from 'types/UserPreferences';

export const USER_PREFERENCES: 'userPreferences' = 'userPreferences';

export interface schema {
  userPreferences: {
    key: UserPreferenceKey;
    value: string | boolean;
  };
}

export interface DbSchema extends DBSchema, schema {}
export type DbStoreNames = keyof schema;

export type UserPreferenceStore = DbStore<UserPreferenceKey, string | boolean>;
export interface DbStores {
  userPreferenceStore: UserPreferenceStore;
}

import React from 'react';
import { Chip, Stack } from '@mui/material';

import { TagsRowWrapper } from './TagsRow.styles';

type TagsRowProps = {
  allowEdit?: boolean;
  tags: { id: string; protected?: boolean }[];
  editComponent?: React.ReactNode;
  onDelete?: ({ id, name }: { id: string; name: string }) => void;
  getTagName: (id: string) => string;
};

export const TagsRow: React.FC<TagsRowProps> = ({
  allowEdit,
  tags,
  editComponent,
  onDelete,
  getTagName,
}) => (
  <TagsRowWrapper>
    <Stack className="tags-row" flexWrap="wrap" direction="row">
      {tags.map((tag, tagIndex) => {
        const name = getTagName(tag.id);

        return (
          <Chip
            key={tagIndex.toString()}
            className="tags-row__chip"
            aria-label="tags-row-chip"
            label={name}
            {...(allowEdit && !tag.protected && !!onDelete
              ? {
                onDelete: () => onDelete({ name, id: tag.id }),
              }
              : {})}
          />
        );
      })}
      {allowEdit && editComponent}
    </Stack>
  </TagsRowWrapper>
);

import React, { useState, useEffect } from 'react';

import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';
import styled from 'styled-components';
import { mapStatus, mapConnectionStatus } from 'utils/deviceUtils';
import useDimensions from 'utils/hooks/useDimensions';
import useSelectedStatus from 'utils/hooks/useSelectedStatus';
import { initImgDims, initMap, calcMapSettings } from 'utils/mapUtils';

import MapStyles from './MapStyles';
import BuildingMarker from './Markers/BuildingMarker';
import DeviceMarker from './Markers/DeviceMarker';
import ImageOverlay from './Overlays/ImageOverlay';

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ClusterMarker = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 3px solid ${(props) => props.theme.statusColor(props.derivedStatus)};
  background-color: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.textColor};
  font-size: ${(props) => props.theme.textSmall};
  font-weight: 500;
  position: absolute;
  top: -1.5rem;
  left: -1.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const MapContainer = ({ handleMarkerClick, clusters, selectedBuilding, floor }) => {
  const selectedStatus = useSelectedStatus();
  const [mapRef, mapDimensions] = useDimensions();
  const [mapClusters, setMapClusters] = useState();
  const [drag] = useState(true);
  const [mapSettings, setMapSettings] = useState(
    initMap(selectedBuilding, clusters, mapDimensions)
  );
  const [imgDims, setImgDims] = useState(initImgDims(floor, mapSettings.zoom));
  const [isInit, setIsInit] = useState(true);

  const zoomCluster = (points, mapDims) => {
    setMapSettings(calcMapSettings(points, mapDims));
  };

  useEffect(() => {
    if (clusters) {
      const getClusters = supercluster(clusters, {
        minZoom: 3,
        maxZoom: 16,
        radius: 50,
      });

      if (mapSettings.bounds) {
        const c = getClusters(mapSettings);
        setMapClusters(c);
      }
    }
  }, [clusters, mapSettings]);

  useEffect(() => {
    setMapSettings(initMap(selectedBuilding, clusters, mapDimensions));
    // TODO REFACTOR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding, mapDimensions]);

  useEffect(() => {
    if (floor) {
      setImgDims(initImgDims(floor, mapSettings.zoom));
    }
  }, [floor, mapSettings.zoom]);

  const _onChange = ({ center, zoom, bounds }) => {
    if (isInit) {
      setMapSettings({
        center,
        zoom,
        bounds,
      });
      setIsInit(false);

      return;
    }
    if ((!selectedBuilding && clusters.length > 1) || !isInit) {
      setMapSettings({
        center,
        zoom,
        bounds,
      });
    }
  };

  const determineWorstStatus = (points) => {
    const deviceStates = {
      Passed: 0,
      NotInstalled: 1,
      Warning: 2,
      Failed: 3,
    };

    const connectionStates = {
      Connected: 0,
      Intermittent: 1,
      NotConnected: 2,
      NeverConnected: 3,
    };

    const states = selectedStatus === 'device' ? deviceStates : connectionStates;

    const x = points.reduce((acc, dev) => {
      const state =
        states[
          selectedStatus === 'device'
            ? mapStatus(dev.deviceStatus)
            : mapConnectionStatus(dev.connectionStatus)
        ];

      return state > acc ? state : acc;
    }, 0);

    return Object.keys(states).find((ds) => states[ds] === x);
  };

  return (
    <>
      {mapSettings && (
        <MapWrapper ref={mapRef}>
          {!isNaN(mapSettings.zoom) && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS }}
              draggable={drag}
              onChange={_onChange}
              center={mapSettings.center}
              zoom={mapSettings.zoom}
              hoverDistance={15}
              options={{
                mapTypeControl: true,
                fullscreenControl: false,
                mapTypeControlOptions: {
                  position: 7,
                  mapTypeIds: ['roadmap', 'satellite'],
                },
                rotateControl: false,
                tilt: 0,
                styles: MapStyles,
              }}
            >
              {!selectedBuilding &&
                mapClusters &&
                mapClusters.map((c) => {
                  if (c.numPoints === 1) {
                    return c.points[0].type === 'device' ? (
                      <DeviceMarker
                        key={c.points[0].deviceId}
                        device={c.points[0]}
                        lat={c.wy}
                        lng={c.wx}
                      />
                    ) : (
                      <BuildingMarker
                        key={c.points[0].name}
                        lat={c.points[0].lat}
                        lng={c.points[0].lng}
                        handleOnClick={() => handleMarkerClick(c.points[0].building)}
                        selected={false}
                        zoom={mapSettings.zoom}
                        buildingName={c.points[0].name}
                      />
                    );
                  }

                  return (
                    <ClusterMarker
                      key={c.wy}
                      lat={c.wy}
                      lng={c.wx}
                      onClick={() => zoomCluster(c.points, mapDimensions)}
                      derivedStatus={determineWorstStatus(c.points)}
                    >
                      {c.numPoints}
                    </ClusterMarker>
                  );
                })}

              {selectedBuilding &&
                clusters.map((c, i) =>
                  c.type === 'device' ? (
                    <DeviceMarker key={i} device={c} lat={c.lat} lng={c.lng} />
                  ) : null
                )}

              {selectedBuilding && floor?.hasFloorPlan && (
                <ImageOverlay
                  lat={floor.lat}
                  lng={floor.lng}
                  width={imgDims.w}
                  height={imgDims.h}
                  rotation={floor.rotation}
                  image={floor.image}
                />
              )}
            </GoogleMapReact>
          )}
        </MapWrapper>
      )}
    </>
  );
};

export default MapContainer;

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

const TableWrapper = styled.div`
  ${(props) => props.theme.wrapper};
  width: 90%;
  margin: 3rem auto;
  overflow-x: auto;

  @media ${(props) => props.theme.laptopL} {
    width: 95%;
  }

  @media ${(props) => props.theme.laptop} {
    width: 100%;
  }
`;

const Table = styled.table`
  ${(props) => props.theme.tableStyle};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  & span {
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
    transform: translateY(0);

    &:hover {
      background-color: #e9e9e9;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
      transform: translateY(-2px);
    }

    &:active {
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
      transform: translateY(-1px);
    }
  }

  & svg {
    width: 100%;
    height: 100%;

    &.edit {
      fill: ${(props) => props.theme.default};
    }

    &.delete {
      fill: ${(props) => props.theme.error};
    }
  }
`;

const IconContainer = ({ tenantId, editTenant }) => (
  <IconWrapper>
    <span
      onClick={() => editTenant(tenantId)}
      role="button"
      tabIndex={0}
      onKeyDown={() => editTenant(tenantId)}
    >
      <svg className="edit">
        <use href={'images/icons.svg#icon-edit'} />
      </svg>
    </span>
  </IconWrapper>
);

const TenantTable = ({ tenants }) => {
  const location = useLocation();

  const navigate = useNavigate();
  const handleEditClick = (tenantId) => {
    navigate(`${location.pathname}/${tenantId}`);
  };

  const handleRowClick = (e, tenantId) => {
    if (
      e.target.nodeName !== 'SPAN' &&
      e.target.nodeName !== 'svg' &&
      e.target.nodeName !== 'use'
    ) {
      navigate(`${location.pathname}/${tenantId}`);
    }
  };

  return (
    <TableWrapper>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Contacts</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tenants.map((t, i) => (
            <tr key={`${i}_${t.id}`} onClick={(e) => handleRowClick(e, t.id)}>
              <td>{t.id}</td>
              <td>{t.name}</td>
              <td>{t.contact}</td>
              <td>
                <IconContainer tenantId={t.id} editTenant={handleEditClick} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};
export default TenantTable;

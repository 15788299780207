import { ApiStatuses, CapabilitiesNames, FeaturesAreas } from 'types';
import { useAreaFeatures, useCapabilities, useIsUserLimited } from 'utils/hooks';

export const usePermissions = () => {
  const { isUserLimited } = useIsUserLimited();

  const propertyFeatures = useAreaFeatures(FeaturesAreas.Properties);
  const features = { ...propertyFeatures.features };

  const propertyCapabilities = useCapabilities(CapabilitiesNames.Properties);
  const capabilities = { ...propertyCapabilities.capabilities };

  const isLoading =
    propertyFeatures.status === ApiStatuses.Loading ||
    propertyCapabilities.status === ApiStatuses.Loading;

  return {
    isUserLimited,
    features,
    capabilities,
    isLoading,
  };
};

import React from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import TenantForm from '../../components/Tenants/TenantForm';

const AddUser = () => {
  const tenantsStore = useSelector((state: any) => state.tenants.tenants);
  const params = useParams();

  const tenant = tenantsStore.find((t) => t.id === params.tenantId);

  return <TenantForm tenant={tenant} />;
};

export default AddUser;

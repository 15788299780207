import { createSlice } from '@reduxjs/toolkit';

import { ApiStatuses } from 'types';
import { generateAsyncReducers } from '../utils';
import { fetchProductNames } from './actions';
import { ERROR_MESSAGES, initialState } from './constants';

const productNamesSlice = createSlice({
  name: 'productNames',
  initialState,
  extraReducers: (builder) => {
    generateAsyncReducers({
      builder,
      thunk: fetchProductNames,
      statusKey: 'fetchProductNames',
      errorMessages: ERROR_MESSAGES,
      onSuccess: ({ state, action }) => {
        state.data = action.payload.data?.results;
        state.statuses.fetchProductNames = { status: ApiStatuses.Success, error: null };
      },
    });
  },
  reducers: {},
});

export default productNamesSlice.reducer;

import styled from 'styled-components';

export const CardContainer = styled.div<{ gridWidth: number }>`
  padding: 5px;
  background-color: #ffffff;
  margin: 1rem;
  grid-column: span ${(props) => props.gridWidth};
  @media ${(props) => props.theme.laptop} {
    grid-column: span ${(props) => props.gridWidth <= 6 && props.gridWidth * 2};
  }
  @media ${(props) => props.theme.mobileL} {
    grid-column: span 12;
  }
  border-radius: 5px;
  border: 1px solid #e2e2e2;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  padding: 2.5rem 1rem;
  overflow-x: auto;
  height: 100%;
  width: 100%;
  filter: blur(5px);
`;

export const ErrorDataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.vericonRed};
`;

export const NoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.secondary};
`;

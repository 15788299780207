import { ApiStatuses } from 'types';

export const generateAsyncReducers = ({ builder, thunk, statusKey, onSuccess, errorMessages }) => {
  builder
    .addCase(thunk.pending, (state) => {
      state.statuses[statusKey] = { status: ApiStatuses.Loading, error: null };
    })
    .addCase(thunk.fulfilled, (state, action) => {
      onSuccess({ state, action });
    })
    .addCase(thunk.rejected, (state) => {
      state.statuses[statusKey] = { status: ApiStatuses.Failed, error: errorMessages[statusKey] };
    });
};

import React, { useCallback, useEffect, useState } from 'react';

import { ConnectedDevice } from 'types/BoilerTypes/BcmConnectDetails';
import { completeConnectedDeviceInstall } from 'utils';
import { UninstallDevice } from '../Dialogs';
import { Button, ButtonWrapper, Wrapper } from './ConnectedDeviceActions.styles';
import { DeviceActionIds, deviceActions } from './constants/deviceAction';

interface ConnectedDeviceActionsProps {
  device: ConnectedDevice;
  refreshView: () => void;
  bcmId: string;
}

export const ConnectedDeviceActions: React.FC<ConnectedDeviceActionsProps> = ({
  device,
  refreshView,
  bcmId,
}) => {
  const [action, setAction] = useState<DeviceActionIds>('none');

  const isDialogOpen = action !== 'none';
  const isUninstall = action === 'uninstall';
  const isCompleteInstall = action === 'completeInstall';

  const resetAction = useCallback((): void => {
    setAction('none');
    refreshView();
  }, [refreshView]);

  const selectedDevice = deviceActions.filter((deviceElement) =>
    deviceElement.shouldDisplay(device)
  );

  useEffect(() => {
    if (isCompleteInstall) {
      completeConnectedDeviceInstall({
        serialNumber: device.serialNumber,
        deviceId: device.deviceId,
        location: device.location,
        powerClamps: device.powerClamps,
        locationDetails: device.locationDetails,
        bcmId,
        resetAction,
      });
    }
  }, [bcmId, device, isCompleteInstall, resetAction]);

  return selectedDevice?.length ? (
    <>
      <Wrapper>
        {selectedDevice.map(({ id, label }, actionButtonIndex) => (
          <ButtonWrapper key={actionButtonIndex.toString()}>
            <Button
              onClick={() => {
                setAction(id);
              }}
            >
              {label}
            </Button>
          </ButtonWrapper>
        ))}
      </Wrapper>
      {isDialogOpen && (
        <>
          {isUninstall && (
            <UninstallDevice isOpen device={device} handleResetAction={resetAction} />
          )}
        </>
      )}
    </>
  ) : null;
};

import React from 'react';
import { Link } from 'react-router-dom';

import NavIcon from 'components/Icons/NavIcon';
import { TotalDiv } from './DeviceWidgetLink.styles';

interface DeviceWidgetLinkProps {
  to: string | null;
  count: number;
  label: string;
  className?: string;
}

export const DeviceWidgetLink: React.FC<DeviceWidgetLinkProps> = ({
  to,
  count,
  label,
  className,
}) =>
  count && to ? (
    <Link to={to}>
      <TotalDiv {...(!!className && { className })}>
        <span className="link-number">{count}</span>
        <p className="link-text">{label}</p>
        <div className="link-icon">
          <NavIcon size={0.9} thickness={2} />
        </div>
      </TotalDiv>
    </Link>
  ) : (
    <TotalDiv {...(!!className && { className })}>
      <span className="link-number">{count}</span>
      <p className="link-text">{label}</p>
    </TotalDiv>
  );

import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: right;
`;

const PageLink = styled.span`
  display: inline-block;
  padding: 0.25rem;
  margin: 0.25rem;
  font-size: ${(props) => props.theme.textSmall};
  color: ${(props) => props.theme.blue};
  opacity: 0.7;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-last-child(1),
  &:nth-last-child(2) {
    font-size: 1.7rem;
  }

  &.active {
    opacity: 1;
    color: ${(props) => props.theme.primary};
    font-weight: 500;
  }

  &:hover {
    background: ${(props) => props.theme.secondary};
  }
`;

const START = 'START';
const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const END = 'END';

const range = (start, end) => {
  let i = start;
  const range: any[] = [];
  while (i <= end) {
    range.push(i);
    i++;
  }

  return range;
};

const Pagination = ({ totalRecords, numPerPage, currentPage, setCurrentPage }) => {
  const pageNeighbours = 3;
  const totalPages = Math.ceil(totalRecords / numPerPage);

  const goToPage = (page) => {
    const currentPage = Math.max(1, Math.min(page, totalPages));
    setCurrentPage(currentPage);
  };

  const handleClick = (page) => {
    goToPage(page);
  };

  const handleMoveLeft = () => {
    goToPage(currentPage - 1);
  };

  const handleMoveRight = () => {
    goToPage(currentPage + 1);
  };

  const handleMoveStart = () => {
    goToPage(1);
  };

  const handleMoveEnd = () => {
    goToPage(totalPages);
  };

  const getPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 1;
    const totalBlocks = totalNumbers + 4;
    let pages;
    if (totalPages > totalBlocks) {
      const subNeighbours = currentPage - pageNeighbours - 1;
      const addNeighbours = currentPage + pageNeighbours;
      const startPage =
        Math.max(subNeighbours + 1, 1) -
        (addNeighbours > totalPages ? addNeighbours - totalPages : 0);
      const endPage = Math.min(totalPages, addNeighbours) - (subNeighbours < 0 ? subNeighbours : 0);
      pages = range(startPage, endPage);
    } else {
      pages = range(1, totalPages);
    }

    return [START, LEFT_PAGE, ...pages, RIGHT_PAGE, END];
  };

  const pages = getPageNumbers();

  return (
    <Wrapper>
      {pages.map((page, index) => {
        if (page === START)
          return (
            <PageLink key={index} onClick={handleMoveStart}>
              &laquo;
            </PageLink>
          );
        if (page === LEFT_PAGE)
          return (
            <PageLink key={index} onClick={handleMoveLeft}>
              &lsaquo;
            </PageLink>
          );
        if (page === RIGHT_PAGE)
          return (
            <PageLink key={index} onClick={handleMoveRight}>
              &rsaquo;
            </PageLink>
          );
        if (page === END)
          return (
            <PageLink key={index} onClick={handleMoveEnd}>
              &raquo;
            </PageLink>
          );

        return (
          <PageLink
            key={index}
            className={currentPage === page ? 'active' : ''}
            onClick={() => handleClick(page)}
          >
            {page}
          </PageLink>
        );
      })}
    </Wrapper>
  );
};

export default Pagination;

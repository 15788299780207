import styled from 'styled-components';

export const BulkUpdateAccessRightsTagsContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  .device-access-rights-tag-select {
    padding-bottom: 20px;

    &__input {
      width: 300px;

      .MuiSelect-select {
        font-size: 14px;

        @media (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
`;

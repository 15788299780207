import React from 'react';

import moment from 'moment';
import styled from 'styled-components';
import { formatDate } from 'utils/helpers';

const ItemWrapper = styled.div`
  font-size: ${(props) => props.theme.textSmall};
  margin: 1rem;
  padding: 1rem;
  border-radius: 4px;
  background-color: ${(props) => props.theme.offWhite};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #e6e6e6;
  }
`;

const SubtextDiv = styled.div`
  font-size: 0.8em !important;
  text-align: right;
  color: #a9a9a9;
  padding-top: 1rem;
`;

const Table = ({ forms, title }) => (
  <>
    <h5>{title}</h5>
    {forms &&
      forms.map((form, i) => {
        const { data } = form;
        const formUtcDateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\s*UTC/gm;
        form.description = form.description.replaceAll(formUtcDateRegex, (match) => {
          const momentDate = moment.utc(match, 'YYYY-MM-DDTHH:mm:ss');

          return momentDate.isValid() ? `${momentDate.local().format('ll LTS')}` : match;
        });

        const replaced = Object.keys(data).reduce((acc, cur) => {
          if (data[cur] === true) {
            return acc ? `${acc}, ${cur}` : cur;
          } else {
            return acc;
          }
        }, '');

        return (
          <ItemWrapper key={i}>
            {replaced && (
              <div>
                <span>Replaced:</span>
                {replaced}
              </div>
            )}

            {form.description && <div>{form.description}</div>}

            <SubtextDiv>
              {`${form.category} ${
                form.category.toLowerCase() !== 'note' ? 'record' : ''
              } added by ${form.user} at ${formatDate(form.timestamp)}`}
            </SubtextDiv>
          </ItemWrapper>
        );
      })}
  </>
);

export default Table;

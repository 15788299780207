import React, { Fragment, useMemo } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import { useMediaQuery } from '@mui/material';

import { Route } from 'types';
import { size } from 'styles/devices';
import { SidebarItem } from 'components/atoms';
import { SidebarSubMenu } from '../SidebarSubMenu/SidebarSubMenu';
import { useSidebarOffsetTop } from './hooks/useSidebarOffsetTop';
import { DesktopSidebar, MobileSidebar, SidebarWrapper } from './Sidebar.styles';
import { getTransformedRoutes } from './utils/getTransformedRoutes';

type SidebarProps = {
  open: boolean;
  toggleSidebar: () => void;
  routes: Route[];
  selectedTenant: string;
  handleChangeTenant: () => void;
  handleLogout: ({ returnTo }: { returnTo: string | undefined }) => void;
};

export const Sidebar: React.FC<SidebarProps> = ({
  open,
  toggleSidebar,
  routes,
  selectedTenant,
  handleChangeTenant,
  handleLogout,
}) => {
  const isTabletPortAndLandscape = useMediaQuery(`(max-width: ${size.tabletPort})`);
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isMobileL = useMediaQuery(`(max-width: ${size.mobileL})`);

  const isMobileMode = (isTabletPortAndLandscape && isLandscape) || isMobileL;

  useSidebarOffsetTop();

  const transformedRoutes = useMemo(() => getTransformedRoutes(routes), [routes]);

  const renderSidebarContent = () => (
    <List>
      {transformedRoutes.map((route, routeIndex) => (
        <Fragment key={routeIndex.toString()}>
          {route.subMenuItems?.length ? (
            <SidebarSubMenu open={open} route={route} selectedTenant={selectedTenant} />
          ) : (
            <SidebarItem open={open} route={route} selectedTenant={selectedTenant} />
          )}
        </Fragment>
      ))}
    </List>
  );

  if (isMobileMode) {
    return (
      <MobileSidebar
        className="sidebar"
        PaperProps={{
          className: 'sidebar__paper sidebar__paper--mobile',
        }}
        anchor="right"
        open={open}
        onClose={toggleSidebar}
      >
        <div className="sidebar__content">{renderSidebarContent()}</div>
        <div className="sidebar__management">
          <button className="sidebar__management-button" onClick={handleChangeTenant}>
            My Account
          </button>
          <button
            className="sidebar__management-button"
            onClick={() => handleLogout({ returnTo: process.env.REACT_APP_CALLBACKURL })}
          >
            Logout
          </button>
        </div>
      </MobileSidebar>
    );
  }

  return (
    <SidebarWrapper>
      <DesktopSidebar
        className="sidebar"
        PaperProps={{
          className: 'sidebar__paper',
        }}
        variant="permanent"
        open={open}
      >
        <div className="sidebar__header" id="sidebarTopStub" />
        <div className="sidebar__content">{renderSidebarContent()}</div>
        <ListItemButton
          className="sidebar__toggle-button"
          aria-label="toggle-sidebar-button"
          sx={{
            justifyContent: open ? 'flex-end' : 'initial',
          }}
          onClick={toggleSidebar}
        >
          <ListItemIcon
            sx={{
              justifyContent: open ? 'flex-end' : 'initial',
            }}
          >
            {open ? (
              <KeyboardTabIcon style={{ transform: 'rotate(180deg)' }} />
            ) : (
              <KeyboardTabIcon />
            )}
          </ListItemIcon>
        </ListItemButton>
      </DesktopSidebar>
    </SidebarWrapper>
  );
};

import styled from 'styled-components';

export const FieldErrorMessagesWrapper = styled.div`
  .field-error-messages {
    &__error {
      padding-left: 8px;
      font-size: 10px;

      @media (min-width: 768px) {
        padding-left: 12px;
        font-size: 12px;
      }
    }
  }
`;

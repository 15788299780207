import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import TenantAlerts from './TenantAlerts';

const Section = styled.section`
  margin-bottom: 2rem;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

const TenantSettings = () => {
  const dispatch = useDispatch();
  const { alerts } = useSelector((state: any) => state.tags);

  return (
    <Section>
      <Wrapper>{alerts && <TenantAlerts alerts={alerts} dispatch={dispatch} />}</Wrapper>
    </Section>
  );
};
export default TenantSettings;

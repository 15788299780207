export const NO_TAG_GROUP = 'noTag';
export const VERICON_SYSTEM_USER_EMAIL = '@vericonsystems.com';
export const VERICON_USER_GROUP_NAME = 'Vericon';

export const DELETE_USER_DIALOG_INITIAL_STATE = { open: false, id: '', name: '' };
export const EDIT_USER_DIALOG_INITIAL_STATE = { open: false, id: '' };
export const RESET_PASSWORD_DIALOG_INITIAL_STATE = { open: false, email: '', name: '' };

export const RESET_USER_PASSWORD_URL = `https://${process.env.REACT_APP_DOMAIN}/dbconnections/change_password`;

export const ACCESS_RIGHTS_TAG_RESET = 'reset';

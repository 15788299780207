import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { createAlert, deleteAlert, updateAlert } from 'reducers/tags/alertActions';
import { selectTags } from 'reducers/tags/selectors';
import styled from 'styled-components';
import { Areas } from 'types/roles';
import Alert, {
  TriggerTypeEnum,
  NotificationTypeEnum,
  triggerDisplay,
  notificationDisplay,
  notificationEditDisplay,
  getTriggerConditionValues,
  triggerDisplayOptions,
} from 'types/Tags/Alert';
import { getDelay } from 'utils/helpers';
import useAccess from 'utils/hooks/useAccess';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';
import { isRequired } from 'utils/inputValidation';

import Table from './Table';

const Section = styled.section`
  margin-bottom: 2rem;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

const timeIncrements = ['mins', 'hours', 'days'];

const initialAlert: Alert = {
  tagTenant: '',
  tagId: '',
  tagName: '',
  alertId: '',
  definedByTenant: '',
  delay: 1,
  delayUnit: 'hours',
  delayDisplay: '1 hours',
  notificationType: NotificationTypeEnum.None,
  notificationTarget: '',
  triggerType: TriggerTypeEnum.None,
  triggerCondition: '',
};

const TenantAlerts = ({ alerts, dispatch }) => {
  const { isManager } = useAccess(Areas.misc);
  const tenant = useSelectedTenant();

  const tags = useSelector((state: any) => selectTags(state));
  const [editData, setEditData] = useState<any>();
  const [alertsData, setAlertsData] = useState<Alert[]>([]);

  const getAlerts = useCallback(
    () => setAlertsData(alerts.filter((a) => a.tagTenant === tenant)),
    [alerts, tenant]
  );

  useEffect(() => {
    getAlerts();
  }, [getAlerts]);

  const findTag = (alert: Alert) => {
    const tenantTag = tags.find((t) => t.name === alert.tagName);

    return {
      tagId: tenantTag.id,
      tagTenant: tenant,
    };
  };

  const tagHeader = {
    display: 'Tag',
    property: 'tagName',
    type: 'dropdown',
    options: tags.map((t) => t.name),
  };
  const delayHeader = {
    display: 'Delay',
    property: 'delayDisplay',
    type: 'unit',
    options: timeIncrements,
  };
  const triggerHeader = {
    display: 'Trigger',
    property: 'triggerType',
    type: 'dropdown',
    options: triggerDisplayOptions,
    displayFn: (v) => triggerDisplay.find((t) => t.value === v)?.display,
  };
  const conditionHeader = {
    display: 'Condition',
    property: 'triggerCondition',
    type: 'dropdown',
    options: getTriggerConditionValues(editData),
  };
  const alertTypeHeader = {
    display: 'Alert Type',
    property: 'notificationType',
    type: 'dropdown',
    options: notificationDisplay,
    displayFn: (v) => notificationDisplay.find((t) => t.value === v)?.display,
  };
  const alertTypeEditHeader = {
    display: 'Alert Type',
    property: 'notificationType',
    type: 'dropdown',
    options: notificationEditDisplay,
    displayFn: (v) => notificationEditDisplay.find((t) => t.value === v)?.display,
  };
  const recipientHeaders = { display: 'Recipient', property: 'notificationTarget', type: 'text' };

  const commonHeaders = [tagHeader, delayHeader, triggerHeader, conditionHeader, recipientHeaders];

  const displayHeaders = [...commonHeaders, alertTypeHeader];

  const editHeaders = [...commonHeaders, alertTypeEditHeader];

  const validationSchema = {
    tagName: [{ fn: isRequired, msg: 'Tag is required' }],
    delayDisplay: [],
    triggerType: [],
    triggerCondition: [],
    notificationType: [],
    notificationTarget: [],
  };

  const handleAddAlert = () => setAlertsData([initialAlert, ...alertsData]);
  const handleReset = () => getAlerts();

  const handleUpdateAlert = async (alert, isAdd = false) => {
    const alertObj = {
      ...alert,
      ...findTag(alert),
      ...getDelay(alert),
    };

    if (isAdd) {
      await dispatch(createAlert(alertObj));
    } else {
      await dispatch(updateAlert(alertObj));
    }
  };

  const handleDeleteAlert = async (i) => await dispatch(deleteAlert(alertsData[i]));

  const showActionsInRow = (rowData): boolean => {
    const canEditValues = notificationEditDisplay.map(({ value }) => value);

    return canEditValues.includes(rowData.notificationType);
  };

  return (
    <>
      <Section>
        <Wrapper>
          <Table
            headers={displayHeaders}
            editHeaders={editHeaders}
            editData={editData}
            setEditData={setEditData}
            data={alertsData}
            canAdd={isManager}
            canEdit={isManager}
            canDelete={isManager}
            validationSchema={validationSchema}
            title="Alerts"
            actions={{
              handleAdd: handleAddAlert,
              handleEdit: handleUpdateAlert,
              handleDelete: handleDeleteAlert,
              handleReset: handleReset,
            }}
            styles={{ overflowY: 'auto' }}
            showActionsInRow={showActionsInRow}
          />
        </Wrapper>
      </Section>
    </>
  );
};

export default TenantAlerts;

import React from 'react';
import { Button, CircularProgress, Dialog, IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import DownloadIcon from '@mui/icons-material/Download';

import { downloadImage } from 'utils';
import { DialogHeader } from 'components/atoms';
import { ImageDialogContentWrapper, ImageDialogWrapper } from './ImageDialog.styles';

type ImageDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  imgSrc: string;
  imgAlt: string;
  onImageRefetch?: () => void;
  imageData?: ArrayBuffer | undefined;
  showDownload?: boolean;
};

export const ImageDialog: React.FC<ImageDialogProps> = ({
  open,
  onClose,
  title,
  isLoading,
  isSuccess,
  isError,
  imgSrc,
  imgAlt,
  onImageRefetch,
  imageData,
  showDownload,
}) => {
  const handleDownloadImage = () => downloadImage({ imageData, fileName: title });
  const showDownloadButton = !!imageData && showDownload && isSuccess;

  return (
    <Dialog
      disableScrollLock
      component={ImageDialogWrapper}
      open={open}
      onClose={onClose}
      PaperProps={{ style: { borderRadius: 8 } }}
    >
      <div {...(!showDownloadButton && { className: 'image-dialog__header--no-start-icon' })}>
        <DialogHeader
          withEllipses
          onClose={onClose}
          title={title}
          {...(showDownloadButton && {
            startElement: (
              <div className="image-dialog__header-button">
                <IconButton data-testid="download-button" onClick={handleDownloadImage}>
                  <DownloadIcon />
                </IconButton>
              </div>
            ),
          })}
        />
      </div>
      <div className="image-dialog__content">
        <ImageDialogContentWrapper>
          {isLoading && (
            <div className="image-dialog__progress">
              <CircularProgress data-testid="image-dialog-progress" />
            </div>
          )}
          {isSuccess && !!imgSrc && (
            <img
              data-testid="image-dialog-image"
              className="image-dialog__image"
              src={imgSrc}
              alt={imgAlt}
            />
          )}
          {isError && (
            <div className="image-dialog__error">
              <div className="image-dialog__error-text">Failed to load image</div>
              {!!onImageRefetch && (
                <Button
                  data-testid="image-dialog-refetch-button"
                  variant="contained"
                  onClick={onImageRefetch}
                  startIcon={<CachedIcon />}
                >
                  Try again
                </Button>
              )}
            </div>
          )}
        </ImageDialogContentWrapper>
      </div>
      <div className="image-dialog__bottom-padding" />
    </Dialog>
  );
};

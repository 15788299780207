import styled from 'styled-components';

export const EditAddressFormFieldWrapper = styled.div`
  padding-top: 10px;

  .edit-address-form-field {
    &__postcode {
      display: flex;
      gap: 10px;
    }

    &__address {
      padding-top: 20px;
    }

    &__api-errors {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-text {
        font-size: 12px;
      }
    }
  }
`;

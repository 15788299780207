import axios from 'axios';
import { getToken } from 'utils/contexts/AuthProvider';
import { ApiErrorsType } from 'types';
import { handleApiErrors } from './api';

export const fetchData = async ({
  url,
  errorAlert,
  enableErrorHandler,
}: {
  url: string;
  errorAlert?: (key: string) => void;
  enableErrorHandler?: boolean;
}) => {
  try {
    const token = await getToken();

    const response = await axios.get(url, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (e) {
    const error = e as ApiErrorsType;

    if (errorAlert && enableErrorHandler) {
      const onError = handleApiErrors({ errorToast: errorAlert });
      onError(error);
    }

    return null;
  }
};

import React from 'react';
import { Divider } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

import { TenantSettings } from 'types/TenantInfo/tenantInfo';
import { ResidentsSettingsWrapper } from './ResidentsSettings.styles';
import { ContactDetails, ResidenceTable } from './molecules';

export type ResidentsSettingsProps = {
  settings: TenantSettings;
  tenant: string;
};

export const ResidentsSettings: React.FC = () => {
  const { settings, tenant } = useOutletContext<ResidentsSettingsProps>();

  return (
    <ResidentsSettingsWrapper>
      <h2 className="residence__title">Resident app settings</h2>
      <Divider />
      <ContactDetails settings={settings} tenant={tenant} />
      <Divider />
      <ResidenceTable tenant={tenant} />
    </ResidentsSettingsWrapper>
  );
};

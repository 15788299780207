import { combineReducers } from 'redux';

import authReducer from './auth';
import { buildingInfoReducer } from './buildingInfo';
import { debugInfoReducer } from './debugInfo';
import { deviceReducer } from './device';
import { fieldsReducer } from './fields';
import { filterReducer } from './filters';
import { formsReducer } from './forms';
import loadingReducer from './loading';
import { mapDataReducer } from './mapData';
import { notesReducer } from './notes';
import { repairFormReducer } from './repairForms';
import { reportReducer } from './reports';
import { luminaireReducer } from './standardLuminaires';
import tagsReducer from './tags';
import { tenantInfoReducer } from './tenantInfo';
import { tenantsReducer } from './tenants';
import { testReducer } from './testResults';
import toastReducers from './toasts';
import usersSliceReducer from './users/userSlice';
import featuresSliceReducer from './features/featuresSlice';
import capabilitiesSliceReducer from './capabilities/capabilitiesSlice';
import productNamesSliceReducer from './productNames/productNamesSlice';
import { viewDataReducer } from './viewData';
import { viewDefReducer } from './viewDefinitions';
import { accessRightsTagsReducer } from './accessRightsTags';

export const rootReducer = combineReducers({
  loading: loadingReducer,
  auth: authReducer,
  buildingInfo: buildingInfoReducer,
  debugInfo: debugInfoReducer,
  device: deviceReducer,
  filters: filterReducer,
  forms: formsReducer,
  notes: notesReducer,
  repairForms: repairFormReducer,
  reports: reportReducer,
  tags: tagsReducer,
  tenants: tenantsReducer,
  tenantInfo: tenantInfoReducer,
  toasts: toastReducers,
  users: usersSliceReducer,
  features: featuresSliceReducer,
  capabilities: capabilitiesSliceReducer,
  productNames: productNamesSliceReducer,
  fields: fieldsReducer,
  viewDefinitions: viewDefReducer,
  viewData: viewDataReducer,
  mapData: mapDataReducer,
  tests: testReducer,
  standardLuminaires: luminaireReducer,
  accessRightsTags: accessRightsTagsReducer,
});

import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { displayValue, setValue } from 'utils/helpers';

const DropDownWrapper = styled.div<{ isDisabled: boolean }>`
  position: relative;
  display: inline-block;
  width: 100%;

  & > div {
    width: 100%;
    position: relative;
    display: inline-block;
    background-color: ${(props) => props.theme.offWhite};
    border-radius: 30px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      background: transparent;
      top: 32%;
      right: 1.5rem;
      width: 0.75rem;
      height: 0.75rem;
      border-right: 2px solid ${(props) => (props.isDisabled ? 'transparent' : props.theme.blue)};
      border-bottom: 2px solid ${(props) => (props.isDisabled ? 'transparent' : props.theme.blue)};
      transform: rotate(45deg);
      transform-origin: 50% 50%;
      z-index: 1;
    }
  }

  & input {
    ${(props) => props.theme.inputStyles};
    background-color: transparent;
    z-index: 6;
    width: 100%;

    ${(props) => {
    if (props.isDisabled)
      return `
          opacity: .6;
          cursor: not-allowed ;
      `;
  }}
  }

  & ul {
    display: none;
    position: absolute;
    left: 0;
    max-height: 20rem;
    overflow: auto;

    background: ${(props) => props.theme.white};
    width: 100%;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 6;

    li {
      padding: 0.5rem 1rem;
      &:hover,
      &.selected {
        cursor: pointer;
        background-color: ${(props) => props.theme.default};
      }
    }

    &.open {
      display: ${(props) => (props.isDisabled ? 'none' : 'block')};
    }
  }
`;

const focusInput = (e) => {
  if (e.target.nodeName === 'DIV') {
    e.target.querySelector('input').focus();
  }
};

const filterData = (values, input) => {
  if (input) {
    return values.filter((item) => {
      if (typeof item === 'string') {
        return item.toLowerCase().includes(input.toLowerCase());
      } else if (typeof item === 'number') {
        return item.toString().includes(input);
      } else {
        return item.display.toLowerCase().includes(input.toLowerCase());
      }
    });
  } else {
    return values;
  }
};

const Dropdown = ({
  selectedValue,
  values,
  onChange,
  name,
  readOnly = false,
  placeholder = '',
  hasOnChange,
  isDisabled,
}) => {
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([] as any);
  const [selectedIndex, setSelectedIndex] = useState(placeholder ? -1 : 0);

  useEffect(() => {
    setFilteredData(values);
    setSelectedIndex(values.findIndex((v) => v.value === selectedValue || v === selectedValue));
  }, [values, selectedValue]);

  const handleOnChange = (e) => {
    if (!isDisabled) {
      const userInput = e.currentTarget.value;
      if (userInput !== '') {
        setFilteredData(filterData(values, userInput));
      } else {
        setFilteredData(values);
      }
      setSelectedIndex(0);
      onChange(e.target);
    }
  };

  const handleOnClick = (e) => {
    if (!isDisabled) {
      const ele = e.target;
      setFilteredData(values);
      setSelectedIndex(values.findIndex((v) => v.value === ele.dataset.value));
      ele.parentNode.parentNode.querySelector('input').value = ele.innerText;
      ele.parentNode.parentNode.querySelector('input').dataset.value = ele.dataset.value;
      onChange(ele);
    }
  };

  const handleKeyPress = (e) => {
    if (!isDisabled) {
      switch (e.key) {
        case 'ArrowUp':
          if (selectedIndex !== -1) {
            setSelectedIndex(selectedIndex - 1);
          }
          break;
        case 'ArrowDown':
          if (selectedIndex < filteredData.length - 1) {
            setSelectedIndex(selectedIndex + 1);
          }
          break;
        case 'Escape':
          setOpen(false);
          break;
        case 'Enter':
          if (!open) {
            setOpen(true);
          } else {
            const ele = e.target;
            const li = ele.parentNode.parentNode.querySelector('li.selected');
            ele.value = li.innerText;
            ele.dataset.value = li.dataset.value;
            setOpen(false);
            onChange(li);
          }
          break;

        default:
          break;
      }
    }
  };

  return (
    <DropDownWrapper isDisabled={isDisabled}>
      <div
        onClick={(e) => focusInput(e)}
        onKeyDown={(e) => focusInput(e)}
        role="button"
        tabIndex={0}
      >
        <input
          autoComplete="off"
          readOnly={readOnly}
          name={name}
          onKeyDown={(e) => handleKeyPress(e)}
          onClick={() => setOpen(true)}
          onChange={handleOnChange}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          {...(!hasOnChange && { defaultValue: displayValue(selectedValue, values) })}
          {...(hasOnChange && { value: displayValue(selectedValue, values) })}
          data-value={setValue(selectedValue, values)}
          placeholder={placeholder}
        />
      </div>
      <ul className={open ? 'open' : ''}>
        {placeholder && (
          <li
            onMouseDown={handleOnClick}
            {...(selectedIndex === -1 && { className: 'selected' })}
            role="presentation"
            data-name={name}
            data-value=""
          >
            {placeholder}
          </li>
        )}
        {filteredData.map((v, i) =>
          typeof v === 'string' || typeof v === 'number' ? (
            <li
              onMouseDown={handleOnClick}
              {...(i === selectedIndex && { className: 'selected' })}
              role="presentation"
              key={i}
              data-name={name}
              data-value={v}
            >
              {v}
            </li>
          ) : (
            <li
              onMouseDown={handleOnClick}
              {...(i === selectedIndex && { className: 'selected' })}
              role="presentation"
              key={i}
              data-name={name}
              data-value={v.value}
            >
              {v.display}
            </li>
          )
        )}
      </ul>
    </DropDownWrapper>
  );
};
export default Dropdown;

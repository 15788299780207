import { styled } from '@mui/material/styles';
import { css } from 'styled-components';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import { styleVars } from './variables';

export const styledMuiXDataGridFiltersPanel = css`
  .MuiDataGrid-panel {
    .MuiPaper-root {
      max-width: 90vw;
      overflow-y: auto;
      max-height: 350px;

      .MuiDataGrid-panelWrapper {
        .MuiDataGrid-filterForm {
          .MuiDataGrid-filterFormDeleteIcon {
            justify-content: center;

            .MuiButtonBase-root {
              transform: translateY(8px);
            }
          }
        }
        .MuiFormControl-root {
          .MuiFormLabel-root {
            transform: translate(0px, -1.5px) !important;
            font-size: 1rem;

            &.Mui-focused {
              color: #1976d2;
            }
          }

          .MuiInputBase-root {
            border-radius: 0 !important;
            height: 36px;

            .MuiInputBase-input {
              padding-top: 6px;
              padding-bottom: 6px;
            }
          }
        }
      }
    }
  }
`;

export const StyledDataGrid = styled(DataGridPro)(() => ({
  '&': { backgroundColor: styleVars.white, minHeight: 400 },
  [`& .${gridClasses.virtualScroller}`]: {
    minHeight: 50,
    '& .MuiCircularProgress-root': {
      width: '32px !important',
      height: '32px !important',
    },
  },
  [`& .${gridClasses.toolbarContainer}`]: {
    paddingBottom: 6,
    paddingTop: 6,
  },
  '& .MuiCheckbox-input:focus': {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeaders}, & .MuiDataGrid-pinnedColumnHeaders, & .${gridClasses.columnHeaders} [role=row]`]:
    {
      fontSize: 14,
      backgroundColor: styleVars.primary,
      color: styleVars.white,

      '& .MuiDataGrid-columnHeader:focus, & div:focus, & div:focus-within': {
        outline: 'unset',
      },

      '& .MuiCheckbox-root, & .MuiDataGrid-columnHeaderDraggableContainer, & .MuiButtonBase-root': {
        color: styleVars.white,

        '&:focus': {
          outline: 'unset',
        },
      },

      '@media screen and (max-width: 768px)': {
        [`& .${gridClasses.columnHeader}`]: {
          fontSize: 12,
          '& .MuiDataGrid-menuIcon, & .MuiDataGrid-iconButtonContainer': {
            display: 'none',
          },
        },
      },
    },
  [`& .${gridClasses.columnHeader}.${gridClasses.columnHeader}--moving`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  [`& .${gridClasses.row}`]: {
    '& .MuiDataGrid-cell': {
      border: 0,
      fontSize: 14,
      overflow: 'hidden !important',

      '& .MuiChip-root': {
        '& .MuiChip-label': {
          fontSize: 14,

          '@media screen and (max-width: 768px)': {
            fontSize: 12,
          },
        },
      },

      '&:focus, &:focus-within': {
        outline: 'unset',
      },

      '@media screen and (max-width: 768px)': {
        fontSize: 12,
      },
    },
    '&.odd': {
      backgroundColor: styleVars.offWhite,
    },
    '&:hover': {
      backgroundColor: styleVars.hover,
    },
    '&.Mui-selected': {
      backgroundColor: styleVars.selected,
    },
    '&.Mui-selected:hover': {
      backgroundColor: styleVars.hover,
    },
  },

  [`& .${gridClasses.footerContainer}`]: {
    '& .MuiToolbar-root, & .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
      fontSize: 12,
    },
    '& .MuiTablePagination-actions': {
      '& .MuiButtonBase-root:not(:disabled)': {
        '& svg': {
          color: styleVars.blue,
        },
      },
    },
  },
}));

StyledDataGrid.defaultProps = {
  getRowClassName: (row) => (row.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'),
};

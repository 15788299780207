import React from 'react';

import styled from 'styled-components';
import { mapStatus, mapConnectionStatus } from 'utils/deviceUtils';
import useSelectedStatus from 'utils/hooks/useSelectedStatus';

import { DeviceStatusSpan } from '../../atoms/DeviceStatusSpan';

const DeviceStatusWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const DeviceStatusIcon = ({ device, selectedStatus = '' }) => {
  let status = useSelectedStatus();
  status = selectedStatus ? selectedStatus : status;

  return (
    <DeviceStatusWrapper>
      <DeviceStatusSpan
        status={
          status === 'device'
            ? mapStatus(device.deviceStatus)
            : mapConnectionStatus(device.connectionStatus)
        }
      />
      {status === 'device' ? device.deviceStatus : device.connectionStatus}
    </DeviceStatusWrapper>
  );
};

export default DeviceStatusIcon;

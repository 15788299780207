import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import BCMFault from 'types/BoilerTypes/BoilerFault';

import DeviceActionContainer from '../DeviceActionContainer';
import BoilerFault from './BoilerFault';
import BoilerForm from './BoilerForm';

const BoilerMaintenanceForm = ({ device, isResettable }) => {
  const faults = useSelector((state: any) => state.device.faults);

  const [boilerFaults, setBoilerFaults] = useState<BCMFault[]>([]);
  const [autofillFaults, setAutofillFaults] = useState<BCMFault[]>([]);

  useEffect(() => {
    if (faults && faults.length > 0) {
      const sepResults = faults.reduce(
        (acc, curr) => {
          switch (curr.faultSource) {
            case 'boiler':
              acc.boilerFaults.push(curr);
              break;
            case 'autofill':
              acc.autofillFaults.push(curr);
              break;
            default:
              break;
          }

          return acc;
        },
        {
          boilerFaults: [],
          autofillFaults: [],
        }
      );

      setAutofillFaults(sepResults.autofillFaults);
      setBoilerFaults(sepResults.boilerFaults);
    }
  }, [faults]);

  const isBcmInFault = boilerFaults.length && boilerFaults[0]?.faultCode !== 'None';
  const isAutofillInFault = autofillFaults.length && autofillFaults[0]?.faultCode !== 'None';
  const isInFault = faults && faults.length > 0 && (isBcmInFault || isAutofillInFault);

  //Get active fault
  //Autofill fault take precedence
  const activeFault = isAutofillInFault ? autofillFaults[0] : boilerFaults[0];

  return (
    <DeviceActionContainer
      title="Maintenance"
      fault={
        isInFault ? (
          <BoilerFault
            deviceId={device.deviceId}
            activeFault={activeFault}
            isResettable={isResettable}
          />
        ) : null
      }
      form={<BoilerForm device={device} />}
      device={device}
    />
  );
};

export default BoilerMaintenanceForm;

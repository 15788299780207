import React from 'react';

import DatePicker from 'react-date-picker';
import styled from 'styled-components';

const SDatePicker = styled(DatePicker)`
  width: 100%;
  text-align: center;

  & > div {
    width: 100%;
    background: ${({ theme }) => theme.white};
    padding: 1px 0;
  }

  & div {
    font-size: 1.2rem;
    color: #000;
    font-family: inherit;
    flex-grow: 0;
  }

  & input {
    font-size: 1.2rem;
    color: inherit;
    font-family: inherit;
  }
`;

const DPicker = ({ onChange, date, name }) => {
  const getDate = () => {
    if (!date) {
      return new Date();
    }
    if (date instanceof Date) {
      return date;
    }

    return new Date(date);
  };

  getDate().setMinutes(0);

  return (
    <SDatePicker
      onChange={(value) => onChange(value, name)}
      value={date}
      clearIcon={null}
      calendarIcon={null}
      name={name}
    />
  );
};

export default DPicker;

import React, { useState } from 'react';

import Button from 'components/atoms/Button';
import Input from 'components/elements/Input';
import Modal from 'components/elements/Modal';
import ValidatedInput from 'components/elements/ValidatedInput';
import { useSelector } from 'react-redux';
import { selectViewDefinitionNames } from 'reducers/viewDefinitions';
import styled from 'styled-components';
import { getInputState } from 'utils/helpers';
import useValidateForm from 'utils/hooks/useValidateForm';
import { isRequired, isUnique } from 'utils/inputValidation';

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const InputWrapper = styled.div`
  margin-bottom: 2.5rem;
  width: 80%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    margin: 0 1rem;
  }
`;
const initialState = {
  name: '',
  keepAuthors: false,
  makePublic: false,
};

const SaveAsModal = ({ showModal, handleClose, handleSave }) => {
  const viewNames = useSelector((state: any) => selectViewDefinitionNames(state));

  const [viewInfo, setViewInfo] = useState(initialState);
  const nameValidators = {
    name: [
      { fn: isRequired, msg: 'Please enter view name' },
      { fn: isUnique(viewNames), msg: 'View name already in use' },
    ],
  };

  const { validationState, validateForm, validateInput } = useValidateForm(
    { name: viewInfo.name },
    nameValidators
  );

  const handleNameChange = (v) => {
    setViewInfo((prev) => ({
      ...prev,
      name: v,
    }));
    validateInput(v, 'name');
  };

  const updateViewInfo = (value, name) =>
    setViewInfo((prev) => ({
      ...prev,
      [name]: value,
    }));

  const clearInfo = () => setViewInfo(initialState);

  const onCancel = () => {
    clearInfo();
    handleClose();
  };

  const onSave = async () => {
    if (validateForm()) {
      await handleSave(viewInfo);
      clearInfo();
      handleClose();
    }
  };

  return (
    <Modal title="Save View" showModal={showModal} handleClose={handleClose} width="40%">
      <Wrapper>
        <InputWrapper>
          <ValidatedInput
            type="text"
            inputData={{
              value: viewInfo.name,
              name: 'viewName',
              onChange: handleNameChange,
              state: getInputState(validationState, 'name'),
            }}
            label="View Name"
            errMsg={validationState['name'].err}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            type="check"
            inputData={{
              name: 'makePublic',
              label: 'Set view to public',
              checked: viewInfo.makePublic,
              onChange: updateViewInfo,
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            type="check"
            inputData={{
              name: 'keepAuthors',
              label: 'Keep authors',
              checked: viewInfo.keepAuthors,
              onChange: updateViewInfo,
            }}
          />
        </InputWrapper>
        <ButtonWrapper>
          <Button width="12rem" type="submit" clickCallback={onSave}>
            Save
          </Button>
          <Button width="12rem" type="clear" clickCallback={onCancel}>
            Cancel
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
};

export default SaveAsModal;

import styled from 'styled-components';

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.4em;
  font-weight: bold;
  font-size: 0.9em;
`;

export const CardTitle = styled.div`
  font-size: 1.2em;
  width: 50%;

  .line-chart-card__subtitle {
    font-size: 1rem;
  }
`;

export const GraphWrapper = styled.div`
  height: 150px;
`;

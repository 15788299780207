import React, { useState } from 'react';

import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import useDimensions from 'utils/hooks/useDimensions';
import { latLng2MetersPerDegree, calcMetersPerPx } from 'utils/mapUtils';

import MapStyles from '../MapStyles';
import DraggableBuildingMarker from '../Markers/DraggableBuildingMarker';

const MapWrapper = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

const SaveButton = styled.button`
  ${(props) => props.theme.submitButton('15rem')};
  margin: 1rem;
`;

const CancelButton = styled.button`
  ${(props) => props.theme.clearButton('15rem')};
  margin: 1rem;
`;

const DevicePositioningMap = ({ building, onSave, onCancel }) => {
  const [mapRef] = useDimensions();
  const [drag, setDrag] = useState(true);
  const [mapSettings, setMapSettings] = useState({
    center: {
      lat: building.lat,
      lng: building.lng,
    },
    zoom: 19,
  });
  const [buildingPos, setBuildingPos] = useState({ lat: building.lat, lng: building.lng });

  const calcLatLongChange = (dX, dY, offset) => {
    const meterPerPx = calcMetersPerPx(buildingPos.lat, mapSettings.zoom);
    const { metersPerLatDegree, metersPerLngDegree } = latLng2MetersPerDegree(buildingPos.lat);
    const dLat = ((dY + offset) * meterPerPx) / metersPerLatDegree;
    const dLong = ((dX + offset) * meterPerPx) / metersPerLngDegree;

    setBuildingPos({
      lat: buildingPos.lat - dLat,
      lng: buildingPos.lng + dLong,
    });
  };
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const onChildMouseDown = (childKey, childProps, mouse) => {
    setDrag(false);
  };
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const onChildMouseUp = (childKey, childProps, mouse) => {
    setDrag(true);
  };
  // eslint-disable-next-line no-unused-vars
  const onChildMouseMove = (childKey, childProps, mouse) => {
    setBuildingPos({
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };

  const _onChange = ({ center, zoom }) => {
    setMapSettings({
      center,
      zoom,
    });
  };

  return (
    <>
      {mapSettings && (
        <MapWrapper ref={mapRef}>
          {!isNaN(mapSettings.zoom) && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS }}
              draggable={drag}
              onChange={_onChange}
              center={mapSettings.center}
              zoom={mapSettings.zoom}
              hoverDistance={15}
              options={{
                mapTypeControl: true,
                fullscreenControl: false,
                mapTypeControlOptions: {
                  position: 7,
                  mapTypeIds: ['roadmap', 'satellite'],
                },
                rotateControl: false,
                tilt: 0,
                styles: MapStyles,
                gestureHandling: 'greedy',
              }}
              onChildMouseDown={onChildMouseDown}
              onChildMouseUp={onChildMouseUp}
              onChildMouseMove={onChildMouseMove}
            >
              <DraggableBuildingMarker
                lat={buildingPos.lat}
                lng={buildingPos.lng}
                setDrag={setDrag}
                calcLatLong={calcLatLongChange}
                mapSettings={mapSettings}
              />
            </GoogleMapReact>
          )}
          <ButtonWrapper>
            <SaveButton onClick={() => onSave(buildingPos)}>Save</SaveButton>
            <CancelButton onClick={onCancel}>Close</CancelButton>
          </ButtonWrapper>
        </MapWrapper>
      )}
    </>
  );
};

export default DevicePositioningMap;

import { createThunkEffect } from 'utils/ReduxUtils';

interface ViewDataState {
  data: any[];
  lastUpdate: Date;
  shouldUpdate?: boolean;
}
const initialState: ViewDataState = {
  data: [],
  lastUpdate: new Date(),
  shouldUpdate: false,
};

const FETCH_VIEWDATA = 'FETCH_VIEWDATA';
const FETCH_SHOULDUPDATE = 'FETCH_SHOULDUPDATE';

const REFRESH_VIEWDATA = 'REFRESH_VIEWDATA';
const CLEAR_VIEWDATA = 'CLEAR_VIEWDATA';

export const viewDataTypes = {
  FETCH_SHOULDUPDATE,
  FETCH_VIEWDATA,
  REFRESH_VIEWDATA,
  CLEAR_VIEWDATA,
};

export const clearViewData = () => ({ type: CLEAR_VIEWDATA });

export const fetchViewData = (viewDef) => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_VIEWDATA, 'FetchViewData ', viewDef);

export const shouldViewUpdate = (deviceTypes, date) => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_SHOULDUPDATE, 'ShouldUpdateView ', { deviceTypes, date });

const getShouldUpdate = (state, deviceIds) => {
  if (state.lastUpdate < new Date(Date.now() - 1000 * 60 * 5)) return true;
  if (deviceIds.length === 0) return false;
  if (state.data.filter((d) => deviceIds.includes(d.deviceId)).length > 0) return true;

  return false;
};

export const viewDataReducer = (state: ViewDataState = initialState, action) => {
  switch (action.type) {
    case `${viewDataTypes.FETCH_VIEWDATA}_SUCCESS`:
      return {
        data: action.payload.data,
        shouldViewUpdate: false,
        lastUpdate: new Date(),
      };
    case `${viewDataTypes.FETCH_SHOULDUPDATE}_SUCCESS`:
      return {
        ...state,
        shouldUpdate: getShouldUpdate(state, action.payload),
      };

    case viewDataTypes.CLEAR_VIEWDATA:
      return initialState;
    default:
      return state;
  }
};

/* eslint-disable max-len */
import React from 'react';

const KebabIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 32 32">
    <path
      d="M16,19.8c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8l0,0c2.1,0,3.8,1.7,3.8,3.8S18.1,19.8,16,19.8L16,19.8z M16,8.4
	        c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8l0,0c2.1,0,3.8,1.7,3.8,3.8S18.1,8.4,16,8.4L16,8.4z M16,31.2c-2.1,0-3.8-1.7-3.8-3.8
	        s1.7-3.8,3.8-3.8l0,0c2.1,0,3.8,1.7,3.8,3.8S18.1,31.2,16,31.2L16,31.2z"
    />
  </svg>
);

export default KebabIcon;

import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { ApiMethod } from 'types';
import { ApiActionNames } from 'reducers/types';
import { handleReduxToolkitApiRequest } from 'utils/hooks/APIRequestsHandlers/handleReduxToolkitApiRequest';
import { UpdatedUserData, User } from './types';
import { updateUserDataInThunk } from './utils/updateUserDataInThunk';

export const fetchUsers = createAsyncThunk<AxiosResponse<User[]>, void, { rejectValue: string }>(
  `users/${ApiActionNames.FetchUsers}`,
  async (_, { rejectWithValue, getState, dispatch }) => {
    const state: any = getState();
    const currentTenant = state.auth.user?.selectedTenant ?? '';

    return handleReduxToolkitApiRequest({
      method: ApiMethod.GET,
      url: `${process.env.REACT_APP_API_URL_V2}tenants/${currentTenant}/users`,
      rejectWithValue,
      dispatch,
      enableErrorHandler: true,
    });
  }
);

export const createUser = createAsyncThunk<void, User, { rejectValue: string }>(
  `users/${ApiActionNames.CreateUser}`,
  async (data, { rejectWithValue, getState, dispatch }) => {
    const state: any = getState();
    const currentTenant = state.auth.user?.selectedTenant ?? '';

    const response = await handleReduxToolkitApiRequest({
      method: ApiMethod.POST,
      url: `${process.env.REACT_APP_API_URL_V2}tenants/${currentTenant}/users`,
      rejectWithValue,
      dispatch,
      enableErrorHandler: true,
      data,
    });

    if (response.status >= 200 && response.status < 300) {
      dispatch(fetchUsers());
    }
  }
);

export const updateUserDetails = createAsyncThunk<
  { id: string; data: { email: string; name: string } },
  UpdatedUserData,
  { rejectValue: string }
>(`users/${ApiActionNames.UpdateUserDetails}`, updateUserDataInThunk('UserDetails'));

export const updateUserTag = createAsyncThunk<
  { id: string; data: { tags: string[] } },
  UpdatedUserData,
  { rejectValue: string }
>(`users/${ApiActionNames.UpdateUserTag}`, updateUserDataInThunk('Tags'));

export const updateUserRole = createAsyncThunk<
  { id: string; data: { role: string } },
  UpdatedUserData,
  { rejectValue: string }
>(`users/${ApiActionNames.UpdateUserRole}`, updateUserDataInThunk('Roles'));

export const deleteUser = createAsyncThunk<{ id: string }, { id: string }, { rejectValue: string }>(
  `users/${ApiActionNames.DeleteUser}`,
  async ({ id }, { rejectWithValue, getState, dispatch }) => {
    const state: any = getState();
    const currentTenant = state.auth.user?.selectedTenant ?? '';

    await handleReduxToolkitApiRequest({
      method: ApiMethod.DELETE,
      url: `${process.env.REACT_APP_API_URL_V2}tenants/${currentTenant}/users/${id}`,
      enableErrorHandler: true,
      rejectWithValue,
      dispatch,
    });

    return { id };
  }
);

export const selectUser = (id: string) =>
  createSelector(
    (state: any) => state.users.data,
    (users) => users.find((user: User) => user.id === id)
  );

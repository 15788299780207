import { MapItem } from 'reducers/mapData';
import { BuildingInfo, FloorInfo } from 'types/BuildingInfo';

const getDefaultFloor = (building, devices) => {
  if (devices.length === 1) return building.floors.find((f) => f.name === devices[0].floor);

  if (building.defaultFloor) return building.floors.find((f) => f.name === building.defaultFloor);

  return building.floors.find(
    (f) => f.name === '0' || f.name.toUpperCase() === 'G' || f.name.toUpperCase() === 'GROUND'
  );
};

const getDeviceMarkers = (devices, building, floor) =>
  devices.reduce(
    (acc, curr) =>
      curr.building === building.building && curr.floor === floor.name
        ? [...acc, { ...curr, type: 'device' }]
        : acc,
    [] as any[]
  );

const getActiveFloors = (devices, building) =>
  devices.reduce(
    (floorAccumulator, deviceItem) =>
      deviceItem.building === building.building && !floorAccumulator.includes(deviceItem.floor)
        ? [...floorAccumulator, deviceItem.floor]
        : floorAccumulator,
    [] as any[]
  );

const getBuildingMarkers = (buildings) => buildings.map((b) => ({ ...b, type: 'building' }));

export interface FloorPlanState {
  buildings: BuildingInfo[];
  selectedBuilding: BuildingInfo | null;
  selectedFloor: FloorInfo | null;
  activeFloors: FloorInfo[];
  clusters: any[];
  devices: MapItem[];
}

export const initFloorPlanState = {
  buildings: [],
  selectedBuilding: null,
  selectedFloor: null,
  activeFloors: [],
  clusters: [],
  devices: [],
};

const setFloorPlanState = (buildingInfo, devices, state) => {
  const buildingNames = [...new Set(devices.map((device) => device.building))].filter((f) => f);

  const buildings = buildingInfo.filter((building) => buildingNames.includes(building.building));

  return {
    ...state,
    buildings: buildings,
    selectedBuilding: buildings.length === 1 ? buildings[0] : null,
    devices: [...devices],
  };
};

const handleBuildingSelect = (state: FloorPlanState) => {
  if (state.selectedBuilding) {
    const floor = getDefaultFloor(state.selectedBuilding, state.devices);
    const activeFloors = getActiveFloors(state.devices, state.selectedBuilding);
    const selectedFloor = activeFloors.includes(floor.name)
      ? floor
      : state.selectedBuilding.floors.find((f) => f.name === activeFloors[0]);

    return {
      ...state,
      selectedFloor: selectedFloor,
      activeFloors: activeFloors,
    };
  } else {
    return {
      ...state,
      selectedFloor: null,
      activeFloors: null,
    };
  }
};

const getClusters = (state: FloorPlanState) => {
  if (state.selectedFloor) {
    return getDeviceMarkers(state.devices, state.selectedBuilding, state.selectedFloor);
  } else if (state.buildings.length > 1) {
    return getBuildingMarkers(state.buildings);
  } else {
    return state.devices.map((d) => ({ ...d, type: 'device' }));
  }
};

const handleIncrementFloor = (state: FloorPlanState) => {
  if (state.selectedBuilding) {
    const i = state.selectedBuilding?.floors.findIndex((f) => f.name === state.selectedFloor?.name);
    let newFloor;
    if (i === state.selectedBuilding?.floors.length - 1) {
      newFloor = state.selectedBuilding.floors[0];
    } else {
      newFloor = state.selectedBuilding.floors[i + 1];
    }

    return {
      ...state,
      selectedFloor: newFloor,
    };
  }

  return state;
};

const handleDecrementFloor = (state: FloorPlanState) => {
  if (state.selectedBuilding) {
    const i = state.selectedBuilding?.floors.findIndex((f) => f.name === state.selectedFloor?.name);
    let newFloor;
    if (i === 0) {
      newFloor = state.selectedBuilding.floors[state.selectedBuilding.floors.length - 1];
    } else {
      newFloor = state.selectedBuilding.floors[i - 1];
    }

    return {
      ...state,
      selectedFloor: newFloor,
    };
  }

  return state;
};

export const floorPlanReducer = (state, action) => {
  switch (action.type) {
    case 'initial': {
      let newState = { ...state };
      newState = setFloorPlanState(action.buildingInfo, action.devices, newState);
      newState = handleBuildingSelect(newState);
      newState.clusters = getClusters(newState);

      return newState;
    }
    case 'increment': {
      const newState = handleIncrementFloor(state);
      newState.clusters = getClusters(newState);

      return newState;
    }
    case 'decrement': {
      const newState = handleDecrementFloor(state);
      newState.clusters = getClusters(newState);

      return newState;
    }
    case 'selectFloor': {
      const newState = {
        ...state,
        selectedFloor: action.selectedFloor,
      };
      newState.clusters = getClusters(newState);

      return newState;
    }
    case 'selectBuilding': {
      let newState = {
        ...state,
        selectedBuilding: action.selectedBuilding,
      };
      newState = handleBuildingSelect(newState);
      newState.clusters = getClusters(newState);

      return newState;
    }
    default:
      return state;
  }
};

export const floorPlanInitialAction = (buildingInfo, devices) => ({
  buildingInfo,
  devices,
  type: 'initial',
});
export const setSelectedFloorAction = (floor) => ({ type: 'selectFloor', selectedFloor: floor });
export const setSelectedBuildingAction = (building) => ({
  type: 'selectBuilding',
  selectedBuilding: building,
});
export const incrementFloorAction = () => ({ type: 'increment' });
export const decrementFloorAction = () => ({ type: 'decrement' });

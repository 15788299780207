import React, { useState } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import { DeviceActionProps } from 'components/DeviceActions/DeviceActionProps';
import { DeviceAccessRightsTagWrapperApiV1 } from 'components/molecules';
import { DialogSuccessMessage } from 'components/atoms';
import { UpdateAccessRightsTagConstrainer } from './UpdateAccessRightsTag.styles';

export const UpdateAccessRightsTag: React.FC<DeviceActionProps> = ({
  device,
  handleResetAction,
}) => {
  const [isUpdated, setIsUpdated] = useState(false);

  const handleOnUpdateSuccess = () => setIsUpdated(true);

  return (
    <Dialog open onClose={() => handleResetAction()}>
      <DialogSuccessMessage
        showMessage={isUpdated}
        onClose={handleResetAction}
        message="Access Tag Updated Successfully"
      >
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '1.7rem' }}>
          Update Access Rights Tag
          <IconButton
            aria-label="close"
            onClick={handleResetAction}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close fontSize="large" />
          </IconButton>
        </DialogTitle>
        <UpdateAccessRightsTagConstrainer>
          <DeviceAccessRightsTagWrapperApiV1
            limited
            device={device}
            onSuccess={handleOnUpdateSuccess}
          />
        </UpdateAccessRightsTagConstrainer>
      </DialogSuccessMessage>
    </Dialog>
  );
};

import React from 'react';

import styled from 'styled-components';
import { mapStatus } from 'utils/deviceUtils';

import { DeviceStatusSpan } from '../../atoms/DeviceStatusSpan';

const Wrapper = styled.span`
  display: flex;
  align-items: center;
`;
const DeviceStatusIndicator = ({ deviceStatus }) => (
  <Wrapper>
    <DeviceStatusSpan status={mapStatus(deviceStatus)} />
    {deviceStatus}
  </Wrapper>
);

export default DeviceStatusIndicator;

import { useState } from 'react';

import { handleApiReqFileDownload } from 'utils/ApiRequestHelpers';
import useAlert from 'utils/hooks/useAlert';

import { ReportFetchPayload, TagsType } from '../types';
import { transformTagsToQuery } from '../utils';

type UseDownloadReportType = {
  tenantID: string;
  endpoint?: string;
  reportType: string;
  selectedTags?: TagsType[];
  reportDates: {
    year: number;
    month: number;
  };
  onSuccess?: () => any;
};

export const useDownloadReport = ({
  tenantID,
  endpoint,
  reportType,
  reportDates,
  selectedTags,
  onSuccess,
}: UseDownloadReportType) => {
  const [reportStatus, setReportStatus] = useState<'pending' | 'init' | 'success'>('init');

  const { errorAlert } = useAlert();

  const hasEndpoint = !!endpoint;

  const queryFormTags = selectedTags?.length ? transformTagsToQuery(selectedTags) : '';

  const handleDownloadReport = async () => {
    setReportStatus('pending');
    const apiNormalizedMonth = reportDates.month + 1;

    const payload: ReportFetchPayload = {
      url: hasEndpoint
        ? `${endpoint}/${tenantID}/${reportType}/${reportDates.year}/${apiNormalizedMonth}/?${queryFormTags}`
        : reportType,
      method: hasEndpoint ? 'GET' : 'POST',
    };

    const request: boolean = await handleApiReqFileDownload(payload);

    if (request) {
      if (onSuccess) {
        onSuccess();
      }
    } else {
      errorAlert('Failed to download report');
    }

    setReportStatus('init');
  };

  const isLoading = reportStatus === 'pending';

  return {
    handleDownloadReport,
    isLoading,
  };
};

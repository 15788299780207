import React, { useCallback } from 'react';
import { Moment } from 'moment';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import { Axis } from '@visx/axis';
import * as allCurves from '@visx/curve';
import { localPoint } from '@visx/event';
import { LinePath, Line, Bar } from '@visx/shape';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';

import { EventProps } from 'types/Telemetry';
import { useChartContext, useChartUpdateContext } from 'utils/contexts';
import {
  paddingX,
  paddingY,
  lineThickness,
  formatDateTime,
  curveType,
} from './constants/eventTimeline';
import { getNumTicks } from './utils/getNumTicks';
import { useEventTimelineCalculations } from './hooks/useEventTimelineCalculations';

export type EventTimelineProps = {
  graphWidth: number;
  graphHeight: number;
  dateRange: Moment[];
  selectedEventList: Array<EventProps>;
  updateSelectedEventList: (eventList: Array<EventProps>) => void;
  eventSeries?: EventProps[];
};

const EventTimeline: React.FC<EventTimelineProps> = ({
  graphHeight,
  graphWidth,
  dateRange,
  selectedEventList,
  updateSelectedEventList,
  eventSeries,
}) => {
  const chartContext = useChartContext();
  const chartUpdateContext = useChartUpdateContext();

  const getX = useCallback((data) => data.startDate, []);
  const getPointX = useCallback((data) => data.date, []);
  const getPointY = useCallback((data) => data.value, []);

  const { tooltipData, tooltipLeft, showTooltip } = useTooltip();
  const { TooltipInPortal, containerRef } = useTooltipInPortal({
    detectBounds: true,
    polyfill: ResizeObserverPolyfill,
    scroll: true,
  });

  const { dateScaleXAxis, metricScaleYAxis, data } = useEventTimelineCalculations({
    graphHeight,
    graphWidth,
    dateRange,
    selectedEventList,
    updateSelectedEventList,
    eventSeries,
    showTooltip,
  });

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const { x } = localPoint(event) || { x: 0 };
      const dateFromXAxis = dateScaleXAxis.invert(x);

      chartUpdateContext({
        ...chartContext,
        selectedDateTime: dateFromXAxis,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartUpdateContext, dateScaleXAxis]
  );

  const handleTooltipPause = () => {
    chartUpdateContext({
      ...chartContext,
      pauseSelection: !chartContext.pauseSelection,
    });
  };

  return (
    <div>
      <svg width={graphWidth} height={graphHeight}>
        <rect
          x={0}
          y={0}
          width={graphWidth > 0 ? graphWidth : 0}
          height={graphHeight > 0 ? graphHeight : 0}
          fill="transparent"
          ref={containerRef}
        />

        <Axis
          scale={dateScaleXAxis}
          top={graphHeight - paddingY}
          orientation="bottom"
          stroke="darkgrey"
          strokeWidth={1}
          tickStroke="darkgrey"
          numTicks={getNumTicks(graphWidth)}
          tickLabelProps={() => ({
            fill: 'grey',
            textAnchor: 'start',
            verticalAnchor: 'middle',
            fontSize: '0.9em',
            transform: `translate(${-paddingX})`,
          })}
        />

        <Axis
          hideZero
          scale={metricScaleYAxis}
          left={paddingX}
          orientation="right"
          hideTicks
          stroke={'darkGrey'}
          strokeWidth={1}
          tickLabelProps={() => ({
            display: 'none',
          })}
        />

        {data && data.length > 0 ? (
          data.map(({ category, startNormalized, endNormalized, severity }, i) => (
            <LinePath
              key={`${category}-${i}`}
              curve={allCurves[curveType]}
              data={[
                {
                  value: severity,
                  date: startNormalized,
                },
                {
                  value: severity,
                  date: endNormalized,
                },
              ]}
              x={(data) => dateScaleXAxis(getPointX(data)) ?? 0}
              y={(data) => metricScaleYAxis(getPointY(data)) ?? 0}
              stroke={
                (severity === 'High' && '#E65D6D') ||
                (severity === 'Medium' && '#EDB760') ||
                '#0187B5'
              }
              fill="transparent"
              strokeWidth={lineThickness}
              strokeOpacity={1}
              style={{ border: '2px solid black' }}
              shapeRendering="geometricPrecision"
            />
          ))
        ) : (
          <text x={'47%'} y={'45%'} fill={'#D0D4DD'}>
            No events
          </text>
        )}

        <Bar
          x={paddingX}
          y={paddingY}
          width={graphWidth - paddingX * 2}
          height={graphHeight - paddingY * 2}
          fill="transparent"
          rx={14}
          onTouchStart={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onTouchMove={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onMouseMove={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onClick={() => handleTooltipPause()}
        />

        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: paddingY }}
              to={{ x: tooltipLeft, y: graphHeight - paddingY }}
              stroke={'#666'}
              strokeWidth={2}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
          </g>
        )}
      </svg>
      {tooltipData && (
        <div>
          <TooltipInPortal
            key={Math.random()}
            top={graphHeight - paddingY - 10}
            left={tooltipLeft && tooltipLeft - 60}
            style={{
              ...defaultStyles,
              textAlign: 'center',
              minWidth: '72px',
            }}
          >
            {`${formatDateTime(getX(tooltipData))}`}
          </TooltipInPortal>
        </div>
      )}
    </div>
  );
};

export default EventTimeline;

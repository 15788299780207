import { useCallback, useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import {
  useAppSelector,
  useDataGridPinnedRows,
  useGetFetchData,
  useSelectedTenant,
} from 'utils/hooks';
import { useOutletContext } from 'react-router-dom';

import { ApiErrorsType, DocumentsResponse, ImageResponseMetadata } from 'types';
import { addDataGridPinColumn } from 'utils';
import { PropertyContextProps } from 'routes/Property/types';
import { propertyRecordsColumns } from '../constants';

export const useRecordsTableData = () => {
  const tenant = useSelectedTenant();
  const users = useAppSelector((state) => state.users.data);

  const { propertyInfo } = useOutletContext<PropertyContextProps>();

  const {
    data,
    isLoading,
    isFetching,
  }: UseQueryResult<DocumentsResponse<ImageResponseMetadata>, ApiErrorsType> = useGetFetchData({
    url: `${process.env.REACT_APP_API_URL_V2}tenants/${tenant}/documents`,
    enableErrorHandler: true,
    query: `?propertyId=${propertyInfo.data?.propertyId}&contentType=image/jpeg&documentType=PropertyRecords`,
    enabled: !!tenant && !!propertyInfo.data?.propertyId,
  });

  const transformPropertyRecords = useCallback(
    (records: DocumentsResponse<ImageResponseMetadata> | undefined) =>
      records?.results.map(({ metadata, propertyLocationId, id, createdBy }) => {
        const propertyLocation = propertyInfo.data?.locations.find(
          (loc) => loc.id === propertyLocationId
        );
        const locationDetails = propertyLocation?.details ? ` - ${propertyLocation.details}` : '';
        const location = propertyLocation?.name ? `${propertyLocation.name}${locationDetails}` : '';

        return {
          id,
          location,
          title: metadata.title,
          date: metadata.timestamp,
          createdBy: users?.find((user) => user.id === createdBy)?.name || '',
          note: metadata.note,
        };
      }),
    [propertyInfo.data?.locations, users]
  );

  const transformedRows = useMemo(
    () => transformPropertyRecords(data) || [],
    [data, transformPropertyRecords]
  );

  const { pinnedRowsIds, setPinnedRowsIds, pinnedRows, rows } = useDataGridPinnedRows({
    rows: transformedRows,
  });

  const columns = useMemo(
    () =>
      addDataGridPinColumn({
        pinnedRowsIds,
        setPinnedRowsIds,
        columnsList: propertyRecordsColumns,
      }),
    [pinnedRowsIds, setPinnedRowsIds]
  );

  return { rows, pinnedRows, columns, isLoading, isFetching };
};

interface LogoProps {
  url: string;
  altText: string;
}

export interface TenantLogoObj {
  location: string;
  forLight: LogoProps;
  forDark: LogoProps;
}

interface ManifestObj {
  tenantName: string;
  logos: Array<TenantLogoObj>;
}

export const tenantLogoManifest: Array<ManifestObj> = [
  {
    tenantName: 'demo1',
    logos: [
      {
        location: 'navbar',
        forLight: {
          url: 'images/tenantLogos/DemoLogoDarkText500x200.svg',
          altText: 'Demo Logo',
        },
        forDark: {
          url: 'images/tenantLogos/DemoLogoWhiteText500x200.svg',
          altText: 'Demo Logo',
        },
      },
    ],
  },
  {
    tenantName: 'guhg',
    logos: [
      {
        location: 'navbar',
        forLight: {
          url: 'images/tenantLogos/guhgDarkText500x200T.png',
          altText: 'Grand Union Logo',
        },
        forDark: {
          url: 'images/tenantLogos/guhgLightText500x200T.png',
          altText: 'Grand Union Logo',
        },
      },
    ],
  },
  {
    tenantName: 'homeserve',
    logos: [
      {
        location: 'navbar',
        forLight: {
          url: 'images/tenantLogos/homeserveHouseTopRedText500x200.svg',
          altText: 'Homeserve Logo',
        },
        forDark: {
          url: 'images/tenantLogos/homeserveHouseTopRedText500x200.svg',
          altText: 'Homeserve Logo',
        },
      },
    ],
  },
  {
    tenantName: 'netzerocollective',
    logos: [
      {
        location: 'navbar',
        forLight: {
          url: 'images/tenantLogos/NetZeroCollectiveLogo.svg',
          altText: 'Net Zero Collective Logo',
        },
        forDark: {
          url: 'images/tenantLogos/NetZeroCollectiveLogo-WhiteText.svg',
          altText: 'Net Zero Collective Logo',
        },
      },
    ],
  },
];

import React from 'react';

import Spinner from 'components/atoms/Spinner';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  & p {
    font-size: 1.4rem;
    padding-top: 1rem;
  }
`;

const SpinnerWrapper = styled.div`
  margin: 0 auto;
  height: 4rem;
  width: 4rem;
`;

const TableLoading = ({ colspan }) => (
  <tr>
    <td colSpan={colspan}>
      <Wrapper>
        <SpinnerWrapper>
          <Spinner backgroundColor="white" />
        </SpinnerWrapper>
        <p>Loading...</p>
      </Wrapper>
    </td>
  </tr>
);

export default TableLoading;

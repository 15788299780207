import React, { useState } from 'react';

import Spinner from 'components/atoms/Spinner';
import { useDispatch } from 'react-redux';
import { fetchRepairForms } from 'reducers/repairForms';
import styled from 'styled-components';
import { handleFormReq } from 'utils/ApiRequestHelpers';
import useAlert from 'utils/hooks/useAlert';

import DeviceMaintenanceForm from '../DeviceMaintenanceForm';

const RetestButton = styled.button`
  ${(props) => props.theme.defaultButton('100%', props.disabled)};
`;
const SpinnerWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: white;
  border-radius: 50%;
  margin-left: 1rem;
`;

const RetestInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const RetestWrapper = styled.div`
  margin-top: 1rem;
`;
const maintenanceMessage =
  'Please select components replaced or type a short description if the action(s) taken to resolve the fault';

const EmeredForm = ({ device }) => {
  const { errorAlert, successAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const formItems = [
    {
      name: 'battery',
      type: 'checkBox',
      label: 'Battery',
    },
    {
      name: 'inverter',
      type: 'checkBox',
      label: 'Inverter',
    },
    {
      name: 'lamp',
      type: 'checkBox',
      label: 'Lamp',
    },
    {
      name: 'description',
      type: 'textArea',
      label: 'Action(s)',
    },
  ];

  const handleRetest = async () => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);
      setIsLoading(true);
      const form = new FormData();
      form.append('deviceId', device.deviceId);
      const res = await handleFormReq('RunT0Test', form, 'POST');
      if (res) {
        successAlert('Flick test queued');
      } else {
        errorAlert('Error! Please try again. If problem persists please contact support');
      }
      dispatch(fetchRepairForms(device.deviceId, device.deviceType));
      setIsButtonDisabled(false);
      setIsLoading(false);
    }
  };

  const handleRefetch = () => dispatch(fetchRepairForms(device.deviceId, device.deviceType));

  return (
    <>
      <DeviceMaintenanceForm
        title="Resolution Action"
        message={maintenanceMessage}
        formItems={formItems}
        device={device}
        endPoint="EmeredRepairForm"
        fetchAction={handleRefetch}
        disabled={isButtonDisabled}
        setIsButtonDisabled={setIsButtonDisabled}
        btnText="Repair Device and Queue Flick Test"
        successMsg="Device marked as repaired and flick test queued"
      />
      <RetestWrapper>
        <RetestButton onClick={handleRetest} disabled={isButtonDisabled}>
          <RetestInnerWrapper>
            Flick Test
            {isLoading && (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            )}
          </RetestInnerWrapper>
        </RetestButton>
      </RetestWrapper>
    </>
  );
};

export default EmeredForm;

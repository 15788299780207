import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchViewData } from 'reducers/viewData';
import {
  ListingColumn,
  selectAllViewDefinitions,
  selectCurrentViewContext,
  selectSelectedView,
  setCurrentViewContext,
  setSelectedView,
  ViewContext,
  ViewDefinition,
} from 'reducers/viewDefinitions';
import { Filter } from 'types/Listing';

import useNavigate from './useNavigate';

const useListingViewHelpers = () => {
  const dispatch = useDispatch();
  const { goTo } = useNavigate();
  const viewDefinitions: ViewDefinition[] = useSelector((state: any) =>
    selectAllViewDefinitions(state)
  );
  const currentViewContext: ViewContext = useSelector((state: any) =>
    selectCurrentViewContext(state)
  );
  const selectedView: ViewDefinition = useSelector((state: any) => selectSelectedView(state));
  const [initDone, setInitDone] = useState<boolean>(false);

  useEffect(() => {
    if (selectedView && initDone) {
      dispatch(fetchViewData(selectedView));
      goTo(currentViewContext.page, 'Listing', currentViewContext.viewId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView, initDone]);

  const refreshViewData = () => {
    if (selectedView) {
      dispatch(fetchViewData(selectedView));
    }
  };

  const isCurrentDifferentFromSelectedContext = (
    currentView: ViewContext,
    selectedView: ViewContext
  ): boolean =>
    Object.keys(currentView)
      .map((key) => currentView[key] !== selectedView[key])
      .includes(true);

  const setSelectedListingView = (viewId: string) => {
    if (viewDefinitions.length > 0) {
      const view = viewDefinitions.find((view) => view.viewId === viewId);
      setInitDone(true);
      dispatch(setSelectedView(view));
    }
  };

  const setListingViewContext = (newContext: ViewContext) => {
    if (
      currentViewContext.page !== '' &&
      currentViewContext.tenantId !== '' &&
      currentViewContext.viewId !== ''
    ) {
      const isSelectedViewDifferent = isCurrentDifferentFromSelectedContext(
        currentViewContext,
        newContext
      );
      if (isSelectedViewDifferent) {
        dispatch(setCurrentViewContext(newContext));
        setSelectedListingView(newContext.viewId);
      }
    } else {
      dispatch(setCurrentViewContext(newContext));
      setSelectedListingView(newContext.viewId);
    }
  };

  const setDefaultContext = (newContext: ViewContext) => {
    dispatch(setCurrentViewContext(newContext));
    setSelectedListingView(newContext.viewId);
    setInitDone(true);
  };

  const updateSelectedView = (newView: ViewDefinition) => {
    setInitDone(true);
    dispatch(setSelectedView(newView));
  };

  const updateFilters = (filters: Filter[]) => {
    updateSelectedView({ ...selectedView, filters });
  };

  const stringArraysAreDifferent = (arrayOne: string[], arrayTwo: string[]): boolean => {
    if (arrayOne.length !== arrayTwo.length) return true;

    return arrayOne.map((value, i) => value !== arrayTwo[i]).includes(true);
  };

  const updateViewColumns = (columns: ListingColumn[]) => {
    const currentColumns = selectedView.columns;
    const newColumns = columns.map((col) => col.id);

    const columnsAreDifferent = stringArraysAreDifferent(currentColumns, newColumns);

    if (columnsAreDifferent) {
      updateSelectedView({
        ...selectedView,
        columns: newColumns,
      });
    }
  };

  return {
    setDefaultContext,
    setListingViewContext,
    setSelectedListingView,
    refreshViewData,
    updateSelectedView,
    updateFilters,
    updateViewColumns,
  };
};

export default useListingViewHelpers;

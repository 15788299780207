import styled from 'styled-components';

export const AddTagWrapper = styled.div`
  display: flex;
  min-height: 40px;
  align-items: center;

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    left: 0;
  }

  .add-tag {
    &__button {
      padding-right: 12px;

      @media (min-width: 768px) {
        padding-right: 24px;
      }
    }

    &__form {
      display: flex;
      gap: 5px;

      @media (min-width: 768px) {
        gap: 10px;
      }

      &-close {
        &-icon {
          transform: rotate(45deg);
          color: ${(props) => props.theme.vericonRed};
        }

        &:disabled {
          .add-tag__form-close-icon {
            color: unset;
          }
        }
      }

      &-submit {
        &-icon {
          color: ${(props) => props.theme.submitGreen};
        }

        &:disabled {
          .add-tag__form-submit-icon {
            color: unset;
          }
        }
      }
    }
  }
`;

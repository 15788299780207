import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import FloorPositioningMap from 'components/FloorPlan/FloorPositioningMap';
import { selectBuilding } from 'reducers/buildingInfo/selectors';
import useSearchParams from 'utils/hooks/useSearchParams';

const PositionFloor = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams(location.search, ['building', 'floor']);
  const building = useSelector((state: any) => selectBuilding(searchParams['building'])(state));
  const [floor, setFloor] = useState();

  useEffect(() => {
    if (building) setFloor(building.floors[searchParams['floor']]);
  }, [building, searchParams]);

  return <>{floor && <FloorPositioningMap floor={floor} building={building.building} />}</>;
};

export default PositionFloor;

import React, { useState } from 'react';
import {
  CircularProgress,
  LinearProgress,
  TableHead,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';

import { useGetFetchData } from 'utils/hooks/APIRequestsHandlers/useGetFetchData';
import { usePostFetchData } from 'utils/hooks/APIRequestsHandlers/usePostFetchData';
import { useAreaFeatures } from 'utils/hooks';
import { FeaturesAreas } from 'types';
import Input from 'components/elements/Input';
import {
  APPROVE_URL,
  FILTERING_OPTIONS,
  FILTERING_OPTION_ALL,
  FILTERING_OPTION_APPROVED,
  FILTERING_OPTION_WAITING_APPROVAL,
  REVOKE_URL,
} from '../../constants/residenceTable';
import { ResidenceTableRowButton, ResidenceTableRowSetText } from '..';
import { ResidenceTableWrapper, StyledTableCell, StyledTableRow } from './ResidenceTable.styles';

type ResidenceTableProps = {
  tenant: string;
};

export const ResidenceTable: React.FC<ResidenceTableProps> = ({ tenant }) => {
  const residentsFeature = useAreaFeatures(FeaturesAreas.Residents).features;
  const homeHubFeature = useAreaFeatures(FeaturesAreas.HomeHub).features;
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(FILTERING_OPTION_ALL);

  const { data, isLoading, isSuccess, refetch, isFetching } = useGetFetchData({
    url: `${process.env.REACT_APP_APIURL}`,
    query: `Tenants/${tenant}/Residents`,
    enableErrorHandler: true,
  });

  const handleRefresh = async () => {
    setTimeout(async () => {
      await refetch();
      setIsUpdating(false);
    }, 1000);
  };

  const approveMutation = usePostFetchData({
    url: APPROVE_URL,
    onSuccess: handleRefresh,
    onError: () => setIsUpdating(false),
    enableErrorHandler: true,
  });

  const revokeMutation = usePostFetchData({
    url: REVOKE_URL,
    onSuccess: handleRefresh,
    onError: () => setIsUpdating(false),
    enableErrorHandler: true,
  });

  const filterData = ({ approved }) => {
    if (selectedFilter === FILTERING_OPTION_APPROVED) {
      return !!approved;
    }
    if (selectedFilter === FILTERING_OPTION_WAITING_APPROVAL) {
      return !approved;
    }

    return true;
  };

  return (
    <ResidenceTableWrapper className="residence-table-container">
      <div className="residence-table__filtering">
        <div className="residence-table__filtering-input">
          <Input
            type="dropdown"
            inputData={{
              value: selectedFilter,
              onChange: (v) => setSelectedFilter(v),
              name: 'residence',
              options: FILTERING_OPTIONS,
              readOnly: true,
            }}
          />
        </div>
      </div>
      {(isFetching || isUpdating) && (
        <div className="residence-table__linear-progress">
          <LinearProgress />
        </div>
      )}
      <Paper elevation={3} className="residence-table">
        <TableContainer sx={{ maxHeight: 300 }}>
          <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 400 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left"> Address</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            {isSuccess && (
              <TableBody>
                {data.map(({ auth0UserId, email, name, residences }) =>
                  residences
                    .filter(filterData)
                    .map(({ address, approved, residenceId }, rowIndex) => (
                      <StyledTableRow key={rowIndex.toString()}>
                        <StyledTableCell align="left">{name}</StyledTableCell>
                        <StyledTableCell align="left">{email}</StyledTableCell>
                        <StyledTableCell align="left">{address}</StyledTableCell>
                        <StyledTableCell align="right">
                          {homeHubFeature.ManageApplication && approved && (
                            <ResidenceTableRowSetText
                              name={name}
                              userId={auth0UserId}
                              refreshTable={refetch}
                            />
                          )}
                          {residentsFeature.ManageResidentAccess && (
                            <ResidenceTableRowButton
                              name={name}
                              approved={approved}
                              handleRevoke={() => {
                                setIsUpdating(true);
                                revokeMutation.mutate({ auth0UserId, residenceId });
                              }}
                              handleApprove={() => {
                                setIsUpdating(true);
                                approveMutation.mutate({ auth0UserId, residenceId });
                              }}
                              isUpdating={isUpdating}
                            />
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {isLoading && (
          <div className="residence-table__loading">
            <CircularProgress />
          </div>
        )}
      </Paper>
    </ResidenceTableWrapper>
  );
};

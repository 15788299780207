import React, { useState } from 'react';

import styled from 'styled-components';

import CheckBox from './CheckBox';

const DropDownWrapper = styled.div<{ width: string }>`
  position: relative;
  display: inline-block;
  width: ${(props) => props.width};

  & > div {
    width: 100%;
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      background: transparent;
      top: 20%;
      right: 0.5rem;
      width: 0.75rem;
      height: 0.75rem;
      border-right: 2px solid ${(props) => props.theme.blue};
      border-bottom: 2px solid ${(props) => props.theme.blue};
      transform: rotate(45deg);
      transform-origin: 50% 50%;
      z-index: 1;
    }

    & > input {
      width: 100%;
      font-size: inherit;
      font-family: inherit;
    }
  }
`;

const CheckListWrapper = styled.ul`
  display: none;
  position: absolute;
  left: 0;
  max-height: 20rem;
  overflow: auto;
  font-size: 1.2rem;
  color: #000;
  background: ${(props) => props.theme.white};
  width: 100%;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 6;
  text-align: left;

  li {
    padding: 0.5rem;

    &:hover,
    &.selected {
      cursor: pointer;
      background-color: ${(props) => props.theme.default};
    }
  }

  &.open {
    display: block;
  }
`;

const CheckList = ({ value, name, onChange, options, placeholder = '', width }) => {
  const [open, setOpen] = useState(false);

  const localValue = value === null || value === undefined ? [] : value;

  const isArray = Array.isArray(localValue);
  const selectedValues = isArray ? localValue : localValue.split(', ').filter((v) => v);
  const handleClick = (v) => {
    if (selectedValues.includes(v)) {
      onChange(
        selectedValues.filter((i) => i !== v),
        name
      );
    } else {
      onChange([...selectedValues, v], name);
    }
  };

  const focusInput = (e) => {
    if (e.target.nodeName === 'DIV') {
      e.target.querySelector('input').focus();
    }
  };

  return (
    <DropDownWrapper width={width}>
      <div
        onClick={(e) => focusInput(e)}
        onKeyDown={(e) => focusInput(e)}
        role="button"
        tabIndex={0}
      >
        <input
          autoComplete="off"
          name={name}
          onClick={() => setOpen(!open)}
          onFocus={() => setOpen(true)}
          value={localValue}
          data-value={localValue}
          placeholder={placeholder}
          readOnly
        />
      </div>

      <CheckListWrapper className={open ? 'open' : ''}>
        {options.map((v, i) => (
          <li key={i}>
            <CheckBox
              label={v}
              name={v}
              checked={selectedValues.includes(v)}
              onChange={() => handleClick(v)}
            />
          </li>
        ))}
      </CheckListWrapper>
    </DropDownWrapper>
  );
};

export default CheckList;

import React from 'react';

import styled from 'styled-components';

const InputWrapper = styled.div<{ width: string }>`
  position: relative;
  display: inline-block;
  width: ${(props) => props.width};

  & > input {
    width: 100%;
    font-size: inherit;
    font-family: inherit;
  }
`;

const NumberInput = ({ value, name, onChange, width }) => {
  const handleOnChange = (e) => {
    onChange(e.target.value, name);
  };

  return (
    <InputWrapper width={width}>
      <input value={value} type="number" name={name} onChange={handleOnChange} />
    </InputWrapper>
  );
};

export default NumberInput;

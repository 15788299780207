import styled from 'styled-components';

const FloorControl = styled.div<{ width: number; left: string; bottom: string }>`
  ${(props) => props.theme.wrapper}
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => 6 * (props.width + 1)}rem;
  padding: 1.25rem 1rem;
  z-index: 9;
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  transition: all 0.3s ease-in-out;
  opacity: 0.7;

  @media ${(props) => props.theme.mobileL} {
    left: 2rem;
  }

  &:hover {
    opacity: 1;
  }

  & section:nth-of-type(1) {
    width: 100%;
    max-height: 32rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  & section:nth-of-type(2) {
    width: 100%;
    max-width: 12rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    & > div {
      font-size: 3rem;
      line-height: 1px;
    }
  }

  & div {
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0;
    width: 4rem;
    height: 4rem;
    font-weight: 500;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;

    &:hover,
    &.selected {
      cursor: pointer;
      background-color: ${(props) => props.theme.secondary};
      color: ${(props) => props.theme.primary};
    }

    &.inactive {
      opacity: 0.1;
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};

      &:hover {
        cursor: default;
      }
    }
  }

  & > hr {
    width: 100%;
    background: #b1b1b1;
    height: 2px;
    border: none;
    margin: 1rem 0;
  }
`;

export default FloorControl;

import { useOutletContext } from 'react-router-dom';

import { PropertyDevice } from 'types';
import { PropertyContextProps } from 'routes/Property/types';
import { propertyDevicesColumns } from '../constants/propertyDevice';

export const useDevicesTableData = () => {
  const { devicesInfo } = useOutletContext<PropertyContextProps>();

  const transformDevicesTableDataToRows = (devices: PropertyDevice[]) =>
    devices.map((device: PropertyDevice) => ({
      ...device,
      id: device.deviceId,
      bcmId: devices.find((d) => d?.serialNumber[0] === 'B')?.deviceId,
      accessRightsTagId: device.accessRightsTagId,
    }));

  const results: PropertyDevice[] = devicesInfo.data?.results || [];
  const gatewayDevice = results.filter((device) => device?.serialNumber[0] === 'B');
  const restDevices = results.filter((device) => device?.serialNumber[0] !== 'B');

  const deviceTableRows = transformDevicesTableDataToRows([...gatewayDevice, ...restDevices]);

  return {
    columns: propertyDevicesColumns(devicesInfo.refetch),
    rows: deviceTableRows,
    isLoading: devicesInfo.isLoading,
    isSuccess: devicesInfo.isSuccess,
    refetch: devicesInfo.refetch,
  };
};

import React from 'react';

import Spinner from 'components/atoms/Spinner';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  display: flex;
  height: 88vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > div {
    display: inline-block;
    width: 4rem;
    height: 4rem;
  }

  & > h4 {
    margin-top: 1rem;
  }
`;

const Loading = () => (
  <LoadingWrapper>
    <Spinner backgroundColor="backgroundColor" />
    <h4 aria-label="loading">Loading ...</h4>
  </LoadingWrapper>
);

export default Loading;

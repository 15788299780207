import styled from 'styled-components';

export const ActionButtonWrapper = styled.div`
  padding-bottom: 3rem;

  button {
    font-size: 14px;
    line-height: 1.7;
  }
`;

import React from 'react';

import FileUpload from 'components/atoms/FileUpload';
import { useDispatch } from 'react-redux';
import { fetchBuildingUpdate } from 'reducers/buildingInfo/actions';

const FloorUpload = ({ floor, building, hasFloorPlan }) => {
  const dispatch = useDispatch();

  const handleOnUpload = () => {
    setTimeout(() => dispatch(fetchBuildingUpdate(building)), 1000);
  };

  return (
    <FileUpload url="UploadFloorPlan" data={{ floor, building }} onUpload={handleOnUpload}>
      {hasFloorPlan ? 'Change floorplan' : 'Select floorplan'}
    </FileUpload>
  );
};

export default FloorUpload;

export const floorReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_FLOOR':
      return [
        ...state,
        {
          name: state.length,
          lat: state[0].lat,
          lng: state[0].lng,
          width: state[0].width,
          height: state[0].height,
          image: '',
        },
      ];
    case 'REMOVE_FLOOR':
      return state.filter((_, i) => i !== action.index);
    case 'UPDATE_FLOOR':
      return state.map((f, i) => {
        if (i === action.index) return action.updatedFloor;

        return f;
      });
    case 'UPDATE_GEO':
      return state.map((f) => ({
        ...f,
        lat: action.lat,
        lng: action.lng,
      }));
    default:
      return state;
  }
};

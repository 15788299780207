import React, { useState, useEffect } from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import styled from 'styled-components';
import { Filter, Field, FilterOperations } from 'types/Listing';

import DropDownInput from './DropDownInput';
import DropDownSearch from './DropDownSearch';
import InputSelector from './InputSelector';

const InputWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #999;
  padding: 0.5rem 1rem;
  z-index: 2;
  font-size: 1.2rem;

  margin: 0 0.25rem;

  & > div {
    margin: 0 0.5rem;
  }
`;

const IconWrapper = styled.span`
  width: 2rem;
  height: 2rem;

  &:hover {
    cursor: pointer;
  }

  & > svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;

interface FilterInputProps {
  fields: Field[];
  filter: Filter;
  handleSave: any;
  handleClose: any;
}

const FilterInput = ({ fields, filter, handleSave, handleClose }: FilterInputProps) => {
  const [state, setState] = useState(filter);
  const [operations, setOperations] = useState<FilterOperations[]>([]);
  const [selectedField, setSelectedField] = useState({
    dataType: 'string',
    options: [] as any[],
    suggestOnly: false,
  });

  useEffect(() => {
    setState(filter);
    const field = fields.find((f) => f.fieldId === filter.fieldId);
    if (field) {
      const allowedOps =
        field.filterSuggests.length === 0
          ? field.filterOps.filter((f) => f !== 'anyof')
          : field.filterOps;
      setOperations(allowedOps);
      setSelectedField({
        dataType: field.dataType,
        options: field.filterSuggests || [],
        suggestOnly: field.filterSuggestsExhaustive,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, JSON.stringify(fields)]);

  const onFilterSelect = (v) => {
    const field = fields.find((f) => f.fieldId === v);
    setState({ ...state, fieldId: v, value: '', filterOp: field?.filterOps[0] || 'equals' });
    if (field) {
      const allowedOps =
        field.filterSuggests.length === 0
          ? field.filterOps.filter((f) => f !== 'anyof')
          : field.filterOps;
      setOperations(allowedOps);
      setSelectedField({
        dataType: field.dataType,
        options: field.filterSuggests || [],
        suggestOnly: field.filterSuggestsExhaustive,
      });
    }
  };

  const onOpSelect = (v) => setState({ ...state, filterOp: v });
  const onValueChange = (v) => setState({ ...state, value: v });

  return (
    <InputWrapper>
      <DropDownSearch
        value={state.fieldId}
        options={fields.map((f) => ({ value: f.fieldId, display: f.label }))}
        name="filter"
        placeholder="Select Filter"
        onChange={onFilterSelect}
        width="12rem"
      />
      <DropDownInput
        value={state.filterOp}
        options={operations}
        name="filterOp"
        onChange={onOpSelect}
        width="8rem"
      />
      <InputSelector
        suggestOnly={selectedField.suggestOnly}
        type={selectedField.dataType}
        filterOp={state.filterOp}
        inputData={{
          options: selectedField.options,
          value: state.value,
          placeholder: 'Enter Value',
          onChange: onValueChange,
          width: '15rem',
        }}
      />
      <IconWrapper onClick={() => handleSave(state)}>
        <SvgIcon type="check" />
      </IconWrapper>
      <IconWrapper onClick={handleClose}>
        <SvgIcon type="close" />
      </IconWrapper>
    </InputWrapper>
  );
};

export default FilterInput;

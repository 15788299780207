import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { ActionDialog } from 'components/molecules';

type ResidenceTableRowButtonProps = {
  name: string;
  approved: boolean;
  handleRevoke: () => void;
  handleApprove: () => void;
  isUpdating: boolean;
};

export const ResidenceTableRowButton: React.FC<ResidenceTableRowButtonProps> = ({
  name,
  approved,
  handleRevoke,
  handleApprove,
  isUpdating,
}) => {
  const [open, setOpen] = useState(false);
  const [localUpdating, setLocalUpdating] = useState(false);

  const handleDialogSubmit = () => {
    if (approved) {
      handleRevoke();
    } else {
      handleApprove();
    }
    setOpen(false);
    setLocalUpdating(true);
  };

  useEffect(() => {
    if (localUpdating && !isUpdating) {
      setLocalUpdating(false);
    }
  }, [isUpdating, localUpdating]);

  return (
    <>
      <Tooltip
        arrow
        placement="top"
        title={approved ? 'Remove resident access' : 'Approve resident request'}
      >
        {approved ? (
          <IconButton onClick={() => setOpen(true)} disabled={localUpdating}>
            {localUpdating && <CircularProgress size={22} />}
            {!localUpdating && <ClearIcon fontSize="large" color="error" />}
          </IconButton>
        ) : (
          <IconButton onClick={() => setOpen(true)} disabled={localUpdating}>
            {localUpdating && <CircularProgress size={22} />}
            {!localUpdating && <CheckIcon fontSize="large" color="success" />}
          </IconButton>
        )}
      </Tooltip>
      <ActionDialog
        open={open}
        onClose={() => setOpen(false)}
        handleSubmit={handleDialogSubmit}
        title={approved ? 'Remove resident access' : 'Approve resident request'}
        submitButtonName={approved ? 'Remove' : 'Approve'}
      >
        <span>
          Are you sure you want to{' '}
          {approved ? `remove resident ${name} access` : `approve resident ${name} request`}?
        </span>
      </ActionDialog>
    </>
  );
};

import { createSelector } from 'reselect';
import { DeviceTypes } from 'types/DeviceTypes/Device';
import {
  TenantDeviceSummary,
  DeviceDeploymentCounts,
  DeviceCounts,
} from 'types/TenantInfo/deviceSummary';
import {
  isEmergencyLight,
  isBoiler,
  isAutofill,
  isAirSourceHeatPump,
  isMultiDot,
  isEnviroDot,
  isHeatInterfaceUnit,
  isPowerMeter,
} from 'utils/deviceUtils';
import { createThunkEffect } from 'utils/ReduxUtils';

import { TenantInfo } from '../../types/TenantInfo/tenantInfo';

const FETCH_TENANTINFO = 'FETCH_TENANTINFO';
const FETCH_TESTSCHEDULE = 'FETCH_TESTSCHEDULE';
const FETCH_TENANTEQUIPMENT = 'FETCH_TENANTEQUIPMENT';
const FETCH_TENANTEMEREDS = 'FETCH_TENANTEMEREDS';
const CLEAR_TENANT = 'CLEAR_TENANT';
const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';
const FETCH_BUILDING_SUMMARY = 'FETCH_BUILDING_SUMMARY';

export const tenantInfoTypes = {
  FETCH_TENANTINFO,
  FETCH_TESTSCHEDULE,
  FETCH_TENANTEQUIPMENT,
  FETCH_TENANTEMEREDS,
  CLEAR_TENANT,
  FETCH_DASHBOARD_DATA,
  FETCH_BUILDING_SUMMARY,
};

export const fetchTenantInfo = () => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_TENANTINFO, 'GetTenantInfo', {});

export const fetchTestSchedules = () => {
  const deviceTypes = [DeviceTypes.EmergencyLighting, DeviceTypes.EmergencyLightingLegacy];

  return async (dispatch) =>
    await createThunkEffect(dispatch, FETCH_TESTSCHEDULE, 'GetTenantEmeredTestShedules', {
      deviceTypes,
    });
};

export const fetchTenantDashboardData = () => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_DASHBOARD_DATA, 'GetTenantDashboardData', {});

export const fetchTenantBuildingSummary = () => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_BUILDING_SUMMARY, 'GetTenantBuildingSummary', {});

export const fetchTenantEmereds = () => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_TENANTEMEREDS, 'GetTenantEmereds', {});

export const clearTenant = () => ({ type: CLEAR_TENANT });

interface TenantInitialState {
  tenantInfo: TenantInfo;
  emeredTestSchedules: any[];
  deviceSummary: TenantDeviceSummary;
  buildingSummaries: { [buildingId: string]: DeviceCounts } | undefined;
  deviceEquipment: {
    inverterTypes: any[];
    lampTypes: any[];
    batteryTypes: any[];
    batteryCapacity: any[];
    batteryVoltage: any[];
  };
  emereds: any[];
}

const initSummary: DeviceDeploymentCounts = {
  total: 0,
  pass: 0,
  fail: 0,
  warn: 0,
  inventory: 0,
  installed: 0,
};

const initialState: TenantInitialState = {
  tenantInfo: {
    id: '',
    name: '',
    contacts: '',
    settings: null,
  },
  emeredTestSchedules: [],
  deviceSummary: {
    emeredSummary: initSummary,
    boilerSummary: initSummary,
    airSourceHeatPumpSummary: initSummary,
    heatInterfaceUnitSummary: initSummary,
    autofillSummary: initSummary,
    multiDotSummary: initSummary,
    enviroDotSummary: initSummary,
    powerMeterSummary: initSummary,
  },
  buildingSummaries: undefined,
  deviceEquipment: {
    inverterTypes: [],
    lampTypes: [],
    batteryTypes: [],
    batteryCapacity: [],
    batteryVoltage: [],
  },
  emereds: [],
};

export const tenantInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${tenantInfoTypes.FETCH_TENANTINFO}_SUCCESS`:
      return {
        ...state,
        tenantInfo: action.payload,
      };
    case `${tenantInfoTypes.FETCH_TESTSCHEDULE}_SUCCESS`:
      return {
        ...state,
        emeredTestSchedules: action.payload,
      };
    case `${tenantInfoTypes.FETCH_DASHBOARD_DATA}_SUCCESS`: {
      return {
        ...state,
        deviceSummary: {
          ...action.payload,
        },
      };
    }
    case `${tenantInfoTypes.FETCH_BUILDING_SUMMARY}_SUCCESS`: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case `${tenantInfoTypes.FETCH_TENANTEQUIPMENT}_SUCCESS`:
      return {
        ...state,
        deviceEquipment: action.payload,
      };
    case `${tenantInfoTypes.FETCH_TENANTEMEREDS}_SUCCESS`:
      return {
        ...state,
        emereds: action.payload,
      };
    case tenantInfoTypes.CLEAR_TENANT:
      return initialState;
    default:
      return state;
  }
};

export const selectSummary = (state: any) => state.tenantInfo.deviceSummary;
export const selectStateBuildingSummaries = (state: any) => state.tenantInfo.buildingSummaries;

const getDeviceTypeSummary = (deviceType) => {
  if (isEmergencyLight(deviceType)) return 'emeredSummary';
  if (isBoiler(deviceType)) return 'boilerSummary';
  if (isAutofill(deviceType)) return 'autofillSummary';
  if (isAirSourceHeatPump(deviceType)) return 'airSourceHeatPumpSummary';
  if (isMultiDot(deviceType)) return 'multiDotSummary';
  if (isEnviroDot(deviceType)) return 'enviroDotSummary';
  if (isHeatInterfaceUnit(deviceType)) return 'heatInterfaceUnitSummary';
  if (isPowerMeter(deviceType)) return 'powerMeterSummary';

  return '';
};

export const selectDeviceSummary = (deviceType) =>
  createSelector(selectSummary, (summary) => summary[getDeviceTypeSummary(deviceType)]);

export const selectBuildingSummaries = createSelector(
  selectStateBuildingSummaries,
  (buildingSummaries) => buildingSummaries
);

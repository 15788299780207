import { useEffect, useState } from 'react';

import { Device } from 'types/DeviceTypes/Device';
import { EmeredDevice } from 'types/EmeredTypes';
import { AccessLevel, Areas } from 'types/roles';

import useAuth from 'utils/hooks/useAuth';

type UseTabsProps = {
  device: Device | EmeredDevice;
  tabsData: {
    tab: string;
    display: string;
    component: any;
    access: any;
    limitedAccessTab?: boolean;
  }[];
};

export const useTabs = ({ tabsData, device }: UseTabsProps) => {
  const [tabs, setTabs] = useState(tabsData);
  const { isAuthenticated, user } = useAuth();

  const isDeveloper = user?.hasAccess(AccessLevel.Dev, Areas.all);
  const userHasReadOnlyAccess = device?.userHasReadOnlyAccess;

  useEffect(() => {
    if (isAuthenticated && user) {
      setTabs(tabsData.filter((tab) => !(userHasReadOnlyAccess && tab.limitedAccessTab)));
    }
  }, [user, isAuthenticated, tabsData, userHasReadOnlyAccess]);

  return isDeveloper ? tabsData : tabs;
};

import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { displayValue, setValue } from 'utils/helpers';

const DropDownWrapper = styled.div<{ state: string }>`
  position: relative;
  display: inline-block;
  width: 100%;

  & > div {
    border-radius: 20px;
    width: 100%;
    position: relative;
    display: inline-block;
    background-color: ${(props) => props.theme.offWhite};
    cursor: pointer;
    box-shadow: ${(props) => {
    if (props.state) {
      return props.state === 'error'
        ? `0 0 4px 1px ${props.theme.error}`
        : `0 0 4px 1px ${props.theme.success}`;
    }

    return 'none';
  }};

    &:after {
      content: '';
      position: absolute;
      background: transparent;
      top: 32%;
      right: 1.5rem;
      width: 0.75rem;
      height: 0.75rem;
      border-right: 2px solid ${(props) => props.theme.blue};
      border-bottom: 2px solid ${(props) => props.theme.blue};
      transform: rotate(45deg);
      transform-origin: 50% 50%;
      z-index: 1;
    }
  }

  & input {
    ${(props) => props.theme.inputStyles};
    background-color: transparent;
    z-index: 6;
    width: 100%;
    ${(props) => {
    if (props.state) return 'border-color: transparent';
  }};
  }

  & ul {
    display: none;
    position: absolute;
    left: 0;
    max-height: 20rem;
    overflow: auto;

    background: ${(props) => props.theme.white};
    width: 100%;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 6;

    li {
      padding: 0.5rem 1rem;

      &:hover,
      &.selected {
        cursor: pointer;
        background-color: ${(props) => props.theme.default};
      }
    }

    &.open {
      display: block;
    }
  }
`;

const focusInput = (e) => {
  if (e.target.nodeName === 'DIV') {
    e.target.querySelector('input').focus();
  }
};

const Dropdown = ({
  value,
  name,
  options,
  onChange,
  placeholder,
  state = '',
  readOnly = false,
}) => {
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([] as any);
  const [selectedIndex, setSelectedIndex] = useState(placeholder ? -1 : 0);

  useEffect(() => {
    setFilteredData(options);
    setSelectedIndex(options.findIndex((v) => v.value === value || v === value));
  }, [options, value]);

  const handleOnClick = (e) => {
    const ele = e.target;
    setFilteredData(options);
    setSelectedIndex(options.findIndex((v) => v.value === ele.dataset.value));
    ele.parentNode.parentNode.querySelector('input').value = ele.innerText;
    ele.parentNode.parentNode.querySelector('input').dataset.value = ele.dataset.value;
    onChange(ele.dataset.value, ele.dataset.name);
  };

  const handleKeyPress = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        if (selectedIndex !== -1) {
          setSelectedIndex(selectedIndex - 1);
        }
        break;
      case 'ArrowDown':
        if (selectedIndex < filteredData.length - 1) {
          setSelectedIndex(selectedIndex + 1);
        }
        break;
      case 'Escape':
        setOpen(false);
        break;
      case 'Enter':
        if (!open) {
          setOpen(true);
        } else {
          const ele = e.target;
          const li = ele.parentNode.parentNode.querySelector('li.selected');
          ele.value = li.innerText;
          ele.dataset.value = li.dataset.value;
          setOpen(false);
          onChange(li.dataset.value, li.dataset.name);
        }
        break;

      default:
        break;
    }
  };

  return (
    <DropDownWrapper state={state}>
      <div
        onClick={(e) => focusInput(e)}
        onKeyDown={(e) => focusInput(e)}
        role="button"
        tabIndex={0}
      >
        <input
          autoComplete="off"
          name={name}
          onKeyDown={(e) => handleKeyPress(e)}
          onClick={() => setOpen(true)}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          value={displayValue(value, options)}
          data-value={setValue(value, options)}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      </div>
      <ul className={open ? 'open' : ''}>
        {placeholder && (
          <li
            role="presentation"
            onMouseDown={handleOnClick}
            className={selectedIndex === -1 ? 'selected' : ''}
            data-name={name}
            data-value=""
          >
            {placeholder}
          </li>
        )}
        {filteredData.map((v, i) =>
          typeof v === 'string' || typeof v === 'number' ? (
            <li
              role="presentation"
              onMouseDown={handleOnClick}
              className={i === selectedIndex ? 'selected' : ''}
              key={i}
              data-name={name}
              data-value={v}
            >
              {v}
            </li>
          ) : (
            <li
              role="presentation"
              onMouseDown={handleOnClick}
              className={i === selectedIndex ? 'selected' : ''}
              key={i}
              data-name={name}
              data-value={v.value}
            >
              {v.display}
            </li>
          )
        )}
      </ul>
    </DropDownWrapper>
  );
};
export default Dropdown;

import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';

import { Axis } from '@visx/axis';
import * as allCurves from '@visx/curve';
import { localPoint } from '@visx/event';
import { scaleLinear, scaleTime } from '@visx/scale';
import { LinePath, Line, Bar } from '@visx/shape';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { min, max, bisector } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import moment from 'moment';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import styled from 'styled-components';

import { useChartContext, useChartUpdateContext } from '../../Chart/ChartContext';
import { Card, DataProps } from '../../Configs/TelemetryConfigs';

type CurveType = keyof typeof allCurves;

const LineGraphWrapper = styled.div`
  padding-top: 5px;
`;

const CustomTooltip = styled.div`
  display: flex;
  justify-items: space-between;
  align-items: center;
`;

const TooltipMarker = styled.div<{ lineColor: string }>`
  width: 5px;
  height: 20px;
  background-color: ${(props) => props.lineColor};
  margin-right: 5px;
`;

interface DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export type CurveProps = {
  graphWidth: number;
  graphHeight: number;
  cardConfig: Card;
  dateRange: DateRange;
};

interface TooltipMetaObj {
  xPosition: number;
  yPosition: number;
  dateFromXAxis: Date;
  index: number;
  data: DataProps;
}

// const formatDate = timeFormat("%b %d, '%y");
const formatDateTime = timeFormat('%d %b %H:%M');

const LineGraph = ({ cardConfig, graphHeight, graphWidth, dateRange }: CurveProps) => {
  const chartContext = useChartContext();
  const chartUpdateContext = useChartUpdateContext();

  const { tooltipData, tooltipLeft, tooltipTop, showTooltip } = useTooltip();

  const { TooltipInPortal, containerRef, containerBounds } = useTooltipInPortal({
    detectBounds: true,
    debounce: 6,
    scroll: true,
    polyfill: ResizeObserverPolyfill,
  });

  const curveType: CurveType = 'curveStepAfter';
  const dataSeries = cardConfig.dataSeries[0].data;
  const unit = cardConfig.dataSeries[0].data.unit;

  const [isOffScreen, setIsOffScreen] = useState<boolean | undefined>();

  const metricTooltipRef = useRef<HTMLDivElement>(null);

  const lineColor = cardConfig.dataSeries[0].color ?? '#0088FF';
  const paddingX: number = 5;
  const paddingY: number = 30;

  const offScreenOffset: number = isOffScreen ? paddingY + 10 : 0;

  const getX = (data: any) => data.date;
  const getY = (data: any) => data.value;

  // Scaling X Axis
  const dateScale = useMemo(
    () =>
      scaleTime({
        // domain: extent(dataSeries.series, getX) as [Date, Date],
        domain: [
          moment(dateRange.startDate).startOf('day').toDate(),
          moment(dateRange.endDate).endOf('day').toDate(),
        ],
        range: [0 + paddingX, graphWidth - paddingX],
        clamp: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRange.startDate, dateRange.endDate, dataSeries, graphWidth]
  );

  // Scaling Y Axis
  const minConfig = cardConfig.dataSeries[0].rangeMin;
  const minSeries = min(dataSeries.series, getY);
  const domainMin = minConfig !== null ? Math.min(minConfig, minSeries) : minSeries ?? 0;
  const maxConfig = cardConfig.dataSeries[0].rangeMax;
  const maxSeries = max(dataSeries.series, getY);
  const domainMax = maxConfig !== null ? Math.max(maxConfig, maxSeries) : maxSeries ?? 0;

  const metricScale = useMemo(
    () =>
      scaleLinear({
        domain: [domainMin as number, domainMax as number],
        range: [graphHeight - paddingY, paddingY],
        clamp: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [domainMax, graphHeight]
  );

  useEffect(() => {
    if (!isOffScreen) {
      if (containerBounds.y + paddingY > window.innerHeight) {
        setIsOffScreen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerBounds]);

  useEffect(() => {
    if (containerBounds.y + paddingY > window.innerHeight) {
      setIsOffScreen(true);
    } else {
      setIsOffScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerHeight]);

  const bisectData = bisector<DataProps, Date>((d) => new Date(d.date)).left;

  const getTooltipData = (
    selectedDate: moment.Moment,
    series: Array<DataProps>,
    modifier: string
  ): TooltipMetaObj => {
    const { xPosition } = { xPosition: dateScale(selectedDate) } || { xPosition: 0 };
    const dateFromXAxis = dateScale.invert(xPosition);
    const index = bisectData(series, dateFromXAxis, 1);

    const previousPoint = series[index - 1];
    const currentPoint = series[index];

    let data;

    //To match up with the curve type "curveStepAfter" the previous value should be shown.
    //"curveStepBefore" uses the current value.
    switch (modifier) {
      case 'earliest':
        data = previousPoint ? previousPoint : currentPoint;
        break;
      case 'middle':
        data = previousPoint ? previousPoint : currentPoint;
        break;
      case 'latest':
        //prioritize the current point for the last point or it will always default to previous.
        data = currentPoint ? currentPoint : previousPoint;
        break;
      default:
        data = previousPoint ? previousPoint : currentPoint;
        break;
    }

    const yPosition = metricScale(getY(data));
    const tooltipMeta = {
      xPosition,
      yPosition,
      dateFromXAxis,
      index,
      data,
    };

    return tooltipMeta;
  };

  useEffect(() => {
    if (dataSeries.series) {
      if (dataSeries.series.length > 0 && chartContext.selectedDateTime) {
        //Get current cursor selection tooltip position and data.
        const tooltipObj = getTooltipData(
          moment(chartContext.selectedDateTime),
          dataSeries.series,
          'middle'
        );
        const earliestDateInSeries = min(dataSeries.series, getX);
        const latestDateInSeries = max(dataSeries.series, getX);

        if (tooltipObj.data) {
          if (moment(tooltipObj.dateFromXAxis).isBefore(earliestDateInSeries)) {
            //In the case where the cursor selects an earlier date than the earliest in the data set.
            const earliestDateTooltipObj = getTooltipData(
              earliestDateInSeries,
              dataSeries.series,
              'earliest'
            );

            showTooltip({
              tooltipData: earliestDateTooltipObj.data,
              tooltipLeft: earliestDateTooltipObj.xPosition,
              tooltipTop: earliestDateTooltipObj.yPosition,
            });
          } else if (moment(tooltipObj.dateFromXAxis).isAfter(latestDateInSeries)) {
            //In the case where the cursor selects a later date than the latest in the data set.
            const latestDateTooltipObj = getTooltipData(
              latestDateInSeries,
              dataSeries.series,
              'latest'
            );

            showTooltip({
              tooltipData: latestDateTooltipObj.data,
              tooltipLeft: latestDateTooltipObj.xPosition,
              tooltipTop: latestDateTooltipObj.yPosition,
            });
          } else {
            //In the case where there is a valid data value, behave as normal (Middle).
            showTooltip({
              tooltipData: tooltipObj.data,
              tooltipLeft: tooltipObj.xPosition,
              tooltipTop: tooltipObj.yPosition,
            });
          }
        } else {
          // In the case that the data value is undefined default to the latest value
          const latestDateTooltipObj = getTooltipData(
            latestDateInSeries,
            dataSeries.series,
            'latest'
          );
          showTooltip({
            tooltipData: latestDateTooltipObj.data,
            tooltipLeft: latestDateTooltipObj.xPosition,
            tooltipTop: latestDateTooltipObj.yPosition,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContext]);

  // Tooltip handler
  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const { x } = localPoint(event) || { x: 0 };
      const dateFromXAxis = dateScale.invert(x);

      chartUpdateContext({
        ...chartContext,
        selectedDateTime: dateFromXAxis,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bisectData, chartUpdateContext, dataSeries.series, dateScale]
  );

  const handleTooltipDefault = () => {
    const endOfDay = moment().endOf('day');
    chartUpdateContext({
      ...chartContext,
      selectedDateTime: endOfDay.toDate(),
    });
  };

  const handleTooltipPause = () => {
    chartUpdateContext({
      ...chartContext,
      pauseSelection: !chartContext.pauseSelection,
    });
  };

  const getDateTimeTooltipXPosition: () => number = (): number => {
    const containerExtentPosition = Math.floor(containerBounds.right - containerBounds.left - 100);
    if (tooltipLeft) {
      if (tooltipLeft > 50) {
        if (tooltipLeft <= containerExtentPosition + 50) {
          return tooltipLeft - 60;
        } else if (tooltipLeft >= containerExtentPosition) {
          return tooltipLeft - 100;
        } else {
          return tooltipLeft;
        }
      } else {
        return tooltipLeft - 10;
      }
    } else {
      return 0;
    }
  };

  const getMetricTooltipXPosition: () => number = (): number => {
    if (tooltipLeft) {
      const containerExtentPosition = Math.floor(
        containerBounds.right - containerBounds.left - 100
      );
      const metricTooltipWidth: number =
        (metricTooltipRef.current && metricTooltipRef.current.clientWidth) || 0;

      if (tooltipLeft > 50) {
        if (tooltipLeft <= containerExtentPosition + 50) {
          return tooltipLeft - metricTooltipWidth / 2 - 20;
        } else {
          return tooltipLeft - metricTooltipWidth - 30;
        }
      } else {
        return tooltipLeft - 10;
      }
    } else {
      return 0;
    }
  };

  const getNumTicks = () => {
    if (graphWidth > 1000) {
      return 9;
    }

    return graphWidth > 600 ? 6 : 4;
  };

  return (
    <LineGraphWrapper ref={containerRef}>
      <svg width={graphWidth} height={graphHeight}>
        <rect
          x={0}
          y={0}
          width={graphWidth > 0 ? graphWidth : 0}
          height={graphHeight > 0 ? graphHeight : 0}
          fill="transparent"
        />

        <Axis
          scale={dateScale}
          top={graphHeight - paddingY}
          orientation="bottom"
          stroke="darkgrey"
          strokeWidth={1}
          tickStroke="darkgrey"
          numTicks={getNumTicks()}
          tickLabelProps={() => ({
            fill: 'grey',
            textAnchor: 'start',
            verticalAnchor: 'middle',
            fontSize: '0.9em',
            transform: `translate(${-paddingX})`,
          })}
        />

        <Axis
          hideZero
          scale={metricScale}
          left={paddingX}
          orientation="right"
          numTicks={3}
          stroke={'darkGrey'}
          strokeWidth={1}
          tickStroke={'darkGrey'}
          tickLabelProps={() => ({
            fill: 'grey',
            verticalAnchor: 'middle',
            fontSize: '0.9em',
          })}
          tickFormat={(value) => `${value}`}
        />

        {dataSeries.series && (
          <LinePath
            curve={allCurves[curveType]}
            data={dataSeries.series}
            x={(d) => dateScale(getX(d)) ?? 0}
            y={(d) => metricScale(getY(d)) ?? 0}
            stroke={lineColor}
            fill="transparent"
            strokeWidth={3}
            strokeOpacity={1}
            shapeRendering="geometricPrecision"
          />
        )}
        <Bar
          x={paddingX}
          y={paddingY}
          width={graphWidth - paddingX * 2}
          height={graphHeight - paddingY * 2}
          fill="transparent"
          rx={14}
          onTouchStart={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onTouchMove={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onMouseMove={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onMouseLeave={() => !chartContext.pauseSelection && handleTooltipDefault()}
          onClick={() => handleTooltipPause()}
        />

        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: paddingY - 10 }}
              to={{ x: tooltipLeft, y: graphHeight - paddingY }}
              stroke={'#666'}
              strokeWidth={2}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={5}
              fill={'#666'}
              stroke="white"
              strokeWidth={2}
              pointerEvents="none"
            />
          </g>
        )}
      </svg>
      {tooltipData && (
        <div className="tooltipcontainerbla">
          <TooltipInPortal
            key={Math.random()}
            top={offScreenOffset > 0 ? offScreenOffset - 10 : -15}
            left={getMetricTooltipXPosition()}
            style={{
              ...defaultStyles,
              textAlign: 'right',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: 'black',
            }}
          >
            <CustomTooltip ref={metricTooltipRef}>
              <TooltipMarker lineColor={lineColor}></TooltipMarker>
              {`${getY(tooltipData) !== undefined ? getY(tooltipData).toFixed(2) : ' - '} ${unit}`}
            </CustomTooltip>
          </TooltipInPortal>

          <TooltipInPortal
            key={Math.random()}
            top={offScreenOffset + graphHeight - paddingY - 2}
            left={getDateTimeTooltipXPosition()}
            style={{
              ...defaultStyles,
              textAlign: 'center',
              minWidth: '72px',
              color: 'black',
            }}
          >
            {`${formatDateTime(getX(tooltipData))}`}
          </TooltipInPortal>
        </div>
      )}
    </LineGraphWrapper>
  );
};

export default LineGraph;

import React from 'react';
import { GridActionsCellItem, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import { TbPinnedOff, TbPin } from 'react-icons/tb';

type DataGridRowPinProps = {
  params: GridRowParams;
  setPinnedRowsIds: (prev) => void;
  pinnedRowsIds: { top: GridRowId[] };
};

export const DataGridRowPin: React.FC<DataGridRowPinProps> = ({
  params,
  setPinnedRowsIds,
  pinnedRowsIds,
}) => {
  const isPinnedTop = pinnedRowsIds.top.includes(params.id);
  const isPinDisabled = !isPinnedTop && pinnedRowsIds.top.length >= 5;

  const handlePinClick = () =>
    setPinnedRowsIds((prevPinnedRowsIds) => ({
      top: isPinnedTop
        ? prevPinnedRowsIds.top.filter((rowId) => rowId !== params.id)
        : [...prevPinnedRowsIds.top, params.id],
    }));

  return (
    <GridActionsCellItem
      label="Pin"
      icon={isPinnedTop ? <TbPinnedOff /> : <TbPin />}
      onClick={handlePinClick}
      disabled={isPinDisabled}
    />
  );
};

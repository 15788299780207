import styled from 'styled-components';

export const LineGraphWrapper = styled.div`
  padding-top: 5px;
  position: relative;
`;

export const CustomTooltip = styled.div`
  display: flex;
  justify-items: space-between;
  align-items: center;
`;

export const TooltipMarker = styled.div<{ lineColor: string }>`
  width: 5px;
  height: 20px;
  background-color: ${(props) => props.lineColor};
  margin-right: 5px;
`;

import { GridColDef, GridRowId, GridValidRowModel } from '@mui/x-data-grid-pro';
import { DataGridRowPin } from 'components/atoms';

type AddDataGridPinColumnProps = {
  pinnedRowsIds: { top: GridRowId[] };
  setPinnedRowsIds: (prev) => void;
  columnsList: GridColDef[];
};

export const addDataGridPinColumn = ({
  pinnedRowsIds,
  setPinnedRowsIds,
  columnsList,
}: AddDataGridPinColumnProps): GridColDef<GridValidRowModel>[] => [
  {
    field: 'actions',
    type: 'actions',
    disableReorder: true,
    hideable: false,
    width: 50,
    getActions: (params) => [
      <DataGridRowPin
        key={params.id}
        params={params}
        pinnedRowsIds={pinnedRowsIds}
        setPinnedRowsIds={setPinnedRowsIds}
      />,
    ],
  },
  ...columnsList,
];

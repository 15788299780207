import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import axios from 'axios';

import { ApiErrorsType, ApiMethod } from 'types';
import { getToken } from 'utils/contexts/AuthProvider';
import { handleReduxApiError } from 'utils/redux/handleReduxApiError';

type HandleReduxToolkitApiRequestProps = {
  method: ApiMethod;
  url: string;
  rejectWithValue: (error) => void;
  onError?: (error: ApiErrorsType) => void;
  dispatch: ThunkDispatch<unknown, unknown, Action<unknown>>;
  data?: any;
  enableErrorHandler?: boolean;
};

export const handleReduxToolkitApiRequest = async ({
  method,
  url,
  rejectWithValue,
  data,
  dispatch,
  onError,
  enableErrorHandler,
}: HandleReduxToolkitApiRequestProps) => {
  const handleError = handleReduxApiError({ dispatch });

  try {
    const token = await getToken();

    const requestConfig = {
      url,
      method,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...(!!data && { data }),
    };

    const response = await axios(requestConfig);

    return response;
  } catch (e) {
    const error = e as ApiErrorsType;

    if (onError) {
      onError(error);
    }

    if (enableErrorHandler) {
      handleError(error);
    }

    throw rejectWithValue(error);
  }
};

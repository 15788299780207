import React from 'react';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';

type InputEndIconType = {
  isError: boolean;
  value: string | boolean;
  isNotFocused: boolean;
};

export const InputEndIcon: React.FC<InputEndIconType> = ({ isError, value, isNotFocused }) =>
  isError ? (
    <ErrorOutline
      style={!isNotFocused ? { color: 'transparent' } : {}}
      color={isNotFocused ? 'error' : undefined}
    />
  ) : (
    <CheckCircleOutline
      style={!value ? { color: 'transparent' } : {}}
      color={value ? 'success' : undefined}
    />
  );

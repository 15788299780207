import React, { useState, useEffect } from 'react';

import useProfile from 'utils/hooks/useProfile';
import { reports } from './constants/reportConstant';
import { ReportCard } from './molecules';
import { ReportWrapper } from './Reporting.styles';
import { Report, ReportingProps } from './types';

export const Reporting: React.FC<ReportingProps> = () => {
  const profile = useProfile();
  const [activeReports, setActiveReports] = useState<Array<Report>>([]);

  useEffect(() => {
    const deviceTypes = profile.role?.devices;

    if (deviceTypes && deviceTypes?.length > 0) {
      const allowedReports = reports.filter((report) =>
        report.deviceType.every((device) => deviceTypes.includes(device))
      );

      setActiveReports(allowedReports);
    }
  }, [profile]);

  return (
    <ReportWrapper>
      <h1 className="report__heading">Report Manager</h1>
      {activeReports.length && (
        <div className="report__board">
          <div className="report__board-area">
            {activeReports.map((reportData, reportIndex) =>
              reportData.hidden ? (
                <div key={reportIndex.toString()} />
              ) : (
                <ReportCard
                  tenantID={profile?.selectedTenant || ''}
                  key={reportIndex.toString()}
                  {...reportData}
                />
              )
            )}
          </div>
        </div>
      )}
      {!activeReports.length && <p>No reports</p>}
    </ReportWrapper>
  );
};

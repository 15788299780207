import { getEngAppUrl } from 'utils/deviceUtils';

import { DeviceActionProps } from '../DeviceActionProps';

const RedirectToApp = ({ device, handleResetAction }: DeviceActionProps) => {
  handleResetAction();
  window.open(getEngAppUrl(device), '_blank');

  return null;
};

export default RedirectToApp;

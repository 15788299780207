import styled from 'styled-components';

export const AccessRightsTagsWrapper = styled.section`
  .access-rights-tags {
    &__top {
      display: flex;
      min-height: 40px;
      margin-top: 24px;
      margin-bottom: 32px;
      flex-direction: column;
      gap: 20px;

      @media (min-width: 768px) {
        align-items: center;
        gap: 0;
        flex-direction: row;
        align-items: center;
      }
    }

    &__title {
      font-size: 1.44rem;
      margin: 0;
      padding-right: 24px;
    }
  }
`;

import React, { useState, useEffect } from 'react';

import MapStyles from 'components/FloorPlan/MapStyles';
import ImageOverlay from 'components/FloorPlan/Overlays/ImageOverlay';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import useDimensions from 'utils/hooks/useDimensions';
import { initImgDims, initMap } from 'utils/mapUtils';

import DeviceMarker from './DeviceMarker';

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const MapContainer = ({
  selectedBuilding,
  floor,
  devices,
  handleDeviceClick,
  selectedDevice,
  selectedGroup,
}) => {
  const [mapRef, mapDimensions] = useDimensions();
  const [drag] = useState(true);
  const [mapSettings, setMapSettings] = useState(initMap(selectedBuilding, devices, mapDimensions));
  const [imgDims, setImgDims] = useState(initImgDims(floor, mapSettings.zoom));

  useEffect(() => {
    setMapSettings(initMap(selectedBuilding, devices, mapDimensions));
    // TODO REFACTOR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding, mapDimensions]);

  useEffect(() => {
    if (floor) {
      setImgDims(initImgDims(floor, mapSettings.zoom));
    }
  }, [floor, mapSettings.zoom]);

  const _onChange = ({ center, zoom }) => {
    setMapSettings({
      center,
      zoom,
    });
  };

  return (
    <>
      {mapSettings && (
        <MapWrapper ref={mapRef}>
          {!isNaN(mapSettings.zoom) && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS }}
              draggable={drag}
              onChange={_onChange}
              center={mapSettings.center}
              zoom={mapSettings.zoom}
              hoverDistance={15}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
                // mapTypeControlOptions: {
                //     position: 7,
                //     mapTypeIds: ['roadmap', 'satellite']
                // },
                rotateControl: false,
                tilt: 0,
                styles: MapStyles,
                gestureHandling: 'greedy',
              }}
            >
              {Object.keys(devices).reduce(
                (acc, cur) =>
                  acc.concat(
                    devices[cur].map(
                      (d) =>
                        d.floor === floor.name && (
                          <DeviceMarker
                            selected={d.deviceId === selectedDevice}
                            conflict={selectedGroup && d.group === selectedGroup}
                            key={d.deviceId}
                            handleDeviceClick={handleDeviceClick}
                            device={d}
                            groupStatus={cur}
                            lat={d.lat}
                            lng={d.long}
                          />
                        )
                    )
                  ),
                []
              )}

              <ImageOverlay
                lat={floor.lat}
                lng={floor.lng}
                width={imgDims.w}
                height={imgDims.h}
                rotation={floor.rotation}
                image={floor.image}
              />
            </GoogleMapReact>
          )}
        </MapWrapper>
      )}
    </>
  );
};

export default MapContainer;

import React, { useEffect, useState } from 'react';

import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import useDimensions from 'utils/hooks/useDimensions';
import { initImgDims, initMap } from 'utils/mapUtils';

import MapStyles from '../MapStyles';
import DeviceMarker from '../Markers/DeviceMarker';
import ImageOverlay from '../Overlays/ImageOverlay';

const MapWrapper = styled.div`
  width: 100%;
  height: 60vh;
  @media ${(props) => props.theme.mobileL} {
    height: 50vh;
  }
  position: relative;
`;

const DeviceRepositioningMap = ({
  device,
  selectedBuilding,
  selectedFloor,
  clusters,
  deviceCoOrds,
  setDeviceCoOrds,
}) => {
  const [mapRef, mapDimensions] = useDimensions();
  const [mapSettings, setMapSettings] = useState(
    initMap(selectedBuilding, clusters, mapDimensions)
  );
  const [imgDims, setImgDims] = useState(initImgDims(selectedFloor, mapSettings.zoom));

  const _onChange = ({ center, zoom }) => {
    setMapSettings({
      center,
      zoom,
    });
  };

  useEffect(() => {
    if (selectedFloor) {
      setImgDims(initImgDims(selectedFloor, mapSettings.zoom));
    }
  }, [selectedFloor, mapSettings.zoom]);

  const handleOnClick = (e) => {
    setDeviceCoOrds({
      lat: e.lat,
      long: e.lng,
    });
  };

  const onMapChildClick = (_hoverKey, childProps) => {
    if (childProps.name === 'FloorplanImage') {
      setDeviceCoOrds({
        lat: childProps.lat,
        long: childProps.lng,
      });
    }
  };

  return (
    <>
      {mapRef !== undefined && process.env.REACT_APP_GOOGLE_MAPS !== undefined && (
        <MapWrapper ref={mapRef}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS }}
            draggable
            onChange={_onChange}
            onClick={handleOnClick}
            onChildClick={onMapChildClick}
            center={mapSettings.center}
            zoom={mapSettings.zoom}
            hoverDistance={30}
            options={{
              mapTypeControl: true,
              fullscreenControl: false,
              mapTypeControlOptions: {
                position: 7,
                mapTypeIds: ['roadmap', 'satellite'],
              },
              rotateControl: false,
              tilt: 0,
              styles: MapStyles,
              gestureHandling: 'greedy',
            }}
          >
            <DeviceMarker
              name="DeviceMarker"
              key={device.deviceId}
              device={device}
              lat={deviceCoOrds.lat}
              lng={deviceCoOrds.long}
              isEdit
            />

            <ImageOverlay
              name="FloorplanImage"
              lat={selectedFloor.lat}
              lng={selectedFloor.lng}
              width={imgDims.w}
              height={imgDims.h}
              rotation={selectedFloor.rotation}
              image={selectedFloor.image}
            />
          </GoogleMapReact>
        </MapWrapper>
      )}
    </>
  );
};

export default DeviceRepositioningMap;

export enum DeviceCapabilities {
  EditAccessRightsTags = 'EditAccessRightsTags',
}

export enum PropertyCapabilities {
  ViewProperties = 'ViewProperties',
}

export enum CapabilitiesNames {
  Devices = 'Devices',
  Properties = 'Properties',
}

type CapabilityMap = {
  [CapabilitiesNames.Devices]: DeviceCapabilities;
  [CapabilitiesNames.Properties]: PropertyCapabilities;
};

export type Capabilities = {
  [K in keyof CapabilityMap]: CapabilityMap[K][];
};

export const CapabilitiesList: Capabilities = {
  [CapabilitiesNames.Devices]: Object.values(DeviceCapabilities),
  [CapabilitiesNames.Properties]: Object.values(PropertyCapabilities),
};

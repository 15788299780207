import React, { useState, useEffect } from 'react';

import { CsvButton } from 'components/atoms/CsvLink';
import Modal from 'components/elements/Modal';
import { useDispatch } from 'react-redux';
import { fetchTestSchedules } from 'reducers/tenantInfo';
import styled from 'styled-components';
import { handleApiReqWithRawResponse } from 'utils/ApiRequestHelpers';
import useAccess from 'utils/hooks/useAccess';
import useAlert from 'utils/hooks/useAlert';
import useFilters from 'utils/hooks/useFilters';

import DataTable from '../DataTable';
import UpdateEmeredTestGroup from './UpdateEmeredTestGroup';

const SubmitButton = styled.button`
  ${(props) => props.theme.submitButton()};
`;

const AddButton = styled(SubmitButton)`
  margin-left: 1rem;
`;

const CancelButton = styled.button`
  ${(props) => props.theme.clearButton()};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: 1rem auto;
`;

const TextWrapper = styled.div`
  text-align: center;

  & p {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }
`;

const SchedulesTable = ({ data, area, buildingInfo, headers, csvHeaders, isFetching }) => {
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfModal, setShowConfModal] = useState(false);
  const [groupInfo, setGroupInfo] = useState({ groupName: '', building: '', t180StartDate: '' });
  const [delGroup, setDelGroup] = useState('');

  const { filters, filteredData, clearFilters } = useFilters(data, ['building', 'group']);
  const { isEdit } = useAccess(area);
  const { errorAlert, successAlert } = useAlert();

  const handleOnClear = () => {
    clearFilters();
  };

  useEffect(() => {
    setUpdate(true);
  }, [data]);

  useEffect(() => {
    setUpdate(false);
  }, [update]);

  const handleAddGroupClick = () => {
    setGroupInfo({ groupName: '', building: '', t180StartDate: '' });
    setShowModal(true);
  };

  const handleCSVClick = async () => filteredData;

  const handleRowClick = (_e, group) => {
    setGroupInfo({
      groupName: group.group,
      t180StartDate: group.t180StartDate,
      building: group.building,
    });
    setShowModal(true);
  };

  const handleDeleteClick = async (e, group) => {
    e.stopPropagation();
    setDelGroup(group.group);
    setShowConfModal(true);
  };

  const handleDeleteConfirmClick = async () => {
    const response = await handleApiReqWithRawResponse('DeleteEmeredGroup', {
      groupName: delGroup,
    });
    if (response.ok) {
      successAlert('Group deleted successfully');
    } else {
      errorAlert('Something went wrong');
    }
    dispatch(fetchTestSchedules());
    setDelGroup('');
    setShowConfModal(false);
  };

  const handleConfClose = () => {
    setShowConfModal(false);
    setDelGroup('');
  };

  const handleClose = () => setShowModal(false);

  const rowFunctions = isEdit
    ? {
      handleRowClick,
      handleDeleteClick,
    }
    : {};

  const TableButtons = () => (
    <>
      <CsvButton headers={csvHeaders} handleClickCallBack={() => handleCSVClick()}>
        Download report
      </CsvButton>
      {isEdit && <AddButton onClick={handleAddGroupClick}>Add Group</AddButton>}
    </>
  );

  return (
    <>
      {filteredData && (
        <DataTable
          data={filteredData}
          headers={isEdit ? headers : headers.filter((h) => h.property !== 'delete')}
          isFetching={isFetching}
          hasUpdate={update}
          handleOnClear={handleOnClear}
          filters={filters}
          tblButtons={<TableButtons />}
          pageUpdateCb={undefined}
          rowFunctions={rowFunctions}
        />
      )}
      <Modal
        handleClose={handleClose}
        showModal={showModal}
        title={groupInfo.groupName ? 'Update Group' : 'Add Group'}
        width="95% !important"
      >
        <UpdateEmeredTestGroup
          buildingInfo={buildingInfo}
          group={groupInfo}
          isUpdate={!!groupInfo.groupName}
          closeModal={handleClose}
          groups={data.map((d) => d.group).filter((g) => g !== groupInfo?.groupName)}
        />
      </Modal>
      <Modal
        handleClose={handleConfClose}
        showModal={showConfModal}
        title="Delete Group"
        width="60rem"
      >
        <TextWrapper>
          <p>Are you sure you want to delete group {delGroup}?</p>
          <p>This will remove the test schedule for all devices in this group</p>
          <ButtonWrapper>
            <SubmitButton onClick={handleDeleteConfirmClick}>Ok</SubmitButton>
            <CancelButton onClick={handleConfClose}>Cancel</CancelButton>
          </ButtonWrapper>
        </TextWrapper>
      </Modal>
    </>
  );
};

export default SchedulesTable;

import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';

import TagItem from 'components/elements/Tag';
import { selectTags } from 'reducers/tags/selectors';
import { handleApiReq } from 'utils/ApiRequestHelpers';
import { contains } from 'utils/helpers';
import useAlert from 'utils/hooks/useAlert';
import { ActionDialog } from 'components/molecules';
import { BulkActionProps } from 'components/DeviceActions/DeviceActionProps';
import { ContentWrapper } from './BulkRemoveTags.styles';

const BulkRemoveTags: React.FC<BulkActionProps> = ({ deviceIds, handleResetAction }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { errorAlert } = useAlert();
  const tags = useSelector((state: any) => selectTags(state));

  const handleAddTag = ({ target }) => setSelectedTags([...selectedTags, target.value]);
  const handleRemoveTag = (tagId) => setSelectedTags(selectedTags.filter((t) => t !== tagId));

  const handleSubmit = async () => {
    setIsLoading(true);

    const res = await handleApiReq('BulkRemoveTags', {
      deviceIds: deviceIds,
      tags: selectedTags,
    });

    if (res) {
      if (res.isSuccessful) {
        setMessage(res.message);
        setIsSuccess(true);
      } else {
        errorAlert(res.message);
        handleResetAction();
      }
    } else {
      errorAlert('Something has gone wrong, please try again');
      handleResetAction();
    }

    setIsLoading(false);
  };

  const selectOptions = tags
    .map((t) => ({ value: t.id, display: t.name }))
    .filter((t) => !contains(t.value, selectedTags));

  return (
    <ActionDialog
      open
      title="Bulk Remove Tags"
      submitButtonName="Submit"
      sendingRequest={isLoading}
      handleSubmit={handleSubmit}
      onClose={handleResetAction}
      isDisabled={selectedTags.length === 0}
      successMessageProps={{
        message: message,
        showMessage: isSuccess,
        onClose: handleResetAction,
      }}
    >
      <ContentWrapper>
        <p className="bulk-remove-tags__description">
          Selected tags will be removed from selected devices if present
        </p>
        <FormControl className="bulk-remove-tags__select">
          <InputLabel id="remove-tags-select">Select Tag</InputLabel>
          <Select
            labelId="remove-tags-select"
            onChange={handleAddTag}
            disabled={isLoading}
            label="Select Tag"
            value=""
          >
            {selectOptions.map((tag, tagIndex) => (
              <MenuItem key={tagIndex.toString()} value={tag.value}>
                {tag.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!!selectedTags.length && (
          <div className="bulk-remove-tags__tags">
            {selectedTags.map((t) => (
              <TagItem
                key={t}
                tag={tags.find((tg) => tg.id === t)}
                handleRemoveClick={handleRemoveTag}
                showRemove
              />
            ))}
          </div>
        )}
      </ContentWrapper>
    </ActionDialog>
  );
};

export default BulkRemoveTags;

/* eslint-disable max-len */
import React from 'react';

const CopyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 28 28">
    <path d="M26.5 6c0.828 0 1.5 0.672 1.5 1.5v19c0 0.828-0.672 1.5-1.5 1.5h-15c-0.828 0-1.5-0.672-1.5-1.5v-4.5h-8.5c-0.828 0-1.5-0.672-1.5-1.5v-10.5c0-0.828 0.484-1.984 1.062-2.562l6.375-6.375c0.578-0.578 1.734-1.062 2.562-1.062h6.5c0.828 0 1.5 0.672 1.5 1.5v5.125c0.609-0.359 1.391-0.625 2-0.625h6.5zM18 9.328l-4.672 4.672h4.672v-4.672zM8 3.328l-4.672 4.672h4.672v-4.672zM11.062 13.438l4.937-4.937v-6.5h-6v6.5c0 0.828-0.672 1.5-1.5 1.5h-6.5v10h8v-4c0-0.828 0.484-1.984 1.062-2.562zM26 26v-18h-6v6.5c0 0.828-0.672 1.5-1.5 1.5h-6.5v10h14z"></path>
  </svg>
);

export default CopyIcon;

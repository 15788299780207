export enum PropertiesFeatures {
  PropertyCentric = 'PropertyCentric',
  ManagedService = 'ManagedService',
  HideDeviceTab = 'HideDeviceTab',
  PropertyRecords = 'PropertyRecords',
  VoidFlag = 'VoidFlag',
}

export enum SystemFeatures {
  SystemDebug = 'SystemDebug',
}

export enum ResidentsFeatures {
  ManageResidentAccess = 'ManageResidentAccess',
}

export enum HomeHubFeatures {
  ManageApplication = 'ManageApplication',
}

export enum BoilersFeatures {
  HideTelemetryTab = 'HideTelemetryTab',
  HideEditPropertyAndResidentFields = 'HideEditPropertyAndResidentFields',
  NewListingView = 'NewListingView',
}

export enum FeaturesAreas {
  Properties = 'Properties',
  System = 'System',
  Residents = 'Residents',
  HomeHub = 'HomeHub',
  Boilers = 'Boilers',
}

export type FeatureTypeMap = {
  [FeaturesAreas.Properties]: PropertiesFeatures;
  [FeaturesAreas.System]: SystemFeatures;
  [FeaturesAreas.Residents]: ResidentsFeatures;
  [FeaturesAreas.HomeHub]: HomeHubFeatures;
  [FeaturesAreas.Boilers]: BoilersFeatures;
};

export const Features = {
  [FeaturesAreas.Properties]: PropertiesFeatures,
  [FeaturesAreas.System]: SystemFeatures,
  [FeaturesAreas.Residents]: ResidentsFeatures,
  [FeaturesAreas.HomeHub]: HomeHubFeatures,
  [FeaturesAreas.Boilers]: BoilersFeatures,
};

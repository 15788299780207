import { ApiStatuses } from 'types';

export const updateUserPartialInSlice = ({ state, action, statusKey }) => {
  const { id, data } = action.payload;

  const updatedIndex = state.data.findIndex((user) => user.id === id);

  if (updatedIndex !== -1) {
    const updatedData = [...state.data];
    updatedData[updatedIndex] = { ...updatedData[updatedIndex], ...data };
    state.data = updatedData;
  }

  state.statuses[statusKey] = { status: ApiStatuses.Success, error: null };
};

import React from 'react';
import { Link, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

import {
  EditAddressFormField,
  EditFieldDropdown,
  EditFieldInput,
  TagsRow,
} from 'components/molecules';
import { EPCRating, FeaturesAreas, Property } from 'types';
import { useAppSelector, useAreaFeatures } from 'utils/hooks';
import { getUniqueDevicesTagIds } from 'utils';
import { PropertyContextProps } from '../types';
import { epcRatingOptions, validations } from '../constants/propertyInfo';
import { getApiUrl, getEpcRatingInfoUrl } from '../utils/getUrls';
import { VoidFlagSwitch } from './molecules';
import { PropertyInfoWrapper } from './PropertyInfo.styles';

export const PropertyInfo: React.FC = () => {
  const tags: { id: string; name: string }[] = useAppSelector((state) => state.tags.tags);
  const propertyFeatures = useAreaFeatures(FeaturesAreas.Properties).features;

  const { propertyInfo, devicesInfo } = useOutletContext<PropertyContextProps>();
  const {
    epcRating,
    propertyReference,
    postcode,
    address1,
    address2,
    address3,
    town,
    county,
    propertyId,
    tenantId,
    voidStatus,
  } = propertyInfo.data as Property;

  const handleRefetchDetails = () => propertyInfo.refetch();
  const { editDetailsUrl, editAddressUrl, postcodeUrl } = getApiUrl({
    tenantId,
    propertyId,
  });

  const uniqueTagIds = getUniqueDevicesTagIds({
    devices: devicesInfo.data?.results,
  });

  const allowEdit = true;

  return (
    <PropertyInfoWrapper>
      <div className="property-info">
        <div className="property-info__block property-info--property-details">
          <h5 className="property-info__title ">Property Details</h5>
          <EditFieldInput
            url={editDetailsUrl}
            onSuccess={handleRefetchDetails}
            fieldName="propertyReference"
            payloadFields={{
              epcRating,
              voidStatus,
            }}
            validationState={validations.propertyReference}
            initialFormState={{ propertyReference: propertyReference || '' }}
            editContainerProps={{
              fieldValue: propertyReference,
              allowEdit,
              fieldTitle: 'Property REF',
            }}
          />
          <div className="property-info__item">
            <Typography className="property-info__item-label">Address:</Typography>
            <div className="property-info__item-content">
              <Typography className="property-info__item-content-text">
                {[address1, address2, address3, town, county]
                  .filter((address) => address)
                  .join(', ')}
              </Typography>
            </div>
          </div>
          <EditAddressFormField
            addressUrl={editAddressUrl}
            onSuccess={handleRefetchDetails}
            fieldValue={postcode}
            fieldTitle="Postcode"
            fieldName="postcode"
            initialFormState={{ postcode: postcode || '' }}
            validationState={validations.postcode}
            postcodeUrl={postcodeUrl}
            allowEdit={allowEdit}
          />
          {propertyFeatures.VoidFlag && (
            <div className="property-info__item">
              <Typography className="property-info__item-label">Void Property Flag:</Typography>
              <div className="property-info__item-content">
                <VoidFlagSwitch
                  url={editDetailsUrl}
                  onSuccess={handleRefetchDetails}
                  payloadFields={{
                    epcRating,
                    propertyReference,
                  }}
                  voidStatus={voidStatus}
                />
              </div>
            </div>
          )}
        </div>
        <div className="property-info__block">
          <h5 className="property-info__title">Property Information</h5>
          <div className="property-info__epc-rating">
            <EditFieldDropdown
              url={editDetailsUrl}
              onSuccess={handleRefetchDetails}
              getPayload={(value) => ({
                propertyReference,
                voidStatus,
                epcRating: value === EPCRating.NoRating ? null : value,
              })}
              initialFormState={epcRating || ''}
              options={epcRatingOptions}
              className="property-info__epc-rating-dropdown"
              inputLabel="Select EPC rating"
              editContainerProps={{
                fieldValue: epcRating || '',
                allowEdit: true,
                fieldTitle: 'EPC rating',
              }}
            />
            <Link
              href={getEpcRatingInfoUrl(postcode)}
              target="_blank"
              underline="hover"
              className="property-info__epc-rating-link"
            >
              Check EPC Rating
            </Link>
          </div>
        </div>
        <div className="property-info__block">
          <h5 className="property-info__title">Tags against devices</h5>
          <TagsRow
            tags={uniqueTagIds}
            getTagName={(id) => tags?.find((tag) => tag.id === id)?.name as string}
          />
        </div>
      </div>
    </PropertyInfoWrapper>
  );
};

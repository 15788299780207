import React from 'react';
import { Typography } from '@mui/material';

import { VoidStatus } from 'types';
import { VoidFlagDialogContentWrapper } from './VoidFlagDialogContent.styles';

type VoidDialogContentProps = {
  type: VoidStatus;
};

export const VoidFlagDialogContent: React.FC<VoidDialogContentProps> = ({ type }) => (
  <VoidFlagDialogContentWrapper>
    {type === VoidStatus.Void && (
      <>
        <Typography data-testid="void-flag-dialog--void" className="void-flag-dialog__paragraph">
          By marking the property as ‘Void’, the following will be automatically implemented:
        </Typography>
        <ul className="void-flag-dialog__list">
          <li className="void-flag-dialog__list-item">
            For properties with a <strong>BCM installed</strong>:
            <ul className="void-flag-dialog__list">
              <li className="void-flag-dialog__list-item">
                The heating max set point will be set to 10°C.
              </li>
              <li className="void-flag-dialog__list-item">
                The boiler will be fire-up automatically every Mondays at 9am for 5 min.
              </li>
            </ul>
          </li>
          <li className="void-flag-dialog__list-item">
            For properties with a <strong>PowerSense A installed</strong>: Electricity will be
            switch off (current isolated)
          </li>
          <li className="void-flag-dialog__list-item">All Device alerts will be muted.</li>
        </ul>
      </>
    )}
    {type === VoidStatus.NotVoid && (
      <>
        <Typography
          data-testid="void-flag-dialog--not-void"
          className="void-flag-dialog__paragraph"
        >
          By unmarking the property as ‘Void’, the following will be automatically implemented:
        </Typography>
        <ul className="void-flag-dialog__list">
          <li className="void-flag-dialog__list-item">
            For properties with a <strong>BCM installed</strong>: The heating max set point will be
            set to original max set point.
          </li>
          <li className="void-flag-dialog__list-item">
            For properties with a <strong>PowerSense A installed</strong>: A message will be sent to
            the device to release the electricity.{' '}
            <span className="void-flag-dialog__list-item-red">
              However, an engineer needs to manually activate the BCM to re-instate the current.
            </span>
          </li>
          <li className="void-flag-dialog__list-item">All Device alerts will be activated.</li>
        </ul>
      </>
    )}
  </VoidFlagDialogContentWrapper>
);

import React, { useEffect, useState } from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Areas } from 'types/roles';
import useAccess from 'utils/hooks/useAccess';

import { fetchLuminaires, deleteLuminaire } from '../../reducers/standardLuminaires';
import AddButton from './AddButton';
import AddLuminaireModal from './AddLuminaireModal';

const Wrapper = styled.div`
  width: 80%;
  margin: 3rem 2rem;

  & > h5 {
    font-size: inherit;
  }
`;

const List = styled.ul`
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.2);
  margin: 2rem 0;
  max-height: 60vh;
  overflow-y: auto;

  & > li {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    background: ${({ theme }) => theme.offWhite};
    position: relative;

    &:nth-child(even) {
      background-color: #ececec;
    }

    & div {
      width: 30%;
      margin: 0.5rem 1rem;
    }

    & span {
      color: ${({ theme }) => theme.spanBlue};
    }

    &:hover {
      background-color: #ececf7;
    }
  }
`;

const SvgWrapper = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 25%;

  width: 4rem !important;
  height: 4rem;
  color: ${({ theme }) => theme.error};

  &:hover {
    cursor: pointer;
  }

  & > svg {
    height: 100%;
    width: 100%;
    fill: currentColor;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  && > h5 {
    margin-right: 2rem;
    margin-bottom: 0;
    font-size: inherit;
  }
`;

const StandardLuminaires = () => {
  const dispatch = useDispatch();
  const luminaires = useSelector((state: any) => state.standardLuminaires.luminaires);
  const [showModal, setShowModal] = useState(false);

  const { isEdit } = useAccess(Areas.lighting);

  useEffect(() => {
    dispatch(fetchLuminaires());
  }, [dispatch]);

  const handleDeleteClick = async (id) => {
    await dispatch(deleteLuminaire(id));
    dispatch(fetchLuminaires());
  };

  return (
    <>
      <Wrapper>
        <HeaderWrapper>
          <h5>Standard Luminaires</h5>
          {isEdit && <AddButton handleAddClick={() => setShowModal(true)} />}
        </HeaderWrapper>

        <List>
          {luminaires?.length > 0 &&
            luminaires.map((l) => (
              <li key={l.id}>
                <div>
                  <span>Luminaire Name:</span> {l.name}
                </div>
                <div>
                  <span>Lamp Type:</span> {l.lampType}
                </div>
                <div>
                  <span>Inverter Type:</span> {l.inverterType}
                </div>
                <div>
                  <span>Battery Type:</span> {l.batteryType}
                </div>
                <div>
                  <span>Battery Voltage (V):</span> {l.batteryVoltage}
                </div>
                <div>
                  <span>Battery Capacity (Ah):</span> {l.batteryCapacity}
                </div>
                {isEdit && (
                  <SvgWrapper onClick={() => handleDeleteClick(l.id)}>
                    <SvgIcon type="delete" />
                  </SvgWrapper>
                )}
              </li>
            ))}
        </List>
      </Wrapper>
      <AddLuminaireModal setShowModal={setShowModal} showModal={showModal} />
    </>
  );
};

export default StandardLuminaires;

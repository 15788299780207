import { useEffect, useState } from 'react';

import Filter from '../../types/filters';
import { mapStatus, mapDeviceTypesToDisplay } from '../deviceUtils';
import { getMappedValues, filterDates } from '../helpers';

const getValues = (arr, data, id, value) =>
  arr.map((i) => {
    if (i) {
      return {
        value: i,
        display: data.find((d) => d[id] === i)[value],
      };
    }
  });

const statuses = [
  {
    value: 'Passed',
    display: 'Passed',
  },
  {
    value: 'Warning',
    display: 'Warning',
  },
  {
    value: 'Failed',
    display: 'Failed',
  },
  {
    value: 'NotInstalled',
    display: 'Not Installed',
  },
];

const useFilters = (data, requiredFilters) => {
  const initSelectedFilters = () =>
    requiredFilters.reduce((acc, cur) => ({ ...acc, [cur]: '' }), {});
  const [filteredData, setFilteredData] = useState(data);
  const [selectedFilters, setSelectedFilters] = useState(initSelectedFilters());

  const updateSelectedFilter = (name, value) =>
    setSelectedFilters((prev) => ({
      ...prev,
      [name]: value,
    }));

  const filterBuilding = (item) => {
    if (selectedFilters['building'] === '') return true;
    if (!item.building) return false;

    return item.building.toLowerCase() === selectedFilters['building'].toLowerCase();
  };
  const filterFloor = (item) => {
    if (selectedFilters['floor'] === '') return true;
    if (!item.floor) return false;

    return item.floor.toLowerCase() === selectedFilters['floor'].toLowerCase();
  };
  const filterStatus = (item) => {
    if (selectedFilters['status'] === '') return true;
    if (!item.deviceStatus) return false;

    return mapStatus(item.deviceStatus).toLowerCase() === selectedFilters['status'].toLowerCase();
  };
  const filterDeviceName = (item) => {
    if (selectedFilters['deviceName'] === '') return true;
    if (!item.deviceName) return false;

    return item.deviceName.includes(selectedFilters['deviceName']);
  };
  const filterGroup = (item) => {
    if (selectedFilters['group'] === '') return true;
    if (!item.group) return false;

    return item.group === selectedFilters['group'];
  };
  const filterDeviceType = (item) => {
    if (selectedFilters['deviceType'] === '') return true;

    return item.deviceType.includes(selectedFilters['deviceType']);
  };
  const filterTestType = (item) => {
    if (selectedFilters['testType'] === '') return true;

    return item.testType === selectedFilters['testType'];
  };
  const filterTestResult = (item) => {
    if (selectedFilters['testResult'] === '') return true;

    return item.result === selectedFilters['testResult'];
  };
  const filterDate = (item) => {
    if (selectedFilters['minDate'] === '' || selectedFilters['maxDate'] === '') return true;

    return filterDates(selectedFilters['minDate'], selectedFilters['maxDate'])(item['testDate']);
  };

  const filterFunctions = {
    deviceName: filterDeviceName,
    building: filterBuilding,
    floor: filterFloor,
    status: filterStatus,
    group: filterGroup,
    deviceType: filterDeviceType,
    testType: filterTestType,
    testResult: filterTestResult,
    date: filterDate,
  };

  const selectedFunctions: any[] = [];
  Object.keys(filterFunctions).forEach((f) => {
    if (requiredFilters.includes(f)) selectedFunctions.push(filterFunctions[f]);
  });

  const handleOnChange = (ele) => {
    if (ele.nodeName === 'SELECT') {
      updateSelectedFilter(ele.name, ele.options[ele.selectedIndex].value);
    } else if (ele.nodeName === 'LI') {
      updateSelectedFilter(ele.dataset.name, ele.dataset.value);
    } else {
      updateSelectedFilter(ele.name, ele.value);
    }
  };

  const handleOnClear = () => setSelectedFilters(initSelectedFilters());

  const filterList: Filter[] = [
    {
      label: 'Device',
      data: data ? data.map((item) => item.deviceName).sort() : [],
      placeholder: 'Enter device name',
      onChangeCallback: handleOnChange,
      isSmall: false,
      inputType: 'text',
      name: 'deviceName',
      selectedValue: selectedFilters['deviceName'],
      hasOnChange: true,
    },
    {
      label: 'Building',
      data: data
        ? getValues(
          [...new Set(data.map((item) => item.building))],
          data,
          'building',
          'buildingName'
        )
          .filter((f) => f)
          .sort()
        : [],
      onChangeCallback: handleOnChange,
      isSmall: false,
      inputType: 'dropdown',
      placeholder: 'Select All Buildings',
      name: 'building',
      selectedValue: selectedFilters['building'],
      hasOnChange: true,
    },
    {
      label: 'Floor',
      data: data ? [...new Set(data.map((item) => item.floor))].filter((f) => f).sort() : [],
      onChangeCallback: handleOnChange,
      isSmall: false,
      inputType: 'dropdown',
      placeholder: 'Select All Floors',
      name: 'floor',
      selectedValue: selectedFilters['floor'],
      hasOnChange: true,
    },
    {
      label: 'Status Group',
      data: statuses,
      onChangeCallback: handleOnChange,
      isSmall: false,
      inputType: 'dropdown',
      placeholder: 'Select All Statuses',
      name: 'status',
      selectedValue: selectedFilters['status'],
      hasOnChange: true,
    },
    {
      label: 'Group',
      data: data ? [...new Set(data.map((item) => item.group))].filter((f) => f).sort() : [],
      onChangeCallback: handleOnChange,
      isSmall: false,
      inputType: 'text',
      placeholder: 'Search Groups',
      name: 'group',
      selectedValue: selectedFilters['group'],
      hasOnChange: true,
    },
    {
      label: 'DeviceType',
      data: data
        ? getMappedValues(
          [
            ...new Set(
              data.map((item) => (item.deviceType ? item.deviceType.split('.')[0] : ''))
            ),
          ],
          mapDeviceTypesToDisplay
        )
          .filter((f) => f)
          .sort()
        : [],
      onChangeCallback: handleOnChange,
      isSmall: false,
      inputType: 'dropdown',
      placeholder: 'Select All Device Types',
      name: 'deviceType',
      selectedValue: selectedFilters['deviceType'],
      hasOnChange: true,
    },
    {
      label: 'TestType',
      data: ['T0', 'T5', 'T60', 'T180'],
      onChangeCallback: handleOnChange,
      isSmall: false,
      inputType: 'dropdown',
      placeholder: 'Select All Test Types',
      name: 'testType',
      selectedValue: selectedFilters['testType'],
      hasOnChange: true,
    },
    {
      label: 'TestResult',
      data: [
        'Passed',
        'Failed',
        'Degraded',
        'Indeterminate',
        'AwaitingReview',
        'Suppressed',
        'AwaitingAnalysis',
      ],
      onChangeCallback: handleOnChange,
      isSmall: false,
      inputType: 'dropdown',
      placeholder: 'Select All Test Results',
      name: 'testResult',
      selectedValue: selectedFilters['testResult'],
      hasOnChange: true,
    },
    {
      label: 'MinDate',
      data: [],
      onChangeCallback: handleOnChange,
      isSmall: false,
      inputType: 'date',
      placeholder: '',
      name: 'minDate',
      selectedValue: selectedFilters['minDate'],
      hasOnChange: true,
    },
    {
      label: 'MaxDate',
      data: [],
      onChangeCallback: handleOnChange,
      isSmall: false,
      inputType: 'date',
      placeholder: '',
      name: 'maxDate',
      selectedValue: selectedFilters['maxDate'],
      hasOnChange: true,
    },
  ];

  const selectedFilterList = filterList.filter((f) => requiredFilters.includes(f.name));

  useEffect(() => {
    const composeFilters = (filterFunctions) => (data) =>
      filterFunctions.reduce((d, f) => d.filter(f), data);
    const applyFilters = composeFilters(selectedFunctions);
    setFilteredData(applyFilters(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, data]);

  return { filters: selectedFilterList, clearFilters: handleOnClear, filteredData };
};

export default useFilters;

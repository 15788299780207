import styled from 'styled-components';

export const EditFieldInputWrapper = styled.div`
  .edit-field-input {
    &__error-text {
      font-size: 0.9rem;
      padding: 0 14px;
    }
  }

  @media (min-width: 768px) {
    .edit-form-fields-container__inner-content-form {
      width: 400px;
    }
  }
`;

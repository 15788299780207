import moment from 'moment';
import { GridColDef } from '@mui/x-data-grid-pro';

export const columnsList: GridColDef[] = [
  { field: 'type', headerName: 'Licence/Package Type', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 1 },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 1,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'start',
    headerName: 'Service Start Date',
    flex: 1,
    type: 'date',
    valueFormatter: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
  },
  {
    field: 'period',
    headerName: 'Service Period (m)',
    flex: 1,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'end',
    headerName: 'Service End Date',
    flex: 1,
    type: 'date',
    valueFormatter: (value) => (value ? moment(value).format('DD/MM/YYYY') : ''),
  },
];

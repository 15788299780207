/* eslint-disable max-len */
import React from 'react';

const ExchangeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 42 32">
    <path
      d="M38.8,14.8L38.8,14.8C38.6,15,38.5,15,38.3,15H4.6c-1.1,0-2-0.9-2-2s0.9-2,2-2h27c0.5,0,0.8-0.6,0.4-1l-5.6-5.6
	c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l9.6,9.6C39.8,12.2,39.8,13.8,38.8,14.8z"
    />
    <path
      d="M3.3,17.6L3.3,17.6c0.1-0.1,0.3-0.2,0.4-0.2h33.8c1.1,0,2,0.9,2,2s-0.9,2-2,2h-27c-0.5,0-0.8,0.6-0.4,1l5.6,5.6
	c0.8,0.8,0.8,2,0,2.8c-0.8,0.8-2,0.8-2.8,0l-9.6-9.6C2.3,20.2,2.3,18.6,3.3,17.6z"
    />
  </svg>
);

export default ExchangeIcon;

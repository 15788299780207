import styled, { css } from 'styled-components';

const containerWidth = css`
  width: 50%;

  @media ${(props) => props.theme.laptopL} {
    width: 60%;
  }

  @media ${(props) => props.theme.mobileL} {
    width: 80%;
  }
`;

export const Container = styled.div`
  padding: 3rem 0;
`;

export const TenantsWrapper = styled.div<{ isFetching: boolean }>`
  text-align: center;

  .clients-select {
    &__header {
      padding-bottom: 12px;
      margin: 0;

      @media (min-width: 1024px) {
        padding-bottom: 0;
      }
    }

    &__search {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 18px;
      margin: 0 auto;

      ${containerWidth}

      &-field {
        .MuiInputBase-root {
          background-color: #fff;
        }
      }
    }
  }

  & h2 {
    margin-bottom: 2rem;
  }

  & ul {
    ${(props) => props.theme.wrapper};
    margin: 0 auto;
    max-height: 70vh;
    overflow-y: auto;
    filter: blur(${(props) => (props.isFetching ? '5px' : '0px')});

    ${containerWidth}
  }

  & li {
    cursor: pointer;
    background-color: transparent;
    color: inherit;
    transition: all 0.3s ease-in-out;
    &:not(:last-child) span {
      border-bottom: #666 1px solid;
    }

    &:hover {
      background-color: ${(props) => props.theme.primaryLight};
      color: ${(props) => props.theme.white};
    }
  }

  & span {
    display: inline-block;
    padding: 1rem;
    width: 60%;
  }
`;

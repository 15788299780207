import { useMemo } from 'react';

import { useAppSelector } from 'utils/hooks';
import { ApiStatuses, Capabilities, CapabilitiesList, CapabilitiesNames } from 'types';

export const useCapabilities = <T extends CapabilitiesNames>(
  capabilityArea: T
): {
  capabilities: Record<Capabilities[T][number], boolean>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  status: ApiStatuses;
} => {
  const capabilitiesStore = useAppSelector((state) => state.capabilities);
  const capabilitiesStatus = capabilitiesStore.statuses.fetchCapabilities.status;

  const areaCapabilities = CapabilitiesList[capabilityArea];

  const capabilities = useMemo(
    () =>
      areaCapabilities.reduce(
        (acc, capability) => ({
          ...acc,
          [capability]: !!capabilitiesStore.data?.[capabilityArea]?.includes(capability),
        }),
        {} as Record<Capabilities[T][number], boolean>
      ),
    [areaCapabilities, capabilitiesStore.data, capabilityArea]
  );

  const isLoading =
    capabilitiesStatus === ApiStatuses.Loading || capabilitiesStatus === ApiStatuses.Init;
  const isSuccess = capabilitiesStatus === ApiStatuses.Success;
  const isError = capabilitiesStatus === ApiStatuses.Failed;

  return { capabilities, isLoading, isSuccess, isError, status: capabilitiesStatus };
};

import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { fetchNotes } from 'reducers/notes';

import DeviceMaintenanceForm from '../DeviceMaintenanceForm';

const NoteForm = ({ device }) => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const formItems = [
    {
      name: 'deviceType',
      type: 'hidden',
      value: device.deviceType.slice(0, 2),
    },
    {
      name: 'note',
      type: 'textArea',
      label: 'Add a note',
    },
  ];

  const handleRefetch = () => dispatch(fetchNotes(device.deviceId));

  return (
    <>
      <DeviceMaintenanceForm
        title="Add a note"
        message=""
        formItems={formItems}
        device={device}
        endPoint="AddDeviceNote"
        fetchAction={handleRefetch}
        btnText="Save Note"
        disabled={isButtonDisabled}
        setIsButtonDisabled={setIsButtonDisabled}
      />
    </>
  );
};

export default NoteForm;

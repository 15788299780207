import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { BuildDetailsProps, getBuildDetails } from '../utils/getBuildDetails';

const SupportWrapper = styled.div`
  text-align: center;
  width: 60%;
  padding: 4rem;
  margin: 0 auto;

  & h1 {
    margin-bottom: 5rem;
  }

  & > p {
    margin-bottom: 0.5rem;
    font-size: ${(props) => props.theme.textSize};

    & span {
      font-weight: 500;
    }

    &:first-of-type {
      font-size: ${(props) => props.theme.textBig};
      margin-bottom: 2rem;
    }
  }

  @media ${(props) => props.theme.laptop} {
    width: 80%;
  }

  @media ${(props) => props.theme.mobileL} {
    width: 95%;
  }

  @media ${(props) => props.theme.mobileM} {
    width: 100%;
    padding: 4rem 2rem;
  }
`;

const BuildDetailsWrapper = styled.div`
  position: absolute;
  font-size: ${(props) => props.theme.textSmall};
  color: ${(props) => props.theme.secondary};
  right: 5rem;
  bottom: 2rem;
  text-align: left;
`;

const Support = () => {
  const [buildDetails, setBuildDetails] = useState<BuildDetailsProps>();

  useEffect(() => {
    const fetchBuildingDetails = async () => {
      setBuildDetails(await getBuildDetails());
    };
    fetchBuildingDetails();
  }, []);

  return (
    <SupportWrapper>
      <h1>Support</h1>
      <p>If you are having an issue, please contact us using the details below:</p>
      <p>
        <span>E-mail: </span>{' '}
        <a href="mailto:support@vericonsystems.com?Subject=Support">support@vericonsystems.com</a>
      </p>
      <p>
        <span>Phone: </span>01242 582555
      </p>
      {buildDetails && (
        <BuildDetailsWrapper>
          <p>Build Number: {buildDetails.buildNumber}</p>
          <p>Commit Id: {buildDetails.commitId}</p>
          <p>Branch Name: {buildDetails.branchName}</p>
        </BuildDetailsWrapper>
      )}
    </SupportWrapper>
  );
};

export default Support;

import axios from 'axios';
import { useMutation } from 'react-query';
import { ApiErrorsType } from 'types';
import { getToken } from 'utils/contexts/AuthProvider';
import { useHandleApiErrors } from './useHandleApiErrors';

type OnMutationMethod =
  | ((data: void, variables: any, context: void | undefined) => void | Promise<unknown>)
  | undefined;

type UsePutFetchDataProps = {
  url: string;
  onSuccess?: OnMutationMethod;
  onError?: (error: ApiErrorsType) => void;
  contentType?: string;
  fullResponse?: boolean;
  enableErrorHandler?: boolean;
};

export const usePutFetchData = ({
  url,
  onSuccess,
  onError,
  enableErrorHandler,
  fullResponse,
  contentType = 'application/json',
}: UsePutFetchDataProps) => {
  const handleError = useHandleApiErrors();

  return useMutation({
    mutationFn: async (body) => {
      const token = await getToken();

      const res = await axios.put(url, body, {
        headers: {
          'Content-type': contentType,
          Authorization: `Bearer ${token}`,
        },
      });

      if (fullResponse) {
        return res;
      }

      return res.data;
    },
    onError: (e: ApiErrorsType) => {
      if (onError) {
        onError(e);
      }

      if (enableErrorHandler) {
        handleError(e);
      }
    },
    ...(!!onSuccess && { onSuccess }),
  });
};

import React from 'react';

import TableLink from 'components/atoms/TableLink';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';

const PositionFloorLink = ({ building, floor }) => {
  const tenant = useSelectedTenant();

  return (
    <TableLink to={`/${tenant}/PositionFloor?building=${building}&floor=${floor}`}>
      {' '}
      Position{' '}
    </TableLink>
  );
};

export default PositionFloorLink;

import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoading } from 'reducers/loading/selectors';
import { fetchTestSchedules, fetchTenantEmereds } from 'reducers/tenantInfo';

import SchedulesTable from '../../components/Tables/SchedulesTable';
import { Areas } from '../../types/roles';

const formatData = (testSchedules, buildingInfo) => {
  if (testSchedules && buildingInfo && buildingInfo.length > 0) {
    return testSchedules.map((ts) => ({
      group: ts.group.groupName,
      building: ts.group.building,
      buildingName: buildingInfo.find((b) => b.building === ts.group.building)
        ? buildingInfo.find((b) => b.building === ts.group.building).name
        : '',
      testType: ts.testSchedule.schedule[0].testType,
      testDate: ts.testSchedule.schedule[0].testDate,
      t180StartDate: ts.testSchedule.t180StartDate,
    }));
  }

  return [];
};

const EmergencyLightsSchedule = () => {
  const [aggregateData, setAggregateData] = useState([] as any[]);
  const dispatch = useDispatch();
  const testSchedules = useSelector((state: any) => state.tenantInfo.emeredTestSchedules);
  const buildingInfo = useSelector((state: any) => state.buildingInfo.buildings);
  const isFetching = useSelector((state: any) => selectIsLoading(['FETCH_TESTSCHEDULE'])(state));

  const headers = [
    {
      display: 'Test Group',
      property: 'group',
      sort: true,
    },
    {
      display: 'Building',
      property: 'buildingName',
      sort: true,
    },

    {
      display: 'Next Test',
      property: 'testType',
      sort: true,
    },
    {
      display: 'Test Date',
      property: 'testDate',
      sort: true,
    },
    {
      display: '3 Hour Test Date',
      property: 't180StartDate',
      sort: true,
    },
    {
      display: '',
      property: 'delete',
    },
  ];

  const csvHeaders = [
    {
      label: 'Test Group',
      key: 'group',
    },
    {
      label: 'Building',
      key: 'buildingName',
    },
    {
      label: 'Test Type',
      key: 'testType',
    },
    {
      label: 'Test Date',
      key: 'testDate',
    },
    {
      label: '3 Hour Test Date',
      key: 't180StartDate',
    },
  ];

  useEffect(() => {
    const getSchedules = async () => {
      await dispatch(fetchTestSchedules());
    };
    getSchedules();
  }, [dispatch]);

  useEffect(() => {
    const getDevices = async () => {
      await dispatch(fetchTenantEmereds());
    };
    getDevices();
  }, [testSchedules, dispatch]);

  useEffect(() => {
    setAggregateData(formatData(testSchedules, buildingInfo));
  }, [testSchedules, buildingInfo]);

  return (
    <>
      <h1>Emergency Lights - Test Schedules</h1>
      {aggregateData && (
        <SchedulesTable
          area={Areas.lighting}
          data={aggregateData}
          buildingInfo={buildingInfo}
          headers={headers}
          csvHeaders={csvHeaders}
          isFetching={isFetching}
        />
      )}
    </>
  );
};

export default EmergencyLightsSchedule;

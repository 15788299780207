import { DeviceTypes } from './DeviceTypes/Device';
import { IRole, Roles, Areas, AccessLevel } from './roles';

interface IUserProfile {
  name: string;
  pictureUrl: string;
  tenantSelected: boolean;
  tenants: TenantRole[];
  selectedTenant?: string;
  role?: IRole;
}

interface TenantRole {
  name: string;
  role: string;
  tags: string[];
}

export interface UserAuth {
  isEdit: boolean;
  isManager: boolean;
  isAdmin: boolean;
  isDev: boolean;
  isRead: boolean;
}

export class UserProfile implements IUserProfile {
  name: string;
  pictureUrl: string;
  tenants: TenantRole[];
  selectedTenant?: string;
  role?: IRole;
  tags?: string[];
  tenantSelected: boolean;

  constructor(profile) {
    this.name = profile.name;
    this.pictureUrl = profile.pictureUrl;
    this.tenants = profile.tenants;
    this.tenantSelected = profile.tenantSelected;
    if (profile.tenant) {
      this.selectedTenant = profile.tenant;
      this.role = this.addRole(profile.role);
      this.tags = profile.tags;
    }
  }

  private addRole(roleName) {
    return new Roles[roleName]();
  }

  getAccess(area: Areas) {
    return this.role ? this.role.permissions[area] : '';
  }

  getAreas() {
    let areas: string[] = [];
    if (this.role) {
      areas = Object.keys(this.role.permissions).filter(
        (k) => this.role!.permissions[k] > AccessLevel.None
      );
    }

    return areas;
  }

  hasArea(area: Areas) {
    const areas = this.getAreas();

    return areas.includes(area);
  }

  hasAccess(access: AccessLevel, area: Areas) {
    return this.role && this.role.permissions[area] >= access;
  }

  getAccessLevels(area: Areas | null): UserAuth {
    if (!area) {
      return {
        isEdit: false,
        isManager: false,
        isAdmin: false,
        isDev: false,
        isRead: false,
      };
    }
    const areaAccess = this.role!.permissions[area];

    return {
      isRead: areaAccess >= AccessLevel.Read,
      isEdit: areaAccess >= AccessLevel.Edit,
      isManager: areaAccess >= AccessLevel.Manage,
      isAdmin: areaAccess >= AccessLevel.Admin,
      isDev: areaAccess >= AccessLevel.Dev,
    };
  }

  //returns filtered array based on available devices
  hasDevices(devices: DeviceTypes[]) {
    return devices.filter((d) => this.role && this.role.devices.includes(d));
  }
}

type OrganizationAccess = { tags: string[]; role: string };
type Organizations = { [key: string]: OrganizationAccess };

export const createProfile = (profile) => {
  const organizations: Organizations =
    profile[`${process.env.REACT_APP_URL}app_metadata`].organizations;

  const tenants = Object.keys(organizations);
  const tenantsArr = tenants.map((name) => ({
    name,
    role: organizations[name].role,
    tags: organizations[name].tags,
  }));

  const hasOneTenant = tenantsArr.length === 1;

  return new UserProfile({
    name: profile.name,
    pictureUrl: profile.picture,
    tenant: hasOneTenant ? tenantsArr[0].name : undefined,
    role: hasOneTenant ? tenantsArr[0].role : undefined,
    tags: hasOneTenant ? tenantsArr[0].tags : undefined,
    tenants: tenantsArr,
    tenantSelected: hasOneTenant,
  });
};

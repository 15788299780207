export const calcMetersPerPx = (lat, zoom) =>
  (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom);

export const latLng2MetersPerDegree = (lat) => {
  const phi = (lat * Math.PI) / 180;
  const metersPerLatDegree =
    111132.92 - 559.82 * Math.cos(2 * phi) + 1.175 * Math.cos(4 * phi) - 0.0023 * Math.cos(6 * phi);
  const metersPerLngDegree =
    111412.84 * Math.cos(phi) - 93.5 * Math.cos(3 * phi) + 0.118 * Math.cos(5 * phi);

  return { metersPerLatDegree, metersPerLngDegree };
};

const getMin = (arr, data) =>
  arr.reduce((min, cur) => (cur[data] < min ? cur[data] : min), arr[0][data]);

const getMax = (arr, data) =>
  arr.reduce((max, cur) => (cur[data] > max ? cur[data] : max), arr[0][data]);

const getMid = (arr, data) => {
  const min = getMin(arr, data);
  const max = getMax(arr, data);

  return min + (max - min) / 2;
};

export const initImgDims = (floor, zoom) => {
  if (!floor) return {};
  const mPerPx = calcMetersPerPx(floor.lat, zoom);
  const dims = {
    w: floor.width / mPerPx,
    h: floor.height / mPerPx,
  };

  return dims;
};

export const calcMidCoords = (coordArray) => ({
  lat: getMid(coordArray, 'lat'),
  lng: getMid(coordArray, 'lng'),
});

export const calcZoomLevel = (coordArray, mapDims) => {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  const allCoordsAreTheSame = coordArray.reduce(
    (acc, val) => acc.lat === val.lat && acc.lng === val.lng
  );
  /* 
        If all co-ordinates are the same there is no need to calculate the zoom level to include all
        devices in the map dimensions as they are all in the exact same position.
    */
  if (allCoordsAreTheSame) return ZOOM_MAX;

  const latRad = (lat) => {
    const sin = Math.sin((lat * Math.PI) / 180);
    const rad = Math.log((1 + sin) / (1 - sin)) / 2;

    return Math.max(Math.min(rad, Math.PI), -Math.PI) / 2;
  };

  const zoom = (mapPx, worldPx, fraction) =>
    Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);

  const ne = {
    lat: Math.max(...coordArray.map((l) => l.lat)) * 1.00001,
    lng: Math.max(...coordArray.map((l) => l.lng)) * 1.00001,
  };

  const sw = {
    lat: Math.min(...coordArray.map((l) => l.lat)) * 0.99999,
    lng: Math.min(...coordArray.map((l) => l.lng)) * 0.99999,
  };

  const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

  const lngDiff = ne.lng - sw.lng;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDims.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDims.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

export const calcMapSettings = (clusters, mapDims) => {
  const coordArray = clusters.map((c) => ({ lat: c.lat, lng: c.lng }));
  const avgCoords = calcMidCoords(coordArray);

  const zoom = calcZoomLevel(coordArray, mapDims);

  return {
    center: {
      lat: avgCoords.lat,
      lng: avgCoords.lng,
    },
    zoom: zoom,
  };
};

export const initMap = (selectedBuilding, clusters, mapDims) => {
  if (
    clusters.length === 1 &&
    (!clusters.building || clusters[0].building === selectedBuilding?.building)
  ) {
    return {
      center: {
        lat: clusters[0].lat,
        lng: clusters[0].lng,
      },
      zoom: 20,
    };
  } else if (!selectedBuilding && mapDims) {
    return calcMapSettings(clusters, mapDims);
  } else {
    return {
      center: {
        lat: selectedBuilding.lat,
        lng: selectedBuilding.lng,
      },
      zoom: 19,
    };
  }
};

import { AuthContextInfo } from 'types/Auth';

const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const SELECT_TENANT = 'SELECT_TENANT';

const AUTH_INIT = 'AUTH_INIT';

const authTypes = {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
  SELECT_TENANT,
  AUTH_INIT,
};

export const setSelectedTenant = (user) => ({
  type: SELECT_TENANT,
  payload: { user },
});

export const authSetup = (authObj) => ({
  type: AUTH_INIT,
  payload: authObj,
});

const initialState: AuthContextInfo = {
  isLoading: true,
  isAuthenticated: false,
  user: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: (...p) => {
    throw new Error('Login not implemented');
  },
  getTokenSilently: () => {
    throw new Error('getTokenSilently not implemented');
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getIdTokenClaims: (...p) => {
    throw new Error('getIdTokenClaims not implemented');
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logout: (...p) => {
    throw new Error('Logout not implemented');
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.AUTH_INIT: {
      return action.payload;
    }
    case authTypes.SELECT_TENANT:
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

export default authReducer;

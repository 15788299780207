import { Property } from 'types';

type PropertyAddressToStringProps = { property: Property; withPostcode?: boolean };

export const propertyAddressToString = ({ property, withPostcode }: PropertyAddressToStringProps) =>
  property?.address1
    ? [
      property.address1,
      property.address2,
      property.address3,
      property.town,
      property.county,
      ...(withPostcode ? [property.postcode] : []),
    ]
      .filter((address) => address)
      .join(', ')
    : '';

import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateFloorPos } from 'reducers/buildingInfo/actions';

import ImageEditInterface from '../Overlays/ImageEditInterface';
import Map from './Map';

const FloorPositioningMap = ({ floor, building }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editFloor, setEditFloor] = useState(floor);

  const handleEditChange = (e) => {
    const updatedFloor = { ...editFloor };
    updatedFloor[e.target.name] = e.target.value;
    setEditFloor(updatedFloor);
  };

  const handleCancelClick = () => navigate(-1);

  const handleSaveClick = async () => {
    const data = {
      building: building,
      floor: floor.name,
      width: editFloor.width,
      height: editFloor.height,
      rotation: editFloor.rotation,
      lat: editFloor.lat,
      long: editFloor.lng,
    };
    dispatch(updateFloorPos(data));
    navigate(-1);
  };

  const handleLatLongChange = (lat, lng) => {
    setEditFloor({
      ...editFloor,
      lng,
      lat,
    });
  };

  return (
    <>
      <ImageEditInterface
        floor={editFloor}
        updateFloor={handleEditChange}
        handleCancel={handleCancelClick}
        handleSave={handleSaveClick}
      />
      <Map floor={editFloor} setFloor={handleLatLongChange} />
    </>
  );
};

export default FloorPositioningMap;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { createTag, deleteTag } from 'reducers/tags/tagActions';
import { selectTags } from 'reducers/tags/selectors';
import { Areas } from 'types/roles';
import Tag from 'types/Tags/Tag';
import useAccess from 'utils/hooks/useAccess';
import { isRequired, isUnique, maxLength, isAlphaNumNWS } from 'utils/inputValidation';

import Table from './Table';

const Wrapper = styled.div`
  margin: 1rem 0;
  overflow: auto;
  width: 100%;
`;

const Tags = () => {
  const dispatch = useDispatch();
  const { tags } = useSelector((state: any) => state.tags);
  const { isManager } = useAccess(Areas.misc);
  const [data, setData] = useState<Tag[]>([]);
  const [editData, setEditData] = useState();
  const tagsOnly = useSelector((state: any) => selectTags(state));

  const headers = [{ display: 'Name', property: 'name', type: 'text' }];

  useEffect(() => {
    setData(tagsOnly);
  }, [tagsOnly]);

  const validationSchema = {
    name: [
      { fn: isRequired, msg: 'Tag name is required' },
      { fn: isUnique(tags.map((t) => t.name)), msg: 'Tag name has already been used' },
      { fn: isAlphaNumNWS, msg: 'Tag name can only have letters and numbers' },
      { fn: maxLength(20), msg: 'Tag name can only 20 characters' },
    ],
    id: [],
  };

  const handleNewTag = () => setData([...data, { id: '', name: '' }]);
  const handleReset = () => setData(tagsOnly);
  const handleCreateTag = async (tag) => await dispatch(createTag(tag));

  const handleDeleteTag = async (i) => {
    const tag = data[i];
    await dispatch(deleteTag(tag));
  };

  return (
    <section>
      <Wrapper>
        <Table
          headers={headers}
          editHeaders={headers}
          title="Tags"
          editData={editData}
          setEditData={setEditData}
          data={data}
          canEdit={false}
          canDelete={isManager}
          canAdd={isManager}
          validationSchema={validationSchema}
          actions={{
            handleAdd: handleNewTag,
            handleEdit: handleCreateTag,
            handleDelete: handleDeleteTag,
            handleReset,
          }}
          styles={{ maxHeight: '70vh' }}
          showActionsInRow={() => true}
          withInnerScroll
        />
      </Wrapper>
    </section>
  );
};

export default Tags;

import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Span = styled.span<{ status: string }>`
  color: ${(props) => props.theme.statusColor(props.status)};
  font-weight: 500;
  width: 4rem;
`;

const DeviceCount = ({ status, value }) =>
  value === 0 ? (
    value
  ) : (
    <Wrapper>
      <Span status={status}>{value}</Span>
    </Wrapper>
  );

export default DeviceCount;

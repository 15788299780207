import { useDispatch } from 'react-redux';
import { fetchTenantInfo } from 'reducers/tenantInfo';
import { usePutFetchData } from 'utils/hooks/APIRequestsHandlers/usePutFetchData';
import useAlert from 'utils/hooks/useAlert';

type UseSaveSettingsProps = {
  tenant: string;
  residentSupportEmail: string;
  residentSupportPhone: string;
};

type UseSaveSettingsReturn = { handleSaveSettings: () => void; isSending: boolean };

export const useSaveSettings = ({
  tenant,
  residentSupportEmail,
  residentSupportPhone,
}: UseSaveSettingsProps): UseSaveSettingsReturn => {
  const dispatch = useDispatch();
  const { successAlert } = useAlert();

  const handleSuccess = () => {
    successAlert('Resident app settings saved successfully');
    dispatch(fetchTenantInfo());
  };

  const saveMutation = usePutFetchData({
    url: `${process.env.REACT_APP_APIURL}Tenants/${tenant}/Settings/ResidentApp`,
    onSuccess: handleSuccess,
    enableErrorHandler: true,
  });

  const handleSaveSettings = () =>
    saveMutation.mutate({
      residentAppContactEmail: residentSupportEmail,
      residentAppContactPhoneNumber: residentSupportPhone,
    });

  return { handleSaveSettings, isSending: saveMutation.isLoading };
};

import React from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import styled from 'styled-components';

const TagSpan = styled.span`
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem 0.75rem 1.5rem;
  margin: 0 0.5rem;
  font-size: ${(props) => props.theme.textSmall};
  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.primaryLight};
  border-radius: 20px;
`;

const RemoveIcon = styled.span`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
  cursor: pointer;

  & svg {
    height: 100%;
    width: 100%;
    fill: currentColor;
  }
`;

const TagItem = ({ tag, showRemove, handleRemoveClick }) => {
  if (tag)
    return (
      <TagSpan>
        {tag.name}
        {showRemove && (
          <RemoveIcon onClick={() => handleRemoveClick(tag.id)}>
            <SvgIcon type="close" />
          </RemoveIcon>
        )}
      </TagSpan>
    );

  return null;
};

export default TagItem;

import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TableCell,
} from '@mui/material';

import { BulkActionRow } from 'components/atoms';
import { BulkActionTableWrapper } from './BulkActionTable.styles';

type BulkActionTableProps = {
  failedInstallsLength: number;
  allInstallsLength: number;
  isAllInstalled: boolean;
  allInstallMessage: string;
  styles?: { maxHeight?: number | string; minWidth?: number | string };
  handleReset: (id: string) => void;
  data: {
    head: string[];
    body: {
      cells: string[];
      id: string;
      status: { loading: boolean; error: boolean; success: boolean };
    }[];
  };
};

export const BulkActionTable: React.FC<BulkActionTableProps> = ({
  failedInstallsLength,
  allInstallsLength,
  isAllInstalled,
  allInstallMessage,
  styles,
  handleReset,
  data,
}) => (
  <BulkActionTableWrapper>
    <TableContainer className="bulk-action-table" sx={{ maxHeight: styles?.maxHeight || 300 }}>
      <Table stickyHeader aria-label="sticky table" sx={{ minWidth: styles?.minWidth || '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell className="bulk-action-table__cell" />
            {data.head.map((cell) => (
              <TableCell className="bulk-action-table__cell" key={cell} align="left">
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.body.map(({ cells, status, id }, rowIndex) => (
            <BulkActionRow
              cells={cells}
              status={status}
              handleReset={() => handleReset(id)}
              key={rowIndex.toString()}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <div className="bulk-action-table-message">
      {!!failedInstallsLength && (
        <Typography className="bulk-action-table-message__failed">
          {failedInstallsLength}/{allInstallsLength} failed
        </Typography>
      )}
      {isAllInstalled && (
        <Typography className="bulk-action-table-message__all-installed">
          {allInstallMessage}
        </Typography>
      )}
    </div>
  </BulkActionTableWrapper>
);

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ContentItem = styled.div`
  display: inline-block;
  & > span {
    color: ${(props) => props.theme.spanBlue};
  }
`;

export const DeviceInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;

  & > div {
    margin-bottom: 1rem;
    width: 33%;
  }

  @media ${(props) => props.theme.mobileL} {
    flex-direction: column;

    & > div {
      width: 100%;
      margin: 0.25rem;

      & > span:first-child {
        width: 10rem;
      }
    }
  }
`;

export const DeviceInfoCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  & > div {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 5px;
  }
`;

export const StatusInfo = styled.div`
  display: flex;
  & > div {
    width: 33%;
    display: flex;
    align-items: center;

    & > span:first-child {
      margin-right: 1rem;
    }
  }

  @media ${(props) => props.theme.tabPort} {
    flex-direction: column;

    & > div {
      width: 100%;
      margin: 0.25rem;

      & > span:first-child {
        width: 10rem;
      }
    }
  }
`;

export const StyledLink = styled.a`
  color: ${(props) => props.theme.blue};
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  padding-right: 1rem;

  &:hover {
    color: ${(props) => props.theme.darkBlue};
  }
`;

export const PropertyPageLink = styled(Link)`
  font-size: 1.44rem;
  font-weight: 500;
  color: ${(props) => props.theme.blue};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.darkBlue};
  }
`;
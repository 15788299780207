import React from 'react';

import styled from 'styled-components';

const Icon = styled.span`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;

  & svg {
    width: 100%;
    height: 100%;
    fill: ${(props) => props.theme.error};
  }
`;

const DeleteIcon = ({ handleClick }) => (
  <Icon onClick={handleClick}>
    <svg>
      <use href="images/icons.svg#icon-delete" />
    </svg>
  </Icon>
);

export default DeleteIcon;

import React from 'react';

import styled from 'styled-components';

const OccupancyStatus = styled.span<{ status: string }>`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  /* padding: .5rem 1rem; */
  color: ${(props) => props.theme.white};
  border-radius: 20px;
  margin-right: 0.5rem;
  //font-size: ${(props) => props.theme.textSmall};
  background-color: ${(props) => props.theme.statusColor(props.status)};
`;

const OccupancyStatusWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const OccupancyStatusIcon = ({ selectedStatus = 'Occupied' }) => {
  const status = selectedStatus;

  return (
    <OccupancyStatusWrapper>
      <OccupancyStatus status={status} />
      {status}
    </OccupancyStatusWrapper>
  );
};

export default OccupancyStatusIcon;

import React from 'react';

import { SvgHoverSpan } from 'components/atoms/SvgIcon';
import styled from 'styled-components';
import { FilterDisplay } from 'types/Listing';
import { formatAsDate } from 'utils/helpers';

const FilterPillWrapper = styled.div<{ removeable: boolean }>`
  border-radius: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: ${({ theme }) => theme.offwhite};
  border: 2px solid #666;
  margin-right: 1rem;
  padding: 0.25rem 1rem;
  min-width: 10rem;
  max-width: 15rem;

  ${(props) => (props.removeable ? 'cursor: pointer' : '')};

  & > div {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    width: 80%;

    & > span {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    & > span:first-child {
      font-size: 1rem;
    }

    & > span:not(:first-child) {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
`;
interface FilterPillProps {
  filter: FilterDisplay;
  handleRemove(id: string): void;
  handleClick: any;
  removeable: boolean;
}
const FilterPill = ({ filter, handleRemove, handleClick, removeable }: FilterPillProps) => {
  const handleOnClick = () => {
    if (removeable) handleClick();
  };

  let value;
  switch (filter.dataType) {
    case 'date':
      value = formatAsDate(filter.value);
      break;
    case 'bool':
    case 'number':
      value = filter.value.toString();
      break;
    default:
      value = Array.isArray(filter.value)
        ? filter.value.filter((v) => v).join(', ')
        : filter.value.toString();
      break;
  }

  return (
    <FilterPillWrapper onClick={handleOnClick} removeable={removeable}>
      <div>
        <span>{filter.label}:</span>
        <span>{value}</span>
      </div>
      {removeable && (
        <SvgHoverSpan type="close" size="2rem" color="#666" handleOnClick={handleRemove} />
      )}
    </FilterPillWrapper>
  );
};

export default FilterPill;

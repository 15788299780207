import React, { useCallback } from 'react';
import moment from 'moment';
import { localPoint } from '@visx/event';
import { bisector } from 'd3-array';

import { DataProps } from 'types/Telemetry';
import { useChartContext, useChartUpdateContext } from 'utils/contexts';
import { getY } from '../utils';

interface TooltipMetaObj {
  xPosition: number;
  yPosition: number;
  dateFromXAxis: Date;
  index: number;
  data: DataProps;
}

export const useManageTooltip = ({
  dateScale,
  metricScale,
  metricTooltipRef,
  tooltipLeft,
  containerBounds,
}) => {
  const chartContext = useChartContext();
  const chartUpdateContext = useChartUpdateContext();

  const bisectData = bisector<DataProps, Date>((d) => new Date(d.date)).left;

  const getTooltipData = useCallback(
    (selectedDate: moment.Moment, series: DataProps[], modifier: string): TooltipMetaObj => {
      const xPosition = dateScale(selectedDate) || 0;
      const dateFromXAxis = dateScale.invert(xPosition);
      const index = bisectData(series, dateFromXAxis, 1);

      const previousPoint = series[index - 1];
      const currentPoint = series[index];

      const data =
        modifier === 'latest' ? currentPoint || previousPoint : previousPoint || currentPoint;

      const yPosition = metricScale(getY(data));
      const tooltipMeta = {
        xPosition,
        yPosition,
        dateFromXAxis,
        index,
        data,
      };

      return tooltipMeta;
    },
    [bisectData, dateScale, metricScale]
  );

  // Tooltip handler
  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const { x } = localPoint(event) || { x: 0 };
      const dateFromXAxis = dateScale.invert(x);

      chartUpdateContext({
        ...chartContext,
        selectedDateTime: dateFromXAxis,
      });
    },

    [chartContext, chartUpdateContext, dateScale]
  );

  const handleTooltipDefault = useCallback(() => {
    const endOfDay = moment().endOf('day');
    chartUpdateContext({
      ...chartContext,
      selectedDateTime: endOfDay.toDate(),
    });
  }, [chartContext, chartUpdateContext]);

  const handleTooltipPause = useCallback(() => {
    chartUpdateContext({
      ...chartContext,
      pauseSelection: !chartContext.pauseSelection,
    });
  }, [chartContext, chartUpdateContext]);

  const getDateTimeTooltipXPosition = useCallback((): number => {
    if (!tooltipLeft) {
      return 0;
    }

    if (tooltipLeft < 50) {
      return tooltipLeft - 10;
    }

    const containerExtentPosition = Math.floor(containerBounds.right - containerBounds.left - 100);

    if (tooltipLeft <= containerExtentPosition + 50) {
      return tooltipLeft - 60;
    }

    return tooltipLeft >= containerExtentPosition ? tooltipLeft - 100 : tooltipLeft;
  }, [containerBounds.left, containerBounds.right, tooltipLeft]);

  const getMetricTooltipXPosition = useCallback((): number => {
    if (!tooltipLeft) {
      return 0;
    }

    if (tooltipLeft < 50) {
      return tooltipLeft - 10;
    }

    const containerExtentPosition = Math.floor(containerBounds.right - containerBounds.left - 100);
    const metricTooltipWidth: number =
      (metricTooltipRef.current && metricTooltipRef.current.clientWidth) || 0;

    return tooltipLeft <= containerExtentPosition + 50
      ? tooltipLeft - metricTooltipWidth / 2 - 20
      : tooltipLeft - metricTooltipWidth - 30;
  }, [containerBounds.left, containerBounds.right, metricTooltipRef, tooltipLeft]);

  return {
    getMetricTooltipXPosition,
    getDateTimeTooltipXPosition,
    handleTooltipPause,
    handleTooltipDefault,
    handleTooltip,
    getTooltipData,
  };
};

import { ApiErrorsType, ApiV2ErrorType } from 'types';
import { getApiErrorMessage } from './getApiErrorMessage';

type HandleApiErrorsProps = {
  errorToast: (err) => void;
  showDomainErrors?: boolean;
  showValidationErrors?: boolean;
};

export const handleApiErrors =
  ({ errorToast, showDomainErrors = true, showValidationErrors }: HandleApiErrorsProps) =>
    (e: ApiErrorsType) => {
      const errorObject = e?.response?.data as ApiV2ErrorType;

      const domainErrors = errorObject?.domainErrors?.filter(
        (error) => error !== errorObject.message
      );
      const validationErrors = errorObject?.validationErrors?.filter(
        (error) => error !== errorObject.message
      );

      if (errorObject?.message) {
        errorToast(errorObject.message);
      }

      if (domainErrors?.length && showDomainErrors) {
        domainErrors.forEach((error) => {
          errorToast(error);
        });
      }

      if (validationErrors?.length && showValidationErrors) {
        validationErrors.forEach((error) => {
          errorToast(error);
        });
      }

      if (!errorObject?.message && !domainErrors) {
        const errorMessage = getApiErrorMessage(e);

        errorToast(errorMessage);
      }
    };

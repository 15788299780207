const SET_FILTERS = 'SET_FILTERS';
const SET_SELECTED_BUILDING = 'SET_SELECTED_BUILDING';
const SET_STATUS_TYPE = 'SET_STATUS_TYPE';

export const filterTypes = {
  SET_FILTERS,
  SET_SELECTED_BUILDING,
  SET_STATUS_TYPE,
};

export const setSelectedStatus = (status) => ({
  type: SET_STATUS_TYPE,
  payload: { status },
});

const intialFilterState = {
  selectedBuilding: '',
  selectedStatus: 'device',
};

export const filterReducer = (state = intialFilterState, action) => {
  switch (action.type) {
    case filterTypes.SET_SELECTED_BUILDING:
      return {
        ...state,
        selectedBuilding: action.payload.selectedBuilding,
      };
    case filterTypes.SET_STATUS_TYPE:
      return {
        ...state,
        selectedStatus: action.payload.status,
      };
    default:
      return state;
  }
};

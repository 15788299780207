import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import BoilerMaintenanceForm from '../BoilerMaintenanceForm';
import DeviceNotes from '../DeviceNotes';

const MaintenanceTabContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 20px;
`;

const GridItem = styled.div`
  border: 1px solid #dcdcdc;
`;

const BoilerMaintenanceTab = () => {
  const { device, boilerDetails } = useSelector((state: any) => state.device);

  return (
    <MaintenanceTabContainer>
      <GridItem>
        <BoilerMaintenanceForm device={device} isResettable={boilerDetails?.remoteResetSupported} />
      </GridItem>

      <GridItem>
        <DeviceNotes device={device} />
      </GridItem>
    </MaintenanceTabContainer>
  );
};

export default BoilerMaintenanceTab;

import initialiseDatabase from './initDatabase';
import { DbStores } from './storeDefinitions';

let dbStores: DbStores;

export const getDataStore = async () => {
  if (!dbStores) {
    dbStores = await initialiseDatabase();
  }

  return dbStores;
};

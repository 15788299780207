import { useState, useEffect } from 'react';

const getParams = (queryString, values) => {
  const valueIsInQs = values.reduce((acc, cur) => (queryString.includes(cur) ? true : acc), false);
  if (queryString && valueIsInQs) {
    const queryObj = {};
    let qs = queryString;
    if (queryString.charAt(0) === '?') qs = queryString.slice(1);
    qs.split('&').forEach((query) => {
      const qp = query.split('=');
      //if (!values.includes(qp[0])) {
      queryObj[qp[0]] = qp[1];
      //}
    });

    return queryObj;
  }

  return {};
};

const valueInQs = (queryString, values) => values.some((str) => queryString.includes(str));

function useSearchParams(queryString, values) {
  const [searchParams, setSearchParams] = useState(getParams(queryString, values));

  useEffect(() => {
    if (queryString && valueInQs(queryString, values)) {
      setSearchParams(getParams(queryString, values));
    }
    //TO DO REFACTOR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  const updateSearchParams = (obj) => setSearchParams(obj);

  return [searchParams, updateSearchParams];
}

export default useSearchParams;

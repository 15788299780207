import axios from 'axios';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import { getToken } from 'utils/contexts/AuthProvider';
import { useHandleApiErrors } from 'utils/hooks/APIRequestsHandlers';
import { DeviceSummaryProps } from '../constants/dashboard';

type UseGetAdeyWidgetDataProps = {
  tenant: string;
};

export const useGetAdeyWidgetData = ({
  tenant,
}: UseGetAdeyWidgetDataProps): UseQueryResult<
  { adeyDeviceSummary: DeviceSummaryProps },
  unknown
> => {
  const queryClient = useQueryClient();
  const query = `tenants/${tenant}/summary/adey`;
  const onError = useHandleApiErrors();

  return useQuery({
    queryKey: query,
    queryFn: async ({ queryKey }) => {
      const queryData = queryClient.getQueryData([queryKey[0]]);

      if (queryData) {
        return queryData;
      }

      const token = await getToken();

      const res = await axios.get(`${process.env.REACT_APP_APIURL}${queryKey[0]}`, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    },
    retry: false,
    onSuccess: (data) => {
      queryClient.setQueryData([query], data);
    },
    onError,
  });
};

import React, { useState } from 'react';
import ValidatedInput from 'components/elements/ValidatedInput';
import { getInputState } from 'utils/helpers';

import useValidateForm from 'utils/hooks/useValidateForm';
import { TenantSettings } from 'types/TenantInfo/tenantInfo';
import { isValidPhoneValue, isRequired, isEmail } from 'utils/inputValidation';
import { useSaveSettings } from '../../hooks/useSaveSettings';
import { Button, ContactDetailsWrapper, InputWrapper } from './ContactDetails.styles';

export type ContactDetailsProps = {
  settings: TenantSettings;
  tenant: string;
};

const validationSchema = {
  phone: [
    { fn: isRequired, msg: 'Phone number is required' },
    { fn: isValidPhoneValue, msg: 'Phone number is not valid' },
  ],
  email: [
    { fn: isRequired, msg: 'Email is required' },
    { fn: isEmail, msg: 'Email is not valid' },
  ],
};

export const ContactDetails: React.FC<ContactDetailsProps> = ({ settings, tenant }) => {
  const [residentSupportPhone, setResidentSupportPhone] = useState(
    settings.residentAppContactPhoneNumber || ''
  );
  const [residentSupportEmail, setResidentSupportEmail] = useState(
    settings.residentAppContactEmail || ''
  );

  const { handleSaveSettings, isSending } = useSaveSettings({
    tenant,
    residentSupportEmail,
    residentSupportPhone,
  });

  const toBeValidated = {
    phone: residentSupportPhone,
    email: residentSupportEmail,
  };

  const { validationState, validateInput } = useValidateForm(toBeValidated, validationSchema);

  const handleEmailChange = (value) => {
    validateInput(value, 'email', false);
    setResidentSupportEmail(value);
  };

  const handlePhoneChange = (value) => {
    validateInput(value, 'phone', false);
    setResidentSupportPhone(value);
  };

  const isMatchSettings =
    residentSupportEmail === (settings.residentAppContactEmail || '') &&
    residentSupportPhone === (settings.residentAppContactPhoneNumber || '');

  const isSomeErrors = (Object.values(validationState) as { isValid: boolean; err: string }[]).some(
    ({ isValid, err }) => !isValid && !!err
  );

  const isDisabled = isSomeErrors || isSending || isMatchSettings;

  return (
    <ContactDetailsWrapper>
      <h3 className="contact-details__title">Support Contact Details</h3>
      <div className="contact-details">
        <div className="contact-details__inputs">
          <InputWrapper>
            <ValidatedInput
              type="text"
              label="Support Email"
              errMsg={validationState['email'].err}
              inputData={{
                value: residentSupportEmail,
                name: 'email',
                onChange: handleEmailChange,
                placeholder: 'Please enter support email',
                state: getInputState(validationState['email']),
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <ValidatedInput
              type="phone"
              label="Support Phone"
              errMsg={validationState['phone'].err}
              inputData={{
                value: residentSupportPhone,
                name: 'phone',
                onChange: handlePhoneChange,
                placeholder: 'Please enter support phone number',
                state: getInputState(validationState['phone']),
              }}
            />
          </InputWrapper>
        </div>
        <div className="contact-details__button">
          <Button disabled={isDisabled} onClick={handleSaveSettings}>
            Save
          </Button>
        </div>
      </div>
    </ContactDetailsWrapper>
  );
};

import React from 'react';

import FloorControl from 'components/FloorPlan/Overlays/FloorControl';

const FloorSelect = ({ selectedFloor, setSelectedFloor, floors }) => {
  const incrementFloors = () => {
    if (selectedFloor === floors.length - 1) {
      setSelectedFloor(0);
    } else {
      setSelectedFloor(selectedFloor + 1);
    }
  };

  const decrementFloors = () => {
    if (selectedFloor === 0) {
      setSelectedFloor(floors.length - 1);
    } else {
      setSelectedFloor(selectedFloor - 1);
    }
  };

  return (
    <FloorControl width={Math.floor(floors.length / 6)} left="30rem" bottom="2rem">
      <section>
        {floors.map((f, index) => (
          <div
            className={selectedFloor === index ? 'selected ' : ' '}
            key={f.name}
            role="button"
            tabIndex={0}
            onKeyDown={() => setSelectedFloor(index)}
            onClick={() => setSelectedFloor(index)}
          >
            {f.name}
          </div>
        ))}
      </section>
      <hr />
      <section>
        <div onClick={incrementFloors} role="button" tabIndex={0} onKeyDown={incrementFloors}>
          <span>+</span>
        </div>
        <div onClick={decrementFloors} role="button" tabIndex={0} onKeyDown={decrementFloors}>
          <span>-</span>
        </div>
      </section>
    </FloorControl>
  );
};

export default FloorSelect;

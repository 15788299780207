import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SvgIcon from 'components/atoms/SvgIcon';
import { updateDeviceLocation, updateMonitoredLedCircuit } from 'reducers/device/deviceActions';
import { setMapData } from 'reducers/mapData';
import { Areas } from 'types/roles';
import { isInventory } from 'utils/deviceUtils';
import { formatDate } from 'utils/helpers';
import useAccess from 'utils/hooks/useAccess';
import { parseSerialNumber } from 'utils';
import { isRequired } from 'utils/inputValidation';
import { EmeredDevice } from 'types/EmeredTypes';
import { DeviceAccessRightsTagWrapperApiV1 } from 'components/molecules';
import ResultsGraph from '../../elements/Graphs/ResultsGraph';
import DeviceStatusIcon from '../../Icons/DeviceStatus';
import TestResultIndicator from '../../Icons/TestResultIndicator';
import DeviceDetailsEditInput from '../DetailsEdit';
import DeviceTags from '../DeviceTags';
import EmeredRepositionModal from './molecules/EmeredRepositionModal';
import LuminaireUpdateModal from './molecules/LuminaireUpdateModal';
import {
  StatusInfo,
  ContentItem,
  DeviceInfo,
  InputWrapper,
  EditIcon,
  UpdateHeader,
  TestInfo,
  TestResultGraph,
} from './EmergencyLightDetails.styles';
import { MONITORED_LED_CIRCUIT_OPTIONS } from './constants';

interface XAxisDate {
  name: string;
  data: Date;
}

interface TestResults {
  xAxis: XAxisDate;
  series: Array<any>;
}

type EmergencyLightDetailsProps = {
  device: EmeredDevice;
  triggerUpdateDevice: () => void;
};

const EmergencyLightDetails: React.FC<EmergencyLightDetailsProps> = ({
  device,
  triggerUpdateDevice,
}) => {
  const dispatch = useDispatch();

  const { statusTest, emeredEquipment } = useSelector((state: any) => state.device);

  const [results, setResults] = useState<TestResults>();
  const [luminaireEdit, setLuminaireEdit] = useState(false);
  const [showEmeredRespoitionModal, setShowEmeredRespoitionModal] = useState(false);
  const { isEdit } = useAccess(Areas.lighting);

  const { model } = parseSerialNumber(device?.serialNumber || '');
  const showMonitoredLedCircuit = model === 'F';

  const setTest = (testData) => {
    if (testData) {
      setResults({
        xAxis: {
          name: 'Time in seconds',
          data: testData.time,
        },
        series: Object.keys(testData.data).map((key) => ({
          name: key,
          data: testData.data[key],
        })),
      });
    }
  };

  const locationVal = [{ fn: isRequired, msg: 'Location can not be empty' }];

  const handleLocationUpdate = async (value) => {
    await dispatch(updateDeviceLocation(device.deviceId, value));
  };
  const canEdit = isEdit && !isInventory(device) && !device.userHasReadOnlyAccess;

  const handleRepositionMapEdit = () => {
    dispatch(
      setMapData([
        {
          lat: device.lat,
          lng: device.long,
          deviceId: device.deviceId,
          deviceName: device.deviceName,
          serialNumber: device.serialNumber,
          deviceType: device.deviceType,
          deviceStatus: device.deviceStatus,
          connectionStatus: device.connectionStatus,
          floor: device.floorName,
          building: device.buildingId,
        },
      ])
    );
    setShowEmeredRespoitionModal(true);
  };

  const handleMonitoredLedCircuitUpdate = async (value) => {
    const val = Object.keys(MONITORED_LED_CIRCUIT_OPTIONS).find(
      (key) => MONITORED_LED_CIRCUIT_OPTIONS[key] === value
    );

    await dispatch(updateMonitoredLedCircuit(device.deviceId, val));
  };

  const ledChargingVal = [{ fn: isRequired, msg: '' }];

  useEffect(() => {
    setTest(statusTest);
  }, [statusTest]);

  return (
    <>
      {device && (
        <>
          <section>
            <h5>Device Statuses</h5>
            <StatusInfo>
              <ContentItem>
                <span>Connection Status:</span>
                <DeviceStatusIcon selectedStatus={'connection'} device={device} />
              </ContentItem>
              <ContentItem>
                <span>Device Status:</span>
                <DeviceStatusIcon selectedStatus={'device'} device={device} />
              </ContentItem>
            </StatusInfo>
          </section>
          <section>
            <h5>Device Infomation</h5>
            <DeviceInfo>
              <ContentItem>
                <span>Building:</span>
                {device.buildingName}
              </ContentItem>
              <ContentItem>
                <span>Floor:</span>
                {device.floorName}
              </ContentItem>
              <InputWrapper>
                <span>Location:</span>
                {canEdit ? (
                  <DeviceDetailsEditInput
                    type="text"
                    value={device.room}
                    action={handleLocationUpdate}
                    actionType="UPDATE_LOCATION"
                    validators={locationVal}
                    width="20rem"
                  />
                ) : (
                  device.room
                )}
              </InputWrapper>
              {showMonitoredLedCircuit && (
                <InputWrapper>
                  <span>What is the Led charging?:</span>
                  {canEdit ? (
                    <DeviceDetailsEditInput
                      type="dropdown"
                      value={
                        MONITORED_LED_CIRCUIT_OPTIONS[emeredEquipment?.monitoredLedCircuit] || ''
                      }
                      action={handleMonitoredLedCircuitUpdate}
                      actionType="UPDATE_MONITORED_LED_CIRCUIT"
                      validators={ledChargingVal}
                      width="25rem"
                      options={{
                        options: Object.values(MONITORED_LED_CIRCUIT_OPTIONS),
                        readOnly: true,
                      }}
                    />
                  ) : (
                    MONITORED_LED_CIRCUIT_OPTIONS[emeredEquipment?.monitoredLedCircuit] || ''
                  )}
                </InputWrapper>
              )}
              <ContentItem>
                <span>Map Coordinates:</span>
                {` (Lat: ${device.lat} Long: ${device.long})`}
                {canEdit && (
                  <EditIcon onClick={() => handleRepositionMapEdit()}>
                    <SvgIcon type="edit" />
                  </EditIcon>
                )}
              </ContentItem>
              <ContentItem>
                <span>Group:</span>
                {device.group}
              </ContentItem>
              <ContentItem>
                <span>Serial Number:</span>
                {device.serialNumber}
              </ContentItem>
            </DeviceInfo>
          </section>
          <section>
            <UpdateHeader>
              <h5>Luminare Infomation</h5>
              {canEdit && (
                <EditIcon onClick={() => setLuminaireEdit(true)}>
                  <SvgIcon type="edit" />
                </EditIcon>
              )}
            </UpdateHeader>
            <DeviceInfo>
              <ContentItem>
                <span>Name:</span>
                {emeredEquipment?.luminaireName}
              </ContentItem>
              <ContentItem>
                <span>Lamp Type:</span>
                {emeredEquipment?.luminaireType}
              </ContentItem>
              <ContentItem>
                <span>Inverter Type:</span>
                {emeredEquipment?.inverter}
              </ContentItem>
              <ContentItem>
                <span>Battery Type:</span>
                {emeredEquipment?.batteries[0]?.type}
              </ContentItem>
              <ContentItem>
                <span>Battery Capacity (Ah):</span>
                {emeredEquipment?.batteries[0]?.capacity ?? 0}
              </ContentItem>
              <ContentItem>
                <span>Battery Voltage (V):</span>
                {emeredEquipment?.batteries[0]?.voltage ?? 0}
              </ContentItem>
              <ContentItem>
                <span>Battery Change Date:</span>
                {emeredEquipment && formatDate(emeredEquipment?.batteries[0]?.installedDate)}
              </ContentItem>
            </DeviceInfo>
          </section>
          <section>
            <h5>Test Information</h5>
            <TestInfo>
              <ContentItem>
                <span>Test Name:</span>
                {statusTest?.testType}
              </ContentItem>
              <ContentItem>
                <span>Tested:</span>
                {statusTest && formatDate(statusTest?.testDate)}
              </ContentItem>
              <ContentItem>
                <span>Test Result:</span>
                <TestResultIndicator result={statusTest?.result} />
              </ContentItem>
              <ContentItem>
                <span>Reason:</span>
                {statusTest?.reason}
              </ContentItem>
            </TestInfo>
          </section>
          {results && (
            <section>
              <TestResultGraph>
                <ResultsGraph legendAlignment="left" data={results.series} xAxis={results.xAxis} />
              </TestResultGraph>
            </section>
          )}
          <section>
            <DeviceAccessRightsTagWrapperApiV1 device={device} onSuccess={triggerUpdateDevice} />
          </section>
          <DeviceTags limited={isEdit && !device.userHasReadOnlyAccess} device={device} />
          <LuminaireUpdateModal
            showModal={luminaireEdit}
            setShowModal={setLuminaireEdit}
            equipment={emeredEquipment}
            deviceId={device.deviceId}
          />
          {showEmeredRespoitionModal && (
            <EmeredRepositionModal
              showModal={showEmeredRespoitionModal}
              setShowModal={setShowEmeredRespoitionModal}
              device={device}
            />
          )}
        </>
      )}
    </>
  );
};

export default EmergencyLightDetails;

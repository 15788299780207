import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import { ResidenceTableMarkDownDialog } from '../ResidenceTableMarkDownDialog/ResidenceTableMarkDownDialog';

type ResidenceTableRowSetTextProps = {
  name: string;
  userId: string;
  refreshTable: () => void;
};

export const ResidenceTableRowSetText: React.FC<ResidenceTableRowSetTextProps> = ({
  name,
  userId,
  refreshTable,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    refreshTable();
  };

  return (
    <>
      <Tooltip arrow placement="top" title="Set Savings and Tips">
        <IconButton onClick={() => setOpen(true)}>
          <ArticleIcon fontSize="large" color="primary" />
        </IconButton>
      </Tooltip>
      {open && (
        <ResidenceTableMarkDownDialog
          onClose={handleClose}
          userId={userId}
          placeholder="Write your markdown here"
          title={`Set Savings and Tips for ${name}`}
        />
      )}
    </>
  );
};

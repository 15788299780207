import { ApiStatuses } from 'types';

export const combineDataStatuses = (statuses: { status: ApiStatuses; error: string | null }[]) => {
  const isLoading = statuses.some(({ status }) => status === ApiStatuses.Loading);
  const isError = statuses.some(({ status }) => status === ApiStatuses.Failed) && !isLoading;
  const errors = statuses.map(({ error }) => error).filter(Boolean) as string[];
  const isSuccess =
    statuses.some(({ status }) => status === ApiStatuses.Success) && !isError && !isLoading;

  return { errors, isLoading, isError, isSuccess };
};

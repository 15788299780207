import moment from 'moment';
import { CardExtended, DeviceMetricsResponse, TelemetryView } from 'types/Telemetry';

type AetMetricDataProps = {
  prev: TelemetryView;
  chart: CardExtended;
  response: DeviceMetricsResponse;
};

export const setMetricData = ({ prev, chart, response }: AetMetricDataProps) => {
  const chartIndex = prev.charts.findIndex((el) => el === chart);

  if (chartIndex !== -1) {
    const updatedChart = {
      ...prev.charts[chartIndex],
      dataSeries: response.results?.map(({ timestamp, value }) => ({
        value,
        date: moment.utc(timestamp),
      })),
    };

    const updatedCharts = [...prev.charts];
    updatedCharts[chartIndex] = updatedChart;

    return { ...prev, charts: updatedCharts };
  }

  return prev;
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  selectStoredPageInfo,
  PageInfo,
  setStoredPageInfo,
  clearPageInfo,
} from 'reducers/viewDefinitions';
import { searchObject } from 'utils/helpers';

const sortByProp = (property) => (a, b) => {
  const isDesc = property[0] === '-';
  const prop = isDesc ? property.slice(1) : property;
  if (a[prop] === null) {
    return 1;
  } else if (b[prop] === null) {
    return -1;
  } else if (!isDesc) {
    return a[prop] < b[prop] ? -1 : 1;
  } else {
    return a[prop] < b[prop] ? 1 : -1;
  }
};

const pipe =
  (...fns) =>
    (x) =>
      fns.reduce((v, f) => f(v), x);

const searchData = (searchTerm, columns) => (data) =>
  data.filter((dr) => searchObject(searchTerm.trim(), dr, columns));

const sortData = (sortProp) => (data) => {
  if (sortProp) {
    const arr = [...data];

    return arr.sort(sortByProp(sortProp));
  }

  return data;
};

const useSortSearchAndPage = (data, selectedView) => {
  const dispatch = useDispatch();
  const storedPageInfo: PageInfo = useSelector((state: any) => selectStoredPageInfo(state));

  const setInfo = (prop, defaultValue: any = '') => {
    if (selectedView.viewId === storedPageInfo?.viewId) return storedPageInfo[prop];

    return defaultValue;
  };

  const [transformed, setTransformed] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    start: 1,
    end: 101,
    total: data.length,
    viewTotal: data.length,
  });
  const [currentPage, setCurrentPage] = useState(setInfo('currentPage', 1));
  const [searchTerm, setSearchTerm] = useState(setInfo('searchTerm'));
  const [sortProp, setSortProp] = useState(setInfo('sortProp'));
  const [isInit, setIsInit] = useState(true);

  const updateSortProp = (prop) => setSortProp(`${prop === sortProp ? `-${prop}` : prop}`);

  const numPerPage = 100;

  const pageData = (currentPage) => (xData: any[]) => {
    const start: number = (currentPage - 1) * numPerPage;
    const end: number = start + numPerPage;
    setPageInfo({
      ...pageInfo,
      start,
      end,
      total: data.length,
      viewTotal: xData.length,
    });

    return xData.slice(start, end);
  };

  useEffect(() => {
    if (!storedPageInfo) {
      setCurrentPage(1);
      setSearchTerm('');
      setSortProp('');
    }
  }, [storedPageInfo]);

  useEffect(() => {
    if (!isInit) {
      setCurrentPage(1);
      setSearchTerm('');
      setSortProp('');
    }
    // TODO: REFACTOR TO USEREDUCERselectedRowInfo
  }, [selectedView]);

  useEffect(() => {
    pipe(
      searchData(searchTerm, selectedView.columns),
      sortData(sortProp),
      pageData(isInit ? currentPage : 1),
      setTransformed
    )(data);
    if (!isInit) setCurrentPage(1);
    // TODO: REFACTOR TO USEREDUCERselectedRowInfo
  }, [sortProp, searchTerm]);

  useEffect(() => {
    pipe(
      searchData(searchTerm, selectedView.columns),
      sortData(sortProp),
      pageData(currentPage),
      setTransformed
    )(data);
    if (isInit) setIsInit(false);
    // TODO: REFACTOR TO USEREDUCERselectedRowInfo
  }, [data, currentPage]);

  useEffect(() => {
    const updateStoredPageInfo = async () =>
      await dispatch(
        setStoredPageInfo({ currentPage, searchTerm, sortProp, viewId: selectedView.viewId })
      );

    return () => {
      updateStoredPageInfo();
    };
    // TODO: REFACTOR TO USEREDUCERselectedRowInfo
  }, [sortProp, searchTerm, currentPage]);

  useEffect(() => {
    if (selectedView?.viewId !== storedPageInfo?.viewId) {
      dispatch(clearPageInfo());
    }
  }, [selectedView]);

  return {
    sortInfo: {
      searchTerm,
      sortProp,
      pageInfo,
      currentPage,
      viewData: transformed,
    },
    sortFunctions: {
      updateCurrentPage: setCurrentPage,
      updateSortProp,
      updateSearch: setSearchTerm,
    },
  };
};

export default useSortSearchAndPage;

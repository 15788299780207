import React from 'react';

import Chart from 'react-apexcharts';
import styled from 'styled-components';

const ChartWrapper = styled.div`
  display: inline-block;
  margin: 0 auto;
  width: 8rem;
  height: 6rem;
  background-color: ${(props) => props.theme.white};
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  & h3 {
    text-align: center;
    margin: 0.5rem;
  }
`;

const mapOptions: any = {
  options: {
    chart: {
      id: 'sparkline',
      sparkline: {
        enabled: true,
      },
    },
    xaxis: {
      type: 'numeric',
      categories: [],
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
  },
};

const SparkLine = ({ info, handleOnClick }) => (
  <>
    {info.hasData && (
      <ChartWrapper
        onClick={() => {
          handleOnClick(info);
        }}
      >
        <Chart options={mapOptions.options} series={info.series} type="line" />
      </ChartWrapper>
    )}
    {!info.hasData && (
      <ChartWrapper>
        <h3>No Data</h3>
      </ChartWrapper>
    )}
  </>
);

export default SparkLine;

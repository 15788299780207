import React from 'react';

import styled from 'styled-components';

import LabelledInput from '../LabelledInput';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const Error = styled.span`
  color: ${(props) => props.theme.failed};
  font-size: ${(props) => props.theme.textSmaller};
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: 500;
  position: absolute;
  bottom: -1.75rem;
`;

const ValidatedInput = ({ type, inputData, label, errMsg, errorLabel = false }) => (
  <Wrapper>
    <LabelledInput
      type={type}
      inputData={inputData}
      label={label}
      errorLabel={errorLabel}
      errorMsg={errMsg}
    />
    {errMsg && !errorLabel && <Error>{errMsg}</Error>}
  </Wrapper>
);

export default ValidatedInput;

import BoilerInfo from 'types/BoilerTypes';
import DeviceInfo from 'types/DeviceTypes';
import EmeredInfo from 'types/EmeredTypes';

import { boilerDetailTypes } from './boilerActions';
import { deviceActionTypes } from './deviceActions';
import { emeredActionTypes } from './emeredActions';
import { networkActionTypes } from './networkActions';

interface DeviceState extends DeviceInfo, BoilerInfo, EmeredInfo {
  lastUpdate: Date;
  shouldUpdate: false;
}

const initialState: DeviceState = {
  device: null,
  boilerDetails: null,
  telemetry: null,
  debug: null,
  emeredEquipment: null,
  statusTest: null,
  faults: [],
  messages: [],
  testResults: [],
  testSchedule: [],
  records: [],
  lastUpdate: new Date(),
  shouldUpdate: false,
};

export const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${deviceActionTypes.ADD_DEVICETAG}_SUCCESS`:
    case `${deviceActionTypes.REMOVE_DEVICETAG}_SUCCESS`:
    case `${deviceActionTypes.UPDATE_DEVICETAGS}_SUCCESS`:
      return {
        ...state,
        device: action.payload,
      };

    case `${boilerDetailTypes.FETCH_BOILERDETAILS}_SUCCESS`:
    case `${boilerDetailTypes.REFRESH_BOILERDETAILS}_SUCCESS`:
    case `${emeredActionTypes.FETCH_EMEREDDETAILS}_SUCCESS`:
    case `${emeredActionTypes.REFRESH_EMEREDDETAILS}_SUCCESS`:
    case `${networkActionTypes.FETCH_NETWORKDEVICE}_SUCCESS`:
    case `${networkActionTypes.REFRESH_NETWORKDEVICE}_SUCCESS`:
      return {
        ...state,
        ...action.payload,
        lastUpdate: new Date(),
        shouldUpdate: false,
      };
    case `${boilerDetailTypes.UPDATE_CHSETPOINT}_SUCCESS`:
    case `${boilerDetailTypes.UPDATE_ADDRESS}_SUCCESS`:
    case `${boilerDetailTypes.UPDATE_OCCUPIERID}_SUCCESS`:
    case `${boilerDetailTypes.TOGGLE_CHSETPOINT}_SUCCESS`:
    case `${boilerDetailTypes.TOGGLE_BUZZER}_SUCCESS`:
      return {
        ...state,
        boilerDetails: action.payload,
      };
    case `${boilerDetailTypes.FETCH_BOILERFAULTS}_SUCCESS`:
      return {
        ...state,
        faults: action.payload,
      };
    case `${boilerDetailTypes.FETCH_BOILERMETRICS}_SUCCESS`:
      return {
        ...state,
        telemetry: action.payload,
      };
    case `${boilerDetailTypes.FETCH_BOILERRECORDS}_SUCCESS`:
      return {
        ...state,
        records: action.payload,
      };
    case `${deviceActionTypes.FETCH_MESSAGES}_SUCCESS`:
      return {
        ...state,
        messages: action.payload,
      };
    case `${deviceActionTypes.FETCH_DEBUGINFO}_SUCCESS`:
      return {
        ...state,
        debug: action.payload,
      };
    case `${deviceActionTypes.FETCH_SHOULDDEVICEUPDATE}_SUCCESS`:
      return {
        ...state,
        shouldUpdate: action.payload,
      };
    case `${emeredActionTypes.FETCH_EMEREDTESTS}_SUCCESS`:
      return {
        ...state,
        testResults: action.payload,
      };
    case `${deviceActionTypes.UPDATE_LOCATION}_SUCCESS`:
      return {
        ...state,
        device: action.payload.data,
      };
    case `${deviceActionTypes.UPDATE_MONITORED_LED_CIRCUIT}_SUCCESS`:
      return {
        ...state,
        emeredEquipment: {
          ...(state.emeredEquipment ?? {}),
          monitoredLedCircuit: action.payload?.monitoredLedCircuit,
        },
      };
    case deviceActionTypes.CLEAR_DEVICE:
      return initialState;
    default:
      return state;
  }
};

import styled from 'styled-components';

export const EditFormFieldsControlsWrapper = styled.div`
  .edit-form-fields-controls {
    display: flex;
    gap: 4px;

    &__button {
      &-submit {
        padding: 6px;
        &-icon {
          font-size: 2rem;
        }

        &:not([disabled]) {
          .edit-form-fields-controls__button-submit-icon {
            color: ${(props) => props.theme.success};
          }
        }
      }

      &-close {
        padding: 6px;
        &-icon {
          transform: rotate(45deg);
          font-size: 2rem;
        }

        &:not([disabled]) {
          .edit-form-fields-controls__button-close-icon {
            color: ${(props) => props.theme.error};
          }
        }
      }
    }
  }
`;

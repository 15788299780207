import { ConnectedDevicesNameByType, PowerClamps } from 'types/BoilerTypes/BcmConnectDetails';
import { parseSerialNumber } from './parseSerialNumber';

export interface CompleteConnectedDeviceInstallProps {
  bcmId: string;
  serialNumber: string;
  deviceId: string;
  location: string | null;
  locationDetails?: string | null;
  powerClamps?: PowerClamps;
  resetAction?: () => void;
}

export const completeConnectedDeviceInstall = ({
  bcmId,
  serialNumber,
  deviceId,
  location,
  locationDetails,
  powerClamps,
  resetAction,
}: CompleteConnectedDeviceInstallProps) => {
  resetAction?.();

  const { product } = parseSerialNumber(serialNumber);

  const deviceType = ConnectedDevicesNameByType[product]?.replace(/\s/g, '');

  const baseUrl = process.env.REACT_APP_ENG_URL;
  let queryString;

  if (powerClamps && Object.keys(powerClamps).length > 0) {
    queryString = Object.keys(powerClamps)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(powerClamps?.[key])}`)
      .join('&');
  } else {
    const locationParam = location ? `location=${encodeURIComponent(location)}` : '';
    const locationDetailsParam = locationDetails
      ? `locationDetails=${encodeURIComponent(locationDetails)}`
      : '';

    queryString = `${locationParam}${
      location && locationDetails ? '&' : ''
    }${locationDetailsParam}`;
  }

  const redirectUrl = `${baseUrl}ConnectedDeviceInstall/${deviceType}/${bcmId}/${serialNumber}/${deviceId}`;
  const fullUrl = `${redirectUrl}?${queryString}`;

  window.open(fullUrl, '_blank');
};

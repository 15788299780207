import React, { useEffect, useState } from 'react';

import moment from 'moment';
import 'moment/min/locales';
import styled from 'styled-components';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { DateRangePickerWrapper } from 'styles/commonStyles';
import EventCard from '../Cards/EventCard';
import LineGraphCard from '../Cards/LineGraphCard';
import NumericCard from '../Cards/NumericCard';
import {
  getMetricIdListFromCards,
  handleGetDeviceMetrics,
  handleGetDeviceEvents,
  mapResponseToCards,
} from '../Configs/DataHandlers';
import { EventDataResponseByContextId, View } from '../Configs/TelemetryConfigs';
import { useChartContext, useChartUpdateContext } from './ChartContext';

const ChartCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 8.3%);
`;

const ChartArea = styled.div`
  width: 100%;
  margin: 5px;
  background-color: #f1f1f1;
`;

const Chart = ({ cards, id }: View) => {
  const chartContext = useChartContext();
  const chartUpdateContext = useChartUpdateContext();

  const [dateRange, setDateRange] = useState<{ startDate: moment.Moment; endDate: moment.Moment }>({
    startDate: moment(moment.now()).subtract(1, 'weeks'),
    endDate: moment(moment.now()),
  });

  const [zoomedDateRange, setZoomedDateRange] = useState<{
    startDate: moment.Moment;
    endDate: moment.Moment;
  }>(dateRange);

  const [chartId, setChartId] = useState(id);

  const locale = navigator.language;
  moment.locale(locale);

  const [chartConfig, setChartConfig] = useState(cards);
  const [isFetchingMetrics, setIsFetchingMetrics] = useState(true);

  const [isFocused, setIsFocused] = useState(false);

  const addDataToCardConfig = async () => {
    try {
      setIsFetchingMetrics(true);

      const listOfMetricsByContextId = getMetricIdListFromCards(cards);

      const dataResponse = await handleGetDeviceMetrics({
        dataSeriesRequestList: listOfMetricsByContextId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });

      const eventsCard = cards.find((card) =>
        card.dataSeries.some((obj) => obj.dataSeriesId === 'allevents')
      );

      if (eventsCard) {
        const allEventsResponse: EventDataResponseByContextId = await handleGetDeviceEvents({
          eventsCard,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        });

        if (allEventsResponse && dataResponse) {
          dataResponse.forEach(({ contextId }, i) => {
            if (contextId === allEventsResponse.contextId) {
              dataResponse[i].metrics.push(allEventsResponse.cardData);
            }
          });
        }
      }

      if (dataResponse) {
        const updatedCards = mapResponseToCards(cards, dataResponse);
        setChartConfig(updatedCards);
        setIsFetchingMetrics(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      if (chartContext.pauseSelection) {
        chartUpdateContext({
          ...chartContext,
          pauseSelection: false,
        });
      }
    }
  };

  const handleDateRangeChange = (startDate, endDate) => {
    setIsFocused(false);
    setDateRange({
      startDate,
      endDate,
    });
  };

  useEffect(() => {
    setChartConfig(cards);
    if (chartId !== id) {
      setChartId(id);
      addDataToCardConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  useEffect(() => {
    if (!isFocused) {
      if (dateRange.startDate && dateRange.endDate) {
        setZoomedDateRange({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        });
        addDataToCardConfig();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <>
      <ChartArea key={chartId}>
        <DateRangePickerWrapper>
          <DateRangePicker
            onChange={([start, end]: moment.MomentInput[]) =>
              handleDateRangeChange(moment(start), moment(end))
            }
            value={[dateRange.startDate.toDate(), dateRange.endDate.toDate()]}
            format="dd/MM/y"
            rangeDivider="➔"
            onFocus={() => setIsFocused(true)}
            maxDate={new Date()}
            clearIcon={null}
          />
        </DateRangePickerWrapper>
        <ChartCardGrid>
          {chartConfig &&
            chartConfig.map((cardConfig: any, i) => {
              switch (cardConfig.cardType) {
                case 'eventsGraph':
                  return (
                    <EventCard
                      key={`${cardConfig.cardtype}_${i}`}
                      cardConfig={cardConfig}
                      isFetchingMetrics={isFetchingMetrics}
                      dateRange={zoomedDateRange}
                    />
                  );
                case 'lineGraph':
                  return (
                    <LineGraphCard
                      key={`${cardConfig.cardtype}_${i}`}
                      cardConfig={cardConfig}
                      isFetchingMetrics={isFetchingMetrics}
                      dateRange={zoomedDateRange}
                    />
                  );
                case 'numericCard':
                  return (
                    <NumericCard
                      key={`${cardConfig.cardtype}_${i}`}
                      cardConfig={cardConfig}
                      isFetchingMetrics={isFetchingMetrics}
                    />
                  );
                default:
                  return undefined;
              }
            })}
        </ChartCardGrid>
      </ChartArea>
    </>
  );
};

export default Chart;

import React from 'react';

import { deviceTypeToArea } from 'utils/deviceUtils';
import {
  DashboardContentProps,
  DashboardDeviceInfoProps,
  WidgetLinks,
} from 'components/DashboardView/constants/dashboard';
import useAccess from 'utils/hooks/useAccess';

import { WidgetContainer, DeviceWidget } from '../../molecules';

type WidgetProps = DashboardContentProps &
  DashboardDeviceInfoProps & {
    isFetching: boolean;
    isFailedToLoad: boolean;
    links: WidgetLinks;
  };

export const Widget: React.FC<WidgetProps> = ({
  style,
  title,
  deviceType,
  links,
  isFetching,
  deviceSummary,
  isFailedToLoad,
}) => {

  const { isRead } = useAccess(deviceTypeToArea(deviceType[0]));

  return (
    <WidgetContainer
      style={style}
      title={title}
      to={links.main}
      canRead={isRead}
      isFetching={isFetching}
      isFailedToLoad={isFailedToLoad}
    >
      <DeviceWidget
        deviceSummary={deviceSummary}
        links={links}
        deviceType={deviceType}
      />
    </WidgetContainer>
  );
};

const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR';
const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';

export const reportTypes = {
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_ERROR,
  FETCH_REPORT_SUCCESS,
};

const initialState = {
  report: null,
};

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportTypes.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload.report,
      };
    default:
      return state;
  }
};

import React, { useEffect, useRef, useState } from 'react';
import { CheckCircle } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { useEffectOnce } from 'utils/hooks';
import { SuccessWindowWrapper } from './DialogSuccessMessage.styles';

export type DialogSuccessMessageProps = {
  message?: string;
  showMessage?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  onComponentUnmount?: () => void;
  timeout?: number;
  dialogClosed?: boolean;
};

export const DialogSuccessMessage: React.FC<DialogSuccessMessageProps> = ({
  message,
  showMessage,
  onClose,
  onComponentUnmount,
  timeout = 1500,
  dialogClosed,
  children,
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (showMessage) {
      setSuccessMessage(message ?? '');
      setShowSuccessMessage(true);
      timer.current = setTimeout(() => {
        onClose?.();
      }, timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMessage]);

  useEffect(() => {
    if (timer.current && dialogClosed) {
      clearTimeout(timer.current);
    }
  }, [onClose, dialogClosed]);

  useEffectOnce(() => () => {
    onComponentUnmount?.();
    setShowSuccessMessage(false);

    if (timer.current) {
      clearTimeout(timer.current);
    }
  });

  return showSuccessMessage ? (
    <SuccessWindowWrapper>
      <CheckCircle className="success-window__icon" />
      <Typography className="success-window__text">{successMessage}</Typography>
    </SuccessWindowWrapper>
  ) : (
    <>{children}</>
  );
};

import React, { useState, createContext, useContext } from 'react';

interface ChartProviderProps {
  children: any;
}

interface GlobalCoOrds {
  selectedDateTime: Date;
  pauseSelection: boolean;
}

const defaultContextObj = {
  selectedDateTime: new Date(),
  pauseSelection: false,
};

const ChartContext = createContext<GlobalCoOrds>(defaultContextObj);
const ChartUpdateContext = createContext<(defaultContextObj: GlobalCoOrds) => void>(
  () => undefined
);

export const useChartContext = () => useContext(ChartContext);
export const useChartUpdateContext = () => useContext(ChartUpdateContext);

export const ChartProvider = ({ children }: ChartProviderProps) => {
  const [globalCardCoOrds, setGlobalCardCoOrds] = useState<GlobalCoOrds>(defaultContextObj);

  const updateCardCoOrds = ({ selectedDateTime, pauseSelection }: GlobalCoOrds) => {
    setGlobalCardCoOrds({
      selectedDateTime,
      pauseSelection,
    });
  };

  return (
    <ChartContext.Provider value={globalCardCoOrds}>
      <ChartUpdateContext.Provider value={updateCardCoOrds}>{children}</ChartUpdateContext.Provider>
    </ChartContext.Provider>
  );
};

import { useState, useEffect, useCallback, useRef } from 'react';

const setInitErrors = (values) =>
  Object.keys(values).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: { msg: '', isValid: false },
    }),
    {}
  );

const useSchemaValidation = (initValues, schema) => {
  const [isValid, setIsValid] = useState(false);
  const [values, setValues] = useState(initValues);
  const [errors, setErrors] = useState<any>(setInitErrors(initValues));
  const timeOut = useRef<ReturnType<typeof setTimeout>>();

  const validateSchema = useCallback(() => {
    const isValid = Object.keys(errors).reduce(
      (acc, curr) => (errors[curr].isValid ? acc : false),
      true
    );
    setIsValid(isValid);
  }, [errors]);

  useEffect(() => {
    validateSchema();
  }, [validateSchema]);

  const validateValue = (value, name) =>
    schema[name].reduce((acc, cur) => (acc === '' && !cur.fn(value) ? cur.msg : acc), '');

  const setValue = (name, value) =>
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const setValid = (name) =>
    setErrors((prevState) => ({
      ...prevState,
      [name]: { isValid: true, msg: '' },
    }));

  const setErrorMsg = (name, msg) =>
    setErrors((prevState) => ({
      ...prevState,
      [name]: {
        isValid: false,
        msg: msg,
      },
    }));

  const handleValueUpdate = (value, name, delay = true) => {
    const msg = validateValue(value, name);
    const inputValid = !msg;

    setValue(name, value);

    if (inputValid) {
      setValid(name);

      return inputValid;
    }

    if (delay) {
      timeOut.current = setTimeout(() => setErrorMsg(name, msg), 1000);
    } else {
      setErrorMsg(name, msg);
    }

    return inputValid;
  };

  const validateValues = (setErrors = true) => {
    const formValid = setErrors
      ? Object.keys(values).reduce(
        (acc, cur) => (handleValueUpdate(values[cur], cur, false) ? acc : false),
        true
      )
      : Object.keys(values).reduce(
        (acc, cur) => (validateValue(values[cur], cur) ? false : acc),
        true
      );
    setIsValid(formValid);

    return formValid;
  };

  return {
    handleValueUpdate,
    validateSchema: validateValues,
    validationState: {
      values,
      errors,
      isValid,
    },
  };
};

export default useSchemaValidation;

import React from 'react';

import Button from 'components/atoms/Button';
import Modal from 'components/elements/Modal';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;

  & p {
    margin-bottom: 2.5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    margin: 0 1rem;
  }
`;

const DeleteModal = ({ showModal, handleClose, handleDelete }) => {
  const handleDeleteClick = () => {
    handleClose();
    handleDelete();
  };

  return (
    <Modal title="Delete View" showModal={showModal} handleClose={handleClose} width="40%">
      <Wrapper>
        <p>Are you sure you want to delete this view</p>
        <ButtonWrapper>
          <Button width="12rem" type="delete" clickCallback={handleDeleteClick}>
            Delete
          </Button>
          <Button width="12rem" type="clear" clickCallback={handleClose}>
            Cancel
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
};

export default DeleteModal;

import styled from 'styled-components';

export const EllipsisWithTooltipWrapper = styled.div<{ maxWidth: string }>`
  max-width: ${(props) => props.maxWidth};

  .ellipsis-with-tooltip {
    &__wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
`;

import React from 'react';

import styled from 'styled-components';
import { Areas } from 'types/roles';
import useAccess from 'utils/hooks/useAccess';

import AddBuildingButton from './AddBuildingButton';

const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
  margin: 4rem 10%;
  padding: 6rem;
  text-align: center;
  height: 70vh;

  & h1 {
    margin: 10% 0 6rem;
  }
`;

const NoBuildings = () => {
  const { isManager } = useAccess(Areas.misc);

  return (
    <Wrapper>
      <h1>No buildings!</h1>
      {isManager && <AddBuildingButton />}
    </Wrapper>
  );
};

export default NoBuildings;

import { useState } from 'react';

import { BulkDeviceDetails } from 'components/DeviceActions/DeviceActionProps';
import { useBulkUpdateDeviceAccessTag } from './useBulkUpdateDeviceAccessTag';

type UseBulkUpdateDeviceAccessTagStateProps = {
  deviceDetails: BulkDeviceDetails;
  accessRightsTagId: string | null;
};

export const useBulkUpdateDeviceAccessTagState = ({
  deviceDetails,
  accessRightsTagId,
}: UseBulkUpdateDeviceAccessTagStateProps) => {
  const { mutate, devicesStatuses } = useBulkUpdateDeviceAccessTag({ deviceDetails });
  const [submitClicked, setSubmitClicked] = useState(false);

  const failedDevices = Object.entries(devicesStatuses)
    .filter(([, state]) => state.error)
    .map(([deviceId]) => deviceDetails.find((device) => device.deviceId === deviceId));

  const isAllInstalled = Object.values(devicesStatuses).every((device) => device.success);
  const isLoading = Object.values(devicesStatuses).some((device) => device.loading);

  const handleBulkUpdateDevices = () => {
    setSubmitClicked(true);

    if (failedDevices.length > 0) {
      failedDevices.forEach((device) => {
        mutate({
          body: { accessRightsTagId },
          deviceId: device?.deviceId || '',
        });
      });
    } else {
      deviceDetails.forEach((device) => {
        mutate({
          body: { accessRightsTagId },
          deviceId: device?.deviceId || '',
        });
      });
    }
  };

  const tableData = {
    head: ['Serial Number', 'Address'],
    body: deviceDetails.map(({ deviceId, serialNumber, address }) => ({
      id: deviceId,
      cells: [serialNumber, address],
      status: devicesStatuses[deviceId],
    })),
  };

  return {
    isAllInstalled,
    handleBulkUpdateDevices,
    tableData,
    submitClicked,
    isLoading,
    failedDevices,
    mutate,
  };
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { ApiMethod, Capabilities } from 'types';
import { handleReduxToolkitApiRequest } from 'utils/hooks/APIRequestsHandlers/handleReduxToolkitApiRequest';

export const fetchCapabilities = createAsyncThunk<
  AxiosResponse<Capabilities>,
  void,
  { rejectValue: string }
>('capabilities/fetchCapabilities', async (_, { rejectWithValue, getState, dispatch }) => {
  const state: any = getState();
  const currentTenant = state.auth.user?.selectedTenant ?? '';

  return handleReduxToolkitApiRequest({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_API_URL_V2}internal/tenants/${currentTenant}/capabilities`,
    rejectWithValue,
    dispatch,
    enableErrorHandler: true,
  });
});

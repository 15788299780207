import React, { useRef } from 'react';

import styled from 'styled-components';
import { formatAsDate } from 'utils/helpers';

import ConnectionStatusIndicator from './ConnectionStatusIndicator';
import DeviceStatusIndicator from './DeviceStatusIndicator';

const CellContents = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const TablePill = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.primaryLight};
  padding: 0.5rem;
  margin: 0.25rem;
  border-radius: 5px;
`;

const Popover = styled.span`
  position: absolute;
  display: inline-block;
  padding: 0.5rem 1rem;
  width: max-content;
  max-width: 50rem;
  background: ${({ theme }) => theme.white};
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  text-align: center;
  top: -100%;
  left: 50%;
  z-index: 6;
  transform-origin: 0 50%;
  transform: scale(0.6) translateX(-50%);
  opacity: 0;
  visibility: hidden;

  transition: all 0.4s ease-in-out 0s;

  &.show {
    transition: all 0.4s ease-in-out 1s;
    visibility: visible;
    transform: scale(1) translateX(-50%);
    opacity: 1;
  }
`;

const TableCell = ({ dataType, value, handleOnClick, property }) => {
  const cell = useRef<HTMLDivElement>(null);

  const isOverflow = (ele) => ele.offsetWidth < ele.scrollWidth;

  const onMouseEnter = () => {
    if (cell.current && isOverflow(cell.current)) {
      cell.current?.nextElementSibling!.classList.add('show');
    }
  };

  const onMouseLeave = () => {
    if (cell.current && isOverflow(cell.current)) {
      cell.current.nextElementSibling!.classList.remove('show');
    }
  };

  const formatValue = (value) => {
    switch (property) {
      case 'deviceStatus':
      case 'adeySenseProductStatus':
        return <DeviceStatusIndicator deviceStatus={value} />;
      case 'deviceConnectionState':
        return <ConnectionStatusIndicator connectionStatus={value} />;
      default:
        return value?.toString();
    }
  };

  const displayValue = (value) => {
    switch (dataType) {
      case 'stringarray':
        return value?.map((x) => <TablePill key={x}>{x}</TablePill>);
      case 'date':
        return formatAsDate(value);
      default:
        return formatValue(value);
    }
  };

  return (
    <td
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleOnClick}
      role="presentation"
    >
      <CellContents ref={cell}>{displayValue(value)}</CellContents>
      <Popover>{displayValue(value)}</Popover>
    </td>
  );
};

export default TableCell;

/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

import styled from 'styled-components';
import { buildURI } from 'utils/csvUtils';

const getShortDateStr = () =>
  new Date()
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .replace(/ /g, '-');

export const handleCsvDownload = async (
  e,
  cb,
  headers,
  separator,
  enclosingCharacter,
  uFEFF,
  filename,
  isDisabled
) => {
  if (e) e.preventDefault();
  if (!isDisabled) {
    const data = await cb();
    const href = buildURI(data, headers, separator, enclosingCharacter, uFEFF);
    const a = document.createElement('a');
    a.setAttribute('href', href);
    a.setAttribute('download', `${getShortDateStr()}-${filename}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const CsvLink = ({
  handleClickCallBack,
  children,
  headers = null as any,
  filename = 'report.csv',
  separator = ',',
  enclosingCharacter = '"',
  uFEFF = true,
  className = '',
  isDisabled = false,
}) => {
  const handleClick = (e) =>
    handleCsvDownload(
      e,
      handleClickCallBack,
      headers,
      separator,
      enclosingCharacter,
      uFEFF,
      filename,
      isDisabled
    );

  return (
    <a
      className={className}
      role="button"
      tabIndex={0}
      onKeyDown={handleClick}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

export const CsvButton = styled(CsvLink)`
  ${(props) => props.theme.defaultButton()};
  font-size: ${(props) => props.theme.textSmall};
  line-height: 2rem;
  margin-left: 1rem;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
`;

import { PropertyLocation } from './Property';

export enum VoidStatus {
  NotVoid = 'NotVoid',
  Void = 'Void',
}

export type Property = {
  propertyReference: string;
  postcode: string;
  propertyId: string;
  tenantId: string;
  address1: string;
  address2: string;
  address3: string;
  town: string;
  county: string;
  latitude: number;
  longitude: number;
  epcRating: string | null;
  locations: PropertyLocation[];
  voidStatus: VoidStatus;
};

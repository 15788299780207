import { createThunkEffect } from 'utils/ReduxUtils';

const FETCH_LUMINAIRES = 'FETCH_LUMINAIRES';
const ADD_LUMINAIRES = 'ADD_LUMINAIRES';
const DELETE_LUMINAIRES = 'DELETE_LUMINAIRES';

export const viewDataTypes = {
  FETCH_LUMINAIRES,
  ADD_LUMINAIRES,
  DELETE_LUMINAIRES,
};

export const fetchLuminaires = () => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_LUMINAIRES, 'GetStandardLuminaires', {});

export const addLuminaire = (luminaire) => async (dispatch) =>
  await createThunkEffect(dispatch, ADD_LUMINAIRES, 'AddStandardLuminaire', { ...luminaire });

export const deleteLuminaire = (luminaireId) => async (dispatch) =>
  await createThunkEffect(dispatch, DELETE_LUMINAIRES, 'DeleteStandardLuminaire', { luminaireId });

const initialState = {
  luminaires: [],
};

export const luminaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${viewDataTypes.FETCH_LUMINAIRES}_SUCCESS`:
      return {
        luminaires: action.payload,
      };

    default:
      return state;
  }
};

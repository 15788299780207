import React from 'react';

import { BcmRecord } from 'types/BoilerTypes/BcmRecord';
import { Nullable } from 'types/Nullable';

import AddGasSafetyRecordModal from './AddGasSafteyRecordModal';
import AddWaterTreatmentRecordModal from './AddWaterTreatmentModal';
import DetailsModal from './DetailsModal';
import { RecordModal } from './RecordModal';

interface RecordModalProps {
  type: RecordModal;
  deviceId: string;
  record: Nullable<BcmRecord>;
  handleResetAction(): void;
}

const RecordModalSelector = ({ type, deviceId, record, handleResetAction }: RecordModalProps) => (
  <>
    {(() => {
      switch (type) {
        case 'details':
          return (
            <DetailsModal
              deviceId={deviceId}
              record={record}
              handleResetAction={handleResetAction}
            />
          );
        case 'gasSafety':
          return (
            <AddGasSafetyRecordModal deviceId={deviceId} handleResetAction={handleResetAction} />
          );
        case 'waterTreatment':
          return (
            <AddWaterTreatmentRecordModal
              deviceId={deviceId}
              handleResetAction={handleResetAction}
            />
          );

        case 'none':
        default:
          return null;
      }
    })()}
  </>
);

export default RecordModalSelector;

import React from 'react';

import { DeviceTypes } from 'types/DeviceTypes/Device';
import { deviceToString } from 'utils/deviceUtils';
import { calcPercentageFromTotal } from 'utils';
import { WidgetProgressBar, WidgetProgressBarBlock } from 'styles/commonStyles';
import { DeviceWidgetLink, DeviceWidgetIcon } from '../../atoms';
import { DashboardDeviceInfoProps, WidgetLinks } from '../../constants/dashboard';
import { IconsWrapper } from './DeviceWidget.styles';

type DeviceWidgetProps = DashboardDeviceInfoProps & {
  deviceType: DeviceTypes[];
  links: WidgetLinks;
};

export const DeviceWidget: React.FC<DeviceWidgetProps> = ({
  deviceType,
  deviceSummary = {
    total: 0,
    fail: 0,
    pass: 0,
    warn: 0,
    installed: 0,
    inventory: 0,
  },
  links,
}) => {
  const { total, fail, pass, warn, installed, inventory } = deviceSummary;
  const { toInventory, toInstalled, toFailed, toWarning } = links;

  const invPercentage = calcPercentageFromTotal(inventory, total);
  const failedPercentage = calcPercentageFromTotal(fail, total);
  const warningPercentage = calcPercentageFromTotal(warn, total);
  const okPercentage = calcPercentageFromTotal(pass, total);

  return (
    <>
      <IconsWrapper>
        <div>
          <DeviceWidgetIcon
            deviceType={deviceType}
            count={fail}
            status="FailedEquipment"
            msg="Failed"
            to={toFailed}
          />
        </div>
        <div>
          <DeviceWidgetIcon
            deviceType={deviceType}
            count={warn}
            status="RepairedEquipment"
            msg="Warning"
            to={toWarning}
          />
        </div>
      </IconsWrapper>
      <WidgetProgressBar>
        {!!fail && failedPercentage > 2 && (
          <WidgetProgressBarBlock status="Failed" width={failedPercentage} />
        )}
        {!!warn && warningPercentage > 2 && (
          <WidgetProgressBarBlock status="Warning" width={warningPercentage} />
        )}
        {!!pass && okPercentage > 2 && (
          <WidgetProgressBarBlock status="Passed" width={okPercentage} />
        )}
        {!!inventory && invPercentage > 2 && (
          <WidgetProgressBarBlock status="NotInstalled" width={invPercentage} />
        )}
      </WidgetProgressBar>
      <DeviceWidgetLink
        to={toInstalled}
        count={installed}
        label={`${deviceToString(deviceType[0])} installed`}
      />
      <DeviceWidgetLink
        to={toInventory}
        count={inventory}
        label={`${deviceToString(deviceType[0])} in inventory`}
      />
    </>
  );
};

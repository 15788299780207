import React, { useEffect } from 'react';

import ListingView from 'components/ListingView';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectThirdPartyViewFields } from 'reducers/fields';
import {
  ViewDefinition,
  selectSelectedView,
  selectThirdPartyViewDefinitions,
  setStoredView,
  ViewContext,
  selectCurrentViewContext,
} from 'reducers/viewDefinitions';
import { Field } from 'types/Listing';
import useListingViewHelpers from 'utils/hooks/useListingViewHelpers';
import useTenantInfo from 'utils/hooks/useTenantInfo';

const ThirdParty = () => {
  const dispatch = useDispatch();
  const viewDefinitions: ViewDefinition[] = useSelector((state: any) =>
    selectThirdPartyViewDefinitions(state)
  );
  const fields: Field[] = useSelector((state: any) => selectThirdPartyViewFields(state));
  const selectedView: ViewDefinition = useSelector((state: any) => selectSelectedView(state));
  const currentViewContext: ViewContext = useSelector((state: any) =>
    selectCurrentViewContext(state)
  );

  const { viewId } = useParams<any>();
  const {
    setDefaultContext,
    setListingViewContext,
    refreshViewData,
    updateSelectedView,
    updateFilters,
    updateViewColumns,
  } = useListingViewHelpers();

  const tenantInfo = useTenantInfo();

  useEffect(() => {
    //This is messy because of the way the routing has been setup, there are two cases to handle.
    const defaultRenderConditionA =
      currentViewContext?.page !== 'ThirdParty' && viewDefinitions && viewDefinitions.length > 0;
    const defaultRenderConditionB =
      !viewId &&
      currentViewContext?.page === 'ThirdParty' &&
      viewDefinitions &&
      viewDefinitions.length > 0;

    if (defaultRenderConditionA || defaultRenderConditionB) {
      const defaultView = viewDefinitions.find(
        (viewDef) => viewDef.root === 'thirdParty' // && viewDef.builtIn
      );
      const defaultViewId = viewId ? viewId : defaultView?.viewId;
      if (defaultViewId && tenantInfo && tenantInfo.id !== '') {
        setDefaultContext({
          tenantId: tenantInfo.id,
          page: 'ThirdParty',
          viewId: defaultViewId,
        });
      }
    }

    return () => {
      dispatch(setStoredView());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedView && viewId && selectedView.viewId !== viewId) {
      setListingViewContext({
        tenantId: tenantInfo.id,
        page: 'ThirdParty',
        viewId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId]);

  return (
    <>
      {viewDefinitions.length && selectedView && (
        <ListingView
          availableViews={viewDefinitions}
          availableFields={fields}
          rowAction={() => {}}
          route="ThirdParty"
          showActions={false}
          refreshViewData={refreshViewData}
          updateSelectedView={updateSelectedView}
          updateFilters={updateFilters}
          updateViewColumns={updateViewColumns}
        />
      )}
    </>
  );
};

export default ThirdParty;

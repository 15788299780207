import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { BulkDeviceDetails } from 'components/DeviceActions/DeviceActionProps';
import { ActionDialog, DeviceAccessRightsTagSelect, BulkActionTable } from 'components/molecules';
import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { useBulkUpdateDeviceAccessTagState } from './hooks/useBulkUpdateDeviceAccessTagState';
import { BulkUpdateAccessRightsTagsContent } from './BulkUpdateAccessRightsTags.styles';

type BulkAddAccessRightsTagsProps = {
  deviceDetails: BulkDeviceDetails;
  handleResetAction(): void;
  remove?: boolean;
};

export const BulkUpdateAccessRightsTags: React.FC<BulkAddAccessRightsTagsProps> = ({
  deviceDetails,
  handleResetAction,
  remove,
}) => {
  const accessRightsTags: AccessRightsTag[] = useSelector(
    (state: any) => state.accessRightsTags.accessRightsTags
  );
  const [selectedAccessRightsTag, setSelectedAccessRightsTag] = useState<
    AccessRightsTag | undefined
  >(undefined);
  const accessRightsTagId = remove ? null : selectedAccessRightsTag?.id || '';

  const {
    isAllInstalled,
    handleBulkUpdateDevices,
    tableData,
    mutate,
    submitClicked,
    isLoading,
    failedDevices,
  } = useBulkUpdateDeviceAccessTagState({
    deviceDetails,
    accessRightsTagId,
  });

  const handleSetAccessRightsTag = ({ target }) =>
    setSelectedAccessRightsTag(accessRightsTags.find((tag) => tag.id === target.value));

  const handleUpdateAccessRightsTag = (deviceId: string) =>
    mutate({
      body: { accessRightsTagId },
      deviceId: deviceId,
    });

  const getSubmitButtonName = () => {
    if (failedDevices.length && !isLoading) {
      return 'Retry all failed';
    }

    const mainButtonText = remove ? 'Remove' : 'Set';

    return submitClicked ? '' : mainButtonText;
  };

  const submitButtonName = getSubmitButtonName();
  const title = remove
    ? 'Bulk Remove Access Rights Tags from Devices'
    : 'Bulk Add Access Rights Tags to Devices';

  return (
    <ActionDialog
      open
      title={title}
      submitButtonName={submitButtonName}
      cancelButtonText={submitClicked ? 'Done' : 'Cancel'}
      isDisabled={!selectedAccessRightsTag && !remove}
      handleSubmit={handleBulkUpdateDevices}
      sendingRequest={isLoading}
      onClose={handleResetAction}
    >
      <BulkUpdateAccessRightsTagsContent>
        {!remove && (
          <DeviceAccessRightsTagSelect
            handleAssignAccessRightsTag={handleSetAccessRightsTag}
            accessRightsTags={accessRightsTags}
            accessRightsTag={selectedAccessRightsTag}
            disabled={submitClicked}
          />
        )}
        <BulkActionTable
          failedInstallsLength={failedDevices.length}
          allInstallsLength={deviceDetails.length}
          isAllInstalled={isAllInstalled}
          allInstallMessage={remove ? 'All Removed' : 'All assigned'}
          handleReset={handleUpdateAccessRightsTag}
          data={tableData}
        />
      </BulkUpdateAccessRightsTagsContent>
    </ActionDialog>
  );
};

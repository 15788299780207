import styled, { css } from 'styled-components';

export const Tabs = styled.ul`
  ${(props) => props.theme.tabs};
`;

export const VR = styled.span`
  display: inline-block;
  margin: 0 2rem;
  height: 3.5rem;
  width: 0.5px;
  background-color: #ccc;
  @media ${(props) => props.theme.mobileM} {
    margin: 0 1rem;
  }
`;

export const BackButton = styled.span`
  position: relative;
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.primary};
  &:hover {
    cursor: pointer;
  }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    height: 1rem;
    width: 1rem;
    border-left: 2px white solid;
    border-bottom: 2px white solid;
    transform-origin: 0 0;
    transform: rotate(45deg) translate(-35%, -65%);
  }
`;

export const IconWrapper = styled.span<{ status: string; size: number }>`
  display: inline-block;
  height: ${(props) => props.size}rem;
  width: ${(props) => props.size}rem;

  & svg {
    fill: ${(props) => props.theme.statusColor(props.status)};
    width: 100%;
    height: 100%;
  }
`;

const editStylesFontSize = css`
  font-weight: 600;
  font-size: 1.4rem;
`;

export const editFieldsStyles = {
  title: css`
    color: ${(props) => props.theme.blue};
    ${editStylesFontSize}
  `,
  label: css`
    display: flex;
    align-items: center;
    gap: 5px;

    &-text {
      ${editStylesFontSize}
      color: ${(props) => props.theme.notDarkGrey};
    }

    &-edit {
      position: relative;
      width: 34px;

      &-button {
        position: absolute;
        padding: 4px;
        transform: translateY(-50%);
      }

      &-icon {
        color: ${(props) => props.theme.darkBlue};
        font-size: 2rem;
      }
    }
  `,
};

export const TabTitle = styled.h5`
  color: ${(props) => props.theme.notDarkGrey};
  font-weight: 600;
  font-size: 2rem;
`;

export const TabContent = styled.div`
  padding-top: 24px;
  width: 100%;
`;

export const TabContentTitle = styled.h6`
  font-weight: 600;
  font-size: 1.6rem;
  padding-bottom: 8px;
`;

export const disableMuiXTableHeaderMixin = css`
  .MuiDataGrid-columnHeader {
    pointer-events: none !important;

    .MuiDataGrid-iconButtonContainer {
      display: none;
    }

    .MuiDataGrid-columnSeparator {
      display: none;
    }
  }
`;

export const WidgetProgressBar = styled.div`
  background-color: ${(props) => props.theme.secondary};
  border-radius: 20px;
  height: 1rem;
  margin-bottom: 2rem;
  display: flex;
  position: relative;

  & div:first-child {
    border-radius: 20px 0 0 20px;
  }

  & div:last-child {
    border-radius: 0 20px 20px 0;
  }

  & div:only-child {
    border-radius: 20px 20px 20px 20px;
  }
`;

export const WidgetProgressBarBlock = styled.div<{ width: number; status: string }>`
  background-color: ${(props) => props.theme.statusColor(props.status)};
  width: ${(props) => props.width}%;
  height: 100%;
`;

export const DateRangePickerWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;

  & .react-daterange-picker {
    margin: 10px 10px 0 10px;

    &__wrapper {
      background-color: #fff;
      padding: 3px 9px;
      border-radius: 20px;
      border: 1px solid rgb(216, 216, 216);
    }

    &__range-divider {
      font-size: 1.6rem;
    }

    &__inputGroup {
      padding: 0 9px;

      &__input:focus-within {
        outline: 0;
        outline: none;
        border-bottom: 2px solid #28bee6;
      }
    }

    .react-calendar {
      &__month-view__weekdays__weekday {
        abbr {
          text-decoration: none;
        }
      }
      &__tile {
        transition: all 0.1s ease-in-out;

        &--now {
          background: #fff;

          &:hover,
          &:focus {
            background-color: #e6e6e6;
          }
        }

        &--hover,
        &--hoverEnd {
          &:not([disabled]) {
            background: #b2f1ec;
            color: #007a87;

            &:hover,
            &:focus {
              background: #b2f1ec;
              color: #007a87;
            }
          }

          &:disabled {
            background: #f0f0f0;
          }
        }

        &--active {
          background: #28bee6;
          border: 1px double #28bee6;

          &:hover,
          &:focus {
            background: #86caf9;
            border: 1px double #86caf9;
          }
        }

        &--rangeStart,
        &--rangeEnd,
        &--hoverBothEnds {
          background: #0187b5 !important;
          border: 1px double #0187b5 !important;
          color: #fff !important;

          &:hover,
          &:focus {
            background: #0187b5;
            border: 1px double #0187b5;
            color: #fff;
          }
        }
      }
    }

    &__calendar--open,
    &__calendar--closed,
    &__clock--open,
    &__clock--closed {
      position: absolute;
    }
  }
`;

import styled from 'styled-components';

export const BulkActionTableWrapper = styled.div`
  .bulk-action-table {
    &__cell {
      font-size: 10px;
      border: 0;
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      padding-top: 8px;
      padding-bottom: 8px;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .bulk-action-table-message {
    padding-top: 12px;
    min-height: 33px;

    &__failed {
      font-size: 14px;
      color: ${(props) => props.theme.error};
    }

    &__all-installed {
      font-size: 14px;
      color: ${(props) => props.theme.darkBlue};
    }
  }
`;

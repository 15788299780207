import React from 'react';

import styled from 'styled-components';

import DatePicker from './DatePicker';

const TextBoxWrapper = styled.div<{ width: string }>`
  position: relative;
  display: inline-block;
  width: ${(props) => props.width}; ;
`;

const DateField = ({ value, onChange, name, width }) => (
  <TextBoxWrapper width={width}>
    <DatePicker name={name} onChange={onChange} date={value} />
  </TextBoxWrapper>
);
export default DateField;

import { DeviceTypes } from 'types/DeviceTypes/Device';

import { Report } from '../types';

export const reports: Report[] = [
  {
    deviceType: [DeviceTypes.EmergencyLightingLegacy],
    title: 'Emered Failure Report',
    description: 'Current emergency light failure report',
    api: {
      reportType: 'FailedEmeredReport',
    },
  },
  {
    deviceType: [DeviceTypes.EmergencyLightingLegacy],
    title: 'Emered Compliance Report',
    description: 'Monthly emergency light testing compliance report',
    // remove this when you want to show report
    hidden: true,
    api: {
      reportType: 'EmeredComplianceReport',
    },
  },
  {
    deviceType: [DeviceTypes.BcmConnect, DeviceTypes.EmergencyLighting],
    title: 'Monthly Overview Report',
    description: 'Portfolio analysis',
    api: {
      reportType: 'Overview',
      endpoint: 'MonthlyReport',
    },
    customizable: true,
  },
  {
    deviceType: [DeviceTypes.BcmConnect],
    title: 'Damp and Mould Report',
    description: 'Damp and mould risk report',
    api: {
      reportType: 'DampAndMould',
      endpoint: 'MonthlyReport',
    },
    customizable: true,
  },
  {
    deviceType: [DeviceTypes.BcmConnect],
    title: 'Fuel Poverty Report',
    description: 'Fuel poverty and extreme fuel poverty risk report',
    api: {
      reportType: 'FuelPoverty',
      endpoint: 'MonthlyReport',
    },
    customizable: true,
  },
];

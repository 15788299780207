import React from 'react';

import Input from 'components/elements/Input';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  color: inherit;
  font-size: inherit;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const LabelWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  min-width: 100%;
  width: 60rem;

  && > label {
    margin-bottom: 0;
  }
`;

const Error = styled.span`
  color: ${(props) => props.theme.failed};
  font-size: ${(props) => props.theme.textSmaller};
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: 500;
`;

const ErrorLabel = ({ name, label, errorMsg }) => (
  <LabelWrapper>
    <Label htmlFor={name}> {label} </Label>
    {errorMsg && <Error>{errorMsg}</Error>}
  </LabelWrapper>
);

const LabelledInput = ({ type, inputData, label, errorLabel = false, errorMsg = '' }) => (
  <Wrapper>
    {errorLabel ? (
      <ErrorLabel label={label} name={inputData?.name} errorMsg={errorMsg} />
    ) : (
      <Label htmlFor={inputData?.name}> {label} </Label>
    )}
    <Input type={type} inputData={inputData} />
  </Wrapper>
);

export default LabelledInput;

import { useState, useEffect } from 'react';

import { PREVIOUS_MONTH, MONTHS_LIST, YEARS_LIST, CURRENT_MONTH } from '../constants/reportCard';

type DateType = {
  year: number;
  month: number;
};

export const useReportDates = () => {
  const isFirstMonth = CURRENT_MONTH === 0;
  const years = isFirstMonth ? YEARS_LIST.slice(1) : YEARS_LIST;

  const [months, setMonths] = useState<Array<string>>(MONTHS_LIST);
  const [reportDates, setReportDates] = useState<DateType>({
    year: years[0],
    month: PREVIOUS_MONTH,
  });

  const isLatestYearInList = reportDates.year === years[0];
  const isCurrentAndAboveMonth = isLatestYearInList && reportDates.month >= CURRENT_MONTH;

  const handleYearChange = ({ target }): void =>
    setReportDates((prev) => ({ ...prev, year: target.value }));

  const handleMonthChange = ({ target }): void =>
    setReportDates((prev) => ({
      ...prev,
      month: months.indexOf(target.value),
    }));

  useEffect(() => {
    const dynamicMonthsList: string[] =
      isLatestYearInList && !isFirstMonth ? MONTHS_LIST.slice(0, CURRENT_MONTH) : MONTHS_LIST;

    setReportDates((prev) => ({
      ...prev,
      month: isCurrentAndAboveMonth && !isFirstMonth ? PREVIOUS_MONTH : prev.month,
    }));
    setMonths(dynamicMonthsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDates.year]);

  return {
    reportDates,
    months,
    years,
    handleYearChange,
    handleMonthChange,
  };
};

import { createTheme } from '@mui/material/styles';
import { colors, styleVars } from './variables';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    container: true;
  }
}

export const MuiDialogMargin = {
  S: 12,
  M: 24,
  L: 32,
};

export default createTheme({
  typography: {
    fontFamily: 'inherit',
  },
  palette: {
    primary: {
      main: colors.darkBlue,
    },
    warning: {
      main: colors.orange,
    },
    success: {
      main: colors.submitHover,
    },
    error: {
      main: colors.error,
    },
    info: {
      main: colors.editHover,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontFamily: 'inherit',
        },
        contained: {
          borderRadius: 25,
          padding: '0.35rem 2.8rem',
          fontSize: '14px',

          '& .Mui-rotation': {
            animation: 'buttonIconSpin 0.5s linear infinite reverse',
          },
          '@keyframes buttonIconSpin': {
            '0%': {
              transform: 'rotate(360deg)',
            },
            '100%': {
              transform: 'rotate(0deg)',
            },
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: 'rgb(221, 221, 221)',
          },
          '&.MuiButton-containedSuccess': {
            backgroundColor: colors.submit,
            color: '#fff',

            '&:hover': {
              backgroundColor: colors.submitHover,
            },
          },
          '&.MuiButton-containedWarning': {
            color: 'white',

            '&:hover': {
              backgroundColor: colors.orangeSaturated,
            },
          },
          '&.Mui-disabled': {
            '&:hover': {
              backgroundColor: colors.disabledButton,
              color: colors.disabledButtonText,
            },
          },
        },
        outlined: {
          borderRadius: 25,
          padding: '0.35rem 2.8rem',
          color: colors.defaultHover,
          borderColor: colors.defaultHover,
        },
        containedPrimary: {
          backgroundColor: colors.default,

          '&:hover': {
            backgroundColor: colors.defaultHover,
          },
        },
      },
      variants: [
        {
          props: { variant: 'container' },
          style: {
            textTransform: 'none',
            padding: 0,
            display: 'inline-block',
            fontSize: 'unset',
            minWidth: 'unset',
            lineHeight: 'unset',
            letterSpacing: 'unset',
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& .MuiInputAdornment-root': {
              '.MuiSvgIcon-colorTransparent': {
                color: 'transparent',
              },
            },
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
              borderColor: 'rgb(85, 179, 247)',
            },
          },
          '& .MuiInputLabel-outlined.Mui-focused': {
            color: 'inherit',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#f7f7f7',
          borderRadius: '20px !important',
          color: 'inherit',
          fontFamily: 'inherit',
          fontSize: '14px',
          lineHeight: '1.5',

          '@media (min-width: 768px)': { lineHeight: '1.5', fontSize: '16px' },

          '&.Mui-focused fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23) !important',
            borderWidth: '1px !important',
          },
          '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23) !important',
            borderWidth: '1px !important',
          },
          '& .MuiSelect-select, & .MuiOutlinedInput-input': {
            padding: '6px 12px',
            minHeight: 'unset !important',
            height: 'unset !important',
          },
          '& .MuiSelect-nativeInput': {
            height: '100%',
          },
          '& .MuiSvgIcon-root.MuiSelect-icon': {
            color: '#28bee6',
          },
          '& .MuiSvgIcon-root.MuiSelect-icon.Mui-disabled': {
            color: '#aeaeae',
          },
          '& .MuiChip-root': {
            height: 'unset',
            fontSize: '12px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0 !important',

          '& .MuiFormLabel-root': {
            fontSize: '14px',
            fontFamily: 'inherit',
            transform: 'translate(14px, 4px) scale(1)',

            '@media (min-width: 768px)': {
              transform: 'translate(14px, 5px) scale(1)',
              fontSize: '16px',
            },

            '@media (min-width: 1800px)': {
              transform: 'translate(14px, 7px) scale(1)',
            },

            '@media (min-width: 2560px)': {
              transform: 'translate(14px, 8px) scale(1)',
            },

            '&.Mui-focused, &.MuiFormLabel-filled': {
              color: 'inherit',
              transform: 'translate(14px, -11px) scale(0.75)',
            },
          },

          '& fieldset': {
            fontSize: '14px',

            '@media (min-width: 768px)': {
              fontSize: '16px',
            },
          },

          '& .MuiFormHelperText-root': {
            fontSize: '0.9rem',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root': {
            fontSize: '13px',

            '&:hover': {
              backgroundColor: '#86caf9',
            },
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '& .MuiFormControlLabel-root': {
            marginRight: '20px',

            '& .MuiTypography-root': {
              fontSize: '13px',
            },

            '& .Mui-checked': {
              color: styleVars.blue,
            },
            '& .MuiButtonBase-root': {
              '& .MuiSvgIcon-root': {
                fontSize: '28px',
              },
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: `rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
            rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;`,
          fontSize: 12,
          '& .MuiTooltip-arrow': {
            color: '#c1c1c1',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            margin: MuiDialogMargin.S,
            maxHeight: 'calc(100% - 24px)',

            '@media (min-width: 768px)': {
              margin: MuiDialogMargin.M,
              maxHeight: 'calc(100% - 48px)',
            },

            '@media (min-width: 1024px)': {
              margin: MuiDialogMargin.L,
              maxHeight: 'calc(100% - 64px)',
            },

            '&.MuiDialog-paperFullScreen': {
              margin: 0,
              maxHeight: '100vh',
            },
          },
        },
      },
    },
  },
});

import React from 'react';

import styled from 'styled-components';
import { getToken } from 'utils/contexts/AuthProvider';

const Button = styled.button`
  color: ${({ theme }) => theme.blue};
  transition: all 0.3s ease-in-out;
  background: transparent;
  font-size: inherit;
  font-family: inherit;

  &:hover {
    color: ${({ theme }) => theme.darkBlue};
  }
`;

const AttachmentDownloadLink = ({ deviceId, record }) => {
  const url = `${
    process.env.REACT_APP_APIURL
  }GetBoilerRecordAttachment?deviceId=${
    deviceId
  }&recordId=${
    record.recordId
  }&attachmentId=${
    record.attachmentIds[0]
  }`;

  const handleOnClick = async () => {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    const blob = await res.blob();
    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobURL;
    a.style.display = 'none';
    a.download = record.serialNumber;
    document.body.appendChild(a);
    a.click();
  };

  return <Button onClick={handleOnClick}>download</Button>;
};

export default AttachmentDownloadLink;

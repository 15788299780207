import React, { useMemo, useCallback, useEffect } from 'react';

import { Axis } from '@visx/axis';
import * as allCurves from '@visx/curve';
import { localPoint } from '@visx/event';
import { scaleTime, scaleBand } from '@visx/scale';
import { LinePath, Line, Bar } from '@visx/shape';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { timeFormat } from 'd3-time-format';
import moment from 'moment';

import { useChartContext, useChartUpdateContext } from '../../Chart/ChartContext';
import { Card, DataSeries, EventDataProps } from '../../Configs/TelemetryConfigs';

type CurveType = keyof typeof allCurves;

interface DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export type EventProps = {
  graphWidth: number;
  graphHeight: number;
  cardConfig: Card;
  dateRange: DateRange;
  selectedEventList: Array<EventDataProps>;
  updateSelectedEventList: (eventList: Array<EventDataProps>) => void;
};

const formatDateTime = timeFormat('%d %b %H:%M');

const EventTimeline = ({
  cardConfig,
  graphHeight,
  graphWidth,
  dateRange,
  selectedEventList,
  updateSelectedEventList,
}: EventProps) => {
  const chartContext = useChartContext();
  const chartUpdateContext = useChartUpdateContext();

  const { tooltipData, tooltipLeft, showTooltip } = useTooltip();

  const { TooltipInPortal, containerRef } = useTooltipInPortal({
    detectBounds: true,
    debounce: 6,
    scroll: true,
  });

  const paddingY = 30;
  const paddingX = 5;

  const getX = (data: any) => data.startDate;
  const getPointX = (data: any) => data.date;
  const getPointY = (data: any) => data.value;
  const lineThickness = 15;

  // Scaling X Axis
  const dateScale = useMemo(
    () =>
      scaleTime({
        domain: [
          moment(dateRange.startDate).startOf('day').toDate(),
          moment(dateRange.endDate).endOf('day').toDate(),
        ],
        range: [0 + paddingX, graphWidth - paddingX],
        clamp: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRange.endDate, dateRange.startDate, graphWidth]
  );

  // Scaling Y Axis
  const metricScale = useMemo(
    () =>
      scaleBand({
        domain: ['Low', 'Medium', 'High'],
        range: [graphHeight - paddingY, paddingY],
        padding: 0.1,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphHeight]
  );

  const normalizeTheData = (dataSeries: DataSeries): DataSeries => {
    if (dataSeries.eventSeries) {
      dataSeries.eventSeries = dataSeries.eventSeries.map((series) => {
        //Round x position to the nearest line thickness (width of the data point on the graph)
        const roundedStartPosition =
          Math.round(dateScale(series.start) / lineThickness) * lineThickness;
        const roundedEndPosition =
          Math.round(dateScale(series.end) / lineThickness) * lineThickness;

        series.startNormalized = dateScale.invert(roundedStartPosition);
        series.endNormalized = dateScale.invert(roundedEndPosition);

        return series;
      });
    }

    return dataSeries;
  };

  const curveType: CurveType = 'curveLinear';
  const dataSeries = normalizeTheData(cardConfig.dataSeries[0].data);

  useEffect(() => {
    if (dataSeries.eventSeries) {
      if (chartContext.selectedDateTime) {
        //Get the index from the synced date and then the x and y position for the tooltip
        const { xPosition } = { xPosition: dateScale(chartContext.selectedDateTime) } || {
          xPosition: 0,
        };
        const cursorStartDate = dateScale.invert(xPosition - lineThickness / 2);
        const cursorEndDate = dateScale.invert(xPosition + lineThickness / 2);
        const dateFromXAxis = dateScale.invert(xPosition);

        const data = dataSeries.eventSeries.filter((event) => {
          const start = moment(event.startNormalized);
          const end = moment(event.endNormalized);

          if (start.isSame(end)) {
            if (start.isBetween(cursorStartDate, cursorEndDate)) {
              return event;
            }
          } else {
            if (moment(dateFromXAxis).isBetween(moment(event.start), moment(event.end))) {
              return event;
            }
          }
        });

        if (data.length > 0) {
          data.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());
        }

        if (selectedEventList.length !== data.length) {
          updateSelectedEventList(data);
        } else {
          //If Same length check each item
          const isDifferent = selectedEventList.some((event, i) => event !== data[i]);

          if (isDifferent) {
            updateSelectedEventList(data);
          }
        }

        showTooltip({
          tooltipData: {
            startDate: chartContext.selectedDateTime,
            value: [],
          },
          tooltipLeft: xPosition,
          tooltipTop: 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContext]);

  // Tooltip handler
  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const { x } = localPoint(event) || { x: 0 };
      const dateFromXAxis = dateScale.invert(x);

      chartUpdateContext({
        ...chartContext,
        selectedDateTime: dateFromXAxis,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartUpdateContext, dateScale]
  );

  const handleTooltipPause = () => {
    chartUpdateContext({
      ...chartContext,
      pauseSelection: !chartContext.pauseSelection,
    });
  };

  const getNumTicks = () => {
    if (graphWidth > 1000) {
      return 9;
    }

    return graphWidth > 600 ? 6 : 4;
  };

  return (
    <div>
      <svg width={graphWidth} height={graphHeight}>
        <rect
          x={0}
          y={0}
          width={graphWidth > 0 ? graphWidth : 0}
          height={graphHeight > 0 ? graphHeight : 0}
          fill="transparent"
          ref={containerRef}
        />

        <Axis
          scale={dateScale}
          top={graphHeight - paddingY}
          orientation="bottom"
          stroke="darkgrey"
          strokeWidth={1}
          tickStroke="darkgrey"
          numTicks={getNumTicks()}
          tickLabelProps={() => ({
            fill: 'grey',
            textAnchor: 'start',
            verticalAnchor: 'middle',
            fontSize: '0.9em',
            transform: `translate(${-paddingX})`,
          })}
        />

        <Axis
          hideZero
          scale={metricScale}
          left={paddingX}
          orientation="right"
          hideTicks
          stroke={'darkGrey'}
          strokeWidth={1}
          tickLabelProps={() => ({
            display: 'none',
          })}
        />

        {dataSeries.eventSeries && dataSeries.eventSeries.length > 0 ? (
          dataSeries.eventSeries.map(
            ({ category, startNormalized, endNormalized, severity }, i) => (
              <LinePath
                key={`${category}-${i}`}
                curve={allCurves[curveType]}
                data={[
                  {
                    value: severity,
                    date: startNormalized,
                  },
                  {
                    value: severity,
                    date: endNormalized,
                  },
                ]}
                x={(data) => dateScale(getPointX(data)) ?? 0}
                y={(data) => metricScale(getPointY(data)) ?? 0}
                stroke={
                  (severity === 'High' && '#E65D6D') ||
                  (severity === 'Medium' && '#EDB760') ||
                  '#0187B5'
                }
                fill="transparent"
                strokeWidth={lineThickness}
                strokeOpacity={1}
                style={{ border: '2px solid black' }}
                shapeRendering="geometricPrecision"
              />
            )
          )
        ) : (
          <text x={'47%'} y={'45%'} fill={'#D0D4DD'}>
            No events
          </text>
        )}

        <Bar
          x={paddingX}
          y={paddingY}
          width={graphWidth - paddingX * 2}
          height={graphHeight - paddingY * 2}
          fill="transparent"
          rx={14}
          onTouchStart={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onTouchMove={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onMouseMove={(e) => !chartContext.pauseSelection && handleTooltip(e)}
          onClick={() => handleTooltipPause()}
        />

        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: paddingY }}
              to={{ x: tooltipLeft, y: graphHeight - paddingY }}
              stroke={'#666'}
              strokeWidth={2}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
          </g>
        )}
      </svg>
      {tooltipData && (
        <div>
          <TooltipInPortal
            key={Math.random()}
            top={graphHeight - paddingY - 10}
            left={tooltipLeft && tooltipLeft - 60}
            style={{
              ...defaultStyles,
              textAlign: 'center',
              minWidth: '72px',
            }}
          >
            {`${formatDateTime(getX(tooltipData))}`}
          </TooltipInPortal>
        </div>
      )}
    </div>
  );
};

export default EventTimeline;

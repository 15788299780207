import { ApiStatuses } from 'types';
import { ProductNamesState } from '../types';

export const initialState: ProductNamesState = {
  data: [],
  statuses: {
    fetchProductNames: { status: ApiStatuses.Init, error: null },
  },
};

export const ERROR_MESSAGES = {
  fetchProductNames: 'An error occurred while fetching productNames.',
};

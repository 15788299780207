import styled, { css } from 'styled-components';

const fontMixin = css`
  font-weight: 600;
  font-size: 1.4rem;
`;

export const PropertyInfoWrapper = styled.div`
  .property-info {
    &__title {
      ${fontMixin}
      font-size: 1.6rem;
      padding: 1rem 0;
    }

    &__item {
      display: flex;
      gap: 1rem;

      &-label {
        color: ${(props) => props.theme.blue};
        ${fontMixin}
      }

      &-content {
        &-text {
          ${fontMixin}
          color: ${(props) => props.theme.notDarkGrey};
        }
      }
    }

    &__block {
      padding-bottom: 10px;
    }

    &--property-details {
      .property-info__title {
        padding-top: 0;
      }
    }

    &__epc-rating {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &-dropdown {
        padding-right: 16px;
      }

      &-link {
        font-size: 1.44rem;
        font-weight: 500;
        color: ${(props) => props.theme.darkBlue};

        &:hover {
          color: ${(props) => props.theme.success};
        }
      }

      .edit-field-dropdown {
        &__select {
          min-width: 190px;
        }
      }
    }
  }
`;

import React from 'react';

import Modal from 'components/elements/Modal';
import styled from 'styled-components';
import { camelCaseToTitleCase } from 'utils/helpers';

import AttachmentDownloadLink from './AttachmentDownloadLink';

const Wrapper = styled.div`
  width: 60%;
  max-width: 40rem;
  min-width: 22rem;
  margin-bottom: 1rem;

  & > span {
    font-weight: 500;
    display: inline-block;
    margin-bottom: 0.25rem;
  }
`;

const StatusSpan = styled.span`
  display: block;
  padding: 0.5rem 1rem;
  text-align: center;
  margin: 0.5rem 0.75rem;
  color: ${(props) => props.theme.white};
  width: 10rem;
  border-radius: 5px;

  &.fail {
    border: 2px solid ${({ theme }) => theme.error};
    background: ${({ theme }) => theme.error};
  }

  &.pass {
    border: 2px solid ${({ theme }) => theme.success};
    background: ${({ theme }) => theme.success};
  }
`;

const RecordStatus = ({ status }) => (
  <StatusSpan className={status.toLowerCase()}>{status}</StatusSpan>
);

const DetailModal = ({ deviceId, record, handleResetAction }) => (
  <Modal showModal handleClose={handleResetAction} title="Compliance Record">
    <div>
      <Wrapper>
        <span>Certificate Number</span>
        <p>{record.serialNumber}</p>
      </Wrapper>
      <Wrapper>
        <span>Record Type</span>
        <p>{record.recordType}</p>
      </Wrapper>

      {!!record.properties &&
        Object.keys(record.properties).map((k) => (
          <Wrapper key={k}>
            <span>{camelCaseToTitleCase(k)}</span>
            <p>{record.properties[k]}</p>
          </Wrapper>
        ))}
      <Wrapper>
        <span>Result</span>
        <p>
          <RecordStatus status={record.status} />
        </p>
      </Wrapper>
      {!!record.attachmentIds.length && (
        <Wrapper>
          <span>Certificate</span>
          <p>
            <AttachmentDownloadLink deviceId={deviceId} record={record} />
          </p>
        </Wrapper>
      )}
    </div>
  </Modal>
);

export default DetailModal;

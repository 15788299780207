import React from 'react';

import styled from 'styled-components';
import { mapDeviceTypes } from 'utils/deviceUtils';

const DeviceMarkerWrapper = styled.div<{ status: string }>`
  width: 3rem;
  height: 3rem;
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  border-radius: 50%;
  z-index: 8;
  padding: 0.5rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.groupStatusBackgroundColor(props.status)};
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0.8;

  &.active,
  &.hover {
   z-index: 11;
    opacity: 1;
  }

  &.active {
    background-color: ${(props) => props.theme.primary};
  }

  &.conflict {
    background-color: ${(props) => props.theme.primaryLight};
  }

  & svg {
    fill: ${(props) => props.theme.groupStatusColor(props.status)};
    transition: all 0.3s ease-in-out;

    width: 100%;
    height: 100%;
  }

  & div {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    position: absolute;
    background-color: ${(props) => props.theme.primary};
    font-size: ${(props) => props.theme.textSmaller};
    color: ${(props) => props.theme.white};
    text-align: center;
    width: 20rem;
    padding: 0.5rem 0.25rem;
    margin-top: 0.5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 14;
    visibility: visible;
  }

  & div.hidden {
    opacity: 0;
    visibility: hidden;
  }
`;

const DeviceMarker = ({ device, $hover, groupStatus, handleDeviceClick, selected, conflict }) => {
  const icon = mapDeviceTypes(device.deviceType);
  const className =
    (selected ? 'active ' : '') + (conflict ? 'conflict ' : '') + ($hover ? 'hover ' : '');

  return (
    <DeviceMarkerWrapper
      onClick={() => handleDeviceClick(device, groupStatus)}
      status={groupStatus}
      className={className}
    >
      <svg>
        <use xlinkHref={`images/icon-${icon}.svg#icon-${icon}`} />
      </svg>
    </DeviceMarkerWrapper>
  );
};

export default DeviceMarker;

import styled from 'styled-components';

export const PropertyTelemetryWrapper = styled.div`
  min-height: 500px; // temp
  padding-bottom: 30px; // temp
  font-size: 1.44rem;

  .property-telemetry {
    &__date-picker {
      display: flex;
      justify-content: flex-end;
      padding: 20px;

      .MuiInputBase-root {
        background-color: ${(props) => props.theme.white};
      }

      &-icon {
        cursor: pointer;
      }
    }

    &__view-select {
      padding-bottom: 12px;
      max-width: 25rem;
    }

    &__board {
      background-color: ${(props) => props.theme.offWhite};
      padding-bottom: 12px;

      &-charts {
      }

      .react-daterange-picker__inputGroup {
        min-width: 100px;

        &__input,
        &__leadingZero,
        .react-date-picker__inputGroup__divider {
          font-size: 1.44rem;
        }
      }
    }
  }
`;

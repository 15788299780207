export enum ThermostatConnection {
  None = 'None',
  BusThermostatConnectedToBoiler = 'BusThermostatConnectedToBoiler',
  ThirdPartyOpenThermConnectedToBcm = 'ThirdPartyOpenThermConnectedToBcm',
  VericonOpenThermConnectedToBcm = 'VericonOpenThermConnectedToBcm',
  CallForHeatThermostatConnectedToBoiler = 'CallForHeatThermostatConnectedToBoiler',
  CallForHeatThermostatConnectedToBcm = 'CallForHeatThermostatConnectedToBcm',
}

export enum HeatingUnit {
  AirSourceHeatPump = 'AirSourceHeatPump',
  Boiler = 'Boiler',
}

export interface BcmConnectDetails {
  occupancy: OccupiedStatus;
  gcNumber: string;
  manufacturer: string;
  model: string;
  heatingMax: number;
  occupierId: string;
  connectedDevices: ConnectedDevice[];
  address: string;
  installNote: string;
  buzzerPropertyAccess: boolean;
  chPropertyAccess: boolean;
  chMaxSupported: boolean;
  remoteResetSupported: boolean;
  hotWaterTemp: number | null;
  flowTemp: number | null;
  heatingUnit: HeatingUnit;
  thermostatKind: ThermostatConnection;
}

export enum ConnectedDeviceType {
  MultiDot = 'MultiDot',
  EnviroDot = 'EnviroDot',
  PowerMeter = 'PowerMeter',
  ZigbeeAutofill = 'Zigbee Autofill',
  Autofill = 'Autofill',
  Condenser = 'Condenser',
  VSThermostat = 'VS Thermostat',
  SmartThermostat = 'SmartThermostat',
  TimedThermostat = 'TimedThermostat',
}

export enum ConnectedDevicesNameByType {
  MD = ConnectedDeviceType.MultiDot,
  ED = ConnectedDeviceType.EnviroDot,
  PM = ConnectedDeviceType.PowerMeter,
  AZ = ConnectedDeviceType.ZigbeeAutofill,
  AF = ConnectedDeviceType.Autofill,
}

interface AlertProps {
  message: string;
  severity: 'Medium' | 'High';
}

export enum DeviceStatus {
  Ok = 'Ok',
  Allocated = 'Allocated',
  Installed = 'Installed',
  DisabledNonPayment = 'DisabledNonPayment',
  DisabledOther = 'DisabledOther',
  PreventativeMaintenance = 'PreventativeMaintenance',
  RepairedDevice = 'RepairedDevice',
  RepairedEquipment = 'RepairedEquipment',
  Inventory = 'Inventory',
  Unknown = 'Unknown',
  Factory = 'Factory',
  AwaitingRecycling = 'AwaitingRecycling',
  REMOVED = 'REMOVED',
  DISPOSED = 'DISPOSED',
  FailedEquipment = 'FailedEquipment',
  FailedDevice = 'FailedDevice',
}

export type PowerClamps = { A?: string; B?: string; C?: string };

export interface ConnectedDevice {
  deviceId: string;
  status: DeviceStatus;
  deviceType: ConnectedDeviceType;
  serialNumber: string;
  accessRightsTag: string;
  userHasReadOnlyAccess: boolean;
  userCanUpdateAccessRightsTag: boolean;
  eui64?: string;
  location: string | null;
  locationDetails: string;
  powerClamps?: PowerClamps;
  alerts: AlertProps[];
}

export enum OccupiedStatus {
  Occupied = 'Occupied',
  Occasional = 'Occasional',
  Holiday = 'Holiday',
  Empty = 'Empty',
}

export enum BcmMonitoring {
  Unknown = 'Unknown',
  None = 'None',
  Boiler = 'Boiler',
  HeatPump = 'HeatPump',
}

export interface BcmInfo {
  tenantId: string;
  deviceId: string;
  serialNumber: string;
  monitoring: BcmMonitoring;
  firmwareVersion: string;
  product: string;
  model: string;
  status: DeviceStatus;
  boilerManufacturer: string;
  boilerModel: string;
  boilerGc: string;
  faultCode: string;
  faultCodeReported: string;
  propertyId: string;
  propertyLocationId: string;
  tagIds: string[];
  accessRightsTagId: string | null;
  isResettable: boolean | null;
  hasBuzzer: boolean;
}

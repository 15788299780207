import React from 'react';

import PhoneNumberInput from 'components/atoms/PhoneNumberInput';

import BigRadioInput from './BigRadio';
import CheckBox from './CheckBox';
import CheckList from './CheckList';
import DateField from './DateField';
import DateRangeInput from './DateRangeInput';
import DateTimeField from './DateTimeField';
import DropDownInput from './DropDownInput';
import DropDownSearch from './DropDownSearch';
import NumberInput from './NumberInput';
import PassFailInput from './PassFailInput';
import RadioInput from './RadioInput';
import TextArea from './TextArea';
import TextInput from './TextInput';
import TextSearch from './TextSearch';
import UnitInput from './UnitInput';

const InputSelector = ({ type, inputData }) => (
  <>
    {(() => {
      switch (type) {
        case 'text':
          return <TextInput {...inputData} />;
        case 'textsearch':
          return <TextSearch {...inputData} />;
        case 'textarea':
          return <TextArea {...inputData} />;
        case 'dropdown':
          return <DropDownInput {...inputData} />;
        case 'search':
          return <DropDownSearch {...inputData} />;
        case 'date':
          return <DateField {...inputData} />;
        case 'datetime':
          return <DateTimeField {...inputData} />;
        case 'check':
          return <CheckBox {...inputData} />;
        case 'checklist':
          return <CheckList {...inputData} />;
        case 'number':
          return <NumberInput {...inputData} />;
        case 'radio':
          return <RadioInput {...inputData} />;
        case 'bigRadio':
          return <BigRadioInput {...inputData} />;
        case 'unit':
          return <UnitInput {...inputData} />;
        case 'daterange':
          return <DateRangeInput {...inputData} />;
        case 'phone':
          return <PhoneNumberInput {...inputData} />;
        case 'passfail':
          return <PassFailInput {...inputData} />;
        default:
          return null;
      }
    })()}
  </>
);

export default InputSelector;

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useAccess from 'utils/hooks/useAccess';
import { Areas } from 'types/roles';
import { Route } from 'types';
import { SidebarItemWrapper } from './SidebarItem.styles';

type SidebarItemProps = {
  route: Route;
  selectedTenant: string;
  open: boolean;
  onClick?: () => void;
  subMenuOpen?: boolean;
  subMenuParent?: boolean;
  parentIconActive?: boolean;
};

export const SidebarItem: React.FC<SidebarItemProps> = ({
  route,
  selectedTenant,
  open,
  onClick,
  subMenuOpen,
  subMenuParent,
  parentIconActive,
}) => {
  const { isDev, isEdit } = useAccess(Areas.misc);

  const to = `/${selectedTenant}${route.path}`;
  const location = useLocation();
  const isActive = location.pathname.includes(to);

  if (route.name === 'Emergency Light Test Results' && !isDev) return null;
  if (route.name === 'Users' && !isEdit) return null;

  return route.icon ? (
    <ListItem
      aria-label="sidebar-item"
      component={SidebarItemWrapper}
      className="sidebar-item"
      disablePadding
    >
      <ListItemButton
        aria-label="sidebar-item-link"
        className={`sidebar-item__button${
          (isActive && !subMenuParent) || parentIconActive ? ' selected' : ''
        }`}
        sx={{
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
        {...(subMenuParent ? {} : { component: NavLink, to })}
        {...(onClick ? { onClick } : {})}
      >
        <ListItemIcon
          className="sidebar-item__button-icon"
          sx={{
            mr: open ? 3 : 'auto',
          }}
        >
          <svg aria-label="sidebar-icon" className="sidebar-item__button-icon-svg">
            <use href={`images/icon-${route.icon}.svg#icon-${route.icon}`} />
          </svg>
        </ListItemIcon>
        <ListItemText
          className="sidebar-item__button-text"
          primary={route.name}
          sx={{ opacity: open ? 1 : 0 }}
        />
        {subMenuParent && open && <>{subMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>}
      </ListItemButton>
    </ListItem>
  ) : null;
};

import moment from 'moment';
import { min, max } from 'd3-array';

export const getX = (data: any) => data.date;
export const getY = (data: any) => data.value;

export const getNumTicks = (graphWidth) => {
  if (graphWidth > 1000) {
    return 9;
  }

  return graphWidth > 600 ? 6 : 4;
};

export const handleTooltipData = ({ dataSeries, getTooltipData, chartContext }) => {
  const tooltipObj = getTooltipData(moment(chartContext.selectedDateTime), dataSeries, 'middle');
  const earliestDateInSeries = min(dataSeries, getX);
  const latestDateInSeries = max(dataSeries, getX);

  let tooltipToShow = tooltipObj;

  if (!tooltipToShow.data) {
    tooltipToShow = getTooltipData(latestDateInSeries, dataSeries, 'latest');
  } else if (moment(tooltipToShow.dateFromXAxis).isBefore(earliestDateInSeries)) {
    tooltipToShow = getTooltipData(earliestDateInSeries, dataSeries, 'earliest');
  } else if (moment(tooltipToShow.dateFromXAxis).isAfter(latestDateInSeries)) {
    tooltipToShow = getTooltipData(latestDateInSeries, dataSeries, 'latest');
  }

  return tooltipToShow;
};

import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';

const ErrorWrapper = styled.div`
  ${(props) => props.theme.wrapper};
  width: 80%;
  min-width: 36rem;
  margin: 4rem auto;
  padding: 6rem 4rem;
  text-align: center;
`;

const Button = styled.button`
  ${(props) => props.theme.defaultButton('26rem')};
  margin-top: 4rem;
`;

const ErrorPage = () => {
  const tenant = useSelectedTenant();

  return (
    <ErrorWrapper>
      <h1>Woops... something has gone wrong</h1>
      <p>
        Sorry, an error has occured our end and support has been notified. Please try again later
      </p>
      <Link to={`/${tenant}/Dashboard`}>
        <Button>Go back to dashboard</Button>
      </Link>
    </ErrorWrapper>
  );
};

export default ErrorPage;

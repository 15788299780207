import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';

type TabItemProps = {
  to: string;
  label: string;
  defaultLocation: string;
};

export const TabItem: React.FC<TabItemProps> = ({ to, label, defaultLocation }) => {
  const tenant = useSelectedTenant();
  const location = useLocation();
  const isActive = location.pathname.includes(to);
  const defaultActive = to === '' && location.pathname === `/${tenant}/${defaultLocation}`;

  const getClassName = () => {
    if (defaultActive || (isActive && to)) {
      return 'active';
    }

    return '';
  };

  const className = getClassName();

  return (
    <Link to={to}>
      <li className={className}>{label}</li>
    </Link>
  );
};

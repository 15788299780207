import { useCallback, useState } from 'react';

const getDefaultStatuses = (list: string[]) =>
  list.reduce(
    (acc, statusItem) => ({
      ...acc,
      [statusItem]: { loading: false, error: false, success: false },
    }),
    {}
  );

export const useApiStatus = (statusList: string[]) => {
  const [status, setStatus] = useState(getDefaultStatuses(statusList));

  const updateStatus = useCallback((newStatusList) => {
    setStatus(getDefaultStatuses(newStatusList));
  }, []);

  const setLoadingStatus = useCallback((statusItem: string) => {
    setStatus((prev) => ({
      ...prev,
      [statusItem]: { loading: true, error: false, success: false },
    }));
  }, []);

  const setErrorStatus = useCallback((statusItem: string) => {
    setStatus((prev) => ({
      ...prev,
      [statusItem]: { loading: false, success: false, error: true },
    }));
  }, []);

  const setSuccessStatus = useCallback((statusItem: string) => {
    setStatus((prev) => ({
      ...prev,
      [statusItem]: { loading: false, error: false, success: true },
    }));
  }, []);

  return {
    status,
    setLoadingStatus,
    setSuccessStatus,
    setErrorStatus,
    updateStatus,
  };
};

import { css } from 'styled-components';

export const tablePageWrapperStyles = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const tablePageStyles = css`
  ${tablePageWrapperStyles}

  .table-page {
    &__header {
      padding: 12px 12px 16px;

      &-title {
        &-text {
          font-size: 3.2rem;
          font-weight: 500;
        }
      }
    }

    &__table {
      width: 100%;
      height: calc(100vh - 19.5rem);

      @media (min-width: 768px) {
        height: calc(100vh - 19rem);
      }

      @media (min-width: 1800px) {
        height: calc(100vh - 19.2rem);
      }
    }

    &__chips {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 12px;

      .MuiChip-root {
        margin-left: 6px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .MuiDataGrid-cell,
  .MuiDataGrid-cell--withRenderer {
    overflow: visible !important;
  }
`;

import React from 'react';

import styled from 'styled-components';

const SNavIcon = styled.span<{ size: number; thickness: number; rotation: number }>`
  display: inline-block;
  width: ${(props) => props.size}rem;
  height: ${(props) => props.size}rem;
  border-right: ${(props) => props.thickness}px solid ${(props) => props.theme.default};
  border-top: ${(props) => props.thickness}px solid ${(props) => props.theme.default};
  transform: rotate(${(props) => props.rotation}deg);
  cursor: pointer;
`;

const NavIcon = ({ size, thickness = 3, rotation = 45 }) => (
  <SNavIcon className="widget-nav-icon" size={size} thickness={thickness} rotation={rotation} />
);

export default NavIcon;

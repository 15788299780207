import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding-right: 2rem;

  .bulk-remove-tags {
    &__tags {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: flex-start;
      padding-top: 1.5rem;
      row-gap: 0.5rem;

      & > span {
        font-size: 1.2rem;
        padding: 0.5rem 1rem 0.5rem 1.25rem;
        margin: 0 0.25rem;
      }
    }

    &__select {
      width: 20rem;
    }

    &__description {
      padding-bottom: 1rem;
    }
  }
`;

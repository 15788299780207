export const managedServiceTableColumns = [
  { field: 'licenceType', headerName: 'Licence type', flex: 1 },
  { field: 'saasStartDate', headerName: 'Service Start Date', flex: 1 },
  { field: 'saasEndDate', headerName: 'Service End Date', flex: 1 },
  { field: 'saasPeriod', headerName: 'Service Period', flex: 1 },
  { field: 'saasRenewedDate', headerName: 'Service Renewed Date', flex: 1 },
];

export const devicePackagesTableColumns = [
  { field: 'name', headerName: 'Device', flex: 1 },
  { field: 'serialNumber', headerName: 'Serial Number', flex: 1 },
  { field: 'licenceType', headerName: 'Licence Type', flex: 1 },
  { field: 'saasStartDate', headerName: 'SaaS Start Date', flex: 1 },
  { field: 'saasPeriod', headerName: 'SaaS Period', flex: 1 },
  { field: 'saasEndDate', headerName: 'SaaS End Date', flex: 1 },
  { field: 'saasRenewedDate', headerName: 'SaaS Renewed Date', flex: 1 },
];

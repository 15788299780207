import React, { useState, useEffect } from 'react';

import Input from 'components/elements/Input';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDeviceMessages } from 'reducers/device/deviceActions';
import styled from 'styled-components';
import { toLocalIsoString } from 'utils/helpers';

import MessageList from './MessageList';

const Wrapper = styled.div`
  padding: 2rem 1rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem 2rem;
`;

const InputWrapper = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
`;

const NoMessages = styled.h1`
  text-align: center;
  margin-top: 3rem;
`;

const DeviceMessages = ({ device }) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const messages = useSelector((state: any) => state.device.messages);

  const deviceId = device.deviceId;

  useEffect(() => {
    const getMessages = async () =>
      await dispatch(fetchDeviceMessages(deviceId, toLocalIsoString(date)));
    getMessages();
  }, [deviceId, date, dispatch]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <InputWrapper>
          Select date: &nbsp;
          <Input
            type="date"
            width="30%"
            inputData={{
              name: 'date',
              onChange: (v) => setDate(v),
              value: date,
            }}
          />
        </InputWrapper>
        <div>No Of Messages: {messages.length}</div>
      </HeaderWrapper>
      {messages?.length ? (
        <MessageList messages={messages} />
      ) : (
        <NoMessages>No Messages</NoMessages>
      )}
    </Wrapper>
  );
};

export default DeviceMessages;

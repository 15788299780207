import React from 'react';

import { Device, DeviceTypes } from 'types/DeviceTypes/Device';

import { DevicePartial } from 'types/DevicePartial';
import { NetworkingDevice } from 'types/NetworkingDevice';
import { EmeredDevice } from 'types/EmeredTypes';
import { ActionType } from '../actions';
import { UninstallDevice } from '../Dialogs';
import JoinNetwork from './JoinNetwork';
import LeaveNetwork from './LeaveNetwork';
import RedirectToApp from './RedirectToApp';
import UpdateTags from './UpdateTags';
import { UpdateAccessRightsTag } from './UpdateAccessRightsTag/UpdateAccessRightsTag';

interface DeviceActionSelectorProps {
  actionType: ActionType;
  device: Device | EmeredDevice | NetworkingDevice | DevicePartial;
  handleResetAction(): void;
}

const DeviceActionSelector = ({
  actionType,
  handleResetAction,
  device,
}: DeviceActionSelectorProps) => (
  <>
    {(() => {
      switch (actionType) {
        case 'claimFromWholesaler':
          return <RedirectToApp device={device} handleResetAction={handleResetAction} />;
        case 'viewInApp':
          return <RedirectToApp device={device} handleResetAction={handleResetAction} />;
        case 'install':
          return <RedirectToApp device={device} handleResetAction={handleResetAction} />;
        case 'uninstall':
          return (
            <UninstallDevice
              isOpen
              shouldBeRedirected={
                device.deviceType.includes(DeviceTypes.BcmConnect) ||
                device.deviceType.includes(DeviceTypes.Boiler)
              }
              device={device}
              handleResetAction={handleResetAction}
            />
          );
        case 'updateTags':
          return <UpdateTags device={device} handleResetAction={handleResetAction} />;
        case 'updateAccessRightsTag':
          return <UpdateAccessRightsTag device={device} handleResetAction={handleResetAction} />;
        case 'joinNetwork':
          return <JoinNetwork device={device} handleResetAction={handleResetAction} />;
        case 'leaveNetwork':
          return <LeaveNetwork device={device} handleResetAction={handleResetAction} />;
        case 'none':
        default:
          return null;
      }
    })()}
  </>
);

export default DeviceActionSelector;

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import DeviceMessages from 'components/DeviceDetails/DeviceMessages';
import { fetchNetworkDevice, refreshNetworkDevice } from 'reducers/device/networkActions';
import useShouldUpdateDevice from 'utils/hooks/useShouldUpdateDevice';
import DeviceDetails from '../../components/DeviceDetails';
import DebugInfo from '../../components/DeviceDetails/DebugInfo';
import NetworkingDeviceDetails from '../../components/DeviceDetails/NetworkingDeviceDetails';

const tabs = [
  {
    tab: 'info',
    display: 'Info',
    component: NetworkingDeviceDetails,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'debug',
    display: 'Debug',
    component: DebugInfo,
    access: (authObj) => authObj.isDev,
  },
  {
    tab: 'messages',
    display: 'Messages',
    component: DeviceMessages,
    access: (authObj) => authObj.isDev,
  },
];

const NetworkingDeviceDetailsPage = () => {
  const dispatch = useDispatch();
  const device = useSelector((state: any) => state.device.device);
  const { deviceId }: any = useParams();

  const [data] = useState();

  const refreshDeviceDetails = useCallback(
    async () => await dispatch(refreshNetworkDevice({ deviceId, startDate: new Date() })),
    [deviceId, dispatch]
  );

  const triggerUpdateDevice = useShouldUpdateDevice(deviceId, refreshDeviceDetails);

  const stateDeviceId = device?.deviceId;
  useEffect(() => {
    const fetchDeviceDetails = async () => {
      await dispatch(fetchNetworkDevice({ deviceId, startDate: new Date() }));
    };
    if (deviceId !== stateDeviceId) fetchDeviceDetails();
  }, [stateDeviceId, deviceId, dispatch]);

  return (
    <DeviceDetails
      triggerUpdateDevice={triggerUpdateDevice}
      tabs={tabs}
      device={device}
      details={data}
    />
  );
};

export default NetworkingDeviceDetailsPage;

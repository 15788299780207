import React, { useState, useEffect } from 'react';

import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import useDimensions from 'utils/hooks/useDimensions';
import { initImgDims, latLng2MetersPerDegree, calcMetersPerPx } from 'utils/mapUtils';

import MapStyles from '../MapStyles';
import EditImageOverlay from '../Overlays/EditImageOverlay';

const MapWrapper = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
`;

const DevicePositioningMap = ({ floor, setFloor }) => {
  const [mapRef] = useDimensions();
  const [drag, setDrag] = useState(true);
  const [mapSettings, setMapSettings] = useState({
    center: {
      lat: floor.lat,
      lng: floor.lng,
    },
    zoom: 19,
  });

  const [imgDims, setImgDims] = useState(initImgDims(floor, mapSettings.zoom));
  const [dimensions, setDimensions] = useState({ width: imgDims.w, height: imgDims.h, x: 0, y: 0 });

  useEffect(() => {
    if (floor) {
      const dims = initImgDims(floor, mapSettings.zoom);
      setImgDims(dims);
      setDimensions({ width: dims.w, height: dims.h, x: 0, y: 0 });
    }
  }, [floor, mapSettings.zoom]);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const onChildMouseDown = (childKey, childProps, mouse) => {
    setDrag(false);
  };
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const onChildMouseUp = (childKey, childProps, mouse) => {
    setDrag(true);
  };
  // eslint-disable-next-line no-unused-vars
  const onChildMouseMove = (childKey, childProps, mouse) => {
    setFloor(mouse.lat, mouse.lng);
  };

  const _onChange = ({ center, zoom }) => {
    setMapSettings({
      center,
      zoom,
    });
  };

  const calcLatLongChange = (dX, dY) => {
    const meterPerPx = calcMetersPerPx(floor.lat, mapSettings.zoom);
    const { metersPerLatDegree, metersPerLngDegree } = latLng2MetersPerDegree(floor.lat);
    const dLat = (dY * meterPerPx) / metersPerLatDegree;
    const dLong = (dX * meterPerPx) / metersPerLngDegree;

    setFloor(floor.lat - dLat, floor.lng + dLong);
  };

  return (
    <>
      {mapSettings && (
        <MapWrapper ref={mapRef}>
          {!isNaN(mapSettings.zoom) && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS }}
              draggable={drag}
              onChange={_onChange}
              center={mapSettings.center}
              zoom={mapSettings.zoom}
              hoverDistance={15}
              options={{
                mapTypeControl: true,
                fullscreenControl: false,
                mapTypeControlOptions: {
                  position: 7,
                  mapTypeIds: ['roadmap', 'satellite'],
                },
                rotateControl: false,
                tilt: 0,
                styles: MapStyles,
                gestureHandling: 'greedy',
              }}
              onChildMouseDown={onChildMouseDown}
              onChildMouseUp={onChildMouseUp}
              onChildMouseMove={onChildMouseMove}
            >
              <EditImageOverlay
                lat={floor.lat}
                lng={floor.lng}
                dimensions={dimensions}
                rotation={floor.rotation}
                image={floor.image}
                calcLatLong={calcLatLongChange}
                setDrag={setDrag}
                mapSettings={mapSettings}
              />
            </GoogleMapReact>
          )}
        </MapWrapper>
      )}
    </>
  );
};

export default DevicePositioningMap;

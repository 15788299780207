import React, { useEffect, useMemo, useState } from 'react';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { isEqual } from 'lodash';
import { FaPlus, FaCheck } from 'react-icons/fa';
import { useForm } from 'utils/hooks';

import { InputEndIcon } from 'components/atoms';
import { AddTagWrapper } from './AddTag.styles';

const validationState = {
  tagName: { fn: (v) => /^[^\s]*$/.test(v), errorText: 'No spaces in tag', required: false },
};

type AddTagProps = {
  isLoading: boolean;
  handleAddTag: (tagName: string) => void;
  error?: string;
  clearError?: () => void;
};

export const AddTag: React.FC<AddTagProps> = ({ isLoading, handleAddTag, error, clearError }) => {
  const [showField, setShowField] = useState(false);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  const initialFormState = useMemo(() => ({ tagName: '' }), []);

  const { formState, isValid, errors, registerField, setFormState, handleChange } = useForm({
    initialFormState,
    validationState,
  });

  const isSubmitDisabled =
    !isValid || isEqual(initialFormState, formState) || isSubmitInProgress || !!error;

  const handleSubmit = () => {
    setIsSubmitInProgress(true);
    handleAddTag(formState.tagName);
  };

  const handleOpenForm = () => {
    setShowField(true);

    if (error) {
      clearError?.();
    }
  };

  const handleCloseForm = () => {
    setShowField(false);
    setFormState(initialFormState);

    if (error) {
      clearError?.();
    }
  };

  const handleFieldChange = (e) => {
    handleChange(e);

    if (error) {
      clearError?.();
    }
  };

  useEffect(() => {
    if (isSubmitInProgress && !isLoading && !error) {
      setIsSubmitInProgress(false);
      setShowField(false);
      setFormState(initialFormState);
    }

    if (error && isSubmitInProgress) {
      setIsSubmitInProgress(false);
    }
  }, [isLoading, isSubmitInProgress, initialFormState, setFormState, error]);

  return (
    <AddTagWrapper>
      <div className="add-tag__button">
        <Button
          onClick={handleOpenForm}
          endIcon={<FaPlus size={15} />}
          aria-label="add-access-rights-tag"
          variant="contained"
          color="success"
        >
          Add
        </Button>
      </div>
      {showField && (
        <div className="add-tag__form">
          <TextField
            {...registerField('tagName')}
            onChange={handleFieldChange}
            error={!!errors.tagName || !!error}
            helperText={errors.tagName || error}
            label="Tag Name"
            variant="outlined"
            fullWidth
            disabled={isSubmitInProgress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InputEndIcon
                    isError={!!errors.tagName || !!error}
                    value={formState.tagName}
                    isNotFocused
                  />
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            onClick={handleSubmit}
            className="add-tag__form-submit"
            aria-label="access-rights-tag-submit"
            disabled={isSubmitDisabled}
          >
            <FaCheck className="add-tag__form-submit-icon" />
          </IconButton>
          <IconButton
            className="add-tag__form-close"
            disabled={isSubmitInProgress}
            onClick={handleCloseForm}
          >
            <FaPlus className="add-tag__form-close-icon" />
          </IconButton>
        </div>
      )}
    </AddTagWrapper>
  );
};

import React, { useCallback, useState } from 'react';

type RegisterField = {
  value: string;
  onChange: ({
    target: { name, value },
  }: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  onBlur: (event: any) => boolean;
  onFocus: () => void;
  name: string;
};

type UseFormReturn = {
  formState: { [key: string]: string };
  errors: { [key: string]: string };
  focused: { [key: string]: boolean };
  isValid: boolean;
  handleFocus: (name: string) => void;
  handleChange: ({ target }: { target: { name: string; value: string } }) => void;
  handleBlur: (name: string, value: string) => boolean;
  registerField: (name: string) => RegisterField;
  setFormState: React.Dispatch<any>;
  resetForm: () => void;
};

export type ValidationStateType = {
  [key: string]: {
    fn?: (value: string) => boolean;
    required?: boolean;
    requiredErrorText?: string;
    errorText?: string;
  };
};

type UseFormProps = {
  initialFormState: { [key: string]: string };
  validationState: ValidationStateType;
};

const getInitialState = (state, value) =>
  Object.keys(state).reduce((acc, field) => ({ ...acc, [field]: value }), {});

export const useForm = ({ initialFormState, validationState }: UseFormProps): UseFormReturn => {
  const errorsInitialState: { [key: string]: string } = getInitialState(initialFormState, '');
  const focusedInitialState: { [key: string]: boolean } = getInitialState(initialFormState, false);

  const [formState, setFormState] = useState(initialFormState);
  const [errors, setErrors] = useState(errorsInitialState);
  const [focused, setFocused] = useState(focusedInitialState);

  const validateField = useCallback(
    (name, value) => {
      const validation = validationState[name];

      const error =
        (validation.fn && validation?.fn(value)) || !validation.fn ? '' : validation?.errorText;

      setErrors((prev) => ({
        ...prev,
        [name]: error,
      }));

      return error === '';
    },
    [validationState]
  );

  const handleBlur = (name, value) => {
    const validation = validationState[name];
    setFocused((prev) => ({
      ...prev,
      [name]: false,
    }));

    if (value === '' && validation?.required) {
      const requiredText =
        validation.requiredErrorText ||
        `${name.charAt(0).toUpperCase()}${name.slice(1)} is required!`;

      setErrors((prev) => ({
        ...prev,
        [name]: requiredText,
      }));

      return false;
    }

    return validateField(name, value);
  };

  const handleChange = ({ target: { name, value } }) => {
    validateField(name, value);
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFocus = (name) => {
    setFocused((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const registerField = (name: string) => ({
    value: formState[name],
    onChange: handleChange,
    onBlur: (event) => handleBlur(name, event.target.value),
    onFocus: () => handleFocus(name),
    name,
  });

  const resetForm = () => {
    setFormState(initialFormState);
    setErrors(errorsInitialState);
    setFocused(focusedInitialState);
  };

  const isNoErrors = Object.values(errors).every((error) => error.length === 0);
  const isRequiredFieldsFiled = Object.entries(validationState)
    .filter(([, value]) => value?.required)
    .every(([field]) => !!formState[field]);

  const isValid = isNoErrors && isRequiredFieldsFiled;

  return {
    formState,
    errors,
    focused,
    isValid,
    handleFocus,
    handleChange,
    handleBlur,
    registerField,
    setFormState,
    resetForm,
  };
};

import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

import { EllipsisWithTooltip } from 'components/atoms';

export const propertyRecordsColumns: GridColDef[] = [
  { field: 'title', headerName: 'Document Title', display: 'flex', flex: 1, minWidth: 100 },
  {
    field: 'date',
    headerName: 'Date and time',
    type: 'date',
    display: 'flex',
    flex: 1,
    minWidth: 100,
    valueFormatter: (value) => (value ? moment(value).format('DD/MM/YYYY - HH:mm:ss') : ''),
  },
  {
    field: 'location',
    headerName: 'Location in property',
    display: 'flex',
    flex: 1,
    minWidth: 100,
  },
  { field: 'createdBy', headerName: 'Uploaded by', display: 'flex', flex: 1, minWidth: 100 },
  {
    field: 'note',
    headerName: 'Note',
    display: 'flex',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ width: '100%' }}>
        <EllipsisWithTooltip text={params.value} />
      </div>
    ),
  },
];

import React from 'react';

import { useSelector } from 'react-redux';

import DeviceActionContainer from '../DeviceActionContainer';
import EmeredFault from './EmeredFault';
import EmeredForm from './EmeredForm';

const EmeredMaintenanceForm = ({ device }) => {
  const testData = useSelector((state: any) => state.device?.statusTest);

  return (
    <DeviceActionContainer
      title="Maintenance"
      fault={testData?.result === 'Failed' ? <EmeredFault testData={testData} /> : null}
      form={<EmeredForm device={device} />}
      device={device}
    />
  );
};

export default EmeredMaintenanceForm;

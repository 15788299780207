import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button } from '@mui/material';

import { ResetDevice, UninstallDevice } from 'components/molecules';
import {
  ConnectedDevicesNameByType,
  DeviceStatus,
  PowerClamps,
} from 'types/BoilerTypes/BcmConnectDetails';
import { DeviceTypes } from 'types/DeviceTypes/Device';
import { completeConnectedDeviceInstall, parseSerialNumber } from 'utils';
import { PropertyContextProps } from 'routes/Property/types';
import { ActionCellContentWrapper } from './ActionCellContent.styles';

type ActionCellContentProps = {
  bcmId: string;
  serialNumber: string;
  deviceId: string;
  location: string | null;
  locationDetails?: string | null;
  powerClamps?: PowerClamps;
  status: DeviceStatus;
};

export const ActionCellContent: React.FC<ActionCellContentProps> = ({
  deviceId,
  serialNumber,
  location,
  locationDetails,
  bcmId,
  powerClamps,
  status,
}) => {
  const handleCompleteInstall = () =>
    completeConnectedDeviceInstall({
      serialNumber,
      deviceId,
      location,
      locationDetails,
      bcmId,
      powerClamps,
    });

  const { product } = parseSerialNumber(serialNumber);
  const { bcmInfo, devicesInfo } = useOutletContext<PropertyContextProps>();

  const refetchDeviceInfo = () => devicesInfo.refetch();

  const isConnectedDevice = Object.keys(ConnectedDevicesNameByType).includes(product);
  const showCompleteInstallButton = status === DeviceStatus.Allocated && isConnectedDevice;
  const showAutoFillResetButton =
    (status === DeviceStatus.FailedDevice || status === DeviceStatus.FailedEquipment) &&
    (product === DeviceTypes.ZigbeeAutofill || product === DeviceTypes.Autofill);

  const showBoilerResetButton =
    bcmInfo.isSuccess &&
    bcmInfo.data?.isResettable &&
    (product === DeviceTypes.Boiler || product === DeviceTypes.BcmConnect);

  return (
    <ActionCellContentWrapper>
      {showAutoFillResetButton && (
        <div className="action-cell-content__block">
          <ResetDevice
            deviceId={bcmId}
            apiData={{
              url: `${process.env.REACT_APP_APIURL}AutofillReset`,
              errorMessage: 'Failed to reset Autofill!',
              successMessage: `Autofill reset on ${serialNumber} was successful!`,
            }}
            deviceName="Autofill"
            serialNumber={serialNumber}
            refresh={refetchDeviceInfo}
          />
        </div>
      )}
      {showBoilerResetButton && (
        <div className="action-cell-content__block">
          <ResetDevice
            deviceId={bcmId}
            apiData={{
              url: `${process.env.REACT_APP_APIURL}BoilerReset`,
              errorMessage: 'Failed to reset Boiler!',
              successMessage: `Boiler reset on ${serialNumber} was successful!`,
            }}
            deviceName="Boiler"
            serialNumber={serialNumber}
            refresh={() => {
              refetchDeviceInfo();
              bcmInfo.refetch();
            }}
          />
        </div>
      )}
      {showCompleteInstallButton && (
        <div className="action-cell-content__block">
          <Button
            variant="contained"
            data-testid="complete-button"
            color="info"
            onClick={handleCompleteInstall}
          >
            Complete Install
          </Button>
        </div>
      )}
      <div className="action-cell-content__block">
        <UninstallDevice
          deviceId={deviceId}
          serialNumber={serialNumber}
          refresh={refetchDeviceInfo}
        />
      </div>
    </ActionCellContentWrapper>
  );
};

import { Dispatch } from 'redux';

import {
  addErrorToastAction,
  addSuccessToastAction,
  handleReduxDeleteRequest,
  handleReduxGetRequest,
  handleReduxPostRequest,
} from 'utils/redux';

import { ActionTypes, CreateAction, DeleteAction, FetchAction } from './actionTypes';

export const fetchAccessRightsTagsAction =
  (): any => (dispatch: Dispatch<FetchAction>, getState: () => any) => {
    const state = getState();
    const currentTenant = state.auth.user?.selectedTenant ?? '';

    handleReduxGetRequest({
      query: `Tenants/${currentTenant}/AccessRightsTags`,
      onInit: () => dispatch({ type: ActionTypes.FETCH_ACCESS_RIGHTS_TAGS_REQUEST_START }),
      onSuccess: (data) =>
        dispatch({ type: ActionTypes.FETCH_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS, payload: data }),
      onError: () => {
        dispatch({ type: ActionTypes.FETCH_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE });
      },
    });
  };

export const createAccessRightsTagsAction =
  (displayName: string) => (dispatch: Dispatch<CreateAction>, getState: () => any) => {
    const state = getState();
    const currentTenant = state.auth.user?.selectedTenant ?? '';

    handleReduxPostRequest({
      query: `Tenants/${currentTenant}/AccessRightsTags`,
      body: {
        displayName,
      },
      onInit: () => dispatch({ type: ActionTypes.CREATE_ACCESS_RIGHTS_TAGS_REQUEST_START }),
      onSuccess: () => {
        dispatch({ type: ActionTypes.CREATE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS });
        dispatch(fetchAccessRightsTagsAction());
        addSuccessToastAction(dispatch, 'Tag created successfully!');
      },
      onError: (errorMessage) =>
        dispatch({
          type: ActionTypes.CREATE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE,
          errorMessage,
        }),
    });
  };

export const deleteAccessRightsTagsAction =
  (accessRightsTagId: string) => (dispatch: Dispatch<DeleteAction>, getState: () => any) => {
    const state = getState();
    const currentTenant = state.auth.user?.selectedTenant ?? '';

    handleReduxDeleteRequest({
      query: `Tenants/${currentTenant}/AccessRightsTags/${accessRightsTagId}`,
      onInit: () => dispatch({ type: ActionTypes.DELETE_ACCESS_RIGHTS_TAGS_REQUEST_START }),
      onSuccess: () => {
        dispatch({ type: ActionTypes.DELETE_ACCESS_RIGHTS_TAGS_REQUEST_SUCCESS });
        dispatch(fetchAccessRightsTagsAction());
        addSuccessToastAction(dispatch, 'Tag deleted!');
      },
      onError: (errorMessage) => {
        dispatch({ type: ActionTypes.DELETE_ACCESS_RIGHTS_TAGS_REQUEST_FAILURE, errorMessage });
        addErrorToastAction(dispatch, 'Failed to delete tag!');
      },
    });
  };

export const clearCreateAccessRightsTagsError = () => ({
  type: ActionTypes.CLEAR_CREATE_ACCESS_RIGHTS_TAGS_ERROR,
});

export const clearDeleteAccessRightsTagsError = () => ({
  type: ActionTypes.CLEAR_DELETE_ACCESS_RIGHTS_TAGS_ERROR,
});

export const clearAccessRightsTags = () => ({ type: ActionTypes.CLEAR_ACCESS_RIGHTS_TAGS });

/* eslint-disable max-len */
import React from 'react';

const SaveAsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 34 34">
    <path
      d="M32.9,14.5l-4.4-4.4C28,9.5,26.8,9.1,26,9.1h-2V8.9c0-0.6-0.2-1.3-0.6-1.9c-0.1-0.3-0.3-0.5-0.5-0.7L18.6,2
                C18,1.4,16.8,0.9,16,0.9H1.5C0.7,0.9,0,1.6,0,2.4v21c0,0.8,0.7,1.5,1.5,1.5H10v6.6c0,0.8,0.7,1.5,1.5,1.5h21c0.8,0,1.5-0.7,1.5-1.5
                V17.1C34,16.2,33.5,15.1,32.9,14.5z M23.5,11.1c0.3,0,0.5,0.2,0.5,0.5v5c0,0.3-0.2,0.5-0.5,0.5h-3c-0.3,0-0.5-0.2-0.5-0.5v-5
                c0-0.3,0.2-0.5,0.5-0.5H23.5z M16,2.9c0.3,0,0.9,0.3,1.1,0.5l4.4,4.4C21.7,8,22,8.6,22,8.9v0.1h-6V2.9z M10,22.9H6v-6h4V22.9z
                M10,14.9H5.5c-0.8,0-1.5,0.7-1.5,1.5v6.5H2v-20h2v6.5c0,0.8,0.7,1.5,1.5,1.5H10V14.9z M10.5,8.9c-0.3,0-0.5-0.2-0.5-0.4
                c0,0,0,0,0-0.1v-5c0-0.3,0.2-0.5,0.5-0.5h3c0.3,0,0.5,0.2,0.5,0.5v5c0,0.3-0.2,0.5-0.5,0.5H10.5z M28,31.1H16v-6h12V31.1z M32,31.1
                h-2v-6.5c0-0.8-0.7-1.5-1.5-1.5h-13c-0.8,0-1.5,0.7-1.5,1.5v6.5h-2v-20h2v6.5c0,0.6,0.4,1.2,1,1.4c0.2,0.1,0.4,0.1,0.5,0.1h9
                c0.8,0,1.5-0.7,1.5-1.5v-6.5c0.3,0,0.9,0.3,1.1,0.5l4.4,4.4c0.2,0.2,0.5,0.8,0.5,1.1V31.1z"
    />
  </svg>
);

export default SaveAsIcon;

import moment from 'moment';

import { handleApiReq } from '../../../../utils/ApiRequestHelpers';
import {
  DataSeriesRequestList,
  DataSeries,
  Card,
  DataSeriesGroupedByContextId,
  MetricDataResponseList,
  EventDataResponseByContextId,
  MetricDataResponseGroupedByContextId,
  DataProps,
  EventDataProps,
} from './TelemetryConfigs';

interface HandleGetDeviceMetricsProps {
  dataSeriesRequestList: DataSeriesRequestList;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export const handleGetDeviceMetrics = async ({
  dataSeriesRequestList,
  startDate,
  endDate,
}: HandleGetDeviceMetricsProps): Promise<MetricDataResponseList> => {
  try {
    const allMetricResponses = Promise.all(
      dataSeriesRequestList.map(
        async ({ contextId, dataSeriesList }): Promise<MetricDataResponseGroupedByContextId> => {
          if (contextId !== '') {
            const startDateIsoString = moment(startDate).startOf('day').toISOString();
            const endDateIsoString = moment(endDate).endOf('day').toISOString();

            const metricsResponse: Array<DataSeries> = await handleApiReq(
              'getDeviceMetrics',
              {
                deviceId: contextId,
                from: startDateIsoString,
                to: endDateIsoString,
                metrics: dataSeriesList,
              },
              'POST'
            );

            return {
              contextId: contextId,
              metrics: metricsResponse
                ? metricsResponse
                : dataSeriesList.map((dataSeriesId) => ({
                  dataSeriesId,
                  series: null,
                  label: '',
                  unit: '',
                })),
            };
          } else {
            return {
              contextId: '',
              metrics: [],
            };
          }
        }
      )
    );

    return allMetricResponses;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    return [];
  }
};

interface HandleGetDeviceEventsProps {
  eventsCard: any;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export const handleGetDeviceEvents = async ({
  eventsCard,
  startDate,
  endDate,
}: HandleGetDeviceEventsProps): Promise<EventDataResponseByContextId> => {
  const startDateIsoString = moment(startDate).startOf('day').toISOString();
  const endDateIsoString = moment(endDate).endOf('day').toISOString();

  const allEventsResponse: EventDataResponseByContextId = {
    contextId: eventsCard.dataSeries[0].contextId,
    cardData: {
      dataSeriesId: eventsCard.dataSeries[0].dataSeriesId,
      label: eventsCard.title,
      eventSeries: await handleApiReq(
        'getDeviceEvents',
        {
          deviceId: eventsCard.dataSeries[0].contextId,
          from: startDateIsoString,
          to: endDateIsoString,
        },
        'POST'
      ),
      unit: eventsCard.dataSeries[0].units,
    },
  };

  allEventsResponse.cardData.eventSeries?.sort(
    (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
  );

  return allEventsResponse;
};

export const getUniqueContextIds = (cards: Array<Card>): Array<string> => {
  const uniqueContextIdsArr: Array<string> = [];

  cards.forEach((card) => {
    card.dataSeries.forEach((series) => {
      if (!uniqueContextIdsArr.includes(series.contextId)) {
        uniqueContextIdsArr.push(series.contextId);
      }
    });
  });

  return uniqueContextIdsArr;
};

export const getMetricIdListFromCards = (cards: Array<Card>): DataSeriesRequestList => {
  // This is to group dataSeriesId by context id to optimise the getDeviceMetrics API endpoint.
  const uniqueContextIdsArr = getUniqueContextIds(cards);
  const dataRequestlistObj = {};

  uniqueContextIdsArr.forEach((id) => {
    dataRequestlistObj[id] = [];
  });

  cards.forEach((card) => {
    card.dataSeries.forEach((series) => {
      if (series.dataSeriesId !== 'allevents') {
        dataRequestlistObj[series.contextId].push(series.dataSeriesId);
      }
    });
  });

  const listGroupedByOContextId = Object.keys(dataRequestlistObj).map((contextId) => {
    const contextObj: DataSeriesGroupedByContextId = {
      contextId,
      dataSeriesList: dataRequestlistObj[contextId],
    };

    return contextObj;
  });

  return listGroupedByOContextId;
};

export const mapResponseToCards = (
  cards: Array<Card>,
  dataResponse: MetricDataResponseList
): Array<Card> => {
  const updatedCards: Array<Card> = cards.map((card: Card) => {
    card.dataSeries.forEach((dataSeries) => {
      dataResponse.forEach((response) => {
        if (response.contextId === dataSeries.contextId) {
          if (response.metrics) {
            const responseFound = response.metrics.find(
              (data) => data.dataSeriesId.toLowerCase() === dataSeries.dataSeriesId.toLowerCase()
            );
            if (responseFound) {
              dataSeries.data = responseFound;
            }
          }
        }
      });

      if (dataSeries.contextId === '') {
        dataSeries.data = {
          dataSeriesId: '',
          label: '',
          series: [],
          eventSeries: [],
          unit: '',
        };
      }

      if (dataSeries.data) {
        if (dataSeries.data.eventSeries) {
          dataSeries.data.eventSeries = dataSeries.data.eventSeries.map(
            (data): EventDataProps => ({
              ...data,
              start: new Date(data.start),
              end: new Date(data.end),
            })
          );
        } else if (dataSeries.data.series) {
          dataSeries.data.series = dataSeries.data.series.map(
            (data): DataProps => ({
              value: data.value,
              date: new Date(data.date),
            })
          );
        }
      }

      return dataSeries;
    });

    card.requestComplete = true;

    return card;
  });

  return updatedCards;
};

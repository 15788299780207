import { BuildingInfo } from 'types/BuildingInfo';

import { buildingInfoTypes } from './actions';

interface buildingInitialState {
  buildings: BuildingInfo[] | undefined;
}

const initialState: buildingInitialState = {
  buildings: undefined,
};

export const buildingInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case buildingInfoTypes.CLEAR_BUILDINGINFO:
      return initialState;

    case buildingInfoTypes.FETCH_BUILDINGINFO_SUCCESS:
      return {
        ...state,
        buildings: action.payload.buildingInfo,
      };
    case buildingInfoTypes.UPDATE_FLOORPOS_SUCCESS:
    case buildingInfoTypes.FETCH_BUILDINGUPDATE_SUCCESS:
    case buildingInfoTypes.UPDATE_BUILDING_SUCCESS: {
      const updatedBuildings = state.buildings?.map((b) => {
        if (b.building === action.payload.building.building) {
          return action.payload.building;
        }

        return b;
      });

      return {
        ...state,
        buildings: updatedBuildings,
      };
    }
    default:
      return state;
  }
};

import { EPCRating } from 'types';

export const validations = {
  propertyReference: {
    propertyReference: {
      fn: (v) => v.length <= 30,
      errorText: 'Property reference must be under 30 characters',
      required: false,
    },
  },
  postcode: {
    postcode: {
      fn: (v) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\s]{5,}$/.test(v),
      errorText: 'Postcode must be valid',
      required: true,
    },
  },
};

export const epcRatingOptions = [
  EPCRating.A,
  EPCRating.B,
  EPCRating.C,
  EPCRating.D,
  EPCRating.E,
  EPCRating.F,
  EPCRating.G,
  EPCRating.NoRating,
];

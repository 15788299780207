import { useParams } from 'react-router-dom';
import { UseQueryResult } from 'react-query';

import { useGetFetchData, useSelectedTenant } from 'utils/hooks';
import { Property, PropertyDevice } from 'types';
import { BcmInfo } from 'types/BoilerTypes/BcmConnectDetails';
import { transformDevicesData } from 'utils/dataTransform';
import { getAvailableDevices } from 'utils';

export const usePropertyApi = () => {
  const { propertyId }: any = useParams();
  const tenant = useSelectedTenant();

  const propertyInfo: UseQueryResult<Property, unknown> = useGetFetchData({
    url: process.env.REACT_APP_API_URL_V2 as string,
    query: `tenants/${tenant}/properties/${propertyId}`,
    enableErrorHandler: true,
  });

  const devicesInfo: UseQueryResult<{ results: PropertyDevice[] }, unknown> = useGetFetchData({
    enabled: propertyInfo.isSuccess,
    url: process.env.REACT_APP_API_URL_V2 as string,
    query: `tenants/${tenant}/properties/${propertyId}/devices`,
    enableErrorHandler: true,
    dataTransform: (data) => {
      const propertyLocations = propertyInfo.data?.locations;
      const devices = data.results || [];

      return { results: transformDevicesData({ propertyLocations, devices }) };
    },
  });

  const devices = devicesInfo.data?.results || [];

  const { boiler } = getAvailableDevices({ devices });

  const bcmInfo: UseQueryResult<BcmInfo, unknown> = useGetFetchData({
    url: process.env.REACT_APP_API_URL_V2 as string,
    query: `tenants/${tenant}/bcms/${boiler[0]?.deviceId}`,
    enabled: devicesInfo.isSuccess && !!boiler?.[0]?.deviceId,
    enableErrorHandler: true,
  });

  return { propertyInfo, bcmInfo, devicesInfo };
};

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import {
  clearCreateAccessRightsTagsError,
  createAccessRightsTagsAction,
  deleteAccessRightsTagsAction,
} from 'reducers/accessRightsTags/actions';
import { Areas } from 'types/roles';
import useAccess from 'utils/hooks/useAccess';
import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { AddTag, TagsTable } from 'components/molecules';
import { errorMapper } from '../utils/errorMapper';
import { AccessRightsTagsWrapper } from './AccessRightsTags.styles';

type AccessRightsTagsType = {
  accessRightsTags: AccessRightsTag[];
  isLoading: boolean;
  errors: { create: { response: { status: number } }; delete: { message: string } };
};

export const AccessRightsTags: React.FC = () => {
  const dispatch = useDispatch();
  const { accessRightsTags, isLoading, errors }: AccessRightsTagsType = useSelector(
    (state: any) => state.accessRightsTags
  );
  const userAccessRightsTags = useSelector((state: any) => state.auth.user.tags);
  const accessLevel = useAccess(Areas.misc);

  const isManager = accessLevel.isManager && !userAccessRightsTags.length;

  const handleCreateAccessRightsTag = (tag) => dispatch(createAccessRightsTagsAction(tag));
  const handleClearCreateAccessRightsTagError = useCallback(
    () => dispatch(clearCreateAccessRightsTagsError()),
    [dispatch]
  );

  const handleDeleteAccessRightsTag = (tagId) => dispatch(deleteAccessRightsTagsAction(tagId));

  const errorMessage = errorMapper(errors.create.response?.status);

  useEffect(
    () => () => {
      handleClearCreateAccessRightsTagError();
    },
    [handleClearCreateAccessRightsTagError]
  );

  return (
    <AccessRightsTagsWrapper>
      <div className="access-rights-tags__top">
        <Typography variant="h5" className="access-rights-tags__title">
          Access Rights Tags
        </Typography>
        {isManager && (
          <AddTag
            handleAddTag={handleCreateAccessRightsTag}
            isLoading={isLoading}
            error={errorMessage}
            clearError={handleClearCreateAccessRightsTagError}
          />
        )}
      </div>
      <TagsTable
        isManager={isManager}
        isLoading={isLoading}
        tags={accessRightsTags}
        handleDeleteAccessRightsTag={handleDeleteAccessRightsTag}
      />
    </AccessRightsTagsWrapper>
  );
};

import { useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import { useGetFetchData, useSelectedTenant } from 'utils/hooks';
import { DeviceLicence, ManagedServiceLicence, PropertyDevice } from 'types';
import { PropertyContextProps } from 'routes/Property/types';
import { devicePackagesTableColumns, managedServiceTableColumns } from '../constants/services';
import {
  transformDeviceTableDataToRows,
  transformServiceTableDataToRows,
} from '../utils/dataTransforms';

type ServicesResponseData = {
  deviceLicences: DeviceLicence[];
  propertyLicences: ManagedServiceLicence[];
};

export const useServicesTableData = () => {
  const { propertyId } = useParams();
  const tenant = useSelectedTenant();
  const { devicesInfo } = useOutletContext<PropertyContextProps>();
  const devices = devicesInfo.data?.results as PropertyDevice[];

  const {
    data: licencesData = {},
    isLoading,
    isSuccess,
    refetch,
  } = useGetFetchData({
    url: process.env.REACT_APP_API_URL_V2 as string,
    query: `tenants/${tenant}/properties/${propertyId}/licences`,
    enableErrorHandler: true,
  });

  const { deviceLicences = [], propertyLicences = [] }: ServicesResponseData = licencesData;

  const devicePackagesTableRows = useMemo(
    () =>
      transformDeviceTableDataToRows({
        deviceLicences,
        devicesData: devices,
      }),
    [deviceLicences, devices]
  );

  const managedServiceTableRows = transformServiceTableDataToRows(propertyLicences);

  const devicePackagesTable = {
    columns: devicePackagesTableColumns,
    rows: devicePackagesTableRows,
  };
  const managedServiceTable = {
    columns: managedServiceTableColumns,
    rows: managedServiceTableRows,
  };

  return {
    devicePackagesTable,
    managedServiceTable,
    isLoading,
    isSuccess,
    refetch,
  };
};

import { useMemo } from 'react';

import { ApiStatuses, FeatureTypeMap, Features, FeaturesAreas } from 'types';
import { useAppSelector } from './useReduxHelpers';

export const useAreaFeatures = <T extends FeaturesAreas>(
  area: T
): {
  features: Record<FeatureTypeMap[T], boolean | undefined>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  status: ApiStatuses;
} => {
  const featureStore = useAppSelector((state) => state.features);
  const featuresStatus = featureStore.statuses.fetchFeatures.status;

  const areaFeatures = featureStore.data?.find(({ name }) => name === area);

  const allAreaFeatures = Object.values(Features[area]);

  const features = useMemo(
    () =>
      allAreaFeatures.reduce(
        (acc, feature) => ({
          ...acc,
          [feature]: areaFeatures?.features.find(({ name }) => name === feature)?.isEnabled,
        }),
        {} as Record<FeatureTypeMap[T], boolean | undefined>
      ),
    [allAreaFeatures, areaFeatures?.features]
  );

  const isLoading = featuresStatus === ApiStatuses.Loading || featuresStatus === ApiStatuses.Init;
  const isSuccess = featuresStatus === ApiStatuses.Success;
  const isError = featuresStatus === ApiStatuses.Failed;

  return {
    features,
    isLoading,
    isSuccess,
    isError,
    status: featuresStatus,
  };
};

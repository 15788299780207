import React, { useState, useRef } from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import Input from 'components/elements/Input';
import styled from 'styled-components';
import useClickAway from 'utils/hooks/useClickAway';

import TableHead from './TableHead';
import TableLoading from './TableLoading';
import TableRow from './TableRow';

const Wrapper = styled.div`
  margin: 1rem 0 2rem;
  height: 60vh;
  overflow: auto;
  position: relative;
  overflow-x: hidden;
`;

const Table = styled.table`
  font-size: 1.4rem;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  & thead tr {
    & th {
      min-width: 10rem;
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.white};
      position: sticky;
      top: 0;
      font-weight: 500;
      text-align: left;
      transition: background-color 0.3s ease-in-out;
      z-index: 1;

      &:hover {
        &:not(:first-of-type) {
          background-color: ${({ theme }) => theme.primaryLight};
        }
      }

      &:first-of-type {
        z-index: 1;
        width: 6rem;
        padding: 0 0.5rem;
      }

      &:last-of-type {
        z-index: 1;
        width: 4rem;
        padding: 0 0.5rem;
      }
    }
  }

  & tbody tr {
    transition: all 0.4s ease-in-out;
    cursor: pointer;

    & td {
      padding: 0.5rem;
      font-size: 1.2rem;
      position: relative;

      &:first-of-type {
        width: 6rem;
        padding: 0 3rem 0 1rem;
      }
    }
  }

  & tbody tr:nth-child(even) {
    background-color: ${({ theme }) => theme.offWhite};
  }

  & tbody tr:hover {
    background-color: #ececf7;

    & td {
      &:last-of-type {
        opacity: 1;
      }
    }
  }
`;

const SelectWrapper = styled.div`
  display: flex;
`;

const DropIcon = styled.span`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.white};
  margin-top: 0.3rem;

  opacity: 0.6;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  & svg {
    fill: currentColor;
    height: 100%;
    width: 100%;
  }
`;

const InnerWrapper = styled.div`
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.primaryLight};
  }
`;

const InnerWrapperLeft = styled(InnerWrapper)`
  padding: 0.5rem;
`;

const InnerWrapperRight = styled(InnerWrapper)`
  padding: 0.2rem;
  position: relative;
`;

const Options = styled.div`
  position: absolute;
  bottom: -8rem;
  left: 0;
  width: 15rem;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.grey};
  z-index: 12;

  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);

  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  & ul {
    & li {
      padding: 0.55rem 1rem;
      font-weight: 400;
      font-size: 1.4rem;

      transition: all 0.3s ease-in-out;
      &:hover {
        color: ${({ theme }) => theme.white};
        background: ${({ theme }) => theme.primary};
        cursor: pointer;
      }
    }
  }
`;

const ListingTable = ({
  viewId,
  data,
  isFetching,
  columns,
  handleColumnSort,
  sortProp,
  toggleSelectedRow,
  selectedRows,
  selectAllRows,
  selectPageRows,
  clearAllRows,
  pageSelected,
  clearPageRows,
  rowAction,
  refreshView,
  showActions,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const closeDropdown = () => setIsOpen(false);

  useClickAway(ref, closeDropdown);

  const handleSort = (sortKey) => {
    if (sortProp === sortKey) {
      return 'asc';
    }
    if (sortProp === `-${sortKey}`) {
      return 'desc';
    }

    return null;
  };

  return (
    <Wrapper>
      <Table ref={tableRef}>
        <thead>
          <tr>
            <th>
              {showActions && (
                <SelectWrapper>
                  <InnerWrapperLeft>
                    <Input
                      type="check"
                      inputData={{
                        checked: pageSelected,
                        onChange: () => (pageSelected ? clearPageRows() : selectPageRows()),
                        paddingLeft: '25px',
                      }}
                    />
                  </InnerWrapperLeft>
                  <InnerWrapperRight ref={ref} onClick={() => setIsOpen(!isOpen)}>
                    <DropIcon>
                      <SvgIcon type="caret-down" />
                    </DropIcon>
                    <Options className={isOpen ? 'open' : ''}>
                      <ul>
                        <li role="presentation" onClick={selectPageRows}>
                          Select Page
                        </li>
                        <li role="presentation" onClick={selectAllRows}>
                          Select All
                        </li>
                        <li role="presentation" onClick={clearAllRows}>
                          Clear Selection
                        </li>
                      </ul>
                    </Options>
                  </InnerWrapperRight>
                </SelectWrapper>
              )}
            </th>

            {columns.map((c, i) => (
              <TableHead
                key={viewId + i}
                sortable={c.sortable}
                handleSort={() => handleColumnSort(c.key, c.sortable)}
                sort={handleSort(c.key)}
              >
                {c.label}
              </TableHead>
            ))}

            <th />
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <TableLoading colspan={columns.length + 2} />
          ) : (
            data.map((dr) => (
              <TableRow
                key={dr.deviceId}
                tableRef={tableRef}
                data={dr}
                columns={columns}
                selectedRows={selectedRows}
                toggleSelectedRow={toggleSelectedRow}
                rowAction={rowAction}
                refreshView={refreshView}
                showActions={showActions}
              />
            ))
          )}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default ListingTable;

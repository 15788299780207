import React, { useState } from 'react';

import Loading from 'components/Loading';
import styled from 'styled-components';
import { deviceTypeToArea } from 'utils/deviceUtils';
import useAccess from 'utils/hooks/useAccess';
import useIsLoading from 'utils/hooks/useIsLoading';

import { Device } from 'types/DeviceTypes/Device';
import { BcmConnectDetails } from 'types/BoilerTypes/BcmConnectDetails';
import { EmeredDevice } from 'types/EmeredTypes';
import { NetworkingDevice } from 'types/NetworkingDevice';
import { DetailsActions } from '../DeviceActions';
import Header from './Header';

const OuterWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-around;
  @media ${(props) => props.theme.laptop} {
    flex-direction: column;
  }
`;

const TabsWrapperLeft = styled.div`
  position: relative;
  margin-right: 3rem;
  flex: 2;
  width: 70%;
  @media ${(props) => props.theme.laptopL} {
    margin-right: 2rem;
  }
  @media ${(props) => props.theme.laptop} {
    margin-right: 0;
    margin-bottom: 5rem;
    width: 100%;
  }
`;

const Tabs = styled.ul`
  ${(props) => props.theme.tabs};
`;

const ContentLeft = styled.div`
  padding: 1rem 2rem;
  ${(props) => props.theme.wrapper};
  position: relative;
  & h5 {
    font-weight: 600;
    margin-bottom: 1rem;
  }
  & section {
    padding: 1rem 0;
  }
  & div {
    font-size: ${(props) => props.theme.textSmall};
  }
`;

type DeviceDetailsProps = {
  device: Device | EmeredDevice | NetworkingDevice;
  details: BcmConnectDetails | undefined;
  tabs: any[]; // not typed as tabs planed to make as routes
  triggerUpdateDevice: () => void;
};

const DeviceDetails: React.FC<DeviceDetailsProps> = ({
  device,
  details,
  tabs,
  triggerUpdateDevice,
}) => {
  const isLoading = useIsLoading([
    'FETCH_EMEREDDETAILS',
    'FETCH_BOILERDETAILS',
    'FETCH_NETWORKDEVICE',
  ]);
  const auth = useAccess(device ? deviceTypeToArea(device.deviceType) : null);
  const { isEdit } = auth;
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <OuterWrapper>
      {isLoading && <Loading />}
      {device && !isLoading && (
        <>
          <Header device={device}>{isEdit && <DetailsActions device={device} />}</Header>
          <Content>
            <TabsWrapperLeft>
              <Tabs>
                {tabs.map((t, i) =>
                  t.access(auth) ? (
                    <li
                      key={i}
                      role="presentation"
                      className={activeTab.tab === t.tab ? 'active' : ''}
                      onClick={() => setActiveTab(t)}
                    >
                      {t.display}
                    </li>
                  ) : null
                )}
              </Tabs>
              <ContentLeft>
                <activeTab.component
                  triggerUpdateDevice={triggerUpdateDevice}
                  device={device}
                  data={details}
                />
              </ContentLeft>
            </TabsWrapperLeft>
          </Content>
        </>
      )}
    </OuterWrapper>
  );
};

export default DeviceDetails;

import React from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import styled from 'styled-components';

const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 1rem;
  margin-left: 1rem;

  & > span {
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: ${({ theme }) => theme.white};

    opacity: 0.4;

    & > svg {
      height: 100%;
      width: 100%;
      fill: currentColor;
    }

    &.active {
      opacity: 1;
    }
  }
`;

interface SortProps {
  sort: 'asc' | 'desc' | null;
}

const SortIcon = ({ sort }: SortProps) => (
  <SortWrapper>
    <span className={sort === 'asc' ? 'active' : ''}>
      <SvgIcon type="sortup" />
    </span>
    <span className={sort === 'desc' ? 'active' : ''}>
      <SvgIcon type="sortdown" />
    </span>
  </SortWrapper>
);

export default SortIcon;

import React from 'react';

import { handleApiReq } from 'utils/ApiRequestHelpers';

import ErrorPage from '../../scenes/ErrorPage';

interface IProps {
  children?: React.ReactNode;
}

interface IState {
  errorOccurred?: boolean;
}

class ErrorHandler extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { errorOccurred: false };
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true });
    const errObj = {
      message: error.message,
      stack: error.stack,
      componentStack: info.componentStack,
    };

    if (process.env.REACT_APP_ENV !== 'dev') handleApiReq('LogError', errObj);
  }

  render() {
    return this.state.errorOccurred ? <ErrorPage /> : this.props.children;
  }
}

export default ErrorHandler;

import React from 'react';

import BuildingDetails from 'components/BuildingManagement/BuildingDetails';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectBuilding } from 'reducers/buildingInfo/selectors';
import styled from 'styled-components';

interface MatchParams {
  buildingName: string;
}

const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
  width: 70%;
  margin: 0 auto;
  padding: 4rem 6rem;
`;

const BuildingDetailsPage = () => {
  const params = useParams<Readonly<MatchParams>>();
  const building = useSelector(
    (state: any) => state.buildingInfo.buildings && selectBuilding(params.buildingName)(state)
  );

  return <Wrapper>{building && <BuildingDetails building={building} />}</Wrapper>;
};

export default BuildingDetailsPage;

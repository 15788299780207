import { createThunkEffect } from 'utils/ReduxUtils';

const FETCH_NETWORKDEVICE = 'FETCH_NETWORKDEVICE';
const REFRESH_NETWORKDEVICE = 'REFRESH_NETWORKDEVICE';

export const networkActionTypes = {
  FETCH_NETWORKDEVICE,
  REFRESH_NETWORKDEVICE,
};

interface NetworkDeviceReq {
  deviceId: string;
  startDate: Date;
}

export const fetchNetworkDevice = (req: NetworkDeviceReq) => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_NETWORKDEVICE, 'GetNetworkingDevice', req);

export const refreshNetworkDevice = (req: NetworkDeviceReq) => async (dispatch) =>
  await createThunkEffect(dispatch, REFRESH_NETWORKDEVICE, 'GetNetworkingDevice', req);

import React from 'react';
import { gridClasses } from '@mui/x-data-grid-pro';

import { useNavigate } from 'utils/hooks';
import { StyledDataGrid } from 'styles/StyledDataGrid';
import { TabContent, TabTitle } from 'styles/commonStyles';
import { DeviceTypes } from 'types/DeviceTypes/Device';
import { parseSerialNumber } from 'utils';
import { PropertyDevicesWrapper } from './PropertyDevices.styles';
import { useDevicesTableData } from './hooks';

export const PropertyDevices: React.FC = () => {
  const { columns, rows, isLoading } = useDevicesTableData();
  const { goTo } = useNavigate();

  const onRowClick = (data) => {
    const { product } = parseSerialNumber(data.row.serialNumber);
    const isBcm = product === DeviceTypes.Boiler || product === DeviceTypes.BcmConnect;

    if (isBcm) {
      goTo(`Boilers/${data.id}`);
    }
  };

  const preventSelectingOnCell = ({ field }: { field: string }, event) => {
    if (field === 'actions') {
      event.stopPropagation();
    }
  };

  return (
    <PropertyDevicesWrapper>
      <TabTitle>Devices</TabTitle>
      <TabContent>
        <StyledDataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          disableColumnFilter
          disableColumnMenu
          hideFooter
          onRowClick={onRowClick}
          onCellClick={preventSelectingOnCell}
          disableDensitySelector
          density="standard"
          disableColumnSelector
          columnHeaderHeight={50}
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </TabContent>
    </PropertyDevicesWrapper>
  );
};

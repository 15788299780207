import { useState, useEffect, useMemo } from 'react';

const getNumOfSelected = (map) => {
  let count = 0;
  for (const value of map.values()) {
    if (value) count++;
  }

  return count;
};

const useRowSelect = (data, pageData) => {
  const pageRowsFiltered = useMemo(
    () => pageData.filter((row) => !row.userHasReadOnlyAccess),
    [pageData]
  );
  const [rows, setRows] = useState(data);
  const [pageRows, setPageRows] = useState(pageRowsFiltered);

  const [selectedRows, setSelectedRows] = useState(new Map());
  const [allSelected, setAllSelected] = useState(false);
  const [pageSelected, setPageSelected] = useState(false);

  const [selected, setSelected] = useState(0);

  const updateSelectedRow = (id, value) => setSelectedRows(new Map(selectedRows.set(id, value)));
  const toggleSelectedRow = (deviceId) => updateSelectedRow(deviceId, !selectedRows.get(deviceId));

  const clearAllRows = () => setSelectedRows(new Map());
  const selectAllRows = () =>
    setSelectedRows(
      new Map(rows.filter((row) => !row.userHasReadOnlyAccess).map((d) => [d.deviceId, true]))
    );
  const selectPageRows = () => {
    const newMap = new Map(selectedRows);
    pageRows.forEach((r) => newMap.set(r.deviceId, true));
    setSelectedRows(newMap);
  };
  const clearPageRows = () => {
    const newMap = new Map(selectedRows);
    pageRows.forEach((r) => newMap.set(r.deviceId, false));
    setSelectedRows(newMap);
  };

  useEffect(() => {
    setRows(data);
    clearAllRows();
    setAllSelected(false);
  }, [data]);

  useEffect(() => {
    const isRowsTrue = (rows) => !!rows.length && rows.every((r) => selectedRows.get(r.deviceId));

    setPageRows(pageRowsFiltered);
    setPageSelected(isRowsTrue(pageRowsFiltered));
    setSelected(getNumOfSelected(selectedRows));
    setAllSelected(isRowsTrue(selectedRows));
  }, [pageRowsFiltered, selectedRows]);

  return {
    selectedRowInfo: {
      selectedRows,
      selected,
      allSelected,
      pageSelected,
    },
    selectedRowFunctions: {
      toggleSelectedRow,
      selectAllRows,
      selectPageRows,
      clearAllRows,
      clearPageRows,
    },
  };
};

export default useRowSelect;

import styled from 'styled-components';

export const ReportWrapper = styled.div`
  & .report {
    &__heading {
      text-align: left;
    }
    &__board {
      ${(props) => props.theme.wrapper};
      width: 100%;
      overflow-y: auto;

      &-area {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-gap: 20px 15px;
        padding: 24px 32px;

        @media (min-width: 967px) {
          grid-template-columns: repeat(2, 300px);
        }

        @media (min-width: 1024px) {
          padding: 48px 96px;
          grid-template-columns: repeat(2, 300px);
        }

        @media (min-width: 1280px) {
          grid-template-columns: repeat(2, 400px);
        }
      }
    }
  }
`;

import { handleReduxApiReq } from '../../utils/ApiRequestHelpers';
import { mapDeviceTypesTo2Let } from '../../utils/deviceUtils';

const FETCH_FORMS_REQUEST = 'FETCH_FORMS_REQUEST';
const FETCH_FORMS_ERROR = 'FETCH_FORMS_ERROR';
const FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS';

export const formTypes = {
  FETCH_FORMS_REQUEST,
  FETCH_FORMS_ERROR,
  FETCH_FORMS_SUCCESS,
};

const fetchFormRequest = () => ({
  type: FETCH_FORMS_REQUEST,
});

const fetchFormSuccess = (forms) => ({
  type: FETCH_FORMS_SUCCESS,
  payload: { forms },
});

const fetchFormError = (error) => ({
  type: FETCH_FORMS_ERROR,
  payload: { error },
});

export const fetchFormAction = async (dispatch, deviceType, deviceId) => {
  dispatch(fetchFormRequest());
  try {
    const reqBody = {
      deviceType: mapDeviceTypesTo2Let(deviceType),
      deviceId: deviceId,
    };
    const data = await handleReduxApiReq('GetAllDeviceForms', reqBody, 'POST');
    dispatch(
      fetchFormSuccess({
        data,
        deviceId,
      })
    );
  } catch (e) {
    dispatch(fetchFormError(e));
  }
};

export const fetchForms = (deviceId, deviceType) => async (dispatch) => {
  await fetchFormAction(dispatch, deviceType, deviceId);
};

const initialState = {
  forms: null,
};

export const formsReducer = (state = initialState, action) => {
  switch (action.type) {
    case formTypes.FETCH_FORMS_SUCCESS:
      return {
        forms: action.payload.forms,
      };
    default:
      return state;
  }
};

import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { ApiErrorsType } from 'types';

import { getToken } from 'utils/contexts/AuthProvider';
import { useHandleApiErrors } from './useHandleApiErrors';

type UseGetFetchDataProps = {
  url: string;
  query: string;
  onSuccess?: (data) => void;
  onError?: (error: ApiErrorsType) => void;
  retry?: number | boolean;
  refetchOnWindowFocus?: boolean | 'always';
  enabled?: boolean;
  staleTime?: number;
  dataTransform?: (data) => unknown;
  axiosConfig?: AxiosRequestConfig<unknown>;
  enableErrorHandler?: boolean;
};

export const useGetFetchData = ({
  url,
  query,
  onSuccess,
  onError,
  retry,
  refetchOnWindowFocus = false,
  enabled,
  staleTime,
  dataTransform,
  axiosConfig = {},
  enableErrorHandler,
}: UseGetFetchDataProps) => {
  const handleError = useHandleApiErrors();

  return useQuery({
    queryKey: query,
    queryFn: async ({ queryKey }) => {
      const token = await getToken();

      const res = await axios.get(`${url}${queryKey[0]}`, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        ...axiosConfig,
      });

      if (dataTransform) {
        return dataTransform(res.data);
      }

      return res.data;
    },
    refetchOnWindowFocus,
    onError: (e: ApiErrorsType) => {
      if (onError) {
        onError(e);
      }

      if (enableErrorHandler) {
        handleError(e);
      }
    },
    ...(typeof retry !== 'undefined' && { retry }),
    ...(typeof enabled !== 'undefined' && { enabled }),
    ...(typeof staleTime !== 'undefined' && { staleTime }),
    ...(!!onSuccess && { onSuccess }),
  });
};

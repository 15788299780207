import { BcmInfo } from 'types/BoilerTypes/BcmConnectDetails';
import { ProductName, Property } from 'types';
import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { parseSerialNumber, propertyAddressToString } from 'utils';
import Tag from 'types/Tags/Tag';

type TransformBcmsRowsProps = {
  bcms: BcmInfo[];
  properties: Property[];
  tagIds: Tag[];
  accessRightsTags: AccessRightsTag[];
  productNames: ProductName[];
};

export const transformBcmsRows = ({
  bcms,
  properties,
  tagIds,
  accessRightsTags,
  productNames,
}: TransformBcmsRowsProps) =>
  bcms.map((bcm: BcmInfo) => {
    const heatPumpProperty = (properties.find(
      (property) => bcm.propertyId === property.propertyId
    ) || {}) as Property;

    const propertyLocation = heatPumpProperty.locations?.find(
      (loc) => loc.id === bcm.propertyLocationId
    );

    const propertyAddress = propertyAddressToString({ property: heatPumpProperty });

    const propertyValues = {
      address: propertyAddress,
      postcode: heatPumpProperty.postcode || '',
      propertyReference: heatPumpProperty.propertyReference || '',
      location: propertyLocation?.name,
      locationDetails: propertyLocation?.details,
    };

    const accessRightTag = bcm.accessRightsTagId
      ? accessRightsTags?.find((tag) => tag.id === bcm.accessRightsTagId)?.displayName
      : 'No Access Rights Tag';

    const tags = bcm.tagIds?.map((id) => tagIds?.find((tag) => id === tag.id)?.name);

    const { model, product } = parseSerialNumber(bcm.serialNumber);
    const productName = productNames.find((p) => p.model === model && p.product === product)?.name;

    return {
      ...propertyValues,
      id: bcm.deviceId,
      serialNumber: bcm.serialNumber,
      status: bcm.status,
      manufacturer: bcm.boilerManufacturer,
      model: bcm.model,
      boilerGC: bcm.boilerGc,
      firmwareVersion: bcm.firmwareVersion,
      deviceId: bcm.deviceId,
      product: bcm.product,
      faultCode: bcm.faultCode,
      productName,
      tags,
      accessRightTag,
    };
  });

import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { updateDeviceLocation } from 'reducers/device/deviceActions';
import { Areas } from 'types/roles';
import { isInventory } from 'utils/deviceUtils';
import useAccess from 'utils/hooks/useAccess';
import { isRequired } from 'utils/inputValidation';
import { NetworkingDevice } from 'types/NetworkingDevice';
import { DeviceAccessRightsTagWrapperApiV1 } from 'components/molecules';
import DeviceDetailsEditInput from '../DetailsEdit';

const ContentItem = styled.div`
  display: inline-block;
  padding: 0.5rem;
  & span {
    color: ${(props) => props.theme.spanBlue};
    padding-right: 0.5rem;
  }
`;

const DeviceInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 12rem;

  @media ${(props) => props.theme.laptopL} {
    height: 15rem;
  }

  @media ${(props) => props.theme.mobileL} {
    height: auto;
  }

  & > Sdiv {
    margin-bottom: 1rem;
  }
`;

const StatusInfo = styled.div`
  display: flex;
  & div {
    width: 37%;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  & span:first-child {
    color: ${(props) => props.theme.spanBlue};
  }
`;

type NetworkingDeviceDetailsProps = {
  device: NetworkingDevice;
  triggerUpdateDevice: () => void;
};

const NetworkingDeviceDetails: React.FC<NetworkingDeviceDetailsProps> = ({
  device,
  triggerUpdateDevice,
}) => {
  const dispatch = useDispatch();
  const { isEdit } = useAccess(Areas.networking);

  const locationVal = [{ fn: isRequired, msg: 'Location can not be empty' }];
  const handleLocationUpdate = async (value) => {
    await dispatch(updateDeviceLocation(device.deviceId, value));
  };
  const canEdit = isEdit && !isInventory(device) && !device.userHasReadOnlyAccess;

  return (
    <>
      {device && (
        <>
          <section>
            <h5>Device Statuses</h5>
            <StatusInfo>
              <ContentItem>
                <span>Device Status: </span> {device.deviceStatus}
              </ContentItem>
              <ContentItem>
                <span>Connection Status: </span> {device.connectionStatus}
              </ContentItem>
            </StatusInfo>
          </section>
          <section>
            <h5>Device Infomation</h5>
            <DeviceInfo>
              <ContentItem>
                <span>Serial Number:</span>
                {device.serialNumber}
              </ContentItem>
              <ContentItem>
                <span>Building:</span>
                {device.buildingName}
              </ContentItem>
              <ContentItem>
                <span>Floor:</span>
                {device.floorName}
              </ContentItem>
              <InputWrapper>
                <span>Location:</span>
                {canEdit ? (
                  <DeviceDetailsEditInput
                    type="text"
                    value={device.room}
                    action={handleLocationUpdate}
                    actionType="UPDATE_LOCATION"
                    validators={locationVal}
                    width="20rem"
                  />
                ) : (
                  device.room
                )}
              </InputWrapper>
            </DeviceInfo>
          </section>
          <section>
            <DeviceAccessRightsTagWrapperApiV1 device={device} onSuccess={triggerUpdateDevice} />
          </section>
        </>
      )}
    </>
  );
};

export default NetworkingDeviceDetails;

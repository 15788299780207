import React, { useState } from 'react';

import styled from 'styled-components';

import LegendMarker from './LegendMarker';

const LegendWrapper = styled.div`
  ${(props) => props.theme.wrapper};
  padding: 1rem 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 7;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  & ul {
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.theme.textSmall};
    transition: all 0.3s ease-in-out;
    height: 24rem;

    & li {
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease-in-out;
      transform: translateY(0);
    }
  }

  & ul.hidden {
    height: 0;

    & li {
      opacity: 0;
      visibility: hidden;

      &:nth-of-type(1) {
        transform: translateY(-30px);
      }
      &:nth-of-type(2) {
        transform: translateY(-60px);
      }
      &:nth-of-type(3) {
        transform: translateY(-90px);
      }
      &:nth-of-type(4) {
        transform: translateY(-120px);
      }
      &:nth-of-type(5) {
        transform: translateY(-150px);
      }
      &:nth-of-type(6) {
        transform: translateY(-180px);
      }
    }
  }
`;

const LegendHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & span {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;

    & svg {
      width: 100%;
      height: 100%;
      fill: ${(props) => props.theme.blue};
    }
  }
`;

const MapLegend = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <LegendWrapper onClick={() => setIsOpen(!isOpen)}>
      <LegendHeader>
        <h4>Legend</h4>
        <span>
          <svg>
            <use href="images/icons.svg#icon-angle-down" />
          </svg>
        </span>
      </LegendHeader>
      <ul className={!isOpen ? 'hidden' : ''}>
        <li>
          <LegendMarker deviceType="EM" groupStatus="inGroup" /> Already In Group
        </li>
        <li>
          <LegendMarker deviceType="EM" groupStatus="addedToGroup" /> To Be Added
        </li>
        <li>
          <LegendMarker deviceType="EM" groupStatus="removedFromGroup" /> To Be Removed
        </li>
        <li>
          <LegendMarker deviceType="EM" groupStatus="anotherGroup" /> In Another Group
        </li>
        <li>
          <LegendMarker deviceType="EM" groupStatus="noGroup" /> No Group
        </li>
        <li>
          <LegendMarker deviceType="EM" groupStatus="conflictingGroup" /> In Conflicting Group
        </li>
      </ul>
    </LegendWrapper>
  );
};

export default MapLegend;

import styled from 'styled-components';

export const NumericDisplayContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MetricLabelContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const MetricLabelContainer = styled.div`
  text-align: center;
`;

export const MetricContainer = styled.span`
  color: black;
  font-size: 2em;
  font-weight: bold;
`;

export const LabelDiv = styled.div`
  font-weight: bold;
  text-align: center;
`;

export const DateTimeDiv = styled.div`
  align-self: flex-end;
`;

import { TabItem } from 'components/atoms';
import React from 'react';
import { Outlet } from 'react-router-dom';

import styled from 'styled-components';
import { settingsLinks } from './constants/settings';

const OuterWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TabsWrapper = styled.ul`
  ${(props) => props.theme.tabs};
`;

const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
  padding: 0.5rem 2rem;
  position: relative;
  height: calc(100% - 45px);

  & h5 {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  & div {
    font-size: ${(props) => props.theme.textSmall};
  }
`;

export const SettingsContainer = ({ settings, tenant, refreshTenantInfo, refreshTagsData }) => (
  <OuterWrapper>
    <TabsWrapper>
      {settingsLinks.map(({ to, label }, linkIndex) => (
        <TabItem key={linkIndex.toString()} to={to} defaultLocation="Settings" label={label} />
      ))}
    </TabsWrapper>
    <Wrapper>
      <Outlet context={{ settings, tenant, refreshTenantInfo, refreshTagsData }} />
    </Wrapper>
  </OuterWrapper>
);

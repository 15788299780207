import React, { useState, useEffect, useCallback } from 'react';

import { getDataStore } from 'DB';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, Navigate, Routes, Route } from 'react-router-dom';
import { setSelectedTenant } from 'reducers/auth';
import { fetchBuildingInfo, clearBuildings } from 'reducers/buildingInfo/actions';
import { fetchFields, clearFields } from 'reducers/fields';
import { selectIsLoading } from 'reducers/loading/selectors';
import { fetchTags, clearTags } from 'reducers/tags/tagActions';
import { fetchTenantInfo, clearTenant, fetchTenantDashboardData } from 'reducers/tenantInfo';
import { fetchTenants } from 'reducers/tenants';
import { clearUsers, fetchUsers } from 'reducers/users';
import { clearFeatures, fetchFeatures } from 'reducers/features';
import { clearViewData } from 'reducers/viewData';
import {
  clearAccessRightsTags,
  fetchAccessRightsTagsAction,
} from 'reducers/accessRightsTags/actions';
import {
  fetchViewDefinitions,
  clearViewDefinitions,
  clearPageInfo,
  clearCurrentViewContext,
} from 'reducers/viewDefinitions';
import styled, { ThemeProvider } from 'styled-components';
import { isMobile } from 'utils/helpers';
import useAuth from 'utils/hooks/useAuth';

import { Sidebar } from 'components/molecules';
import { clearCapabilities, fetchCapabilities } from 'reducers/capabilities';
import { useEffectOnce, useReduxApiData } from 'utils/hooks';
import { fetchProductNames } from 'reducers/productNames';
import { ApiActionNames } from 'reducers/types';
import routes from '../../routes';
import { styleVars } from '../../styles/variables';
import { AccessLevel, Areas } from '../../types/roles';
import { UserProfile } from '../../types/userProfile';
import Loading from '../Loading';
import ErrorHandler from './Error';
import Header from './Header';
import Login from './Login';
import TenantSelect from './TenantSelect/TenantSelect';
import ToastContainer from './ToastContainer';
import { usePermissions } from './hooks/usePermissions';

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const PageContainer = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
  background: ${(props) => props.theme.backgroundColor};
`;

const PageContent = styled.div`
  padding: 2rem 4rem;
  flex-grow: 1;
  position: relative;
  transition: all 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  & h1 {
    margin-bottom: 3rem;
  }

  @media ${(props) => props.theme.laptopL} {
    padding: 2rem 2rem;
  }
`;

const renderRoutes = (routes, parentPath = '') =>
  routes.map((route, index) => {
    const fullPath = `${parentPath}${route.path}`;

    if (route.routes || route.nested) {
      const nestedRoutes = route.nested ? renderRoutes(route.nested, fullPath).flat() : null;

      return [
        <Route
          key={index}
          path={`/:tenant${fullPath}`}
          element={
            <ErrorHandler>
              <route.component />
            </ErrorHandler>
          }
        >
          {nestedRoutes}
        </Route>,
        ...(route.routes ? renderRoutes(route.routes, parentPath) : []),
      ];
    } else {
      return (
        <Route
          key={index}
          path={`/:tenant${fullPath}`}
          element={
            <ErrorHandler>
              <route.component />
            </ErrorHandler>
          }
        />
      );
    }
  });

type Display = 'loading' | 'login' | 'select' | 'app';

const Layout: React.FC = () => {
  const { login, isAuthenticated, user, isLoading, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isFetching = useSelector((state: any) =>
    selectIsLoading(['FETCH_VIEWDEFINITIONS', 'FETCH_TAGS', 'FETCH_FIELDS'])(state)
  );

  const { isLoading: areUsersLoading } = useReduxApiData(
    (state) => state.users,
    ApiActionNames.FetchUsers
  );

  const {
    isUserLimited,
    features,
    capabilities,
    isLoading: arePermissionsLoading,
  } = usePermissions();

  const isLoadingScreen = isLoading || isFetching || arePermissionsLoading || areUsersLoading;

  const [display, setDisplay] = useState<Display>('loading');

  const getInitialShowSidebar = useCallback(() => {
    if (isMobile()) {
      return false;
    }

    const saved = localStorage.getItem('showSidebar');
    if (saved === null) {
      return true;
    }

    const sidebarOpen = JSON.parse(saved);

    return sidebarOpen;
  }, []);

  const [showSidebar, setShowSidebar] = useState(getInitialShowSidebar());
  let userRoutes;

  useEffectOnce(() => {
    getDataStore();
  });

  useEffect(() => {
    if (isLoadingScreen) {
      setDisplay('loading');
    } else if (!isAuthenticated && !isLoading) {
      setDisplay('login');
    } else if (isAuthenticated && !isLoading && !user?.tenantSelected) {
      setDisplay('select');
    } else if (isAuthenticated && !isLoading && user?.tenantSelected) {
      setDisplay('app');
    }
  }, [isAuthenticated, isLoading, isLoadingScreen, user?.tenantSelected]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchProductNames());
      setShowSidebar(getInitialShowSidebar());
    }
  }, [isAuthenticated, getInitialShowSidebar, dispatch]);

  useEffect(() => {
    if (isAuthenticated && user && user.tenantSelected) {
      dispatch(fetchTenantDashboardData());
      dispatch(fetchUsers());
      dispatch(fetchFeatures());
      dispatch(fetchCapabilities());
      dispatch(fetchTenantInfo());
      dispatch(fetchTags());
      dispatch(fetchAccessRightsTagsAction());
      dispatch(fetchFields());
      dispatch(fetchViewDefinitions());
      dispatch(fetchTenants());
      dispatch(fetchBuildingInfo());
      dispatch(clearPageInfo());
    }
  }, [isAuthenticated, dispatch, user]);

  const handleTenantSelect = useCallback(
    (tenantName, fromSelect) => {
      const userProfile = {
        ...user,
        tenantSelected: true,
        tenant: tenantName,
        tags: user?.tenants.find((t) => t.name === tenantName)?.tags,
        role: user?.tenants.find((t) => t.name === tenantName)?.role,
      };
      dispatch(setSelectedTenant(new UserProfile(userProfile)));
      if (fromSelect) navigate(`/${tenantName + location.pathname}`);
    },
    [dispatch, location.pathname, navigate, user]
  );

  if (isAuthenticated && user) {
    if (!user.tenantSelected) {
      const pathParams = location.pathname.split('/');
      const tenantNames = user.tenants.map((t) => t.name);
      if (pathParams.length > 2 && tenantNames.includes(pathParams[1])) {
        handleTenantSelect(pathParams[1], false);
      }
    }

    if (user?.hasAccess(AccessLevel.Dev, Areas.all)) {
      userRoutes = routes.filter((route) => {
        const showFeatured = route?.feature ? features[route.feature] : true;
        const showCapability = route?.capability ? capabilities[route.capability] : true;

        return showFeatured && showCapability;
      });
    } else {
      userRoutes = routes.filter((route) => {
        const showFeatured = route?.feature ? features[route.feature] : true;
        const showCapability = route?.capability ? capabilities[route.capability] : true;

        return (
          user.hasArea(route.area) &&
          !(isUserLimited && route.limitedAccessRoute) &&
          showFeatured &&
          showCapability
        );
      });
    }
  }

  const toggleSidebar = () => {
    if (!isMobile()) {
      localStorage.setItem('showSidebar', JSON.stringify(!showSidebar));
    }
    setShowSidebar(!showSidebar);
  };

  const handleChangeTenant = () => {
    dispatch(clearTags());
    dispatch(clearAccessRightsTags());
    dispatch(clearBuildings());
    dispatch(clearUsers());
    dispatch(clearFeatures());
    dispatch(clearCapabilities());
    dispatch(clearFields());
    dispatch(clearViewDefinitions());
    dispatch(clearTenant());
    dispatch(clearCurrentViewContext());
    dispatch(clearViewData());
    dispatch(
      setSelectedTenant(
        new UserProfile({
          ...user,
          tenantSelected: false,
          tenant: '',
        })
      )
    );
    location.pathname = '';
    navigate('/');
  };

  return (
    <>
      <ThemeProvider theme={styleVars}>
        <MainContainer>
          <Header
            handleLogout={logout}
            loggedIn={isAuthenticated}
            handleMenuClick={toggleSidebar}
            isOpen={showSidebar}
            profile={user}
            handleChangeTenant={handleChangeTenant}
          />
          <ToastContainer />
          {display === 'loading' && <Loading />}
          {display === 'login' && <Login login={login} />}
          {display === 'select' && (
            <TenantSelect tenants={user?.tenants} handleTenantSelect={handleTenantSelect} />
          )}
          {display === 'app' && user?.selectedTenant && (
            <PageContainer>
              {user.role && (
                <Sidebar
                  routes={userRoutes}
                  open={showSidebar}
                  selectedTenant={user.selectedTenant}
                  toggleSidebar={toggleSidebar}
                  handleChangeTenant={handleChangeTenant}
                  handleLogout={logout}
                />
              )}
              <PageContent>
                <Routes>
                  <Route
                    path={`/${user.selectedTenant}`}
                    element={<Navigate to={`/${user.selectedTenant}/Dashboard`} />}
                  />
                  <Route path="/" element={<Navigate to={`/${user.selectedTenant}/Dashboard`} />} />
                  {renderRoutes(userRoutes).flat()}
                </Routes>
              </PageContent>
            </PageContainer>
          )}
        </MainContainer>
      </ThemeProvider>
    </>
  );
};

export default Layout;

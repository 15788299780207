import moment, { Moment } from 'moment';

import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';

export const shortcutsItems: PickersShortcutsItem<DateRange<Moment>>[] = [
  {
    label: 'Last 7 days',
    getValue: () => {
      const today = moment().utc();

      return [today.clone().subtract(7, 'day'), today.endOf('day')];
    },
  },
  {
    label: 'So far this month',
    getValue: () => {
      const today = moment().utc();

      return [today.clone().startOf('month'), today.endOf('day')];
    },
  },
  {
    label: 'Previous Calendar Month',
    getValue: () => {
      const today = moment().utc();
      const startOfLastMonth = today.clone().subtract(1, 'month').startOf('month');
      const endOfLastMonth = startOfLastMonth.clone().endOf('month');

      return [startOfLastMonth, endOfLastMonth.endOf('day')];
    },
  },
  {
    label: 'Last 5 weeks',
    getValue: () => {
      const today = moment().utc();

      return [today.clone().subtract(5, 'week'), today.endOf('day')];
    },
  },
];

import React, { useState, useEffect, useRef, RefObject } from 'react';
import styled from 'styled-components';

import SvgIcon from 'components/atoms/SvgIcon';
import { handleApiReq } from 'utils/ApiRequestHelpers';
import { deviceTypeToArea } from 'utils/deviceUtils';
import useAccess from 'utils/hooks/useAccess';
import { classNames } from 'utils/styleHelpers';
import { DevicePartial } from 'types/DevicePartial';

import { ActionType, deviceActions } from '../actions';
import { ActionsList } from '../ActionsList';
import DeviceActionSelector from '../DeviceActionSelector';

const IconWrapper = styled.span`
  display: inline-block;
  height: 3rem;
  width: 3rem;
  color: #666;

  padding: 0.5rem;

  border-radius: 50%;
  background: transparent;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.show {
    opacity: 0.5;
  }

  &:hover {
    opacity: 1;
    background: ${({ theme }) => theme.secondary};
  }

  & > svg {
    fill: currentColor;
    height: 100%;
    width: 100%;
  }
`;

const ListingActionList = styled(ActionsList)`
  right: 3rem;
  top: 0.5rem;
  opacity: 0;

  &.open {
    max-height: 60vh;
  }

  .closed {
    max-height: 0;
  }
`;

const actions = deviceActions;

interface ListingActionsProps {
  devicePartial: DevicePartial;
  showButton: boolean;
  tableRef: RefObject<HTMLTableElement>;
  refreshView(): void;
}

const ListingActions: React.FC<ListingActionsProps> = ({
  devicePartial,
  showButton,
  refreshView,
  tableRef,
}) => {
  const [device, setDevice] = useState<DevicePartial>(devicePartial);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>('none');

  const listRef = useRef<HTMLUListElement>(null);

  const { isEdit, isManager } = useAccess(deviceTypeToArea(device));

  const resetAction = () => {
    setAction('none');
    refreshView();
  };

  useEffect(() => {
    if (listRef.current && tableRef.current) {
      const tableRect = tableRef.current.parentElement!.getBoundingClientRect();
      const listRect = listRef.current.getBoundingClientRect();

      if (listRect.bottom > tableRect.bottom) {
        listRef.current.style.bottom = '.75rem';
        listRef.current.style.top = 'initial';
      }
    }
    setIsOpen(false);
  }, [tableRef]);

  useEffect(() => {
    if (!showButton) {
      setIsOpen(false);
    }
  }, [showButton]);

  const handleClick = async () => {
    if (!isOpen) {
      const res = await handleApiReq('GetDeviceTags', { deviceId: device.deviceId });
      if (res) {
        setDevice({
          ...device,
          tags: res.tags,
          accessRightsTag: res.accessRightsTag,
        });
      }
    }

    if (tableRef.current && listRef.current) {
      const tableRect = tableRef.current.parentElement!.getBoundingClientRect();
      const listRect = listRef.current.getBoundingClientRect();

      if (listRect.bottom > tableRect.bottom) {
        listRef.current.style.bottom = '.75rem';
        listRef.current.style.top = 'initial';
      }

      if (listRect.top < tableRect.top) {
        listRef.current.removeAttribute('style');
      }
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <IconWrapper onClick={handleClick} className={classNames({ show: showButton })}>
        <SvgIcon type="kebab" />
      </IconWrapper>
      <ListingActionList className={isOpen ? 'open' : 'closed'} ref={listRef}>
        {actions.map(
          (a) =>
            a.shouldDisplay({ device, isListing: true, isManager, isEng: isEdit }) && (
              <li
                key={a.id}
                onClick={() => {
                  setAction(a.id);
                  setIsOpen(false);
                }}
                role="presentation"
              >
                {a.label}
              </li>
            )
        )}
      </ListingActionList>
      <DeviceActionSelector actionType={action} device={device} handleResetAction={resetAction} />
    </>
  );
};

export default ListingActions;

import styled from 'styled-components';

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
  font-size: 0.9em;
`;

export const CardTitle = styled.div`
  font-size: 1.2em;
`;

export const GraphWrapper = styled.div`
  height: 150px;
  width: 100%;
`;

export const EventListContainer = styled.div`
  border-top: 1px solid lightgray;
  padding: 5px;
`;

export const EventList = styled.div`
  width: 100%;
  height: 6.5rem;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
`;

export const EventItem = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const EventDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

export const EventDescription = styled.span`
  font-size: 0.9em;
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const EventMeta = styled.span`
  font-size: 0.7em;
  white-space: nowrap;
  font-weight: bold;
`;

export const EventMarker = styled.div<{ severity: string }>`
  width: 10px;
  background-color: ${(props) =>
    (props.severity === 'High' && '#E65D6D') ||
    (props.severity === 'Medium' && '#EDB760') ||
    '#0187B5'};
  border-radius: 5px;
  margin-right: 5px;
`;

export const DetailsContainer = styled.div`
  padding-right: 5px;
  min-width: 350px;
  max-width: 500px;
`;

export const NoEventsPlaceholder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.secondary};
`;

export const OngoingText = styled.span`
  color: ${(props) => props.theme.vericonRed};
`;

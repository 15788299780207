import React, { useState } from 'react';

import styled from 'styled-components';

const Heading = styled.h3`
  text-align: center;
  margin-bottom: 1rem;

  & span {
    font-weight: 600;

    &.passed {
      color: ${(props) => props.theme.success};
    }
    &.degraded {
      color: ${(props) => props.theme.warning};
    }
    &.failed {
      color: ${(props) => props.theme.failed};
    }
  }
`;

const FormGroup = styled.div`
  display: flex;
  padding: 0.5rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  && h4 {
    font-weight: 500;
    margin-bottom: 0;
    width: 10rem;
  }
`;

const FormItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  & label {
    margin-left: 0.5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 30rem;
  margin: 0 auto;
  margin-top: 2rem;
  justify-content: space-around;
  align-items: center;
`;

const SubmitButton = styled.button`
  ${(props) => props.theme.submitButton('12rem')};
`;

const CancelButton = styled.button`
  ${(props) => props.theme.clearButton('12rem')};
`;

const Input = styled.textarea`
  ${(props) => props.theme.inputStyle('40rem')};
  height: 10rem;
  cursor: text;
`;

const TestResultEdit = ({ testData, handleSubmit, handleCancel }) => {
  const [result, setResult] = useState(testData.result);
  const [reason, setReason] = useState(testData.reason);
  const [derivedReason, setDerivedReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [componentStates, setComponentStates] = useState(testData.componentStates);

  const calculateTestResult = (componentStates) => {
    let isFailed, isDegraded, result;
    Object.keys(componentStates).forEach((k) => {
      if (componentStates[k] === 'Failed') {
        isFailed = true;
      }
      if (componentStates[k] === 'Degraded') {
        isDegraded = true;
      }
      if (k === 'other') result = componentStates[k];
    });

    if (result) {
      return result;
    }

    if (isFailed) {
      return 'Failed';
    }

    if (isDegraded) {
      return 'Degraded';
    }

    return 'Passed';
  };

  const calculateReason = (componentStates, testResult) => {
    if (testResult === 'Passed') return 'Device successfully completed test.';
    let failedMsg = '',
      degradedMsg = '';

    Object.keys(componentStates).forEach((k) => {
      if (k === 'other') return;
      if (componentStates[k] === 'Failed') {
        if (failedMsg) {
          failedMsg += `, ${k}`;
        } else {
          failedMsg = `Predicted Failed Components: ${k}`;
        }
      }
      if (componentStates[k] === 'Degraded') {
        if (degradedMsg) {
          degradedMsg += `, ${k}`;
        } else {
          degradedMsg = `Predicted degraded Components: ${k}`;
        }
      }
    });

    if (failedMsg && degradedMsg) {
      return `${failedMsg}. ${degradedMsg}.`;
    }

    if (failedMsg) {
      return `${failedMsg}.`;
    }

    if (degradedMsg) {
      return `${degradedMsg}.`;
    }

    return '';
  };

  const handleRadioChange = ({ target }) => {
    const name = target.name;
    const value = target.value;

    const newComponentState = {
      ...componentStates,
      [name]: value,
    };
    setComponentStates(newComponentState);

    //calculate test result
    const testResult = calculateTestResult(newComponentState);
    setResult(testResult);
    const testReason = calculateReason(newComponentState, testResult);
    setDerivedReason(testReason);
    setReason(testReason + customReason);
  };

  const handleInputChange = ({ target }) => {
    const value = target.value;
    const userInput = value.replace(derivedReason, '');
    setCustomReason(userInput);
    setReason(value);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Heading>
        Test Result: <span className={result.toLowerCase()}>{result}</span>
      </Heading>
      <input type="hidden" value={result} id="result" name="result" />
      <input type="hidden" value={testData.eventId} id="eventId" name="eventId" />
      <input type="hidden" value={testData.testType} id="testType" name="testType" />
      <FormGroup>
        <h4>Battery: </h4>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Battery"
            value="Ok"
            id="battery-ok"
            checked={componentStates.Battery === 'Ok'}
          />
          <label htmlFor="battery-ok">Ok</label>
        </FormItem>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Battery"
            value="Degraded"
            id="battery-degraded"
            checked={componentStates.Battery === 'Degraded'}
          />
          <label htmlFor="battery-degraded">Degraded</label>
        </FormItem>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Battery"
            value="Failed"
            id="battery-failed"
            checked={componentStates.Battery === 'Failed'}
          />
          <label htmlFor="battery-failed">Failed</label>
        </FormItem>
      </FormGroup>
      <FormGroup>
        <h4>Inverter: </h4>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Inverter"
            value="Ok"
            id="inverter-ok"
            checked={componentStates.Inverter === 'Ok'}
          />
          <label htmlFor="inverter-ok">Ok</label>
        </FormItem>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Inverter"
            value="Degraded"
            id="inverter-degraded"
            checked={componentStates.Inverter === 'Degraded'}
          />
          <label htmlFor="inverter-degraded">Degraded</label>
        </FormItem>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Inverter"
            value="Failed"
            id="inverter-failed"
            checked={componentStates.Inverter === 'Failed'}
          />
          <label htmlFor="inverter-failed">Failed</label>
        </FormItem>
      </FormGroup>
      <FormGroup>
        <h4>Lamp: </h4>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Lamp"
            value="Ok"
            id="lamp-ok"
            checked={componentStates.Lamp === 'Ok'}
          />
          <label htmlFor="lamp-ok">Ok</label>
        </FormItem>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Lamp"
            value="Degraded"
            id="lamp-degraded"
            checked={componentStates.Lamp === 'Degraded'}
          />
          <label htmlFor="lamp-degraded">Degraded</label>
        </FormItem>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Lamp"
            value="Failed"
            id="lamp-failed"
            checked={componentStates.Lamp === 'Failed'}
          />
          <label htmlFor="lamp-failed">Failed</label>
        </FormItem>
      </FormGroup>
      <FormGroup>
        <h4>Emered: </h4>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Emered"
            value="Ok"
            id="emered-ok"
            checked={componentStates.Emered === 'Ok'}
          />
          <label htmlFor="emered-ok">Ok</label>
        </FormItem>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Emered"
            value="Degraded"
            id="emered-degraded"
            checked={componentStates.Emered === 'Degraded'}
          />
          <label htmlFor="emered-degraded">Degraded</label>
        </FormItem>
        <FormItem>
          <input
            type="radio"
            onChange={handleRadioChange}
            name="Emered"
            value="Failed"
            id="emered-failed"
            checked={componentStates.Emered === 'Failed'}
          />
          <label htmlFor="emered-failed">Failed</label>
        </FormItem>
      </FormGroup>
      <FormGroup>
        <h4>Result State: </h4>
        <div>
          <FormItem>
            <input
              type="radio"
              onChange={handleRadioChange}
              name="other"
              value="Passed"
              id="other-pass"
              checked={componentStates.other === 'Passed'}
            />
            <label htmlFor="other-pass">Pass</label>
          </FormItem>
          <FormItem>
            <input
              type="radio"
              onChange={handleRadioChange}
              name="other"
              value="Degraded"
              id="other-degraded"
              checked={componentStates.other === 'Degraded'}
            />
            <label htmlFor="other-degraded">Degraded</label>
          </FormItem>
          <FormItem>
            <input
              type="radio"
              onChange={handleRadioChange}
              name="other"
              value="Failed"
              id="other-failed"
              checked={componentStates.other === 'Failed'}
            />
            <label htmlFor="other-failed">Failed</label>
          </FormItem>
          <FormItem>
            <input
              type="radio"
              onChange={handleRadioChange}
              name="other"
              value="Indeterminate"
              id="other-indeterminate"
              checked={componentStates.other === 'Indeterminate'}
            />
            <label htmlFor="other-indeterminate">Indeterminate</label>
          </FormItem>
          <FormItem>
            <input
              type="radio"
              onChange={handleRadioChange}
              name="other"
              value="AwaitingReview"
              id="other-awaitingReview"
              checked={componentStates.other === 'AwaitingReview'}
            />
            <label htmlFor="other-awaitingReview">AwaitingReview</label>
          </FormItem>
          <FormItem>
            <input
              type="radio"
              onChange={handleRadioChange}
              name="other"
              value="Suppressed"
              id="other-suppressed"
              checked={componentStates.other === 'Suppressed'}
            />
            <label htmlFor="other-suppressed">Suppressed</label>
          </FormItem>
        </div>
      </FormGroup>
      <FormGroup>
        <h4>Reason: </h4>
        <FormItem>
          <Input name="reason" value={reason} onChange={handleInputChange} id="reason" />
        </FormItem>
      </FormGroup>
      <ButtonWrapper>
        <SubmitButton>Submit</SubmitButton>
        <CancelButton onClick={(e) => handleCancel(e)}>Cancel</CancelButton>
      </ButtonWrapper>
    </form>
  );
};

export default TestResultEdit;

import React, { useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { refreshTags } from 'reducers/tags/tagActions';
import styled from 'styled-components';

import { SettingsContainer } from '../components/SettingsContainer';
import { fetchTenantInfo } from '../reducers/tenantInfo';

const SettingsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const SettingsHeader = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  && h1 {
    margin-bottom: 0;
  }
`;

const Settings = () => {
  const dispatch = useDispatch();
  const tenantInfo = useSelector((state: any) => state.tenantInfo.tenantInfo);

  const tenantId = useMemo(() => tenantInfo.id, [tenantInfo.id]);

  const settings = useMemo(() => tenantInfo.settings, [tenantInfo.settings]);

  const refreshTenantInfo = async () => {
    try {
      await dispatch(fetchTenantInfo());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const refreshTagsData = async () => {
    try {
      await dispatch(refreshTags());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <SettingsWrapper>
      <SettingsHeader>
        <h1>Settings</h1>
      </SettingsHeader>
      {settings && (
        <SettingsContainer
          settings={settings}
          tenant={tenantId}
          refreshTenantInfo={refreshTenantInfo}
          refreshTagsData={refreshTagsData}
        />
      )}
    </SettingsWrapper>
  );
};

export default Settings;

import { DeviceStatus } from 'types/BoilerTypes/BcmConnectDetails';

export enum PropertyTelemetryOptions {
  PropertySummary = 'Property Summary',
  BoilerMonitoring = 'Boiler Monitoring',
  HeatPumpMonitoring = 'Heat Pump Monitoring',
  AssetManagement = 'Asset Management',
  VericonDiagnostics = 'Vericon Diagnostics',
}

export enum EPCRating {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  NoRating = 'No rating',
}

export type DeviceLicence = {
  deviceId: string;
  licenceId: string;
  licenceType: string;
  saasStartDate: string;
  saasPeriod: string;
  saasEndDate: string;
  saasRenewedDate: string;
};

export type ManagedServiceLicence = {
  licenceId: string;
  licenceType: string;
  saasStartDate: string;
  saasPeriod: string;
  saasEndDate: string;
  saasRenewedDate: string;
};

export enum LocationRoom {
  Attic = 'Attic',
  AiringCupboard = 'Airing Cupboard',
  Bathroom1 = 'Bathroom 1',
  Bathroom2 = 'Bathroom 2',
  Bedroom1 = 'Bedroom 1',
  Bedroom2 = 'Bedroom 2',
  Bedroom3 = 'Bedroom 3',
  Bedroom4 = 'Bedroom 4',
  BoilerRoom = 'Boiler room',
  CorridorDownstairs = 'Corridor - Downstairs',
  CorridorUpstairs = 'Corridor - Upstairs',
  EntranceHall = 'Entrance Hall',
  Garage = 'Garage',
  Kitchen = 'Kitchen',
  LivingRoom = 'Living room',
  Toilet1 = 'Toilet 1',
  Toilet2 = 'Toilet 2',
  Other = 'Other',
}

export type PropertyLocation = {
  id: string;
  name: LocationRoom;
  details: string;
};

export type PropertyDevice = {
  deviceId: string;
  name: string;
  status: DeviceStatus;
  accessRightsTagId: string | null;
  serialNumber: string;
  eui64: string | null;
  location: string;
  locationDetails: string | null;
  propertyLocationId: string | null;
  tagIds: { id: string }[];
};

import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { selectIsLoading } from 'reducers/loading/selectors';
import styled from 'styled-components';
import { colors } from 'styles/variables';

type btnTypes = 'edit' | 'submit' | 'error' | 'clear' | 'default';

interface ButtonProps {
  type: string;
}

const ButtonWrapper = styled.div<ButtonProps>`
  display: inline-block;
  position: relative;
  margin-right: 2rem;
  width: 16rem;
  height: 3.5rem;
  text-align: center;
  outline: none;
  border-radius: 20px;
  font-family: inherit;
  font-weight: 500;
  font-size: inherit;
  background-color: transparent;
  border: 2px ${({ type }) => colors[type]} solid;
  color: #666;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;

  & > div {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: ${(props) => colors[props.type]};

    opacity: 0;
    transform: scale(0);

    transition: all 0.3s ease-in-out;
  }

  & span {
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    transition: all 0.3s ease-in-out;
  }

  &.active {
    & > div {
      opacity: 1;
      transform: scale(1.01);
    }

    & span {
      color: ${(props) => props.theme.white};
    }
  }

  &:hover {
    cursor: pointer;
    border: 2px ${({ type }) => colors[`${type}Hover`]} solid;

    & > div {
      background: ${(props) => colors[`${props.type}Hover`]};
    }
  }
`;

interface buttonProps {
  isActive: boolean;
  type: btnTypes;
  actionType: string;
  action: any;
  children: any;
}

const StateButton = ({ isActive, action, actionType, type, children }: buttonProps) => {
  const [isOn, setIsOn] = useState(isActive);
  const isLoading = useSelector((state: any) => selectIsLoading([actionType])(state));
  useEffect(() => {
    setIsOn(isActive);
  }, [isActive]);

  const handleOnClick = async () => {
    await action();
  };

  return (
    <ButtonWrapper className={isOn ? 'active' : ''} type={type} onClick={handleOnClick}>
      <span> {isLoading ? 'Sending ...' : children}</span>
      <div />
    </ButtonWrapper>
  );
};

export default StateButton;

import { styled as styledMui } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';

export const StyledTableCell = styledMui(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingTop: 4,
    paddingBottom: 4,
  },
}));

export const StyledTableRow = styledMui(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const ResidenceTableWrapper = styled.div`
  padding: 24px 0;
  position: relative;

  .residence-table {
    &__filtering {
      padding-bottom: 20px;
      display: flex;
      justify-content: flex-end;

      &-input {
        max-width: 300px;
      }
    }

    &__linear-progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    &__loading {
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

import React, { useState, useEffect } from 'react';

import BuildingsTable from 'components/BuildingManagement/BuildingsTable';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBuildingInfo } from 'reducers/buildingInfo/actions';
import { selectBuildings } from 'reducers/buildingInfo/selectors';
import { fetchTenantBuildingSummary, selectBuildingSummaries } from 'reducers/tenantInfo';
import useIsLoading from 'utils/hooks/useIsLoading';

const Buildings = () => {
  const isLoading = useIsLoading(['FETCH_BUILDING_SUMMARY', 'FETCH_BUILDINGINFO_REQUEST']);
  const dispatch = useDispatch();
  const buildings = useSelector((state: any) => selectBuildings(state));
  const summaries = useSelector((state: any) => selectBuildingSummaries(state));

  const [buildingInfo, setBuildingInfo] = useState();

  useEffect(() => {
    if (!isLoading) {
      dispatch(fetchBuildingInfo());
      dispatch(fetchTenantBuildingSummary());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (buildings && summaries && !isLoading) {
      setBuildingInfo(
        buildings.map((b) => ({
          building: b,
          deviceCount: summaries[b.building]?.total | 0,
          failedDeviceCount: summaries[b.building]?.fail | 0,
          warningDeviceCount: summaries[b.building]?.warn | 0,
          offlineDeviceCount: summaries[b.building]?.offline | 0,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildings, summaries]);

  return (
    <>{buildingInfo && !isLoading ? <BuildingsTable buildingInfo={buildingInfo} /> : <Loading />}</>
  );
};

export default Buildings;

import React from 'react';
import { Tooltip, IconButton, CircularProgress, TableRow, TableCell } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import ErrorIcon from '@mui/icons-material/Error';

import { StyledTableRow } from './BulkActionRow.styles';

type BulkActionRowProps = {
  cells: string[];
  status: { error: boolean; success: boolean; loading: boolean };
  handleReset: () => void;
};

export const BulkActionRow: React.FC<BulkActionRowProps> = ({ cells, status, handleReset }) => (
  <TableRow component={StyledTableRow}>
    <TableCell className="bulk-action-row__cell" align="left">
      <div className="bulk-action-row__controls">
        {status.success && (
          <CheckCircleIcon aria-label="success-icon" className="bulk-action-row__success" />
        )}
        {status.error && (
          <div className="bulk-action-row__error">
            <ErrorIcon className="bulk-action-row__error-icon" />
            <Tooltip arrow placement="top" title="Retry">
              <IconButton
                aria-label="bulk-action-row-reset"
                className="bulk-action-row__error-reset"
                onClick={handleReset}
              >
                <ReplayIcon className="bulk-action-row__error-reset-icon" />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {status.loading && <CircularProgress className="bulk-action-row__progress" />}
      </div>
    </TableCell>
    {cells.map((cell: string) => (
      <TableCell className="bulk-action-row__cell" key={cell} align="left">
        {cell}
      </TableCell>
    ))}
  </TableRow>
);

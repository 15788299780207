import React from 'react';

import { handleApiReq } from 'utils/ApiRequestHelpers';
import { isEmergencyLight } from 'utils/deviceUtils';
import useAlert from 'utils/hooks/useAlert';

import { DeviceActionProps } from '../DeviceActionProps';
import { DeviceActionMainDialog } from '../Dialogs';

const LeaveNetwork = ({ device, handleResetAction }: DeviceActionProps) => {
  const { errorAlert, successAlert } = useAlert();

  const handleLeaveNetwork = async () => {
    const url = isEmergencyLight(device) ? 'EmeredLeaveNetwork' : 'RepeaterLeaveNetwork';
    const res = await handleApiReq(url, { deviceId: device.deviceId });
    if (res) {
      successAlert('Request Successful');
    } else {
      errorAlert('Something has gone wrong, please try again');
    }
    handleResetAction();
  };

  return (
    <DeviceActionMainDialog
      withSpinner
      handleResetAction={handleResetAction}
      handleSubmit={handleLeaveNetwork}
    >
      <h3>Leave Network</h3>
      <p>Clicking submit will attempt to Leave the device to the zigbee gateway</p>
    </DeviceActionMainDialog>
  );
};

export default LeaveNetwork;

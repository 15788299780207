import React from 'react';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';

import { getColumnVisibilityModel } from 'utils';
import { StyledDataGrid } from 'styles/StyledDataGrid';
import { CustomToolbar } from 'components/molecules';
import { useTableData } from './hooks/useTableData';
import { PropertiesWrapper } from './Properties.styles';
import { DataGridColumnsHidden } from './types';

export const Properties: React.FC = () => {
  const { rows, pinnedRows, columns, isLoading } = useTableData();
  const navigate = useNavigate();

  const onRowClick = ({ id }) => navigate(`${id}/info`);

  const { columnVisibilityModel } = getColumnVisibilityModel(DataGridColumnsHidden);

  return (
    <PropertiesWrapper>
      <div className="table-page__header">
        <div className="table-page__header-title">
          <Typography className="table-page__header-title-text">Properties</Typography>
        </div>
      </div>
      <div className="table-page__table">
        <StyledDataGrid
          rows={rows}
          columns={columns}
          pinnedRows={pinnedRows}
          loading={isLoading}
          disableDensitySelector
          density="standard"
          columnHeaderHeight={50}
          disableRowSelectionOnClick
          onRowClick={onRowClick}
          pageSizeOptions={[25, 50, 100]}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
            columns: {
              columnVisibilityModel,
            },
          }}
          checkboxSelection
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </PropertiesWrapper>
  );
};

import React from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Areas } from 'types/roles';
import useAccess from 'utils/hooks/useAccess';

import DeviceCount from './DeviceCount';

const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
  padding: 2rem;
`;

const DataTable = styled.table`
  ${(props) => props.theme.tableStyle};
`;

const Table = ({ buildingInfo }) => {
  const { isManager } = useAccess(Areas.misc);
  const navigate = useNavigate();

  const handleRowClick = (e, building) => {
    if (isManager && e.target.nodeName !== 'A') navigate(building);
  };

  return (
    <Wrapper>
      <DataTable>
        <thead>
          <tr>
            <th>Building Name</th>
            <th>No. of Devices</th>
            <th>No. of Fails</th>
            <th>No. of Warnings</th>
            <th>No. of Offline</th>
          </tr>
        </thead>
        <tbody>
          {buildingInfo.map((b) => (
            <tr onClick={(e) => handleRowClick(e, b.building.building)} key={b.building.building}>
              <td>{b.building.name}</td>
              <td>{b.deviceCount}</td>
              <td>
                <DeviceCount status="Failed" value={b.failedDeviceCount} />
              </td>
              <td>
                <DeviceCount status="Warning" value={b.warningDeviceCount} />
              </td>
              <td>
                <DeviceCount status="NotInstalled" value={b.offlineDeviceCount} />
              </td>
            </tr>
          ))}
        </tbody>
      </DataTable>
    </Wrapper>
  );
};

export default Table;

import React from 'react';
import { IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { EditFormFieldActionWrapper } from './EditFormFieldAction.styles';

type EditFormFieldActionProps = {
  fieldTitle: string;
  fieldValue: string;
  onSubmit: () => void;
  allowEdit?: boolean;
  children?: React.ReactNode;
};

export const EditFormFieldAction: React.FC<EditFormFieldActionProps> = ({
  fieldTitle,
  fieldValue,
  onSubmit,
  allowEdit,
  children,
}) => (
  <EditFormFieldActionWrapper>
    <div className="edit-form-field-action__main">
      <Typography className="edit-form-field-action__title">{fieldTitle}:</Typography>
      <div className="edit-form-field-action__block">
        <div className="edit-form-field-action__label">
          <Typography className="edit-form-field-action__label-text">
            {fieldValue}
          </Typography>
          {allowEdit && (
            <div className="edit-form-field-action__label-edit">
              <IconButton
                onClick={onSubmit}
                className="edit-form-field-action__label-edit-button"
                aria-label="edit-form-field-action-edit"
              >
                <EditIcon className="edit-form-field-action__label-edit-icon" />
              </IconButton>
            </div>
          )}
        </div>
        <div className="edit-form-field-action__content">{children}</div>
      </div>
    </div>
  </EditFormFieldActionWrapper>
);

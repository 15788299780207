import React from 'react';

import styled from 'styled-components';

const TextBoxWrapper = styled.div<{ disabled: boolean; state: string }>`
  position: relative;
  display: inline-block;
  width: 100%;

  & > div {
    width: 100%;
    position: relative;
    display: inline-block;
    background-color: ${(props) => props.theme.offWhite};
    border-radius: 20px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    box-shadow: ${(props) => {
    if (props.state) {
      return props.state === 'error'
        ? `0 0 4px 1px ${props.theme.error}`
        : `0 0 4px 1px ${props.theme.success}`;
    }

    return 'none';
  }};
  }

  & input {
    ${(props) => props.theme.inputStyles};
    background-color: transparent;
    z-index: 6;
    width: 100%;
    ${(props) => {
    if (props.state) return 'border-color: transparent';
  }};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  & ul {
    display: none;
    position: absolute;
    left: 0;
    max-height: 20rem;
    overflow: auto;
    background: ${(props) => props.theme.white};
    width: 100%;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 6;

    li {
      padding: 0.5rem 1rem;
      &:hover,
      &.selected {
        cursor: pointer;
        background-color: ${(props) => props.theme.default};
      }
    }

    &.open {
      display: block;
    }
  }
`;

const TextSearch = ({
  value,
  onChange,
  name,
  placeholder = '',
  readOnly = false,
  disabled = false,
  state = '',
  autoFocus = false,
  handleFocus,
}) => {
  const handleOnChange = (e) => onChange(e.target.value, e.target.name);

  return (
    <TextBoxWrapper disabled={disabled} state={state}>
      <div>
        <input
          autoComplete="off"
          name={name}
          onChange={handleOnChange}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          onFocus={handleFocus ? (e) => handleFocus(e) : undefined}
        />
      </div>
    </TextBoxWrapper>
  );
};

export default TextSearch;

import React from 'react';
import { Link } from 'react-router-dom';

import DeviceIcon from 'components/Icons/DeviceIcon';
import { DeviceTypes } from 'types/DeviceTypes/Device';
import NavIcon from 'components/Icons/NavIcon';

import { LinkLess } from './DeviceWidgetIcon.styles';

interface DeviceWidgetIconProps {
  deviceType?: DeviceTypes[];
  count: number;
  status?: string;
  icon?: React.ReactNode;
  className?: string;
  msg: string;
  to: string | null;
}

export const DeviceWidgetIcon: React.FC<DeviceWidgetIconProps> = ({
  deviceType,
  className,
  count,
  status,
  icon,
  msg,
  to,
}) =>
  count === 0 || !to ? (
    <LinkLess {...(!!className && { className })}>
      {icon ? icon : <DeviceIcon size={6} status={status} deviceType={deviceType} />}
      <div className="device-widget-icon__text">
        <span className="device-widget-icon__text-count">{count} </span>
        <span className="device-widget-icon__text-msg">{msg}</span>
      </div>
    </LinkLess>
  ) : (
    <Link {...(!!className && { className })} to={to}>
      <LinkLess>
        {icon ? icon : <DeviceIcon size={6} status={status} deviceType={deviceType} />}
        <div className="device-widget-icon__text">
          <span className="device-widget-icon__text-count">{count} </span>
          <span className="device-widget-icon__text-msg">{msg}</span>
          <NavIcon size={0.9} thickness={2} />
        </div>
      </LinkLess>
    </Link>
  );

import React from 'react';
import LockResetIcon from '@mui/icons-material/LockReset';
import { FormHelperText } from '@mui/material';

import { ActionDialog } from 'components/molecules';
import { useResetUserPassword } from 'scenes/Users/hooks';

type ResetPasswordDialogProps = {
  onClose: () => void;
  email: string;
  name: string;
};

export const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({
  onClose,
  email,
  name,
}) => {
  const { isLoading, isError, isSuccess, mutate } = useResetUserPassword();

  const handleResetPassword = () => mutate({ email });

  return (
    <ActionDialog
      open
      onClose={onClose}
      handleSubmit={handleResetPassword}
      submitButtonName="Reset"
      submitButtonIcon={<LockResetIcon />}
      title="Reset Password"
      sendingRequest={isLoading}
      successMessageProps={{
        message: 'Password reset sent successfully',
        showMessage: isSuccess,
        onClose: onClose,
      }}
    >
      Are you sure you want to reset the password for {name}?
      {isError && (
        <FormHelperText error sx={{ fontSize: 14, textAlign: 'center', pt: 1 }}>
          Failed to send password reset
        </FormHelperText>
      )}
    </ActionDialog>
  );
};

import React from 'react';

import { useSelector } from 'react-redux';

const InfoItem = ({ title, content }) => (
  <section>
    <h5>{title}</h5>
    <div>
      <pre>
        <code>{JSON.stringify(content, null, 2)}</code>
      </pre>
    </div>
  </section>
);

const DebugInfo = () => {
  const info = useSelector((state: any) => state.device.debug);

  return (
    <>{info && Object.keys(info).map((d, i) => <InfoItem title={d} content={info[d]} key={i} />)}</>
  );
};

export default DebugInfo;

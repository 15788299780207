import React, { useCallback } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import { CardExtended } from 'types/Telemetry';
import {
  CardContainer,
  ErrorDataContainer,
  LoadingWrapper,
  NoDataContainer,
} from 'styles/commonStyles/telemetry';
import LoadingOverlay from 'components/atoms/LoadingOverlay';
import { EllipsisWithTooltip } from 'components/atoms';
import { NumericDisplay } from '../NumericDisplay/NumericDisplay';
import { CardHeader, CardSubtitle, CardTitle, GraphWrapper } from './NumericChartCard.styles';

type NumericChartCardProps = {
  chart: CardExtended;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

export const NumericChartCard: React.FC<NumericChartCardProps> = ({
  chart,
  isLoading,
  isError,
  isSuccess,
}) => {
  const renderNumericChart = useCallback(
    ({ width, height }) => {
      if (width <= 0 || height <= 0) {
        return null;
      }

      if (chart.dataSeries?.length && !isError) {
        return <NumericDisplay cardWidth={width} chart={chart} />;
      }

      if (isError) {
        return <ErrorDataContainer>Something went wrong, please retry.</ErrorDataContainer>;
      }

      return <NoDataContainer>No data available.</NoDataContainer>;
    },
    [chart, isError]
  );

  const hideChartWhenNoData = isSuccess && chart.hideWhenNoData && !chart.dataSeries?.length;

  return hideChartWhenNoData ? null : (
    <CardContainer gridWidth={chart.gridWidth}>
      <CardHeader>
        <CardTitle>
          <div>{chart.title}</div>
          {chart.subTitle && (
            <div className="numeric-chart-card__subtitle">
              <EllipsisWithTooltip text={chart.subTitle} />
            </div>
          )}
        </CardTitle>
        {!isLoading && <CardSubtitle>({chart.units})</CardSubtitle>}
      </CardHeader>
      <GraphWrapper>
        {isLoading ? (
          <LoadingWrapper data-testid="loading-overlay">
            <LoadingOverlay />
          </LoadingWrapper>
        ) : (
          <ParentSize>{renderNumericChart}</ParentSize>
        )}
      </GraphWrapper>
    </CardContainer>
  );
};

import React, { useState, useEffect, useCallback } from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import { useSelector, useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { removeToast } from 'reducers/toasts/ToastActions';
import styled from 'styled-components';

const ToastWrapperWrapper = styled.div`
  position: absolute;
  top: 8rem;
  right: 2rem;
  height: 100%;
`;

const ToastsWrapper = styled.ul`
  position: sticky;
  right: 2rem;
  top: 1rem;
  z-index: 13;
`;

const Toast = styled.li<{ state: string; type: string }>`
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  background-color: ${(props) => `${props.theme.toastColor(props.type)}bb`};
  font-size: ${(props) => props.theme.textSmall};
  color: ${(props) => props.theme.white};
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4);
 z-index: 11;
  transition: transform 0.6s cubic-bezier(0.6, -0.5, 0.3, 1.5), background-color 0.3s ease-in-out;

  margin-bottom: 1rem;

  transform: ${({ state }) => (state === 'entered' ? 'translateX(0)' : 'translateX(150%)')};

  &:hover {
    background-color: ${(props) => `${props.theme.toastColor(props.type)}ff`};
    cursor: pointer;
  }

  & svg {
    width: 2rem;
    height: 2rem;
    fill: ${(props) => props.theme.white};
  }
`;

const ToastWrapper = ({ message, id, type, dismissalRequired, dispatch }) => {
  const [showAlert, setShowAlert] = useState(false);

  const dismissAlert = useCallback(() => {
    const removeAlert = () => dispatch(removeToast(id));
    setShowAlert(false);
    setTimeout(() => removeAlert(), 550);
  }, [dispatch, id]);

  useEffect(() => {
    let timeout;
    setShowAlert(true);
    if (!dismissalRequired) timeout = setTimeout(() => dismissAlert(), 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dismissAlert, dismissalRequired]);

  return (
    <>
      <Transition
        in={showAlert}
        timeout={{ appear: 0, enter: 100, exit: 700 }}
        appear
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <Toast onClick={dismissAlert} state={state} type={type}>
            {message}
            <SvgIcon type="close" />
          </Toast>
        )}
      </Transition>
    </>
  );
};

const ToastsContainer = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state: any) => state.toasts.alerts);

  return (
    <ToastWrapperWrapper>
      <ToastsWrapper>
        {toasts.map((t) => (
          <ToastWrapper key={t.id} {...t} dispatch={dispatch} />
        ))}
      </ToastsWrapper>
    </ToastWrapperWrapper>
  );
};

export default ToastsContainer;

import React, { useCallback } from 'react';
import { Moment } from 'moment';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import { EllipsisWithTooltip, LoadingOverlay } from 'components/atoms';
import {
  CardContainer,
  ErrorDataContainer,
  LoadingWrapper,
  NoDataContainer,
} from 'styles/commonStyles/telemetry';
import { CardExtended } from 'types/Telemetry';
import { LineChart } from '../LineChart/LineChart';
import { CardHeader, CardTitle, GraphWrapper } from './LineChartCard.styles';

type LineChartCardProps = {
  chart: CardExtended;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  dateRange: Moment[];
};

export const LineChartCard: React.FC<LineChartCardProps> = ({
  chart,
  isLoading,
  isSuccess,
  isError,
  dateRange,
}) => {
  const renderLineChart = useCallback(
    ({ width, height }) => {
      if (width <= 0 || height <= 0) {
        return null;
      }

      if (chart.dataSeries?.length && !isError) {
        return (
          <LineChart graphWidth={width} graphHeight={height} chart={chart} dateRange={dateRange} />
        );
      }

      if (isError) {
        return <ErrorDataContainer>Something went wrong, please retry.</ErrorDataContainer>;
      }

      return <NoDataContainer>No data available.</NoDataContainer>;
    },
    [chart, dateRange, isError]
  );

  const hideChartWhenNoData = isSuccess && chart.hideWhenNoData && !chart.dataSeries?.length;

  return hideChartWhenNoData ? null : (
    <CardContainer gridWidth={chart.gridWidth}>
      <CardHeader>
        <CardTitle>
          <div>{chart.title}</div>
          {chart.subTitle && (
            <div className="line-chart-card__subtitle">
              <EllipsisWithTooltip text={chart.subTitle} />
            </div>
          )}
        </CardTitle>
        <div>{chart.chartLabel}</div>
      </CardHeader>
      <GraphWrapper>
        {isLoading ? (
          <LoadingWrapper data-testid="loading-overlay">
            <LoadingOverlay />
          </LoadingWrapper>
        ) : (
          <ParentSize>{renderLineChart}</ParentSize>
        )}
      </GraphWrapper>
    </CardContainer>
  );
};

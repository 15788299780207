import React, { useState, useRef } from 'react';

import { ButtonWithIcon } from 'components/atoms/Button';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBoilerRecords } from 'reducers/device/boilerActions';
import { fetchForms } from 'reducers/forms';
import { fetchRepairForms } from 'reducers/repairForms';
import styled from 'styled-components';
import { BcmRecord } from 'types/BoilerTypes/BcmRecord';
import { Nullable } from 'types/Nullable';
import { Areas } from 'types/roles';
import { formatAsDate } from 'utils/helpers';
import useAccess from 'utils/hooks/useAccess';
import useClickAway from 'utils/hooks/useClickAway';

import { TableWrapper, Table, Centered } from '../Table';
import { RecordModal } from './RecordModal';
import RecordModalSelector from './RecordModalSelector';

const Header = styled.div`
  font-size: ${(props) => props.theme.textSize} !important;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  && > h5 {
    margin-right: 2rem;
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;

  & > ul {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    position: absolute;

    & > li {
      padding: 0.5rem 1rem;
      background: ${({ theme }) => theme.white};
      transition: all 0.3s ease-in-out;

      &:hover {
        color: ${({ theme }) => theme.white};
        background: ${({ theme }) => theme.primary};
        cursor: pointer;
      }
    }

    &.open {
      opacity: 1;
    }
  }
`;

const BoilerRecords = ({ device }) => {
  const dispatch = useDispatch();
  const records: BcmRecord[] = useSelector((state: any) => state.device.records);

  const [showList, setShowList] = useState(false);
  const [modal, setModal] = useState<RecordModal>('none');

  const wrapper = useRef<HTMLDivElement>(null);
  useClickAway(wrapper, () => setShowList(false));
  const [selectedRecord, setSelectedRecord] = useState<Nullable<BcmRecord>>(null);
  const { isEdit } = useAccess(Areas.boilers);

  const handleRowClick = (record) => {
    setModal('details');
    setSelectedRecord(record);
  };

  const handleReset = async (refetch = false) => {
    setModal('none');
    setSelectedRecord(null);

    if (refetch) {
      await dispatch(fetchBoilerRecords(device.deviceId));
      await dispatch(fetchRepairForms(device.deviceId, device.deviceType));
      await dispatch(fetchForms(device.deviceId, device.deviceType));
    }
  };

  const canEdit = isEdit && !device.userHasReadOnlyAccess;

  return (
    <>
      <section>
        <Header>
          <h5>Boiler Compliance Records</h5>
          {canEdit && (
            <Wrapper ref={wrapper}>
              <ButtonWithIcon
                btnType="submit"
                iconType="caret-down"
                clickCallback={() => setShowList(!showList)}
              >
                Add record
              </ButtonWithIcon>
              <ul className={showList ? 'open' : ''}>
                <li onClick={() => setModal('gasSafety')} role="presentation">
                  Gas Safety
                </li>
                <li onClick={() => setModal('waterTreatment')} role="presentation">
                  Water Treatment
                </li>
              </ul>
            </Wrapper>
          )}
        </Header>
        <TableWrapper>
          <Table rowAction>
            <thead>
              <tr>
                <th>Certificate No.</th>
                <th>Record Type</th>
                <th>Result</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {records &&
                records.length > 0 &&
                records.map((record) => (
                  <tr onClick={() => handleRowClick(record)} key={record.recordId}>
                    <td>{record.serialNumber}</td>
                    <td>{record.recordType}</td>
                    <td>{record.status}</td>
                    <td>{formatAsDate(record.timestamp)}</td>
                  </tr>
                ))}
              {(!records || records.length === 0) && (
                <tr>
                  <td colSpan={4}>
                    <Centered>No records</Centered>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </TableWrapper>
      </section>
      <RecordModalSelector
        record={selectedRecord}
        type={modal}
        deviceId={device.deviceId}
        handleResetAction={handleReset}
      />
    </>
  );
};

export default BoilerRecords;

import axios from 'axios';
import { getToken } from 'utils/contexts/AuthProvider';

type HandleReduxPostRequestProps = {
  query: string;
  onInit?: () => void;
  onSuccess?: (data) => void;
  onError: (error) => void;
  body?: unknown;
};

export const handleReduxPostRequest = async ({
  query,
  body,
  onInit,
  onSuccess,
  onError,
}: HandleReduxPostRequestProps) => {
  if (onInit) {
    onInit();
  }

  try {
    const token = await getToken();

    const response = await axios.post(`${process.env.REACT_APP_APIURL}${query}`, body, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (error: any) {
    if (onError) {
      onError(error);
    }
  }
};

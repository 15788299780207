import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { setTenant } from '../utils/ApiRequestHelpers';
import { rootReducer } from './rootReducer';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const saveTenant = (store) => (next) => (action) => {
  if (action.type === 'AUTH_INIT' || action.type === 'SELECT_TENANT') {
    setTenant(action.payload.user?.selectedTenant);
  }

  return next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, saveTenant],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, any, any>;

export default store;

import React from 'react';

import styled from 'styled-components';

const TextBoxWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  & > div {
    width: 100%;
    position: relative;
    display: inline-block;
    background-color: ${(props) => props.theme.offWhite};
    border-radius: 30px;
    cursor: pointer;
  }

  & input {
    ${(props) => props.theme.inputStyles};
    padding: 0.3rem 1rem;
    background-color: transparent;
    z-index: 6;
    width: 100%;
  }
`;

const focusInput = (e) => {
  if (e.target.nodeName === 'DIV') {
    e.target.querySelector('input').focus();
  }
};

const DateField = ({
  selectedValue,
  onChange,
  name,
  placeholder = '',
  readOnly = false,
  hasOnChange,
}) => {
  const handleOnChange = (e) => onChange(e.target);

  return (
    <TextBoxWrapper>
      <div
        onClick={(e) => focusInput(e)}
        onKeyDown={(e) => focusInput(e)}
        role="button"
        tabIndex={0}
      >
        <input
          type="date"
          autoComplete="off"
          name={name}
          onChange={handleOnChange}
          {...(!hasOnChange && { defaultValue: selectedValue, key: selectedValue })}
          {...(hasOnChange && { value: selectedValue })}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      </div>
    </TextBoxWrapper>
  );
};
export default DateField;

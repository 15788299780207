import React from 'react';

import Input from 'components/elements/Input';
import styled from 'styled-components';

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.4rem;
`;

export interface SearchProps {
  searchTerm: string;
  updateSearchTerm(str: string): void;
}

const Search = ({ searchTerm, updateSearchTerm }: SearchProps) => (
  <SearchWrapper>
    <Input
      type="text"
      inputData={{
        value: searchTerm,
        onChange: updateSearchTerm,
        name: 'search',
        placeholder: 'Search ...',
      }}
    />
  </SearchWrapper>
);

export default Search;

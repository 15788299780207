import { ApiErrorsType } from 'types';

type GetErrorsListProps = {
  form?: {
    isValid: boolean;
    errors: { [key: string]: string };
    fieldName: string;
  };
  apiError: unknown;
  isError: boolean;
  includeDomainErrors?: boolean;
};

export const getErrorsList = ({
  form,
  apiError,
  isError,
  includeDomainErrors,
}: GetErrorsListProps) => {
  const apiDomainErrors: string[] | undefined = (apiError as unknown as ApiErrorsType)?.response
    ?.data?.domainErrors;
  const apiValidationErrors: string[] | undefined = (apiError as unknown as ApiErrorsType)?.response
    ?.data?.validationErrors;

  const apiErrorsList = [
    ...(includeDomainErrors && apiDomainErrors ? apiDomainErrors : []),
    ...(apiValidationErrors ?? []),
  ];

  return [
    ...(form && !form.isValid && form.errors[form.fieldName].length
      ? [form.errors[form.fieldName]]
      : []),
    ...(apiErrorsList?.length ? apiErrorsList.map((error) => error) : []),
    ...(isError && !apiErrorsList?.length ? ['Something went wrong!'] : []),
  ];
};

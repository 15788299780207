import React from 'react';
import { FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { FaPlus } from 'react-icons/fa';

import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { DeviceAccessRightsTagSelectWrapper } from './DeviceAccessRightsTagSelect.styles';

interface DeviceAccessRightsTagsSelectProps {
  handleAssignAccessRightsTag: (e) => void;
  accessRightsTags: AccessRightsTag[];
  handleCloseEditMode?: (e) => void;
  accessRightsTag: AccessRightsTag | undefined;
  disabled?: boolean;
}

export const DeviceAccessRightsTagSelect: React.FC<DeviceAccessRightsTagsSelectProps> = ({
  handleAssignAccessRightsTag,
  accessRightsTags,
  handleCloseEditMode,
  accessRightsTag,
  disabled,
}) => (
  <DeviceAccessRightsTagSelectWrapper className="device-access-rights-tag-select">
    <FormControl className="device-access-rights-tag-select__input">
      <InputLabel id="device-access-rights-tags-select-label">
        Select device access rights tag
      </InputLabel>
      <Select
        labelId="device-access-rights-tags-select-label"
        IconComponent={KeyboardArrowDown}
        onChange={handleAssignAccessRightsTag}
        disabled={disabled}
        label="Select device access rights tag"
        value={accessRightsTag?.id || ''}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {accessRightsTags.map((tag, monthIndex) => (
          <MenuItem key={monthIndex.toString()} value={tag.id}>
            {tag.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    {handleCloseEditMode && (
      <IconButton
        data-testid="device-access-rights-tag-select__close"
        className="device-access-rights-tag-select__close"
        onClick={handleCloseEditMode}
      >
        <FaPlus className="device-access-rights-tag-select__close-icon" />
      </IconButton>
    )}
  </DeviceAccessRightsTagSelectWrapper>
);

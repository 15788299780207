import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Divider, LinearProgress, Paper, Typography } from '@mui/material';
import { FaPlus } from 'react-icons/fa';

import { createUser, User } from 'reducers/users';
import { useAppSelector, useReduxApiData } from 'utils/hooks';
import { ApiActionNames } from 'reducers/types';
import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { UserDialog, UserGroupTable } from './molecules';
import { UsersWrapper } from './Users.styles';
import {
  ACCESS_RIGHTS_TAG_RESET,
  DELETE_USER_DIALOG_INITIAL_STATE,
  EDIT_USER_DIALOG_INITIAL_STATE,
  NO_TAG_GROUP,
  RESET_PASSWORD_DIALOG_INITIAL_STATE,
} from './constants/users';
import { groupUsersByTag, sortGroups, usersGroupedByTagWithTagNames } from './utils';
import { useUpdateUser } from './hooks/useUpdateUser';
import { DeleteUserDialog } from './molecules/DeleteUserDialog/DeleteUserDialog';
import { ResetPasswordDialog } from './molecules/ResetPasswordDialog/ResetPasswordDialog';

export const Users: React.FC = () => {
  const dispatch = useDispatch();
  const [openAddUser, setOpenAddUser] = useState(false);
  const [editDialog, setEditDialog] = useState(EDIT_USER_DIALOG_INITIAL_STATE);
  const [deleteDialog, setDeleteDialog] = useState(DELETE_USER_DIALOG_INITIAL_STATE);
  const [resetDialog, setResetDialog] = useState(RESET_PASSWORD_DIALOG_INITIAL_STATE);

  const accessRightsTags: AccessRightsTag[] = useAppSelector(
    (state) => state.accessRightsTags.accessRightsTags
  );

  const {
    isLoading,
    isError,
    data: users,
  } = useReduxApiData<User[]>((state) => state.users, ApiActionNames.FetchUsers);
  const createUserStatus = useReduxApiData((state) => state.users, ApiActionNames.CreateUser);

  const { handleUpdateUser, updateStatus } = useUpdateUser(users);

  const transformedGroups = usersGroupedByTagWithTagNames({
    groups: groupUsersByTag({ users }),
    accessRightsTags,
  });

  const groups = sortGroups(transformedGroups);

  const handleCreateUser = ({ data }: { data: User }) => {
    const { accessRightsTag, ...rest } = data;
    const tempDataTransform = {
      ...rest,
      tags: [
        ...(accessRightsTag && accessRightsTag !== ACCESS_RIGHTS_TAG_RESET
          ? [{ type: 'AccessRight', value: accessRightsTag }]
          : []),
      ],
    } as any;

    dispatch(createUser(tempDataTransform));
  };

  return (
    <UsersWrapper>
      <div className="users__heading">
        <Typography paddingBottom={3} variant="h2" fontWeight={400}>
          Users
        </Typography>
        {!isLoading && (
          <div className="users__heading-add-user">
            <Button
              onClick={() => setOpenAddUser(true)}
              endIcon={<FaPlus size={15} />}
              variant="contained"
              color="success"
            >
              Add User
            </Button>
          </div>
        )}
      </div>
      {isLoading && (
        <div className="users__progress">
          <LinearProgress />
        </div>
      )}
      {!!groups.length && (
        <Paper className="users__content" sx={{ p: 3 }} elevation={3}>
          {groups.map(({ groupName, groupUsers }, groupIndex) => (
            <Fragment key={groupIndex.toString()}>
              <UserGroupTable
                title={groupName === NO_TAG_GROUP ? 'General Access' : groupName}
                users={groupUsers}
                dialogSetters={{ setEditDialog, setDeleteDialog, setResetDialog }}
              />
              {groupName === NO_TAG_GROUP && groups.length > 1 && (
                <Divider>
                  <Typography className="users__content-separator">Other groups</Typography>
                </Divider>
              )}
            </Fragment>
          ))}
        </Paper>
      )}
      {!groups.length && !isLoading && !isError && (
        <div className="users__no-users">
          <Typography className="users__no-users-text">No users</Typography>
        </div>
      )}
      {!groups.length && !isLoading && isError && (
        <div className="users__failed">
          <Typography className="users__failed-text">Failed to load</Typography>
        </div>
      )}
      {editDialog.open && (
        <UserDialog
          isEdit
          status={updateStatus}
          successMessage="User edited successfully!"
          title="Edit user"
          onClose={() => setEditDialog(EDIT_USER_DIALOG_INITIAL_STATE)}
          user={users.find((user) => user.id === editDialog.id)}
          onSubmit={handleUpdateUser}
        />
      )}
      {deleteDialog.open && (
        <DeleteUserDialog
          onClose={() => setDeleteDialog(DELETE_USER_DIALOG_INITIAL_STATE)}
          id={deleteDialog.id}
          name={deleteDialog.name}
        />
      )}
      {resetDialog.open && (
        <ResetPasswordDialog
          onClose={() => setResetDialog(RESET_PASSWORD_DIALOG_INITIAL_STATE)}
          email={resetDialog.email}
          name={resetDialog.name}
        />
      )}
      {openAddUser && (
        <UserDialog
          status={{
            isLoading: createUserStatus.isLoading,
            isError: createUserStatus.isError,
            isSuccess: createUserStatus.isSuccess,
            errors: [createUserStatus.error],
          }}
          successMessage="User added successfully!"
          title="Add new user"
          onClose={() => setOpenAddUser(false)}
          onSubmit={handleCreateUser}
        />
      )}
    </UsersWrapper>
  );
};

import React from 'react';

import styled from 'styled-components';

import CheckBox from './CheckBox';

const CheckListWrapper = styled.div`
  display: flex;

  & label {
    margin-right: 1rem;
  }
`;

const CheckList = ({ value, name, onChange, options }) => {
  const localValue = value === null || value === undefined ? [] : value;

  const isArray = Array.isArray(localValue);
  const selectedValues = isArray ? localValue : localValue.split(', ');

  const handleClick = (v) => {
    if (selectedValues.includes(v)) {
      onChange(
        isArray
          ? selectedValues.filter((i) => i !== v)
          : selectedValues.filter((i) => i !== v).join(', '),
        name
      );
    } else {
      onChange(isArray ? [...selectedValues, v] : [...selectedValues, v].join(', '), name);
    }
  };

  return (
    <CheckListWrapper>
      {options.map((v, i) => (
        <CheckBox
          key={i}
          label={v}
          name={v}
          checked={selectedValues.includes(v)}
          onChange={() => handleClick(v)}
        />
      ))}
    </CheckListWrapper>
  );
};

export default CheckList;

import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { isEqual } from 'lodash';

import { getErrorsList } from 'utils';
import { FieldErrorMessages, InputEndIcon } from 'components/atoms';
import { useForm, ValidationStateType } from 'utils/hooks';
import { usePutFetchData } from 'utils/hooks/APIRequestsHandlers/usePutFetchData';
import { EditFormFieldsContainer } from '..';
import { EditFieldInputWrapper } from './EditFieldInput.styles';

type EditFieldInputProps = {
  url: string;
  onSuccess: () => void;
  fieldName: string;
  payloadFields?: { [key: string]: string | number | null };
  validationState: ValidationStateType;
  initialFormState: { [key: string]: string };
  editContainerProps: {
    fieldValue: string;
    fieldTitle: string;
    editIcon?: React.ReactNode;
    allowEdit?: boolean;
  };
};

export const EditFieldInput: React.FC<EditFieldInputProps> = ({
  url,
  onSuccess,
  fieldName,
  payloadFields = {},
  validationState,
  initialFormState,
  editContainerProps,
}) => {
  const { mutate, isLoading, isError, isSuccess, reset, error } = usePutFetchData({
    url,
    onSuccess,
  });

  const { formState, isValid, errors, registerField, resetForm } = useForm({
    initialFormState,
    validationState,
  });

  const isSubmitDisabled = !isValid || isEqual(initialFormState, formState) || isLoading || isError;

  const handleSubmit = () => mutate({ ...payloadFields, [fieldName]: formState[fieldName] });

  const handleFormClose = () => {
    reset();
    resetForm();
  };

  const errorsList = getErrorsList({
    apiError: error,
    isError: isError,
    form: { isValid, errors, fieldName },
  });

  return (
    <EditFieldInputWrapper>
      <EditFormFieldsContainer
        onSubmit={handleSubmit}
        onClose={handleFormClose}
        isLoading={isLoading || isSuccess}
        isSuccess={isSuccess}
        submitDisabled={isSubmitDisabled}
        {...editContainerProps}
      >
        <TextField
          {...registerField(fieldName)}
          label={editContainerProps.fieldTitle}
          aria-label="edit-field-input"
          variant="outlined"
          fullWidth
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InputEndIcon
                  isError={!!errors[fieldName] || isError}
                  value={
                    formState[fieldName] !== editContainerProps.fieldValue
                      ? formState[fieldName]
                      : ''
                  }
                  isNotFocused
                />
              </InputAdornment>
            ),
          }}
        />
        <FieldErrorMessages errors={errorsList} />
      </EditFormFieldsContainer>
    </EditFieldInputWrapper>
  );
};

import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { addToast } from 'reducers/toasts/ToastActions';
import { ToastStatusEnum } from 'types';
import { handleApiErrors } from 'utils/api/handleApiErrors';

type HandleReduxApiErrorProps = {
  dispatch: ThunkDispatch<unknown, unknown, Action<unknown>>;
  showDomainErrors?: boolean;
  showValidationErrors?: boolean;
};

export const handleReduxApiError = ({
  dispatch,
  showDomainErrors,
  showValidationErrors,
}: HandleReduxApiErrorProps) => {
  const errorToast = (msg, dissmisalRequired = false) =>
    dispatch(addToast(msg, ToastStatusEnum.Error, dissmisalRequired) as Action<unknown>);

  return handleApiErrors({ errorToast, showDomainErrors, showValidationErrors });
};

import React from 'react';

import DeviceActionContainer from '../DeviceActionContainer';
import NoteForm from './NoteForm';

const DeviceNotes = ({ device }) => (
  <DeviceActionContainer
    title="Notes"
    fault={null}
    form={<NoteForm device={device} />}
    device={device}
  />
);

export default DeviceNotes;

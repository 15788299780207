import React, { useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import moment, { Moment } from 'moment';
import Calendar from '@mui/icons-material/Event';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';

import { Charts } from 'components/molecules';
import { ChartProvider } from 'utils/contexts';
import { PropertyTelemetryWrapper } from './PropertyTelemetry.styles';
import { shortcutsItems } from './constants/shortcutsItems';
import { useTelemetry } from './hooks';

export const PropertyTelemetryBoard: React.FC = () => {
  const [dateRange, setDateRange] = useState<Moment[]>([
    moment().utc().subtract(1, 'week').startOf('day'),
    moment().utc().endOf('day'),
  ]);

  const {
    handleUpdateDateRange,
    handleTelemetryViewChange,
    currentView,
    chartsStatus,
    propertyTelemetryViewsList,
  } = useTelemetry({
    startTime: dateRange[0]?.toISOString(),
    endTime: dateRange[1]?.toISOString(),
  });

  const handleDateRangeChange = ([start, end]) => {
    setDateRange([start.utc().startOf('day'), end.utc().endOf('day')]);
  };

  return (
    <PropertyTelemetryWrapper>
      <div className="property-telemetry__view-select">
        <Select
          value={currentView.name}
          onChange={handleTelemetryViewChange}
          fullWidth
          MenuProps={{
            disableScrollLock: true,
          }}
          disabled={false}
        >
          {propertyTelemetryViewsList.map((option, optionIndex) => (
            <MenuItem key={optionIndex.toString()} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="property-telemetry__board">
        <div className="property-telemetry__date-picker">
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
            <DateRangePicker
              slots={{
                field: SingleInputDateRangeField,
              }}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                },
                textField: {
                  InputProps: {
                    endAdornment: <Calendar className="property-telemetry__date-picker-icon" />,
                  },
                },
              }}
              onAccept={handleUpdateDateRange}
              onChange={handleDateRangeChange}
              value={dateRange as DateRange<Moment>}
              maxDate={moment()}
              format="DD-MM-YYYY"
            />
          </LocalizationProvider>
        </div>
        <div className="property-telemetry__board-charts">
          <ChartProvider>
            <Charts charts={currentView.charts} chartsStatus={chartsStatus} dataRange={dateRange} />
          </ChartProvider>
        </div>
      </div>
    </PropertyTelemetryWrapper>
  );
};

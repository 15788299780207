const FETCH_DEBUGINFO_REQUEST = 'FETCH_DEBUGINFO_REQUEST';
const FETCH_DEBUGINFO_ERROR = 'FETCH_DEBUGINFO_ERROR';
const FETCH_DEBUGINFO_SUCCESS = 'FETCH_DEBUGINFO_SUCCESS';

export const debugInfoTypes = {
  FETCH_DEBUGINFO_REQUEST,
  FETCH_DEBUGINFO_ERROR,
  FETCH_DEBUGINFO_SUCCESS,
};

const initialState = {
  debugInfo: [],
};

export const debugInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case debugInfoTypes.FETCH_DEBUGINFO_SUCCESS:
      return {
        ...state,
        debugInfo: action.payload.debugInfo,
      };

    default:
      return state;
  }
};

import React, { useEffect } from 'react';

import ListingView from 'components/ListingView';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectEmeredViewFields } from 'reducers/fields';
import {
  ViewDefinition,
  selectSelectedView,
  selectEmeredViewDefinitions,
  setStoredView,
  ViewContext,
  selectCurrentViewContext,
} from 'reducers/viewDefinitions';
import { Field } from 'types/Listing';
import useListingViewHelpers from 'utils/hooks/useListingViewHelpers';
import useNavigate from 'utils/hooks/useNavigate';
import useTenantInfo from 'utils/hooks/useTenantInfo';

const EmergencyLights = () => {
  const dispatch = useDispatch();
  const viewDefinitions: ViewDefinition[] = useSelector((state: any) =>
    selectEmeredViewDefinitions(state)
  );
  const currentViewContext: ViewContext = useSelector((state: any) =>
    selectCurrentViewContext(state)
  );
  const fields: Field[] = useSelector((state: any) => selectEmeredViewFields(state));
  const { goTo } = useNavigate();
  const { viewId } = useParams<any>();
  const selectedView: ViewDefinition = useSelector((state: any) => selectSelectedView(state));
  const tenantInfo = useTenantInfo();

  const {
    setDefaultContext,
    setListingViewContext,
    refreshViewData,
    updateSelectedView,
    updateFilters,
    updateViewColumns,
  } = useListingViewHelpers();

  useEffect(() => {
    //This is messy because of the way the routing has been setup, there are two cases to handle.
    const defaultRenderConditionA =
      currentViewContext?.page !== 'EmergencyLights' &&
      viewDefinitions &&
      viewDefinitions.length > 0;
    const defaultRenderConditionB =
      !viewId &&
      currentViewContext?.page === 'EmergencyLights' &&
      viewDefinitions &&
      viewDefinitions.length > 0;

    if (defaultRenderConditionA || defaultRenderConditionB) {
      const defaultView = viewDefinitions.find(
        (viewDef) => viewDef.label === 'Emergency Lights' && viewDef.builtIn
      );
      const defaultViewId = viewId ? viewId : defaultView?.viewId;
      if (defaultViewId && tenantInfo && tenantInfo.id !== '') {
        setDefaultContext({
          tenantId: tenantInfo.id,
          page: 'EmergencyLights',
          viewId: defaultViewId,
        });
      }
    }

    return () => {
      dispatch(setStoredView());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (viewDefinitions && viewDefinitions.length > 0) {
      if (selectedView && viewId && selectedView.viewId !== viewId) {
        setListingViewContext({
          tenantId: tenantInfo.id,
          page: 'EmergencyLights',
          viewId,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId]);

  const handleRowClick = (d) => {
    goTo('EmergencyLights', '', d.deviceId);
  };

  return (
    <>
      {viewDefinitions.length > 0 && selectedView && (
        <ListingView
          availableViews={viewDefinitions}
          availableFields={fields}
          rowAction={handleRowClick}
          route={'EmergencyLights'}
          refreshViewData={refreshViewData}
          updateSelectedView={updateSelectedView}
          updateFilters={updateFilters}
          updateViewColumns={updateViewColumns}
        />
      )}
    </>
  );
};

export default EmergencyLights;

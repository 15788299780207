import moment from 'moment';
import { ManagedServiceLicence } from '../types';

export type ManagedServiceLicenceTransformed = ManagedServiceLicence & {
  id: string;
  quantity: number;
  status: string;
};

export const transManagedServiceDataToRows = ({
  licences = [],
}: {
  licences: ManagedServiceLicence[];
}) =>
  licences
    ?.map((licence, licenceIndex) => ({
      ...licence,
      id: licenceIndex.toString(),
      start: licence.start ? moment.utc(licence.start) : null,
      end: licence.end ? moment.utc(licence.end) : null,
    }))
    .reduce(
      (acc, licence) => [
        ...acc,
        {
          ...licence,
          status: 'Assigned',
          quantity: licence.assigned,
        } as ManagedServiceLicenceTransformed,
        {
          ...licence,
          status: 'Unassigned',
          quantity: licence.total - licence.assigned,
          id: `${licence.id}duplicate`,
        } as ManagedServiceLicenceTransformed,
      ],
      [] as ManagedServiceLicenceTransformed[]
    );

import { ApiStatuses } from 'types';

interface Status {
  status: ApiStatuses;
  error: string | null;
}

export interface GenericState<T> {
  data: T;
  statuses: {
    [key: string]: Status;
  };
}

interface GetReduxApiDataArgs<T> {
  state: GenericState<T>;
  actionName: string;
}

interface ReduxApiData<T> {
  data: T;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: string | null;
}

export const getReduxApiData = <T>({
  state,
  actionName,
}: GetReduxApiDataArgs<T>): ReduxApiData<T> => ({
    data: state.data,
    isLoading: state.statuses[actionName].status === ApiStatuses.Loading,
    isSuccess: state.statuses[actionName].status === ApiStatuses.Success,
    isError: state.statuses[actionName].status === ApiStatuses.Failed,
    error: state.statuses[actionName].error,
  });

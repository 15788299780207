import React from 'react';

import { BulkDeviceDetails } from 'components/DeviceActions/DeviceActionProps';
import { BulkActionType } from '../../actions';
import BulkAddTags from './BulkAddTags';
import BulkFlickTest from './BulkFlickTest';
import BulkJoinNetwork from './BulkJoinNetwork';
import BulkLeaveNetwork from './BulkLeaveNetwork';
import BulkRemoveTags from './BulkRemoveTags/BulkRemoveTags';
import BulkUninstall from './BulkUninstall';
import { BulkUpdateAccessRightsTags } from './BulkUpdateAccessRightsTags';

interface BulkActionSelectorProps {
  actionType: BulkActionType;
  deviceIds?: string[];
  handleResetAction(): void;
  deviceDetails: BulkDeviceDetails;
}

const BulkActionSelector = ({
  actionType,
  handleResetAction,
  deviceIds = [],
  deviceDetails,
}: BulkActionSelectorProps) => (
  <>
    {(() => {
      switch (actionType) {
        case 'bulkAddTags':
          return <BulkAddTags deviceIds={deviceIds} handleResetAction={handleResetAction} />;
        case 'bulkAddAccessRightsTags':
          return (
            <BulkUpdateAccessRightsTags
              deviceDetails={deviceDetails}
              handleResetAction={handleResetAction}
            />
          );
        case 'bulkRemoveAccessRightsTags':
          return (
            <BulkUpdateAccessRightsTags
              deviceDetails={deviceDetails}
              handleResetAction={handleResetAction}
              remove
            />
          );
        case 'bulkRemoveTags':
          return <BulkRemoveTags deviceIds={deviceIds} handleResetAction={handleResetAction} />;
        case 'bulkJoinNetwork':
          return <BulkJoinNetwork deviceIds={deviceIds} handleResetAction={handleResetAction} />;
        case 'bulkLeaveNetwork':
          return <BulkLeaveNetwork deviceIds={deviceIds} handleResetAction={handleResetAction} />;
        case 'bulkUninstall':
          return <BulkUninstall deviceIds={deviceIds} handleResetAction={handleResetAction} />;
        case 'bulkFlickTest':
          return <BulkFlickTest deviceIds={deviceIds} handleResetAction={handleResetAction} />;
        case 'none':
        default:
          return null;
      }
    })()}
  </>
);

export default BulkActionSelector;

import { ApiStatuses } from 'types';
import { FeaturesState } from '../types';

export const initialState: FeaturesState = {
  data: [],
  statuses: {
    fetchFeatures: { status: ApiStatuses.Init, error: null },
  },
};

export const ERROR_MESSAGES = {
  fetchFeatures: 'An error occurred while fetching features.',
};

import React from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import styled from 'styled-components';

const Wrapper = styled.span`
  display: flex;
  height: 4rem;
  width: 4rem;

  margin-right: 2rem;

  padding: 0.5rem 0.6rem 0.5rem 0.4rem;

  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.white};

  transition: all 0.3s ease-in-out;

  & > svg {
    height: 100%;
    width: 100%;
    fill: currentColor;
  }

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.primary};
  }
`;

const BackButton = ({ handleClick }) => (
  <Wrapper onClick={handleClick}>
    <SvgIcon type="angle-left" />
  </Wrapper>
);

export default BackButton;

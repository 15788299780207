import React, { useEffect, useRef, useLayoutEffect } from 'react';

import styled from 'styled-components';

const ImageWrapper = styled('div')<{ rotation: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.gradient};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
  object-fit: none;

  & div {
    transform: rotate(${(props) => props.rotation}deg);
    background-color: #e6e6e6;
    opacity: 0.5;
    position: absolute;
    width: 10rem;

    & span {
      display: inline-block;
      width: 25%;
      height: 10px;
      border-radius: 50px;
      position: absolute;
      cursor: pointer;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    user-select: none;
    display: block;
    transition: border 0.2s ease-in;
  }
`;

const ImageOverlay = ({
  dimensions,
  image,
  rotation,
  setDrag,
  calcLatLong,
  lat,
  lng,
  mapSettings,
}) => {
  let initX = 0,
    initY = 0;
  let isRepos = false;
  const imageRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<HTMLDivElement>(null);

  const onTouchStart = (e) => {
    setDrag(false);
    isRepos = true;
    initX = e.touches[0].clientX;
    initY = e.touches[0].clientY;
  };

  const onTouchEnd = () => {
    setDrag(true);
    if (isRepos) {
      isRepos = false;
      calcLatLong(
        parseInt(markerRef.current!.style.left!.slice(0, -2)),
        parseInt(markerRef.current!.style.top!.slice(0, -2))
      );
      markerRef.current!.style.left = '';
      markerRef.current!.style.top = '';
    }
  };

  const onTouchMove = (e) => {
    if (isRepos) {
      markerRef.current!.style.left = `${e.touches[0].clientX - initX}px`;
      markerRef.current!.style.top = `${e.touches[0].clientY - initY}px`;
    }
  };

  useLayoutEffect(() => {
    if (imageRef.current) {
      imageRef.current.style.height = `${dimensions.height}px`;
      imageRef.current.style.width = `${dimensions.width}px`;
      imageRef.current.style.left = `-${dimensions.width / 2}px`;
      imageRef.current.style.top = `-${dimensions.height / 2}px`;
    }
  });

  useEffect(() => {
    document.addEventListener('touchend', onTouchEnd);
    document.addEventListener('touchmove', onTouchMove);

    return () => {
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchmove', onTouchMove);
    };
    //TODO REFACTOR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, mapSettings]);

  return (
    <ImageWrapper ref={markerRef} rotation={rotation} onTouchStart={(e) => onTouchStart(e)}>
      <div className="hello" ref={imageRef}>
        <img src={image} alt="overlay" />
      </div>
    </ImageWrapper>
  );
};

export default React.memo(ImageOverlay);

import React, { useState } from 'react';

import GetAppIcon from '@mui/icons-material/GetApp';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, CircularProgress } from '@mui/material';

import { MONTHS_LIST_LONG } from '../../constants/reportCard';
import { ReportCardProps } from '../../types';
import { useReportDates, useDownloadReport } from '../../hooks';
import { ReportCustomizationDialog } from '..';
import { ReportCardWrapper } from './ReportCard.styles';

export const ReportCard: React.FC<ReportCardProps> = ({
  title,
  description,
  customizable,
  tenantID,
  api,
}) => {
  const [open, setOpen] = useState(false);
  const { reportDates } = useReportDates();

  const { handleDownloadReport, isLoading } = useDownloadReport({
    tenantID,
    reportDates,
    ...api,
  });

  return (
    <ReportCardWrapper>
      <div className="card__top">
        <h4 className="card__title">{title}</h4>
        <p className="card__description">{description}</p>
      </div>
      <div className="card__bottom">
        {customizable && (
          <Button
            onClick={() => setOpen(true)}
            fullWidth
            disabled={isLoading}
            variant="contained"
            startIcon={<SettingsIcon />}
          >
            Customize
          </Button>
        )}
        <Button
          onClick={handleDownloadReport}
          fullWidth
          disabled={isLoading}
          variant="contained"
          startIcon={isLoading ? <CircularProgress size={20} /> : <GetAppIcon />}
        >
          {customizable
            ? ` Download for ${MONTHS_LIST_LONG[reportDates.month]} ${reportDates.year}`
            : 'Download'}
        </Button>
      </div>
      <ReportCustomizationDialog
        tenantID={tenantID}
        api={api}
        open={open}
        onClose={() => setOpen(false)}
      />
    </ReportCardWrapper>
  );
};

import React, { useCallback, useState } from 'react';

import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { Moment } from 'moment';

import { LoadingOverlay } from 'components/atoms';
import { CardExtended, EventProps } from 'types/Telemetry';
import { CardContainer, ErrorDataContainer, LoadingWrapper } from 'styles/commonStyles/telemetry';
import EventTimeline from '../EventTimeline/EventTimeline';
import { formatEventDescriptionDates } from './utils/formatEventDescriptionDates';
import {
  CardHeader,
  CardTitle,
  DetailsContainer,
  EventDescription,
  EventDetails,
  EventItem,
  EventList,
  EventListContainer,
  EventMarker,
  EventMeta,
  GraphWrapper,
  NoEventsPlaceholder,
} from './EventChart.styles';

type DateRange = Moment[];

type EventChartProps = {
  chart: CardExtended;
  dateRange: DateRange;
  isLoading: boolean;
};

export const EventChart: React.FC<EventChartProps> = ({ chart, dateRange, isLoading }) => {
  const [selectedEventList, setSelectedEventList] = useState<Array<EventProps>>([]);

  const updateSelectedEventList = (eventList: Array<EventProps>): void => {
    setSelectedEventList(eventList);
  };

  const eventDescriptionDates = useCallback((event) => formatEventDescriptionDates(event), []);

  const renderEventTimeline = useCallback(
    ({ width, height }) => {
      if (width > 0 && height > 0) {
        if (chart.eventSeries) {
          return (
            <EventTimeline
              graphWidth={width}
              graphHeight={height}
              eventSeries={chart?.eventSeries}
              dateRange={dateRange}
              selectedEventList={selectedEventList}
              updateSelectedEventList={updateSelectedEventList}
            />
          );
        } else {
          return <ErrorDataContainer>Something went wrong, please retry.</ErrorDataContainer>;
        }
      }
    },
    [chart.eventSeries, dateRange, selectedEventList]
  );

  return (
    <CardContainer gridWidth={chart.gridWidth}>
      <CardHeader>
        <CardTitle>{chart.title}</CardTitle>
        <div>{chart.chartLabel}</div>
      </CardHeader>
      <GraphWrapper>
        {isLoading ? (
          <LoadingWrapper data-testid="loading-overlay">
            <LoadingOverlay />
          </LoadingWrapper>
        ) : (
          <ParentSize>{renderEventTimeline}</ParentSize>
        )}
      </GraphWrapper>
      {!isLoading && (
        <EventListContainer>
          <EventList>
            {selectedEventList.length > 0 ? (
              selectedEventList.map((event, i) => (
                <EventItem key={`${event.category}-${i}`}>
                  <EventMarker severity={event.severity} />

                  <DetailsContainer>
                    <EventDetails>
                      <EventMeta>{eventDescriptionDates(event)}</EventMeta>
                      <EventMeta style={{ textAlign: 'right' }}>{event.cardTitle}</EventMeta>
                    </EventDetails>
                    <EventDescription title={event.description}>
                      {event.description}
                    </EventDescription>
                  </DetailsContainer>
                </EventItem>
              ))
            ) : (
              <NoEventsPlaceholder>
                <span>No events selected</span>
              </NoEventsPlaceholder>
            )}
          </EventList>
        </EventListContainer>
      )}
    </CardContainer>
  );
};

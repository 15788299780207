import React from 'react';

import { getInputState } from 'utils/helpers';
import useValidateForm from 'utils/hooks/useValidateForm';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select } from '@mui/material';

import Icon from './Icon';
import TableValidationInput from './TableValidationInput';

const EditRow = ({ rowData, rowActions, properties, handleOnChange, validationSchema }) => {
  const { validateInput, validationState, validateForm } = useValidateForm(
    rowData,
    validationSchema
  );
  const { handleUpdateClick, handleCancelClick } = rowActions;

  const _handleOnChange = (value, name) => {
    validateInput(value, name);
    handleOnChange(value, name);
  };

  const _handleUpdateClick = () => {
    const isValid = validateForm(true);
    if (isValid) handleUpdateClick();
  };

  return (
    <tr>
      {properties.map(({ property, type, ...rest }, i) => {
        if (type) {
          return type === 'dropdown' ? (
            <td key={i}>
              <div style={{ width: '80%' }}>
                <Select
                  value={rowData[property]}
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={({ target }) => handleOnChange(target.value, property)}
                  fullWidth
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {rest.options.map((option, optionIndex) => (
                    <MenuItem key={optionIndex.toString()} value={option.value || option}>
                      {option.display || option}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </td>
          ) : (
            <td key={i}>
              <TableValidationInput
                type={type}
                errMsg={validationState[property].err}
                inputData={{
                  value: rowData[property],
                  state: getInputState(validationState[property]),
                  name: property,
                  onChange: _handleOnChange,
                  autoFocus: i === 0,
                  handleFocus: (e) => e.target.select(),
                  ...rest,
                }}
              />
            </td>
          );
        }

        return <td key={i}>{rowData[property]}</td>;
      })}
      <td>
        <Icon type="check" onClick={() => _handleUpdateClick()} />
        <Icon type="close" onClick={() => handleCancelClick()} />
      </td>
    </tr>
  );
};

export default EditRow;

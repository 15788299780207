import { createSelector } from 'reselect';
import { handleReduxApiReq } from 'utils/ApiRequestHelpers';

const FETCH_TENANTS_REQUEST = 'FETCH_TENANTS_REQUEST';
const FETCH_TENANTS_ERROR = 'FETCH_TENANTS_ERROR';
const FETCH_TENANTS_SUCCESS = 'FETCH_TENANTS_SUCCESS';

export const tenantsTypes = {
  FETCH_TENANTS_REQUEST,
  FETCH_TENANTS_ERROR,
  FETCH_TENANTS_SUCCESS,
};

const fetchTenantsRequest = () => ({
  type: FETCH_TENANTS_REQUEST,
});

const fetchTenantsSuccess = (tenants) => ({
  type: FETCH_TENANTS_SUCCESS,
  payload: { tenants },
});

const fetchTenantsError = (error) => ({
  type: FETCH_TENANTS_ERROR,
  payload: { error },
});

export const fetchTenantsAction = async (dispatch) => {
  dispatch(fetchTenantsRequest());
  try {
    const reqBody = {
      tenant: '',
    };
    const data = await handleReduxApiReq('GetTenants', reqBody, 'POST');

    dispatch(fetchTenantsSuccess(data));
  } catch (e) {
    dispatch(fetchTenantsError(e));
  }
};

export const fetchTenants = () => async (dispatch) => {
  fetchTenantsAction(dispatch);
};

const initialState = {
  tenants: [],
};

export const tenantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case tenantsTypes.FETCH_TENANTS_SUCCESS:
      return {
        ...state,
        tenants: action.payload.tenants,
      };

    default:
      return state;
  }
};

const getTenants = (state: any) => state.tenants.tenants;
export const selectTenant = (tenant) =>
  createSelector(getTenants, (tenants) => tenants.find((t) => t.id === tenant));

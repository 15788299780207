import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import { selectIsLoading } from 'reducers/loading/selectors';
import { fetchTenants } from 'reducers/tenants';
import LoadingOverlay from '../../atoms/LoadingOverlay';
import { Container, TenantsWrapper } from './TenantSelect.styles';

type TenantSelectProps = {
  handleTenantSelect: (tenantName: string, isSelect: boolean) => void;
  tenants: { name: string; role: string; tags: { id: string }[] }[];
};

const TenantSelect: React.FC<TenantSelectProps> = ({ tenants, handleTenantSelect }) => {
  const dispatch = useDispatch();
  const tenantsStore = useSelector((state: any) => state.tenants.tenants);
  const isFetching = useSelector((state: any) => selectIsLoading(['FETCH_TENANTS'])(state));

  const [searchTerm, setSearchTerm] = useState('');

  const displayClients = tenantsStore && tenantsStore.length > 0;

  const getTenantName = useMemo<(tenantId: string) => string | null>(
    () => (tenantId) =>
      tenantsStore.find((x) => x.id === tenantId)
        ? tenantsStore.find((x) => x.id === tenantId).name
        : null,
  [tenantsStore]
  );

  const handleSearch = ({ target }) => setSearchTerm(target.value);

  const filteredTenants = useMemo(
    () => {
      const tenantsInStore = tenants.filter(tenant => getTenantName(tenant.name) !== null);

      return searchTerm
        ? tenantsInStore.filter((tenant) => getTenantName(tenant.name)!.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : tenantsInStore;
    },
    [searchTerm, tenants, getTenantName]
  );

  const sortedTenants = useMemo(() => {
    if (!displayClients) return [];

    return filteredTenants.sort((a, b) => {
      const aName = getTenantName(a.name) || a.name;
      const bName = getTenantName(b.name) || b.name;

      return aName.localeCompare(bName);
    });
  }, [displayClients, filteredTenants, getTenantName]);

  useEffect(() => {
    if (tenantsStore.length === 0) {
      dispatch(fetchTenants());
    }
  }, [dispatch, tenantsStore.length]);

  return (
    <Container>
      <TenantsWrapper isFetching={isFetching}>
        <h2 id="tenantSelect" className="clients-select__header">
          Select Client
        </h2>
        {displayClients && (
          <div className="clients-select__search">
            <TextField
              className="clients-select__search-field"
              aria-label="search-client-input"
              label="Search Client"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        )}
        <ul>
          {isFetching && <LoadingOverlay />}
          {sortedTenants.map((tenant) => (
            <li
              key={tenant.name}
              role="presentation"
              onClick={() => handleTenantSelect(tenant.name, true)}
            >
              <span>{getTenantName(tenant.name)}</span>
            </li>
          ))}
          {!displayClients &&
            [1, 2, 3, 4, 5, 6].map((p) => (
              <li key={p}>
                <span>{`Tenant ${p}`}</span>
              </li>
            ))}
        </ul>
      </TenantsWrapper>
    </Container>
  );
};

export default TenantSelect;

import { useState } from 'react';

import { Documents } from 'types';
import {
  useDeleteFetchData,
  useGetFetchData,
  usePutFetchData,
  useSelectedTenant,
} from 'utils/hooks';

type UseResidenceTableMarkDownDialog = {
  userId: string;
};

export const useResidenceTableMarkDownDialog = ({ userId }: UseResidenceTableMarkDownDialog) => {
  const tenant = useSelectedTenant();
  const [docsData, setDocsData] = useState<Documents<unknown>>();

  const [markdownText, setMarkdownText] = useState('');

  const handleMarkdownChange = ({ target }) => {
    setMarkdownText(target.value);
  };

  const docs = useGetFetchData({
    url: `${process.env.REACT_APP_API_URL_V2}`,
    refetchOnWindowFocus: false,
    staleTime: 0,
    enableErrorHandler: true,
    onSuccess: (data) => {
      setDocsData(data?.results?.[0]);
    },
    query: `tenants/${tenant}/documents?userId=${userId}&contentType=text/markdown&documentType=SavingsAndTips`,
  });

  const markDownData = useGetFetchData({
    url: `${process.env.REACT_APP_API_URL_V2}`,
    query: `${docsData?.resourceLocation}`,
    enabled: !!docsData?.resourceLocation,
    staleTime: 0,
    onSuccess: (data) => {
      setMarkdownText(data);
    },
    enableErrorHandler: true,
  });

  const deleteMutation = useDeleteFetchData({
    url: `${process.env.REACT_APP_API_URL_V2}tenants/${tenant}/documents/${docsData?.id}`,
    enableErrorHandler: true,
  });

  const updateMutation = usePutFetchData({
    url: `${process.env.REACT_APP_API_URL_V2}tenants/${tenant}/documents`,
    contentType: 'multipart/form-data',
    enableErrorHandler: true,
  });

  const isContentInitial = (markDownData.data || '') === markdownText;
  const isContentLoading = docs.isLoading || markDownData.isLoading;

  return {
    handleMarkdownChange,
    deleteMutation,
    updateMutation,
    isContentLoading,
    isContentInitial,
    markdownText,
    docsData,
  };
};

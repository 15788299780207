import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import useAuth from 'utils/hooks/useAuth';
import useSelectedTenant from 'utils/hooks/useSelectedTenant';

const FileInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
`;

const DefaultStyles = `
    width: 16rem;
    display: inline-block;
    padding: .75rem 1.5rem;
    border-radius: 30px;
    transition: all .3s ease-in-out;
    text-align: center;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.3);

    &:hover {
        cursor: pointer;
    }
`;

const FileLabel = styled.label`
  ${DefaultStyles}
  background-color: ${(props) => props.theme.default};
  color: ${(props) => props.theme.white};
  font-size: ${(props) => props.theme.textSmall};
  border: 2px solid ${(props) => props.theme.default};

  &:hover {
    background-color: ${(props) => props.theme.blue};
    border: 2px solid ${(props) => props.theme.blue};
  }
`;

const UploadButton = styled.span<{ progress: number }>`
  ${DefaultStyles}
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.success};
  position: relative;
  font-size: ${(props) => props.theme.textSmall};
  z-index: 6;

  &:hover {
    border: 2px solid ${(props) => props.theme.darkGreen};

    &::before {
      background-color: ${(props) => props.theme.darkGreen};
    }
  }

  &::before {
    position: absolute;
    height: 100%;
    min-width: ${(props) => (props.progress === 0 ? '0%' : '20%')};
    mix-blend-mode: color-dodge;
    width: ${(props) => props.progress}%;
    background-color: ${(props) => props.theme.success};
    content: '';
    left: 0;
    bottom: 0;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
  }
`;

type UploadState = 'notstarted' | 'pending' | 'done' | 'error';

const FileUpload = ({ url, data, children, onUpload }) => {
  const { getTokenSilently } = useAuth();
  const [token, setToken] = useState();
  const tenant = useSelectedTenant();
  const [file, setFile] = useState();
  const [uploadState, setUploadState] = useState<UploadState>('notstarted');
  const [progress, setProgress] = useState(0);

  const hasFile = !!file;

  useEffect(() => {
    const fetchToken = async () => setToken(await getTokenSilently());
    fetchToken();
  }, [getTokenSilently]);

  useEffect(() => {
    if (uploadState === 'done') {
      onUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadState]);

  const handleFileUpload = (url, file) =>
    new Promise((resolve, reject) => {
      const form = new FormData();
      form.append('file', file);
      form.append('tenant', tenant);
      Object.keys(data).forEach((k) => form.append(k, data[k]));

      const ajax = new XMLHttpRequest();

      ajax.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          setProgress((event.loaded / event.total) * 100);
          setUploadState('pending');
        }
      });

      ajax.upload.addEventListener('load', () => {
        setUploadState('done');
        setProgress(100);
        resolve(ajax.response);
      });

      ajax.addEventListener('load', () => {
        if (ajax.status !== 200) {
          setUploadState('error');
          setProgress(0);
          reject(ajax.response);
        }
      });

      ajax.addEventListener('error', () => {
        setUploadState('error');
        setProgress(0);
        reject(ajax.response);
      });

      ajax.upload.addEventListener('error', () => {
        setUploadState('error');
        setProgress(0);
        reject(ajax.response);
      });

      ajax.open('POST', process.env.REACT_APP_APIURL + url);
      ajax.setRequestHeader('Authorization', `Bearer ${token}`);

      ajax.send(form);
    });

  const handleFileChange = (e) => {
    // TODO validate file type
    setFile(e.target.files[0]);
  };

  const handleUploadClick = async () => {
    if (uploadState !== 'done') {
      await handleFileUpload(url, file);
    }
  };

  const UploadText = () => {
    switch (uploadState) {
      case 'done':
        return 'Complete';
      case 'pending':
        return 'Uploading...';
      case 'notstarted':
        return 'Upload';
      case 'error':
        return 'Retry';
      default:
        return '';
    }
  };

  return (
    <>
      {hasFile ? (
        <UploadButton onClick={handleUploadClick} progress={progress}>
          {UploadText()}
        </UploadButton>
      ) : (
        <FileLabel>
          {children}
          <FileInput type="file" name="fileupload" id="fileupload" onChange={handleFileChange} />
        </FileLabel>
      )}
    </>
  );
};

export default FileUpload;

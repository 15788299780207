import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 2rem;
  position: relative;
  background-color: ${(props) => props.theme.primaryLight};
  font-size: ${(props) => props.theme.textSmall};
  font-weight: 500;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  width: fit-content;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${(props) => props.theme.primary};
    cursor: pointer;
  }
`;

export const Button = styled.span`
  display: inline-block;
  width: 100%;
  color: inherit;
  padding: 0.75rem 2rem;
  color: ${(props) => props.theme.white};
  text-align: center;
`;

import React from 'react';

import { DashboardContentProps } from 'components/DashboardView/constants/dashboard';
import { selectIsLoading } from 'reducers/loading/selectors';
import { useAppSelector } from 'utils/hooks';
import { selectDeviceSummary } from 'reducers/tenantInfo';
import { Widget } from '../Widget/Widget';

const VericonWidget: React.FC<DashboardContentProps> = (widgetProps) => {
  const deviceSummary = useAppSelector((state) =>
    selectDeviceSummary(widgetProps.deviceType)(state)
  );
  const isFetching = useAppSelector((state) =>
    selectIsLoading(['FETCH_DEVICES', 'FETCH_BUILDINGINFO', 'FETCH_DASHBOARD_DATA'])(state)
  );

  return (
    <Widget
      deviceSummary={deviceSummary}
      isFailedToLoad={!deviceSummary && !isFetching}
      isFetching={isFetching}
      {...widgetProps}
    />
  );
};

export default VericonWidget;

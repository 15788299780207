import React, { Fragment } from 'react';
import { Moment } from 'moment';

import { CardExtended, ChartNames } from 'types/Telemetry';
import { EventChart } from '../EventChart/EventChart';
import { LineChartCard } from '../LineChartCard/LineChartCard';
import { NumericChartCard } from '../NumericChartCard/NumericChartCard';
import { ChartCardGrid } from './Charts.styles';

type ChartsProps = {
  charts: CardExtended[];
  dataRange: Moment[];
  chartsStatus: {
    [key: string]: { loading: boolean; error: boolean; success: boolean };
  };
};

export const Charts: React.FC<ChartsProps> = ({ charts, dataRange, chartsStatus }) => (
  <ChartCardGrid>
    {charts?.map((chart, chartIndex) => (
      <Fragment key={chartIndex.toString()}>
        {chart.type === ChartNames.EventTimeline && (
          <EventChart
            chart={chart}
            isLoading={chartsStatus[chartIndex.toString()].loading}
            dateRange={dataRange}
          />
        )}
        {chart.type === ChartNames.Linear && (
          <LineChartCard
            chart={chart}
            isLoading={chartsStatus[chartIndex.toString()].loading}
            isSuccess={chartsStatus[chartIndex.toString()].success}
            isError={chartsStatus[chartIndex.toString()].error}
            dateRange={dataRange}
          />
        )}
        {chart.type === ChartNames.Numeric && (
          <NumericChartCard
            chart={chart}
            isLoading={chartsStatus[chartIndex.toString()].loading}
            isSuccess={chartsStatus[chartIndex.toString()].success}
            isError={chartsStatus[chartIndex.toString()].error}
          />
        )}
      </Fragment>
    ))}
  </ChartCardGrid>
);

import React, { useState } from 'react';

import { DeviceActionMainDialog } from 'components/DeviceActions/Dialogs';
import styled from 'styled-components';
import { handleFormReq } from 'utils/ApiRequestHelpers';
import useAlert from 'utils/hooks/useAlert';

import Spinner from '../../../atoms/Spinner';
import { BulkActionProps } from '../../DeviceActionProps';

const SpinnerWrapper = styled.div`
  height: 30px;
  width: 30px;
`;
const SendingFlickTestMessageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;

const BulkFlickTest = ({ deviceIds, handleResetAction }: BulkActionProps) => {
  const { errorAlert, successAlert, warningAlert } = useAlert();
  const [sendingFlickTests, setSendingFlickTests] = useState(false);

  const sendFlickTest = async (deviceId) => {
    try {
      const form = new FormData();
      form.append('deviceId', deviceId);
      const flickResponse: FlickResponse = await handleFormReq('RunT0Test', form, 'POST');

      return { deviceId, flickResponse };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Request error for deviceID: ${deviceId}`);
      throw error;
    }
  };
  interface FlickResponse {
    status: string;
    dateTime: string;
  }

  const triggerBulkFlickTest = async () => {
    try {
      setSendingFlickTests(true);
      const promiseAllResp: { deviceId: number; flickResponse: FlickResponse }[] =
        await Promise.all(deviceIds.map((id) => sendFlickTest(id)));
      if (promiseAllResp) {
        let countFailed = 0;
        promiseAllResp.forEach(({ flickResponse }) => flickResponse.status || countFailed++);
        if (countFailed > 0) {
          if (promiseAllResp.length > countFailed) {
            warningAlert(
              `Something went wrong, only ${
                promiseAllResp.length - countFailed
              } flick tests out of ${promiseAllResp.length} were successful.`
            );
          } else if (promiseAllResp.length === countFailed) {
            errorAlert('Something went wrong, no flick tests were successful.');
          }
        } else {
          successAlert('Flick tests for all selected devices were sent successfully.');
        }
      }
    } catch (error) {
      errorAlert('Something has gone wrong, please try again.');
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setSendingFlickTests(false);
      handleResetAction();
    }
  };

  return (
    <DeviceActionMainDialog
      title="Bulk Flick Test"
      handleResetAction={handleResetAction}
      handleSubmit={triggerBulkFlickTest}
      hideButtons={sendingFlickTests}
    >
      {sendingFlickTests ? (
        <SendingFlickTestMessageBox>
          <span>{`Sending ${deviceIds.length} flick tests, please wait..`}</span>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        </SendingFlickTestMessageBox>
      ) : (
        <SendingFlickTestMessageBox>
          <span>
            {`This will perform a flick test for ${deviceIds.length} selected device${
              deviceIds.length > 1 ? 's' : ''
            }`}
          </span>
        </SendingFlickTestMessageBox>
      )}
    </DeviceActionMainDialog>
  );
};

export default BulkFlickTest;

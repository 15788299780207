export enum UserFormField {
  Name = 'name',
  Email = 'email',
  Role = 'role',
  AccessRightsTags = 'accessRightsTag',
}

export type FormStateType = {
  [UserFormField.Name]: string;
  [UserFormField.Email]: string;
  [UserFormField.Role]: string;
  [UserFormField.AccessRightsTags]: string;
};

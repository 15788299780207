import { IPhoneNumber } from 'components/atoms/PhoneNumberInput/CountryInput';
import { splitCountryCodeAndNumber } from 'utils/phoneNumberHelpers';
import { phone } from 'phone';

import { contains } from './helpers';

export const regExValidator = (regEx) => (v) => v ? regEx.test(String(v)) : true;
export const allowedValuesValidator = (allowedValues) => (v) =>
  v ? contains(v, allowedValues) : true;

export const isRequired = (v) => !!v || v === 0;
export const isNumber = (v) => (v ? !isNaN(v) : true);
export const isNotZero = (v) => v !== 0;
export const isLessThanOrEqualTo = (num) => (v) => v <= num;
export const isGreaterThanOrEqualTo = (num) => (v) => v >= num;
export const isValidCHMax = (num) => num >= 30;

export const maxLength = (num) => (v) => v ? v.length <= num : true;

export const isAlphaNum = regExValidator(/^[\w\s-]+$/);
export const isAlphaNumNWS = regExValidator(/^[\w-]+$/);
export const isEmail = regExValidator(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const isUnique = (xs: string[]) => (v: string) => !xs.includes(v);
export const isGcNumber = regExValidator(/^\d{2}-\d{3}-\d{2}$/);

export const isPhoneNumber = regExValidator(/^\+\d{1,4}\s\d{6,12}$/);

export const isValidPhoneValue = (completePhoneNumber: string): boolean => {
  const phoneNumber: IPhoneNumber = splitCountryCodeAndNumber(completePhoneNumber);
  const phoneNumberAfterCode = phoneNumber.subscriberNumber;
  if (phoneNumberAfterCode === '') {
    return true;
  } else {
    const formattedNumber = phone(completePhoneNumber);

    return formattedNumber.isValid;
  }
};

export const isInList = (xs) => (x) => x ? xs.includes(x) : true;

import styled from 'styled-components';

export const VoidFlaDialogStyles = styled.div`
  .dialog-header__title {
    color: ${(props) => props.theme.error};
    text-transform: uppercase;
    text-align: center;
    padding-right: 24px;
  }
`;

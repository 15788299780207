import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { countryDialCodes } from 'components/constants/countries';
import CountryDialCode from 'types/CountryDialCode';
import useClickAway from 'utils/hooks/useClickAway';

import SvgIcon from '../SvgIcon';

export interface IPhoneNumber {
  countryDialCode: CountryDialCode;
  subscriberNumber: string;
}

interface CountryInputProps {
  value: CountryDialCode;
  onChange(country: CountryDialCode): void;
  hidden?: boolean;
  disabled?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CountrySpan = styled.span`
  font-weight: 500;
`;

const Search = styled.input`
  opacity: 0;
  width: 1px;
  height: 1px;
  cursor: pointer;
`;

const CaratIcon = styled.span`
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;

  & > svg {
    height: 100%;
    width: 100%;

    fill: currentColor;
  }
`;

const CountryList = styled.ul`
  display: none;
  position: absolute;
  top: 3.5rem;
  left: 0;
  width: 100%;

  text-align: left;

  max-height: 20rem;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);

  z-index: 7;

  & li {
    background: ${({ theme }) => theme.white};
    padding: 0.5rem 1rem;

    &:hover,
    &.selected {
      cursor: pointer;
      background-color: ${(props) => props.theme.default};
    }
  }

  &.open {
    display: block;
  }
`;

const CountryInput = ({ value, onChange, hidden = false, disabled = false }: CountryInputProps) => {
  const input = useRef<HTMLInputElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);

  const [dialCodes, setDialCodes] = useState(countryDialCodes);
  const [filter, setFilter] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const filtered = countryDialCodes.filter(
      (v) =>
        v.id.toLowerCase().includes(filter.toLowerCase()) ||
        v.label.toLowerCase().includes(filter.toLowerCase())
    );
    setDialCodes(filtered);
  }, [filter]);

  useClickAway(wrapper, () => setIsOpen(false));

  const handleOnClick = () => {
    if (!disabled) {
      input.current?.focus();
      setIsOpen(!isOpen);
    }
  };

  return hidden ? null : (
    <Wrapper>
      <InnerWrapper onClick={handleOnClick} ref={wrapper}>
        <CountrySpan>{value.id}</CountrySpan>
        <Search
          ref={input}
          type="text"
          value={filter}
          onChange={({ target }) => setFilter(target.value)}
        />

        {!disabled && (
          <CaratIcon>
            <SvgIcon type="caret-down" />
          </CaratIcon>
        )}
      </InnerWrapper>
      <CountryList className={isOpen ? 'open' : ''}>
        {dialCodes.map((c) => (
          <li key={c.id} role="presentation" onClick={() => onChange(c)}>
            {c.label}
          </li>
        ))}
      </CountryList>
    </Wrapper>
  );
};

export default CountryInput;

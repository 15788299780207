import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown, Save as SaveIcon, Close as CloseIcon } from '@mui/icons-material';

import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { clearUsersStatus } from 'reducers/users';
import { isEmail } from 'utils/inputValidation';
import { useForm } from 'utils/hooks';
import { DialogSuccessMessage, InputEndIcon } from 'components/atoms';
import { Roles, UserRole } from 'types/roles';
import { User } from 'reducers/users/types';
import { FormStateType, UserFormField } from '../../types';
import { ACCESS_RIGHTS_TAG_RESET } from '../../constants/users';
import { UserDialogWrapper } from './UserDialog.styles';

type UserDialogProps = {
  onClose: () => void;
  onSubmit: ({ data, id }) => void;
  user?: User;
  title: string;
  successMessage: string;
  status: { isLoading: boolean; isSuccess: boolean; isError: boolean; errors: (string | null)[] };
  isEdit?: boolean;
};

const roles = Object.keys(Roles);

const validationState = {
  name: { fn: (v) => !!v.length, errorText: '', required: true },
  email: { fn: isEmail, errorText: 'Invalid email address format', required: true },
  role: { fn: (v) => !!v.length, errorText: '', required: true },
  accessRightsTag: { required: false },
};

export const UserDialog: React.FC<UserDialogProps> = ({
  onClose,
  user,
  title,
  successMessage,
  onSubmit,
  status,
  isEdit,
}) => {
  const accessRightsTags: AccessRightsTag[] = useSelector(
    (state: any) => state.accessRightsTags.accessRightsTags
  );

  const { isLoading, isSuccess, isError, errors: apiErrors } = status;
  const dispatch = useDispatch();

  const initialFormState: FormStateType = {
    name: user?.name || '',
    email: user?.email || '',
    role: user?.role || '',
    accessRightsTag: user?.accessRightsTag || '',
  };

  const { formState, isValid, errors, focused, registerField } = useForm({
    initialFormState,
    validationState,
  });

  const handleSubmit = () => onSubmit({ data: formState, id: user?.id });

  const isSubmitDisabled = !isValid || isEqual(initialFormState, formState) || isLoading;

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      dispatch(clearUsersStatus({ statusKey: 'createUser' }));
      dispatch(clearUsersStatus({ statusKey: 'updateUserDetails' }));
      dispatch(clearUsersStatus({ statusKey: 'updateUserTag' }));
      dispatch(clearUsersStatus({ statusKey: 'updateUserRole' }));
    });
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogSuccessMessage message={successMessage} onClose={handleClose} showMessage={isSuccess}>
        <UserDialogWrapper>
          <DialogTitle>
            <Typography fontSize="h4" fontWeight={500} sx={{ pr: 3 }}>
              {title}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 6,
                top: 6,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </DialogTitle>
          <DialogContent className="user-dialog__content">
            <TextField
              {...registerField(UserFormField.Name)}
              error={!!errors.name}
              helperText={!focused.name && errors.name}
              label="Name"
              variant="outlined"
              required
              fullWidth
              disabled={isLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InputEndIcon
                      isError={!!errors.name}
                      value={formState.name}
                      isNotFocused={!focused.name}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              {...registerField(UserFormField.Email)}
              helperText={!focused.email && errors.email}
              error={!!errors.email}
              label="Email"
              variant="outlined"
              required
              fullWidth
              disabled={isLoading || isEdit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InputEndIcon
                      isError={!!errors.email}
                      value={formState.email}
                      isNotFocused={!focused.email}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl required fullWidth disabled={isLoading} error={!!errors.role}>
              <InputLabel id="user-dialog-role-select">Role</InputLabel>
              <Select
                {...registerField(UserFormField.Role)}
                labelId="user-dialog-role-select"
                IconComponent={KeyboardArrowDown}
                label="Role"
                fullWidth
              >
                {roles.map((role, roleIndex) => (
                  <MenuItem
                    sx={{
                      ...(role === UserRole.Developer || role === UserRole.Admin
                        ? { display: 'none' }
                        : {}),
                    }}
                    key={roleIndex.toString()}
                    value={role}
                  >
                    {role}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{!focused.role && errors.role}</FormHelperText>
            </FormControl>
            {!!accessRightsTags.length && (
              <FormControl className="user-dialog__accessRightsTag" fullWidth disabled={isLoading}>
                <InputLabel id="user-dialog-accessRightsTag-select">Access Rights Tag</InputLabel>
                <Select
                  {...registerField(UserFormField.AccessRightsTags)}
                  labelId="user-dialog-accessRightsTag-select"
                  IconComponent={KeyboardArrowDown}
                  label="Access Rights Tag"
                  fullWidth
                  disabled={isLoading}
                >
                  <MenuItem value={ACCESS_RIGHTS_TAG_RESET}>No restrictions</MenuItem>
                  {accessRightsTags.map((accessRightsTag, accessRightsTagIndex) => (
                    <MenuItem key={accessRightsTagIndex.toString()} value={accessRightsTag.id}>
                      {accessRightsTag.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {isError && (
              <div>
                {apiErrors.map((error) => (
                  <FormHelperText key={error} className="user-dialog__error" error>
                    {error ?? ''}
                  </FormHelperText>
                ))}
              </div>
            )}
          </DialogContent>
          <DialogActions sx={{ pb: 3, pr: 3, pt: 0 }}>
            <Button
              disabled={isSubmitDisabled}
              variant="contained"
              endIcon={isLoading ? <CircularProgress size={20} /> : <SaveIcon />}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </UserDialogWrapper>
      </DialogSuccessMessage>
    </Dialog>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import { EllipsisWithTooltipWrapper } from './EllipsisWithTooltip.styles';

type EllipsisWithTooltipProps = {
  text: string;
  maxWidth?: string;
};

export const EllipsisWithTooltip: React.FC<EllipsisWithTooltipProps> = ({ text, maxWidth }) => {
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      setIsTextOverflowing(textRef.current.offsetWidth < textRef.current.scrollWidth);
    }
  }, [text, textRef.current?.offsetWidth]);

  const parentContainerWidth = wrapperRef.current
    ? `${(wrapperRef.current.parentNode as HTMLElement).clientWidth}px`
    : false;

  return (
    <EllipsisWithTooltipWrapper
      ref={wrapperRef}
      maxWidth={maxWidth || parentContainerWidth || 'initial'}
      data-testid="ellipsis-with-tooltip"
      className="ellipsis-with-tooltip"
    >
      {isTextOverflowing ? (
        <Tooltip aria-label="tooltip-with-ellipsis" title={text}>
          <div className="ellipsis-with-tooltip__wrapper" ref={textRef}>
            {text}
          </div>
        </Tooltip>
      ) : (
        <div className="ellipsis-with-tooltip__wrapper" ref={textRef}>
          {text}
        </div>
      )}
    </EllipsisWithTooltipWrapper>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { calcPercentageFromTotal } from 'utils';
import { useSelectedTenant } from 'utils/hooks';
import { DeviceWidgetIcon, DeviceWidgetLink } from 'components/DashboardView/atoms';
import { selectIsLoading } from 'reducers/loading/selectors';
import { WidgetContainer } from 'components/DashboardView/molecules';
import { WidgetProgressBar, WidgetProgressBarBlock } from 'styles/commonStyles';
import { ManagedServiceContentWrapper } from './ManagedServiceWidget.styles';

export const ManagedServiceWidget: React.FC = () => {
  const tenant = useSelectedTenant();

  const managedServiceSummary: {
    assigned: number;
    available: number;
    expiringInUnder12Months: number;
    total: number;
  } = useSelector((state: any) => state.tenantInfo.deviceSummary?.managedServiceLicenceSummary);

  const isFetching = useSelector((state: any) =>
    selectIsLoading(['FETCH_DEVICES', 'FETCH_BUILDINGINFO'])(state)
  );

  const {
    assigned = 0,
    available = 0,
    expiringInUnder12Months = 0,
    total = 0,
  } = managedServiceSummary || {};

  const assignedPercentage = calcPercentageFromTotal(assigned - expiringInUnder12Months, total);
  const expiringInUnder12MonthsPercentage = calcPercentageFromTotal(expiringInUnder12Months, total);
  const availablePercentage = calcPercentageFromTotal(available, total);

  return (
    <WidgetContainer
      title="Managed Services Licences"
      to={`/${tenant}/ManagedService`}
      style={{ gridColumn: 'span 1', gridRow: 'span 6' }}
      canRead
      isFetching={isFetching}
      isFailedToLoad={!managedServiceSummary && !isFetching}
      className="managed-services-widget"
    >
      <ManagedServiceContentWrapper>
        <div className="managed-services__top">
          <DeviceWidgetIcon
            count={assigned}
            msg="Assigned"
            to={`/${tenant}/ManagedService?type=assigned`}
            className="managed-services__top-icon"
          />
          <DeviceWidgetIcon
            icon={<CheckCircleIcon />}
            count={available}
            msg="Available"
            to={`/${tenant}/ManagedService?type=available`}
            className="managed-services__top-icon"
          />
        </div>
        <div className="managed-services__progress">
          <WidgetProgressBar>
            {assignedPercentage > 2 && (
              <WidgetProgressBarBlock status="Assigned" width={assignedPercentage} />
            )}
            {expiringInUnder12MonthsPercentage > 2 && (
              <WidgetProgressBarBlock status="Expiring" width={expiringInUnder12MonthsPercentage} />
            )}
            {availablePercentage > 2 && (
              <WidgetProgressBarBlock status="Passed" width={availablePercentage} />
            )}
          </WidgetProgressBar>
        </div>
        <DeviceWidgetLink
          to={`/${tenant}/ManagedService?type=expiring`}
          count={expiringInUnder12Months}
          label="Expiring within 12 month"
          className="managed-services__bottom-link"
        />
      </ManagedServiceContentWrapper>
    </WidgetContainer>
  );
};

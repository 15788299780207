export const errorMapper = (errorCode: number): string => {
  if (!errorCode) return '';

  switch (errorCode) {
    case 409:
      return 'Access Rights Tag already exists';
    default:
      return 'Something went wrong!';
  }
};

import React, { useEffect, useReducer } from 'react';

import { MapItem } from 'reducers/mapData';
import styled from 'styled-components';
import { BuildingInfo } from 'types/BuildingInfo';
import useNavigate from 'utils/hooks/useNavigate';

import BackButton from './BackButton';
import {
  floorPlanReducer,
  floorPlanInitialAction,
  setSelectedBuildingAction,
  setSelectedFloorAction,
  incrementFloorAction,
  decrementFloorAction,
  initFloorPlanState,
} from './FloorPlanReducer';
import MapContainer from './MapContainer';
import MapContainerEmpty from './MapContainerEmpty';
import FloorControl from './Overlays/FloorControl';
import StatusSwitch from './StatusSwitch';

const ActionsWrapper = styled.div`
  position: absolute;
  left: 6rem;
  top: 2.5rem;
  z-index: 9;

  display: flex;
  align-items: center;
`;

interface FloorPlanProps {
  buildingInfo: BuildingInfo[];
  devices: MapItem[];
}

const FloorPlan = ({ buildingInfo, devices }: FloorPlanProps) => {
  const { goBack } = useNavigate();
  const [state, dispatch] = useReducer(floorPlanReducer, initFloorPlanState);
  const { clusters, selectedBuilding, selectedFloor, activeFloors } = state;

  useEffect(() => {
    dispatch(floorPlanInitialAction(buildingInfo, devices));
  }, [devices, buildingInfo]);

  const selectBuilding = (name) => {
    const building = buildingInfo.find(
      (building) => building.building === name || building.name === name
    );
    if (building) {
      dispatch(setSelectedBuildingAction(building));
    } else {
      dispatch(setSelectedBuildingAction(null));
    }
  };

  const handleBackClick = () => {
    if (selectedBuilding) {
      dispatch(setSelectedBuildingAction(null));
    } else {
      goBack();
    }
  };

  return (
    <>
      <ActionsWrapper>
        <BackButton handleClick={handleBackClick} />
        <StatusSwitch />
      </ActionsWrapper>
      {selectedBuilding && activeFloors && (
        <>
          <FloorControl
            width={Math.floor(selectedBuilding.floors.length / 6)}
            left="6rem"
            bottom="5rem"
          >
            <section>
              {selectedBuilding.floors.map((f) => (
                <div
                  className={
                    (selectedFloor?.name === f.name ? 'selected ' : ' ') +
                    (activeFloors.includes(f.name) ? '' : 'inactive')
                  }
                  key={f.name}
                  onClick={() => dispatch(setSelectedFloorAction(f))}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => dispatch(setSelectedFloorAction(f))}
                >
                  {f.name}
                </div>
              ))}
            </section>
            <hr />
            <section>
              <div
                onClick={() => dispatch(incrementFloorAction())}
                role="button"
                tabIndex={0}
                onKeyDown={() => dispatch(incrementFloorAction())}
              >
                <span>+</span>
              </div>
              <div
                onClick={() => dispatch(decrementFloorAction())}
                role="button"
                tabIndex={0}
                onKeyDown={() => dispatch(decrementFloorAction())}
              >
                <span>-</span>
              </div>
            </section>
          </FloorControl>
        </>
      )}

      {(clusters && clusters.length > 0) || (selectedBuilding && selectedFloor) ? (
        <MapContainer
          handleMarkerClick={selectBuilding}
          selectedBuilding={selectedBuilding}
          clusters={clusters}
          floor={selectedFloor}
        />
      ) : (
        <MapContainerEmpty />
      )}
    </>
  );
};

export default FloorPlan;

import React from 'react';
import { Outlet } from 'react-router-dom';

import { useNavigate, useSelectedTenant } from 'utils/hooks';
import Loading from 'components/Loading';
import { TabItem } from 'components/atoms';
import { BackButton, IconWrapper, Tabs, VR } from 'styles/commonStyles';
import { PropertyWrapper } from './Property.styles';

import { useFilterTabs, usePropertyApi } from './hooks';

export const Property: React.FC = () => {
  const { goTo } = useNavigate();
  const tenant = useSelectedTenant();
  const { propertyInfo, bcmInfo, devicesInfo } = usePropertyApi();

  const handleBackClick = () => goTo('Properties');

  const tabs = useFilterTabs();

  return (
    <PropertyWrapper>
      {propertyInfo.isLoading && <Loading />}
      {propertyInfo.isSuccess && (
        <>
          <div className="property__header">
            <div className="property__header-left">
              <BackButton aria-label="back-button" onClick={handleBackClick} />
              <VR />
              <IconWrapper size={3.5} status="">
                <img src="images/icon-property.svg" alt="property icon" />
              </IconWrapper>
              <h2>{propertyInfo.data.propertyReference}</h2>
            </div>
          </div>
          <div className="property__container">
            <Tabs>
              {tabs.map(({ to, label }, linkIndex) => (
                <TabItem
                  key={linkIndex.toString()}
                  to={to}
                  defaultLocation="Settings"
                  label={label}
                />
              ))}
            </Tabs>
            <div className="property__container-content">
              <Outlet
                context={{
                  propertyInfo,
                  tenant,
                  devicesInfo,
                  bcmInfo,
                }}
              />
            </div>
          </div>
        </>
      )}
    </PropertyWrapper>
  );
};

import React, { useState, useRef } from 'react';

import styled from 'styled-components';
import { deviceTypeToArea } from 'utils/deviceUtils';
import useAccess from 'utils/hooks/useAccess';
import useClickAway from 'utils/hooks/useClickAway';

import { Device } from 'types/DeviceTypes/Device';
import { EmeredDevice } from 'types/EmeredTypes';
import { NetworkingDevice } from 'types/NetworkingDevice';
import { deviceActions as actions, ActionType } from '../actions';
import { ActionsList } from '../ActionsList';
import DeviceActionSelector from '../DeviceActionSelector';

const Wrapper = styled.div`
  display: flex;
  margin-left: 2rem;
  position: relative;
  background-color: ${(props) => props.theme.primaryLight};
  font-size: ${(props) => props.theme.textSmall};
  font-weight: 500;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  width: fit-content;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${(props) => props.theme.primary};
    cursor: pointer;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.white};
  transition: all 0.3s ease-in-out;
  outline: none;
  opacity: 0.5;
  padding-right: 1rem;
  &:hover,
  :focus {
    cursor: pointer;
    opacity: 1;
  }
  &:focus ~ .reveal {
    opacity: 1;
    visibility: visible;
    outline: none;
  }
`;

const IconWrapper = styled.span`
  display: inline-block;
  & svg {
    height: 100%;
    width: 100%;
    fill: currentColor;
  }
`;

const Caret = styled(IconWrapper)`
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
`;

const CaretPlaceholder = styled.div`
  width: 20px;
`;

const TextWrapper = styled.span`
  display: inline-block;
  width: 100%;
  color: inherit;
  padding: 0.75rem 0.5rem 0.75rem 2rem;
  color: ${(props) => props.theme.white};
  text-align: center;
`;

type DeviceActionsProps = {
  device: Device | EmeredDevice | NetworkingDevice;
};

const DeviceActions: React.FC<DeviceActionsProps> = ({ device }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>('none');
  const { isManager, isEdit } = useAccess(deviceTypeToArea(device));
  const wrapper = useRef<HTMLDivElement>(null);
  useClickAway(wrapper, () => setIsOpen(false));

  const firstAction = actions.filter((a) =>
    a.shouldDisplay({
      device,
      isListing: false,
      isManager,
      isEng: isEdit,
    })
  )[0];

  const moreActions = actions.filter((a) =>
    a.shouldDisplay({
      device,
      isListing: false,
      isManager,
      isEng: isEdit,
    })
  );

  return (
    <Wrapper ref={wrapper}>
      <TextWrapper onClick={() => setAction(firstAction.id)}>{firstAction.label}</TextWrapper>

      {moreActions.length > 1 ? (
        <InnerWrapper onClick={() => setIsOpen(!isOpen)}>
          <Caret>
            <svg>
              <use href="images/icons.svg#icon-caret-down" />
            </svg>
          </Caret>
        </InnerWrapper>
      ) : (
        <CaretPlaceholder />
      )}

      <ActionsList className={isOpen ? 'open' : ''}>
        {moreActions.map((a) => (
          <li
            key={a.id}
            onClick={() => {
              setAction(a.id);
              setIsOpen(false);
            }}
            role="presentation"
          >
            {a.label}
          </li>
        ))}
      </ActionsList>
      <DeviceActionSelector
        actionType={action}
        device={device}
        handleResetAction={() => setAction('none')}
      />
    </Wrapper>
  );
};

export default DeviceActions;

import styled from 'styled-components';

export const CardHeader = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.9em;
  height: 2.4em;
`;

export const CardTitle = styled.div`
  font-size: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: bold;

  .numeric-chart-card__subtitle {
    font-size: 1rem;
  }
`;

export const CardSubtitle = styled.div`
  font-size: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const GraphWrapper = styled.div`
  height: 150px;
`;

import styled from 'styled-components';
import { editFieldsStyles } from 'styles/commonStyles';

export const EditFormFieldActionWrapper = styled.div`
  .edit-form-field-action {
    &__main {
      display: flex;
      gap: 1rem;
    }

    &__title {
      ${editFieldsStyles.title}
    }

    &__label {
      ${editFieldsStyles.label}
    }
  }
`;

import React from 'react';
import { NavLink } from 'react-router-dom';

import { NavIcon } from './WidgetContainerLink.styles';

interface WidgetContainerLinkProps {
  to: string | null;
  title: string;
  canRead: boolean;
}

export const WidgetContainerLink: React.FC<WidgetContainerLinkProps> = ({
  to,
  title,
  canRead,
}) =>
  canRead && !!to ? (
    <NavLink to={to}>
      <h3 className="widget-nav-title">{title}</h3>
      {canRead && <NavIcon className="widget-nav-icon" size={1.25} />}
    </NavLink>
  ) : (
    <span>
      <h3 className="widget-nav-title">{title}</h3>
    </span>
  );

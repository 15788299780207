import React from 'react';
import {
  TableHead,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LockResetIcon from '@mui/icons-material/LockReset';

import { VERICON_SYSTEM_USER_EMAIL } from 'scenes/Users/constants/users';
import { User } from 'reducers/users';
import { useAreaFeatures } from 'utils/hooks';
import { FeaturesAreas } from 'types';
import { UserGroupTableWrapper, StyledTableCell, StyledTableRow } from './UserGroupTable.styles';

type UserGroupTableProps = {
  users: User[];
  title: string;
  dialogSetters: {
    setResetDialog: ({ open, email, name }: { open: boolean; email: string; name: string }) => void;
    setEditDialog: ({ open, id }: { open: boolean; id: string }) => void;
    setDeleteDialog: ({ open, id, name }: { open: boolean; id: string; name: string }) => void;
  };
};

export const UserGroupTable: React.FC<UserGroupTableProps> = ({ users, title, dialogSetters }) => {
  const vericonUser = useAreaFeatures(FeaturesAreas.System).features.SystemDebug;
  const sortedUsers = users.sort((a, b) => a.name.localeCompare(b.name));
  const { setResetDialog, setEditDialog, setDeleteDialog } = dialogSetters;

  return (
    <UserGroupTableWrapper className="users-table-wrapper">
      <div className="users-table__title">
        <Typography variant="h5" paddingBottom={1} fontWeight={500}>
          {title}
        </Typography>
      </div>
      <div>
        <Paper elevation={3} className="users-table">
          <TableContainer className="users-table__container">
            <Table sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left"> Access Level</StyledTableCell>
                  <StyledTableCell align="right">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedUsers.map(({ id, email, name, role }, rowIndex) => (
                  <StyledTableRow key={rowIndex.toString()}>
                    <StyledTableCell sx={{ width: '25%' }} align="left">
                      {name}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '43%' }} align="left">
                      {email}
                    </StyledTableCell>
                    <StyledTableCell align="left">{role}</StyledTableCell>
                    <StyledTableCell align="right">
                      <div className="users-table__icons">
                        <IconButton disabled aria-label="stub-button">
                          <LockResetIcon sx={{ color: 'transparent' }} fontSize="large" />
                        </IconButton>
                        {(vericonUser || !email.includes(VERICON_SYSTEM_USER_EMAIL)) && (
                          <>
                            <Tooltip arrow title="Send Reset Password Email" placement="top">
                              <IconButton
                                onClick={() => setResetDialog({ open: true, email, name })}
                                className="users-table__reset-icon"
                              >
                                <LockResetIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip arrow title="Edit user" placement="top">
                              <IconButton
                                onClick={() => setEditDialog({ open: true, id })}
                                className="users-table__edit-icon"
                              >
                                <BorderColorIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip arrow title="Delete user" placement="top">
                              <IconButton
                                onClick={() => setDeleteDialog({ open: true, id, name })}
                                className="users-table__delete-icon"
                              >
                                <DeleteIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </UserGroupTableWrapper>
  );
};

import { ApiStatuses } from 'types';
import { ApiActionNames } from 'reducers/types';
import { UsersState } from '../types';

export const initialState: UsersState = {
  data: [],
  statuses: {
    [ApiActionNames.FetchUsers]: { status: ApiStatuses.Init, error: null },
    [ApiActionNames.CreateUser]: { status: ApiStatuses.Init, error: null },
    [ApiActionNames.UpdateUserDetails]: { status: ApiStatuses.Init, error: null },
    [ApiActionNames.UpdateUserTag]: { status: ApiStatuses.Init, error: null },
    [ApiActionNames.UpdateUserRole]: { status: ApiStatuses.Init, error: null },
    [ApiActionNames.DeleteUser]: { status: ApiStatuses.Init, error: null },
  },
};

export const ERROR_MESSAGES = {
  [ApiActionNames.FetchUsers]: 'An error occurred while fetching users.',
  [ApiActionNames.CreateUser]: 'Failed to create user!',
  [ApiActionNames.UpdateUserTag]: 'Failed to update user tag!',
  [ApiActionNames.UpdateUserRole]: 'Failed to update user role!',
  [ApiActionNames.UpdateUserDetails]: 'Failed to update user details!',
  [ApiActionNames.DeleteUser]: 'Failed to delete user.',
};

import React, { useState } from 'react';
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { ValidationStateType, useForm, useGetFetchData, usePutFetchData } from 'utils/hooks';
import { getErrorsList } from 'utils';
import { Property } from 'types';
import { FieldErrorMessages, InputEndIcon } from 'components/atoms';
import { ActionDialog, EditFormFieldAction } from '..';
import { EditAddressFormFieldWrapper } from './EditAddressFormField.styles';

type EditAddressFormFieldProps = {
  fieldTitle: string;
  fieldValue: string;
  fieldName: string;
  postcodeUrl: string;
  addressUrl: string;
  onSuccess: () => void;
  allowEdit?: boolean;
  payloadFields?: Partial<Property>;
  validationState: ValidationStateType;
  initialFormState: { [key: string]: string };
};

type AddressResponse = {
  county: string;
  line1: string;
  line2: string;
  line3: string;
  postcode: string;
  town: string;
};

export const EditAddressFormField: React.FC<EditAddressFormFieldProps> = ({
  allowEdit,
  postcodeUrl,
  addressUrl,
  onSuccess,
  fieldValue,
  fieldTitle,
  fieldName,
  payloadFields = {},
  initialFormState,
  validationState,
}) => {
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState<AddressResponse | null>(null);
  const { formState, isValid, errors, registerField, resetForm, handleChange } = useForm({
    initialFormState,
    validationState,
  });

  const getPostcode = useGetFetchData({
    url: postcodeUrl,
    query: formState[fieldName],
    enabled: false,
    enableErrorHandler: true,
  });

  const addressMutation = usePutFetchData({
    url: addressUrl,
    onSuccess,
  });

  const handlePostcodeChange = (event) => {
    handleChange(event);
    setAddress(null);
    getPostcode.remove();
  };

  const handleGetPostcode = () => {
    getPostcode.refetch();
  };

  const handleAddressChange = ({ target }) => {
    setAddress(target.value);
    addressMutation.reset();
  };

  const handleSubmitForm = () => {
    if (address) {
      addressMutation.mutate({
        ...payloadFields,
        address1: address.line1,
        address2: address.line2,
        address3: address.line3,
        town: address.town,
        county: address.county,
        postcode: address.postcode,
        latitude: getPostcode.data.latitude,
        longitude: getPostcode.data.longitude,
      });
    }
  };

  const handleFormClose = () => {
    setOpen(false);
    setAddress(null);
    resetForm();
    getPostcode.remove();
    addressMutation.reset();
  };

  const isSubmitDisabled = addressMutation.isLoading || addressMutation.isError || !address;
  const isCheckPostCodeDisabled =
    getPostcode.isLoading || getPostcode.isError || !isValid || getPostcode.isSuccess;

  const errorsList = getErrorsList({
    apiError: addressMutation.error,
    isError: addressMutation.isError,
  });

  return (
    <>
      <EditFormFieldAction
        fieldTitle={fieldTitle}
        fieldValue={fieldValue}
        onSubmit={() => setOpen(true)}
        allowEdit={allowEdit}
      />
      {open && (
        <ActionDialog
          open
          onClose={handleFormClose}
          handleSubmit={handleSubmitForm}
          title="Edit address"
          submitButtonName="Save"
          isDisabled={isSubmitDisabled}
          sendingRequest={addressMutation.isLoading}
          successMessageProps={{
            message: 'Address updated successfully!',
            showMessage: addressMutation.isSuccess,
            onClose: handleFormClose,
          }}
        >
          <EditAddressFormFieldWrapper>
            <div className="edit-address-form-field__postcode">
              <TextField
                {...registerField(fieldName)}
                onChange={handlePostcodeChange}
                error={!!errors[fieldName] || getPostcode.isError}
                helperText={getPostcode.isError ? 'Something went wrong!' : errors[fieldName]}
                label={fieldTitle}
                aria-label="edit-field-input"
                variant="outlined"
                fullWidth
                disabled={getPostcode.isLoading || addressMutation.isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InputEndIcon
                        isError={!!errors[fieldName] || getPostcode.isError}
                        value={formState[fieldName]}
                        isNotFocused
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                disabled={isCheckPostCodeDisabled}
                onClick={handleGetPostcode}
              >
                Check
              </Button>
            </div>
            {!!getPostcode.data?.addresses.length && (
              <div className="edit-address-form-field__address">
                <FormControl fullWidth>
                  <InputLabel id="edit-address-form-field">Select Address</InputLabel>
                  <Select
                    labelId="edit-address-form-field"
                    IconComponent={KeyboardArrowDown}
                    onChange={handleAddressChange}
                    disabled={addressMutation.isLoading}
                    label="Select Address"
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value={undefined} style={{ display: 'none' }} />
                    {getPostcode.data.addresses.map((addressObj, addressIndex) => (
                      <MenuItem key={addressIndex.toString()} value={addressObj}>
                        {[
                          addressObj.line1,
                          addressObj.line2,
                          addressObj.line3,
                          addressObj.town,
                          addressObj.county,
                        ]
                          .filter((address) => address)
                          .join(', ')}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <FieldErrorMessages errors={errorsList} />
          </EditAddressFormFieldWrapper>
        </ActionDialog>
      )}
    </>
  );
};

import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { displayValue, setValue } from 'utils/helpers';

const TextBoxWrapper = styled.div<{ readOnly: boolean }>`
  position: relative;
  display: inline-block;
  width: 100%;

  & > div {
    width: 100%;
    position: relative;
    display: inline-block;
    background-color: ${(props) => props.theme.offWhite};
    border-radius: 30px;
    cursor: pointer;
  }

  & input {
    ${(props) => props.theme.inputStyles};
    background-color: transparent;
    z-index: 6;
    width: 100%;
  }

  & ul {
    display: none;
    position: absolute;
    left: 0;
    max-height: 20rem;
    overflow: auto;

    background: ${(props) => props.theme.white};
    width: 100%;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 6;

    li {
      padding: 0.5rem 1rem;

      &:hover,
      &.selected {
        cursor: pointer;
        background-color: ${(props) => props.theme.default};
      }
    }

    &.open {
      display: block;
    }
  }
`;

const focusInput = (e) => {
  if (e.target.nodeName === 'DIV') {
    e.target.querySelector('input').focus();
  }
};

const TextSearch = ({
  selectedValue,
  values,
  onChange,
  name,
  placeholder = '',
  readOnly = false,
  hasOnChange,
}) => {
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([] as any);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    const filterData = (filter) =>
      values.filter((item) => {
        if (typeof item === 'string') {
          return item.toLowerCase().includes(filter.toLowerCase());
        } else if (typeof item === 'number') {
          return item.toString().includes(filter);
        } else {
          return item.display.toLowerCase().includes(filter.toLowerCase());
        }
      });
    setFilteredData(filterData(selectedValue));
    setSelectedIndex(values.findIndex((v) => v.value === selectedValue || v === selectedValue));
  }, [values, selectedValue]);

  const handleOnChange = (e) => {
    const userInput = e.currentTarget.value;
    e.target.value = userInput;
    e.target.dataset.value = userInput;
    if (userInput !== '') {
      setFilteredData(
        values.filter((item) => {
          if (typeof item === 'string') {
            return item.toLowerCase().includes(userInput.toLowerCase());
          } else if (typeof item === 'number') {
            return item.toString().includes(userInput);
          } else {
            return item.display.toLowerCase().includes(userInput.toLowerCase());
          }
        })
      );
    } else {
      setFilteredData([]);
    }
    setSelectedIndex(0);
    onChange(e.target);
  };

  const handleOnClick = (e) => {
    const ele = e.target;
    setFilteredData([]);
    setSelectedIndex(values.findIndex((v) => v.value === ele.dataset.value));
    ele.parentNode.parentNode.querySelector('input').value = ele.innerText;
    ele.parentNode.parentNode.querySelector('input').dataset.value = ele.dataset.value;
    onChange(ele);
  };

  const handleKeyPress = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        if (selectedIndex !== -1) {
          setSelectedIndex(selectedIndex - 1);
        }
        break;
      case 'ArrowDown':
        if (selectedIndex < filteredData.length - 1) {
          setSelectedIndex(selectedIndex + 1);
        }
        break;
      case 'Escape':
        setOpen(false);
        break;
      case 'Enter':
        if (!open) {
          setOpen(true);
        } else {
          const ele = e.target;
          const li = ele.parentNode.parentNode.querySelector('li.selected');
          ele.value = li.innerText;
          ele.dataset.value = li.dataset.value;
          setOpen(false);
          onChange(li);
        }
        break;

      default:
        break;
    }
  };

  return (
    <TextBoxWrapper readOnly={readOnly}>
      <div
        onClick={(e) => focusInput(e)}
        onKeyDown={(e) => focusInput(e)}
        role="button"
        tabIndex={0}
      >
        <input
          autoComplete="off"
          name={name}
          onKeyDown={(e) => handleKeyPress(e)}
          onClick={() => setOpen(true)}
          onChange={handleOnChange}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          {...(!hasOnChange && {
            defaultValue: displayValue(selectedValue, values),
            key: selectedValue,
          })}
          {...(hasOnChange && { value: displayValue(selectedValue, values) })}
          data-value={setValue(selectedValue, values)}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      </div>
      <ul className={open ? 'open' : ''}>
        {filteredData.map((v, i) =>
          typeof v === 'string' || typeof v === 'number' ? (
            <li
              onMouseDown={handleOnClick}
              role="presentation"
              {...(i === selectedIndex && { className: 'selected' })}
              key={i}
              data-name={name}
              data-value={v}
            >
              {v}
            </li>
          ) : (
            <li
              onMouseDown={handleOnClick}
              role="presentation"
              {...(i === selectedIndex && { className: 'selected' })}
              key={i}
              data-name={name}
              data-value={v.value}
            >
              {v.display}
            </li>
          )
        )}
      </ul>
    </TextBoxWrapper>
  );
};
export default TextSearch;

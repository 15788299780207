import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchTenants } from 'reducers/tenants';
import styled from 'styled-components';

import TenantTable from '../../components/Tenants/TenantTable';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  && h1 {
    margin-bottom: 0;
  }
`;

const AddBtn = styled.button`
  ${(props) => props.theme.submitButton()};
  display: flex;
  align-items: center;
  justify-content: space-around;

  & svg {
    fill: ${(props) => props.theme.white};
    width: 2rem;
    height: 2rem;
  }
`;

const ListTenants = () => {
  const dispatch = useDispatch();
  const tenants = useSelector((state: any) => state.tenants.tenants);

  useEffect(() => {
    const getTenants = async () => {
      await fetchTenants();
    };
    getTenants();
  }, [dispatch]);

  return (
    <>
      <Header>
        <h1>Tenants</h1>
        <div>
          <Link to={'Tenants/Add'}>
            <AddBtn>
              Add Tenant{' '}
              <svg>
                <use href="images/icons.svg#icon-plus" />
              </svg>
            </AddBtn>
          </Link>
        </div>
      </Header>
      <TenantTable tenants={tenants} />
    </>
  );
};

export default ListTenants;

import React from 'react';

import styled from 'styled-components';

import InputSelector from './InputSelector';

const InputWrapper = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: ${(props) => props.width};
`;

export type inputTypes =
  | 'text'
  | 'textsearch'
  | 'textarea'
  | 'dropdown'
  | 'search'
  | 'date'
  | 'datetime'
  | 'check'
  | 'checklist'
  | 'number'
  | 'radio'
  | 'bigRadio'
  | 'unit'
  | 'daterange'
  | 'phone'
  | 'passfail';

export interface InputProps {
  type: inputTypes;
  inputData: any;
  width?: string;
}

const Input = ({ type, inputData, width = '100%' }: InputProps) => (
  <InputWrapper width={width}>
    <InputSelector type={type} inputData={inputData} />
  </InputWrapper>
);

export default Input;

import React, { useState, useEffect } from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import styled from 'styled-components';

import FloorRow from './FloorRow';

const Table = styled.table`
  ${(props) => props.theme.tableStaticHeader('50vh')};
  font-size: ${(props) => props.theme.textSmall};

  & tbody tr td {
    display: flex;
    align-items: center;

    &:last-of-type {
      justify-content: flex-end;
    }
  }
`;

const AddButton = styled.button`
  ${(props) => props.theme.submitButton('14rem')};
  display: flex;
  margin: 3rem auto;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.textSmall};

  & svg {
    display: inline-block;
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    fill: ${(props) => props.theme.white};
  }
`;

const FloorTable = ({ floors, dispatch, canEdit = true, building = '' }) => {
  const [editRow, setEditRow] = useState(-1);
  const [editFloor, setEditFloor] = useState(null);

  useEffect(() => {
    if (editRow !== -1) {
      setEditFloor(floors[editRow]);
    } else {
      setEditFloor(null);
    }
  }, [editRow, floors]);

  const handleEditClick = (i) => {
    setEditRow(i);
  };

  const handleCancelClick = () => {
    setEditRow(-1);
  };

  const handleAddClick = () => {
    if (editRow !== -1) {
      dispatch({ type: 'UPDATE_FLOOR', updatedFloor: editFloor, index: editRow });
    }
    dispatch({ type: 'ADD_FLOOR' });
    setEditRow(floors.length);
  };

  const handleRemoveClick = (i) => dispatch({ type: 'REMOVE_FLOOR', index: i });

  const handleSaveClick = (floor, i) => {
    dispatch({ type: 'UPDATE_FLOOR', updatedFloor: floor, index: i });
    setEditRow(-1);
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Floor Name</th>
            {!canEdit && <th>Floor Plan</th>}
            <th />
          </tr>
        </thead>
        <tbody>
          {floors.map((f, i) => (
            <FloorRow
              key={i}
              floor={f}
              editFloor={editFloor}
              setEditFloor={setEditFloor}
              isEdit={i === editRow && editFloor}
              onSaveClick={(floor) => handleSaveClick(floor, i)}
              onCancelClick={handleCancelClick}
              onRemoveClick={() => handleRemoveClick(i)}
              handleEditClick={() => handleEditClick(i)}
              canEdit={canEdit}
              building={building}
              index={i}
            />
          ))}
        </tbody>
      </Table>

      {canEdit && (
        <AddButton onClick={() => handleAddClick()}>
          Add floor
          <SvgIcon type="plus" />
        </AddButton>
      )}
    </>
  );
};

export default FloorTable;

import React from 'react';

import StateButton from 'components/atoms/StateButton';
import { useDispatch } from 'react-redux';
import { toggleBuzzer, toggleChSetPoint } from 'reducers/device/boilerActions';
import { Areas } from 'types/roles';
import useAccess from 'utils/hooks/useAccess';
import { Device } from 'types/DeviceTypes/Device';
import { BcmConnectDetails } from 'types/BoilerTypes/BcmConnectDetails';

type PropertyAccessProps = {
  device: Device;
  boilerDetails: BcmConnectDetails;
  hasBuzzer: boolean;
};

const PropertyAccess: React.FC<PropertyAccessProps> = ({ device, boilerDetails, hasBuzzer }) => {
  const dispatch = useDispatch();
  const { isEdit } = useAccess(Areas.boilers);
  const { buzzerPropertyAccess, chPropertyAccess, chMaxSupported } = boilerDetails;

  const buzzerText = buzzerPropertyAccess ? 'Buzzer is on' : 'Turn buzzer on';
  const chText = chPropertyAccess ? 'CH Max Limited' : 'Limit CH Max';

  const buzzerAction = async () =>
    isEdit && dispatch(toggleBuzzer(device.deviceId, !buzzerPropertyAccess));
  const chAction = async () =>
    isEdit && dispatch(toggleChSetPoint(device.deviceId, !chPropertyAccess));

  return (
    <section>
      <h5>Property Access Aid</h5>
      <div>
        {hasBuzzer && (
          <StateButton
            type="edit"
            actionType="TOGGLE_BUZZER"
            action={buzzerAction}
            isActive={buzzerPropertyAccess}
          >
            {buzzerText}
          </StateButton>
        )}
        {chMaxSupported && (
          <StateButton
            type="default"
            actionType="TOGGLE_CHSETPOINT"
            action={chAction}
            isActive={chPropertyAccess}
          >
            {chText}
          </StateButton>
        )}
      </div>
    </section>
  );
};

export default PropertyAccess;

import React, { useState, useEffect } from 'react';

import LoadingOverlay from 'components/atoms/LoadingOverlay';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Filters from './Filters';
import Pagination from './Pagination';
import TableRow from './TableRow';
import { PageListGroup } from './types';

const TableOuterWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media ${(props) => props.theme.mobileL} {
    width: 90vw;
  }
`;

const TableFiltersWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 1.5rem 2rem 1.5rem;
  font-size: ${(props) => props.theme.textSmall};
  position: relative;
  flex-wrap: wrap;
`;

const TableWrapper = styled.div<{ isFetching: boolean }>`
  ${(props) => props.theme.wrapper}
  padding: 2.5rem 1rem;
  overflow-x: auto;
  filter: blur(${(props) => (props.isFetching ? '10px' : '0px')});
`;

const TableInnerWrapper = styled.div`
  min-height: 50rem;
`;

const Table = styled.table`
  ${(props) => props.theme.tableStyle};

  && th {
    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.primaryLight};
    }
  }
`;

const TableFooter = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    text-align: center;
  }
`;

const TableSpan = styled.span`
  display: inline-block;
  width: 12rem;
  height: 3rem;
  background: #999;
  opacity: 0.3;
`;

const Centered = styled.p`
  text-align: center;
`;

const FilterIconWrapper = styled.span`
  margin-left: 0.5rem;
  display: inline-block;
  width: 2rem;

  svg {
    fill: ${(props) => props.theme.white};
    width: 1rem;
    height: 1rem;
  }
`;

const TableLeft = styled.div`
  display: flex;
`;

const TableRight = styled.div`
  margin-left: auto;
`;

const TableButtonContainer = styled.div`
  @media ${(props) => props.theme.laptop} {
    width: 100%;
    order: 10;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
`;

const TableCount = styled.div`
  position: absolute;
  left: 50%;
  top: 1rem;
  transform: translateX(-50%);
`;

const createRows = (headers) => {
  const rows: any[] = [];
  for (let i = 0; i < 10; i++) {
    rows.push(
      <tr key={i}>
        {headers.map((h) => (
          <td key={h.property}>
            <TableSpan />
          </td>
        ))}
      </tr>
    );
  }

  return rows;
};

const sortByProp = (property, isAsc) => (a, b) => {
  if (a[property] === null) {
    return 1;
  } else if (b[property] === null) {
    return -1;
  } else if (isAsc) {
    return a[property] < b[property] ? -1 : 1;
  } else {
    return a[property] < b[property] ? 1 : -1;
  }
};

const sortTable = (data, property) => {
  const arr = [...data];
  if (property[0] === '-') {
    const prop = property.slice(1);

    return arr.sort(sortByProp(prop, false));
  } else {
    return arr.sort(sortByProp(property, true));
  }
};

const FilterIcon = () => (
  <FilterIconWrapper>
    <svg>
      <use href={'images/icons.svg#icon-caret-up'} />
    </svg>
    <svg>
      <use href={'images/icons.svg#icon-caret-down'} />
    </svg>
  </FilterIconWrapper>
);

const DataTable = ({
  data,
  headers,
  isFetching,
  hasUpdate,
  handleOnClear,
  filters,
  tblButtons,
  pageUpdateCb,
  rowFunctions,
}) => {
  const selectedStatus = useSelector((state: any) => state.filters.selectedStatus);
  const [itemsPerPage] = useState(10);
  const [sortProp, setSortProp] = useState(
    headers[0].property.toLowerCase().includes('date')
      ? `-${headers[0].property}`
      : headers[0].property
  );
  const [sortedData, setSortedData] = useState(sortTable(data, sortProp));
  const [pagedList, setPagedList] = useState<PageListGroup[]>();
  const [pageInfo, setPageInfo] = useState({ count: 0, start: 0, end: itemsPerPage });
  const [currentPage, setCurrentPage] = useState(1);
  const setSort = (p, canSort) => {
    let localP = p;
    if (canSort) {
      if (p === 'status') localP = `${selectedStatus}Status`;

      if (sortProp === localP) {
        setSortProp(`-${localP}`);
      } else {
        setSortProp(localP);
      }
    }
  };

  const handlePageList = (pagingData, sorted) => {
    const start: number = (pagingData.currentPage - 1) * pagingData.numPerPage;
    const end: number = start + parseInt(pagingData.numPerPage);
    setPageInfo({
      ...pageInfo,
      start,
      end,
      count: data.length,
    });
    setPagedList(sorted.slice(start, end));
  };

  useEffect(() => {
    const sorted = sortTable(data, sortProp);
    setSortedData(sorted);
    handlePageList(
      {
        currentPage: currentPage,
        numPerPage: itemsPerPage,
      },
      sorted
    );

    // TODO REFACTOR TO USE REDUCER
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const sorted = sortTable(data, sortProp);
    setSortedData(sorted);
    handlePageList(
      {
        currentPage: 1,
        numPerPage: itemsPerPage,
      },
      sorted
    );
    setCurrentPage(1);
    // TODO REFACTOR TO USE REDUCER
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortProp, hasUpdate]);

  useEffect(() => {
    if (pagedList) {
      if (pageUpdateCb) {
        const deviceIds = pagedList.map((d) => d.deviceId);
        pageUpdateCb(deviceIds);
      }
    }
    // TODO REFACTOR TO USE REDUCER
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagedList]);

  useEffect(() => {
    handlePageList(
      {
        currentPage: currentPage,
        numPerPage: itemsPerPage,
      },
      sortedData
    );
    // TODO REFACTOR TO USE REDUCER
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage]);

  return (
    <TableOuterWrapper>
      {filters && <Filters handleOnClear={handleOnClear} filters={filters} />}
      <TableWrapper isFetching={isFetching}>
        {isFetching && <LoadingOverlay />}
        <TableFiltersWrapper>
          <TableLeft></TableLeft>
          <TableButtonContainer>{tblButtons}</TableButtonContainer>
          <TableCount>
            {pageInfo.start + 1} - {pageInfo.count > pageInfo.end ? pageInfo.end : pageInfo.count}{' '}
            of {pageInfo.count}
          </TableCount>
          <TableRight>
            <Pagination
              totalRecords={data.length}
              numPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </TableRight>
        </TableFiltersWrapper>
        <TableInnerWrapper>
          <Table>
            <thead>
              <tr>
                {headers.map((header, i) => (
                  <th key={i} onClick={() => setSort(header.property, header.sort)}>
                    <div>
                      {header.display}
                      {header.sort && <FilterIcon />}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pagedList &&
                pagedList.map((data, i) => (
                  <TableRow key={i} data={data} headers={headers} rowFunctions={rowFunctions} />
                ))}
              {isFetching && createRows(headers)}
              {(!pagedList || pagedList.length === 0) && (
                <tr>
                  <td colSpan={headers.length}>
                    <Centered>No data available</Centered>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </TableInnerWrapper>
        <TableFooter>
          <Pagination
            totalRecords={data.length}
            numPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </TableFooter>
      </TableWrapper>
    </TableOuterWrapper>
  );
};

export default DataTable;

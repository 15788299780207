export const settingsLinks = [
  {
    to: '',
    label: 'Alerts Settings',
  },
  {
    to: 'tags',
    label: 'Tags',
  },
  {
    to: 'accessRightsTags',
    label: 'Access Rights Tags',
  },
  {
    to: 'luminaire',
    label: 'Standard Luminaires',
  },
  {
    to: 'residents',
    label: 'Residents',
  },
];

import { AllDevices } from 'types/AllDevices';
import { DevicePartial } from 'types/DevicePartial';
import { Areas } from 'types/roles';
import { isConnectedDevice } from 'utils';
import {
  isInventory,
  isEmergencyLight,
  isZigbeeExt,
  isAutofill,
  isInWholesale,
} from 'utils/deviceUtils';

export type ActionType =
  | 'install'
  | 'uninstall'
  | 'updateTags'
  | 'joinNetwork'
  | 'leaveNetwork'
  | 'uninstallAutofill'
  | 'uninstallPowerMeter'
  | 'uninstallMultiDot'
  | 'none'
  | 'viewInApp'
  | 'claimFromWholesaler'
  | 'updateAccessRightsTag';

export interface DeviceAction {
  id: ActionType;
  label: string;
  shouldDisplay(device: {
    isManager: boolean;
    isEng: boolean;
    device: AllDevices | DevicePartial;
    isListing?: boolean;
  }): boolean;
}

export const deviceActions: DeviceAction[] = [
  {
    id: 'viewInApp',
    label: 'View in app',
    shouldDisplay: ({ device, isEng }) =>
      isEng &&
      !isInventory(device) &&
      !isInWholesale(device) &&
      !isConnectedDevice(device.serialNumber),
  },
  {
    id: 'claimFromWholesaler',
    label: 'Claim from Wholesaler',
    shouldDisplay: ({ device, isEng }) => isEng && isInWholesale(device),
  },
  {
    id: 'install',
    label: 'Install',
    shouldDisplay: ({ device, isEng }) =>
      isEng &&
      isInventory(device) &&
      !isInWholesale(device) &&
      !isAutofill(device) &&
      !isConnectedDevice(device.serialNumber),
  },
  {
    id: 'uninstall',
    label: 'Uninstall',
    shouldDisplay: ({ device, isEng }) =>
      isEng && !isInventory(device) && !device?.userHasReadOnlyAccess,
  },
  {
    id: 'updateTags',
    label: 'Update tags',
    shouldDisplay: ({ isListing, isManager }) => !!isManager && !!isListing,
  },
  {
    id: 'updateAccessRightsTag',
    label: 'Update access rights tag',
    shouldDisplay: ({ device }) =>
      !isInventory(device) &&
      !device?.userHasReadOnlyAccess &&
      !!device?.userCanUpdateAccessRightsTag,
  },
  {
    id: 'joinNetwork',
    label: 'Join Network',
    shouldDisplay: ({ device, isEng }) =>
      !device?.userHasReadOnlyAccess &&
      isEng &&
      !isInventory(device) &&
      (isEmergencyLight(device) || isZigbeeExt(device)),
  },
  {
    id: 'leaveNetwork',
    label: 'Leave Network',
    shouldDisplay: ({ device, isEng }) =>
      !device?.userHasReadOnlyAccess &&
      isEng &&
      !isInventory(device) &&
      (isEmergencyLight(device) || isZigbeeExt(device)),
  },
];

export type BulkActionType =
  | 'bulkAddTags'
  | 'bulkRemoveTags'
  | 'bulkJoinNetwork'
  | 'bulkLeaveNetwork'
  | 'bulkUninstall'
  | 'bulkFlickTest'
  | 'bulkAddAccessRightsTags'
  | 'bulkRemoveAccessRightsTags'
  | 'none';

export interface BulkDeviceAction {
  id: BulkActionType;
  label: string;
  shouldDisplay(props: {
    isManager: boolean;
    isEng: boolean;
    allJoinedNetwork: boolean;
    allInstalled: boolean;
    area: Areas;
  }): boolean;
}

export const bulkActions: BulkDeviceAction[] = [
  {
    id: 'bulkAddTags',
    label: 'Add tags',
    shouldDisplay: ({ isManager }) => isManager,
  },

  {
    id: 'bulkAddAccessRightsTags',
    label: 'Add Access Rights Tags',
    shouldDisplay: ({ isManager, allInstalled }) => isManager && !!allInstalled,
  },
  {
    id: 'bulkRemoveAccessRightsTags',
    label: 'Remove Access Rights Tags',
    shouldDisplay: ({ isManager, allInstalled }) => isManager && !!allInstalled,
  },
  {
    id: 'bulkRemoveTags',
    label: 'Remove tags',
    shouldDisplay: ({ isManager }) => isManager,
  },
  {
    id: 'bulkJoinNetwork',
    label: 'Join Network',
    shouldDisplay: ({ isEng, area, allInstalled }) =>
      isEng && (area === Areas.lighting || area === Areas.networking) && !!allInstalled,
  },
  {
    id: 'bulkLeaveNetwork',
    label: 'Leave Network',
    shouldDisplay: ({ isEng, area, allJoinedNetwork }) =>
      !!allJoinedNetwork && isEng && (area === Areas.lighting || area === Areas.networking),
  },
  {
    id: 'bulkUninstall',
    label: 'Uninstall',
    shouldDisplay: ({ isEng, allInstalled }) => !!isEng && !!allInstalled,
  },
  {
    id: 'bulkFlickTest',
    label: 'Bulk Flick Test',
    shouldDisplay: ({ isEng, area, allJoinedNetwork }) =>
      !!isEng && area === Areas.lighting && !!allJoinedNetwork,
  },
];

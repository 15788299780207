import { handleReduxApiReq } from 'utils/ApiRequestHelpers';

const CLEAR_BUILDINGINFO = 'CLEAR_BUILDINGINFO';
const FETCH_BUILDINGINFO_REQUEST = 'FETCH_BUILDINGINFO_REQUEST';
const FETCH_BUILDINGINFO_ERROR = 'FETCH_BUILDINGINFO_ERROR';
const FETCH_BUILDINGINFO_SUCCESS = 'FETCH_BUILDINGINFO_SUCCESS';
const UPDATE_FLOORPOS_REQUEST = 'UPDATE_FLOORPOS_REQUEST';
const UPDATE_FLOORPOS_ERROR = 'UPDATE_FLOORPOS_ERROR';
const UPDATE_FLOORPOS_SUCCESS = 'UPDATE_FLOORPOS_SUCCESS';
const FETCH_BUILDINGUPDATE_REQUEST = 'FETCH_BUILDINGUPDATE_REQUEST';
const FETCH_BUILDINGUPDATE_ERROR = 'FETCH_BUILDINGUPDATE_ERROR';
const FETCH_BUILDINGUPDATE_SUCCESS = 'FETCH_BUILDINGUPDATE_SUCCESS';
const UPDATE_BUILDING_REQUEST = 'UPDATE_BUILDING_REQUEST';
const UPDATE_BUILDING_ERROR = 'UPDATE_BUILDING_ERROR';
const UPDATE_BUILDING_SUCCESS = 'UPDATE_BUILDING_SUCCESS';

export const buildingInfoTypes = {
  CLEAR_BUILDINGINFO,
  FETCH_BUILDINGINFO_REQUEST,
  FETCH_BUILDINGINFO_ERROR,
  FETCH_BUILDINGINFO_SUCCESS,
  UPDATE_FLOORPOS_REQUEST,
  UPDATE_FLOORPOS_ERROR,
  UPDATE_FLOORPOS_SUCCESS,
  FETCH_BUILDINGUPDATE_REQUEST,
  FETCH_BUILDINGUPDATE_ERROR,
  FETCH_BUILDINGUPDATE_SUCCESS,
  UPDATE_BUILDING_REQUEST,
  UPDATE_BUILDING_ERROR,
  UPDATE_BUILDING_SUCCESS,
};

export const clearBuildings = () => ({
  type: CLEAR_BUILDINGINFO,
});

const fetchBuildingInfoRequest = () => ({
  type: FETCH_BUILDINGINFO_REQUEST,
});

const fetchBuildingInfoSuccess = (buildingInfo) => ({
  type: FETCH_BUILDINGINFO_SUCCESS,
  payload: { buildingInfo },
});

const fetchBuildingInfoError = (error) => ({
  type: FETCH_BUILDINGINFO_ERROR,
  payload: { error },
});

export const fetchBuildingInfoAction = async (dispatch) => {
  dispatch(fetchBuildingInfoRequest());
  try {
    const data = await handleReduxApiReq('GetTenantBuildings', {}, 'POST');
    dispatch(fetchBuildingInfoSuccess(data));
  } catch (e) {
    dispatch(fetchBuildingInfoError(e));
  }
};

export const fetchBuildingInfo = () => async (dispatch) => {
  fetchBuildingInfoAction(dispatch);
};

const updateFloorPosRequest = () => ({
  type: UPDATE_FLOORPOS_REQUEST,
});

const updateFloorPosSuccess = (building) => ({
  type: UPDATE_FLOORPOS_SUCCESS,
  payload: { building },
});

const updateFloorPosError = (error) => ({
  type: UPDATE_FLOORPOS_ERROR,
  payload: { error },
});

export const updateFloorPosAction = async (dispatch, reqData) => {
  dispatch(updateFloorPosRequest());
  try {
    const data = await handleReduxApiReq('UpdateFloorPosition', reqData, 'POST');
    dispatch(updateFloorPosSuccess(data));
  } catch (e) {
    dispatch(updateFloorPosError(e));
  }
};

export const updateFloorPos = (data) => async (dispatch) => {
  updateFloorPosAction(dispatch, data);
};

const fetchBuildingUpdateRequest = () => ({
  type: FETCH_BUILDINGUPDATE_REQUEST,
});

const fetchBuildingUpdateSuccess = (building) => ({
  type: FETCH_BUILDINGUPDATE_SUCCESS,
  payload: { building },
});

const fetchBuildingUpdateError = (error) => ({
  type: FETCH_BUILDINGUPDATE_SUCCESS,
  payload: { error },
});

export const fetchBuildingUpdateAction = async (dispatch, building) => {
  dispatch(fetchBuildingUpdateRequest());
  try {
    const data = await handleReduxApiReq('GetBuilding', { building }, 'POST');
    dispatch(fetchBuildingUpdateSuccess(data));
  } catch (e) {
    dispatch(fetchBuildingUpdateError(e));
  }
};

export const fetchBuildingUpdate = (building) => async (dispatch) => {
  fetchBuildingUpdateAction(dispatch, building);
};

const updateBuildingRequest = () => ({
  type: UPDATE_BUILDING_REQUEST,
});

const updateBuildingSuccess = (building) => ({
  type: UPDATE_BUILDING_SUCCESS,
  payload: { building },
});

const updateBuildingError = (error) => ({
  type: UPDATE_BUILDING_SUCCESS,
  payload: { error },
});

export const updateBuildingAction = async (dispatch, building) => {
  dispatch(updateBuildingRequest());
  try {
    const data = await handleReduxApiReq('UpdateBuilding', { building }, 'POST');
    dispatch(updateBuildingSuccess(data));
  } catch (e) {
    dispatch(updateBuildingError(e));
  }
};

export const updateBuilding = (building) => async (dispatch) => {
  updateBuildingAction(dispatch, building);
};

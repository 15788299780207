import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { tenantLogoManifest, TenantLogoObj } from './LogoManifest';

type selectedTenantIds = 'homeserve' | 'gugh';

type UILocation = 'navbar';

interface TenantLogoProps {
  selectedTenantId: selectedTenantIds;
  uILocation: UILocation;
}

const TenantLogoWrapper = styled.div`
  width: 12rem;
  margin-left: 2rem;
  display: flex;
  align-items: center;
`;

const TenantLogoPicture = styled.picture`
  width: 100%;
`;

const TenantLogoImg = styled.img`
  width: 100%;
  height: 100%;
`;

const TenantName = styled.span`
  margin-left: 2rem;
  @media ${(props) => props.theme.mobileS} {
    color: white;
  }
  @media ${(props) => props.theme.mobileM} {
    color: white;
  }
  @media ${(props) => props.theme.mobileL} {
    color: white;
  }
`;

const TenantLogo = ({ selectedTenantId, uILocation }: TenantLogoProps) => {
  const tenantsStore = useSelector((state: any) => state.tenants.tenants);

  const [selectedLogoObj, setSelectedLogoObj] = useState<TenantLogoObj>();
  const [tenantName, setTenantName] = useState();
  const [logoCheckDone, setLogoCheckDone] = useState<boolean>(false);

  useEffect(() => {
    const selectedTenantLogoObj = tenantLogoManifest.find(
      (tenantLogoObj) => tenantLogoObj.tenantName === selectedTenantId.trim()
    );

    if (selectedTenantLogoObj) {
      const selectedLogoObj = selectedTenantLogoObj.logos.find(
        (logoObj) => logoObj.location === uILocation
      );

      if (selectedLogoObj) {
        setSelectedLogoObj(selectedLogoObj);
      }
    }

    if (tenantsStore.length > 0) {
      setTenantName(tenantsStore.find((tenant) => tenant.id === selectedTenantId).name);
    }

    setLogoCheckDone(true);
  }, [selectedTenantId, tenantsStore, uILocation]);

  return (
    <>
      {selectedLogoObj ? (
        <TenantLogoWrapper>
          <TenantLogoPicture>
            <source media="(max-width: 32em)" srcSet={selectedLogoObj.forDark.url} />
            <source
              media="(max-width: 40em) and (orientation : landscape)"
              srcSet={selectedLogoObj.forDark.url}
            />
            <source srcSet={selectedLogoObj.forLight.url} />
            <TenantLogoImg
              src={selectedLogoObj.forLight.url}
              alt={selectedLogoObj.forLight.altText}
              //Below is the rounded up aspect ratio of the vericon logo.
              width="5"
              height="2"
            />
          </TenantLogoPicture>
        </TenantLogoWrapper>
      ) : (
        <>{logoCheckDone && tenantName && <TenantName>{tenantName}</TenantName>}</>
      )}
    </>
  );
};

export default TenantLogo;

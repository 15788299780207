import React from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import { FaCheck, FaPlus } from 'react-icons/fa';

import { EditFormFieldsControlsWrapper } from './EditFormFieldsControls.styles';

type EditFormFieldsControlsProps = {
  isLoading?: boolean;
  onSubmit: () => void;
  onClose: () => void;
  submitDisabled?: boolean;
  closeDisabled?: boolean;
};

export const EditFormFieldsControls: React.FC<EditFormFieldsControlsProps> = ({
  isLoading,
  onSubmit,
  onClose,
  submitDisabled,
  closeDisabled,
}) => (
  <EditFormFieldsControlsWrapper>
    <div className="edit-form-fields-controls">
      {isLoading ? (
        <CircularProgress
          aria-label="edit-form-fields-controls-progress"
          className="edit-form-fields-controls__progress"
          size={30}
        />
      ) : (
        <>
          <IconButton
            onClick={onSubmit}
            className="edit-form-fields-controls__button-submit"
            aria-label="edit-form-fields-controls-submit"
            disabled={submitDisabled}
          >
            <FaCheck className="edit-form-fields-controls__button-submit-icon" />
          </IconButton>
          <IconButton
            className="edit-form-fields-controls__button-close"
            aria-label="edit-form-fields-controls-close"
            disabled={closeDisabled}
            onClick={onClose}
          >
            <FaPlus className="edit-form-fields-controls__button-close-icon" />
          </IconButton>
        </>
      )}
    </div>
  </EditFormFieldsControlsWrapper>
);

import CountryDialCode from 'types/CountryDialCode';

import { countryDialCodes } from 'components/constants/countries';

export interface IPhoneNumber {
  countryDialCode: CountryDialCode;
  subscriberNumber: string;
}

const initialCountry: CountryDialCode = { label: 'United Kingdom', id: 'GB', dialCode: '+44' };

export const splitNumberFromPhoneNumber = (code: string, number: string): string => {
  const separatedNumber = number.substring(code.length, number.length);

  return separatedNumber;
};

export const splitCountryCodeAndNumber = (e164PhoneNumber: string): IPhoneNumber => {
  if (e164PhoneNumber === '') {
    return { countryDialCode: initialCountry, subscriberNumber: '' };
  }

  const dialCodes = countryDialCodes.filter((countryCode) =>
    e164PhoneNumber.includes(countryCode.dialCode)
  );

  if (dialCodes.length === 1) {
    const phoneNumber = splitNumberFromPhoneNumber(dialCodes[0].dialCode, e164PhoneNumber);

    return { countryDialCode: dialCodes[0], subscriberNumber: phoneNumber };
  }

  const dialCodesSortedByLength = dialCodes.sort((a, b) =>
    a.dialCode.length < b.dialCode.length ? 1 : -1
  );

  const dialCodesFilteredByLargestMatch = dialCodesSortedByLength.filter(
    ({ dialCode }) => dialCode === dialCodesSortedByLength[0].dialCode
  );

  if (dialCodesFilteredByLargestMatch.length === 1) {
    const phoneNumber = splitNumberFromPhoneNumber(
      dialCodesFilteredByLargestMatch[0].dialCode,
      e164PhoneNumber
    );

    return { countryDialCode: dialCodesFilteredByLargestMatch[0], subscriberNumber: phoneNumber };
  }

  const priorityDialCodes = dialCodesFilteredByLargestMatch.sort((a, b) => {
    if (!a.priority || !b.priority) return 0;

    return a.priority > b.priority ? 1 : -1;
  });

  if (priorityDialCodes.length >= 1) {
    const phoneNumber = splitNumberFromPhoneNumber(priorityDialCodes[0].dialCode, e164PhoneNumber);

    return { countryDialCode: priorityDialCodes[0], subscriberNumber: phoneNumber };
  }

  return { countryDialCode: initialCountry, subscriberNumber: '' };
};

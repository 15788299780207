import { getAvailableDevices } from 'utils';
import { eventTimelineSettings } from '../constants/propertyTelemetry';
import { handleTelemetryChartsConfig } from './handleTelemetryChartsConfig';

type HandleGenerateTelemetryViewProps = {
  isSurveyorCube: boolean;
};

export const handleGenerateTelemetryView = ({
  isSurveyorCube,
}: HandleGenerateTelemetryViewProps) => {
  const {
    getBoilerCharts,
    getMultiDotCharts,
    getEnviroDotCharts,
    getPowerMeterCard,
    getVericonDiagnosticChartForDevice,
    getMultiDotAndEnviroDotBatteryCards,
    getBoilerDiagnosticChartForDevice,
    getBoilerAssetManagementChartForDevice,
    getMultiDotAndEnviroDotTamperCards,
    getBoilerMonitoringChartForDevice,
    getHeatPumpCharts,
  } = handleTelemetryChartsConfig();

  const generatePropertySummeryCharts = ({ devices }) => {
    const { boiler, multiDOTs, enviroDOTs, powerMeters } = getAvailableDevices({ devices });

    const boilerCharts = () => {
      if (isSurveyorCube || !boiler.length) {
        return [];
      }

      return getBoilerCharts({
        label: boiler[0]?.serialNumber,
        propertyLocationId: boiler[0]?.propertyLocationId,
      });
    };

    return [
      eventTimelineSettings,
      ...boilerCharts(),
      ...(multiDOTs
        ?.map((device) =>
          getMultiDotCharts({
            title: device.location,
            subTitle: device.locationDetails,
            label: device.serialNumber,
            propertyLocationId: device.propertyLocationId,
          })
        )
        .flat() || []),
      ...(enviroDOTs
        ?.map((device) =>
          getEnviroDotCharts({
            title: device.location,
            subTitle: device.locationDetails,
            label: device.serialNumber,
            propertyLocationId: device.propertyLocationId,
          })
        )
        .flat() || []),
      ...((false && // Temporary hide powerSense
        powerMeters
          ?.map((device) =>
            getPowerMeterCard({
              title: device.location,
              subTitle: device.locationDetails,
              label: device.serialNumber,
              propertyLocationId: device.propertyLocationId,
            })
          )
          .flat()) ||
        []),
    ];
  };

  const generateBoilerMonitoringCharts = ({ devices }) => {
    const { boiler, zigbeeAutofill, legacyAutoFill } = getAvailableDevices({ devices });

    const boilerCharts = () => {
      if (isSurveyorCube || !boiler.length) {
        return [];
      }

      return [
        ...getBoilerMonitoringChartForDevice({
          label: boiler[0]?.serialNumber,
          propertyLocationId: boiler[0]?.propertyLocationId,
          zigbeeAutoFill: zigbeeAutofill[0],
          legacyAutoFill: legacyAutoFill[0],
          subTitle: `${boiler[0].location ?? ''}${
            boiler[0].locationDetails ? ` - ${boiler[0].locationDetails}` : ''
          }`,
        }),
      ];
    };

    return [eventTimelineSettings, ...boilerCharts()];
  };

  const generateHeatPumpMonitoringCharts = ({ devices }) => {
    const { boiler } = getAvailableDevices({ devices });

    const heatPumpCharts = () => {
      if (isSurveyorCube || !boiler.length) {
        return [];
      }

      return [
        ...getHeatPumpCharts({
          label: boiler[0]?.serialNumber,
          propertyLocationId: boiler[0]?.propertyLocationId,
          subTitle: `${boiler[0].location ?? ''}${
            boiler[0].locationDetails ? ` - ${boiler[0].locationDetails}` : ''
          }`,
        }),
      ];
    };

    return [eventTimelineSettings, ...heatPumpCharts()];
  };

  const generateAssetManagementCharts = ({ devices }) => {
    const { boiler, multiDOTs } = getAvailableDevices({ devices });

    const boilerCharts = () => {
      if (!boiler.length) {
        return [];
      }

      return [
        ...getBoilerDiagnosticChartForDevice({
          label: boiler[0].serialNumber,
          propertyLocationId: boiler[0].propertyLocationId,
          subTitle: `${boiler[0].location ?? ''}${
            boiler[0].locationDetails ? ` - ${boiler[0].locationDetails}` : ''
          }`,
        }),
        ...getBoilerAssetManagementChartForDevice({
          label: boiler[0].serialNumber,
          propertyLocationId: boiler[0].propertyLocationId,
          subTitle: `${boiler[0].location ?? ''}${
            boiler[0].locationDetails ? ` - ${boiler[0].locationDetails}` : ''
          }`,
        }),
      ];
    };

    return [
      ...boilerCharts(),
      ...(multiDOTs
        ?.map((device) =>
          getMultiDotAndEnviroDotTamperCards({
            title: device.location,
            subTitle: device.locationDetails,
            label: device.serialNumber,
            propertyLocationId: device.propertyLocationId,
          })
        )
        .flat() || []),
    ];
  };

  const generateVericonDiagnostics = ({ devices }) => {
    const { boiler, multiDOTs, enviroDOTs } = getAvailableDevices({ devices });

    return [
      ...(boiler.length
        ? getVericonDiagnosticChartForDevice({
          label: boiler[0]?.serialNumber,
          propertyLocationId: boiler[0]?.propertyLocationId,
        })
        : []),
      ...(multiDOTs
        ?.map((device) =>
          getMultiDotAndEnviroDotBatteryCards({
            title: device.location,
            subTitle: device.locationDetails,
            label: device.serialNumber,
            propertyLocationId: device.propertyLocationId,
          })
        )
        .flat() || []),
      ...(enviroDOTs
        ?.map((device) =>
          getMultiDotAndEnviroDotBatteryCards({
            title: device.location,
            subTitle: device.locationDetails,
            label: device.serialNumber,
            propertyLocationId: device.propertyLocationId,
          })
        )
        .flat() || []),
    ];
  };

  return {
    generatePropertySummeryCharts,
    generateBoilerMonitoringCharts,
    generateHeatPumpMonitoringCharts,
    generateVericonDiagnostics,
    generateAssetManagementCharts,
  };
};

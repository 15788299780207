import React from 'react';

import { DeviceActionMainDialog } from 'components/DeviceActions/Dialogs';
import { handleApiReq } from 'utils/ApiRequestHelpers';
import useAlert from 'utils/hooks/useAlert';

import { BulkActionProps } from '../../DeviceActionProps';

const BulkLeaveNetwork = ({ deviceIds, handleResetAction }: BulkActionProps) => {
  const { errorAlert, successAlert } = useAlert();

  const handleLeaveNetwork = async () => {
    const res = await handleApiReq('BulkLeaveNetwork', { deviceIds: deviceIds });
    if (res) {
      successAlert('Request Successful');
    } else {
      errorAlert('Something has gone wrong, please try again');
    }
    handleResetAction();
  };

  return (
    <DeviceActionMainDialog
      withSpinner
      handleResetAction={handleResetAction}
      handleSubmit={handleLeaveNetwork}
    >
      <h3>Bulk Leave Network</h3>
      <p>Clicking submit will attempt to Leave the devices to their building zigbee gateway</p>
    </DeviceActionMainDialog>
  );
};

export default BulkLeaveNetwork;

import React from 'react';
import { UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';

import {
  updateChSetPoint,
  updateOccupierAddress,
  updateOccupierId,
} from 'reducers/device/boilerActions';
import { Areas } from 'types/roles';
import { isInventory } from 'utils/deviceUtils';
import useAccess from 'utils/hooks/useAccess';
import { isGcNumber, isRequired, isValidCHMax } from 'utils/inputValidation';
import {
  BcmConnectDetails,
  BcmInfo,
  BcmMonitoring,
  HeatingUnit,
} from 'types/BoilerTypes/BcmConnectDetails';
import { Device } from 'types/DeviceTypes/Device';
import { FeaturesAreas } from 'types';
import { propertyAddressToString } from 'utils';
import { useAreaFeatures, useGetFetchData, useSelectedTenant } from 'utils/hooks';
import { thermostatConnectionText } from 'components/constants/thermostatConnectionText';
import { DeviceAccessRightsTagWrapperApiV1 } from 'components/molecules';
import DeviceStatusIcon from 'components/Icons/DeviceStatus';
import OccupancyStatusIcon from 'components/Icons/OccupancyStatus';
import DeviceDetailsEditInput from 'components/DeviceDetails/DetailsEdit';
import DeviceTags from 'components/DeviceDetails/DeviceTags';
import { PropertyAccess } from './molecules';
import {
  ContentItem,
  DeviceInfo,
  DeviceInfoCol,
  PropertyPageLink,
  StatusInfo,
  StyledLink,
} from './BoilerInfo.styles';

type BoilerInfoProps = {
  device: Device;
  data: BcmConnectDetails;
  triggerUpdateDevice: () => void;
};

const BoilerInfo: React.FC<BoilerInfoProps> = ({ device, data, triggerUpdateDevice }) => {
  const dispatch = useDispatch();
  const tenant = useSelectedTenant();
  const { isEdit } = useAccess(Areas.boilers);
  const { HideEditPropertyAndResidentFields } = useAreaFeatures(FeaturesAreas.Boilers).features;

  const { features: propertyFeatures, isSuccess: areFeaturesSuccess } = useAreaFeatures(
    FeaturesAreas.Properties
  );
  const isPropertyCentric =
    !!device.propertyId && propertyFeatures.PropertyCentric && areFeaturesSuccess;

  const {
    data: deviceProperty,
    isSuccess: devicePropertyIsSuccess,
    isError: devicePropertyIsError,
  } = useGetFetchData({
    url: process.env.REACT_APP_API_URL_V2 as string,
    query: `tenants/${tenant}/properties/${device?.propertyId}`,
    enabled: isPropertyCentric,
    enableErrorHandler: true,
  });

  const bcmInfo: UseQueryResult<BcmInfo, unknown> = useGetFetchData({
    url: process.env.REACT_APP_API_URL_V2 as string,
    query: `tenants/${tenant}/bcms/${device.deviceId}`,
    enabled: !!device.deviceId,
    enableErrorHandler: true,
  });

  const isSurveyorCube = bcmInfo.data?.monitoring === BcmMonitoring.None;
  const isHeatPump = data.heatingUnit === HeatingUnit.AirSourceHeatPump;

  const handleCHUpdate = async (value) => {
    await dispatch(updateChSetPoint(device.deviceId, value));
  };

  const handlAddressUpdate = async (value) =>
    await dispatch(updateOccupierAddress(device.deviceId, value));
  const handleOccupierIdUpdate = async (value) =>
    await dispatch(updateOccupierId(device.deviceId, value));

  const addressVal = [{ fn: isRequired, msg: 'Address can not be empty' }];
  const idVal = [{ fn: isRequired, msg: 'Property Reference can not be empty' }];
  const chMaxVal = [{ fn: isValidCHMax, msg: 'CH max should be greater than 30' }];

  const canEdit =
    isEdit &&
    !isInventory(device) &&
    !device.userHasReadOnlyAccess &&
    !HideEditPropertyAndResidentFields;

  return (
    <>
      {isPropertyCentric && (
        <section>
          <ContentItem>
            <PropertyPageLink to={`/${tenant}/properties/${device.propertyId}/info`}>
              Navigate to property page
            </PropertyPageLink>
          </ContentItem>
        </section>
      )}
      <section>
        <h5>Device Statuses</h5>
        <StatusInfo>
          <ContentItem>
            <span>Connection: </span>
            <DeviceStatusIcon selectedStatus={'connection'} device={device} />
          </ContentItem>
          <ContentItem>
            <span>Device: </span>
            <DeviceStatusIcon selectedStatus={'device'} device={device} />{' '}
          </ContentItem>
          {!isHeatPump && !isSurveyorCube && bcmInfo.isSuccess && (
            <ContentItem>
              <span>Occupancy: </span>
              <OccupancyStatusIcon selectedStatus={data.occupancy} />{' '}
            </ContentItem>
          )}
        </StatusInfo>
      </section>
      <section>
        <h5>{isHeatPump ? 'Heat Pump ' : 'Boiler '}Information</h5>
        <DeviceInfo>
          {!isHeatPump && (
            <ContentItem>
              <span>GC Number: </span>
              {data.gcNumber}
            </ContentItem>
          )}
          <ContentItem>
            <span>Manufacturer: </span>
            {data.manufacturer}
          </ContentItem>
          <ContentItem>
            <span>Model: </span>
            {data.model}
          </ContentItem>
        </DeviceInfo>
        {!isHeatPump && (
          <DeviceInfoCol>
            <ContentItem>
              <span>Get parts from: </span>
              <StyledLink
                href={`https://www.heatingspareparts.com/boiler-spares/gascode/${
                  isGcNumber(data.gcNumber) ? data.gcNumber : '47-min-349-min-16'
                }`}
                target="_blank"
              >
                HeatingSpareParts
              </StyledLink>
            </ContentItem>
          </DeviceInfoCol>
        )}
      </section>
      {!isHeatPump && (
        <section>
          <h5>Boiler Settings</h5>
          <DeviceInfo>
            <ContentItem>
              <span>Hot Water Actual: </span>
              {data.hotWaterTemp ? `${data.hotWaterTemp}°C` : 'Not Available'}{' '}
            </ContentItem>
            <ContentItem>
              <span>Heating Actual: </span>
              {data.flowTemp ? `${data.flowTemp}°C` : 'Not Available'}{' '}
            </ContentItem>
            <ContentItem>
              <span>Thermostat Type: </span>
              {thermostatConnectionText[data.thermostatKind] || 'Not Available'}
            </ContentItem>
          </DeviceInfo>
          <DeviceInfo>
            <ContentItem>
              <span>Heating Max Override: </span>
              {data.chMaxSupported && !data.chPropertyAccess && canEdit ? (
                <DeviceDetailsEditInput
                  type="number"
                  value={data.heatingMax}
                  options={{ min: 30, max: 80 }}
                  action={(value) => handleCHUpdate(value)}
                  unit="°C"
                  actionType="UPDATE_CHSETPOINT"
                  validators={chMaxVal}
                />
              ) : (
                `${data.heatingMax}°C`
              )}
            </ContentItem>
          </DeviceInfo>
        </section>
      )}
      <section>
        <h5>Property Information</h5>
        <DeviceInfoCol>
          <ContentItem>
            <span>Address: </span>
            {isPropertyCentric && devicePropertyIsSuccess && (
              <>{propertyAddressToString({ property: deviceProperty, withPostcode: true })}</>
            )}
            {((!isPropertyCentric && areFeaturesSuccess) || devicePropertyIsError) && (
              <>
                {canEdit ? (
                  <DeviceDetailsEditInput
                    type="text"
                    value={device.address}
                    action={handlAddressUpdate}
                    actionType="UPDATE_ADDRESS"
                    validators={addressVal}
                    width="80%"
                  />
                ) : (
                  device.address
                )}
              </>
            )}
          </ContentItem>
          <ContentItem>
            <span>Property Reference: </span>
            {isPropertyCentric && devicePropertyIsSuccess && (
              <>{deviceProperty?.propertyReference}</>
            )}
            {((!isPropertyCentric && areFeaturesSuccess) || devicePropertyIsError) && (
              <>
                {canEdit ? (
                  <DeviceDetailsEditInput
                    type="text"
                    value={data.occupierId}
                    action={handleOccupierIdUpdate}
                    actionType="UPDATE_OCCUPIERID"
                    validators={idVal}
                    width="40%"
                  />
                ) : (
                  data.occupierId
                )}
              </>
            )}
          </ContentItem>
        </DeviceInfoCol>
      </section>
      <section>
        <DeviceAccessRightsTagWrapperApiV1 device={device} onSuccess={triggerUpdateDevice} />
      </section>
      <DeviceTags device={device} limited={isEdit && !device.userHasReadOnlyAccess} />
      {!isHeatPump && canEdit && (
        <PropertyAccess
          device={device}
          boilerDetails={data}
          hasBuzzer={!!bcmInfo.data?.hasBuzzer}
        />
      )}
    </>
  );
};

export default BoilerInfo;

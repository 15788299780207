import React from 'react';

import styled from 'styled-components';
import { mapDeviceTypes } from 'utils/deviceUtils';

const DeviceMarkerWrapper = styled.div<{ status: string }>`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.groupStatusBackgroundColor(props.status)};
  z-index: 8;
  padding: 0.5rem;
  margin-right: 0.5rem;
  transition: background-color 0.3s ease-in-out;

  &.active {
   z-index: 11;
  }

  & svg {
    fill: ${(props) => props.theme.groupStatusColor(props.status)};
    width: 100%;
    height: 100%;
  }
`;

const DeviceMarker = ({ deviceType, groupStatus }) => {
  const icon = mapDeviceTypes(deviceType);

  return (
    <DeviceMarkerWrapper status={groupStatus}>
      <svg>
        <use xlinkHref={`images/icon-${icon}.svg#icon-${icon}`} />
      </svg>
    </DeviceMarkerWrapper>
  );
};

export default DeviceMarker;

import styled from 'styled-components';

export const DeviceStatusSpan = styled.span<{
  status: string;
}>`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  color: ${(props) => props.theme.white};
  border-radius: 20px;
  margin-right: 0.5rem;
  background-color: ${(props) => props.theme.statusColor(props.status)};
`;

import { createSelector } from 'reselect';
import { createThunkEffect } from 'utils/ReduxUtils';

import { Filter } from '../../types/Listing';

export interface ListingColumn {
  id: string;
  label: string;
}

export interface ViewDefinition {
  viewId: string;
  label: string;
  root: string;
  category: 'device' | 'boiler' | 'emered';
  columns: string[];
  builtIn: boolean;
  private: boolean;
  authors: string[];
  filters: Filter[];
}

export interface PageInfo {
  currentPage: number;
  sortProp: string;
  searchTerm: string;
  viewId: string;
}

export interface ViewContext {
  tenantId: string;
  viewId: string;
  page: string;
}

interface ViewDefState {
  viewContext: ViewContext;
  views: ViewDefinition[];
  selectedView: ViewDefinition | null;
  storedView: ViewDefinition | null;
  storedPageInfo: PageInfo | null;
}

const intialState: ViewDefState = {
  viewContext: {
    page: '',
    tenantId: '',
    viewId: '',
  },
  views: [],
  selectedView: null,
  storedView: null,
  storedPageInfo: null,
};

const FETCH_VIEWDEFINITIONS = 'FETCH_VIEWDEFINITIONS';
const ADD_VIEWDEFINITION = 'ADD_VIEWDEFINITION';
const UPDATE_VIEWDEFINITION = 'UPDATE_VIEWDEFINITION';
const DELETE_VIEWDEFINITION = 'DELETE_VIEWDEFINITION';
const CLEAR_VIEWDEFINITIONS = 'CLEAR_VIEWDEFINITIONS';
const SET_SELECTEDVIEW = 'SET_SELECTEDVIEW';
const CLEAR_SELECTEDVIEW = 'CLEAR_SELECTEDVIEW';
const CLEAR_STOREDVIEW = 'CLEAR_STOREDVIEW';
const SET_STOREDVIEW = 'SET_STOREDVIEW';
const CLEAR_STOREDPAGEINFO = 'CLEAR_STOREDPAGEINFO';
const SET_STOREDPAGEINFO = 'SET_STOREDPAGEINFO';
const SET_CURRENT_VIEW_CONTEXT = 'SET_CURRENT_VIEW_CONTEXT';
const CLEAR_CURRENT_VIEW_CONTEXT = 'CLEAR_CURRENT_VIEW_CONTEXT';

export const viewDefinitionTypes = {
  FETCH_VIEWDEFINITIONS,
  ADD_VIEWDEFINITION,
  UPDATE_VIEWDEFINITION,
  DELETE_VIEWDEFINITION,
  CLEAR_VIEWDEFINITIONS,
  SET_SELECTEDVIEW,
  CLEAR_SELECTEDVIEW,
  SET_STOREDVIEW,
  CLEAR_STOREDVIEW,
  CLEAR_STOREDPAGEINFO,
  SET_STOREDPAGEINFO,
  SET_CURRENT_VIEW_CONTEXT,
  CLEAR_CURRENT_VIEW_CONTEXT,
};

export const clearViewDefinitions = () => ({ type: CLEAR_VIEWDEFINITIONS });
export const clearPageInfo = () => ({ type: CLEAR_STOREDPAGEINFO });
export const clearCurrentViewContext = () => ({ type: CLEAR_CURRENT_VIEW_CONTEXT });

export const setSelectedView = (view) => ({ type: SET_SELECTEDVIEW, payload: { view } });
export const setStoredPageInfo = (pageInfo: PageInfo) => ({
  type: SET_STOREDPAGEINFO,
  payload: pageInfo,
});
export const setStoredView = () => ({ type: SET_STOREDVIEW });

export const setCurrentViewContext = (context: ViewContext) => ({
  type: SET_CURRENT_VIEW_CONTEXT,
  payload: context,
});

export const fetchViewDefinitions = () => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_VIEWDEFINITIONS, 'GetViewDefinitions ', {});

export const addViewDefinition = (viewDef: ViewDefinition) => async (dispatch) =>
  await createThunkEffect(dispatch, ADD_VIEWDEFINITION, 'CreateViewDefinition ', { view: viewDef });

export const updateViewDefinition = (viewDef: ViewDefinition) => async (dispatch) =>
  await createThunkEffect(dispatch, UPDATE_VIEWDEFINITION, 'UpdateViewDefinition ', {
    view: viewDef,
  });

export const deleteViewDefinition = (viewDefId: string) => async (dispatch) =>
  await createThunkEffect(dispatch, DELETE_VIEWDEFINITION, 'DeleteViewDefinition ', {
    viewId: viewDefId,
  });

export const viewDefReducer = (state = intialState, action): ViewDefState => {
  switch (action.type) {
    case `${viewDefinitionTypes.FETCH_VIEWDEFINITIONS}_SUCCESS`:
      return {
        ...state,
        views: action.payload.views,
      };
    case `${viewDefinitionTypes.ADD_VIEWDEFINITION}_SUCCESS`:
      return {
        ...state,
        views: [...state.views, action.payload.view],
        selectedView: action.payload.view,
      };

    case `${viewDefinitionTypes.UPDATE_VIEWDEFINITION}_SUCCESS`:
      return {
        ...state,
        views: state.views.map((v) =>
          v.viewId === action.payload.view.viewId ? action.payload.view : v
        ),
        selectedView: action.payload.view,
      };
    case `${viewDefinitionTypes.DELETE_VIEWDEFINITION}_SUCCESS`:
      return {
        ...state,
        views: state.views.filter((v) => v.viewId !== action.payload.viewId),
      };
    case viewDefinitionTypes.SET_SELECTEDVIEW:
      return {
        ...state,
        selectedView: action.payload.view,
      };
    case viewDefinitionTypes.SET_STOREDVIEW:
      return {
        ...state,
        storedView: state.selectedView,
      };
    case viewDefinitionTypes.SET_STOREDPAGEINFO:
      return {
        ...state,
        storedPageInfo: action.payload,
      };
    case viewDefinitionTypes.CLEAR_SELECTEDVIEW:
      return {
        ...state,
        selectedView: null,
      };
    case viewDefinitionTypes.CLEAR_STOREDVIEW:
      return {
        ...state,
        storedView: null,
      };
    case viewDefinitionTypes.CLEAR_STOREDPAGEINFO:
      return {
        ...state,
        storedPageInfo: null,
      };
    case viewDefinitionTypes.CLEAR_CURRENT_VIEW_CONTEXT:
      return {
        ...state,
        viewContext: {
          page: '',
          tenantId: '',
          viewId: '',
        },
      };
    case viewDefinitionTypes.CLEAR_VIEWDEFINITIONS:
      return intialState;
    case viewDefinitionTypes.SET_CURRENT_VIEW_CONTEXT:
      return {
        ...state,
        viewContext: action.payload,
      };
    default:
      return state;
  }
};

const selectViewDefinitions = (state: any) => state.viewDefinitions.views;
export const selectSelectedView = (state): ViewDefinition => state.viewDefinitions.selectedView;
export const selectStoredView = (state): ViewDefinition => state.viewDefinitions.storedView;
export const selectStoredPageInfo = (state): PageInfo => state.viewDefinitions.storedPageInfo;
export const selectCurrentViewContext = (state): ViewContext => state.viewDefinitions.viewContext;

export const selectAllViewDefinitions = createSelector(
  selectViewDefinitions,
  (viewDefs) => viewDefs
);

export const selectEmeredViewDefinitions = createSelector(selectViewDefinitions, (viewDefs) =>
  viewDefs.filter((v) => v.category === 'device' || v.category === 'emered')
);

export const selectBoilerViewDefinitions = createSelector(selectViewDefinitions, (viewDefs) =>
  viewDefs.filter((v) => v.category === 'device' || v.category === 'boiler')
);

export const selectNetworkingViewDefinitions = createSelector(selectViewDefinitions, (viewDefs) =>
  viewDefs.filter((v) => v.category === 'device' || v.category === 'networking')
);

export const selectThirdPartyViewDefinitions = createSelector(selectViewDefinitions, (viewDefs) =>
  viewDefs.filter((v) => v.root === 'thirdParty')
);

export const selectViewDefinitionNames = createSelector(selectViewDefinitions, (viewDefs) =>
  viewDefs.map((v) => v.label)
);

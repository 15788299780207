import { GridColDef } from '@mui/x-data-grid-pro';
import { DataGridColumns } from '../types';

export const columnsList: GridColDef[] = [
  { field: DataGridColumns.PropertyReference, headerName: 'Property REF', flex: 1, minWidth: 110 },
  { field: DataGridColumns.Postcode, headerName: 'Postcode', flex: 1, minWidth: 110 },
  { field: DataGridColumns.Address, headerName: 'Address', flex: 1, minWidth: 110 },
  { field: DataGridColumns.PropertyId, headerName: 'Property Id', flex: 1, minWidth: 110 },
  { field: DataGridColumns.TenantId, headerName: 'Tenant Id', flex: 1, minWidth: 110 },
  { field: DataGridColumns.Address1, headerName: 'Address 1', flex: 1, minWidth: 110 },
  { field: DataGridColumns.Address2, headerName: 'Address 2', flex: 1, minWidth: 110 },
  { field: DataGridColumns.Address3, headerName: 'Address 3', flex: 1, minWidth: 110 },
  { field: DataGridColumns.Town, headerName: 'Town', flex: 1, minWidth: 110 },
  { field: DataGridColumns.County, headerName: 'County', flex: 1, minWidth: 110 },
  {
    field: DataGridColumns.Latitude,
    headerName: 'Latitude',
    flex: 1,
    minWidth: 110,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: DataGridColumns.Longitude,
    headerName: 'Longitude',
    flex: 1,
    minWidth: 110,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  { field: DataGridColumns.EpcRating, headerName: 'EPC Rating', flex: 1, minWidth: 110 },
  { field: DataGridColumns.VoidStatus, headerName: 'Void Flag', flex: 1, minWidth: 110 },
];

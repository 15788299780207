/* eslint-disable max-len */
import React from 'react';

const ColumnIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 26 28">
    <path d="M2.5 24h9.5v-18h-10v17.5c0 0.266 0.234 0.5 0.5 0.5zM24 23.5v-17.5h-10v18h9.5c0.266 0 0.5-0.234 0.5-0.5zM26 4.5v19c0 1.375-1.125 2.5-2.5 2.5h-21c-1.375 0-2.5-1.125-2.5-2.5v-19c0-1.375 1.125-2.5 2.5-2.5h21c1.375 0 2.5 1.125 2.5 2.5z"></path>
  </svg>
);

export default ColumnIcon;

import React from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import styled from 'styled-components';

const ChkBox = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 25px;
  /* font-weight: 500; */
  padding: 0 0.5rem;
  justify-content: space-between;

  & input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & span {
    margin-left: 0.5rem;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 20px;
    background: ${(props) => props.theme.offWhite};
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;

    & svg {
      width: 100%;
      height: 100%;
      opacity: 0;
      fill: ${(props) => props.theme.white};
      transition: all 0.3s ease-in-out;
    }
  }

  & input:checked ~ span {
    background-color: ${(props) => props.theme.primary};

    & svg {
      opacity: 1;
    }
  }
`;
const CheckBox = ({ name = '', label = 'Is True:', onChange, checked }) => {
  const handleOnClick = () => onChange(!checked, name);

  return (
    <ChkBox>
      {label}
      <input type="checkbox" id={name} name={name} onClick={handleOnClick} checked={checked} />
      <span>
        <SvgIcon type="check" />
      </span>
    </ChkBox>
  );
};
export default CheckBox;

import React from 'react';

import DatePicker from 'react-date-picker';
import styled from 'styled-components';

const SDatePicker = styled(DatePicker)<{ state: string }>`
  width: 100%;

  & svg {
    fill: #666;
    stroke: #666;
    transition: all 0.3s ease-in-out;
  }

  & > div {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #d8d8d8;
    padding: 0.5rem 1rem;
    background-color: #f7f7f7;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    box-shadow: ${(props) => {
    if (props.state) {
      return props.state === 'error'
        ? `0 0 4px 1px ${props.theme.error}`
        : `0 0 4px 1px ${props.theme.success}`;
    }

    return 'none';
  }};
  }

  & div {
    font-size: inherit;
    color: inherit;
    font-family: inherit;
  }

  & input {
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    ${(props) => {
    if (props.state) return 'border-color: transparent';
  }};
  }
`;

const DPicker = ({ onChange, date, name, state = '' }) => {
  const getDate = () => {
    if (!date) {
      return new Date();
    }
    if (date instanceof Date) {
      return date;
    }

    return new Date(date);
  };
  const d = getDate();

  d.setMinutes(0);

  return (
    <SDatePicker
      state={state}
      onChange={(value) => onChange(value, name)}
      value={d}
      clearIcon={null}
      name={name}
    />
  );
};

export default DPicker;

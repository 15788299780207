import React from 'react';

import { formatDate } from 'utils/helpers';

import DeviceFault from '../DeviceFault';

const EmeredFault = ({ testData }) => {
  const faultDetails = [
    { label: 'Date', text: formatDate(testData.testDate) },
    { label: 'Reason', text: testData.reason },
  ];

  return (
    <DeviceFault
      message="The device has not passed a test and has generated an alarm with the following details:"
      faultDetails={faultDetails}
      action={null}
    />
  );
};

export default EmeredFault;

import { createThunkEffect } from 'utils/ReduxUtils';

const FETCH_ALLTESTS = 'FETCH_ALLTESTS';
const CLEAR_ALLTESTS = 'CLEAR_ALLTESTS';
const UPDATE_TEST = 'UPDATE_TEST';

export const testActionTypes = {
  FETCH_ALLTESTS,
  CLEAR_ALLTESTS,
  UPDATE_TEST,
};

export const clearAllTests = () => ({
  type: CLEAR_ALLTESTS,
});

export const fetchAllTests = () => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_ALLTESTS, 'GetAllTests', {});

export const updateTest = (test) => ({
  type: UPDATE_TEST,
  payload: { test },
});

const initialTestsState: any[] = [];

export const testReducer = (state = initialTestsState, action) => {
  switch (action.type) {
    case `${testActionTypes.FETCH_ALLTESTS}_SUCCESS`:
      return action.payload;
    case testActionTypes.CLEAR_ALLTESTS:
      return initialTestsState;
    case testActionTypes.UPDATE_TEST: {
      const updatedTest = action.payload.test;

      return state.map((t) => {
        if (
          t.deviceId === updatedTest.deviceId &&
          t.eventId === updatedTest.eventId &&
          t.testType === updatedTest.testType
        ) {
          return updatedTest;
        }

        return t;
      });
    }
    default:
      return state;
  }
};

export const propertyLinks = [
  {
    to: 'info',
    label: 'Info',
  },
  {
    to: 'services',
    label: 'Services',
  },
  {
    to: 'devices',
    label: 'Devices',
  },
  {
    to: 'telemetry',
    label: 'Telemetry',
  },
  {
    to: 'propertyRecords',
    label: 'Property Records',
  },
];

import IAction from '../IAction';
import { alertTypes } from './alertActions';
import { tagTypes } from './tagActions';
import { TagsState } from './TagState';

const initialState: TagsState = {
  tags: [],
  alerts: [],
};

const tagsToArr = (tags) => (tags ? Object.keys(tags).map((t) => ({ id: t, name: tags[t] })) : []);

const tagReducer = (state: TagsState = initialState, action: IAction<any>): TagsState => {
  switch (action.type) {
    case tagTypes.CLEAR_TAGS:
      return initialState;
    case tagTypes.FETCH_TAGS_SUCCESS:
      return {
        tags: tagsToArr(action.payload.tags),
        alerts: action.payload.alerts,
      };
    case `${tagTypes.REFRESH_TAGS}_SUCCESS`:
      return {
        tags: tagsToArr(action.payload.tags),
        alerts: action.payload.alerts,
      };
    case tagTypes.CREATE_TAG_SUCCESS:
      return {
        ...state,
        tags: [...state.tags, { id: action.payload.id, name: action.payload.name }],
      };
    case tagTypes.DELETE_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags.filter((t) => t.id !== action.payload),
      };
    case alertTypes.CREATE_ALERT_SUCCESS:
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    case alertTypes.DELETE_ALERT_SUCCESS:
      return {
        ...state,
        alerts: state.alerts.filter((a) => a.alertId !== action.payload.id),
      };
    case alertTypes.UPDATE_ALERT_SUCCESS:
      return {
        ...state,
        alerts: state.alerts.map((a) =>
          a.alertId === action.payload.id ? action.payload.alert : a
        ),
      };
    default:
      return state;
  }
};

export default tagReducer;

import styled from 'styled-components';

export const EditTagsRowWrapper = styled.div`
  .edit-tags-row {
    &__dropdown {
      .edit-field-dropdown__select {
        min-width: 200px;
      }

      &-edit-icon {
        font-size: 2rem;
        color: ${(props) => props.theme.success};
      }
    }
  }
`;

export const EditTagsRowDeleteDialogContentWrapper = styled.div`
  .edit-tags-row {
    &__error-message {
      .field-error-messages__error {
        padding: 0;
        text-align: center;

        &:first-child {
          padding-top: 10px;
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const PropertyRecordsWrapper = styled.div`
  padding-bottom: 1rem;

  .property-records {
    &__table {
      padding-top: 24px;
      height: 0px;
      min-height: 500px;
    }
  }

  .MuiDataGrid-root {
    min-height: 0;
  }

  .MuiDataGrid-cell,
  .MuiDataGrid-cell--withRenderer {
    overflow: visible !important;
  }
`;

import React from 'react';

import CheckBox from 'components/atoms/CheckBox';
import styled from 'styled-components';

const FormItemWrapper = styled.div`
  &.chk {
    display: inline-block;
    width: 30%;
  }

  @media ${(props) => props.theme.mobileL} {
    margin: 0.5rem 0;
  }
`;

const TextArea = styled.textarea`
  ${(props) => props.theme.textAreaStyle()}
`;

const Input = styled.input`
  ${(props) => props.theme.inputStyle()}
`;

const FormItem = ({ formItem }) => {
  switch (formItem.type) {
    case 'textArea':
      return (
        <FormItemWrapper>
          <TextArea name={formItem.name} id={formItem.name} placeholder={formItem.label} />
        </FormItemWrapper>
      );
    case 'input':
      return (
        <FormItemWrapper>
          <Input name={formItem.name} id={formItem.name} placeholder={formItem.label} />
        </FormItemWrapper>
      );
    case 'checkBox':
      return (
        <FormItemWrapper className="chk">
          <CheckBox name={formItem.name} label={formItem.label} />
        </FormItemWrapper>
      );
    case 'hidden':
      return (
        <FormItemWrapper>
          <input type="hidden" name={formItem.name} value={formItem.value} />
        </FormItemWrapper>
      );
    default:
      return null;
  }
};

export default FormItem;

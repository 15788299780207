import React from 'react';

import styled from 'styled-components';

const FileInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
`;

const DefaultStyles = `
    width: 16rem;
    display: inline-block;
    padding: .75rem 1.5rem;
    border-radius: 30px;
    transition: all .3s ease-in-out;
    text-align: center;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.3);

    &:hover {
        cursor: pointer;
    }
`;

const FileLabel = styled.label`
  ${DefaultStyles}
  background-color: transparent;
  font-size: ${(props) => props.theme.textSmall};
  border: 2px solid ${(props) => props.theme.default};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease-in-out;

  &.hasFile {
    background: ${({ theme }) => theme.default};
    color: ${({ theme }) => theme.white};
  }
`;

const FileSelect = ({ fileName, setFile }) => {
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <>
      <FileLabel className={fileName ? 'hasFile' : ''}>
        {fileName ? fileName : 'Select File'}
        <FileInput type="file" name="fileupload" id="fileupload" onChange={handleFileChange} />
      </FileLabel>
    </>
  );
};

export default FileSelect;

import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import { Collapse } from '@mui/material';

import { useEffectOnce } from 'utils/hooks';
import { Route } from 'types';
import { SidebarItem } from 'components/atoms';

type SidebarSubMenuProps = { route: Route; selectedTenant: string; open: boolean };

export const SidebarSubMenu: React.FC<SidebarSubMenuProps> = ({ route, selectedTenant, open }) => {
  const subMenuItems = route.subMenuItems;
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [parentIconActive, setParentIconActive] = useState(false);

  const handleClick = () => {
    setSubMenuOpen((prev) => !prev);
  };

  const location = useLocation();

  useEffectOnce(() => {
    if (subMenuItems?.find((item) => location.pathname.includes(item.path))) {
      setSubMenuOpen(true);
    }
  });

  useEffect(() => {
    if (!subMenuOpen && subMenuItems?.find((item) => location.pathname.includes(item.path))) {
      setParentIconActive(true);
    } else {
      setParentIconActive(false);
    }
  }, [location.pathname, subMenuItems, subMenuOpen]);

  return (
    <Fragment>
      <SidebarItem
        open={open}
        route={route}
        onClick={handleClick}
        selectedTenant={selectedTenant}
        subMenuOpen={subMenuOpen}
        parentIconActive={parentIconActive}
        subMenuParent
      />
      <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
        <List component="div" style={{ backgroundColor: 'rgba(0,0,0,.3)' }} disablePadding>
          {subMenuItems?.map((subMenuItem, subMenuItemIndex) => (
            <SidebarItem
              key={subMenuItemIndex.toString()}
              open={open}
              route={subMenuItem}
              selectedTenant={selectedTenant}
            />
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
};

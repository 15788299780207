import React, { useEffect, useCallback } from 'react';
import createAuth0Client, { Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authSetup } from 'reducers/auth';
import { AuthContextInfo } from 'types/Auth';
import { createProfile } from 'types/userProfile';

export let getToken;

const config: Auth0ClientOptions = {
  domain: process.env.REACT_APP_DOMAIN!,
  client_id: process.env.REACT_APP_CLIENTID!,
  redirect_uri: process.env.REACT_APP_CALLBACKURL!,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  audience: process.env.REACT_APP_AUDIENCE!,
};

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onRedirectCallback = useCallback(
    (appState) => {
      window.history.replaceState({}, document.title, window.location.pathname);

      if (appState && appState.targetUrl) navigate(appState.targetUrl);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const initialiseAuth0 = async () => {
      const client = await createAuth0Client(config);
      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }
      const isAuthenticated = await client.isAuthenticated();
      const user = isAuthenticated ? await client.getUser() : null;

      const authInfo: AuthContextInfo = {
        user: user ? createProfile(user) : null,
        isLoading: false,
        isAuthenticated,
        login: (...p) => client.loginWithRedirect(...p),
        getTokenSilently: () => client.getTokenSilently(),
        getIdTokenClaims: (...p) => client.getIdTokenClaims(...p),
        logout: (...p) => client.logout(...p),
      };

      dispatch(authSetup(authInfo));
      getToken = async () => await client.getTokenSilently();
    };
    initialiseAuth0();
  }, [onRedirectCallback, dispatch]);

  return <>{children}</>;
};

export default AuthProvider;

import React, { useEffect } from 'react';

import ListingView from 'components/ListingView';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectBoilerViewFields } from 'reducers/fields';
import {
  ViewDefinition,
  selectSelectedView,
  selectBoilerViewDefinitions,
  setStoredView,
  ViewContext,
  selectCurrentViewContext,
} from 'reducers/viewDefinitions';
import { Field } from 'types/Listing';
import useListingViewHelpers from 'utils/hooks/useListingViewHelpers';
import useNavigate from 'utils/hooks/useNavigate';
import useTenantInfo from 'utils/hooks/useTenantInfo';

const Boilers = () => {
  const dispatch = useDispatch();
  const viewDefinitions: ViewDefinition[] = useSelector((state: any) =>
    selectBoilerViewDefinitions(state)
  );
  const fields: Field[] = useSelector((state: any) => selectBoilerViewFields(state));
  const selectedView: ViewDefinition = useSelector((state: any) => selectSelectedView(state));
  const currentViewContext: ViewContext = useSelector((state: any) =>
    selectCurrentViewContext(state)
  );

  const { goTo } = useNavigate();
  const { viewId } = useParams<any>();
  const {
    setDefaultContext,
    setListingViewContext,
    refreshViewData,
    updateSelectedView,
    updateFilters,
    updateViewColumns,
  } = useListingViewHelpers();

  const tenantInfo = useTenantInfo();

  useEffect(() => {
    //This is messy because of the way the routing has been setup, there are two cases to handle.
    const defaultRenderConditionA =
      currentViewContext?.page !== 'Boilers' && viewDefinitions && viewDefinitions.length > 0;
    const defaultRenderConditionB =
      !viewId &&
      currentViewContext?.page === 'Boilers' &&
      viewDefinitions &&
      viewDefinitions.length > 0;

    if (defaultRenderConditionA || defaultRenderConditionB) {
      const defaultView = viewDefinitions.find(
        (viewDef) => viewDef.label === 'Boilers' && viewDef.builtIn
      );
      const defaultViewId = viewId ? viewId : defaultView?.viewId;
      if (defaultViewId && tenantInfo && tenantInfo.id !== '') {
        setDefaultContext({
          tenantId: tenantInfo.id,
          page: 'Boilers',
          viewId: defaultViewId,
        });
      }
    }

    return () => {
      dispatch(setStoredView());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedView && viewId && selectedView.viewId !== viewId) {
      setListingViewContext({
        tenantId: tenantInfo.id,
        page: 'Boilers',
        viewId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId]);

  const shouldShowActions = (value: string | Array<string>): boolean => {
    if (Array.isArray(value)) {
      if (value.includes('BX')) {
        return true;
      } else if (value.includes('AF')) {
        return true;
      } else if (value.includes('ED')) {
        return true;
      } else if (value.includes('MD')) {
        return true;
      } else if (value.includes('PM')) {
        return true;
      } else {
        return false;
      }
    } else {
      switch (value) {
        case 'BX':
          return true;
        case 'AF':
          return true;
        case 'ED':
          return true;
        case 'MD':
          return true;
        case 'PM':
          return true;
        default:
          return false;
      }
    }
  };

  const showActions = shouldShowActions(
    selectedView?.filters.find((f) => f.fieldId === 'deviceProductId')?.value
  );
  const isBcm = (d) => d.deviceProductId === 'BX' || d.deviceProductId === 'BC';
  const isInstalled = (d) =>
    d.deviceStatus !== 'Inventory' && d.deviceStatus !== 'AwaitingRecycling';
  const handleRowClick = (d) => {
    if (isBcm(d)) {
      goTo('Boilers', '', d.deviceId);

      return;
    }

    if (!isInstalled(d)) {
      return;
    }

    goTo('BoilerConnectedDevice', '', d.deviceId);
  };

  return (
    <>
      {viewDefinitions.length && selectedView && (
        <ListingView
          availableViews={viewDefinitions}
          availableFields={fields}
          rowAction={handleRowClick}
          route="Boilers"
          showActions={showActions}
          refreshViewData={refreshViewData}
          updateSelectedView={updateSelectedView}
          updateFilters={updateFilters}
          updateViewColumns={updateViewColumns}
        />
      )}
    </>
  );
};

export default Boilers;

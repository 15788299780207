import React, { useState } from 'react';

import { handleApiReq } from 'utils/ApiRequestHelpers';
import useAlert from 'utils/hooks/useAlert';
import { ActionDialog } from 'components/molecules';

import { BulkActionProps } from '../../DeviceActionProps';

const uninstallDescription = `
  Uninstalling these devices will permanently remove all the data associated 
  with these devices and put them back in your inventory.
`;

const BulkUninstallDevice = ({ deviceIds, handleResetAction }: BulkActionProps) => {
  const { errorAlert, successAlert } = useAlert();
  const [sendingRequest, setSendingRequest] = useState(false);

  const handleRemoveSubmit = async () => {
    setSendingRequest(true);
    const res = await handleApiReq('BulkUninstall', { deviceIds }, 'POST');

    if (res) {
      if (res.isSuccessful) {
        successAlert('Request Successful');
      } else {
        errorAlert(res.message);
      }
    } else {
      errorAlert('Something has gone wrong, please try again');
    }
    setSendingRequest(false);
    handleResetAction();
  };

  const handleCloseDialog = () => {
    if (!sendingRequest) {
      handleResetAction();
    }
  };

  return (
    <ActionDialog
      open
      title="Bulk Uninstall Device"
      submitButtonName="Uninstall"
      sendingRequest={sendingRequest}
      handleSubmit={handleRemoveSubmit}
      onClose={handleCloseDialog}
    >
      {uninstallDescription}
    </ActionDialog>
  );
};

export default BulkUninstallDevice;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

const TableLink = styled(Link)`
  display: inline-block;
  margin-left: 1rem;
  color: ${(props) => props.theme.blue};
  font-size: ${(props) => props.theme.textSmall};
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 2px;
    background-color: ${(props) => props.theme.blue};
    left: 2px;
    bottom: -2px;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${(props) => props.theme.darkBlue};

    &::after {
      width: 100%;
      background-color: ${(props) => props.theme.darkBlue};
    }
  }
`;

export default TableLink;

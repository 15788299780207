import { ApiStatuses, CapabilitiesNames } from 'types';
import { CapabilitiesState } from '../types';

export const initialState: CapabilitiesState = {
  data: { [CapabilitiesNames.Devices]: [], [CapabilitiesNames.Properties]: [] },
  statuses: {
    fetchCapabilities: { status: ApiStatuses.Init, error: null },
  },
};

export const ERROR_MESSAGES = {
  fetchCapabilities: 'An error occurred while fetching capabilities.',
};

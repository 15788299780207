import React from 'react';

import { StyledDataGrid } from 'styles/StyledDataGrid';
import { TabContent, TabContentTitle, TabTitle } from 'styles/commonStyles';
import { PropertyServicesWrapper } from './PropertyServices.styles';
import { useServicesTableData } from './hooks';

export const PropertyServices: React.FC = () => {
  const { devicePackagesTable, managedServiceTable, isLoading } = useServicesTableData();

  return (
    <PropertyServicesWrapper>
      <TabTitle>Services</TabTitle>
      <TabContent>
        <TabContentTitle>Device Packages</TabContentTitle>
        <StyledDataGrid
          rows={devicePackagesTable.rows}
          columns={devicePackagesTable.columns}
          loading={isLoading}
          disableColumnFilter
          disableColumnMenu
          hideFooter
          disableDensitySelector
          density="standard"
          disableColumnSelector
          columnHeaderHeight={50}
          disableRowSelectionOnClick
        />
      </TabContent>
      <TabContent>
        <TabContentTitle>Managed Service Licence or Package</TabContentTitle>
        <StyledDataGrid
          rows={managedServiceTable.rows}
          columns={managedServiceTable.columns}
          loading={isLoading}
          disableColumnFilter
          disableColumnMenu
          hideFooter
          disableDensitySelector
          density="standard"
          disableColumnSelector
          columnHeaderHeight={50}
          disableRowSelectionOnClick
        />
      </TabContent>
    </PropertyServicesWrapper>
  );
};

import styled from 'styled-components';

export const SuccessWindowWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .success-window {
    &__icon {
      width: 60px;
      height: 60px;
      color: ${(props) => props.theme.darkGreen};
      margin-bottom: 10px;
    }

    &__text {
      font-size: 1.8rem;
    }
  }
`;

import React from 'react';

import { Transition } from 'react-transition-group';
import styled from 'styled-components';

const Overlay = styled.section<{ state: string }>`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1300px;
  transition: 0.5s;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  z-index: 8;
`;

const ModalContainer = styled.div<{ state: string; width: string; overflow: string }>`
  width: ${(props) => props.width};
  border-radius: 1rem;
  transform-origin: 0 100%;
  padding: 3rem;
  transform-style: preserve-3d;
  transition: all 0.5s ease-in-out;
  background-color: ${(props) => props.theme.white};
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);

  max-height: 95vh;
  overflow-y: ${(props) => props.overflow};

  @media ${(props) => props.theme.laptop} {
    width: 80%;
  }
  @media ${(props) => props.theme.mobileS} {
    width: 36rem;
  }
`;

const ModalHeader = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
`;

const ModalContents = styled.div``;

const CloseIcon = styled.div`
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding: 1rem;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 4px;
    border-radius: 50px;
    background-color: ${(props) => props.theme.secondary};
    top: 35%;
    right: 0;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

interface ModalProps {
  state: any;
  title: any;
  width?: string | undefined;
  children?: React.ReactNode;
  handleClose: any;
  overflow?: string | undefined;
}

const Modal: React.FC<ModalProps> = ({
  state,
  title,
  width = '50%',
  children,
  handleClose,
  overflow = 'auto',
}) => {
  const closeModal = (e) => {
    if (e.target.nodeName === 'SECTION') {
      handleClose();
    }
  };

  return (
    <Overlay state={state} onClick={closeModal}>
      <ModalContainer state={state} width={width} overflow={overflow}>
        <ModalHeader>
          <h2>{title}</h2>
          <CloseIcon onClick={handleClose}>
            <div />
            <div />
          </CloseIcon>
        </ModalHeader>
        <ModalContents>{children}</ModalContents>
      </ModalContainer>
    </Overlay>
  );
};

const TransitionModal = ({
  showModal,
  handleClose,
  title,
  width = '50%',
  children,
  overflow = 'auto',
}) => (
  <Transition
    in={showModal}
    timeout={{ appear: 0, enter: 0, exit: 300 }}
    mountOnEnter
    unmountOnExit
  >
    {(state) => (
      <Modal
        handleClose={handleClose}
        state={state}
        title={title}
        width={width}
        overflow={overflow}
      >
        {children}
      </Modal>
    )}
  </Transition>
);

export default TransitionModal;

import React, { useMemo, useEffect } from 'react';

import { scaleLinear, scaleUtc } from '@visx/scale';
import { useTooltip } from '@visx/tooltip';
import { extent, max, bisector } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import styled from 'styled-components';

import { useChartContext } from '../../Chart/ChartContext';
import { Card, DataProps } from '../../Configs/TelemetryConfigs';

const NumericDisplayContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MetricLabelContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const MetricLabelContainer = styled.div`
  text-align: center;
`;

const MetricContainer = styled.span`
  color: black;
  font-size: 2em;
  font-weight: bold;
`;

const LabelDiv = styled.div`
  font-weight: bold;
  text-align: center;
`;

const DateTimeDiv = styled.div`
  align-self: flex-end;
`;

export type CurveProps = {
  cardWidth: number;
  cardHeight: number;
  cardConfig: Card;
};

const formatDateTime = timeFormat('%d %b %H:%M');

const NumericDisplay = ({ cardConfig, cardHeight, cardWidth }: CurveProps) => {
  const chartContext = useChartContext();

  const { tooltipData, showTooltip } = useTooltip();

  const dataSeries = cardConfig.dataSeries[0].data;

  const getX = (d: any) => new Date(d.date);
  const getY = (d: any) => d.value;

  const bisectData = bisector<DataProps, Date>((d) => new Date(d.date)).left;

  // Scaling X Axis
  const dateScale = useMemo(
    () =>
      scaleUtc({
        domain: extent(dataSeries.series, getX) as [Date, Date],
        range: [0, cardWidth],
        clamp: true,
      }),
    [dataSeries, cardWidth]
  );

  // Scaling Y Axis
  const metricScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, max(dataSeries.series, getY) as number],
        range: [cardHeight - 0],
        clamp: true,
      }),
    [cardHeight, dataSeries]
  );

  useEffect(() => {
    if (dataSeries.series) {
      if (dataSeries.series.length > 0 && chartContext.selectedDateTime) {
        const { xPosition } = { xPosition: dateScale(chartContext.selectedDateTime) } || {
          xPosition: 0,
        };
        const dateFromXAxis = dateScale.invert(xPosition);
        const index = bisectData(dataSeries.series, dateFromXAxis, 1);

        const data = dataSeries.series[index]
          ? dataSeries.series[index]
          : dataSeries.series[index - 1];

        const yPosition = metricScale(getY(data));

        showTooltip({
          tooltipData: data,
          tooltipLeft: xPosition,
          tooltipTop: yPosition,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContext]);

  return (
    <>
      {tooltipData && (
        <NumericDisplayContainer>
          <MetricLabelContainerWrapper>
            <MetricLabelContainer>
              <MetricContainer>
                <span>
                  {getY(tooltipData) !== undefined ? getY(tooltipData).toFixed(2) : ' - '}
                </span>
              </MetricContainer>
              <LabelDiv>
                {cardConfig.dataSeries.map(({ contextLabel }) => (
                  <span key={contextLabel}>{contextLabel}</span>
                ))}
              </LabelDiv>
            </MetricLabelContainer>
          </MetricLabelContainerWrapper>
          <DateTimeDiv>{`${formatDateTime(getX(tooltipData))}`}</DateTimeDiv>
        </NumericDisplayContainer>
      )}
    </>
  );
};

export default NumericDisplay;

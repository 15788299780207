import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Input, { InputProps } from 'components/elements/Input';
import styled from 'styled-components';
import { handleApiReq } from 'utils/ApiRequestHelpers';

const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
  margin: 2rem auto;
  width: 56rem;
  padding: 3rem 6rem;

  & h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  @media ${(props) => props.theme.mobileL} {
    width: 48rem;
  }

  @media ${(props) => props.theme.mobileM} {
    width: 44rem;
  }

  @media ${(props) => props.theme.mobileS} {
    width: 36rem;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;

  & h4 {
    width: 36rem;
    margin-bottom: 1rem;

    @media ${(props) => props.theme.mobileS} {
      width: 32rem;
    }
  }
`;

const InputWrapper = styled.div`
  font-size: ${(props) => props.theme.textSmall};
  margin-bottom: 1.5rem;
  position: relative;

  & span {
    color: ${(props) => props.theme.spanBlue};
    font-weight: 500;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.textSmall};

  & button {
    margin: 0 1rem;
  }
`;

const SaveButton = styled.button`
  ${(props) => props.theme.submitButton('12rem')};
`;

const CancelButton = styled.button`
  ${(props) => props.theme.clearButton('12rem')};
`;

const TenantForm = ({ tenant }) => {
  const navigate = useNavigate();

  const [tenantInfo, setTenantInfo] = useState(tenant);
  const isNewTenant = !tenant;

  useEffect(() => {
    setTenantInfo(tenant);
  }, [tenant]);

  const handleCancelClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleSaveClick = async (e, isNewTenant) => {
    e.preventDefault();
    if (isNewTenant) {
      await handleApiReq('AddTenant', tenantInfo, 'POST');
    } else {
      await handleApiReq('EditTenant', tenantInfo, 'POST');
    }

    navigate(-1);
  };

  //add labels

  const inputs: InputProps[] = [
    {
      type: 'text',
      width: '36rem',
      inputData: {
        name: 'id',
        placeholder: 'Please enter tenant ID name',
        value: tenant ? tenant.id : '',
        readOnly: !!tenant,
        onChange: (v) => setTenantInfo((old) => ({ ...old, id: v })),
      },
    },
    {
      type: 'text',
      width: '36rem',
      inputData: {
        name: 'name',
        placeholder: 'Please enter tenant name',
        value: tenant ? tenant.name : '',
        onChange: (v) => setTenantInfo((old) => ({ ...old, name: v })),
      },
    },
    {
      type: 'text',
      width: '36rem',
      inputData: {
        name: 'contact',
        placeholder: 'Please enter tenant contact',
        value: tenant ? tenant.contact : '',
        onChange: (v) => setTenantInfo((old) => ({ ...old, contact: v })),
      },
    },
  ];

  return (
    <Wrapper>
      {isNewTenant ? <h2>Add new tenant</h2> : <h2>Update tenant</h2>}
      <form onSubmit={(e) => handleSaveClick(e, isNewTenant)}>
        <InnerWrapper>
          {inputs.map((u, i) => (
            <InputWrapper key={i}>
              <Input {...u} />
            </InputWrapper>
          ))}
        </InnerWrapper>
        <ButtonWrapper>
          <SaveButton>Save</SaveButton>
          <CancelButton onClick={(e) => handleCancelClick(e)}>Cancel</CancelButton>
        </ButtonWrapper>
      </form>
    </Wrapper>
  );
};
export default TenantForm;

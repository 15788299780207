import React, { useState } from 'react';

import styled from 'styled-components';
import { formatDate } from 'utils/helpers';

const TableOuterWrapper = styled.div`
  position: absolute;
  width: 28rem;
  top: 4.25rem;
  left: 1rem;
  z-index: 7;
  height: 90%;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const TableWrapper = styled.div`
  ${(props) => props.theme.wrapper};
  padding: 1rem 1.5rem;
  height: 100%;
  overflow-y: auto;

  h4 {
    margin-bottom: 1rem;
  }

  & table {
    ${(props) => props.theme.tableStyle};
    font-size: ${(props) => props.theme.textSmall};

    & tbody tr {
      height: 4.6rem;
    }

    & tbody tr.added {
      background-color: ${(props) => props.theme.primaryLight};
      color: ${(props) => props.theme.white};

      &:hover {
        background-color: ${(props) => props.theme.primary};
      }
    }

    & tbody tr.removed {
      opacity: 0.7;
      color: ${(props) => props.theme.error};
    }

    & tbody tr.selected {
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
    }

    & td {
      display: flex;
      align-items: center;
      line-height: 1.8;
    }

    & tbody tr.collisions td {
      display: table-cell;
    }
  }
`;

const TableSpan = styled.span`
  width: 90%;
`;

const Tabs = styled.ul`
  position: absolute;
  left: 0;
  top: -3.25rem;

  & li {
    font-size: ${(props) => props.theme.textSmall};
    display: inline-block;
    min-width: 7.5rem;
    text-align: center;
    padding: 1rem 0.5rem;
    padding-bottom: 0.5rem;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: 6px 0px 5px -5px rgba(0, 0, 0, 0.3);
    z-index: 1;
    transform: translateY(0);
    background-color: #e9e9e9;
    transition: all 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
      padding-bottom: 0.75rem;
      transform: translateY(-0.25rem);
    }

    &.active {
      background-color: #ffffff;
      padding-bottom: 1rem;
      transform: translateY(-0.5rem);
      z-index: 7;
    }
  }
`;

const Icon = styled.span`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;

  & svg {
    width: 100%;
    height: 100%;
    fill: ${(props) => props.theme.error};
  }
`;

const DeleteIcon = ({ handleClick }) => (
  <Icon onClick={handleClick}>
    <svg>
      <use href="images/icons.svg#icon-delete" />
    </svg>
  </Icon>
);

const MapContainer = ({
  groupedDevices,
  conflictingGroups,
  setSelectedDevice,
  setSelectedGroup,
  removeFromGroup,
  selectedDevice,
  selectedGroup,
}) => {
  const [selectedTab, setSelectedTab] = useState('devices');

  return (
    <TableOuterWrapper>
      <Tabs>
        <li
          onClick={() => setSelectedTab('devices')}
          role="presentation"
          className={selectedTab === 'devices' ? 'active' : ''}
        >
          Devices
        </li>
        <li
          role="presentation"
          onClick={() => setSelectedTab('collisions')}
          className={selectedTab === 'collisions' ? 'active' : ''}
        >
          Collisions
        </li>
      </Tabs>
      <TableWrapper>
        <h4>{selectedTab === 'devices' ? 'Group Devices' : 'Collisions'}</h4>
        <table>
          <tbody>
            {selectedTab === 'devices' && (
              <>
                {groupedDevices.inGroup.map((d) => (
                  <tr key={d.deviceId} className={d.deviceId === selectedDevice ? 'selected' : ''}>
                    <td>
                      <TableSpan onClick={() => setSelectedDevice(d.deviceId)}>
                        {d.deviceName}
                      </TableSpan>{' '}
                      <DeleteIcon handleClick={() => removeFromGroup(d, 'inGroup')} />
                    </td>
                  </tr>
                ))}
                {groupedDevices.addedToGroup.map((d) => (
                  <tr
                    key={d.deviceId}
                    className={`added ${d.deviceId === selectedDevice ? 'selected' : ''}`}
                  >
                    <td>
                      <TableSpan onClick={() => setSelectedDevice(d.deviceId)}>
                        {d.deviceName}
                      </TableSpan>{' '}
                      <DeleteIcon handleClick={() => removeFromGroup(d, 'addedToGroup')} />
                    </td>
                  </tr>
                ))}
                {groupedDevices.removedFromGroup.map((d) => (
                  <tr
                    key={d.deviceId}
                    className={`removed ${d.deviceId === selectedDevice ? 'selected' : ''}`}
                  >
                    <td>
                      <TableSpan onClick={() => setSelectedDevice(d.deviceId)}>
                        {d.deviceName}
                      </TableSpan>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {selectedTab === 'collisions' &&
              conflictingGroups.map((c) => (
                <tr
                  className={`collisions ${selectedGroup}` === c.groupName ? 'selected' : ''}
                  key={c.groupName}
                  onClick={() => setSelectedGroup(c.groupName)}
                >
                  <td>{c.groupName}</td>
                  <td>{formatDate(c.t180StartDate)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </TableWrapper>
    </TableOuterWrapper>
  );
};

export default MapContainer;

import { ApiErrorsType } from 'types';

export const errorMessagesMapper: Record<number, string> = {
  400: 'Request failed.',
  401: 'Unauthorized.',
  403: "You don't have permission.",
  404: 'Not found.',
  500: 'Server error.',
};

export const getApiErrorMessage = ({ response }: ApiErrorsType): string => {
  const status = response?.status;

  return errorMessagesMapper[status] || 'Something went wrong!';
};

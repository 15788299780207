import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { refreshBoilerDetails } from 'reducers/device/boilerActions';
import { BcmConnectDetails } from 'types/BoilerTypes/BcmConnectDetails';
import { Areas } from 'types/roles';
import { asStartDate, asEndDate } from 'utils/helpers';
import useAccess from 'utils/hooks/useAccess';
import LoadingOverlay from '../../atoms/LoadingOverlay';
import { Table, TableWrapper } from './ConnectedDevices.styles';
import { ConnectedDevicesTableRow } from './molecules/ConnectedDevicesTableRow';

type ConnectedDevicesProps = {
  data: BcmConnectDetails;
  triggerUpdateDevice: () => void;
};

export const ConnectedDevices: React.FC<ConnectedDevicesProps> = ({
  data,
  triggerUpdateDevice,
}) => {
  const dispatch = useDispatch();
  const { deviceId } = useParams() as { deviceId: string };

  const refreshDeviceDetails = useCallback(
    async () =>
      await dispatch(
        refreshBoilerDetails({
          deviceId,
          startDate: asStartDate(new Date()).toISOString(),
          endDate: asEndDate(new Date()).toISOString(),
        })
      ),
    [deviceId, dispatch]
  );

  const { isEdit } = useAccess(Areas.boilers);

  return (
    <section>
      <h5>Connected Devices</h5>
      <TableWrapper isFetching={false}>
        {false && <LoadingOverlay />}
        <Table>
          <thead>
            <tr>
              <th>Device</th>
              <th>Status</th>
              <th>Location</th>
              <th>Additional location Details</th>
              <th>Serial</th>
              <th>Eui64</th>
              <th>Access Rights Tag</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.connectedDevices?.map((device, deviceIndex) => (
              <ConnectedDevicesTableRow
                key={deviceIndex.toString()}
                device={device}
                isEdit={isEdit}
                deviceId={deviceId}
                refreshDeviceDetails={refreshDeviceDetails}
                triggerUpdateDevice={triggerUpdateDevice}
              />
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </section>
  );
};

import React from 'react';

import styled from 'styled-components';

const RadioInputWrapper = styled.div`
  display: flex;

  & input[type='radio'] {
    position: absolute;
    visibility: hidden;
    display: none;
  }

  label {
    display: block;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease-in-out;
    background: transparent;
    text-align: center;
    flex: 1 1 0px;
    margin: 0.5rem 0.75rem;

    border-radius: 5px;

    &.fail {
      border: 2px solid ${({ theme }) => theme.error};
    }

    &.pass {
      border: 2px solid ${({ theme }) => theme.success};
    }

    &:hover {
      cursor: pointer;
    }
  }

  input[type='radio']:checked + label {
    color: ${(props) => props.theme.white};

    &.fail {
      background: ${({ theme }) => theme.error};
    }

    &.pass {
      background: ${({ theme }) => theme.success};
    }
  }
`;

const PassElement = ({ name, handleOnClick, checked }) => (
  <>
    <input type="radio" id={'Pass'} name={name} checked={checked} />
    <label className="pass" onClick={handleOnClick} htmlFor={'Pass'} role="presentation">
      Pass
    </label>
  </>
);

const FailElement = ({ name, handleOnClick, checked }) => (
  <>
    <input type="radio" id={'Fail'} name={name} checked={checked} />
    <label className="fail" onClick={handleOnClick} htmlFor={'Fail'} role="presentation">
      Fail
    </label>
  </>
);

const PassFailInput = ({ value, onChange, name }) => (
  <RadioInputWrapper>
    <PassElement
      handleOnClick={() => onChange('Pass', name)}
      name={name}
      checked={value === 'Pass'}
    />
    <FailElement
      handleOnClick={() => onChange('Fail', name)}
      name={name}
      checked={value === 'Fail'}
    />
  </RadioInputWrapper>
);

export default PassFailInput;

import React from 'react';

import DatePicker from 'components/atoms/DatePicker';
import styled from 'styled-components';

const TextBoxWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const DateField = ({ value, onChange, name, state = '' }) => (
  <TextBoxWrapper>
    <DatePicker name={name} onChange={onChange} date={value} state={state} />
  </TextBoxWrapper>
);
export default DateField;

import React from 'react';

import ResultsGraph from 'components/elements/Graphs/ResultsGraph';
import styled from 'styled-components';
import { formatDate } from 'utils/helpers';

import Modal from '../Modal';
import TestResultEdit from './TestResultEdit';

const Wrapper = styled.div`
  display: flex;

  & > div {
    width: 50%;
    margin-right: 3rem;
  }

  @media ${(props) => props.theme.tablet} {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;

const GraphInfo = styled.div`
  font-size: ${(props) => props.theme.textSmall};

  & div {
    margin-bottom: 0.5rem;
  }

  & span {
    color: ${(props) => props.theme.darkBlue};
  }
`;

const UpdateTestModal = ({
  showUpdateModal,
  setShowUpdateModal,
  graphData,
  testData,
  handleCancel,
  handleSubmit,
}) => (
  <Modal
    handleClose={() => setShowUpdateModal(false)}
    showModal={showUpdateModal}
    title="Update test results"
    width="120rem"
  >
    <Wrapper>
      {graphData && (
        <div>
          <GraphInfo>
            <div>
              <span>Device Name: </span>
              {graphData.deviceName}
            </div>
            <div>
              <span>Tested: </span>
              {formatDate(graphData.testDate)}
            </div>
            <div>
              <span>Test Name: </span>
              {graphData.testType}
            </div>
            <div>
              <span>Battery Voltage: </span>
              {graphData.deviceBatteryVoltage}
            </div>
            <div>
              <span>Current Status: </span>
              {graphData.deviceCurrentStatus}
            </div>
          </GraphInfo>
          <ResultsGraph
            legendAlignment={'left'}
            data={graphData.series}
            xAxis={graphData.xAxis}
            isAdmin
          />
        </div>
      )}
      <TestResultEdit handleSubmit={handleSubmit} handleCancel={handleCancel} testData={testData} />
    </Wrapper>
  </Modal>
);

export default UpdateTestModal;

export const getApiUrl = ({ tenantId, propertyId }: { tenantId: string; propertyId: string }) => ({
  editDetailsUrl: `${process.env.REACT_APP_API_URL_V2}/tenants/${tenantId}/properties/${propertyId}/Details`,
  editAddressUrl: `${process.env.REACT_APP_API_URL_V2}/tenants/${tenantId}/properties/${propertyId}/Addresses`,
  postcodeUrl: `${process.env.REACT_APP_API_URL_V2}/services/addresses/`,
});

export const getEpcRatingInfoUrl = (postcode: string): string =>
  `https://find-energy-certificate.service.gov.uk/find-a-certificate/search-by-postcode?postcode=${postcode
    .replace(' ', '')
    .toLowerCase()}`;

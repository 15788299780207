import { createSlice } from '@reduxjs/toolkit';

import { ApiStatuses } from 'types';
import { generateAsyncReducers } from '../utils';
import { fetchCapabilities } from './actions';
import { ERROR_MESSAGES, initialState } from './constants';

const capabilitiesSlice = createSlice({
  name: 'capabilities',
  initialState,
  reducers: {
    clearCapabilities: (state) => {
      state.data = initialState.data;
      state.statuses = initialState.statuses;
    },
    clearCapabilitiesStatus: (state, action) => {
      const { statusKey } = action.payload;
      if (state.statuses[statusKey] !== undefined) {
        state.statuses[statusKey] = initialState.statuses[statusKey];
      }
    },
  },
  extraReducers: (builder) => {
    generateAsyncReducers({
      builder,
      thunk: fetchCapabilities,
      statusKey: 'fetchCapabilities',
      errorMessages: ERROR_MESSAGES,
      onSuccess: ({ state, action }) => {
        state.data = action.payload.data;
        state.statuses.fetchCapabilities = { status: ApiStatuses.Success, error: null };
      },
    });
  },
});

export const { clearCapabilities, clearCapabilitiesStatus } = capabilitiesSlice.actions;

export default capabilitiesSlice.reducer;

import React from 'react';

import DateTimePicker from 'react-datetime-picker';
import styled from 'styled-components';

const SDateTypePicker = styled(DateTimePicker)`
  width: 100%;

  & svg {
    fill: #666;
    stroke: #666;
    transition: all 0.3s ease-in-out;
  }

  & > div {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #d8d8d8;
    padding: 0.5rem 1rem;
    background-color: #f7f7f7;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
  }

  & div {
    font-size: inherit;
    color: inherit;
    font-family: inherit;
  }

  & input {
    font-size: inherit;
    color: inherit;
    font-family: inherit;
  }
`;

const DTPicker = ({ onChange, date, name }) => {
  const getDate = () => {
    if (!date) {
      return new Date();
    }
    if (date instanceof Date) {
      return date;
    }

    return new Date(date);
  };

  const d = getDate();
  d.setMinutes(0);

  return (
    <SDateTypePicker
      onChange={(value) => onChange(value, name)}
      value={d}
      disableClock
      clearIcon={null}
      name={name}
    />
  );
};

export default DTPicker;

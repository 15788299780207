import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import DeviceLogs from 'components/DeviceDetails/DeviceLogs';
import { fetchEmeredDetails, refreshEmeredDetails } from 'reducers/device/emeredActions';
import useShouldUpdateDevice from 'utils/hooks/useShouldUpdateDevice';
import { EmeredDevice } from 'types/EmeredTypes';
import DeviceDetails from '../../components/DeviceDetails';
import DebugInfo from '../../components/DeviceDetails/DebugInfo';
import DeviceMessages from '../../components/DeviceDetails/DeviceMessages';
import EmeredMaintenanceTab from '../../components/DeviceDetails/EmeredMaintenanceTab';
import EmergencyLightDetails from '../../components/DeviceDetails/EmergencyLightDetails';
import TestSchedules from '../../components/DeviceDetails/TestSchedules';
import TestsResults from '../../components/DeviceDetails/TestsResults';
import { useTabs } from './hooks/useTabs';

const tabsData = [
  {
    tab: 'info',
    display: 'Info',
    component: EmergencyLightDetails,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'testResults',
    display: 'Test Results',
    component: TestsResults,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'testSchedules',
    display: 'Test Schedules',
    component: TestSchedules,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'emeredHistoryLogs',
    display: 'History',
    limitedAccessTab: true,
    component: DeviceLogs,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'emeredMaintenance',
    display: 'Maintenance',
    component: EmeredMaintenanceTab,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    access: (_) => true,
  },
  {
    tab: 'debug',
    display: 'Debug',
    component: DebugInfo,
    access: (authObj) => authObj.isDev,
  },
  {
    tab: 'messages',
    display: 'Messages',
    component: DeviceMessages,
    access: (authObj) => authObj.isDev,
  },
];

const EmergencyLightsPage: React.FC = () => {
  const dispatch = useDispatch();
  const device: EmeredDevice = useSelector((state: any) => state.device.device);
  const { deviceId }: any = useParams();

  const [data] = useState();
  const tabs = useTabs({ tabsData, device });

  const refreshDeviceDetails = useCallback(
    async () => await dispatch(refreshEmeredDetails({ deviceId, startDate: new Date() })),
    [deviceId, dispatch]
  );

  const triggerUpdateDevice = useShouldUpdateDevice(deviceId, refreshDeviceDetails);

  const stateDeviceId = device?.deviceId;

  useEffect(() => {
    const fetchDeviceDetails = async () => {
      await dispatch(fetchEmeredDetails({ deviceId, startDate: new Date() }));
    };
    if (deviceId !== stateDeviceId) fetchDeviceDetails();
  }, [stateDeviceId, deviceId, dispatch]);

  return (
    <DeviceDetails
      triggerUpdateDevice={triggerUpdateDevice}
      tabs={tabs}
      device={device}
      details={data}
    />
  );
};

export default EmergencyLightsPage;

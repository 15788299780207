import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

import { PropertyContextProps } from '../types';
import { PropertyTelemetryBoard } from './PropertyTelemetryBoard';

export const PropertyTelemetry: React.FC = () => {
  const { propertyInfo, devicesInfo, bcmInfo } = useOutletContext<PropertyContextProps>();

  if (propertyInfo.isSuccess && devicesInfo.isSuccess && !bcmInfo.isLoading) {
    return <PropertyTelemetryBoard />;
  }

  return (
    <Box minHeight={300}>
      {devicesInfo.isLoading || bcmInfo.isLoading || propertyInfo.isLoading ? (
        <LinearProgress />
      ) : (
        <Typography variant="h6" align="center">
          Failed to retrieve property data
        </Typography>
      )}
    </Box>
  );
};

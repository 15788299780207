import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectIsLoading } from 'reducers/loading/selectors';
import { fetchAllTests, clearAllTests } from 'reducers/testResults';

import TestResultsTable from '../../components/Tables/TestResultsTable';

const EmergencyLightsResults = () => {
  const dispatch = useDispatch();
  const tests = useSelector((state: any) => state.tests);
  const isFetching = useSelector((state: any) => selectIsLoading(['FETCH_ALLTESTS'])(state));

  const headers = [
    {
      display: 'Date',
      property: 'testDate',
      sort: true,
    },
    {
      display: 'Device Name',
      property: 'deviceName',
      sort: true,
    },
    {
      display: 'Serial Number',
      property: 'serialNumber',
      sort: true,
    },
    {
      display: 'Test Type',
      property: 'testType',
      sort: true,
    },
    {
      display: 'Result',
      property: 'result',
      sort: true,
    },
    {
      display: 'Data',
      property: 'data',
    },
    {
      display: 'Actions',
      property: 'edit',
    },
  ];

  const csvHeaders = [
    {
      label: 'Date',
      key: 'testDate',
    },
    {
      label: 'Device Name',
      key: 'deviceName',
    },
    {
      label: 'Serial Number',
      key: 'serialNumber',
    },
    {
      label: 'Test Type',
      key: 'testType',
    },
    {
      label: 'Result',
      key: 'result',
    },
  ];

  useEffect(() => {
    const getTestSchedules = async () => {
      await dispatch(fetchAllTests());
    };
    getTestSchedules();

    return () => {
      clearAllTests();
    };
  }, [dispatch]);

  return (
    <>
      <h1>Emergency Lights - Test Results</h1>
      <TestResultsTable
        data={tests}
        headers={headers}
        csvHeaders={csvHeaders}
        isFetching={isFetching}
      />
    </>
  );
};

export default EmergencyLightsResults;

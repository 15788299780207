import React, { useState } from 'react';
import { GridRowParams } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from 'styles/StyledDataGrid';
import { DataGridColumnsHidden } from 'scenes/Properties/types';
import { TabTitle } from 'styles/commonStyles';
import { useGetFetchData, useSelectedTenant } from 'utils/hooks';
import { CustomToolbar, ImageDialog } from 'components/molecules';
import { getColumnVisibilityModel, imageToBase64String } from 'utils';
import { useRecordsTableData } from './hooks/useRecordsTableData';
import { PropertyRecordsWrapper } from './PropertyRecords.styles';

type SelectedDocument = {
  id: string;
  title: string;
};

export const PropertyRecords: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<SelectedDocument>({
    id: '',
    title: '',
  });

  const tenant = useSelectedTenant();

  const { rows, pinnedRows, columns, isLoading } = useRecordsTableData();

  const getDocument = useGetFetchData({
    url: `${process.env.REACT_APP_API_URL_V2}`,
    enableErrorHandler: true,
    retry: false,
    query: `tenants/${tenant}/documents/${selectedDocument?.id}`,
    enabled: !!selectedDocument?.id,
    axiosConfig: { responseType: 'arraybuffer' },
  });

  const handleRowClick = (params: GridRowParams) => {
    setSelectedDocument({ id: params.row.id, title: params.row.title });
    setOpenDialog(true);
  };

  const handleGetImage = () => {
    if (selectedDocument?.id) {
      getDocument.refetch();
    }
  };

  const handleOnClose = () => {
    setOpenDialog(false);
    setSelectedDocument({ id: '', title: '' });
  };

  const { columnVisibilityModel } = getColumnVisibilityModel(DataGridColumnsHidden);

  const imageSrc = selectedDocument?.id ? imageToBase64String(getDocument.data) : '';

  return (
    <PropertyRecordsWrapper>
      <TabTitle>Property Records</TabTitle>
      <div className="property-records__table">
        <StyledDataGrid
          rows={rows}
          columns={columns}
          pinnedRows={pinnedRows}
          loading={isLoading}
          disableDensitySelector
          density="standard"
          columnHeaderHeight={50}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
          pageSizeOptions={[25, 50, 100]}
          pagination
          checkboxSelection
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
            columns: {
              columnVisibilityModel,
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
      {openDialog && (
        <ImageDialog
          open
          onClose={handleOnClose}
          title={selectedDocument?.title}
          isLoading={getDocument.isLoading}
          isSuccess={getDocument.isSuccess}
          isError={getDocument.isError}
          imgSrc={imageSrc}
          imgAlt={`Document ${selectedDocument?.title}`}
          onImageRefetch={handleGetImage}
          imageData={getDocument.data}
          showDownload
        />
      )}
    </PropertyRecordsWrapper>
  );
};

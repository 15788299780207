import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getBuildingEmereds } from 'utils/deviceUtils';

import FloorSelect from './FloorSelect';
import Map from './Map';
import MapTable from './MapTable';

const MapWrapper = styled.div`
  height: 100%;
`;

const groupDevices = (devices, group, conflictingGroups) => {
  const initValue = {
    inGroup: [],
    noGroup: [],
    anotherGroup: [],
    conflictingGroup: [],
    addedToGroup: [],
    removedFromGroup: [],
  };

  const reduceFunction = (acc, currentDevice) => {
    if (!currentDevice.group) {
      acc.noGroup.push(currentDevice);
    } else if (currentDevice.group === group) {
      acc.inGroup.push(currentDevice);
    } else if (conflictingGroups.includes(currentDevice.group)) {
      acc.conflictingGroup.push(currentDevice);
    } else {
      acc.anotherGroup.push(currentDevice);
    }

    return acc;
  };

  return devices.reduce(reduceFunction, initValue);
};

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
`;

const SubmitButton = styled.button`
  ${(props) => props.theme.submitButton()};
`;

const MapContainer = ({ selectedBuilding, group, conflictingGroups, handleSubmit }) => {
  const devices = useSelector((state: any) =>
    getBuildingEmereds(selectedBuilding.building)(state.tenantInfo.emereds)
  );
  const [groupedDevices, setGroupedDevices] = useState(
    groupDevices(
      devices,
      group.groupName,
      conflictingGroups.map((c) => c.groupName)
    )
  );
  const [selectedFloor, setSelectedFloor] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');

  const handleDeviceSelect = (deviceId) =>
    selectedDevice === deviceId ? setSelectedDevice('') : setSelectedDevice(deviceId);

  const handleGroupSelect = (group) =>
    selectedGroup === group ? setSelectedGroup('') : setSelectedGroup(group);

  const selectFloor = (floor) => {
    setSelectedFloor(parseInt(floor));
  };

  useEffect(() => {
    const initView = () => {
      const floors = [...new Set(groupedDevices.inGroup.map((d) => d.floor))];
      if (floors.length >= 1) {
        selectFloor(selectedBuilding.floors.findIndex((f) => f.name === floors[0]));
      } else {
        selectFloor(0);
      }
    };
    initView();
    // TODO REFACTOR TO USEREDUCER
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGroupedDevices(
      groupDevices(
        devices,
        group.groupName,
        conflictingGroups.map((c) => c.groupName)
      )
    );
    // TODO REFACTOR TO USEREDUCER
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conflictingGroups, group.groupName]);

  const getGroup = (device) => {
    if (!device.group) {
      return 'noGroup';
    }

    if (conflictingGroups.map((c) => c.groupName).includes(device.group)) {
      return 'conflictingGroup';
    }

    return 'anotherGroup';
  };

  const handleDeviceClick = (device, groupStatus) => {
    switch (groupStatus) {
      case 'inGroup':
        handleDeviceSelect(device.deviceId);
        break;
      case 'noGroup':
      case 'anotherGroup':
      case 'conflictingGroup':
        setGroupedDevices({
          ...groupedDevices,
          [groupStatus]: groupedDevices[groupStatus].filter((d) => d.deviceId !== device.deviceId),
          addedToGroup: [...groupedDevices['addedToGroup'], device],
        });
        break;
      case 'addedToGroup':
        {
          const g = getGroup(device);

          setGroupedDevices({
            ...groupedDevices,
            addedToGroup: groupedDevices['addedToGroup'].filter(
              (d) => d.deviceId !== device.deviceId
            ),
            [g]: [...groupedDevices[g], device],
          });
        }
        break;

      case 'removedFromGroup':
        setGroupedDevices({
          ...groupedDevices,
          removedFromGroup: groupedDevices['removedFromGroup'].filter(
            (d) => d.deviceId !== device.deviceId
          ),
          inGroup: [...groupedDevices['inGroup'], device],
        });
        break;
      default:
        break;
    }
  };

  const removeFromGroup = (device, groupStatus) => {
    if (groupStatus === 'inGroup') {
      setGroupedDevices({
        ...groupedDevices,
        inGroup: groupedDevices['inGroup'].filter((d) => d.deviceId !== device.deviceId),
        removedFromGroup: [...groupedDevices['removedFromGroup'], device],
      });
    } else {
      const g = getGroup(device);

      setGroupedDevices({
        ...groupedDevices,
        addedToGroup: groupedDevices['addedToGroup'].filter((d) => d.deviceId !== device.deviceId),
        [g]: [...groupedDevices[g], device],
      });
    }
  };

  return (
    <MapWrapper>
      <MapTable
        groupedDevices={groupedDevices}
        conflictingGroups={conflictingGroups}
        selectedDevice={selectedDevice}
        selectedGroup={selectedGroup}
        setSelectedDevice={handleDeviceSelect}
        setSelectedGroup={handleGroupSelect}
        removeFromGroup={removeFromGroup}
      />
      <FloorSelect
        selectedFloor={selectedFloor}
        setSelectedFloor={selectFloor}
        floors={selectedBuilding.floors}
      />
      <Map
        selectedDevice={selectedDevice}
        selectedGroup={selectedGroup}
        handleDeviceClick={handleDeviceClick}
        devices={groupedDevices}
        selectedBuilding={selectedBuilding}
        floor={selectedBuilding.floors[selectedFloor]}
      />
      <ButtonWrapper>
        <SubmitButton
          onClick={() =>
            handleSubmit(
              [...groupedDevices.inGroup, ...groupedDevices.addedToGroup].map((d) => d.deviceId)
            )
          }
        >
          Save
        </SubmitButton>
      </ButtonWrapper>
    </MapWrapper>
  );
};

export default MapContainer;

import React from 'react';

import LoadingOverlay from 'components/atoms/LoadingOverlay';
import { FailedToLoadingOverlay } from 'components/atoms';
import { WidgetContainerLink } from '../../atoms';
import { WidgetContent, WidgetHeader, WidgetWrapper } from './WidgetContainer.styles';

interface WidgetContainerProps {
  title: string;
  to: string | null;
  children?: React.ReactNode;
  style?: { gridColumn: string; gridRow: string };
  canRead: boolean;
  isFetching: boolean;
  isFailedToLoad: boolean;
  className?: string;
}

export const WidgetContainer: React.FC<WidgetContainerProps> = ({
  title,
  to,
  children,
  style,
  canRead,
  isFetching,
  isFailedToLoad,
  className,
}) => (
  <WidgetWrapper
    isFetching={isFetching}
    {...(!!className && { className })}
    {...(style && { style })}
  >
    {isFailedToLoad && <FailedToLoadingOverlay />}
    {isFetching && <LoadingOverlay />}
    <WidgetHeader>
      <WidgetContainerLink to={to}
        title={title}
        canRead={canRead} />
    </WidgetHeader>
    <WidgetContent className="widget-container__content">{children}</WidgetContent>
  </WidgetWrapper>
);

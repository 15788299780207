import React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatDate } from 'utils/helpers';
import { styleVars } from 'styles/variables';

import { MdCheckCircle, MdInfoOutline } from 'react-icons/md';
import useAccess from 'utils/hooks/useAccess';
import { Areas } from '../../types/roles';

const Table = styled.table`
  ${(props) => props.theme.tableStyle};
`;

const TestSchedules = () => {
  const testSchedules = useSelector(
    (state: any) => state.device.testSchedule
  );

  const { isDev } = useAccess(Areas.lighting);

  return (
    <section>
      <h5>Test Schedules</h5>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Test Type</th>
            {isDev && <th>Synced</th> }
          </tr>
        </thead>
        <tbody>
          {testSchedules.length ? (
            testSchedules.map((ds, i) => (
              <tr key={i}>
                <td>{formatDate(ds.testDate)}</td>
                <td>T{ds.duration}</td>
                {isDev &&
                  <td>{ds.isSynced ?
                    <MdCheckCircle color={styleVars.darkGreen} /> :
                    <MdInfoOutline color={styleVars.vericonOrange}/>
                  }</td>
                }
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={isDev ? 3 : 2} style={{ textAlign: 'center' }}>
                No tests scheduled
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </section>
  );
};

export default TestSchedules;

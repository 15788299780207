import React, { useCallback, useEffect } from 'react';

import Loading from 'components/Loading';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectSelectedView } from 'reducers/viewDefinitions';
import { handleApiReqWithRawResponse } from 'utils/ApiRequestHelpers';
import useAlert from 'utils/hooks/useAlert';
import useNavigate from 'utils/hooks/useNavigate';
import useTenantInfo from 'utils/hooks/useTenantInfo';

type GetBcmConnectedDeviceResponse = {
  bcmId: string;
};

const BcmConnectedDevicePage = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const tenantInfo = useTenantInfo();
  const { errorAlert } = useAlert();

  const { goTo } = useNavigate();

  const selectedView = useSelector((state: any) => selectSelectedView(state));

  const redirectToBcmPage = useCallback(async () => {
    try {
      const apiFetchResponse = await handleApiReqWithRawResponse('GetBcmConnectedDevice', {
        deviceId: deviceId,
      });

      const apiResponse: GetBcmConnectedDeviceResponse = await apiFetchResponse.json();

      // Todo: When possible, redirect to the page and include a parameter to have the "connected devices" tab selected
      goTo(`Boilers/${apiResponse.bcmId}`);
    } catch {
      errorAlert('Failed to find associated BCM');

      if (selectedView) {
        goTo('Boilers', 'Listing', selectedView.viewId);
      } else {
        goTo('Boilers');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, tenantInfo, selectedView]);

  useEffect(() => {
    redirectToBcmPage();
  }, [redirectToBcmPage]);

  return <Loading></Loading>;
};

export default BcmConnectedDevicePage;

import { Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { EllipsisWithTooltip, StatusChip } from 'components/atoms';
import { DataGridColumns } from '../types';

export const genericColumnsList: GridColDef[] = [
  { field: DataGridColumns.PropertyReference, headerName: 'Property REF', flex: 1, minWidth: 110 },
  {
    field: DataGridColumns.Status,
    headerName: 'Device Status',
    flex: 1,
    minWidth: 120,
    renderCell: (params) => (
      <div className="devices-status-chips">
        <StatusChip status={params.value} />
      </div>
    ),
  },
  { field: DataGridColumns.SerialNumber, headerName: 'Serial Number', flex: 1, minWidth: 110 },
  { field: DataGridColumns.Postcode, headerName: 'Postcode', flex: 1, minWidth: 110 },
  {
    field: DataGridColumns.Address,
    headerName: 'Address',
    flex: 1,
    minWidth: 110,
    renderCell: (params) => (
      <div style={{ width: '100%' }}>
        <EllipsisWithTooltip text={params.value} />
      </div>
    ),
  },
  { field: DataGridColumns.AccessRightTag, headerName: 'Access Right Tag', flex: 1, minWidth: 110 },
  { field: DataGridColumns.Manufacturer, headerName: 'Manufacturer', flex: 1, minWidth: 110 },
  { field: DataGridColumns.Model, headerName: 'Model', flex: 1, minWidth: 110 },
  { field: DataGridColumns.BoilerGC, headerName: 'Boiler GC', flex: 1, minWidth: 110 },
  {
    field: DataGridColumns.FirmwareVersion,
    headerName: 'Firmware Version',
    flex: 1,
    minWidth: 110,
  },
  { field: DataGridColumns.DeviceId, headerName: 'Device Id', flex: 1, minWidth: 110 },
  { field: DataGridColumns.ProductName, headerName: 'Product', flex: 1, minWidth: 110 },
  { field: DataGridColumns.Location, headerName: 'Location', flex: 1, minWidth: 110 },
  {
    field: DataGridColumns.LocationDetails,
    headerName: 'Location Details',
    flex: 1,
    minWidth: 110,
  },
  { field: DataGridColumns.Product, headerName: 'Product Id', flex: 1, minWidth: 110 },
  {
    field: DataGridColumns.Tags,
    headerName: 'Tags',
    flex: 1,
    minWidth: 120,
    renderCell: (params) => (
      <div className="properties__chips">
        {params.value?.map((tag, tagIndex) => (
          <Chip component="span" key={tagIndex.toString()} label={tag} />
        ))}
      </div>
    ),
  },
];

export const bcmsColumnsList = [
  ...genericColumnsList,
  { field: DataGridColumns.FaultCode, headerName: 'Fault code', flex: 1, minWidth: 110 },
];

import { PropertyDevice, PropertyLocation } from 'types';

type TransformDevicesDataProps = {
  devices: PropertyDevice[];
  propertyLocations?: PropertyLocation[];
};

export const transformDevicesData = ({ propertyLocations, devices }: TransformDevicesDataProps) =>
  devices.map((device) => {
    const currentDeviceLocation = propertyLocations?.find(
      (location) => location.id === device.propertyLocationId
    );

    return {
      ...device,
      location: currentDeviceLocation?.name || null,
      locationDetails: currentDeviceLocation?.details || null,
    };
  });

import { createThunkEffect, createThunkWithFailure } from 'utils/ReduxUtils';

const FETCH_BOILERDETAILS = 'FETCH_BOILERDETAILS';
const REFRESH_BOILERDETAILS = 'REFRESH_BOILERDETAILS';
const TOGGLE_CHSETPOINT = 'TOGGLE_CHSETPOINT';
const TOGGLE_BUZZER = 'TOGGLE_BUZZER';
const UPDATE_CHSETPOINT = 'UPDATE_CHSETPOINT';
const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
const UPDATE_OCCUPIERID = 'UPDATE_OCCUPIERID';

const FETCH_BOILERFAULTS = 'FETCH_BOILERFAULTS';
const FETCH_BOILERMETRICS = 'FETCH_BOILERMETRICS';

const FETCH_BOILERRECORDS = 'FETCH_BOILERRECORDS';
const ADD_GASSAFETY = 'ADD_GASSAFETY';
const ADD_WATERTREATMENT = 'ADD_WATERTREATMENT';

export const boilerDetailTypes = {
  REFRESH_BOILERDETAILS,
  FETCH_BOILERDETAILS,
  TOGGLE_CHSETPOINT,
  TOGGLE_BUZZER,
  UPDATE_CHSETPOINT,
  UPDATE_ADDRESS,
  UPDATE_OCCUPIERID,
  FETCH_BOILERFAULTS,
  FETCH_BOILERMETRICS,
  FETCH_BOILERRECORDS,
  ADD_GASSAFETY,
  ADD_WATERTREATMENT,
};

interface BoilerReq {
  deviceId: string;
  startDate: string;
  endDate: string;
}

export const fetchBoilerDetails = (req: BoilerReq) => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_BOILERDETAILS, 'GetBoiler', req);

export const refreshBoilerDetails = (req: BoilerReq) => async (dispatch) =>
  await createThunkEffect(dispatch, REFRESH_BOILERDETAILS, 'GetBoiler', req);

export const updateChSetPoint = (deviceId, chSetPoint) => async (dispatch) =>
  await createThunkWithFailure(dispatch, UPDATE_CHSETPOINT, 'UpdateChSetPoint', {
    deviceId,
    chSetPoint,
  });

export const updateOccupierAddress = (deviceId, occupierAddress) => async (dispatch) =>
  await createThunkWithFailure(dispatch, UPDATE_ADDRESS, 'UpdateOccupierAddress', {
    deviceId,
    occupierAddress,
  });
export const updateOccupierId = (deviceId, occupierId) => async (dispatch) =>
  await createThunkWithFailure(dispatch, UPDATE_OCCUPIERID, 'UpdateOccupierId', {
    deviceId,
    occupierId,
  });

export const toggleChSetPoint = (deviceId, isLimited) => async (dispatch) =>
  await createThunkWithFailure(dispatch, TOGGLE_CHSETPOINT, 'ToggleCHAccess', {
    deviceId,
    isLimited,
  });

export const toggleBuzzer = (deviceId, isLimited) => async (dispatch) =>
  await createThunkWithFailure(dispatch, TOGGLE_BUZZER, 'ToggleBoilerBuzzer', {
    deviceId,
    isLimited,
  });

export const fetchBoilerRecords = (deviceId) => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_BOILERRECORDS, 'GetBoilerRecords', { deviceId }, true);

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TagsType } from '../types';
import { removeDefaultTag } from '../utils';

export const useReportTags = () => {
  const tags = useSelector((state: any) => removeDefaultTag(state.tags?.tags));
  const userTags = useSelector((state: any) => state.auth.user.tags);

  const [selectedTags, setSelectedTags] = useState<TagsType[]>([]);

  const handleTagChange = ({ target }) => {
    const { value } = target;

    setSelectedTags(Array.from(new Set(value)));
  };

  const preSelectedTags = useMemo(
    () => tags.filter((tag) => userTags.includes(tag.id)),
    [tags, userTags]
  );

  const uniqueSelectedTags = useMemo(
    () => Array.from(new Set([...preSelectedTags, ...selectedTags])),
    [preSelectedTags, selectedTags]
  );

  return {
    handleTagChange,
    selectedTags: uniqueSelectedTags,
    tags,
  };
};

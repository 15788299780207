import React, { useState, useRef } from 'react';

import styled from 'styled-components';
import useAccess from 'utils/hooks/useAccess';
import useClickAway from 'utils/hooks/useClickAway';

import { bulkActions, BulkActionType } from '../actions';
import { ActionsList } from '../ActionsList';
import BulkActionSelector from './BulkActionSelector';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  z-index: 2;
`;

const Span = styled.span<{ isDisabled: boolean }>`
  display: inline-block;
  padding: 0.75rem 1rem;
  font-size: 1.4rem;
  opacity: ${(props) => (props.isDisabled ? 0.3 : 0.7)};
  transition: all 0.5s ease-in-out;
  background: transparent;
  color: inherit;

  ${(props) =>
    props.isDisabled
      ? ''
      : `&:hover {
        background: #ececf7;
        cursor: pointer;
        opacity: 1;
    }`};
`;

const actions = bulkActions;

const JoinedNetworkStatuses = [
  'Ok',
  'RepairedDevice',
  'RepairedEquipment',
  'Installed',
  'FailedDevice',
  'FailedEquipment',
  'PreventativeMaintenance',
];

const InstalledStatuses = [...JoinedNetworkStatuses, 'Allocated'];

const BulkActions = ({
  deviceIds,
  isDisabled,
  refreshView,
  selectedDevicesStatuses,
  area,
  deviceDetails,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<BulkActionType>('none');

  const { isEdit, isManager } = useAccess(area);

  const wrapper = useRef<HTMLDivElement>(null);

  const resetAction = () => {
    setAction('none');
    refreshView();
  };

  const handleClick = async () => {
    if (!isDisabled) setIsOpen(!isOpen);
  };

  useClickAway(wrapper, () => setIsOpen(false));

  const allJoinedNetwork = selectedDevicesStatuses.every((status) =>
    JoinedNetworkStatuses.includes(status)
  );
  const allInstalled = selectedDevicesStatuses.every((status) =>
    InstalledStatuses.includes(status)
  );

  return (
    <Wrapper ref={wrapper}>
      <Span onClick={handleClick} isDisabled={isDisabled}>
        Actions
      </Span>
      <ActionsList className={isOpen ? 'open' : ''}>
        {actions.map(
          (a) =>
            a.shouldDisplay({
              isManager: isManager,
              isEng: isEdit,
              allJoinedNetwork,
              allInstalled,
              area,
            }) && (
              <li
                key={a.id}
                onClick={() => {
                  setAction(a.id);
                  setIsOpen(false);
                }}
                role="presentation"
              >
                {a.label}
              </li>
            )
        )}
      </ActionsList>
      <BulkActionSelector
        actionType={action}
        deviceIds={deviceIds}
        deviceDetails={deviceDetails}
        handleResetAction={resetAction}
      />
    </Wrapper>
  );
};

export default BulkActions;

import React from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import Input from 'components/elements/Input';
import styled from 'styled-components';

import FloorUpload from './FloorUpload';
import PositionFloorLink from './PositionFloorLink';

const SaveButton = styled.button`
  ${(props) => props.theme.submitButton('8rem')};
  font-size: ${(props) => props.theme.textSmall};
`;

const IconWrapper = styled.span`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;

  &:hover {
    cursor: pointer;
  }

  & svg {
    height: 100%;
    width: 100%;
  }

  &.trash svg {
    fill: ${(props) => props.theme.failed};
  }

  &.close svg {
    fill: ${(props) => props.theme.grey};
  }

  &.edit svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const Icon = ({ type, onClick }) => (
  <IconWrapper className={type} onClick={onClick}>
    <SvgIcon type={type} />
  </IconWrapper>
);

const FloorRow = ({
  floor,
  editFloor,
  setEditFloor,
  isEdit,
  onSaveClick,
  onCancelClick,
  onRemoveClick,
  handleEditClick,
  canEdit,
  building,
  index,
}) => {
  if (isEdit) {
    return (
      <tr>
        <td>
          <Input
            type="text"
            inputData={{
              name: 'name',
              value: editFloor?.name,
              onChange: (v) => setEditFloor({ ...editFloor, name: v }),
              autoFocus: true,
              handleFocus: (e) => e.target.select(),
            }}
          />
        </td>
        {!canEdit && <td>{editFloor.image}</td>}
        <td>
          <SaveButton onClick={() => onSaveClick(editFloor)}>Save</SaveButton>
          <Icon type="close" onClick={onCancelClick} />
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td>{floor.name}</td>
      {!canEdit && (
        <td>
          {floor.hasFloorPlan ? `${floor.name}.svg` : 'No floorplan'}
          {floor.hasFloorPlan && <PositionFloorLink building={building} floor={index} />}
        </td>
      )}
      <td>
        {canEdit ? (
          <>
            <Icon type="edit" onClick={handleEditClick} />
            <Icon type="trash" onClick={onRemoveClick} />
          </>
        ) : (
          <FloorUpload hasFloorPlan={floor.hasFloorPlan} floor={floor.name} building={building} />
        )}
      </td>
    </tr>
  );
};

export default FloorRow;

export const classNames = (...args) => {
  const getClassNames = (...args) =>
    args.reduce((classes, arg) => {
      if (!arg) return classes;
      const argType = typeof arg;
      if (argType === 'string') {
        return [...classes, arg];
      }
      if (Array.isArray(arg)) {
        const c = getClassNames(...arg);

        return [...classes, ...c];
      }
      if (argType === 'object') {
        for (const key in arg) {
          if (arg[key]) classes.push(key);
        }

        return classes;
      }
    }, []);

  return getClassNames(...args).join(' ');
};

import { getToken } from 'utils/contexts/AuthProvider';

class HttpError extends Error {
  status: number;
  constructor(status: number, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError);
    }

    this.name = 'HttpError';
    this.status = status;
  }
}

let currentSelectedTenant = null;

export const setTenant = (tenant) => (currentSelectedTenant = tenant);

export const handleApiReq = async (
  url: string,
  body: { [key: string]: unknown },
  method: string = 'POST'
) => {
  try {
    body.tenant = currentSelectedTenant;
    const response = await fetch(process.env.REACT_APP_APIURL + url, {
      method: method,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${await getToken()}`,
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const data = await response.json();

      return data;
    }

    return false;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);

    return false;
  }
};

export const handleApiReqFileDownload = async ({
  url,
  body = {},
  method = 'POST',
}: {
  url: string;
  body?: { [key: string]: unknown };
  method?: 'POST' | 'GET';
}) => {
  try {
    const response = await fetch(process.env.REACT_APP_APIURL + url, {
      method: method,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${await getToken()}`,
        'Content-Disposition': 'please',
      },
      ...(method === 'POST' && {
        body: JSON.stringify({ ...body, tenant: currentSelectedTenant }),
      }),
    });

    if (response === null || response.headers === null || !response.ok) {
      return false;
    }

    const filename = response?.headers?.get('Content-Disposition')?.split('filename=')[1];

    const blobApiResponse = await response.blob();

    const urlObject = URL.createObjectURL(blobApiResponse);

    const downloadLink = document.createElement('a');

    downloadLink.href = urlObject;
    if (filename) {
      downloadLink.download = filename;
    }

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    URL.revokeObjectURL(urlObject);

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);

    return false;
  }
};

export const handleApiReqWithRawResponse = async (url, body, method = 'POST') => {
  if (body) {
    body.tenant = currentSelectedTenant;
  }

  const response = await fetch(process.env.REACT_APP_APIURL + url, {
    method: method,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${await getToken()}`,
    },
    ...(body && { body: JSON.stringify(body) }),
  });

  return response;
};

export const handleReduxApiReq = async (url, body, method = 'POST') => {
  body.tenant = currentSelectedTenant;
  const response = await fetch(process.env.REACT_APP_APIURL + url, {
    method: method,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${await getToken()}`,
    },
    body: JSON.stringify(body),
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  } else {
    throw new HttpError(response.status);
  }
};

export const handleFormReq = async (url, form, method = 'POST') => {
  form.append('tenant', currentSelectedTenant);
  const response = await fetch(process.env.REACT_APP_APIURL + url, {
    method: method,
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
    body: form,
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  }

  return false;
};

import React from 'react';

import styled from 'styled-components';

const OverlayWrapper = styled.div`
  ${(props) => props.theme.wrapper}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  height: auto;
  padding: 1rem 2rem 1rem 1.5rem;
  width: 45rem;
  top: 2.5rem;
  left: 6rem;
  z-index: 9;
  transition: all 0.3s ease-in-out;
  opacity: 0.7;
  font-size: ${(props) => props.theme.textSmall};

  @media ${(props) => props.theme.tablet} {
    left: 2rem;
    width: 25rem;
  }

  &:hover {
    opacity: 1;
  }

  &.closed {
    opacity: 0.4;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const InputWrapper = styled.div`
  padding: 1rem;
  width: 100%;

  @media ${(props) => props.theme.tablet} {
    padding: 0;
  }

  & div {
    margin: 1rem;
  }

  & label {
    display: inline-block;
    width: 10rem;
  }

  & input {
    ${(props) => props.theme.inputStyle()};
  }
`;

const SearchButton = styled.button`
  ${(props) => props.theme.defaultButton()}
`;

const ClearButton = styled.button`
  ${(props) => props.theme.clearButton()}
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  visibility: visible;
  transition: all 0.3s ease-in-out 0.2s;

  .closed & {
    visibility: hidden;
    display: none;
  }
`;

const ImageEditInterface = ({ floor, updateFloor, handleCancel, handleSave }) => (
  <OverlayWrapper>
    <InputWrapper>
      <div>
        <label htmlFor="width">Width: </label>
        <input
          onChange={(e) => updateFloor(e)}
          type="number"
          name="width"
          defaultValue={floor.width}
        />
      </div>
      <div>
        <label htmlFor="height">Height: </label>
        <input
          type="number"
          onChange={(e) => updateFloor(e)}
          name="height"
          defaultValue={floor.height}
        />
      </div>
      <div>
        <label htmlFor="rotation">Rotation: </label>
        <input
          type="number"
          onChange={(e) => updateFloor(e)}
          name="rotation"
          defaultValue={floor.rotation}
        />
      </div>
      <div>
        <label htmlFor="lat">Latitude: </label>
        <input type="number" name="lat" value={floor.lat} readOnly />
      </div>
      <div>
        <label htmlFor="lng">Longitude: </label>
        <input type="number" name="lng" value={floor.lng} readOnly />
      </div>
    </InputWrapper>
    <ButtonWrapper>
      <SearchButton onClick={handleSave}>Save</SearchButton>
      <ClearButton onClick={handleCancel}>Cancel</ClearButton>
    </ButtonWrapper>
  </OverlayWrapper>
);
export default ImageEditInterface;

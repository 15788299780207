import React from 'react';

const Row = ({ rowData, rowActions, properties, showActionsInRow }) => {
  const showActions: boolean = showActionsInRow(rowData);

  return (
    <tr>
      {properties.map((p, i) => (
        <td key={i}>{p.displayFn ? p.displayFn(rowData[p.property]) : rowData[p.property]}</td>
      ))}
      <td>{showActions && rowActions}</td>
    </tr>
  );
};

export default Row;

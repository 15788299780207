import React, { useMemo, useEffect } from 'react';
import { scaleUtc } from '@visx/scale';
import { useTooltip } from '@visx/tooltip';
import { extent, bisector } from 'd3-array';

import { useChartContext } from 'utils/contexts';
import { CardExtended, DataProps } from 'types/Telemetry';
import {
  DateTimeDiv,
  LabelDiv,
  MetricContainer,
  MetricLabelContainer,
  MetricLabelContainerWrapper,
  NumericDisplayContainer,
} from './NumericDisplay.styles';
import { getX, formatDateTime } from './utils';

export type NumericDisplayProps = {
  cardWidth: number;
  chart: CardExtended;
};

export const NumericDisplay: React.FC<NumericDisplayProps> = ({ chart, cardWidth }) => {
  const chartContext = useChartContext();

  const { tooltipData, showTooltip } = useTooltip();

  const dataSeries = chart.dataSeries;

  const bisectData = bisector<DataProps, Date>((d) => new Date(d.date)).left;

  const dateScaleXAxis = useMemo(
    () =>
      scaleUtc({
        domain: extent(dataSeries, getX) as [Date, Date],
        range: [0, cardWidth],
        clamp: true,
      }),
    [dataSeries, cardWidth]
  );

  useEffect(() => {
    if (dataSeries && dataSeries.length > 0 && chartContext.selectedDateTime) {
      const xPosition = dateScaleXAxis(chartContext.selectedDateTime) || 0;
      const dateFromXAxis = dateScaleXAxis.invert(xPosition);
      const index = bisectData(dataSeries, dateFromXAxis, 1);
      const data = dataSeries[index] ?? dataSeries[index - 1];

      showTooltip({
        tooltipData: data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContext]);

  return (
    <>
      {tooltipData && (
        <NumericDisplayContainer>
          <MetricLabelContainerWrapper>
            <MetricLabelContainer>
              <MetricContainer>
                <span>{(tooltipData as { value: number }).value?.toFixed(2) ?? ' - '}</span>
              </MetricContainer>
              <LabelDiv>
                <span>{chart.chartLabel}</span>
              </LabelDiv>
            </MetricLabelContainer>
          </MetricLabelContainerWrapper>
          <DateTimeDiv>{`${formatDateTime(getX(tooltipData))}`}</DateTimeDiv>
        </NumericDisplayContainer>
      )}
    </>
  );
};

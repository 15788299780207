import { createSlice } from '@reduxjs/toolkit';

import { ApiStatuses } from 'types';
import { ApiActionNames } from 'reducers/types';
import { generateAsyncReducers } from '../utils';
import {
  createUser,
  deleteUser,
  fetchUsers,
  updateUserDetails,
  updateUserRole,
  updateUserTag,
} from './actions';
import { ERROR_MESSAGES, initialState } from './constants/user';
import { updateUserPartialInSlice } from './utils/updateUserPartialInSlice';

const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUsers: (state) => {
      state.data = initialState.data;
      state.statuses = initialState.statuses;
    },
    clearUsersStatus: (state, action) => {
      const { statusKey } = action.payload;
      if (state.statuses[statusKey] !== undefined) {
        state.statuses[statusKey] = initialState.statuses[statusKey];
      }
    },
  },
  extraReducers: (builder) => {
    generateAsyncReducers({
      builder,
      thunk: fetchUsers,
      statusKey: ApiActionNames.FetchUsers,
      errorMessages: ERROR_MESSAGES,
      onSuccess: ({ state, action }) => {
        const { results } = action.payload.data;

        state.data = results.map(({ id, name, role, tags, email }) => ({
          name,
          role,
          email,
          id,
          accessRightsTag: tags[0]?.value || null,
        }));
        state.statuses[ApiActionNames.FetchUsers] =
          initialState.statuses[ApiActionNames.FetchUsers];
      },
    });
    generateAsyncReducers({
      builder,
      thunk: createUser,
      statusKey: ApiActionNames.CreateUser,
      errorMessages: ERROR_MESSAGES,
      onSuccess: ({ state }) => {
        state.statuses[ApiActionNames.CreateUser] = { status: ApiStatuses.Success, error: null };
      },
    });
    generateAsyncReducers({
      builder,
      thunk: updateUserDetails,
      statusKey: ApiActionNames.UpdateUserDetails,
      errorMessages: ERROR_MESSAGES,
      onSuccess: ({ state, action }) => {
        updateUserPartialInSlice({ state, action, statusKey: ApiActionNames.UpdateUserDetails });
      },
    });
    generateAsyncReducers({
      builder,
      thunk: updateUserRole,
      statusKey: ApiActionNames.UpdateUserRole,
      errorMessages: ERROR_MESSAGES,
      onSuccess: ({ state, action }) => {
        updateUserPartialInSlice({ state, action, statusKey: ApiActionNames.UpdateUserRole });
      },
    });
    generateAsyncReducers({
      builder,
      thunk: updateUserTag,
      statusKey: ApiActionNames.UpdateUserTag,
      errorMessages: ERROR_MESSAGES,
      onSuccess: ({ state, action }) => {
        updateUserPartialInSlice({ state, action, statusKey: ApiActionNames.UpdateUserTag });
      },
    });
    generateAsyncReducers({
      builder,
      thunk: deleteUser,
      statusKey: ApiActionNames.DeleteUser,
      errorMessages: ERROR_MESSAGES,
      onSuccess: ({ state, action }) => {
        const { id } = action.payload;

        state.data = state.data.filter((user) => user.id !== id);
        state.statuses[ApiActionNames.DeleteUser] = { status: ApiStatuses.Success, error: null };
      },
    });
  },
});

export const { clearUsers, clearUsersStatus } = usersSlice.actions;

export default usersSlice.reducer;

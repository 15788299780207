import { AccessRightsTag } from 'reducers/accessRightsTags/types';
import { User } from 'reducers/users/types';

export type UsersGroupedByTagWithTagNamesProps = {
  accessRightsTags: AccessRightsTag[];
  groups: { [key: string]: User[] } | undefined;
};

export const usersGroupedByTagWithTagNames = ({
  accessRightsTags,
  groups,
}: UsersGroupedByTagWithTagNamesProps) =>
  groups
    ? Object.entries(groups).map(([groupId, groupUsers]) => ({
      groupName: accessRightsTags.find((tag) => tag.id === groupId)?.displayName || groupId,
      groupUsers,
    }))
    : [];

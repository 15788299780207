import { useRef, useState } from 'react';

const getProps = (obj) =>
  Object.keys(obj).reduce((acc, cur) => ({ ...acc, [cur]: { isValid: false, err: '' } }), {});

const useValidateForm = (values, schema) => {
  const [isValid, setIsValid] = useState(false);
  const [validationState, setValidationState] = useState(getProps(schema));

  const timeOut = useRef<ReturnType<typeof setTimeout>>();

  // if property isn't in schema return empty string - don't want it validating
  const validateValue = (value, name) =>
    schema[name]
      ? schema[name].reduce((acc, cur) => (acc === '' && !cur.fn(value) ? cur.msg : acc), '')
      : '';

  const setValid = (name) =>
    setValidationState((prevState) => ({
      ...prevState,
      [name]: { isValid: true, err: '' },
    }));

  const setErrorMsg = (name, err) =>
    setValidationState((prevState) => ({
      ...prevState,
      [name]: {
        isValid: false,
        err: err,
      },
    }));

  const validateInput = (value, name, delay = true) => {
    const err = validateValue(value, name);
    clearTimeout(timeOut.current);

    if (!err) {
      setValid(name);

      return err;
    }

    if (delay) {
      timeOut.current = setTimeout(() => setErrorMsg(name, err), 1000);
    } else {
      setErrorMsg(name, err);
    }

    return err;
  };

  const validateForm = (setErrors = true) => {
    const formValid = setErrors
      ? Object.keys(values).reduce(
        (acc, cur) => (validateInput(values[cur], cur, false) ? false : acc),
        true
      )
      : Object.keys(values).reduce(
        (acc, cur) => (validateValue(values[cur], cur) ? false : acc),
        true
      );
    setIsValid(formValid);

    return formValid;
  };

  const validateInputAndForm = (value, name) => {
    validateInput(value, name);
    validateForm(false);
  };

  const resetErrors = () => setValidationState(getProps(schema));

  return {
    isValid,
    validationState,
    validateForm,
    validateInputAndForm,
    validateInput,
    resetErrors,
  };
};

export default useValidateForm;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { ApiMethod } from 'types';
import { handleReduxToolkitApiRequest } from 'utils/hooks/APIRequestsHandlers/handleReduxToolkitApiRequest';
import { FeaturesApi } from './types';

export const fetchFeatures = createAsyncThunk<
  AxiosResponse<FeaturesApi>,
  void,
  { rejectValue: string }
>('features/fetchFeatures', async (_, { rejectWithValue, getState, dispatch }) => {
  const state: any = getState();
  const currentTenant = state.auth.user?.selectedTenant ?? '';

  return handleReduxToolkitApiRequest({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_API_URL_V2}internal/tenants/${currentTenant}/features`,
    rejectWithValue,
    dispatch,
    enableErrorHandler: true,
  });
});

import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';

import { DialogHeader, DialogSuccessMessage, DialogSuccessMessageProps } from 'components/atoms';
import { ActionDialogWrapper } from './ActionDialog.styles';

export interface ActionDialogProps {
  open: boolean;
  onClose: () => void;
  handleSubmit?: () => void | Promise<void>;
  title: string;
  children: React.ReactNode;
  submitButtonName?: string;
  hideCancelButton?: boolean;
  submitButtonIcon?: React.ReactNode;
  sendingRequest?: boolean;
  isDisabled?: boolean;
  submitButtonColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  cancelButtonText?: string;
  successMessageProps?: DialogSuccessMessageProps;
  dialogProps?: Partial<DialogProps>;
  fullScreen?: boolean;
  fullScreenMargin?: number;
  dialogBorderRadius?: number;
  component?: React.ElementType;
}

export const ActionDialog: React.FC<ActionDialogProps> = ({
  open,
  title,
  children,
  submitButtonName,
  submitButtonIcon = null,
  submitButtonColor = 'primary',
  cancelButtonText = 'Cancel',
  hideCancelButton,
  sendingRequest,
  handleSubmit,
  onClose,
  successMessageProps,
  isDisabled,
  fullScreen,
  fullScreenMargin = 0,
  dialogProps = {},
  dialogBorderRadius = 0,
  component,
}) => (
  <Dialog
    open={open}
    onClose={() => !sendingRequest && onClose()}
    disableScrollLock
    {...(fullScreen
      ? {
        fullScreen: true,
        PaperProps: {
          style: {
            margin: fullScreenMargin,
            height: `calc(100% - ${fullScreenMargin * 2}px)`,
            borderRadius: dialogBorderRadius,
          },
        },
      }
      : { PaperProps: { sx: { maxWidth: 800, borderRadius: dialogBorderRadius } } })}
    {...dialogProps}
    {...(!!component && { component })}
  >
    <DialogSuccessMessage dialogClosed={!open} {...successMessageProps}>
      <ActionDialogWrapper className="action-dialog__wrapper" fullScreen={fullScreen}>
        <DialogHeader onClose={onClose} disabled={!!sendingRequest} title={title} />
        <DialogContent sx={{ m: 0, p: 3, pt: 0 }} className="action-dialog__content-wrapper">
          <Typography component="div" className="action-dialog__content">
            {children}
          </Typography>
        </DialogContent>
        <DialogActions className="action-dialog__buttons">
          {submitButtonName && (
            <Button
              variant="contained"
              disabled={sendingRequest || isDisabled}
              color={submitButtonColor}
              onClick={handleSubmit}
              aria-label="action-dialog-submit"
              startIcon={sendingRequest ? <CircularProgress size={20} /> : submitButtonIcon}
            >
              {submitButtonName}
            </Button>
          )}
          {!hideCancelButton && (
            <Button
              disabled={sendingRequest}
              variant="contained"
              color="secondary"
              onClick={onClose}
            >
              {cancelButtonText}
            </Button>
          )}
        </DialogActions>
      </ActionDialogWrapper>
    </DialogSuccessMessage>
  </Dialog>
);

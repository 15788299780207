import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import { PropertyContextProps } from 'routes/Property/types';
import { FeaturesAreas, PropertyDevice, PropertyTelemetryOptions } from 'types';
import { BcmMonitoring } from 'types/BoilerTypes/BcmConnectDetails';
import { useAreaFeatures } from 'utils/hooks';
import { handleGenerateTelemetryView } from '../utils';

export const useTelemetryViews = () => {
  const { devicesInfo, bcmInfo } = useOutletContext<PropertyContextProps>();

  const devices = devicesInfo.data?.results as PropertyDevice[];
  const vericonFeatures = useAreaFeatures(FeaturesAreas.System).features;
  const isSurveyorCube = bcmInfo.data?.monitoring === BcmMonitoring.None;
  const isHeatPump = bcmInfo.data?.monitoring === BcmMonitoring.HeatPump;

  return useMemo(() => {
    const {
      generatePropertySummeryCharts,
      generateBoilerMonitoringCharts,
      generateVericonDiagnostics,
      generateAssetManagementCharts,
      generateHeatPumpMonitoringCharts,
    } = handleGenerateTelemetryView({ isSurveyorCube });

    const getBcmMonitoring = () => {
      if (!devices?.length || isSurveyorCube) {
        return {};
      }

      if (isHeatPump) {
        return {
          [PropertyTelemetryOptions.HeatPumpMonitoring]: generateHeatPumpMonitoringCharts({
            devices,
          }),
        };
      }

      return {
        [PropertyTelemetryOptions.BoilerMonitoring]: generateBoilerMonitoringCharts({
          devices,
        }),
      };
    };

    const vericonDiagnostics = vericonFeatures.SystemDebug
      ? { [PropertyTelemetryOptions.VericonDiagnostics]: generateVericonDiagnostics({ devices }) }
      : {};

    const assetManagement = isHeatPump
      ? {}
      : { [PropertyTelemetryOptions.AssetManagement]: generateAssetManagementCharts({ devices }) };

    return {
      [PropertyTelemetryOptions.PropertySummary]: generatePropertySummeryCharts({ devices }),
      ...getBcmMonitoring(),
      ...assetManagement,
      ...vericonDiagnostics,
    };
  }, [devices, isHeatPump, isSurveyorCube, vericonFeatures.SystemDebug]);
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { ApiMethod, ProductName } from 'types';
import { handleReduxToolkitApiRequest } from 'utils/hooks/APIRequestsHandlers/handleReduxToolkitApiRequest';

export const fetchProductNames = createAsyncThunk<
  AxiosResponse<{ results: ProductName[] }>,
  void,
  { rejectValue: string }
>('productNames/fetchProductNames', async (_, { rejectWithValue, dispatch }) =>
  handleReduxToolkitApiRequest({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_API_URL_V2}/devices/productnames`,
    rejectWithValue,
    dispatch,
    enableErrorHandler: true,
  })
);

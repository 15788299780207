import React, { useState } from 'react';

import Input from 'components/elements/Input';
import TagItem from 'components/elements/Tag';
import { useSelector, useDispatch } from 'react-redux';
import { updateDeviceTags } from 'reducers/device/deviceActions';
import { selectTags } from 'reducers/tags/selectors';
import styled from 'styled-components';
import { contains } from 'utils/helpers';

import { DeviceActionProps } from '../DeviceActionProps';
import { DeviceActionMainDialog } from '../Dialogs';

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  width: 90%;
  margin: 2rem;
  min-height: 15rem;

  & > h3 {
    flex: 1 100%;
    width: 100%;
    margin: 0 0 1.4rem;
    font-weight: 500;
    font-size: 1.8rem;
  }
`;

const UpdateTags = ({ device, handleResetAction }: DeviceActionProps) => {
  const dispatch = useDispatch();
  const tags = useSelector((state: any) => selectTags(state));

  const [deviceTags, setDeviceTags] = useState(device.tags);

  const handleRemoveTag = (tagId) => setDeviceTags(deviceTags.filter((t) => t !== tagId));
  const handleAddTag = (tagId) => setDeviceTags([...deviceTags, tagId]);

  const handleSubmit = async () => {
    await dispatch(updateDeviceTags(device.deviceId, deviceTags));
    handleResetAction();
  };

  return (
    <DeviceActionMainDialog handleResetAction={handleResetAction} handleSubmit={handleSubmit}>
      <h3>Update Device Tags</h3>
      <Input
        type="dropdown"
        width="20rem"
        inputData={{
          name: 'newTag',
          options: tags
            .map((t) => ({ value: t.id, display: t.name }))
            .filter((t) => !contains(t.value, device.tags)),
          placeholder: 'Select Tag',
          onChange: handleAddTag,
        }}
      />
      <TagsWrapper>
        {deviceTags
          .filter((t) => t !== 'DEFAULT')
          .map((t) => (
            <TagItem
              key={t}
              tag={tags.find((tg) => tg.id === t)}
              handleRemoveClick={handleRemoveTag}
              showRemove
            />
          ))}
      </TagsWrapper>
    </DeviceActionMainDialog>
  );
};

export default UpdateTags;

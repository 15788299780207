import { User } from 'reducers/users/types';
import { NO_TAG_GROUP } from '../constants/users';

export type SortGroups = { groupName: string; groupUsers: User[] }[];

export const sortGroups = (groups: SortGroups) => {
  const noTagGroup = groups.find((group) => group.groupName === NO_TAG_GROUP);

  const sortedGroups = [...groups]
    .filter((group) => group.groupName !== NO_TAG_GROUP)
    .sort((a, b) => a.groupName.localeCompare(b.groupName));

  if (noTagGroup) {
    sortedGroups.unshift(noTagGroup);
  }

  return sortedGroups;
};

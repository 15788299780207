import { handleReduxApiReq } from '../../utils/ApiRequestHelpers';
import { mapDeviceTypesTo2Let, getDeviceFormsEndpoint } from '../../utils/deviceUtils';

const FETCH_REPAIRFORMS_REQUEST = 'FETCH_REPAIRFORMS_REQUEST';
const FETCH_REPAIRFORMS_ERROR = 'FETCH_REPAIRFORMS_ERROR';
const FETCH_REPAIRFORMS_SUCCESS = 'FETCH_REPAIRFORMS_SUCCESS';

export const repairFormTypes = {
  FETCH_REPAIRFORMS_REQUEST,
  FETCH_REPAIRFORMS_ERROR,
  FETCH_REPAIRFORMS_SUCCESS,
};

const fetchRepairFormRequest = () => ({
  type: FETCH_REPAIRFORMS_REQUEST,
});

const fetchRepairFormSuccess = (repairForms) => ({
  type: FETCH_REPAIRFORMS_SUCCESS,
  payload: { repairForms },
});

const fetchRepairFormError = (error) => ({
  type: FETCH_REPAIRFORMS_ERROR,
  payload: { error },
});

export const fetchRepairFormAction = async (dispatch, deviceType, deviceId) => {
  dispatch(fetchRepairFormRequest());
  try {
    const reqBody = {
      deviceType: mapDeviceTypesTo2Let(deviceType),
      deviceId: deviceId,
    };
    const data = await handleReduxApiReq(getDeviceFormsEndpoint(deviceType), reqBody, 'POST');
    dispatch(
      fetchRepairFormSuccess({
        data,
        deviceId,
      })
    );
  } catch (e) {
    dispatch(fetchRepairFormError(e));
  }
};

export const fetchRepairForms = (deviceId, deviceType) => async (dispatch) => {
  await fetchRepairFormAction(dispatch, deviceType, deviceId);
};

const initialState = {
  forms: null,
};

export const repairFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case repairFormTypes.FETCH_REPAIRFORMS_SUCCESS:
      return {
        ...state,
        forms: action.payload.repairForms,
      };
    default:
      return state;
  }
};

import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedStatus } from 'reducers/filters';
import styled from 'styled-components';

const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const Label = styled.span`
  display: inline-block;
  width: 10rem;
  padding: 1rem 0.5rem;
  position: relative;
  text-align: center;
  font-family: inherit;
  font-size: 1.2rem;
  margin: -1px;
  font-weight: 600;
  transition: all 600ms ease;
`;
const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0;
  border-radius: 30px;
  border: 3px solid ${(props) => props.theme.darkGreen};
  transition: 0.4s;
  background: ${({ theme }) => theme.white};

  &:before {
    position: absolute;
    content: '';
    width: 50%;
    height: 100%;
    left: -1px;
    background-color: ${(props) => props.theme.darkGreen};
    transition: 0.4s;
    border-radius: 30px 0 0 30px;
  }
`;

const Check = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    border: 3px solid ${(props) => props.theme.darkBlue};

    &:before {
      transform: translateX(104%);
      border-radius: 0 30px 30px 0;
      background-color: ${(props) => props.theme.darkBlue};
    }
  }

  & ~ ${Label}:nth-of-type(2) {
    color: white;
  }

  &:checked ~ ${Label}:nth-of-type(2) {
    color: inherit;
  }

  &:checked ~ ${Label}:last-child {
    color: white;
  }
`;

const StatusSwitch = () => {
  const dispatch = useDispatch();
  const selectedStatus = useSelector((state: any) => state.filters.selectedStatus);

  const handleStatusSwitch = () => {
    if (selectedStatus === 'device') {
      dispatch(setSelectedStatus('connection'));
    } else {
      dispatch(setSelectedStatus('device'));
    }
  };

  useEffect(() => {
    dispatch(setSelectedStatus('device'));
  }, [dispatch]);

  return (
    <SwitchContainer htmlFor="switchStatus">
      <Check id="switchStatus" onChange={handleStatusSwitch} name="switchStatus" type="checkbox" />
      <Slider />
      <Label>Device</Label>
      <Label>Connection</Label>
    </SwitchContainer>
  );
};

export default StatusSwitch;

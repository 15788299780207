import React, { useState, useEffect } from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import Input from 'components/elements/Input';
import Modal from 'components/elements/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addDeviceTag, removeDeviceTag } from 'reducers/device/deviceActions';
import { selectTags } from 'reducers/tags/selectors';
import styled from 'styled-components';
import { Device } from 'types/DeviceTypes/Device';
import { Areas } from 'types/roles';
import { contains } from 'utils/helpers';
import useAccess from 'utils/hooks/useAccess';
import { EmeredDevice } from 'types/EmeredTypes';
import { NetworkingDevice } from 'types/NetworkingDevice';

import TagItem from '../../elements/Tag';

interface DeviceTagsProps {
  device: Device | EmeredDevice | NetworkingDevice;
  limited: boolean;
}

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & h6 {
    margin: 1rem;
    font-size: ${(props) => props.theme.textSmall};
    font-weight: 400;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem;

  & > button {
    margin: 0 1rem;
  }
`;

const ConfirmButton = styled.button`
  ${(props) => props.theme.defaultButton()};
`;

const CancelButton = styled.button`
  ${(props) => props.theme.clearButton()};
`;

const AddButton = styled.span`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;

  &:hover {
    cursor: pointer;
  }

  & svg {
    width: 100%;
    height: 100%;
    fill: ${(props) => props.theme.darkGreen};
  }
`;

const DeviceTags: React.FC<DeviceTagsProps> = ({ device, limited }) => {
  const { isManager } = useAccess(Areas.misc);
  const dispatch = useDispatch();
  const tags = useSelector((state: any) => selectTags(state));

  const [deviceTags, setDeviceTags] = useState<string[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');

  useEffect(() => {
    setDeviceTags(device.tags);
  }, [device]);

  const handleAddTag = async (tagId) => {
    await dispatch(addDeviceTag(device.deviceId, tagId));
    setIsAdding(false);
  };

  const handleRemoveTag = (tagId) => {
    setSelectedTag(tagId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTag('');
  };

  const handleConfirmRemoveTag = async () => {
    await dispatch(removeDeviceTag(device.deviceId, selectedTag));
    handleCloseModal();
  };

  return (
    <>
      <section>
        <h5>Tags</h5>
        <TagsWrapper>
          {deviceTags.length && tags.length ? (
            deviceTags.map((t) => (
              <TagItem
                key={t}
                tag={tags.find((tg) => tg.id === t)}
                handleRemoveClick={handleRemoveTag}
                showRemove={isManager && limited}
              />
            ))
          ) : (
            <h6>No Tags</h6>
          )}

          {isManager && limited &&
            (isAdding ? (
              <Input
                type="dropdown"
                width="20rem"
                inputData={{
                  name: 'newTag',
                  options: tags
                    .map((t) => ({ value: t.id, display: t.name }))
                    .filter((t) => !contains(t.value, device.tags)),
                  placeholder: 'Select Tag',
                  onChange: handleAddTag,
                }}
              />
            ) : (
              <AddButton onClick={() => setIsAdding(true)}>
                <SvgIcon type="plus" />
              </AddButton>
            ))}
        </TagsWrapper>
      </section>
      <Modal showModal={showModal} handleClose={handleCloseModal} title="Remove Device Tag">
        <p>Are you sure you want to remove {tags.find((t) => t.id === selectedTag)?.name}?</p>
        <ButtonWrapper>
          <ConfirmButton onClick={handleConfirmRemoveTag}>Confirm</ConfirmButton>
          <CancelButton onClick={handleCloseModal}>Cancel</CancelButton>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default DeviceTags;

import styled from 'styled-components';

export const ResidentsSettingsWrapper = styled.div`
padding: 20px;
  .residence {
    &__title {
        padding-bottom: 20px;
    }
  }
`;

import React, { useState } from 'react';

import { ListingActions } from 'components/DeviceActions';
import Input from 'components/elements/Input';

import TableCell from './TableCell';

const TableRow = ({
  data,
  columns,
  selectedRows,
  tableRef,
  toggleSelectedRow,
  rowAction,
  refreshView,
  showActions,
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <tr onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <td>
        {showActions && !data.userHasReadOnlyAccess && (
          <Input
            type="check"
            inputData={{
              checked: !!selectedRows.get(data.deviceId),
              onChange: () => toggleSelectedRow(data.deviceId),
            }}
          />
        )}
      </td>
      {columns.map((h) => (
        <TableCell
          handleOnClick={() => rowAction(data)}
          key={`${h.key}_${data.deviceId}`}
          dataType={h.dataType}
          value={data[h.key]}
          property={h.key}
        />
      ))}
      <td>
        {showActions && (
          <ListingActions
            devicePartial={{
              deviceId: data.deviceId,
              deviceType: data.deviceProductId,
              deviceStatus: data.deviceStatus,
              serialNumber: data.deviceSerial,
              tags: [],
              inWholesale: data.deviceInWholesale,
              userHasReadOnlyAccess: data.userHasReadOnlyAccess,
              userCanUpdateAccessRightsTag: data.userCanUpdateAccessRightsTag,
              accessRightsTag: '',
            }}
            tableRef={tableRef}
            showButton={isHover}
            refreshView={refreshView}
          />
        )}
      </td>
    </tr>
  );
};

export default TableRow;

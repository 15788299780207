import { createThunkEffect } from 'utils/ReduxUtils';

const CLEAR_DEVICE = 'CLEAR_DEVICE';

const FETCH_MESSAGES = 'FETCH_MESSAGES';
const FETCH_DEBUGINFO = 'FETCH_DEBUGINFO';
const ADD_DEVICETAG = 'ADD_DEVICETAG';
const REMOVE_DEVICETAG = 'REMOVE_DEVICETAG';
const FETCH_SHOULDDEVICEUPDATE = 'FETCH_SHOULDDEVICEUPDATE';
const UPDATE_DEVICETAGS = 'UPDATE_DEVICETAGS';
const UPDATE_LOCATION = 'UPDATE_LOCATION';
const UPDATE_MONITORED_LED_CIRCUIT = 'UPDATE_MONITORED_LED_CIRCUIT';

export const deviceActionTypes = {
  FETCH_SHOULDDEVICEUPDATE,
  CLEAR_DEVICE,
  FETCH_MESSAGES,
  FETCH_DEBUGINFO,
  ADD_DEVICETAG,
  REMOVE_DEVICETAG,
  UPDATE_DEVICETAGS,
  UPDATE_LOCATION,
  UPDATE_MONITORED_LED_CIRCUIT,
};

export const shouldDeviceUpdate = (deviceId, date) => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_SHOULDDEVICEUPDATE, 'ShouldUpdateDevice', {
    deviceId,
    date,
  });

export const fetchDeviceMessages = (deviceId, date) => async (dispatch) =>
  await createThunkEffect(dispatch, FETCH_MESSAGES, 'GetDeviceMessages', { deviceId, date });

export const addDeviceTag = (deviceId, tagId) => async (dispatch) =>
  await createThunkEffect(dispatch, ADD_DEVICETAG, 'AddTagToDevice', { deviceId, tagId });

export const updateDeviceTags = (deviceId, tagIds) => async (dispatch) =>
  await createThunkEffect(dispatch, UPDATE_DEVICETAGS, 'UpdateDeviceTags', { deviceId, tagIds });

export const removeDeviceTag = (deviceId, tagId) => async (dispatch) =>
  await createThunkEffect(dispatch, REMOVE_DEVICETAG, 'RemoveTagFromDevice', { deviceId, tagId });

export const updateDeviceLocation = (deviceId, room) => async (dispatch) =>
  await createThunkEffect(dispatch, UPDATE_LOCATION, 'UpdateDeviceBuildingLocation', {
    deviceId,
    room,
  });

export const updateMonitoredLedCircuit = (deviceId, monitoredLedCircuit) => async (dispatch) =>
  await createThunkEffect(
    dispatch,
    UPDATE_MONITORED_LED_CIRCUIT,
    'UpdateEmeredMonitoredLedCircuit',
    {
      deviceId,
      monitoredLedCircuit,
    }
  );

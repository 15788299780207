import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import { DialogSuccessMessage, MarkDown } from 'components/atoms';
import { ResidenceTableMarkDownDialogWrapper } from './ResidenceTableMarkDownDialog.styles';
import { useResidenceTableMarkDownDialog } from './hooks/useResidenceTableMarkDownDialog';

type ResidenceTableMarkDownDialogProps = {
  onClose: () => void;
  placeholder: string;
  title: string;
  userId: string;
};

export const ResidenceTableMarkDownDialog: React.FC<ResidenceTableMarkDownDialogProps> = ({
  onClose,
  placeholder,
  title,
  userId,
}) => {
  const {
    handleMarkdownChange,
    deleteMutation,
    updateMutation,
    isContentLoading,
    markdownText,
    isContentInitial,
    docsData,
  } = useResidenceTableMarkDownDialog({ userId });

  const handleDeleteMessage = () => {
    deleteMutation.mutate({});
  };

  const handleUpdateMassage = () => {
    const docFile = docsData?.fileName || 'SavingsAndTips.md';
    const docId = docsData?.id || '';

    const formData = new FormData();
    const blob = new Blob([markdownText], {
      type: 'text/markdown',
    });

    formData.append('content', blob, docFile);
    formData.append('documentId', docId);
    formData.append('userId', userId);
    formData.append('contentType', 'text/markdown');
    formData.append('documentType', 'SavingsAndTips');

    updateMutation.mutate(formData);
  };

  const isSuccess = updateMutation.isSuccess || deleteMutation.isSuccess;
  const sendingRequest = deleteMutation.isLoading || updateMutation.isLoading;

  const isSaveDisabled = !markdownText || sendingRequest || isContentInitial;
  const isDeleteDisabled = !markdownText || sendingRequest || !isSaveDisabled;

  return (
    <Dialog
      open
      fullWidth
      PaperProps={{ sx: { maxWidth: 800 } }}
      onClose={!sendingRequest ? onClose : undefined}
    >
      <DialogSuccessMessage
        message={`Savings and tips ${
          updateMutation.isSuccess ? 'updated' : 'deleted'
        } successfully!`}
        showMessage={isSuccess}
        onClose={onClose}
      >
        <ResidenceTableMarkDownDialogWrapper>
          <div className="residence-table-markdown-dialog__top">
            <IconButton
              aria-label="close"
              disabled={sendingRequest}
              onClick={onClose}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <DialogTitle className="residence-table-markdown-dialog__title">{title}</DialogTitle>
          </div>
          <DialogContent
            className="residence-table-markdown-dialog__content"
            sx={{ m: 0, p: 4, pt: 0 }}
          >
            <MarkDown
              markdownText={markdownText}
              placeholder={placeholder}
              onChange={handleMarkdownChange}
            />
            <Backdrop open={isContentLoading} sx={{ position: 'absolute' }} invisible>
              <CircularProgress color="primary" />
            </Backdrop>
          </DialogContent>
          <DialogActions className="residence-table-markdown-dialog__buttons">
            <Button
              variant="contained"
              disabled={isSaveDisabled}
              onClick={handleUpdateMassage}
              aria-label="residence-table-markdown-dialog-submit"
              startIcon={updateMutation.isLoading ? <CircularProgress size={20} /> : <SaveIcon />}
            >
              Save
            </Button>
            <Button
              disabled={isDeleteDisabled}
              variant="contained"
              color="error"
              onClick={handleDeleteMessage}
              startIcon={deleteMutation.isLoading ? <CircularProgress size={20} /> : <DeleteIcon />}
            >
              Delete
            </Button>
            <Button
              disabled={sendingRequest}
              variant="contained"
              color="secondary"
              onClick={onClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </ResidenceTableMarkDownDialogWrapper>
      </DialogSuccessMessage>
    </Dialog>
  );
};

import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { addDataGridPinColumn } from 'utils';
import { BcmInfo, BcmMonitoring } from 'types/BoilerTypes/BcmConnectDetails';
import { BcmsDisplayType, FeaturesAreas, Property } from 'types';
import {
  useAppSelector,
  useAreaFeatures,
  useDataGridPinnedRows,
  useGetFetchData,
  useSelectedTenant,
} from 'utils/hooks';
import { transformBcmsRows } from '../utils';
import { bcmsColumnsList, genericColumnsList } from '../constants';

export const useTableData = () => {
  const tenant = useSelectedTenant();
  const tagIds = useAppSelector((state) => state.tags.tags);
  const productNames = useAppSelector((state) => state.productNames.data);
  const accessRightsTags = useAppSelector((state) => state.accessRightsTags.accessRightsTags);

  const showNewBcmsPage = useAreaFeatures(FeaturesAreas.Boilers).features.NewListingView;

  const propertiesInfo = useGetFetchData({
    url: process.env.REACT_APP_API_URL_V2 as string,
    query: `tenants/${tenant}/properties`,
    enableErrorHandler: true,
  });

  const bcmInfo = useGetFetchData({
    url: process.env.REACT_APP_API_URL_V2 as string,
    query: `tenants/${tenant}/bcms`,
    enabled: propertiesInfo.isSuccess,
    enableErrorHandler: true,
  });

  const bcmsData = useMemo(
    () => (bcmInfo.data?.results || []) as BcmInfo[],
    [bcmInfo.data?.results]
  );
  const properties = useMemo(
    () => (propertiesInfo.data?.results || []) as Property[],
    [propertiesInfo.data?.results]
  );

  const [searchParams] = useSearchParams();
  const displayType = searchParams.get('type') as BcmsDisplayType | null;
  const showAshpPage = displayType === BcmsDisplayType.ASHP || !showNewBcmsPage;

  const bcms = bcmsData.filter((bcm) => {
    if (showAshpPage) {
      return bcm.monitoring === BcmMonitoring.HeatPump;
    }

    return true;
  });

  const transformedRows = useMemo(
    () =>
      transformBcmsRows({
        bcms,
        properties,
        tagIds,
        accessRightsTags,
        productNames,
      }) || [],
    [accessRightsTags, bcms, properties, tagIds, productNames]
  );

  const { pinnedRows, rows, pinnedRowsIds, setPinnedRowsIds } = useDataGridPinnedRows({
    rows: transformedRows,
  });

  const columnsList = showAshpPage ? genericColumnsList : bcmsColumnsList;

  const columns = useMemo(
    () => addDataGridPinColumn({ pinnedRowsIds, setPinnedRowsIds, columnsList }),
    [columnsList, pinnedRowsIds, setPinnedRowsIds]
  );

  const isLoading = propertiesInfo.isLoading || bcmInfo.isLoading;

  return { rows, columns, isLoading, pinnedRows };
};

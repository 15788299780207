import { useMemo, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { scaleTime, scaleBand } from '@visx/scale';

import { EventProps } from 'types/Telemetry';
import { useChartContext } from 'utils/contexts';
import { paddingX, paddingY, lineThickness } from '../constants/eventTimeline';

type UseEventTimelineCalculationsProps = {
  graphWidth: number;
  graphHeight: number;
  dateRange: Moment[];
  selectedEventList: Array<EventProps>;
  updateSelectedEventList: (eventList: Array<EventProps>) => void;
  eventSeries?: EventProps[];
  showTooltip: (parameter) => void;
};

export const useEventTimelineCalculations = ({
  graphHeight,
  graphWidth,
  dateRange,
  selectedEventList,
  updateSelectedEventList,
  eventSeries,
  showTooltip,
}: UseEventTimelineCalculationsProps) => {
  const chartContext = useChartContext();

  const dateScaleXAxis = useMemo(
    () =>
      scaleTime({
        domain: [
          moment(dateRange[0]).startOf('day').toDate(),
          moment(dateRange[1]).endOf('day').toDate(),
        ],
        range: [0 + paddingX, graphWidth - paddingX],
        clamp: true,
      }),
    [dateRange, graphWidth]
  );

  const metricScaleYAxis = useMemo(
    () =>
      scaleBand({
        domain: ['Low', 'Medium', 'High'],
        range: [graphHeight - paddingY, paddingY],
        padding: 0.1,
      }),
    [graphHeight]
  );

  const normalizeTheData = useMemo(
    () =>
      (data?: EventProps[]): EventProps[] | undefined => {
        let innerData = data;

        if (data) {
          innerData = data.map((series) => {
            //Round x position to the nearest line thickness (width of the data point on the graph)
            const roundedStartPosition =
              Math.round(dateScaleXAxis(new Date(series.start)) / lineThickness) * lineThickness;
            const roundedEndPosition =
              Math.round(dateScaleXAxis(new Date(series.end)) / lineThickness) * lineThickness;

            series.startNormalized = dateScaleXAxis.invert(roundedStartPosition);
            series.endNormalized = dateScaleXAxis.invert(roundedEndPosition);

            return series;
          });
        }

        return innerData;
      },
    [dateScaleXAxis]
  );

  const data = normalizeTheData(eventSeries);

  useEffect(() => {
    if (data) {
      if (chartContext.selectedDateTime) {
        //Get the index from the synced date and then the x and y position for the tooltip
        const { xPosition } = { xPosition: dateScaleXAxis(chartContext.selectedDateTime) } || {
          xPosition: 0,
        };
        const cursorStartDate = dateScaleXAxis.invert(xPosition - lineThickness / 2);
        const cursorEndDate = dateScaleXAxis.invert(xPosition + lineThickness / 2);
        const dateFromXAxis = dateScaleXAxis.invert(xPosition);

        const dataFiltered = data.filter((event) => {
          const start = moment(event.startNormalized);
          const end = moment(event.endNormalized);

          if (start.isSame(end)) {
            if (start.isBetween(cursorStartDate, cursorEndDate)) {
              return event;
            }
          } else {
            if (moment(dateFromXAxis).isBetween(moment(event.start), moment(event.end))) {
              return event;
            }
          }
        });

        if (dataFiltered.length > 0) {
          dataFiltered.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());
        }

        if (selectedEventList.length !== dataFiltered.length) {
          updateSelectedEventList(dataFiltered);
        } else {
          //If Same length check each item
          const isDifferent = selectedEventList.some((event, i) => event !== dataFiltered[i]);

          if (isDifferent) {
            updateSelectedEventList(dataFiltered);
          }
        }

        showTooltip({
          tooltipData: {
            startDate: chartContext.selectedDateTime,
            value: [],
          },
          tooltipLeft: xPosition,
          tooltipTop: 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContext]);

  return { dateScaleXAxis, metricScaleYAxis, data };
};

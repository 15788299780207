import { addToast } from 'reducers/toasts/ToastActions';
import Tag from 'types/Tags/Tag';
import ToastStatusEnum from 'types/ToastStatusEnum';
import { handleReduxApiReq } from 'utils/ApiRequestHelpers';
import { getDelayDisplay } from 'utils/helpers';
import { createThunkEffect } from 'utils/ReduxUtils';

import IAction from '../IAction';

const CLEAR_TAGS = 'CLEAR_TAGS';
const CREATE_TAG_REQUEST = 'CREATE_TAG_REQUEST';
const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
const CREATE_TAG_ERROR = 'CREATE_TAG_ERROR';
const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
const DELETE_TAG_ERROR = 'DELETE_TAG_ERROR';
const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';
const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
const REFRESH_TAGS = 'REFRESH_TAGS';

export const tagTypes = {
  CLEAR_TAGS,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_ERROR,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_ERROR,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_ERROR,
  FETCH_TAGS_SUCCESS,
  REFRESH_TAGS,
};

export const clearTags = () => ({
  type: CLEAR_TAGS,
});

const fetchTagsRequest = () => ({
  type: FETCH_TAGS_REQUEST,
});

const fetchTagsSuccess = (tags): IAction<any> => ({
  type: FETCH_TAGS_SUCCESS,
  payload: tags,
});

const fetchTagsError = (error): IAction<any> => ({
  type: FETCH_TAGS_ERROR,
  error: error,
});

export const fetchTagsAction = async (dispatch) => {
  dispatch(fetchTagsRequest());
  try {
    const data = await handleReduxApiReq('GetTenantTags', {}, 'POST');
    if (data) {
      data.alerts = data.alerts.map((a) => ({ ...a, delayDisplay: getDelayDisplay(a) }));
      dispatch(fetchTagsSuccess(data));
    }
  } catch (e) {
    dispatch(fetchTagsError(e));
  }
};

export const fetchTags = () => async (dispatch) => {
  fetchTagsAction(dispatch);
};

export const refreshTags = () => async (dispatch) =>
  await createThunkEffect(dispatch, REFRESH_TAGS, 'GetTenantTags', {});

const createTagRequest = () => ({
  type: CREATE_TAG_REQUEST,
});

const createTagSuccess = (tag): IAction<any> => ({
  type: CREATE_TAG_SUCCESS,
  payload: tag,
});

const createTagError = (error): IAction<any> => ({
  type: CREATE_TAG_ERROR,
  error: error,
});

export const createTagAction = async (dispatch, tag: Tag) => {
  dispatch(createTagRequest());
  try {
    const data = await handleReduxApiReq('TenantCreateTag', { name: tag.name }, 'POST');
    if (data) {
      dispatch(createTagSuccess(data));
    }
  } catch (e) {
    dispatch(createTagError(e));
  }
};

export const createTag = (tag: Tag) => async (dispatch) => {
  createTagAction(dispatch, tag);
};

const deleteTagRequest = () => ({
  type: DELETE_TAG_REQUEST,
});

const deleteTagSuccess = (key): IAction<any> => ({
  type: DELETE_TAG_SUCCESS,
  payload: key.id,
});

const deleteTagError = (error): IAction<any> => ({
  type: DELETE_TAG_ERROR,
  error: error,
});

export const deleteTagAction = async (dispatch, tag) => {
  dispatch(deleteTagRequest());
  try {
    const data = await handleReduxApiReq('TenantDeleteTag', tag, 'POST');
    if (data) {
      dispatch(deleteTagSuccess(data));
    }
  } catch (e) {
    dispatch(deleteTagError(e));
    dispatch(addToast('Something went wrong!', ToastStatusEnum.Error));
  }
};

export const deleteTag = (tag) => async (dispatch) => {
  deleteTagAction(dispatch, tag);
};

import React, { useEffect, useState, useReducer } from 'react';

import Button from 'components/atoms/Button';
import Modal from 'components/elements/Modal';
import DeviceRepositioningMap from 'components/FloorPlan/DeviceRepositioningMap';
import { useSelector, useDispatch } from 'react-redux';
import { refreshEmeredDetails } from 'reducers/device/emeredActions';
import styled from 'styled-components';
import { handleApiReq } from 'utils/ApiRequestHelpers';
import useAlert from 'utils/hooks/useAlert';

import {
  floorPlanReducer,
  floorPlanInitialAction,
  initFloorPlanState,
} from '../../../FloorPlan/FloorPlanReducer';

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  text-align: center;

  & > button {
    width: 30%;
    min-width: 10rem;
    max-width: 20rem;
    margin: 0 2rem;
  }
`;

const EmeredRepositionModal = ({ showModal, setShowModal, device }) => {
  const dispatchRedux = useDispatch();
  const { errorAlert, successAlert } = useAlert();
  const mapItem = useSelector((state: any) => state.mapData);
  const buildingInfo = useSelector((state: any) => state.buildingInfo.buildings);

  const [state, dispatch] = useReducer(floorPlanReducer, initFloorPlanState);

  const { clusters, selectedBuilding, selectedFloor } = state;

  const [deviceCoOrds, setDeviceCoOrds] = useState<{ lat: number; long: number }>({
    lat: device.lat,
    long: device.long,
  });

  useEffect(() => {
    dispatch(floorPlanInitialAction(buildingInfo, mapItem));
  }, [buildingInfo, mapItem]);

  const handleSaveClick = async () => {
    try {
      const reqBody: { deviceId: string; tenant: string; latitude: number; longitude: number } = {
        deviceId: device.deviceId,
        tenant: device.tenant,
        latitude: deviceCoOrds.lat,
        longitude: deviceCoOrds.long,
      };
      const response = await handleApiReq('UpdateDeviceGeoLocation', reqBody, 'POST');
      if (response && response.isSuccessful) {
        await dispatchRedux(
          refreshEmeredDetails({ deviceId: device.deviceId, startDate: new Date() })
        );
        successAlert('Device geo location updated successfully');
      }
    } catch (error) {
      errorAlert(`There was an error updating device geo location ${error}`);
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <Modal
      showModal={showModal}
      handleClose={() => setShowModal(false)}
      title={`Reposition ${device.deviceName}`}
    >
      <div>Click to reposition and then click save</div>
      {selectedFloor && selectedBuilding && (
        <DeviceRepositioningMap
          device={device}
          selectedBuilding={selectedBuilding}
          selectedFloor={selectedFloor}
          clusters={clusters}
          deviceCoOrds={deviceCoOrds}
          setDeviceCoOrds={setDeviceCoOrds}
        />
      )}
      <ButtonWrapper>
        <Button type="clear" clickCallback={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button type="submit" clickCallback={handleSaveClick}>
          Save
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default EmeredRepositionModal;

import { useAreaFeatures } from 'utils/hooks';
import { FeaturesAreas } from 'types';
import { propertyLinks } from '../constants/property';

export const useFilterTabs = () => {
  const propertyFeatures = useAreaFeatures(FeaturesAreas.Properties).features;

  const tabs = propertyLinks.filter(({ label }) => {
    switch (label) {
      case 'Services':
        return propertyFeatures.ManagedService;
      case 'Devices':
        return !propertyFeatures.HideDeviceTab;
      case 'Property Records':
        return propertyFeatures.PropertyRecords;
      default:
        return true;
    }
  });

  return tabs;
};

import { User } from 'reducers/users/types';
import {
  NO_TAG_GROUP,
  VERICON_SYSTEM_USER_EMAIL,
  VERICON_USER_GROUP_NAME,
} from '../constants/users';

type GroupUsersByTagProps = {
  users: User[];
};

export const groupUsersByTag = ({ users }: GroupUsersByTagProps) => {
  if (!users?.length) {
    return;
  }

  const vericonUsers = users.filter((user) => user.email.includes(VERICON_SYSTEM_USER_EMAIL));

  const usersGroupedByTag = users
    .filter((user) => !user.email.includes(VERICON_SYSTEM_USER_EMAIL))
    .reduce((acc, user) => {
      const tagName = user.accessRightsTag || NO_TAG_GROUP;
      acc[tagName] = acc[tagName] || [];
      acc[tagName].push(user);

      return acc;
    }, {});

  return {
    ...usersGroupedByTag,
    ...(vericonUsers.length ? { [VERICON_USER_GROUP_NAME]: vericonUsers } : {}),
  };
};

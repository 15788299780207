import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import DeviceIcon from 'components/Icons/DeviceIcon';
import { setMapData } from 'reducers/mapData';
import { selectStoredView } from 'reducers/viewDefinitions';
import { isInventory, mapDeviceTypesToUrl } from 'utils/deviceUtils';
import { isNullOrUndefined } from 'utils/helpers';
import useNavigate from 'utils/hooks/useNavigate';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 5rem 0;
  &.admin {
    margin: 1rem 0 5rem 0;
  }
  & h2 {
    margin-left: 2rem;
  }
  @media ${(props) => props.theme.laptopM} {
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem 0 5rem 0;
  }
  @media ${(props) => props.theme.mobileL} {
    align-items: center;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media ${(props) => props.theme.laptopM} {
    margin-bottom: 2rem;
  }
  @media ${(props) => props.theme.tabPort} {
    flex-direction: column;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  @media ${(props) => props.theme.tabPort} {
    margin-bottom: 1rem;
  }
`;

const MapButton = styled.button`
  ${(props) => props.theme.defaultButton('12rem')};
  font-size: ${(props) => props.theme.textSmall};
  margin-left: 2rem;

  @media ${(props) => props.theme.mobileM} {
    margin-left: 0;
  }
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  & div {
    margin-left: 1rem;
  }
`;

const VR = styled.span`
  display: inline-block;
  margin: 0 2rem;
  height: 3.5rem;
  width: 0.5px;
  background-color: #ccc;
  @media ${(props) => props.theme.mobileM} {
    margin: 0 1rem;
  }
`;

const BackButton = styled.span`
  position: relative;
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.primary};
  &:hover {
    cursor: pointer;
  }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    height: 1rem;
    width: 1rem;
    border-left: 2px white solid;
    border-bottom: 2px white solid;
    transform-origin: 0 0;
    transform: rotate(45deg) translate(-35%, -65%);
  }
`;

const ContentItem = styled.div`
  display: inline-block;
  & span {
    color: ${(props) => props.theme.spanBlue};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Header = ({ device, children }) => {
  const dispatch = useDispatch();
  const storedView = useSelector((state: any) => selectStoredView(state));
  const [showMapBtn, setShowMapBtn] = useState(false);

  const { goTo } = useNavigate();

  const handleBackClick = () => {
    if (storedView) {
      goTo(mapDeviceTypesToUrl(device.deviceType), 'Listing', storedView.viewId);
    } else {
      goTo(mapDeviceTypesToUrl(device.deviceType));
    }
  };

  useEffect(() => {
    setShowMapBtn(
      !isInventory(device) && !isNullOrUndefined(device?.lat) && !isNullOrUndefined(device?.long)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  const handleMapClick = () => {
    dispatch(
      setMapData([
        {
          lat: device.lat,
          lng: device.long,
          deviceId: device.deviceId,
          deviceName: device.deviceName,
          serialNumber: device.serialNumber,
          deviceType: device.deviceType,
          deviceStatus: device.deviceStatus,
          connectionStatus: device.connectionStatus,
          floor: device.floorName,
          building: device.buildingId,
        },
      ])
    );
    goTo('FloorPlan');
  };

  return (
    <HeaderWrapper>
      <HeaderLeft>
        <TitleWrapper>
          <BackButton onClick={handleBackClick} />
          <VR />
          <DeviceIcon deviceType={device.deviceType} status={device.deviceStatus} size={3.5} />
          <h2>{device.deviceName}</h2>
        </TitleWrapper>
        <ButtonWrapper>
          {children}
          {showMapBtn && <MapButton onClick={handleMapClick}>Go To Map</MapButton>}
        </ButtonWrapper>
      </HeaderLeft>
      <HeaderRight>
        {device.building && (
          <>
            <ContentItem>
              <span>Building:&nbsp;</span>
              {device.buildingName}
            </ContentItem>
            <ContentItem>
              <span>Floor:&nbsp;</span>
              {device.floor}
            </ContentItem>
            <ContentItem>
              <span>Room:&nbsp;</span>
              {device.room}
            </ContentItem>
          </>
        )}
      </HeaderRight>
    </HeaderWrapper>
  );
};

export default Header;

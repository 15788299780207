import styled from 'styled-components';
import { editFieldsStyles } from 'styles/commonStyles';

export const EditFormFieldsContainerWrapper = styled.div`
  .edit-form-fields-container {
    &__main {
      display: flex;
      gap: 1rem;

      &--only-icon {
        .edit-form-fields-container__label-edit-button {
          position: static;
          transform: unset;
        }
      }
    }

    &__title {
      ${editFieldsStyles.title}
    }

    &__label {
      ${editFieldsStyles.label}
    }

    &__inner-content {
      display: flex;
      gap: 10px;
    }

    &__block {
      display: flex;
      align-items: center;
    }
  }
`;

import React, { useState, useEffect, useRef } from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import Input from 'components/elements/Input';
import { useSelector, useDispatch } from 'react-redux';
import { shouldViewUpdate } from 'reducers/viewData';
import { ViewDefinition } from 'reducers/viewDefinitions';
import styled from 'styled-components';
import { formatDate } from 'utils/helpers';
import useClickAway from 'utils/hooks/useClickAway';
import useInterval from 'utils/hooks/useInterval';
import useIsLoading from 'utils/hooks/useIsLoading';
import useNavigate, { Page } from 'utils/hooks/useNavigate';
import useProfile from 'utils/hooks/useProfile';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 3rem;
`;

const HeaderLeft = styled.div`
  display: flex;
  position: relative;

  && h1 {
    font-size: 3.2rem;
    font-weight: 500;
    margin-bottom: 0;
  }
`;

const List = styled.div`
  width: 30rem;
  position: absolute;
  top: 5rem;

  border-radius: 3px;
  background: ${(props) => props.theme.white};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;

  max-height: 40rem;

  overflow: hidden;
  visibility: hidden;
  opacity: 0;
 z-index: 10;

  &.open {
    opacity: 1;
    overflow-y: auto;
    visibility: visible;
  }

  & ul {
    & li {
      font-size: 1.4rem;
      padding: 1rem 3rem;
      transform-origin: top center;
      transition: all 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        background-color: #ececf7;
      }
    }
  }
`;

const InputWrapper = styled.div`
  padding: 1rem 2rem;
`;

const VertSeperator = styled.div`
  height: 4rem;
  background: #999;
  width: 0.25rem;
  margin: 0 1.5rem 0 2.6rem;
`;

const SvgWrapper = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.primaryLight};
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.primary};
  }

  & svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;

const CaratWrapper = styled(SvgWrapper)`
  width: 3.5rem;
  height: 3.5rem;
  margin-top: 0.3rem;
  cursor: pointer;
`;

const SyncWrapper = styled(SvgWrapper)`
  width: 2rem;
  height: 2rem;
  margin: 1rem;
  position: relative;
  cursor: pointer;
`;

const UpdateIndicator = styled.span`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.success};

  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
`;

const SyncText = styled.span`
  position: absolute;
  font-size: ${(props) => props.theme.textSmallest};
  width: 20rem;
  right: -21.5rem;
  top: -1.5rem;
  opacity: 0.8;

  cursor: default;
`;

const MapButton = styled.button<{ isDisabled: boolean }>`
  ${(props) => props.theme.defaultButton('12rem', props.isDisabled)};
`;

const Heading = styled.h4`
  padding: 0 1.5rem;
  margin-top: 0.5rem;
  font-weight: 500;
`;

interface HeaderProps {
  selectedView: ViewDefinition;
  availableViews: ViewDefinition[];
  isDisabled: boolean;
  route: Page;
  handleMapClick(): void;
  handleUpdate(): void;
}

const isSystemView = (view: ViewDefinition) => view.builtIn;
const isPublicView = (view: ViewDefinition) => !view.builtIn && !view.private;
const isPrivate = (view: ViewDefinition) => !view.builtIn && view.private;
const containsString = (str: string) => (view: ViewDefinition) =>
  view.label.toLowerCase().includes(str.toLowerCase());

const isSystemViewAndContainsString = (str: string) => (view: ViewDefinition) =>
  isSystemView(view) && containsString(str)(view);

const isPublicViewAndContainsString = (str: string) => (view: ViewDefinition) =>
  isPublicView(view) && containsString(str)(view);

const isPrivateViewAndContainsString = (str: string) => (view: ViewDefinition) =>
  isPrivate(view) && containsString(str)(view);

const Header = ({
  selectedView,
  availableViews,
  handleMapClick,
  isDisabled,
  handleUpdate,
  route,
}: HeaderProps) => {
  const node = useRef<HTMLDivElement>(null);

  const isFetching = useIsLoading(['FETCH_VIEWDATA', 'REFRESH_VIEWDATA']);
  const dispatch = useDispatch();
  const lastUpdate = useSelector((state: any) => state.viewData.lastUpdate);
  const shouldUpdate = useSelector((state: any) => state.viewData.shouldUpdate);

  const profile = useProfile();
  const [isOpen, setIsOpen] = useState(false);

  const [systemViews, setSystemViews] = useState(availableViews.filter(isSystemView));
  const [publicViews, setPublicViews] = useState(availableViews.filter(isPublicView));
  const [privateViews, setPrivateViews] = useState(availableViews.filter(isPrivate));

  const checkStatus = () => {
    dispatch(shouldViewUpdate(profile.role?.devices, lastUpdate));
  };

  const { goTo } = useNavigate();
  const interval = process.env.REACT_APP_ENV !== 'dev' ? 10000 : 600000;
  useInterval(checkStatus, isFetching || shouldUpdate ? null : interval);

  const handleItemClick = (v) => {
    setIsOpen(false);
    goTo(route, 'Listing', v.viewId);
  };

  const [search, setSearch] = useState('');
  const handleOnSearchChange = (v) => setSearch(v);

  useClickAway(node, () => setIsOpen(false));

  useEffect(() => {
    setSystemViews(availableViews.filter(isSystemView));
    setPublicViews(availableViews.filter(isPublicView));
    setPrivateViews(availableViews.filter(isPrivate));
  }, [availableViews]);

  useEffect(() => {
    setSystemViews(availableViews.filter(isSystemViewAndContainsString(search)));
    setPublicViews(availableViews.filter(isPublicViewAndContainsString(search)));
    setPrivateViews(availableViews.filter(isPrivateViewAndContainsString(search)));
  }, [search, availableViews]);

  return (
    <HeaderWrapper>
      <HeaderLeft>
        <h1>{selectedView?.label}</h1>
        <VertSeperator />

        <div ref={node}>
          <CaratWrapper onClick={() => setIsOpen(!isOpen)}>
            <SvgIcon type="caret-down" />
          </CaratWrapper>
          <List className={isOpen ? 'open' : ''}>
            <InputWrapper>
              <Input
                type="text"
                inputData={{
                  value: search,
                  name: 'search',
                  placeholder: 'Filter Views',
                  onChange: handleOnSearchChange,
                }}
              />
            </InputWrapper>
            <Heading>System Views</Heading>
            <ul>
              {systemViews.map((v, i) => (
                <li
                  role="presentation"
                  key={i}
                  className={v?.viewId === selectedView?.viewId ? 'active' : ''}
                  onClick={() => handleItemClick(v)}
                >
                  {v.label}
                </li>
              ))}
            </ul>
            <Heading>Public Views</Heading>
            <ul>
              {publicViews.map((v, i) => (
                <li
                  role="presentation"
                  key={i}
                  className={v?.viewId === selectedView?.viewId ? 'active' : ''}
                  onClick={() => handleItemClick(v)}
                >
                  {v.label}
                </li>
              ))}
            </ul>
            <Heading>Private Views</Heading>
            <ul>
              {privateViews.map((v, i) => (
                <li
                  role="presentation"
                  key={i}
                  className={v?.viewId === selectedView?.viewId ? 'active' : ''}
                  onClick={() => handleItemClick(v)}
                >
                  {v.label}
                </li>
              ))}
            </ul>
          </List>
        </div>
        <SyncWrapper>
          <div
            onClick={() => handleUpdate()}
            role="button"
            tabIndex={0}
            onKeyDown={() => handleUpdate()}
          >
            <SvgIcon type="sync" />
            {shouldUpdate && <UpdateIndicator />}
          </div>
          <SyncText>Last Updated: {formatDate(lastUpdate)} </SyncText>
        </SyncWrapper>
      </HeaderLeft>
      <div>
        <MapButton isDisabled={isDisabled} onClick={handleMapClick}>
          Map
        </MapButton>
      </div>
    </HeaderWrapper>
  );
};

export default Header;

import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import TenantLogo from '../Tenants/TenantLogo';
import UserInfo from './UserInfo';

const HeaderWrapper = styled.header`
  position: relative;
  background: ${(props) => props.theme.headerColor};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  z-index: 1;
  @media ${(props) => props.theme.mobileLand} {
    background: ${(props) => props.theme.primary};
  }
`;

const LogoContainer = styled.div`
  width: 12rem;

  & img {
    width: 100%;
  }
`;

const Logo = styled.picture`
  width: 100%;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${(props) => props.theme.mobileLand} {
    justify-content: space-around;
  }
`;

const MenuWrapper = styled.div`
  display: none;
  margin: 0 2.5rem;
  cursor: pointer;

  & > div {
    width: 35px;
    height: 4px;
    border-radius: 50px;
    background-color: ${(props) => props.theme.primary};
    margin: 8px 0;
    transition: 0.4s;
    transform: rotate(0) translate(0);
    transform-origin: 37.5% 50%;

    @media ${(props) => props.theme.mobileLand} {
      width: 30px;
      margin: 6px 0;
      background-color: ${(props) => props.theme.white};
      order: 9;
    }
  }

  &.open > div {
    &:nth-child(1) {
      transform: rotate(135deg) translateY(-12px);
    }

    &:nth-child(2) {
      transform: translateX(-20px);
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-135deg) translateY(12px);
    }

    @media ${(props) => props.theme.mobileLand} {
      &:nth-child(1) {
        transform: rotate(135deg) translateY(-10px);
      }
      &:nth-child(3) {
        transform: rotate(-135deg) translateY(10px);
      }
    }
  }

  @media ${(props) => props.theme.mobileLand} {
    order: 9;
    margin: 0 1rem;
  }

  @media ${(props) => props.theme.laptop} {
    display: none;
  }
  @media ${(props) => props.theme.laptopM} {
    display: none;
  }
  @media ${(props) => props.theme.laptopL} {
    display: none;
  }
  @media ${(props) => props.theme.tablet} {
    display: none;
  }
  @media ${(props) => props.theme.mobileS} {
    display: inline-block;
  }
  @media ${(props) => props.theme.mobileM} {
    display: inline-block;
  }
  @media ${(props) => props.theme.mobileL} {
    display: inline-block;
  }
`;

const MenuIcon = ({ onClick, className }) => (
  <MenuWrapper className={className} onClick={onClick}>
    <div />
    <div />
    <div />
  </MenuWrapper>
);

const Header = ({
  handleMenuClick,
  loggedIn,
  profile,
  isOpen,
  handleLogout,
  handleChangeTenant,
}) => {
  const url = profile?.selectedTenant ? `/${profile.selectedTenant}/Dashboard` : '/';

  return (
    <HeaderWrapper id="mainHeader">
      <HeaderLeft>
        <Link to={url}>
          <LogoContainer>
            <Logo>
              <source media="(max-width: 32em)" srcSet="images/logo-white.png" />
              <source
                media="(max-width: 40em) and (orientation : landscape)"
                srcSet="images/logo-white.png"
              />
              <source srcSet="images/logo.png" />
              <img src="images/logo.png" alt="Vericon Systems" />
            </Logo>
          </LogoContainer>
        </Link>
        {profile?.selectedTenant && (
          <TenantLogo selectedTenantId={profile.selectedTenant} uILocation="navbar" />
        )}
      </HeaderLeft>

      {loggedIn && profile.tenantSelected && (
        <MenuIcon className={isOpen ? 'open' : ''} onClick={handleMenuClick} />
      )}

      <HeaderRight>
        {loggedIn && (
          <UserInfo
            profile={profile}
            handleLogout={() => handleLogout({ returnTo: process.env.REACT_APP_CALLBACKURL })}
            handleChangeTenant={handleChangeTenant}
          />
        )}
      </HeaderRight>
    </HeaderWrapper>
  );
};

export default Header;

import React from 'react';

import DateTimePicker from 'components/atoms/DateTimePicker';
import styled from 'styled-components';

const TextBoxWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const DateTimeField = ({ value, onChange, name }) => (
  <TextBoxWrapper>
    <DateTimePicker name={name} onChange={onChange} date={value} />
  </TextBoxWrapper>
);
export default DateTimeField;

import styled from 'styled-components';

export const ContactDetailsWrapper = styled.div`
  padding-top: 20px;

  .contact-details {
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      padding-bottom: 20px;
    }

    &__inputs {
      display: flex;
      flex-direction: column;

      @media (min-width: 1024px) {
        gap: 30px;
        flex-direction: row;
      }
    }

    &__button {
      padding-bottom: 20px;

      @media (min-width: 1024px) {
        padding: 0;
      }
    }
  }
`;

export const InputWrapper = styled.div`
  font-size: ${(props) => props.theme.textSmall};
  margin-bottom: 1.5rem;
  position: relative;
  padding-right: 10px;
  width: unset;

  @media (min-width: 1024px) {
    width: 100%;
  }

  & label {
    color: ${(props) => props.theme.spanBlue};
    font-weight: 500;
  }
`;

export const Button = styled.button<{ disabled: boolean }>`
  ${(props) => props.theme.defaultButton('12rem', props.disabled)};

  &:disabled {
    &:hover {
      cursor: unset;
    }
  }
`;
